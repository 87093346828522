import SvssFunction from './SvssFunction'

import { InnerRoute } from '@lemonbrain/hooks'

export default function SvssFunctionBusiness({
  subNav,
  svssFunctionId,
  setSvssFunctionId,
}: {
  subNav: InnerRoute[]
  svssFunctionId: string | null | undefined
  setSvssFunctionId: (svssFunctionId: string | undefined) => void
}) {
  return (
    <SvssFunction
      subNav={subNav}
      svssFunctionId={svssFunctionId}
      setSvssFunctionId={setSvssFunctionId}
    />
  )
}
