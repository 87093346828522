import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import AddressTypeTable, { RowDataT } from './AddressTypeTable'

import { useAlert } from '@lemonbrain/hooks'

export default function AddressTypeTableBusiness({
  isLoading,
  addressTypes,
  fetchMoreAddressTypeEntries,
  refetchAddressTypeEntries,
  goToAddressTypeEntry,
  removeAddressTypeEntry,
  queryError,
}: {
  isLoading: boolean
  addressTypes: {
    id: string
    data: RowDataT
  }[]
  fetchMoreAddressTypeEntries: () => void
  refetchAddressTypeEntries: () => void
  goToAddressTypeEntry: (id: string) => void
  removeAddressTypeEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeAddressTypeEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.addressTypeTable.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchAddressTypeEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.addressTypeTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.addressTypeTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.addressTypeTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.addressTypeTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<AddressTypeTable
      isLoading={currentIsLoading}
      rows={addressTypes}
      fetchMoreListItems={fetchMoreAddressTypeEntries}
      editRow={goToAddressTypeEntry}
      removeRow={removeRow}
    />
  )
}
