import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import StateTypeBusiness from './StateType.business'

export default function StateTypeData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { stateId } = useParams<{ stateId: string }>()
  const { stateTypeId } = useParams<{ stateTypeId: string }>()
  const { language } = useParams<{ language: string }>()

  function setStateTypeId(stateTypeId: string) {
    if (stateId && stateId !== '' && stateTypeId && stateTypeId !== '') {
      pushPath(`/${language}/settings/state-types/${stateId}/type/${stateTypeId}`)
    } else  if (stateId && stateId !== '') {
      pushPath(`/${language}/settings/state-types/${stateId}/type`)
    }
  }

  return (
    <StateTypeBusiness
      subNav={subNav}
      stateId={stateId || ''}
      stateTypeId={stateTypeId || ''}
      setStateTypeId={setStateTypeId}
    />
  )
}
