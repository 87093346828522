import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'

import { useAlert } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'
import { useHistoryPush } from '@lemonbrain/hooks'
import { prepStringWN } from '@lemonbrain/utils'

import AssociationEdit from './AssociationEdit'
import {
  UpdateAssociationMutationVariables,
  UpdateAssociationIfMemberMutationVariables,
} from '../../../gql/Association.generated'

export default function AssociationEditBusiness({
  goToAssociation,
  loading,
  updateAssociationEntry,
  association,
  setAssociation,
  associationId,
}: {
  goToAssociation: (id: string | null) => void
  loading: boolean
  updateAssociationEntry(
    data: UpdateAssociationIfMemberMutationVariables
  ): Promise<void>
  association: UpdateAssociationIfMemberMutationVariables & {
    prizeString: string
    imageSrc: string
  }
  setAssociation: React.Dispatch<
    React.SetStateAction<
      UpdateAssociationIfMemberMutationVariables & { prizeString: string, imageSrc: string }
    >
  >
  associationId?: string
}) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const [errors, setErrors] = useState<string[]>([])
  const [associationErrors, setAssociationErrors] = useState<string[]>([])
  const intl = useIntl()
  const setAlert = useAlert()
  const { user } = useAppState()

  function getHasAssociationErrors(errors: {
    deName: string
    frName: string
    itName: string
    deShort: string
    frShort: string
    itShort: string
    associationURL: string
    prize: string
  }) {
    if (errors && Object.values(errors)) {
      setAssociationErrors([
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map(
            (item) =>
              `${intl.formatMessage({
                id: 'associations.associationEdit.association',
                defaultMessage: '1/2 Association',
              })} - ${item}`
          ),
      ])
    } else {
      setAssociationErrors([])
    }
  }

  function getHasBillingInformaitinosErrors(errors: {
    firmname: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    account: string
    iban: string
    qrIban: string
    prize: string
    // membersCalculationAt: string
  }) {
    if (errors && Object.values(errors)) {
      setAssociationErrors([
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map(
            (item) =>
              `${intl.formatMessage({
                id: 'associations.associationEdit.billingInformations',
                defaultMessage: '2/2 Billing informations',
              })} - ${item}`
          ),
      ])
    } else {
      setAssociationErrors([])
    }
  }

  function validate() {
    return errors.length === 0
  }

  function cancelClickHandler() {
    goToAssociation(null)
  }

  async function updateClickHandler() {
    validate()
    if (association) {
      if (user && user.uID) {
        try {
          let variables: UpdateAssociationMutationVariables = {
            id: association.id,
            name: {
              de: prepStringWN(association.name.de) || '',
              fr: prepStringWN(association.name.fr) || '',
              it: prepStringWN(association.name.it) || '',
            },
            short: {
              de: prepStringWN(association.short.de) || '',
              fr: prepStringWN(association.short.fr) || '',
              it: prepStringWN(association.short.it) || '',
            },
            imageId: association.imageId,
            image: association.image,
            associationURL: prepStringWN(association.associationURL) || '',
            isStufenvereinigung: association.isStufenvereinigung,
            prize: Math.round(parseFloat(association.prizeString) * 100),
            needsAComment: association.needsAComment,
            defaultComment: association.defaultComment,
            // membersCalculationAt: association.membersCalculationAt,
            billingInformation: association.billingInformation,
          }

          await updateAssociationEntry(variables)

          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'associations.associationEdit.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'associations.associationEdit.saved.updated',
                defaultMessage: 'Entry is updated',
              }),
              style: 'success',
            })
          pushPath('/' + language + '/associations/overview')
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'associations.associationEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'associations.associationEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'associations.associationEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'associations.associationEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  useEffect(() => {
    setErrors([...associationErrors])
  }, [associationErrors])

  return (
    <AssociationEdit
      errors={errors}
      goToAssociation={goToAssociation}
      onCancelClick={cancelClickHandler}
      isLoading={loading}
      onUpdateClick={updateClickHandler}
      association={association}
      setAssociation={setAssociation}
      getHasAssociationErrors={getHasAssociationErrors}
      getHasBillingInformaitinosErrors={getHasBillingInformaitinosErrors}
      associationId={associationId}
    />
  )
}
