import StateEditBusiness from './StateEdit.business'
import {HighlightColors} from './StateEdit'
import {
  useGetStateLazyQuery,
  useUpdateStateMutation,
  useAddStateMutation,
} from '../../../gql/State.generated'
import getReactApolloCients from '../../../localReactApollo'

import { useEffect, useState } from 'react'

export default function StateEditData({
  stateId,
  setStateId,
}: {
  stateId: string | null | undefined
  setStateId: (addressTypeId: string | undefined) => void
}) {
  const [values, setValues] = useState<{
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }>({
    isInitState: false,
    highlightColor: HighlightColors.primary,
    de: '',
    fr: '',
    it: '',
  })
  const client = getReactApolloCients().getClient()
  const [
    getStateQuery,
    { data, loading, error: queryError },
  ] = useGetStateLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateStateMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateStateMutation({
    //@ts-ignore
    client,
  })
  const [
    addStateMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddStateMutation({
    //@ts-ignore
    client,
  })

  async function updateStateEntry(data: {
    stateId: string
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) {
    if (data.stateId) {
      await updateStateMutation({
        variables: {
          id: data.stateId,
          isInitState: data.isInitState,
          highlightColor: data.highlightColor,
          name: {
            de: data.de,
            fr: data.fr,
            it: data.it,
          },
        },
      })
    }
  }

  async function createStateEntry(data: {
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) {
    await addStateMutation({
      variables: {
        isInitState: data.isInitState,
        highlightColor: data.highlightColor,
        name: {
          de: data.de,
          fr: data.fr,
          it: data.it,
        },
      },
    })
  }

  function removeStateId() {
    setStateId(undefined)
  }

  useEffect(() => {
    if (stateId) {
      getStateQuery({
        variables: {
          id: stateId,
        },
      })
    }
  }, [stateId])

  useEffect(() => {
    if (data?.getState) {
      setValues({
        isInitState: data.getState.isInitState,
        highlightColor: data.getState.highlightColor,
        de: data.getState.name.de,
        fr: data.getState.name.fr,
        it: data.getState.name.it,
      })
    }
  }, [data])

  return (
    <StateEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      queryError={queryError || queryChangeError || queryAddError}
      removeStateId={removeStateId}
      stateId={stateId}
      updateStateEntry={updateStateEntry}
      createStateEntry={createStateEntry}
    />
  )
}
