import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import StateTable, { RowDataT } from './StateTable'

import { useAlert } from '@lemonbrain/hooks'

export default function StateTableBusiness({
  isLoading,
  states,
  fetchMoreStateEntries,
  refetchStateEntries,
  goToStateEntry,
  listStateTypes,
  removeStateEntry,
  queryError,
}: {
  isLoading: boolean
  states: {
    id: string
    data: RowDataT
  }[]
  fetchMoreStateEntries: () => void
  refetchStateEntries: () => void
  goToStateEntry: (id: string) => void
  listStateTypes: (id: string) => void
  removeStateEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeStateEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateTable.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchStateEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<StateTable
      isLoading={currentIsLoading}
      rows={states}
      fetchMoreListItems={fetchMoreStateEntries}
      editRow={goToStateEntry}
      listStateTypes={listStateTypes}
      removeRow={removeRow}
    />
  )
}
