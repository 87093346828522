import { useState, useEffect } from 'react'

import WorkLevelTableBusiness from './WorkLevelTable.business'
import { RowDataT } from './WorkLevelTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteWorkLevelMutation,
  useGetWorkLevelsQuery,
  GetNextWorkLevelsDocument,
  GetNextWorkLevelsQuery,
} from '../../../gql/WorkLevel.generated'

export default function WorkLevelTableData({
  setWorkLevelId,
}: {
  setWorkLevelId: (workLevelId: string | undefined) => void
}) {
  const [workLevels, setWorkLevels] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetWorkLevelsQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteWorkLevelMutation] = useDeleteWorkLevelMutation({
    //@ts-ignore
    client,
  })

  async function removeWorkLevelEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteWorkLevelMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteWorkLevel.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreWorkLevelEntries() {
    if (data?.getWorkLevels.nextCursor) {
      fetchMore({
        query: GetNextWorkLevelsDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getWorkLevels.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextWorkLevelsQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextWorkLevels: {
                nextCursor: fetchMoreResult.getNextWorkLevels.nextCursor,
                items: [...fetchMoreResult.getNextWorkLevels.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextWorkLevels.items.length > 0 &&
            previousResult.getWorkLevels.items.filter((item) => {
              return item.id === fetchMoreResult.getNextWorkLevels.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getWorkLevels: {
                ...previousResult.getWorkLevels,
                nextCursor: fetchMoreResult.getNextWorkLevels.nextCursor,
                items: [
                  ...previousResult.getWorkLevels.items,
                  ...fetchMoreResult.getNextWorkLevels.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getWorkLevels.items) {
      setWorkLevels(
        data?.getWorkLevels.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToWorkLevelEntry(id: string) {
    setWorkLevelId(id)
  }

  return (
    <WorkLevelTableBusiness
      isLoading={loading}
      workLevels={workLevels}
      fetchMoreWorkLevelEntries={fetchMoreWorkLevelEntries}
      refetchWorkLevelEntries={refetch}
      goToWorkLevelEntry={goToWorkLevelEntry}
      removeWorkLevelEntry={removeWorkLevelEntry}
      queryError={queryError}
    />
  )
}
