import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import SportsEducationFilterBusiness from './SportsEducationFilter.business'
import getReactApolloCients from '../../../../../localReactApollo'
import { useGetSportsEducationsNoPersonQuery } from '../../../../../gql/members/SportsEducation.generated'

export default function SportsEducationFilterData({
  sportsEducationFilter,
  setSportsEducationFilter,
}: {
  sportsEducationFilter: string[] | null
  setSportsEducationFilter: React.Dispatch<
    React.SetStateAction<string[] | null>
  >
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [sportsEducations, setSortsEducations] = useState<
    {
      id: string
      label: string
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data: dataSportsEducation,
    loading: loadingSportsEducation,
    error: errorSportsEducation,
  } = useGetSportsEducationsNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (errorSportsEducation) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.sportsEducation_filter.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.sportsEducation_filter.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorSportsEducation?.message)
    }
  }, [errorSportsEducation])

  useEffect(() => {
    if (dataSportsEducation?.getSportsEducationsNoPerson.items) {
      setSortsEducations(
        dataSportsEducation?.getSportsEducationsNoPerson.items
          .map((item) => ({
            id: item.id,
            label: getTranslation(item.name, intl),
          }))
          .sort(function (a, b) {
            var labelA = a.label.toUpperCase()
            var labelB = b.label.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataSportsEducation])

  return (
    <SportsEducationFilterBusiness
      sportsEducations={sportsEducations}
      loadingSportsEducation={loadingSportsEducation}
      sportsEducationFilter={sportsEducationFilter}
      setSportsEducationFilter={setSportsEducationFilter}
    />
  )
}
