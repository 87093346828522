import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ApolloQueryResult } from '@apollo/client'

import AssociationTypeTable, { RowDataT } from './AssociationTypeTable'
import { Exact, StartCursorInput } from '../../../types'
import { GetAssociationTypesNoPersonQuery } from '../../../gql/AssociationType.generated'

import { useAlert } from '@lemonbrain/hooks'

export default function AssociationTypeTableBusiness({
  isLoading,
  associationTypes,
  fetchMoreAssociationTypeEntries,
  refetchAssociationTypeEntries,
  goToAssociationTypeEntry,
  removeAssociationTypeEntry,
  queryError,
}: {
  isLoading: boolean
  associationTypes: {
    id: string
    data: RowDataT
  }[]
  fetchMoreAssociationTypeEntries: () => void
  refetchAssociationTypeEntries: (variables?: Partial<Exact<{
    cursor: StartCursorInput;
}>> | undefined) => Promise<ApolloQueryResult<GetAssociationTypesNoPersonQuery>>
  goToAssociationTypeEntry: (id: string) => void
  removeAssociationTypeEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeAssociationTypeEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.associationTypeTable.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        await refetchAssociationTypeEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.associationTypeTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.associationTypeTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.associationTypeTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.associationTypeTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<AssociationTypeTable
      isLoading={currentIsLoading}
      rows={associationTypes}
      fetchMoreListItems={fetchMoreAssociationTypeEntries}
      editRow={goToAssociationTypeEntry}
      removeRow={removeRow}
    />
  )
}
