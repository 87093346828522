import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { required, verifyNumber } from '@lemonbrain/utils'

import Address from './Address'

export default function AddressBusiness({
  types,
  setAddress,
  address,
  loadingAddressType,
  getHasAddressErrors,
  isFirst,
}: {
  types: {
    id: string
    isFirm: boolean
    label: string
  }[]
  setAddress: (address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }) => void
  address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      isFirm?: boolean
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }
  loadingAddressType: boolean
  getHasAddressErrors: (
    errors: {
      salutation: string
      firstName: string
      lastName: string
      firmName: string
      streetNr: string
      postCode: string
      place: string
      canton: string
      country: string
  }) => void
  isFirst: boolean
}) {
  const [errors, setErrors] = useState<{
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
  }>({
    salutation: '',
    firstName: '',
    lastName: '',
    firmName: '',
    streetNr: '',
    postCode: '',
    place: '',
    canton: '',
    country: '',
  })
  const intl = useIntl()

  function checkSalutation(salutation: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (salutation && required(salutation))) {
      setErrors((prev) => ({ ...prev, salutation: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      salutation: intl.formatMessage({
        id: 'members.address.salutation.isRequired',
        defaultMessage:
          'Salutation is required',
      }),
    }))
    return true
  }

  function checkFirmName(firmName: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (firmName && required(firmName) || !address.types.isFirm)) {
      setErrors((prev) => ({ ...prev, firmName: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      firmName: intl.formatMessage({
        id: 'members.address.firmName.isRequired',
        defaultMessage:
          'Firm name is required',
      }),
    }))
    return true
  }

  function checkFirstName(firstName: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (firstName && required(firstName))) {
      setErrors((prev) => ({ ...prev, firstName: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      firstName: intl.formatMessage({
        id: 'members.address.firstName.isRequired',
        defaultMessage:
          'First name is required',
      }),
    }))
    return true
  }

  function checkLastName(lastName: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (lastName && required(lastName))) {
      setErrors((prev) => ({ ...prev, lastName: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      lastName: intl.formatMessage({
        id: 'members.address.lastName.isRequired',
        defaultMessage:
          'Last name is required',
      }),
    }))
    return true
  }

  function checkStreetNr(streetNr: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (streetNr && required(streetNr))) {
      setErrors((prev) => ({ ...prev, streetNr: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      streetNr: intl.formatMessage({
        id: 'members.address.streetNr.isRequired',
        defaultMessage:
          'Street Nr is required',
      }),
    }))
    return true
  }

  function checkPostCode(postCode: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (postCode && required(postCode) && verifyNumber(postCode))) {
      setErrors((prev) => ({ ...prev, postCode: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      postCode: intl.formatMessage({
        id: 'members.address.postCode.isRequired',
        defaultMessage:
          'Post code is required and needs to be a number',
      }),
    }))
    return true
  }

  function checkPlace(place: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (place && required(place))) {
      setErrors((prev) => ({ ...prev, place: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      place: intl.formatMessage({
        id: 'members.address.place.isRequired',
        defaultMessage:
          'Place is required',
      }),
    }))
    return true
  }

  function checkCanton(canton: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (canton && required(canton))) {
      setErrors((prev) => ({ ...prev, canton: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      canton: intl.formatMessage({
        id: 'members.address.canton.isRequired',
        defaultMessage:
          'Canton is required',
      }),
    }))
    return true
  }

  function checkCountry(country: string | null | undefined) {
    if ((!isFirst && isEmpty()) || (country && required(country))) {
      setErrors((prev) => ({ ...prev, country: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      country: intl.formatMessage({
        id: 'members.address.country.isRequired',
        defaultMessage:
          'Country is required',
      }),
    }))
    return true
  }

  function isEmpty() {
    return (
      address.salutation === '' &&
      address.firstName === '' &&
      address.lastName === '' &&
      address.firmName === '' &&
      address.mailbox === '' &&
      address.streetNr === '' &&
      address.place === '' &&
      address.postCode === ''
    )
  }

  useEffect(() => {
    checkSalutation(address?.salutation)
    checkFirstName(address?.firstName)
    checkLastName(address?.lastName)
    checkFirmName(address?.firmName)
    checkStreetNr(address?.streetNr)
    checkPostCode(address?.postCode)
    checkPlace(address?.place)
    checkCanton(address?.canton)
    checkCountry(address?.country)
  }, [address])

  useEffect(() => {
    getHasAddressErrors(errors)
  }, [errors])

  return (
    <Address
      types={types}
      address={address}
      setAddress={setAddress}
      loadingAddressType={loadingAddressType}
      errors={errors}
    />
  )
}
