import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'

import { useAlert } from '@lemonbrain/hooks'

import getReactApolloCients from '../../../../localReactApollo'
import {
  useRenderNewsletterMailingMutation,
  useSendNewsletterMailingMutation,
  NewsletterMailingFragmentFragment,
} from '../../../../gql/NewsletterMailing.generated'
import NewsletterSendBusiness from './NewsletterSend.business'
import * as Types from '../../../../types'
import { MailingState } from '../../../../types'

export default function NewsletterSendData({
  newsletterId,
}: {
  newsletterId: string
}) {
  const [newsletterMailing, setnewsletterMailing] = useState<
    Pick<Types.NewsletterMailing, 'htmlContent' | 'sendProcessId'> &
      NewsletterMailingFragmentFragment
  >()
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  const [
    renderNewsletterMailingMutation,
    { data: renderData, loading: renderLoading, error: renderError },
  ] = useRenderNewsletterMailingMutation({
    variables: {
      id: newsletterId,
    },
    //@ts-ignore
    client,
  })

  const [
    sendMailingMutation,
    {
      data: dataSendMailing,
      error: errorSendMailing,
    },
  ] = useSendNewsletterMailingMutation({
    variables: {
      id: newsletterId,
    },
    //@ts-ignore
    client,
  })

  function sendMailing() {
    setLoading(true)
    sendMailingMutation()
  }

  useEffect(() => {
    if (dataSendMailing?.sendNewsletterMailing) {
      setnewsletterMailing(dataSendMailing.sendNewsletterMailing.item)

      if (
        dataSendMailing.sendNewsletterMailing.item.state ===
          MailingState.Running ||
        dataSendMailing.sendNewsletterMailing.item.state ===
          MailingState.Sleeping ||
        dataSendMailing.sendNewsletterMailing.item.state ===
          MailingState.Pending ||
          dataSendMailing.sendNewsletterMailing.item.sendProcessId
      ) {
        setTimeout(() => {
          sendMailing()
        }, 1000)
      } else {
        setLoading(false)
      }
    }
  }, [dataSendMailing])

  useEffect(() => {
    if (renderData?.renderNewsletterMailing) {
      setnewsletterMailing(renderData.renderNewsletterMailing)

      if(renderData?.renderNewsletterMailing?.sendProcessId && !loading) {
        sendMailing()
      }
    }
  }, [renderData])

  useEffect(() => {
    if (isOpen && newsletterId) {
      renderNewsletterMailingMutation()
    }
  }, [isOpen])

  useEffect(() => {
    if (renderError || errorSendMailing) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'newsletters.newsletterSend.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'newsletters.newsletterSend.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(renderError?.message || errorSendMailing?.message)
    }
  }, [renderError, errorSendMailing])

  return renderLoading ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <NewsletterSendBusiness
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      sendMailing={sendMailing}
      loading={loading}
      newsletterMailing={newsletterMailing}
    />
  )
}
