import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { required } from '@lemonbrain/utils'

import State from './State'

export default function StateBusiness({
  state,
  setState,
  stateType,
  setStateType,
  membership,
  setMembership,
  loadingStates,
  states,
  memberships,
  getHasStateErrors,
}: {
  state?: string
  setState: React.Dispatch<React.SetStateAction<string>>
  membership?: string
  setMembership: React.Dispatch<React.SetStateAction<string>>
  stateType?: string
  setStateType: React.Dispatch<React.SetStateAction<string>>
  loadingStates: boolean
  states: {
    id: string
    name: string
    types:
      | {
          id: string
          name: string
          requireMembership: boolean
        }[]
      | null
  }[]
  memberships: {
    id: string
    name: string
  }[]
  getHasStateErrors: (errors: { state: string; stateType: string; membership: string }) => void
}) {
  const [errors, setErrors] = useState<{
    state: string
    stateType: string
    membership: string
  }>({
    state: '',
    stateType: '',
    membership: '',
  })
  const intl = useIntl()
  const fullStateType:
    | {
        id: string
        label: string
        requireMembership: boolean
      }[]
    | null = states
    .filter((item) => item.id === state)
    .map(
      (item) =>
        item.types &&
        item.types
          .filter((item) => item.id === stateType)
          .map((item) => ({
            label: item.name,
            id: item.id,
            requireMembership: item.requireMembership,
          }))
    )[0]

  function checkState() {
    if (required(state)) {
      setErrors((prev) => ({ ...prev, state: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      state: intl.formatMessage({
        id: 'members.state.isRequired',
        defaultMessage: 'State is required',
      }),
    }))
    return true
  }

  function checkStateType() {
    if (states.find((item) => item.id === state)?.types?.length) {
      if (required(stateType)) {
        setErrors((prev) => ({
          ...prev,
          stateType: '',
        }))
        return false
      }
      setErrors((prev) => ({
        ...prev,
        stateType: intl.formatMessage({
          id: 'members.state.stateTypeisRequired',
          defaultMessage: 'Reason is required',
        }),
      }))
      return true
    }
    setStateType('')
    setErrors((prev) => ({
      ...prev,
      stateType: '',
    }))
    return false
  }

  function checkMembership() {
    if (fullStateType && fullStateType[0]?.requireMembership) {
      if (required(membership)) {
        setErrors((prev) => ({
          ...prev,
          membership: '',
        }))
        return false
      }
      setErrors((prev) => ({
        ...prev,
        membership: intl.formatMessage({
          id: 'members.state.membershipisRequired',
          defaultMessage: 'Membership is required',
        }),
      }))
      return true
    } else {
      setMembership('')
    }
    return false
  }

  useEffect(() => {
    checkState()
    checkStateType()
    checkMembership()
  }, [state, stateType, membership])

  useEffect(() => {
    getHasStateErrors(errors)
  }, [errors])

  return (
    <State
      state={state}
      setState={setState}
      membership={membership}
      setMembership={setMembership}
      stateType={stateType}
      setStateType={setStateType}
      loadingStates={loadingStates}
      states={states}
      memberships={memberships}
      errors={errors}
    />
  )
}
