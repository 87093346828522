import { useParams } from "react-router-dom"

import CheckValidationEmailBusiness from './CheckValidationEmail.business'

import { getApolloClientAuth } from '../../react-apollo'
import { useSendValidationEmailMutation } from '../../gql/Auth.generated'

export default function CheckValidationEmailData () {
    const { uName } = useParams<{ uName: string }>()
    const { language } = useParams<{ language: string }>()
    const clientAuth = getApolloClientAuth()

    const [sendValidationEmailMutation] = useSendValidationEmailMutation({
        client: clientAuth,
    })

    async function sendValidationEmail (reCaptchaToken: string) {
        return new Promise<"is-valid">(async (resolve, reject) => {
            const validationUrl = `${window.location.host}/${language}/validate-email`
            try {
                const result = await sendValidationEmailMutation({ variables: { uName: uName || '', reCaptchaToken, validationUrl } })

                if (result.data?.sendValidationEmail) {
                    resolve('is-valid')
                } else {
                    reject()
                }
            } catch (e) {
                reject(e)
            }
            reject()
        })
    }

    return (
        <CheckValidationEmailBusiness
            sendValidationEmail={sendValidationEmail}
        />
    )
}
