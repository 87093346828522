import { useIntl } from 'react-intl'
import { Button } from 'baseui/button'
import { ArrowDown } from 'baseui/icon'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'

export default function Export({
  loading,
  onClick,
  selectedInvoices,
}: {
  loading: boolean
  onClick: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => any
  selectedInvoices: string[]
}) {
  const intl = useIntl()

  return selectedInvoices.length > 0 && (
    <StatefulTooltip
      placement={PLACEMENT.right}
      content={intl.formatMessage({
        id: 'invoices.export.exportInvoices',
        defaultMessage: 'Export invoices',
      })}
      overrides={{
        Body: {
          style: () => ({
            zIndex: 99999,
          }),
        },
      }}
    >
      <Button isLoading={loading} onClick={onClick}>
        <ArrowDown
          title={intl.formatMessage({
            id: 'invoices.export.exportInvoices',
            defaultMessage: 'Export invoices',
          })}
          size={24}
        />
      </Button>
    </StatefulTooltip>
  ) || <></>
}
