import InvoicesMy from './InvoicesMy'

import { InnerRoute, useLocalStorage } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'
import { PaymentState } from './InvoicesMy.data'

export default function InvoicesMyBusiness({
  subNav,
  goToInvoice,
  invoiceId,
  paymentState,
  language,
}: {
  subNav: InnerRoute[]
  goToInvoice: (id: string | null) => void
  invoiceId: string | undefined
  paymentState: PaymentState | undefined
  language: string
}) {
  const { user } = useAppState()
  const [searchQuery, setSearchQuery] = useLocalStorage<string>('searchQuery', '')
  const [selectedInvoices, setSelectedInvoices] = useLocalStorage<string[]>(
    'selectedInvoices', []
  )
  const [payedFilter, setPayedFilter] = useLocalStorage<string[] | null>(
    'payedFilter'
  )

  return (
    <InvoicesMy
      subNav={subNav}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      user={user}
      selectedInvoices={selectedInvoices}
      setSelectedInvoices={setSelectedInvoices}
      goToInvoice={goToInvoice}
      invoiceId={invoiceId}
      paymentState={paymentState}
      language={language}
      payedFilter={payedFilter}
      setPayedFilter={setPayedFilter}
    />
  )
}
