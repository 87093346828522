import { FormattedMessage, useIntl } from 'react-intl'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Textarea } from "baseui/textarea";
import { Notification, KIND } from 'baseui/notification'

import EditContainer, {
  STATE,
} from '@lemonbrain/components/UiElements/EditContainer/EditContainer'
import { useAppState } from '@lemonbrain/contexts/PageWrapper'

import {
  AddInvoiceMutationVariables,
} from '../../gql/invoices/Invoices.generated'

export default function InvoicesEdit({
  goToInvoice,
  isLoading,
  onCreateClick,
  onCancelClick,
  invoice,
  setInvoice,
  errors,
}: {
  goToInvoice: (id: string | null) => void
  isLoading: boolean
  onCreateClick: () => void
  onCancelClick: () => void
  invoice: AddInvoiceMutationVariables & {
    costText: string,
    person?: {
      id: string
      label?: string
    }
  }
  setInvoice: React.Dispatch<React.SetStateAction<AddInvoiceMutationVariables & {
    costText: string,
    person?: {
      id: string
      label?: string
    }
  }>>
  errors: {
    personId: string
    title: string
    itemText: string
    cost: string
  }
}) {
  const intl = useIntl()
  const { components } = useAppState()
  const MemberSelectorData = components && components['MemberSelectorData']

  return (
    <>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
        <Button
          onClick={() => goToInvoice(null)}
          startEnhancer={() => <ArrowLeft size={24} />}
        >
          <FormattedMessage
            id='invoices.invoicesEdit.toOverview'
            defaultMessage={'To the overview'}
          />
        </Button>
      </Block>
      <EditContainer
        state={STATE.CREATE}
        onUpdateClick={onCreateClick}
        onCreateClick={onCreateClick}
        onCancelClick={onCancelClick}
        isLoading={isLoading}
        noUpdate={true}
        noCopy={true}
      >
        <FormControl
          label={intl.formatMessage({
            id: 'invoices.invoicesEdit.person',
            defaultMessage: 'Person',
          })}
          error={errors.personId}
        >
        {MemberSelectorData && (
          //@ts-ignores
          <MemberSelectorData
            //@ts-ignore
            value={invoice.person}
            setValue={(person: {
              id: string
              label?: string | undefined
            }) => setInvoice((prev) => ({
              ...prev,
              person,
            }))} />
        )}
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'invoices.invoicesEdit.title',
            defaultMessage: 'Title',
          })}
          error={errors.title}
        >
          <Input
            value={invoice.title || ''}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                title: (e.target as HTMLInputElement).value
              }))
            }
            positive={errors.title === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'invoices.invoicesEdit.itemText',
            defaultMessage: 'Item Text',
          })}
          error={errors.itemText}
        >
          <Textarea
            value={invoice.itemText || ''}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                itemText: (e.target as HTMLTextAreaElement).value
              }))
            }
            positive={errors.itemText === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'invoices.invoicesEdit.cost',
            defaultMessage: 'Cost',
          })}
          error={errors.cost}
        >
          <Input
            value={invoice.costText || ''}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                costText: (e.target as HTMLInputElement).value
              }))
            }
            positive={errors.cost === ''}
          />
        </FormControl>
      </EditContainer>
      {Object.values(errors).find((item) => item !== '') && (
        <Notification kind={KIND.negative}>
          <ul>
            {Object.keys(errors).map(
              (item) =>
                (errors as { [key: string]: string })[item] && (
                  <li key={item}>
                    {(errors as { [key: string]: string })[item]}
                  </li>
                )
            )}
          </ul>
        </Notification>
      )}
    </>
  )
}
