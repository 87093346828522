import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import StateTypeTable, { RowDataT } from './StateTypeTable'

import {
    useAlert,
    useInfiniteScroll,
} from '@lemonbrain/hooks'


export default function StateTypeTableBusiness({
    isLoading,
    stateTypes,
    fetchMoreStateTypeEntries,
    removeStateTypeEntry,
    refetchStateTypeEntries,
    goToStateTypeEntry,
}: {
    isLoading: boolean
    stateTypes: {
        id: string
        data: RowDataT
    }[]
    fetchMoreStateTypeEntries: () => void
    removeStateTypeEntry: (id: string) => void
    refetchStateTypeEntries: () => void
    goToStateTypeEntry: (id: string) => void
}) {
    const setAlert = useAlert()
    const intl = useIntl()
    const { isFetching, setIsFetching } = useInfiniteScroll(fetchMoreStateTypeEntries)

    async function removeRow(id: string) {
        try {
            await removeStateTypeEntry(id)
            setAlert &&
                setAlert({
                    title: intl.formatMessage({
                        id: 'settings.stateTypeTable.removeRow.success',
                        defaultMessage: 'Entry removed',
                    }),
                    style: 'success',
                })
            refetchStateTypeEntries()
        } catch (e) {
            setAlert &&
                setAlert({
                    title: intl.formatMessage({
                        id: 'settings.stateTypeTable.removeRow.error',
                        defaultMessage: 'Error',
                    }),
                    label: intl.formatMessage({
                        id: 'settings.stateTypeTable.removeRow.error.notRemoved',
                        defaultMessage:
                            'We could not remove the entry. Please try again',
                    }),
                    style: 'error',
                })
        }
    }

    useEffect(() => {
        if (!isLoading && isFetching) {
            setIsFetching(false)
        }
    }, [isFetching, isLoading])

    return (<StateTypeTable
        rows={stateTypes}
        editRow={goToStateTypeEntry}
        removeRow={removeRow}
        isLoading={isLoading}
    />
    )
}
