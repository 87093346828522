import { useIntl } from 'react-intl'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Block } from 'baseui/block'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { FileUploader } from 'baseui/file-uploader'
import { Avatar } from 'baseui/avatar'

import {
  UpdateAssociationIfMemberMutationVariables,
} from '../../../../gql/Association.generated'

export default function Association({
  values,
  setValues,
  errors,
  isLoading,
  setRejectedFiles
}: {
  values: UpdateAssociationIfMemberMutationVariables & {
    prizeString: string
    imageSrc: string
  }
  setValues: React.Dispatch<
    React.SetStateAction<UpdateAssociationIfMemberMutationVariables & {prizeString: string, imageSrc: string}>
  >
  errors: {
    deName: string,
    frName: string,
    itName: string,
    deShort: string,
    frShort: string,
    itShort: string,
    associationURL: string,
  }
  isLoading: boolean,
  setRejectedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>
}) {
  const intl = useIntl()

  return (
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'associations.associationEdit.de.name',
              defaultMessage: 'Label (German)',
            })}
            error={errors.deName}
          >
            <Input
              value={(values && values.name.de) || ''}
              //@ts-ignore
              onChange={(de) =>
                setValues((prev) => ({
                  ...prev,
                  name: {
                    ...prev.name,
                    de: (de.target as HTMLInputElement).value,
                  },
                }))
              }
              positive={errors.deName === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'associations.associationEdit.fr.name',
              defaultMessage: 'Label (French)',
            })}
            error={errors.frName}
          >
            <Input
              value={(values && values.name.fr) || ''}
              //@ts-ignore
              onChange={(fr) =>
                setValues((prev) => ({
                  ...prev,
                  name: {
                    ...prev.name,
                    fr: (fr.target as HTMLInputElement).value,
                  },
                }))
              }
              positive={errors.frName === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'associations.associationEdit.it.name',
              defaultMessage: 'Label (Italian)',
            })}
            error={errors.itName}
          >
            <Input
              value={(values && values.name.it) || ''}
              //@ts-ignore
              onChange={(it) =>
                setValues((prev) => ({
                  ...prev,
                  name: {
                    ...prev.name,
                    it: (it.target as HTMLInputElement).value,
                  },
                }))
              }
              positive={errors.itName === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'associations.associationEdit.de.short',
              defaultMessage: 'Abbreviation (German)',
            })}
            error={errors.deShort}
          >
            <Input
              value={(values && values.short.de) || ''}
              //@ts-ignore
              onChange={(de) =>
                setValues((prev) => ({
                  ...prev,
                  short: {
                    ...prev.short,
                    de: (de.target as HTMLInputElement).value,
                  },
                }))
              }
              positive={errors.deShort === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'associations.associationEdit.fr.short',
              defaultMessage: 'Abbreviation (French)',
            })}
            error={errors.frShort}
          >
            <Input
              value={(values && values.short.fr) || ''}
              //@ts-ignore
              onChange={(fr) =>
                setValues((prev) => ({
                  ...prev,
                  short: {
                    ...prev.short,
                    fr: (fr.target as HTMLInputElement).value,
                  },
                }))
              }
              positive={errors.frShort === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'associations.associationEdit.it.short',
              defaultMessage: 'Abreviation (Italian)',
            })}
            error={errors.itShort}
          >
            <Input
              value={(values && values.short.it) || ''}
              //@ts-ignore
              onChange={(it) =>
                setValues((prev) => ({
                  ...prev,
                  short: {
                    ...prev.short,
                    it: (it.target as HTMLInputElement).value,
                  },
                }))
              }
              positive={errors.itShort === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'associations.associationEdit.associationURL',
              defaultMessage: 'Association URL',
            })}
            error={errors.associationURL}
          >
            <Input
              value={(values && values.associationURL) || ''}
              //@ts-ignore
              onChange={(associationURL) =>
                setValues((prev) => ({
                  ...prev,
                  associationURL: (associationURL.target as HTMLInputElement).value,
                }))
              }
              positive={errors.associationURL === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FileUploader
            accept={'.jpg, .png'}
            onDrop={(acceptedFiles, rejectedFiles) => {
              if (acceptedFiles.length > 0) {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(acceptedFiles[0])
                fileReader.addEventListener('load', function () {
                  setValues((prev) => ({
                    ...prev,
                    image: acceptedFiles[0],
                    imageSrc: this.result?.toString() || '',
                  }))
                })
              }
              setRejectedFiles(rejectedFiles)
            }}
            progressMessage={isLoading ? `Uploading...` : ''}
            overrides={{
              ContentMessage: {
                component: () => (
                  <span>
                    {intl.formatMessage({
                      id: 'associations.associationEdit.dropFilesHere',
                      defaultMessage: 'Drop file here to upload...',
                    })}
                  </span>
                ),
              },
            }}
          />
        </Cell>
        <Cell span={[12, 6, 4]}>
          {values.imageSrc && (
            <Avatar
              overrides={{
                Avatar: {
                  style: ({ $theme }) => ({
                    borderTopLeftRadius: $theme.borders.radius100,
                    borderTopRightRadius: $theme.borders.radius100,
                    borderBottomRightRadius: $theme.borders.radius100,
                    borderBottomLeftRadius: $theme.borders.radius100,
                  }),
                },
                Root: {
                  style: ({ $theme }) => ({
                    borderTopLeftRadius: $theme.borders.radius100,
                    borderTopRightRadius: $theme.borders.radius100,
                    borderBottomRightRadius: $theme.borders.radius100,
                    borderBottomLeftRadius: $theme.borders.radius100,
                  }),
                },
              }}
              size='140px'
              src={values.imageSrc}
            />
          )}
        </Cell>
        <Cell span={[12, 12, 12]}>
          <Checkbox
            checked={(values && values.isStufenvereinigung) || false}
            onChange={(e) =>
              {setValues((prev) => ({
                ...prev,
                isStufenvereinigung: (e.target as HTMLInputElement).checked,
              }))}
            }
            labelPlacement={LABEL_PLACEMENT.right}
          >
            {intl.formatMessage({
              id: 'associations.associationEdit.isStufenvereinigung',
              defaultMessage: 'is Stufenvereinigung',
            })}
          </Checkbox>
        </Cell>
        <Cell span={[12, 12, 12]}>
          <Block marginTop='30px' marginBottom='30px'>
            <hr />
          </Block>
        </Cell>

        <Cell span={[12, 6, 4]}>
          <Checkbox
            checked={(values && values.needsAComment) || false}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                needsAComment: (e.target as HTMLInputElement).checked,
              }))
            }
            labelPlacement={LABEL_PLACEMENT.right}
          >
            {intl.formatMessage({
              id: 'associations.associationEdit.needsAComment',
              defaultMessage: 'Needs a comment',
            })}
          </Checkbox>
        </Cell>
        {values.needsAComment && (
          <Cell span={[12, 6, 8]}>
            <FormControl
              label={intl.formatMessage({
                id: 'associations.associationEdit.defaultComment',
                defaultMessage: 'Default Comment',
              })}
            >
              <Input
                value={(values && values.defaultComment) || ''}
                //@ts-ignore
                onChange={(defaultComment) =>
                  setValues((prev) => ({
                    ...prev,
                    defaultComment: (defaultComment.target as HTMLInputElement)
                      .value,
                  }))
                }
              />
            </FormControl>
          </Cell>
        )}
      </Grid>
  )
}
