import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { required } from '@lemonbrain/utils'
import { useAppState } from '@lemonbrain/contexts'

import Association from './Association'
import { ArrayTasks } from '../../../../gql/members/types'

export default function WorkLevelBusiness({
  associations,
  setAssociations,
  associationPayTo,
  setAssociationPayTo,
  loadingAssociations,
  associationList,
  associationTypes,
  associationComment,
  setAssociationComment,
  getHasAssociationErrors,
  memberExtend,
}: {
  associations?: {
    associationId: string
    associationTypes: {
      id: string
      label: string
      task?: ArrayTasks
    }[]
  }[]
  setAssociations: React.Dispatch<
    React.SetStateAction<
      {
        associationId: string
        associationTypes: {
          id: string
          label: string
          task?: ArrayTasks
        }[]
      }[]
    >
  >
  associationPayTo: { id: string }
  setAssociationPayTo: React.Dispatch<React.SetStateAction<{ id: string }>>
  loadingAssociations: boolean
  associationList: {
    id: string
    needsAComment: boolean
    prize: number
    label: string
  }[]
  associationTypes: {
    id: string
    label: string
    isDefaultType: boolean
  }[]
  associationComment: string
  setAssociationComment: React.Dispatch<React.SetStateAction<string>>
  getHasAssociationErrors: (errors: {
    association: string
    associationPayTo: string
  }) => void
  memberExtend: {
    id: string;
    prize?: number | undefined;
    directInvoicing: boolean;
}
}) {
  const [errors, setErrors] = useState<{
    association: string
    associationComment: string
    associationPayTo: string
  }>({
    association: '',
    associationComment: '',
    associationPayTo: '',
  })
  const intl = useIntl()
  const { user } = useAppState()

  function checkAssociation() {
    if (associations && associations.length > 0) {
      if (
        associations.filter((item) => item.associationTypes.length > 0)
          .length === associations.length
      ) {
        setErrors((prev) => ({ ...prev, association: '' }))
        return false
      } else {
        setErrors((prev) => ({
          ...prev,
          association: intl.formatMessage({
            id: 'members.association.associationType.isRequired',
            defaultMessage: 'Association type is required',
          }),
        }))
        return true
      }
    } else if (associations?.length === 0) {
      setErrors((prev) => ({ ...prev, association: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      association: intl.formatMessage({
        id: 'members.association.isRequired',
        defaultMessage: 'Association is required',
      }),
    }))
    return true
  }

  function checkAssociationPayTo() {
    if (associations?.length === 0 || required(associationPayTo.id)) {
      setErrors((prev) => ({ ...prev, associationPayTo: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      associationPayTo: intl.formatMessage({
        id: 'members.associationPayTo.isRequired',
        defaultMessage: 'Pay to is required',
      }),
    }))
    return true
  }

  function checkAssociationComment() {
    if (associations && associations.length > 0) {
      let needsComment = false
      for (const associationElement of associations) {
        needsComment =
          needsComment ||
          associationList.find((item) => item.id === associationElement.associationId)
            ?.needsAComment ||
          false
      }
      if (!needsComment || (needsComment && required(associationComment))) {
        setErrors((prev) => ({ ...prev, associationComment: '' }))
        return false
      }
      setErrors((prev) => ({
        ...prev,
        associationComment: intl.formatMessage({
          id: 'members.association.associationComment.isRequired',
          defaultMessage: 'Association comment is required',
        }),
      }))
      return true
    } else if (!associations || associations.length === 0) {
      if (required(associationComment)) {
        setErrors((prev) => ({ ...prev, associationComment: '' }))
        return false
      }
      setErrors((prev) => ({
        ...prev,
        associationComment: intl.formatMessage({
          id: 'members.association.associationComment.isRequired1',
          defaultMessage: 'there is a comment required, why you do not select an association',
        }),
      }))
      return true
    }
    return false
  }

  useEffect(() => {
    checkAssociation()
  }, [associations])

  useEffect(() => {
    checkAssociationPayTo()
  }, [associationPayTo])

  useEffect(() => {
    checkAssociationComment()
  }, [associationComment, associations])

  useEffect(() => {
    getHasAssociationErrors(errors)
  }, [errors])

  useEffect(() => {
    if(memberExtend.prize && memberExtend.prize === 0 || memberExtend.directInvoicing) {
      setAssociationPayTo({
        id: ''
      })
    }
  }, [memberExtend])

  return (
    <Association
      associations={associations}
      setAssociations={setAssociations}
      associationPayTo={associationPayTo}
      setAssociationPayTo={setAssociationPayTo}
      loadingAssociations={loadingAssociations}
      associationList={associationList}
      associationTypes={associationTypes}
      associationComment={associationComment}
      setAssociationComment={setAssociationComment}
      errors={errors}
      user={user}
      memberExtend={memberExtend}
    />
  )
}
