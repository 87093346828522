import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';

import StateTypeEdit from './StateTypeEdit'

import { useAppState } from '@lemonbrain/contexts'
import { useAlert } from '@lemonbrain/hooks'
import { prepStringWN } from '@lemonbrain/utils'
import { STATE } from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

export default function StateTypeEditBusiness({
    stateId,
    stateTypeId,
    values,
    setValues,
    isLoading,
    createStateTypeEntry,
    updateStateTypeEntry,
    removeStateTypeId
}: {
    stateId: string
    stateTypeId: string
    values: {
        stateTypeId: string,
        de: string,
        fr: string,
        it: string
        stateId: string
        isTrialType: boolean
        requireMembership: boolean
    }
  setValues: React.Dispatch<
    React.SetStateAction<{
        stateTypeId: string,
        de: string,
        fr: string,
        it: string
        stateId: string
        isTrialType: boolean
        requireMembership: boolean
    }>
  >
  isLoading: boolean
  createStateTypeEntry: (data: {
    name: {
        de: string,
        fr: string,
        it: string
      },
      state: {
        id: string
      }
      isTrialType: boolean
      requireMembership: boolean
  }) => void
  updateStateTypeEntry: (data: {
    id: string,
    name: {
      de: string,
      fr: string,
      it: string
    },
    state: {
      id: string
    }
    isTrialType: boolean
    requireMembership: boolean
  }) => void
  removeStateTypeId: () => void
}) {
    const intl = useIntl()
    const { user } = useAppState()
    const setAlert = useAlert()
    const [deError, setDeError] = useState<string>('')
    const [frError, setFrError] = useState<string>('')
    const [itError, setItError] = useState<string>('')

    const createEntry = async ({
        name,
        isTrialType,
        requireMembership,
    }: {
        name: {
            de: string
            fr: string
            it: string
        },
        isTrialType: boolean
        requireMembership: boolean
    }) => {
        if (user && user.uID) {
        try {
            await createStateTypeEntry({
                name: {
                    de: prepStringWN(name.de) || name.de,
                    fr: prepStringWN(name.fr) || name.fr,
                    it: prepStringWN(name.it) || name.it,
                },
                state: {
                    id: stateId
                },
                isTrialType,
                requireMembership,
            })
            setAlert &&
            setAlert({
                title: intl.formatMessage({
                id: 'settings.stateTypeEdit.saved',
                defaultMessage: 'Saved',
                }),
                label: intl.formatMessage({
                id: 'settings.stateTypeEdit.saved.created',
                defaultMessage: 'Entry is created',
                }),
                style: 'success',
            })
            updateCancel()
        } catch (e) {
            console.error(e)
            setAlert &&
            setAlert({
                title: intl.formatMessage({
                id: 'settings.stateTypeEdit.error',
                defaultMessage: 'Error',
                }),
                label: intl.formatMessage({
                id: 'settings.stateTypeEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
                }),
                style: 'error',
            })
        }
        } else {
        setAlert &&
            setAlert({
            title: intl.formatMessage({
                id: 'settings.stateTypeEdit.error',
                defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
                id: 'settings.stateTypeEdit.error.notLoggedIn',
                defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
            })
        }
    }

    const updateEntry = async ({
        stateTypeId,
        de,
        fr,
        it,
        stateId,
        isTrialType,
        requireMembership
    }: {
        stateTypeId: string
        de: string
        fr: string
        it: string
        stateId: string
        isTrialType: boolean
        requireMembership: boolean
    }) => {
        if (user && user.uID && stateId && stateTypeId) {
        try {
            await updateStateTypeEntry({
                id: stateTypeId,
                name: {
                    de: prepStringWN(de) || de,
                    fr: prepStringWN(fr) || fr,
                    it: prepStringWN(it) || it,
                },
                state: {
                    id: stateId
                },
                isTrialType,
                requireMembership,
            })
            setAlert &&
            setAlert({
                title: intl.formatMessage({
                id: 'settings.stateTypeEdit.saved',
                defaultMessage: 'Saved',
                }),
                label: intl.formatMessage({
                id: 'settings.stateTypeEdit.saved.updated',
                defaultMessage: 'Entry is updated',
                }),
                style: 'success',
            })
            updateCancel()
        } catch (e) {
            console.error(e)
            setAlert &&
            setAlert({
                title: intl.formatMessage({
                id: 'settings.stateTypeEdit.error',
                defaultMessage: 'Error',
                }),
                label: intl.formatMessage({
                id: 'settings.stateTypeEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
                }),
                style: 'error',
            })
        }
        } else {
        setAlert &&
            setAlert({
            title: intl.formatMessage({
                id: 'settings.stateTypeEdit.error',
                defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
                id: 'settings.stateTypeEdit.error.notLoggedIn',
                defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
            })
        }
    }

    function updateCancel() {
        removeStateTypeId()
        setValues({
            stateTypeId: '',
            de: '',
            fr: '',
            it: '',
            stateId: '',
            isTrialType: false,
            requireMembership: false,
        })
    }

    function checkDe(
        de: string,
        setDeError: React.Dispatch<React.SetStateAction<string>>
    ) {
        if (!de || de === '') {
            setDeError(
            intl.formatMessage({
                id: 'settings.stateTypeEdit.error.deRequired',
                defaultMessage: 'Label (German) is required',
            })
            )
            return true
        }
        setDeError('')
        return false
    }
    
    function checkFr(
        fr: string,
        setFrError: React.Dispatch<React.SetStateAction<string>>
    ) {
        if (!fr || fr === '') {
            setFrError(
            intl.formatMessage({
                id: 'settings.stateTypeEdit.error.frRequired',
                defaultMessage: 'Label (French) is required',
            })
            )
            return true
        }
        setFrError('')
        return false
    }
    
    function checkIt(
        it: string,
        setItError: React.Dispatch<React.SetStateAction<string>>
    ) {
        if (!it || it === '') {
            setItError(
            intl.formatMessage({
                id: 'settings.stateTypeEdit.error.itRequired',
                defaultMessage: 'Label (Italian) is required',
            })
            )
            return true
        }
        setItError('')
        return false
    }

    function validate(
        de: string,
        setDeError: React.Dispatch<React.SetStateAction<string>>,
        fr: string,
        setFrError: React.Dispatch<React.SetStateAction<string>>,
        it: string,
        setItError: React.Dispatch<React.SetStateAction<string>>
      ) {
        let generalError = false
    
        generalError = generalError || checkDe(de, setDeError)
        generalError = generalError || checkFr(fr, setFrError)
        generalError = generalError || checkIt(it, setItError)
    
        return !generalError
      }

    function onUpdateClick() {
        if (
          values &&
          stateId &&
          stateTypeId &&
          validate(
            values.de,
            setDeError,
            values.fr,
            setFrError,
            values.it,
            setItError
          ) &&
          values.de &&
          values.fr &&
          values.it
        ) {
          updateEntry({
            stateTypeId,
            de: values.de,
            fr: values.fr,
            it: values.it,
            stateId,
            isTrialType: values.isTrialType,
            requireMembership: values.requireMembership,
          })
        }
    }


    function onCopyClick() {
        if (
        values &&
        validate(
            values.de,
            setDeError,
            values.fr,
            setFrError,
            values.it,
            setItError
        ) &&
        values.de &&
        values.fr &&
        values.it
        ) {
        createEntry({
            name: {
                de: values.de,
                fr: values.fr,
                it: values.it,
            },
            isTrialType: values.isTrialType,
            requireMembership: values.requireMembership,
        })
        }
    }

    function onCancelClick() {
        updateCancel()
    }


    function onCreateClick() {
        if (
        values &&
        validate(
            values.de,
            setDeError,
            values.fr,
            setFrError,
            values.it,
            setItError
        ) &&
        values.de &&
        values.fr &&
        values.it
        ) {
        createEntry({
            name: {
                de: values.de,
                fr: values.fr,
                it: values.it,
            },
            isTrialType: values.isTrialType,
            requireMembership: values.requireMembership,
        })
        }
    }


    useEffect(() => {
        if (values) {
        checkDe(values.de, setDeError)
        checkFr(values.fr, setFrError)
        checkIt(values.it, setItError)
        }
    }, [values])

    return (
        <StateTypeEdit
        state={stateTypeId ? STATE.UPDATE : STATE.CREATE}
        values={values}
        setValues={setValues}
        isLoading={isLoading}
        onUpdateClick={onUpdateClick}
        onCopyClick={onCopyClick}
        onCancelClick={onCancelClick}
        onCreateClick={onCreateClick}
        checkDe={checkDe}
        checkFr={checkFr}
        checkIt={checkIt}
        deError={deError}
        frError={frError}
        itError={itError}
        setDeError={setDeError}
        setFrError={setFrError}
        setItError={setItError}
        />
    )
}
