import { useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { debounce } from '@lemonbrain/utils'
import SearchFilters, { ModalStatesBasic } from '@lemonbrain/components/UiElements/SearchFilters/SearchFilters'

import AssociationFilterData from './CheckboxFilters/AssociationFilter/AssociationFilter.data'
import WorkLevelFilterData from './CheckboxFilters/WorkLevelFilter/WorkLevelFilter.data'
import SportsEducationFilterData from './CheckboxFilters/SortsEducationFilter/SportsEducationFilter.data'
import StateFilterData from './StateFilter/StateFilter.data'

export enum ModalStates {
  state = 'state',
  association = 'association',
  workLevel = 'workLevel',
  sportsEducation = 'sportsEducation',
}

export default function MembersFilterBusiness({
  searchQuery,
  setSearchQuery,
  associationFilter,
  setAssociationFilter,
  workLevelFilter,
  setWorkLevelFilter,
  sportsEducationFilter,
  setSportsEducationFilter,
  stateFilter,
  setStateFilter,
  stateTypeFilter,
  setStateTypeFilter,
  membershipFilter,
  setMembershipFilter,
}: {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  associationFilter: string[] | null
  setAssociationFilter: React.Dispatch<React.SetStateAction<string[] | null>>
  workLevelFilter: string[] | null
  setWorkLevelFilter: React.Dispatch<React.SetStateAction<string[] | null>>
  sportsEducationFilter: string[] | null
  setSportsEducationFilter: React.Dispatch<
    React.SetStateAction<string[] | null>
  >
  stateFilter: string | null
  setStateFilter: React.Dispatch<
    React.SetStateAction<string | null>
  >
  stateTypeFilter: string | null
  setStateTypeFilter: React.Dispatch<
    React.SetStateAction<string | null>
  >
  membershipFilter: string | null
  setMembershipFilter: React.Dispatch<React.SetStateAction<string | null>>
}) {
  const intl = useIntl()
  const optionValues = [
    {
      id: ModalStates.state,
      label: getMessage(ModalStates.state),
    },
    {
      id: ModalStates.association,
      label: getMessage(ModalStates.association),
    },
    {
      id: ModalStates.workLevel,
      label: getMessage(ModalStates.workLevel),
    },
    {
      id: ModalStates.sportsEducation,
      label: getMessage(ModalStates.sportsEducation),
    },
  ]
  const [modalState, setModalState] = useState<ModalStates | ModalStatesBasic>(
    ModalStatesBasic.empty
  )
  const [options, setOptions] = useState(
    optionValues.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0))
  )
  const [values, setValues] = useState<ModalStates[]>(() => {
    let initValues = []
    if (stateFilter) {
      initValues.push(ModalStates.state)
    }
    if (associationFilter) {
      initValues.push(ModalStates.association)
    }
    if (workLevelFilter) {
      initValues.push(ModalStates.workLevel)
    }
    if (sportsEducationFilter) {
      initValues.push(ModalStates.sportsEducation)
    }
    return initValues
  })
  const [query, setQuery] = useState(searchQuery)
  const [currentAssociationFilter, setCurrentAssociationFilter] = useState(
    associationFilter
  )
  const [
    currentWorkLevelFilterworkLevelFilter,
    setCurrentWorkLevelFilterworkLevelFilter,
  ] = useState(workLevelFilter)
  const [
    currentSportsEducationFilter,
    setCurrentSportsEducationFilter,
  ] = useState(sportsEducationFilter)
  const [
    currentStateFilter,
    setCurrentStateFilter,
  ] = useState(stateFilter)
  const [
    currentStateTypeFilter,
    setCurrentStateTypeFilter,
  ] = useState(stateTypeFilter)
  const [
    currentMembershipFilter,
    setCurrentMembershipFilter,
  ] = useState(membershipFilter)
  const debouncedSearch = useCallback(debounce(setSearchQuery, 500), [])
  const debouncedAssociationFilter = useCallback(
    debounce(setAssociationFilter, 500),
    []
  )
  const debouncedWorkLevelFilter = useCallback(
    debounce(setWorkLevelFilter, 500),
    []
  )
  const debouncedSportsEducationFilter = useCallback(
    debounce(setSportsEducationFilter, 500),
    []
  )
  const debouncedStateFilter = useCallback(
    debounce(setStateFilter, 500),
    []
  )
  const debouncedStateTypeFilter = useCallback(
    debounce(setStateTypeFilter, 500),
    []
  )
  const debouncedMembershipFilter = useCallback(
    debounce(setMembershipFilter, 500),
    []
  )

  useEffect(() => {
    debouncedSearch(query)
  }, [query])

  useEffect(() => {
    debouncedAssociationFilter(currentAssociationFilter)
  }, [currentAssociationFilter])

  useEffect(() => {
    debouncedWorkLevelFilter(currentWorkLevelFilterworkLevelFilter)
  }, [currentWorkLevelFilterworkLevelFilter])

  useEffect(() => {
    debouncedSportsEducationFilter(currentSportsEducationFilter)
  }, [currentSportsEducationFilter])

  useEffect(() => {
    debouncedStateFilter(currentStateFilter)
  }, [currentStateFilter])

  useEffect(() => {
    debouncedStateTypeFilter(currentStateTypeFilter)
  }, [currentStateTypeFilter])

  useEffect(() => {
    debouncedMembershipFilter(currentMembershipFilter)
  }, [currentMembershipFilter])

  function addFilter(newValue: ModalStates[]) {
    const diff = newValue.filter((x) => !values.includes(x))
    if (diff && diff.length > 0) {
      const newV = diff[0].toString() as ModalStates
      setModalState(newV)
      setOptions((prev) => prev.filter((item) => item.id !== newV))
    }

    setValues((prev) => [...prev, ...newValue])
  }

  function removeFilter(oldValue: ModalStates) {
    switch (oldValue) {
      case ModalStates.state:
        setStateFilter(null)
        setStateTypeFilter(null)
        setMembershipFilter(null)
        break
      case ModalStates.association:
        setAssociationFilter(null)
        break
      case ModalStates.workLevel:
        setWorkLevelFilter(null)
        break
      case ModalStates.sportsEducation:
        setSportsEducationFilter(null)
        break
    }
    setValues((prev) => prev.filter((item) => item !== oldValue))
    setOptions((prev) =>
      [
        ...prev,
        optionValues.filter((item) => item.id === oldValue.toString())[0],
      ].sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0))
    )
  }

  function editFilter(value: ModalStates) {
    setModalState(value)
  }

  function getMessage(modalState: ModalStates) {
    switch (modalState) {
      case ModalStates.state:
        return intl.formatMessage({
          id: 'members.members_filter.state',
          defaultMessage: 'State',
        })
      case ModalStates.association:
        return intl.formatMessage({
          id: 'members.members_filter.association',
          defaultMessage: 'Association',
        })
      case ModalStates.workLevel:
        return intl.formatMessage({
          id: 'members.members_filter.workLevel',
          defaultMessage: 'Work level',
        })
      case ModalStates.sportsEducation:
        return intl.formatMessage({
          id: 'members.members_filter.sportsEducation',
          defaultMessage: 'Sports education',
        })
      default:
        return ''
    }
  }

  return (
    <SearchFilters<ModalStates>
      modalState={modalState}
      setModalState={setModalState}
      query={query}
      setQuery={setQuery}
      values={values}
      options={options}
      addFilter={addFilter}
      editFilter={editFilter}
      removeFilter={removeFilter}
      getMessage={getMessage}
      filters={[
        {
          id: ModalStates.state,
          Component: (
            <StateFilterData
              stateFilter={currentStateFilter}
              setStateFilter={setCurrentStateFilter}
              stateTypeFilter={currentStateTypeFilter}
              setStateTypeFilter={setCurrentStateTypeFilter}
              membershipFilter={currentMembershipFilter}
              setMembershipFilter={setCurrentMembershipFilter}
            />
          ),
        },
        {
          id: ModalStates.association,
          Component: (
            <AssociationFilterData
              associationFilter={currentAssociationFilter}
              setAssociationFilter={setCurrentAssociationFilter}
            />
          ),
        },
        {
          id: ModalStates.workLevel,
          Component: (
            <WorkLevelFilterData
              workLevelFilter={currentWorkLevelFilterworkLevelFilter}
              setWorkLevelFilter={setCurrentWorkLevelFilterworkLevelFilter}
            />
          ),
        },
        {
          id: ModalStates.sportsEducation,
          Component: (
            <SportsEducationFilterData
              sportsEducationFilter={currentSportsEducationFilter}
              setSportsEducationFilter={setCurrentSportsEducationFilter}
            />
          ),
        },
      ]}
    />
  )
}
