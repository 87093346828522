import Export from './Export'

export default function ExportBusiness({
  loading,
  startExport,
  selectedPerson,
}: {
  loading: boolean
  startExport: () => void
  selectedPerson: string[]
}) {
  return (
    <Export
      loading={loading}
      onClick={startExport}
      selectedPerson={selectedPerson}
    />
  )
}
