import { useParams } from "react-router-dom"
import { Block } from 'baseui/block'
import { Avatar } from 'baseui/avatar'
import { StatefulPopover } from 'baseui/popover'
import { FormattedMessage } from 'react-intl'

import {useHistoryPush} from '@lemonbrain/hooks'

import MenuWrapper, { Line, MenuItem } from './AvatarMenu.styled'

import {
  useThemeSwitcherCtx,
  THEME,
} from '@lemonbrain/contexts/theme/theme.provider'
import { useAppState } from '@lemonbrain/contexts/PageWrapper'

type AvatarMenuType = {
  name?: string
  src?: string
  placement?: any
  showOnlyMenu?: boolean
  onClick?: () => void
}

type MenuType = {
  onClick?: () => void
}

const Menu = ({ onClick }: MenuType) => {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const { theme, setTheme } = useThemeSwitcherCtx()
  const {
    mainTyedRoutesItems,
    user
  } = useAppState()
  return (
    <MenuWrapper>
      <li onClick={() => pushPath('/' + language)}>
        <MenuItem onClick={onClick}>
          <FormattedMessage
            id='homesite'
            defaultMessage='Homesite'
          />
        </MenuItem>
      </li>
      {mainTyedRoutesItems['AVATAR'].map(item => (item.visible || item.visible === undefined) && (
        <li key={`avatar-menu--item${item.id}`} onClick={() => pushPath('/' + language + '/' + item.routePath)}>
          <MenuItem onClick={onClick}>
            <FormattedMessage
              id={item.title.id}
              defaultMessage={item.title.defaultMessage}
            />
          </MenuItem>
        </li>
      ))}
      <Line></Line>
      <li>
        <MenuItem
          onClick={() => {
            let getTheme = theme === THEME.light ? THEME.dark : THEME.light
            setTheme(getTheme)
            localStorage.setItem('theme', getTheme)
          }}
        >
          {theme === THEME.light ?
            (
              <FormattedMessage
                id='darkMode'
                defaultMessage="Dark Mode"
              />
            ) : (
              <FormattedMessage
                id='lightMode'
                defaultMessage="Light Mode"
              />
            )}
        </MenuItem>
      </li>
      <Line></Line>
      <li onClick={() => pushPath(`/${language}/login`)}>
        {!user || !user.uID ? (
          <MenuItem onClick={onClick}>
            <FormattedMessage
              id='login'
              defaultMessage="Login"
            />
          </MenuItem>
        ) : (
            <MenuItem onClick={onClick}>
              <FormattedMessage
                id='logout'
                defaultMessage="Logout"
              />
            </MenuItem>
          )}
      </li>
    </MenuWrapper>
  )
}

const AvatarMenu = ({
  name,
  src,
  placement,
  showOnlyMenu,
  onClick,
}: AvatarMenuType) => {
  return (
    <>
      {showOnlyMenu ? (
        <Menu onClick={onClick} />
      ) : (
          <StatefulPopover
            content={<Menu onClick={onClick} />}
            placement={placement ? placement : 'bottomRight'}
            overrides={{
              Inner: {
                style: ({ $theme }) => {
                  return {
                    backgroundColor: $theme.colors.primaryB,
                  }
                },
              },
            }}
            showArrow
          >
            <Block overrides={{ Block: { style: { cursor: 'pointer' } } }}>
              <Avatar src={src} name={name || ''} size="scale1200" />
            </Block>
          </StatefulPopover>
        )}
    </>
  )
}

export default AvatarMenu
