import { useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'

import AssociationTypeEdit, { STATE } from './AssociationTypeEdit'

import { useAppState } from '@lemonbrain/contexts'
import { prepStringWN, required } from '@lemonbrain/utils'
import { useAlert } from '@lemonbrain/hooks'

export default function AssociationTypeEditBusiness({
  values,
  isLoading,
  removeAssociationTypeId,
  associationTypeId,
  updateAssociationTypeEntry,
  createAssociationTypeEntry,
  setValues,
}: {
  values: {
    isDefaultType: boolean
    userGroup: string
    de: string
    it: string
    fr: string
  }
  isLoading: boolean
  removeAssociationTypeId: () => void
  associationTypeId?: string | null | undefined
  updateAssociationTypeEntry: (data: {
    id: string
    isDefaultType: boolean
    userGroup: string
    de: string
    fr: string
    it: string
  }) => void
  createAssociationTypeEntry: (data: {
    isDefaultType: boolean
    userGroup: string
    de: string
    fr: string
    it: string
  }) => void
  setValues: React.Dispatch<
    React.SetStateAction<{
      isDefaultType: boolean
      userGroup: string
      de: string
      fr: string
      it: string
    }>
  >
}) {
  const intl = useIntl()
  const { user } = useAppState()
  const setAlert = useAlert()
  const [userGroupError, setUserGroupError] = useState<string>('')
  const [deError, setDeError] = useState<string>('')
  const [frError, setFrError] = useState<string>('')
  const [itError, setItError] = useState<string>('')

  const updateEntry = async () => {
    if (validate()) {
      if (user && user.uID && associationTypeId) {
        try {
          await updateAssociationTypeEntry({
            id: prepStringWN(associationTypeId) || associationTypeId,
            isDefaultType: !!values.isDefaultType,
            userGroup: prepStringWN(values.userGroup) || values.userGroup,
            de: prepStringWN(values.de) || values.de,
            fr: prepStringWN(values.fr) || values.fr,
            it: prepStringWN(values.it) || values.it,
          })
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.associationTypeEdit.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'settings.associationTypeEdit.saved.updated',
                defaultMessage: 'Entry is updated',
              }),
              style: 'success',
            })
          updateCancel()
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.associationTypeEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'settings.associationTypeEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.associationTypeEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.associationTypeEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  const createEntry = async () => {
    if (validate()) {
      if (user && user.uID) {
        try {
          await createAssociationTypeEntry({
            isDefaultType: !!values.isDefaultType,
            userGroup: prepStringWN(values.userGroup) || values.userGroup,
            de: prepStringWN(values.de) || values.de,
            fr: prepStringWN(values.fr) || values.fr,
            it: prepStringWN(values.it) || values.it,
          })
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.associationTypeEdit.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'settings.associationTypeEdit.saved.created',
                defaultMessage: 'Entry is created',
              }),
              style: 'success',
            })
          updateCancel()
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.associationTypeEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'settings.associationTypeEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.associationTypeEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.associationTypeEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  function updateCancel() {
    removeAssociationTypeId()
    setValues((prev) => ({
      ...prev,
      isDefaultType: false,
      userGroup: '',
      de: '',
      fr: '',
      it: '',
    }))
  }

  function checkUserGroup() {
    if (required(values.userGroup)) {
      setUserGroupError('')
      return false
    }
    setUserGroupError(
      intl.formatMessage({
        id: 'settings.associationTypeEdit.error.userGroupRequired',
        defaultMessage: 'User group is required',
      })
    )
    return true
  }

  function checkDe() {
    if (required(values.de)) {
      setDeError('')
      return false
    }
    setDeError(
      intl.formatMessage({
        id: 'settings.associationTypeEdit.error.deRequired',
        defaultMessage: 'Label (German) is required',
      })
    )
    return true
  }

  function checkFr() {
    if (required(values.fr)) {
      setFrError('')
      return false
    }
    setFrError(
      intl.formatMessage({
        id: 'settings.associationTypeEdit.error.frRequired',
        defaultMessage: 'Label (French) is required',
      })
    )
    return true
  }

  function checkIt() {
    if (required(values.it)) {
      setItError('')
      return false
    }
    setItError(
      intl.formatMessage({
        id: 'settings.associationTypeEdit.error.itRequired',
        defaultMessage: 'Label (Italian) is required',
      })
    )
    return true
  }

  const validate = useCallback(() => {
    let generalError = false

    generalError = generalError || checkUserGroup()
    generalError = generalError || checkDe()
    generalError = generalError || checkFr()
    generalError = generalError || checkIt()

    return !generalError
  }, [values])

  useEffect(() => {
    if (values) {
      checkUserGroup()
      checkDe()
      checkFr()
      checkIt()
    }
  }, [values])

  return (
    <AssociationTypeEdit
      state={!!associationTypeId ? STATE.UPDATE : STATE.CREATE}
      userGroupError={userGroupError}
      deError={deError}
      itError={itError}
      frError={frError}
      values={values}
      isLoading={isLoading}
      setValues={setValues}
      onUpdateClick={updateEntry}
      onCopyClick={createEntry}
      onCancelClick={updateCancel}
      onCreateClick={createEntry}
    />
  )
}
