import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { priceFormater} from '@lemonbrain/utils'

import InvoiceEditBusiness from './InvoiceEdit.business'
import getReactApolloCients from '../../../localReactApollo'
import {
  useGetInvoiceIfFromAssociationQuery,
  useUpdateInvoiceIfAssociationMutation,
  UpdateInvoiceIfAssociationMutationVariables,
} from '../../../gql/invoices/Invoices.generated'
import {
  useGetTransactionQuery
} from '../../../gql/transaction/Transaction.generated'

export default function InvoiceEditIfAssociationData({
  goToInvoice,
  invoiceId,
}: {
  goToInvoice: (id: string | null) => void
  invoiceId: string
}) {
  const intl = useIntl()
  const [invoice, setInvoice] = useState<
    | {
        id: string
        payAmount: string
        payDate: string
        total: string
        sendAt: string
      }
  >({
    id: '',
    payAmount: '',
    payDate: '',
    total: '',
    sendAt: '',
  })
  const [loading, setLoading] = useState(false)
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const {
    data: dataInvoice,
    loading: loadingInvoice,
    error: errorInvoice,
    refetch,
  } = useGetInvoiceIfFromAssociationQuery({
    variables: {
      id: invoiceId,
    },
    //@ts-ignore
    client,
  })
  const [
    updateInvoiceMutation,
    { loading: loadingUpdate, error: loadingError },
  ] = useUpdateInvoiceIfAssociationMutation({
    //@ts-ignore
    client,
  })

   const { data: dataTransaction, loading: loadingTransaction, error: errorTransaction } = useGetTransactionQuery({
     variables: {
        invoiceId
     },
     //@ts-ignore
     client,
   });

  async function updateInvoiceEntry(
    data: UpdateInvoiceIfAssociationMutationVariables
  ) {
    if (data.id && invoiceId) {
      await updateInvoiceMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    }
  }

  useEffect(() => {
    if (dataInvoice?.getInvoiceIfFromAssociation) {
      setInvoice({
        id: dataInvoice.getInvoiceIfFromAssociation.id,
        payAmount: dataInvoice.getInvoiceIfFromAssociation.payAmount ? priceFormater(dataInvoice.getInvoiceIfFromAssociation.payAmount / 100) : '0',
        payDate: dataInvoice.getInvoiceIfFromAssociation.payDate || '',
        total: dataInvoice.getInvoiceIfFromAssociation.total ? priceFormater(dataInvoice.getInvoiceIfFromAssociation.total / 100) : '0',
        sendAt: dataInvoice.getInvoiceIfFromAssociation.sendAt || '',
      })
    }
  }, [dataInvoice])

  useEffect(() => {
    setLoading(
      loadingInvoice ||
        loadingUpdate || 
        loadingTransaction
    )
  }, [loadingInvoice, loadingUpdate, loadingTransaction])

  useEffect(() => {
    if (!loadingUpdate) {
      refetch()
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (
      errorInvoice ||
      loadingError ||
      errorTransaction
    ) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.invoiceEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.invoiceEdit.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorInvoice?.message ||
          loadingError?.message ||
          errorTransaction?.message
      )
    }
  }, [errorInvoice, loadingError, errorTransaction])

  return (
    <InvoiceEditBusiness
      goToInvoice={goToInvoice}
      invoiceId={invoiceId}
      loading={loading}
      updateInvoiceEntry={updateInvoiceEntry}
      invoice={invoice}
      setInvoice={setInvoice}
      dataTransaction={dataTransaction}
    />
  )
}
