// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type NewsletterFragmentReducedFragment = (
  { __typename?: 'Newsletter' }
  & Pick<Types.Newsletter, 'id'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetNewslettersNoPersonQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetNewslettersNoPersonQuery = (
  { __typename?: 'Query' }
  & { getNewslettersNoPerson: (
    { __typename?: 'NewsletterPagination' }
    & Pick<Types.NewsletterPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Newsletter' }
      & NewsletterFragmentReducedFragment
    )> }
  ) }
);

export type GetNewsletterMailaddressesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetNewsletterMailaddressesQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getNewsletterMailaddresses'>
);

export const NewsletterFragmentReducedFragmentDoc = gql`
    fragment NewsletterFragmentReduced on Newsletter {
  id
  name {
    de
    fr
    it
  }
}
    `;
export const GetNewslettersNoPersonDocument = gql`
    query getNewslettersNoPerson($cursor: StartCursorInput!) {
  getNewslettersNoPerson(cursor: $cursor) {
    items {
      ...NewsletterFragmentReduced
    }
    nextCursor
  }
}
    ${NewsletterFragmentReducedFragmentDoc}`;

/**
 * __useGetNewslettersNoPersonQuery__
 *
 * To run a query within a React component, call `useGetNewslettersNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewslettersNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewslettersNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNewslettersNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetNewslettersNoPersonQuery, GetNewslettersNoPersonQueryVariables>) {
        return Apollo.useQuery<GetNewslettersNoPersonQuery, GetNewslettersNoPersonQueryVariables>(GetNewslettersNoPersonDocument, baseOptions);
      }
export function useGetNewslettersNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewslettersNoPersonQuery, GetNewslettersNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetNewslettersNoPersonQuery, GetNewslettersNoPersonQueryVariables>(GetNewslettersNoPersonDocument, baseOptions);
        }
export type GetNewslettersNoPersonQueryHookResult = ReturnType<typeof useGetNewslettersNoPersonQuery>;
export type GetNewslettersNoPersonLazyQueryHookResult = ReturnType<typeof useGetNewslettersNoPersonLazyQuery>;
export type GetNewslettersNoPersonQueryResult = Apollo.QueryResult<GetNewslettersNoPersonQuery, GetNewslettersNoPersonQueryVariables>;
export const GetNewsletterMailaddressesDocument = gql`
    query getNewsletterMailaddresses($id: ID!) {
  getNewsletterMailaddresses(id: $id)
}
    `;

/**
 * __useGetNewsletterMailaddressesQuery__
 *
 * To run a query within a React component, call `useGetNewsletterMailaddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterMailaddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterMailaddressesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNewsletterMailaddressesQuery(baseOptions: Apollo.QueryHookOptions<GetNewsletterMailaddressesQuery, GetNewsletterMailaddressesQueryVariables>) {
        return Apollo.useQuery<GetNewsletterMailaddressesQuery, GetNewsletterMailaddressesQueryVariables>(GetNewsletterMailaddressesDocument, baseOptions);
      }
export function useGetNewsletterMailaddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsletterMailaddressesQuery, GetNewsletterMailaddressesQueryVariables>) {
          return Apollo.useLazyQuery<GetNewsletterMailaddressesQuery, GetNewsletterMailaddressesQueryVariables>(GetNewsletterMailaddressesDocument, baseOptions);
        }
export type GetNewsletterMailaddressesQueryHookResult = ReturnType<typeof useGetNewsletterMailaddressesQuery>;
export type GetNewsletterMailaddressesLazyQueryHookResult = ReturnType<typeof useGetNewsletterMailaddressesLazyQuery>;
export type GetNewsletterMailaddressesQueryResult = Apollo.QueryResult<GetNewsletterMailaddressesQuery, GetNewsletterMailaddressesQueryVariables>;