import { useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'
import { RadioGroup, Radio, ALIGN } from 'baseui/radio'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Select, OptionsT } from 'baseui/select'

export default function State({
  state,
  setState,
  stateType,
  setStateType,
  membership,
  setMembership,
  loadingStates,
  states,
  memberships,
  errors,
}: {
  state?: string
  setState: React.Dispatch<React.SetStateAction<string>>
  membership?: string
  setMembership: React.Dispatch<React.SetStateAction<string>>
  stateType?: string
  setStateType: React.Dispatch<React.SetStateAction<string>>
  loadingStates: boolean
  states: {
    id: string
    name: string
    types:
      | {
          id: string
          name: string
          requireMembership: boolean
        }[]
      | null
  }[]
  memberships: {
    id: string
    name: string
  }[]
  errors: {
    state: string
    stateType: string
    membership: string
  }
}) {
  const intl = useIntl()
  const fullStateType:
    | {
        id: string
        label: string
        requireMembership: boolean
      }[]
    | null = states
    .filter((item) => item.id === state)
    .map(
      (item) =>
        item.types &&
        item.types
          .filter((item) => item.id === stateType)
          .map((item) => ({
            label: item.name,
            id: item.id,
            requireMembership: item.requireMembership,
          }))
    )[0]

  return (
    <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.state.state',
            defaultMessage: 'State',
          })}
          error={errors.state}
        >
          {loadingStates ? (
            <Spinner $size={SIZE.small} />
          ) : (
            <RadioGroup
              value={state}
              onChange={(e) => {
                setStateType('')
                setState((e.target as HTMLInputElement).value)
              }}
              name='state'
              align={ALIGN.vertical}
              error={errors.state !== ''}
            >
              {states.map((item) => (
                <Radio
                  key={item.id}
                  value={item.id}
                  error={errors.state !== ''}
                >
                  {item.name}
                </Radio>
              ))}
            </RadioGroup>
          )}
        </FormControl>
      </Cell>
      {states.find((item) => item.id === state)?.types?.length && (
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.state.stateType',
              defaultMessage: 'Reason',
            })}
            error={errors.stateType}
          >
            {loadingStates ? (
              <Spinner $size={SIZE.small} />
            ) : (
              <Select
                clearable={false}
                error={errors.stateType !== ''}
                positive={errors.stateType === ''}
                //@ts-ignore
                options={
                  states
                    .filter((item) => item.id === state)
                    .map(
                      (item) =>
                        item.types &&
                        item.types.map((item) => ({
                          label: item.name,
                          id: item.id,
                        }))
                    )[0] || ([] as OptionsT[])
                }
                //@ts-ignore
                value={fullStateType || ([] as OptionsT[])}
                onChange={(params) => setStateType(params.value[0]?.id?.toString() || '')
                }
              />
            )}
          </FormControl>
        </Cell>
      )}
      {fullStateType && fullStateType[0]?.requireMembership && (
        <>
          <Cell span={[12, 6, 6]}></Cell>
          <Cell span={[12, 6, 6]}>
            <FormControl
              label={intl.formatMessage({
                id: 'members.state.membership',
                defaultMessage: 'Membership',
              })}
              error={errors.membership}
            >
              {loadingStates ? (
                <Spinner $size={SIZE.small} />
              ) : (
                <Select
                  clearable={false}
                  error={errors.membership !== ''}
                  positive={errors.membership === ''}
                  options={
                    memberships.map((item) => ({
                      label: item.name,
                      id: item.id,
                    })) || ([] as OptionsT[])
                  }
                  value={
                    memberships
                      .filter((item) => item.id === membership)
                      .map((item) => ({
                        label: item.name,
                        id: item.id,
                      })) || ([] as OptionsT[])
                  }
                  onChange={(params) => setMembership(params.value[0]?.id?.toString() || '')
                  }
                />
              )}
            </FormControl>
          </Cell>
        </>
      )}
    </Grid>
  )
}
