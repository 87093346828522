import { useParams } from "react-router-dom"

import {useHistoryPush} from '@lemonbrain/hooks'

import ChangePasswordBusiness from './ChangePassword.business'

import { getApolloClientAuth } from '../../react-apollo'
import { useChangePasswordMutation } from '../../gql/Auth.generated'

export default function ChangePasswordData () {
    const { language } = useParams<{ language: string }>()
    const { token } = useParams<{ token: string }>()
    const pathPush = useHistoryPush()
    const clientAuth = getApolloClientAuth()

    const [changePasswordMutation] = useChangePasswordMutation({
        client: clientAuth,
    })

    async function changePassword (password: string, reCaptchaToken: string) {
        return new Promise<"is-valid">(async (resolve, reject) => {
            try {
                const result = await changePasswordMutation({
                    variables: {
                        password,
                        token: token || '',
                        reCaptchaToken
                    }
                })

                if (result.data?.changePassword.length === 0) {
                    resolve('is-valid')
                } else {
                    reject()
                }
            } catch (e) {
                reject(e)
            }
            reject()
        })
    }

    function goToSignIn () {
        pathPush(`/${language}/login`)
    }

    function goToForgotPassword () {
        pathPush(`/${language}/forgot-password`)
    }

    return (
        <ChangePasswordBusiness
            changePassword={changePassword}
            goToSignIn={goToSignIn}
            goToForgotPassword={goToForgotPassword}
        />
    )
}
