import { useIntl } from 'react-intl'

import { useJson2CsvExporter } from '@lemonbrain/hooks'

import ParticipantsExportData from './ParticipantsExport.data'

export default function ParticipantsExportFile({
  courseId,
}: {
  courseId: string
}) {
  const intl = useIntl()
  const setNext = useJson2CsvExporter(
    //@ts-ignore
    `${new Date().toLocaleDateString().replaceAll('.', '_')}_ParticipantsExport.csv`,
    {
      fields: [
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.number',
            defaultMessage: 'number',
          }),
          value: (row: any) => row.course.number ? row.course.number : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.title',
            defaultMessage: 'Title',
          }),
          value: (row: any) => row.course.title.de ? row.course.title.de : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.salutation',
            defaultMessage: 'Salutation',
          }),
          value: (row: any) => row.participant.addresses[0].salutation ? row.participant.addresses[0].salutation : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.lastName',
            defaultMessage: 'Last name',
          }),
          value: (row: any) => row.participant.addresses[0].lastName ? row.participant.addresses[0].lastName : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.firstName',
            defaultMessage: 'First name',
          }),
          value: (row: any) => row.participant.addresses[0].firstName ? row.participant.addresses[0].firstName : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.streetNr',
            defaultMessage: 'Street Nr',
          }),
          value: (row: any) => row.participant.addresses[0].streetNr ? row.participant.addresses[0].streetNr : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.postCode',
            defaultMessage: 'ZIP',
          }),
          value: (row: any) => row.participant.addresses[0].postCode ? row.participant.addresses[0].postCode : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.place',
            defaultMessage: 'Place',
          }),
          value: (row: any) => row.participant.addresses[0].place ? row.participant.addresses[0].place : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.telephone',
            defaultMessage: 'Telephone',
          }),
          value: (row: any) => row.participant.telephone ? row.participant.telephone : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.mobile',
            defaultMessage: 'Mobile',
          }),
          value: (row: any) => row.participant.mobile ? row.participant.mobile : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.email',
            defaultMessage: 'E-Mail',
          }),
          value: (row: any) => row.participant.email ? row.participant.email : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.membership',
            defaultMessage: 'Membership',
          }),
          value: (row: any) => row.participant.state.name.de ? row.participant.state.name.de : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.ahvNumber',
            defaultMessage: 'AHV Number',
          }),
          value: (row: any) => row.participant.ahvNumber ? row.participant.ahvNumber : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.jsNumber',
            defaultMessage: 'J+S Number',
          }),
          value: (row: any) => row.participant.jsNumber ? row.participant.jsNumber : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.birthdate',
            defaultMessage: 'Birthdate',
          }),
          value: (
            row: any,
            field: {
              label: string
              default?: string
            }
          ) => {
            let birthdate: Date | string | undefined = field.default
            if (row && row.participant.birthdate !== '') {
              birthdate = new Date(row.participant.birthdate)
            }
            return birthdate instanceof Date
              ? birthdate.toLocaleDateString()
              : birthdate
          },
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.signUpState',
            defaultMessage: 'Sign up state',
          }),
          value: (row: any) => row.signUpStatus ?
          intl.formatMessage({
            id: `courses.registrations.status.${row.signUpStatus}`,
            defaultMessage: row.signUpStatus
           }) : '',
        },
        {
          label: intl.formatMessage({
            id: 'courses.participantsExport.comment',
            defaultMessage: 'Comment',
          }),
          value: (row: any) => row.comment
        },
      ],
    }
  )

  return <ParticipantsExportData
      setNext={setNext}
      courseId={courseId}
    />
}
