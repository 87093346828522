import { useIntl } from 'react-intl'
import Container from '@lemonbrain/components/UiElements/Container/Container'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Grid, Cell } from 'baseui/layout-grid'

export default function MemberAddForm({
  values,
  setValues,
  errors
}: {
  values: {
    email: string
  }
  setValues: React.Dispatch<React.SetStateAction<{ email: string }>>
  errors: {
    email: string
  }
}) {
  const intl = useIntl()

  return (
    <Container>
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.personal.email',
              defaultMessage: 'Correspondence e-mail',
            })}
            error={errors.email}
          >
            <Input
              value={values && values.email}
              onChange={(e) =>
                setValues(
                  (prev) =>
                    prev && {
                      ...prev,
                      email: (e.target as HTMLInputElement).value,
                    }
                )
              }
              positive={errors.email === ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </Container>
  )
}
