import DisplayNameBusiness from './DisplayName.business'

import getReactApolloCients from '../../localReactApollo'
import { useGetDisplayNameQuery, useGetDisplayNameByIdQuery } from '../../gql/Auth.generated'

export default function DisplayNameData({
  username,
  userId,
}: {
  username?: string
  userId?: string
}) {
  const clientAuth = getReactApolloCients().getClient()

    const { data: usernameData, loading: usernameLoading, error: usernameError } = useGetDisplayNameQuery({
      variables: {
        username: username || '' ,
      },
      skip: !username || username==='',
      //@ts-ignore
      client: clientAuth,
    })

    const { data: idData, loading: idLoading, error: idError } = useGetDisplayNameByIdQuery({
      variables: {
        id: userId || '',
      },
      skip: !!username && username !== '',
      //@ts-ignore
      client: clientAuth,
    })

  return (
    <DisplayNameBusiness
      displayName={username && username !== '' ? usernameData?.getDisplayName : idData?.getDisplayNameById}
      isLoading={usernameLoading || idLoading}
      queryError={username && username !== '' ? usernameError : idError}
    />
  )
}
