export const courseContentCategories = [
    'dance',
    'mobingSchool',
    'blessYou',
    'outdoor',
    'polysportivesAndGames',
    'efSport',
    'water',
    'apparatusGymnastics',
    'inclusion',
  ]
  
  export function getCourseContentCategoryOptions(intl: any) {
    const courseContentOptions: {
      id: string
      label: string
    }[] = []
    courseContentCategories.forEach((courseContentCategory) => {
      courseContentOptions.push({
        id: courseContentCategory,
        label: intl.formatMessage({
          id: 'courseContentCategories.' + courseContentCategory,
          defaultMessgae: courseContentCategory,
        }),
      })
    })
    return courseContentOptions
  }
  