import { useParams } from 'react-router-dom'

import { InnerRoute } from '@lemonbrain/hooks'
import {useHistoryPush} from '@lemonbrain/hooks'

import InvoicesMyBusiness from './InvoicesMy.business'

export enum PaymentState {
  Success = 'success',
  Failed = 'failed'
}

export default function InvoicesMyData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const { invoiceId } = useParams<{ invoiceId: string | undefined }>()
  const { paymentState } = useParams<{ paymentState: PaymentState | undefined }>()

  const goToInvoice = function (id: string | null) {
    if (id && id !== '') {
      pushPath(`/${language}/invoices/my/${id}`)
    } else {
      pushPath(`/${language}/invoices/my`)
    }
  }

  return <InvoicesMyBusiness subNav={subNav} goToInvoice={goToInvoice} invoiceId={invoiceId} paymentState={paymentState} language={language || 'de'} />
}
