import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'

import InvoicesEditBusiness from './InvoicesEdit.business'
import {
  AddInvoiceMutationVariables,
  useAddInvoiceMutation
} from '../../gql/invoices/Invoices.generated'
import { useAlert, useHistoryPush } from '@lemonbrain/hooks'
import getReactApolloCients from '../../localReactApollo'

export default function InvoicesEditData({
  goToInvoice,
}: {
  goToInvoice: (id: string | null) => void
}) {
  const intl = useIntl()
  const [invoice, setInvoice] = useState<AddInvoiceMutationVariables & {
    costText: string,
    person?: {
      id: string
      label?: string
    }
  }>({
    personId: '',
    title: '',
    itemText: '',
    cost: 0,
    costText: '0.00',
  })
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  const [
    addInvoiceMutation,
    { data: dataAdd, loading: loadingAdd, error: errorAdd },
  ] = useAddInvoiceMutation({
    //@ts-ignore
    client,
  })

  async function createEntry(variables: AddInvoiceMutationVariables) {
    await addInvoiceMutation({
      variables,
      //@ts-ignore
      client,
    })
  }

  useEffect(() => {
    if (errorAdd) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.invoicesEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.invoicesEdit.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorAdd?.message
      )
    }
  }, [errorAdd])

  useEffect(() => {
    if (dataAdd?.addInvoice.id) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.invoicesEdit.created',
            defaultMessage: 'Created',
          }),
          label: intl.formatMessage({
            id: 'invoices.invoicesEdit.saved.created',
            defaultMessage: 'Entry is created',
          }),
          style: 'success',
        })
      pushPath(`/${language}/invoices/overview/${dataAdd?.addInvoice.id}`)
    }
  }, [dataAdd])

  return <InvoicesEditBusiness
    loading={loadingAdd}
    createEntry={createEntry}
    goToInvoice={goToInvoice}
    invoice={invoice}
    setInvoice={setInvoice}
  />
}
