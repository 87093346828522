import SportsEducationEditBusiness from './SportsEducationEdit.business'
import {
    useGetSportsEducationLazyQuery,
  useUpdateSportsEducationMutation,
  useAddSportsEducationMutation,
} from '../../../gql/SportsEducation.generated'
import getReactApolloCients from '../../../localReactApollo'

import { useEffect, useState } from 'react'

export default function SportsEducationEditData({
  sportsEducationId,
  setSportsEducationId,
}: {
  sportsEducationId: string | null | undefined
  setSportsEducationId: (sportsEducationId: string | undefined) => void
}) {
  const [values, setValues] = useState<{
    de: string | undefined
    fr: string | undefined
    it: string | undefined
  }>({
    de: undefined,
    fr: undefined,
    it: undefined,
  })
  const client = getReactApolloCients().getClient()
  const [
    getSportsEducationQuery,
    { data, loading, error: queryError },
  ] = useGetSportsEducationLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateSportsEducationMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateSportsEducationMutation({
    //@ts-ignore
    client,
  })
  const [
    addSportsEducationMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddSportsEducationMutation({
    //@ts-ignore
    client,
  })

  async function updateSportsEducationEntry(data: {
    sportsEducationId: string
    de: string
    fr: string
    it: string
  }) {
    if (data.sportsEducationId) {
      await updateSportsEducationMutation({
        variables: {
          id: data.sportsEducationId,
          name: {
              de: data.de,
              fr: data.fr,
              it: data.it
          }
        },
      })
    }
  }

  async function createSportsEducationEntry(data: {
    de: string
    fr: string
    it: string
  }) {
    await addSportsEducationMutation({
      variables: {
        name: {
            de: data.de,
            fr: data.fr,
            it: data.it
        }
      },
    })
  }

  function removeSportsEducationId() {
    setSportsEducationId(undefined)
  }

  useEffect(() => {
    if (sportsEducationId) {
        getSportsEducationQuery({
        variables: {
          id: sportsEducationId,
        },
      })
    }
  }, [sportsEducationId])

  useEffect(() => {
    if (data?.getSportsEducation) {

      setValues({
        de: data.getSportsEducation.name.de,
        fr: data.getSportsEducation.name.fr,
        it: data.getSportsEducation.name.it,
      })
    }
  }, [data])

  return (
    <SportsEducationEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      queryError={queryError || queryChangeError || queryAddError}
      removeSportsEducationId={removeSportsEducationId}
      sportsEducationId={sportsEducationId}
      updateSportsEducationEntry={updateSportsEducationEntry}
      createSportsEducationEntry={createSportsEducationEntry}
    />
  )
}
