export const cantons = [
  'AG',
  'AI',
  'AR',
  'BE',
  'BL',
  'BS',
  'FR',
  'GE',
  'GL',
  'GR',
  'JU',
  'LU',
  'NE',
  'NW',
  'OW',
  'SG',
  'SH',
  'SO',
  'SZ',
  'TG',
  'TI',
  'UR',
  'VD',
  'VS',
  'ZG',
  'ZH',
]

export function getCantonsOptions(intl: any) {
  const cantonsOptions: {
    id: string
    label: string
  }[] = []
  cantons.forEach((canton) => {
    cantonsOptions.push({
      id: canton,
      label: intl.formatMessage({
        id: 'cantons.' + canton,
        defaultMessgae: canton,
      }),
    })
  })
  return cantonsOptions
}
