import { useEffect } from 'react'
import { ModalHeader, ModalBody } from 'baseui/modal'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Spinner, SIZE } from 'baseui/spinner'
import { Block } from 'baseui/block'

export default function CheckboxFilter({
  values,
  loadingValues,
  filter,
  setFilter,
  label,
  comment,
}: {
  values: {
    id: string
    label: string
  }[]
  loadingValues: boolean
  filter: string[] | null
  setFilter: React.Dispatch<React.SetStateAction<string[] | null>>
  label: string
  comment: string
}) {
  useEffect(() => {
    if (!filter && setFilter) {
      setFilter([])
    }
  }, [setFilter])

  return (
    <>
      <ModalHeader>{label}</ModalHeader>
      <ModalBody>
        <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
          <Cell span={[12, 12, 12]}>
            <FormControl label={comment}>
              {loadingValues ? (
                <Block
                  overrides={{
                    Block: {
                      style: {
                        width:"100%",
                        display: 'block',
                        margin: '15px auto',
                      },
                    },
                  }}
                >
                  <Spinner $size={SIZE.small} />
                </Block>
              ) : (
                <>
                  {values.map((item) => (
                    <Checkbox
                      key={item.id}
                      value={item.id}
                      checked={
                        (filter &&
                          !!filter.find((item1) => item1 === item.id)) ||
                        false
                      }
                      onChange={(e) => {
                        setFilter((prev) => {
                          let filterNew = prev ? [...prev] : []
                          const value = (e.target as HTMLInputElement).value
                          const index = filterNew.findIndex(
                            (item1) => item1 === value
                          )
                          if (index >= 0) {
                            filterNew.splice(index, 1)
                          } else {
                            filterNew.push(value)
                          }
                          return filterNew
                        })
                      }}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      {item.label}
                    </Checkbox>
                  ))}
                </>
              )}
            </FormControl>
          </Cell>
        </Grid>
      </ModalBody>
    </>
  )
}
