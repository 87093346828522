import AddressType from './AddressType'

import { InnerRoute } from '@lemonbrain/hooks'

export default function AddressTypeBusiness({
  subNav,
  addressTypeId,
  setAddressTypelId,
}: {
  subNav: InnerRoute[]
  addressTypeId: string | null | undefined
  setAddressTypelId: (addressTypeId: string | undefined) => void
}) {
  return (
    <AddressType
      subNav={subNav}
      addressTypeId={addressTypeId}
      setAddressTypelId={setAddressTypelId}
    />
  )
}
