import AssociationsAdd from './AssociationsAdd'

import { InnerRoute } from '@lemonbrain/hooks'

export default function AssociationsAddBusiness({
  subNav,
  goToAssociation,
}: {
  subNav: InnerRoute[]
  goToAssociation: (id: string | null) => void
}) {

  return (
    <AssociationsAdd
      subNav={subNav}
      goToAssociation={goToAssociation}
    />
  )
}
