import NewslettersAdd from './NewslettersAdd'

import { InnerRoute } from '@lemonbrain/hooks'

export default function NewslettersAddBusiness({
  subNav,
  goToNewsletter,
}: {
  subNav: InnerRoute[]
  goToNewsletter: (id: string | null) => void
}) {

  return (
    <NewslettersAdd
      subNav={subNav}
      goToNewsletter={goToNewsletter}
    />
  )
}
