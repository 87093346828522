import { FormattedMessage } from 'react-intl'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'

import PaymentInfosData from './PaymentInfos/PaymentInfos.data'
import OnlinePayerData from './OnlinePayer/OnlinePayer.data'
import { PaymentState } from '../InvoicesMy.data'

export default function MyInvoiceEdit({
  goToInvoice,
  invoiceId,
  paymentState,
  language,
}: {
  goToInvoice: (id: string | null) => void
  invoiceId: string
  paymentState: PaymentState | undefined
  language: string
}) {

  return (
    <>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
        <Button
          onClick={() => goToInvoice(null)}
          startEnhancer={() => <ArrowLeft size={24} />}
        >
          <FormattedMessage
            id='invoices.myInvoiceEdit.toOverview'
            defaultMessage={'To the overview'}
          />
        </Button>
      </Block>
        <>
          <PaymentInfosData invoiceId={invoiceId} />
          <OnlinePayerData invoiceId={invoiceId} paymentState={paymentState} language={language} />
        </>
    </>
  )
}
