import Newsletter from './Newsletter'

export default function NewsletterBusiness({
  newsletters,
  setNewsletters,
  loadingNewsletters,
  newslettersInput,
}: {
  newsletters?: { id: string }[]
  setNewsletters: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingNewsletters: boolean
  newslettersInput: {
    id: string
    label: string
  }[]
}) {
  return (
    <Newsletter
      newsletters={newsletters}
      setNewsletters={setNewsletters}
      loadingNewsletters={loadingNewsletters}
      newslettersInput={newslettersInput}
    />
  )
}
