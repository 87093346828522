import WorkLevel from './WorkLevel'

import { InnerRoute } from '@lemonbrain/hooks'

export default function WorkLevelBusiness({
  subNav,
  workLevelId,
  setWorkLevelId,
}: {
  subNav: InnerRoute[]
  workLevelId: string | null | undefined
  setWorkLevelId: (workLevelId: string | undefined) => void
}) {
  return (
    <WorkLevel
      subNav={subNav}
      workLevelId={workLevelId}
      setWorkLevelId={setWorkLevelId}
    />
  )
}
