const LocaleData = {
    'settings.settings': 'Einstellungen',
    'settings.workLevel': 'Tätigkeit/Stufe',
    'settings.svssFunctions': 'SVSS Funktionen',
    'settings.stateFunctions': 'Kantons Funktionen',
    'settings.addressType': 'Adresstypen',
    'settings.membership': 'Mitgliedschaft',
    'settings.association': 'Mitglieder Vereinigungen',
    'settings.associationType': 'Verbandstätigkeiten',
    'settings.sportsEducation': 'Sportausbildung',
    'settings.courses': 'Weiterbildungen',
    'settings.state': 'Status',
    'settings.stateTypes': 'Status Typen',

    'settings.workLevelEdit.de': 'Bezeichnung (Deutsch)',
    'settings.workLevelEdit.fr': 'Bezeichnung (Französisch)',
    'settings.workLevelEdit.it': 'Bezeichnung (Italienisch)',
    'settings.workLevelEdit.error': 'Fehler',
    'settings.workLevelEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.workLevelEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'settings.workLevelEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'settings.workLevelEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'settings.workLevelEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.workLevelEdit.error.thereWasAnServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.workLevelEdit.saved': 'Gespeichert',
    'settings.workLevelEdit.saved.updated': 'Die Tätigkeit/Stufe wurde aktualisiert',
    'settings.workLevelEdit.saved.created': 'Die Tätigkeit/Stufe wurde erstellt',

    'settings.workLevelTable.fetchingMore': 'Es werden mehr Tätigkeit/Stufe geladen...',
    'settings.workLevelTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.workLevelTable.createdFrom': 'Erstellt von',
    'settings.workLevelTable.de': 'Bezeichnung (Deutsch)',
    'settings.workLevelTable.fr': 'Bezeichnung (Französisch)',
    'settings.workLevelTable.it': 'Bezeichnung (Italienisch)',
    'settings.workLevelTable.action': 'Funktionen',
    'settings.workLevelTable.editOrDeleteAWorkLevel': 'Bearbeite oder lösche eine Tätigkeit/Stufe',
    'settings.workLevelTable.workLevels': 'Tätigkeiten/Stufen',
    'settings.workLevelTable.delete': 'Löschen',
    'settings.workLevelTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.workLevelTable.error': 'Fehler',
    'settings.workLevelTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
    'settings.workLevelTable.removeRow.error': 'Fehler',
    'settings.workLevelTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.workLevelTable.removeRow.success': 'Eintrag wurde gelöscht',

    'settings.addressTypeEdit.isInitType': 'Initialisierungs Typ',
    'settings.addressTypeEdit.isFirm': 'Ist Firmenadresse',
    'settings.addressTypeEdit.de': 'Bezeichnung (Deutsch)',
    'settings.addressTypeEdit.fr': 'Bezeichnung (Französisch)',
    'settings.addressTypeEdit.it': 'Bezeichnung (Italienisch)',
    'settings.addressTypeEdit.error': 'Fehler',
    'settings.addressTypeEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.addressTypeEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'settings.addressTypeEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'settings.addressTypeEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'settings.addressTypeEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.addressTypeEdit.error.thereWasAServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.addressTypeEdit.saved': 'Gespeichert',
    'settings.addressTypeEdit.saved.updated': 'Die AdressTypen wurde aktualisiert',
    'settings.addressTypeEdit.saved.created': 'Die AdressTypen wurde erstellt',

    'settings.addressTypeTable.fetchingMore': 'Es werden mehr Adresstyp geladen...',
    'settings.addressTypeTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.addressTypeTable.createdFrom': 'Erstellt von',
    'settings.addressTypeTable.isInitType': 'Initialisierungs Typ',
    'settings.addressTypeTable.isFirm': 'Ist Firmenadresse',
    'settings.addressTypeTable.de': 'Bezeichnung (Deutsch)',
    'settings.addressTypeTable.fr': 'Bezeichnung (Französisch)',
    'settings.addressTypeTable.it': 'Bezeichnung (Italienisch)',
    'settings.addressTypeTable.action': 'Funktionen',
    'settings.addressTypeTable.editOrDeleteAnAddressType': 'Bearbeite oder lösche ein Adresstyp',
    'settings.addressTypeTable.addressTypes': 'Addresstypen',
    'settings.addressTypeTable.delete': 'Löschen',
    'settings.addressTypeTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.addressTypeTable.error': 'Fehler',
    'settings.addressTypeTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
    'settings.addressTypeTable.removeRow.error': 'Fehler',
    'settings.addressTypeTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.addressTypeTable.removeRow.success': 'Eintrag wurde gelöscht',

    'settings.membershipEdit.de': 'Bezeichnung (Deutsch)',
    'settings.membershipEdit.fr': 'Bezeichnung (Französisch)',
    'settings.membershipEdit.it': 'Bezeichnung (Italienisch)',
    'settings.membershipEdit.prize': 'Preis',
    'settings.membershipEdit.directInvoicing': 'Mitglied erhält direkt eine Rechnung',
    'settings.membershipEdit.error': 'Fehler',
    'settings.membershipEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.membershipEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'settings.membershipEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'settings.membershipEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'settings.membershipEdit.error.prizeRequired': 'Preis ist verlangt und muss eine Dezimalzahl sein',
    'settings.membershipEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.membershipEdit.error.thereWasAnServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.membershipEdit.saved': 'Gespeichert',
    'settings.membershipEdit.saved.updated': 'Die Mitgliedschaft wurde aktualisiert',
    'settings.membershipEdit.saved.created': 'Die Mitgliedschaft wurde erstellt',

    'settings.membershipTable.fetchingMore': 'Es werden mehr Mitgliedschaft geladen...',
    'settings.membershipTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.membershipTable.createdFrom': 'Erstellt von',
    'settings.membershipTable.de': 'Bezeichnung (Deutsch)',
    'settings.membershipTable.fr': 'Bezeichnung (Französisch)',
    'settings.membershipTable.it': 'Bezeichnung (Italienisch)',
    'settings.membershipTable.prize': 'Preis',
    'settings.membershipTable.directInvoicing': 'Direkt Rechnung',
    'settings.membershipTable.action': 'Funktionen',
    'settings.membershipTable.editOrDeleteAMembership': 'Bearbeite oder lösche eine Mitgliedschaft',
    'settings.membershipTable.memberships': 'Mitgliedschaften',
    'settings.membershipTable.delete': 'Löschen',
    'settings.membershipTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.membershipTable.error': 'Fehler',
    'settings.membershipTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
    'settings.membershipTable.removeRow.error': 'Fehler',
    'settings.membershipTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.membershipTable.removeRow.success': 'Eintrag wurde gelöscht',

    'settings.sportsEducationEdit.de': 'Bezeichnung (Deutsch)',
    'settings.sportsEducationEdit.fr': 'Bezeichnung (Französisch)',
    'settings.sportsEducationEdit.it': 'Bezeichnung (Italienisch)',
    'settings.sportsEducationEdit.error': 'Fehler',
    'settings.sportsEducationEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.sportsEducationEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'settings.sportsEducationEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'settings.sportsEducationEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'settings.sportsEducationEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.sportsEducationEdit.error.thereWasAnServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.sportsEducationEdit.saved': 'Gespeichert',
    'settings.sportsEducationEdit.saved.updated': 'Die Sportsausbildung wurde aktualisiert',
    'settings.sportsEducationEdit.saved.created': 'Die Sportsausbildung wurde erstellt',

    'settings.sportsEducationTable.fetchingMore': 'Es werden mehr Sportsausbildung geladen...',
    'settings.sportsEducationTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.sportsEducationTable.createdFrom': 'Erstellt von',
    'settings.sportsEducationTable.de': 'Bezeichnung (Deutsch)',
    'settings.sportsEducationTable.fr': 'Bezeichnung (Französisch)',
    'settings.sportsEducationTable.it': 'Bezeichnung (Italienisch)',
    'settings.sportsEducationTable.action': 'Funktionen',
    'settings.sportsEducationTable.editOrDeleteASportsEducation': 'Bearbeite oder lösche eine Sportsausbildung',
    'settings.sportsEducationTable.sportsEducations': 'Sportsausbildung',
    'settings.sportsEducationTable.delete': 'Löschen',
    'settings.sportsEducationTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.sportsEducationTable.error': 'Fehler',
    'settings.sportsEducationTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
    'settings.sportsEducationTable.removeRow.error': 'Fehler',
    'settings.sportsEducationTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.sportsEducationTable.removeRow.success': 'Eintrag wurde gelöscht',

    'settings.stateTable.fetchingMore': 'Es werden mehr Status geladen...',
    'settings.stateTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.stateTable.createdFrom': 'Erstellt von',
    'settings.stateTable.isInitState': 'Initialisierungs Status',
    'settings.stateTable.isInitType': 'Initialisierungs Typ',
    'settings.stateTable.de': 'Bezeichnung (Deutsch)',
    'settings.stateTable.fr': 'Bezeichnung (Französisch)',
    'settings.stateTable.it': 'Bezeichnung (Italienisch)',
    'settings.stateTable.action': 'Funktionen',
    'settings.stateTable.editOrDeleteAState': 'Bearbeite oder lösche einen Status',
    'settings.stateTable.states': 'Status',
    'settings.stateTable.delete': 'Löschen',
    'settings.stateTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.stateTable.error': 'Fehler',
    'settings.stateTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal - addressType',
    'settings.stateTable.removeRow.error': 'Fehler',
    'settings.stateTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.stateTable.removeRow.success': 'Eintrag wurde gelöscht',

    'settings.stateEdit.isInitState': 'Initialisierungs Status',
    'settings.stateEdit.highlightColor': 'Auszeichnungs Farbe',
    'settings.stateEdit.de': 'Bezeichnung (Deutsch)',
    'settings.stateEdit.fr': 'Bezeichnung (Französisch)',
    'settings.stateEdit.it': 'Bezeichnung (Italienisch)',
    'settings.stateEdit.error': 'Fehler',
    'settings.stateEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.stateEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'settings.stateEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'settings.stateEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'settings.stateEdit.error.highlightColorRequired': 'Ausziechungs Farbe ist verlangt',
    'settings.stateEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.stateEdit.error.thereWasAServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.stateEdit.saved': 'Gespeichert',
    'settings.stateEdit.saved.updated': 'Der Status wurde aktualisiert',
    'settings.stateEdit.saved.created': 'Der Status wurde erstellt',
    'settings.stateEdit.default': 'Standard',
    'settings.stateEdit.green': 'Grün',
    'settings.stateEdit.orange': 'Orange',
    'settings.stateEdit.red': 'Rot',
    'settings.stateEdit.blue': 'Blau',
    'settings.stateEdit.purple': 'Violett',
    'settings.stateEdit.yellow': 'Gelb',

    'settings.stateTypeTable.fetchingMore': 'Es werden mehr Status Typen geladen...',
    'settings.stateTypeTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.stateTypeTable.createdFrom': 'Erstellt von',
    // 'settings.stateTypeTable.isInitState': 'Initialisierungs Status',
    'settings.stateTypeTable.isInitType': 'Initialisierungs Typ',
    'settings.stateTypeTable.de': 'Bezeichnung (Deutsch)',
    'settings.stateTypeTable.fr': 'Bezeichnung (Französisch)',
    'settings.stateTypeTable.it': 'Bezeichnung (Italienisch)',
    'settings.stateTypeTable.isTrialType': 'Ist zur Probe (1 Jahr)',
    'settings.stateTypeTable.requireMembership': 'Verlangt das Setzen einer Mitgliedschaft',
    'settings.stateTypeTable.action': 'Funktionen',
    'settings.stateTypeTable.editOrDeleteAState': 'Bearbeite oder lösche einen Status',
    'settings.stateTypeTable.stateTypes': 'Status Typen',
    'settings.stateTypeTable.delete': 'Löschen',
    'settings.stateTypeTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.stateTypeTable.error': 'Fehler',
    'settings.stateTypeTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal - addressType',
    'settings.stateTypeTable.removeRow.error': 'Fehler',
    'settings.stateTypeTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.stateTypeTable.removeRow.success': 'Eintrag wurde gelöscht',

    // 'settings.stateTypeEdit.isInitState': 'Initialisierungs Status',
    'settings.stateTypeEdit.de': 'Bezeichnung (Deutsch)',
    'settings.stateTypeEdit.fr': 'Bezeichnung (Französisch)',
    'settings.stateTypeEdit.it': 'Bezeichnung (Italienisch)',
    'settings.stateTypeEdit.error': 'Fehler',
    'settings.stateTypeEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.stateTypeEdit.error.deRequired': 'Bezeichnung (Deutsch) ist verlangt',
    'settings.stateTypeEdit.error.frRequired': 'Bezeichnung (Französisch) ist verlangt',
    'settings.stateTypeEdit.error.itRequired': 'Bezeichnung (Italienisch) ist verlangt',
    'settings.stateTypeEdit.isTrialType': 'Ist zur Probe (1 Jahr)',
    'settings.stateTypeEdit.requireMembership': 'Verlangt das Setzen einer Mitgliedschaft',
    'settings.stateTypeEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.stateTypeEdit.error.thereWasAServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.stateTypeEdit.saved': 'Gespeichert',
    'settings.stateTypeEdit.saved.updated': 'Der Status wurde aktualisiert',
    'settings.stateTypeEdit.saved.created': 'Der Status wurde erstellt',

    'settings.addressEdit.type': 'Typ',
    'settings.addressEdit.salutation': 'Anrede',
    'settings.addressEdit.firstName': 'Vorname',
    'settings.addressEdit.lastName': 'Nachname',
    'settings.addressEdit.firmName': 'Schule',
    'settings.addressEdit.mailbox': 'Addresszusatz',
    'settings.addressEdit.streetNr': 'Strasse und Hausnummer',
    'settings.addressEdit.postCode': 'PLZ',
    'settings.addressEdit.place': 'Ort',
    'settings.addressEdit.canton': 'Kanton',
    'settings.addressEdit.salutation.isRequired': 'Anrede ist verlangt',
    'settings.addressEdit.firmName.isRequired': 'Schule ist verlangt',
    'settings.addressEdit.firstName.isRequired': 'Vorname ist verlangt',
    'settings.addressEdit.lastName.isRequired': 'Nachname ist verlangt',
    'settings.addressEdit.streetNr.isRequired': 'Strasse und Hausnummer sind verlangt',
    'settings.addressEdit.postCode.isRequired': 'PLZ muss eine Nummer sein und ist verlangt',
    'settings.addressEdit.place.isRequired': 'Ort ist verlangt',
    'settings.addressEdit.canton.isRequired': 'Kanton ist verlangt',

    'settings.associationTypeTable.removeRow.success': 'Eintrag wurde gelöscht',
    'settings.associationTypeTable.removeRow.error': 'Fehler',
    'settings.associationTypeTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.associationTypeTable.error': 'Fehler',
    'settings.associationTypeTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
    'settings.associationTypeTable.delete': 'Löschen',
    'settings.associationTypeTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll?',
    'settings.associationTypeTable.associationType': 'Verbandstätigkeiten',
    'settings.associationTypeTable.editOrDeleteAsEntry': 'Bearbeite oder lösche eine Verbandstätigkeit',
    'settings.associationTypeTable.action': 'Funktionen',
    'settings.associationTypeTable.isDefaultType': 'Default Typ',
    'settings.associationTypeTable.userGroup': 'Benutzergruppe',
    'settings.associationTypeTable.de': 'Bezeichnung (Deutsch)',
    'settings.associationTypeTable.fr': 'Bezeichnung (Französisch)',
    'settings.associationTypeTable.it': 'Bezeichnung (Italienisch)',
    'settings.associationTypeTable.createdFrom': 'Erstellt von',
    'settings.associationTypeTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.associationTypeTable.fetchingMore': 'Es werden mehr Einträge geladen...',

    'settings.associationTypeEdit.error': 'Fehler',
    'settings.associationTypeEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.associationTypeEdit.saved': 'Gespeichert',
    'settings.associationTypeEdit.saved.updated': 'Der Eintrag wurde aktualisiert',
    'settings.associationTypeEdit.error.thereWasAServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.associationTypeEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.associationTypeEdit.saved.created': 'Der Eintrag wurde erstellt',
    'settings.associationTypeEdit.error.userGroupRequired': 'Die Benutzergruppe ist verlangt',
    'settings.associationTypeEdit.error.deRequired': 'Bezeichnung (Deutsch) ist verlangt',
    'settings.associationTypeEdit.error.frRequired': 'Bezeichnung (Französisch) ist verlangt',
    'settings.associationTypeEdit.error.itRequired': 'Bezeichnung (Italienisch) ist verlangt',
    'settings.associationTypeEdit.isDefaultType': 'Default Typ',
    'settings.associationTypeEdit.userGroup': 'Benutzergruppe',
    'settings.associationTypeEdit.de': 'Bezeichnung (Deutsch)',
    'settings.associationTypeEdit.fr': 'Bezeichnung (Französisch)',
    'settings.associationTypeEdit.it': 'Bezeichnung (Italienisch)',

    'settings.svssFunctionEdit.de': 'Bezeichnung (Deutsch)',
    'settings.svssFunctionEdit.fr': 'Bezeichnung (Französisch)',
    'settings.svssFunctionEdit.it': 'Bezeichnung (Italienisch)',
    'settings.svssFunctionEdit.error': 'Fehler',
    'settings.svssFunctionEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.svssFunctionEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'settings.svssFunctionEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'settings.svssFunctionEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'settings.svssFunctionEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.svssFunctionEdit.error.thereWasAnServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.svssFunctionEdit.saved': 'Gespeichert',
    'settings.svssFunctionEdit.saved.updated': 'Der Eintrag wurde aktualisiert',
    'settings.svssFunctionEdit.saved.created': 'Der Eintrag wurde erstellt',

    'settings.svssFunctionTable.fetchingMore': 'Es werden mehr Einträge geladen...',
    'settings.svssFunctionTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.svssFunctionTable.createdFrom': 'Erstellt von',
    'settings.svssFunctionTable.de': 'Bezeichnung (Deutsch)',
    'settings.svssFunctionTable.fr': 'Bezeichnung (Französisch)',
    'settings.svssFunctionTable.it': 'Bezeichnung (Italienisch)',
    'settings.svssFunctionTable.action': 'Funktionen',
    'settings.svssFunctionTable.editOrDeleteAnEntry': 'Bearbeite oder lösche einen Eintrag',
    'settings.svssFunctionTable.svssFunctions': 'SVSS Funktionalitäten',
    'settings.svssFunctionTable.delete': 'Löschen',
    'settings.svssFunctionTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.svssFunctionTable.error': 'Fehler',
    'settings.svssFunctionTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
    'settings.svssFunctionTable.removeRow.error': 'Fehler',
    'settings.svssFunctionTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.svssFunctionTable.removeRow.success': 'Eintrag wurde gelöscht',

    'settings.stateFunctionEdit.de': 'Bezeichnung (Deutsch)',
    'settings.stateFunctionEdit.fr': 'Bezeichnung (Französisch)',
    'settings.stateFunctionEdit.it': 'Bezeichnung (Italienisch)',
    'settings.stateFunctionEdit.error': 'Fehler',
    'settings.stateFunctionEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'settings.stateFunctionEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'settings.stateFunctionEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'settings.stateFunctionEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'settings.stateFunctionEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.stateFunctionEdit.error.thereWasAnServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.stateFunctionEdit.saved': 'Gespeichert',
    'settings.stateFunctionEdit.saved.updated': 'Der Eintrag wurde aktualisiert',
    'settings.stateFunctionEdit.saved.created': 'Der Eintrag wurde erstellt',

    'settings.stateFunctionTable.fetchingMore': 'Es werden mehr Einträge geladen...',
    'settings.stateFunctionTable.noEntries': 'Es sind keine Einträge vorhanden',
    'settings.stateFunctionTable.createdFrom': 'Erstellt von',
    'settings.stateFunctionTable.de': 'Bezeichnung (Deutsch)',
    'settings.stateFunctionTable.fr': 'Bezeichnung (Französisch)',
    'settings.stateFunctionTable.it': 'Bezeichnung (Italienisch)',
    'settings.stateFunctionTable.action': 'Funktionen',
    'settings.stateFunctionTable.editOrDeleteAnEntry': 'Bearbeite oder lösche einen Eintrag',
    'settings.stateFunctionTable.stateFunctions': 'Kantons Funktionalitäten',
    'settings.stateFunctionTable.delete': 'Löschen',
    'settings.stateFunctionTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
    'settings.stateFunctionTable.error': 'Fehler',
    'settings.stateFunctionTable.errorMessage': 'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
    'settings.stateFunctionTable.removeRow.error': 'Fehler',
    'settings.stateFunctionTable.removeRow.error.notRemoved': 'Eintrag wurde nicht gelöscht, versuche es noch einmal',
    'settings.stateFunctionTable.removeRow.success': 'Eintrag wurde gelöscht',

    'settings.svssBillingInformations': 'Rechnungsinformationen',
    'settings.svssBillingInformations.from': 'Absender',
    'settings.svssBillingInformations.errorMessage': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.svssBillingInformations.error': 'Fehler',

    'settings.billingInformations': 'Rechnungsinformationen',
    'settings.billingInformations.firstName': 'Vorname',
    'settings.billingInformations.lastName': 'Nachname',
    'settings.billingInformations.firmName': 'Firmenname',
    'settings.billingInformations.saved': 'Gespeichert',
    'settings.billingInformations.saved.updated': 'Der Eintrag wurde aktualisiert',
    'settings.billingInformations.error': 'Fehler',
    'settings.billingInformations.error.thereWasAnServerError' : 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.billingInformations.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'settings.billingInformations.saved.created': 'Der Eintrag wurde erstellt',
    'settings.billingInformations.error.firmnameRequired': 'Firmenname ist verlangt',
    'settings.billingInformations.errorMessage': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.billingInformations.mailbox': 'Addresszusatz',
    'settings.billingInformations.streetNr': 'Strasse und Hausnummer',
    'settings.billingInformations.postCode': 'PLZ',
    'settings.billingInformations.place': 'Ort',
    'settings.billingInformations.canton': 'Kanton',
    'settings.billingInformations.account': 'Postcheck-Konto',
    'settings.billingInformations.iban': 'IBAN',
    'settings.billingInformations.qrIban': 'QR-IBAN',
    'settings.billingInformations.besrId': 'BESR-ID',
    'settings.billingInformations.error.accountRequired': 'Postcheck-Konto ist verlangt und muss im korrekten Format sien',
    'settings.billingInformations.error.ibanRequired': 'IBAN ist verlangt und muss im korrekten Format sien',
    'settings.billingInformations.error.ibanQrRequired': 'QR IBAN muss im korrekten Format sein',
    'settings.billingInformations.error.streetNrRequired': 'Strasse und Hausnummer ist verlangt',
    'settings.billingInformations.error.postCodeRequired': 'PLZ ist verlangt',
    'settings.billingInformations.error.placeRequired': 'Ort ist verlangt',
    'settings.billingInformations.error.cantonRequired': 'Kanton ist verlangt',

    'settings.courses.courseSettings': 'Einstellungen Weiterbildungen',
    'settings.courses.courseSettings.signupRecipients': 'Empfänger bei Kursanmeldungen',
    'settings.courses.courseSettings.error': 'Fehler',
    'settings.courses.courseSettings.delete': 'Löschen',
    'settings.courses.courseSettings.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll?',
    'settings.courses.courseSettings.errorMessage': 'Fehler beim Laden der Einträge',
    'settings.courses.courseSettings.noEntries': 'Keine Einträge',
    'settings.courses.courseSettings.signupRecipientForm.email': 'E-Mail',
    'settings.courses.courseSettings.signupRecipientForm.emailError': 'E-Mail ist nicht gültig',
    'settings.courses.courseSettings.signupRecipient.saved': 'Empfänger wurde gespeichert',
    'settings.courses.courseSettings.signupRecipient.error': 'Fehler beim Speichern',
    'settings.courses.courseSettings.signupRecipient.error.thereWasAnServerError': 'Server-Fehler beim Speichern',
    'settings.courses.courseSettings.signupRecipient.error.notLoggedIn': 'Sie sind nicht eingeloggt',
    'settings.courses.courseSettings.removeRow.success': 'Empfänger wurde gelöscht',
    'settings.courses.courseSettings.removeRow.error': 'Fehler',
    'settings.courses.courseSettings.removeRow.error.notRemoved': 'Empfänger konnte nicht gelöscht werden',

    'settings.membershipMailing': 'Mitgliedschaftsmailing',
    'settings.membershipMailing.MembershipSettings': 'Einstellungen Mitgliedschaft',

    'settings.membershipMailingForm.saved': 'Gespeichert',
    'settings.membershipMailingForm.error': 'Fehler',
    'settings.membershipMailingForm.thereWasAnServerError': 'Server-Fehler beim Speichern',
    'settings.membershipMailingForm.notLoggedIn': 'Sie sind nicht eingeloggt',
    'settings.membershipMailingForm.emailError': 'E-Mail ist nicht gültig',
    'settings.membershipMailingForm.errorMessage': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.membershipMailingForm.email': 'E-Mail',

    'settings.membershipMailingTable.success': 'Empfänger wurde gelöscht',
    'settings.membershipMailingTable.error': 'Fehler',
    'settings.membershipMailingTable.notRemoved': 'Empfänger wurde nicht gelöscht',
    'settings.membershipMailingTable.errorMessage': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'settings.membershipMailingTable.delete': 'Löschen',
    'settings.membershipMailingTable.deleteConfirm': 'Sicher, dass dieser Eintrag gelöscht werden soll?',
    'settings.membershipMailingTable.signupRecipients': 'Empfänger bei Neumitglieder',
    'settings.membershipMailingTable.email': 'E-Mail',
    'settings.membershipMailingTable.noEntries': 'Keine Einträge',
}

export default LocaleData
