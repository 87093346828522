import { useParams, useLocation } from 'react-router-dom'
import Sticky from 'react-stickynode'
import { FormattedMessage } from 'react-intl'

import MenuWrapper, { Menu, MenuItem, MenuLink } from './SideMenu.styled'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

type MenuProps = {
  className?: string
  onClick?: () => void
  menuItems?: InnerRoute[]
}

const SideMenu = ({ className = '', onClick, menuItems }: MenuProps) => {
  const { language } = useParams<{ language: string }>()
  const pushPath = useHistoryPush()
  const location = useLocation()
  const pathname = location.pathname + '/'
  
  return (
    <Sticky top='.navbar' innerZ='1'>
      <MenuWrapper className='side-menu-wrapper'>
        <Menu className={`side-menu ${className}`}>
          {menuItems && menuItems.map((item) => (item.visible || item.visible === undefined) && (
              <MenuItem key={`side-menu--key${item.id}`} onClick={onClick}>
                <MenuLink
                  onClick={() => pushPath('/' + language + item.routePath)}
                  className={pathname.indexOf(item.routePath + '/') > -1 ? 'active' : ''}
                >
                  <FormattedMessage
                    id={item.title.id}
                    defaultMessage={item.title.defaultMessage}
                  />
                </MenuLink>
              </MenuItem>
            ))
          }
        </Menu>
      </MenuWrapper>
    </Sticky>
  )
}

export default SideMenu
