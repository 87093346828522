// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useAppState } from '@lemonbrain/contexts'
import { isInGroupOrAdmin } from '@lemonbrain/utils'

function PrivateRoute({
  accessibleBy,
  component: Component,
  ...rest
}: {
  accessibleBy?: string[]
  component:
    | React.ComponentClass<any, any>
    | ((props?: any) => JSX.Element)
    | undefined
  rest?: []
}) {
  const { user } = useAppState()
  const { language } = useParams<{ language: string }>()

  const hasRightsForDashboard =
    user &&
    isInGroupOrAdmin(
      user,
      accessibleBy && accessibleBy.length > 0
        ? accessibleBy
        : [
            process.env.REACT_APP_GROUP_GUESTS || '',
            process.env.REACT_APP_GROUP_MANAGERS || '',
            process.env.REACT_APP_GROUP_MEMBERS || '',
            process.env.REACT_APP_GROUP_ADMINS || '',
          ]
    )

  return (
    <div {...rest}>
      {hasRightsForDashboard && Component ? (
        //@ts-ignore
        <Component />
      ) : (
        <Navigate to={`/${language}/login`} />
      )}
    </div>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
  language: PropTypes.string,
}

export default PrivateRoute
