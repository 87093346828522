import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import StateBusiness from './State.business'

export default function StateData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { stateId } = useParams<{ stateId: string }>()
  const { language } = useParams<{ language: string }>()

  function setStateId(stateId: string | undefined) {
    if (stateId && stateId !== '') {
      pushPath(`/${language}/settings/state/${stateId}`)
    } else {
      pushPath(`/${language}/settings/state`)
    }
  }

  function listStateTypes(stateId: string) {
    if (stateId && stateId !== '') {
      pushPath(`/${language}/settings/state-types/${stateId}/type`)
    } else {
      pushPath(`/${language}/settings/state`)
    }
  }

  return (
    <StateBusiness
      subNav={subNav}
      stateId={stateId}
      setStateId={setStateId}
      listStateTypes={listStateTypes}
    />
  )
}
