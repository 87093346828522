import CreateDirectMemberInvoices from './CreateDirectMemberInvoices'

export default function CreateDirectMemberInvoicesBusiness({
    loading,
    createDirectMemberInvoices,
    progress,
}: {
    loading: boolean
    createDirectMemberInvoices: () => void
    progress: number | null
}) {

  return <CreateDirectMemberInvoices loading={loading} onClick={createDirectMemberInvoices} progress={progress}/>
}
