export const appendScript = (scriptToAppend: string, onLoad: () => void, onError: () => void) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;

    script.addEventListener('load', onLoad)
    script.addEventListener('error', onError)

    document.body.appendChild(script)

    return () => {
        script.removeEventListener('load', onLoad)
        script.removeEventListener('error', onError)
    }
}