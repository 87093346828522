import { useParams, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import Menu, { MenuItem, MenuLink } from './MainMenu.styled'

import {useHistoryPush} from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts/PageWrapper'

type MenuProps = {
  className?: string
  onClick?: () => void
}

const MainMenu = ({ className, onClick }: MenuProps) => {
  const { language } = useParams<{ language: string }>()
  const pushPath = useHistoryPush()
  const location = useLocation()
  const pathname = location.pathname
  const {
    mainTyedRoutesItems,
  } = useAppState()

  return <Menu className={className}>
      {mainTyedRoutesItems['MAIN'].map(item => (item.visible || item.visible === undefined) && (
        <MenuItem key={`top-menu--item${item.id}`} onClick={onClick}>
          <MenuLink onClick={() => pushPath('/' + language + item.routePath)}
                  className={pathname.indexOf(item.routePath) > -1 ? 'active' : ''}>
              <FormattedMessage
                id={item.title.id}
                defaultMessage={item.title.defaultMessage}
              />
          </MenuLink>
        </MenuItem>
      ))}
    </Menu>
}

export default MainMenu
