// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type NewsletterMailingFragmentFragment = (
  { __typename?: 'NewsletterMailing' }
  & Pick<Types.NewsletterMailing, 'id' | 'from' | 'fromName' | 'to' | 'subject' | 'mailingDate' | 'mainImageId' | 'mainImageSrc' | 'mainImageCopyright' | 'title' | 'content' | 'leftColoumn' | 'rightColoumn' | 'attachment' | 'sent' | 'failed' | 'state' | 'showOnWebsite' | 'createdAt' | 'createdFrom' | 'updatedAt' | 'updatedFrom'>
  & { newsletter: (
    { __typename?: 'Newsletter' }
    & Pick<Types.Newsletter, 'id'>
    & { name: (
      { __typename?: 'TranslationObject' }
      & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
    ) }
  ), items: Array<Types.Maybe<(
    { __typename?: 'NewsletterMailingItem' }
    & NewsletterMailingItemFragmentFragment
  )>> }
);

export type NewsletterMailingItemFragmentFragment = (
  { __typename?: 'NewsletterMailingItem' }
  & Pick<Types.NewsletterMailingItem, 'id' | 'imageId' | 'imageSrc' | 'imageCopyright' | 'title' | 'content' | 'link' | 'orderNr' | 'createdAt' | 'createdFrom' | 'updatedAt' | 'updatedFrom'>
);

export type GetNewsletterMailingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetNewsletterMailingQuery = (
  { __typename?: 'Query' }
  & { getNewsletterMailing: (
    { __typename?: 'NewsletterMailing' }
    & NewsletterMailingFragmentFragment
  ) }
);

export type GetNewsletterMailingItemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetNewsletterMailingItemQuery = (
  { __typename?: 'Query' }
  & { getNewsletterMailingItem: (
    { __typename?: 'NewsletterMailingItem' }
    & NewsletterMailingItemFragmentFragment
  ) }
);

export type GetNewsletterMailingsQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetNewsletterMailingsQuery = (
  { __typename?: 'Query' }
  & { getNewsletterMailings: (
    { __typename?: 'NewsletterMailingPagination' }
    & Pick<Types.NewsletterMailingPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'NewsletterMailing' }
      & NewsletterMailingFragmentFragment
    )> }
  ) }
);

export type GetNextNewsletterMailingsQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextNewsletterMailingsQuery = (
  { __typename?: 'Query' }
  & { getNextNewsletterMailings: (
    { __typename?: 'NewsletterMailingPagination' }
    & Pick<Types.NewsletterMailingPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'NewsletterMailing' }
      & NewsletterMailingFragmentFragment
    )> }
  ) }
);

export type AddNewsletterMailingMutationVariables = Types.Exact<{
  newsletterId: Types.Scalars['ID'];
  from: Types.Scalars['String'];
  fromName: Types.Scalars['String'];
  to: Array<Types.Scalars['String']>;
  subject: Types.Scalars['String'];
  mailingDate: Types.Scalars['String'];
  items: Array<Types.Maybe<Types.NewsletterMailingItemElemUpdate>>;
  mainImage?: Types.Maybe<Types.Scalars['Upload']>;
  mainImageId?: Types.Maybe<Types.Scalars['ID']>;
  mainImageCopyright?: Types.Maybe<Types.Scalars['String']>;
  title?: Types.Maybe<Types.Scalars['String']>;
  content?: Types.Maybe<Types.Scalars['String']>;
  leftColoumn?: Types.Maybe<Types.Scalars['String']>;
  rightColoumn?: Types.Maybe<Types.Scalars['String']>;
  attachment?: Types.Maybe<Types.Scalars['Upload']>;
  sent?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>>>;
  failed?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>>>;
  state?: Types.Maybe<Types.MailingState>;
  showOnWebsite?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type AddNewsletterMailingMutation = (
  { __typename?: 'Mutation' }
  & { addNewsletterMailing: (
    { __typename?: 'NewsletterMailing' }
    & NewsletterMailingFragmentFragment
  ) }
);

export type UpdateNewsletterMailingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  newsletterId: Types.Scalars['ID'];
  items: Array<Types.Maybe<Types.NewsletterMailingItemElemUpdate>>;
  from: Types.Scalars['String'];
  fromName: Types.Scalars['String'];
  to: Array<Types.Scalars['String']>;
  subject: Types.Scalars['String'];
  mailingDate: Types.Scalars['String'];
  mainImage?: Types.Maybe<Types.Scalars['Upload']>;
  mainImageId?: Types.Maybe<Types.Scalars['ID']>;
  mainImageCopyright: Types.Scalars['String'];
  title: Types.Scalars['String'];
  content: Types.Scalars['String'];
  leftColoumn: Types.Scalars['String'];
  rightColoumn: Types.Scalars['String'];
  attachment?: Types.Maybe<Types.Scalars['Upload']>;
  sent?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>>>;
  failed?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>>>;
  state?: Types.Maybe<Types.MailingState>;
  showOnWebsite?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type UpdateNewsletterMailingMutation = (
  { __typename?: 'Mutation' }
  & { updateNewsletterMailing: (
    { __typename?: 'NewsletterMailing' }
    & NewsletterMailingFragmentFragment
  ) }
);

export type DeleteNewsletterMailingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteNewsletterMailingMutation = (
  { __typename?: 'Mutation' }
  & { deleteNewsletterMailing: (
    { __typename?: 'NewsletterMailing' }
    & NewsletterMailingFragmentFragment
  ) }
);

export type AddNewsletterMailingItemMutationVariables = Types.Exact<{
  newsletterMailingId: Types.Scalars['ID'];
  image?: Types.Maybe<Types.Scalars['Upload']>;
  imageId?: Types.Maybe<Types.Scalars['ID']>;
  imageCopyright?: Types.Maybe<Types.Scalars['String']>;
  title?: Types.Maybe<Types.Scalars['String']>;
  content?: Types.Maybe<Types.Scalars['String']>;
  link?: Types.Maybe<Types.Scalars['String']>;
}>;


export type AddNewsletterMailingItemMutation = (
  { __typename?: 'Mutation' }
  & { addNewsletterMailingItem: (
    { __typename?: 'NewsletterMailingItem' }
    & NewsletterMailingItemFragmentFragment
  ) }
);

export type UpdateNewsletterMailingItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  image?: Types.Maybe<Types.Scalars['Upload']>;
  imageId?: Types.Maybe<Types.Scalars['ID']>;
  imageCopyright?: Types.Maybe<Types.Scalars['String']>;
  title?: Types.Maybe<Types.Scalars['String']>;
  content?: Types.Maybe<Types.Scalars['String']>;
  link?: Types.Maybe<Types.Scalars['String']>;
  orderNr?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type UpdateNewsletterMailingItemMutation = (
  { __typename?: 'Mutation' }
  & { updateNewsletterMailingItem: (
    { __typename?: 'NewsletterMailingItem' }
    & NewsletterMailingItemFragmentFragment
  ) }
);

export type DeleteNewsletterMailingItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteNewsletterMailingItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteNewsletterMailingItem: (
    { __typename?: 'NewsletterMailingItem' }
    & NewsletterMailingItemFragmentFragment
  ) }
);

export type RenderNewsletterMailingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type RenderNewsletterMailingMutation = (
  { __typename?: 'Mutation' }
  & { renderNewsletterMailing: (
    { __typename?: 'NewsletterMailing' }
    & Pick<Types.NewsletterMailing, 'htmlContent' | 'sendProcessId'>
    & NewsletterMailingFragmentFragment
  ) }
);

export type SendNewsletterMailingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SendNewsletterMailingMutation = (
  { __typename?: 'Mutation' }
  & { sendNewsletterMailing: (
    { __typename?: 'SendReturn' }
    & { item: (
      { __typename?: 'NewsletterMailing' }
      & Pick<Types.NewsletterMailing, 'htmlContent' | 'sendProcessId'>
      & NewsletterMailingFragmentFragment
    ), process: (
      { __typename?: 'ProcessState' }
      & Pick<Types.ProcessState, 'errorMessage' | 'processId' | 'progress' | 'returnCode'>
    ) }
  ) }
);

export const NewsletterMailingItemFragmentFragmentDoc = gql`
    fragment NewsletterMailingItemFragment on NewsletterMailingItem {
  id
  imageId
  imageSrc
  imageCopyright
  title
  content
  link
  orderNr
  createdAt
  createdFrom
  updatedAt
  updatedFrom
}
    `;
export const NewsletterMailingFragmentFragmentDoc = gql`
    fragment NewsletterMailingFragment on NewsletterMailing {
  id
  newsletter {
    id
    name {
      de
      fr
      it
    }
  }
  items {
    ...NewsletterMailingItemFragment
  }
  from
  fromName
  to
  subject
  mailingDate
  mainImageId
  mainImageSrc
  mainImageCopyright
  title
  content
  leftColoumn
  rightColoumn
  attachment
  sent
  failed
  state
  showOnWebsite
  createdAt
  createdFrom
  updatedAt
  updatedFrom
}
    ${NewsletterMailingItemFragmentFragmentDoc}`;
export const GetNewsletterMailingDocument = gql`
    query getNewsletterMailing($id: ID!) {
  getNewsletterMailing(id: $id) {
    ...NewsletterMailingFragment
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;

/**
 * __useGetNewsletterMailingQuery__
 *
 * To run a query within a React component, call `useGetNewsletterMailingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterMailingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterMailingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNewsletterMailingQuery(baseOptions: Apollo.QueryHookOptions<GetNewsletterMailingQuery, GetNewsletterMailingQueryVariables>) {
        return Apollo.useQuery<GetNewsletterMailingQuery, GetNewsletterMailingQueryVariables>(GetNewsletterMailingDocument, baseOptions);
      }
export function useGetNewsletterMailingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsletterMailingQuery, GetNewsletterMailingQueryVariables>) {
          return Apollo.useLazyQuery<GetNewsletterMailingQuery, GetNewsletterMailingQueryVariables>(GetNewsletterMailingDocument, baseOptions);
        }
export type GetNewsletterMailingQueryHookResult = ReturnType<typeof useGetNewsletterMailingQuery>;
export type GetNewsletterMailingLazyQueryHookResult = ReturnType<typeof useGetNewsletterMailingLazyQuery>;
export type GetNewsletterMailingQueryResult = Apollo.QueryResult<GetNewsletterMailingQuery, GetNewsletterMailingQueryVariables>;
export const GetNewsletterMailingItemDocument = gql`
    query getNewsletterMailingItem($id: ID!) {
  getNewsletterMailingItem(id: $id) {
    ...NewsletterMailingItemFragment
  }
}
    ${NewsletterMailingItemFragmentFragmentDoc}`;

/**
 * __useGetNewsletterMailingItemQuery__
 *
 * To run a query within a React component, call `useGetNewsletterMailingItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterMailingItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterMailingItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNewsletterMailingItemQuery(baseOptions: Apollo.QueryHookOptions<GetNewsletterMailingItemQuery, GetNewsletterMailingItemQueryVariables>) {
        return Apollo.useQuery<GetNewsletterMailingItemQuery, GetNewsletterMailingItemQueryVariables>(GetNewsletterMailingItemDocument, baseOptions);
      }
export function useGetNewsletterMailingItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsletterMailingItemQuery, GetNewsletterMailingItemQueryVariables>) {
          return Apollo.useLazyQuery<GetNewsletterMailingItemQuery, GetNewsletterMailingItemQueryVariables>(GetNewsletterMailingItemDocument, baseOptions);
        }
export type GetNewsletterMailingItemQueryHookResult = ReturnType<typeof useGetNewsletterMailingItemQuery>;
export type GetNewsletterMailingItemLazyQueryHookResult = ReturnType<typeof useGetNewsletterMailingItemLazyQuery>;
export type GetNewsletterMailingItemQueryResult = Apollo.QueryResult<GetNewsletterMailingItemQuery, GetNewsletterMailingItemQueryVariables>;
export const GetNewsletterMailingsDocument = gql`
    query getNewsletterMailings($cursor: StartCursorInput!) {
  getNewsletterMailings(cursor: $cursor) {
    items {
      ...NewsletterMailingFragment
    }
    nextCursor
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;

/**
 * __useGetNewsletterMailingsQuery__
 *
 * To run a query within a React component, call `useGetNewsletterMailingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterMailingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterMailingsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNewsletterMailingsQuery(baseOptions: Apollo.QueryHookOptions<GetNewsletterMailingsQuery, GetNewsletterMailingsQueryVariables>) {
        return Apollo.useQuery<GetNewsletterMailingsQuery, GetNewsletterMailingsQueryVariables>(GetNewsletterMailingsDocument, baseOptions);
      }
export function useGetNewsletterMailingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsletterMailingsQuery, GetNewsletterMailingsQueryVariables>) {
          return Apollo.useLazyQuery<GetNewsletterMailingsQuery, GetNewsletterMailingsQueryVariables>(GetNewsletterMailingsDocument, baseOptions);
        }
export type GetNewsletterMailingsQueryHookResult = ReturnType<typeof useGetNewsletterMailingsQuery>;
export type GetNewsletterMailingsLazyQueryHookResult = ReturnType<typeof useGetNewsletterMailingsLazyQuery>;
export type GetNewsletterMailingsQueryResult = Apollo.QueryResult<GetNewsletterMailingsQuery, GetNewsletterMailingsQueryVariables>;
export const GetNextNewsletterMailingsDocument = gql`
    query getNextNewsletterMailings($cursor: NextCursorInput!) {
  getNextNewsletterMailings(cursor: $cursor) {
    items {
      ...NewsletterMailingFragment
    }
    nextCursor
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;

/**
 * __useGetNextNewsletterMailingsQuery__
 *
 * To run a query within a React component, call `useGetNextNewsletterMailingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextNewsletterMailingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextNewsletterMailingsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextNewsletterMailingsQuery(baseOptions: Apollo.QueryHookOptions<GetNextNewsletterMailingsQuery, GetNextNewsletterMailingsQueryVariables>) {
        return Apollo.useQuery<GetNextNewsletterMailingsQuery, GetNextNewsletterMailingsQueryVariables>(GetNextNewsletterMailingsDocument, baseOptions);
      }
export function useGetNextNewsletterMailingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextNewsletterMailingsQuery, GetNextNewsletterMailingsQueryVariables>) {
          return Apollo.useLazyQuery<GetNextNewsletterMailingsQuery, GetNextNewsletterMailingsQueryVariables>(GetNextNewsletterMailingsDocument, baseOptions);
        }
export type GetNextNewsletterMailingsQueryHookResult = ReturnType<typeof useGetNextNewsletterMailingsQuery>;
export type GetNextNewsletterMailingsLazyQueryHookResult = ReturnType<typeof useGetNextNewsletterMailingsLazyQuery>;
export type GetNextNewsletterMailingsQueryResult = Apollo.QueryResult<GetNextNewsletterMailingsQuery, GetNextNewsletterMailingsQueryVariables>;
export const AddNewsletterMailingDocument = gql`
    mutation addNewsletterMailing($newsletterId: ID!, $from: String!, $fromName: String!, $to: [String!]!, $subject: String!, $mailingDate: String!, $items: [NewsletterMailingItemElemUpdate]!, $mainImage: Upload, $mainImageId: ID, $mainImageCopyright: String, $title: String, $content: String, $leftColoumn: String, $rightColoumn: String, $attachment: Upload, $sent: [String], $failed: [String], $state: MailingState, $showOnWebsite: Boolean) {
  addNewsletterMailing(
    newsletterId: $newsletterId
    from: $from
    fromName: $fromName
    to: $to
    subject: $subject
    mailingDate: $mailingDate
    items: $items
    mainImage: $mainImage
    mainImageId: $mainImageId
    mainImageCopyright: $mainImageCopyright
    title: $title
    content: $content
    leftColoumn: $leftColoumn
    rightColoumn: $rightColoumn
    attachment: $attachment
    sent: $sent
    failed: $failed
    state: $state
    showOnWebsite: $showOnWebsite
  ) {
    ...NewsletterMailingFragment
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;
export type AddNewsletterMailingMutationFn = Apollo.MutationFunction<AddNewsletterMailingMutation, AddNewsletterMailingMutationVariables>;

/**
 * __useAddNewsletterMailingMutation__
 *
 * To run a mutation, you first call `useAddNewsletterMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewsletterMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewsletterMailingMutation, { data, loading, error }] = useAddNewsletterMailingMutation({
 *   variables: {
 *      newsletterId: // value for 'newsletterId'
 *      from: // value for 'from'
 *      fromName: // value for 'fromName'
 *      to: // value for 'to'
 *      subject: // value for 'subject'
 *      mailingDate: // value for 'mailingDate'
 *      items: // value for 'items'
 *      mainImage: // value for 'mainImage'
 *      mainImageId: // value for 'mainImageId'
 *      mainImageCopyright: // value for 'mainImageCopyright'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      leftColoumn: // value for 'leftColoumn'
 *      rightColoumn: // value for 'rightColoumn'
 *      attachment: // value for 'attachment'
 *      sent: // value for 'sent'
 *      failed: // value for 'failed'
 *      state: // value for 'state'
 *      showOnWebsite: // value for 'showOnWebsite'
 *   },
 * });
 */
export function useAddNewsletterMailingMutation(baseOptions?: Apollo.MutationHookOptions<AddNewsletterMailingMutation, AddNewsletterMailingMutationVariables>) {
        return Apollo.useMutation<AddNewsletterMailingMutation, AddNewsletterMailingMutationVariables>(AddNewsletterMailingDocument, baseOptions);
      }
export type AddNewsletterMailingMutationHookResult = ReturnType<typeof useAddNewsletterMailingMutation>;
export type AddNewsletterMailingMutationResult = Apollo.MutationResult<AddNewsletterMailingMutation>;
export type AddNewsletterMailingMutationOptions = Apollo.BaseMutationOptions<AddNewsletterMailingMutation, AddNewsletterMailingMutationVariables>;
export const UpdateNewsletterMailingDocument = gql`
    mutation updateNewsletterMailing($id: ID!, $newsletterId: ID!, $items: [NewsletterMailingItemElemUpdate]!, $from: String!, $fromName: String!, $to: [String!]!, $subject: String!, $mailingDate: String!, $mainImage: Upload, $mainImageId: ID, $mainImageCopyright: String!, $title: String!, $content: String!, $leftColoumn: String!, $rightColoumn: String!, $attachment: Upload, $sent: [String], $failed: [String], $state: MailingState, $showOnWebsite: Boolean) {
  updateNewsletterMailing(
    id: $id
    newsletterId: $newsletterId
    items: $items
    from: $from
    fromName: $fromName
    to: $to
    subject: $subject
    mailingDate: $mailingDate
    mainImage: $mainImage
    mainImageId: $mainImageId
    mainImageCopyright: $mainImageCopyright
    title: $title
    content: $content
    leftColoumn: $leftColoumn
    rightColoumn: $rightColoumn
    attachment: $attachment
    sent: $sent
    failed: $failed
    state: $state
    showOnWebsite: $showOnWebsite
  ) {
    ...NewsletterMailingFragment
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;
export type UpdateNewsletterMailingMutationFn = Apollo.MutationFunction<UpdateNewsletterMailingMutation, UpdateNewsletterMailingMutationVariables>;

/**
 * __useUpdateNewsletterMailingMutation__
 *
 * To run a mutation, you first call `useUpdateNewsletterMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsletterMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsletterMailingMutation, { data, loading, error }] = useUpdateNewsletterMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newsletterId: // value for 'newsletterId'
 *      items: // value for 'items'
 *      from: // value for 'from'
 *      fromName: // value for 'fromName'
 *      to: // value for 'to'
 *      subject: // value for 'subject'
 *      mailingDate: // value for 'mailingDate'
 *      mainImage: // value for 'mainImage'
 *      mainImageId: // value for 'mainImageId'
 *      mainImageCopyright: // value for 'mainImageCopyright'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      leftColoumn: // value for 'leftColoumn'
 *      rightColoumn: // value for 'rightColoumn'
 *      attachment: // value for 'attachment'
 *      sent: // value for 'sent'
 *      failed: // value for 'failed'
 *      state: // value for 'state'
 *      showOnWebsite: // value for 'showOnWebsite'
 *   },
 * });
 */
export function useUpdateNewsletterMailingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsletterMailingMutation, UpdateNewsletterMailingMutationVariables>) {
        return Apollo.useMutation<UpdateNewsletterMailingMutation, UpdateNewsletterMailingMutationVariables>(UpdateNewsletterMailingDocument, baseOptions);
      }
export type UpdateNewsletterMailingMutationHookResult = ReturnType<typeof useUpdateNewsletterMailingMutation>;
export type UpdateNewsletterMailingMutationResult = Apollo.MutationResult<UpdateNewsletterMailingMutation>;
export type UpdateNewsletterMailingMutationOptions = Apollo.BaseMutationOptions<UpdateNewsletterMailingMutation, UpdateNewsletterMailingMutationVariables>;
export const DeleteNewsletterMailingDocument = gql`
    mutation deleteNewsletterMailing($id: ID!) {
  deleteNewsletterMailing(id: $id) {
    ...NewsletterMailingFragment
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;
export type DeleteNewsletterMailingMutationFn = Apollo.MutationFunction<DeleteNewsletterMailingMutation, DeleteNewsletterMailingMutationVariables>;

/**
 * __useDeleteNewsletterMailingMutation__
 *
 * To run a mutation, you first call `useDeleteNewsletterMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsletterMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsletterMailingMutation, { data, loading, error }] = useDeleteNewsletterMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewsletterMailingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNewsletterMailingMutation, DeleteNewsletterMailingMutationVariables>) {
        return Apollo.useMutation<DeleteNewsletterMailingMutation, DeleteNewsletterMailingMutationVariables>(DeleteNewsletterMailingDocument, baseOptions);
      }
export type DeleteNewsletterMailingMutationHookResult = ReturnType<typeof useDeleteNewsletterMailingMutation>;
export type DeleteNewsletterMailingMutationResult = Apollo.MutationResult<DeleteNewsletterMailingMutation>;
export type DeleteNewsletterMailingMutationOptions = Apollo.BaseMutationOptions<DeleteNewsletterMailingMutation, DeleteNewsletterMailingMutationVariables>;
export const AddNewsletterMailingItemDocument = gql`
    mutation addNewsletterMailingItem($newsletterMailingId: ID!, $image: Upload, $imageId: ID, $imageCopyright: String, $title: String, $content: String, $link: String) {
  addNewsletterMailingItem(
    newsletterMailingId: $newsletterMailingId
    image: $image
    imageId: $imageId
    imageCopyright: $imageCopyright
    title: $title
    content: $content
    link: $link
  ) {
    ...NewsletterMailingItemFragment
  }
}
    ${NewsletterMailingItemFragmentFragmentDoc}`;
export type AddNewsletterMailingItemMutationFn = Apollo.MutationFunction<AddNewsletterMailingItemMutation, AddNewsletterMailingItemMutationVariables>;

/**
 * __useAddNewsletterMailingItemMutation__
 *
 * To run a mutation, you first call `useAddNewsletterMailingItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewsletterMailingItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewsletterMailingItemMutation, { data, loading, error }] = useAddNewsletterMailingItemMutation({
 *   variables: {
 *      newsletterMailingId: // value for 'newsletterMailingId'
 *      image: // value for 'image'
 *      imageId: // value for 'imageId'
 *      imageCopyright: // value for 'imageCopyright'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddNewsletterMailingItemMutation(baseOptions?: Apollo.MutationHookOptions<AddNewsletterMailingItemMutation, AddNewsletterMailingItemMutationVariables>) {
        return Apollo.useMutation<AddNewsletterMailingItemMutation, AddNewsletterMailingItemMutationVariables>(AddNewsletterMailingItemDocument, baseOptions);
      }
export type AddNewsletterMailingItemMutationHookResult = ReturnType<typeof useAddNewsletterMailingItemMutation>;
export type AddNewsletterMailingItemMutationResult = Apollo.MutationResult<AddNewsletterMailingItemMutation>;
export type AddNewsletterMailingItemMutationOptions = Apollo.BaseMutationOptions<AddNewsletterMailingItemMutation, AddNewsletterMailingItemMutationVariables>;
export const UpdateNewsletterMailingItemDocument = gql`
    mutation updateNewsletterMailingItem($id: ID!, $image: Upload, $imageId: ID, $imageCopyright: String, $title: String, $content: String, $link: String, $orderNr: Int) {
  updateNewsletterMailingItem(
    id: $id
    image: $image
    imageId: $imageId
    imageCopyright: $imageCopyright
    title: $title
    content: $content
    link: $link
    orderNr: $orderNr
  ) {
    ...NewsletterMailingItemFragment
  }
}
    ${NewsletterMailingItemFragmentFragmentDoc}`;
export type UpdateNewsletterMailingItemMutationFn = Apollo.MutationFunction<UpdateNewsletterMailingItemMutation, UpdateNewsletterMailingItemMutationVariables>;

/**
 * __useUpdateNewsletterMailingItemMutation__
 *
 * To run a mutation, you first call `useUpdateNewsletterMailingItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsletterMailingItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsletterMailingItemMutation, { data, loading, error }] = useUpdateNewsletterMailingItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      image: // value for 'image'
 *      imageId: // value for 'imageId'
 *      imageCopyright: // value for 'imageCopyright'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      link: // value for 'link'
 *      orderNr: // value for 'orderNr'
 *   },
 * });
 */
export function useUpdateNewsletterMailingItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsletterMailingItemMutation, UpdateNewsletterMailingItemMutationVariables>) {
        return Apollo.useMutation<UpdateNewsletterMailingItemMutation, UpdateNewsletterMailingItemMutationVariables>(UpdateNewsletterMailingItemDocument, baseOptions);
      }
export type UpdateNewsletterMailingItemMutationHookResult = ReturnType<typeof useUpdateNewsletterMailingItemMutation>;
export type UpdateNewsletterMailingItemMutationResult = Apollo.MutationResult<UpdateNewsletterMailingItemMutation>;
export type UpdateNewsletterMailingItemMutationOptions = Apollo.BaseMutationOptions<UpdateNewsletterMailingItemMutation, UpdateNewsletterMailingItemMutationVariables>;
export const DeleteNewsletterMailingItemDocument = gql`
    mutation deleteNewsletterMailingItem($id: ID!) {
  deleteNewsletterMailingItem(id: $id) {
    ...NewsletterMailingItemFragment
  }
}
    ${NewsletterMailingItemFragmentFragmentDoc}`;
export type DeleteNewsletterMailingItemMutationFn = Apollo.MutationFunction<DeleteNewsletterMailingItemMutation, DeleteNewsletterMailingItemMutationVariables>;

/**
 * __useDeleteNewsletterMailingItemMutation__
 *
 * To run a mutation, you first call `useDeleteNewsletterMailingItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsletterMailingItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsletterMailingItemMutation, { data, loading, error }] = useDeleteNewsletterMailingItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewsletterMailingItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNewsletterMailingItemMutation, DeleteNewsletterMailingItemMutationVariables>) {
        return Apollo.useMutation<DeleteNewsletterMailingItemMutation, DeleteNewsletterMailingItemMutationVariables>(DeleteNewsletterMailingItemDocument, baseOptions);
      }
export type DeleteNewsletterMailingItemMutationHookResult = ReturnType<typeof useDeleteNewsletterMailingItemMutation>;
export type DeleteNewsletterMailingItemMutationResult = Apollo.MutationResult<DeleteNewsletterMailingItemMutation>;
export type DeleteNewsletterMailingItemMutationOptions = Apollo.BaseMutationOptions<DeleteNewsletterMailingItemMutation, DeleteNewsletterMailingItemMutationVariables>;
export const RenderNewsletterMailingDocument = gql`
    mutation renderNewsletterMailing($id: ID!) {
  renderNewsletterMailing(id: $id) {
    ...NewsletterMailingFragment
    htmlContent
    sendProcessId
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;
export type RenderNewsletterMailingMutationFn = Apollo.MutationFunction<RenderNewsletterMailingMutation, RenderNewsletterMailingMutationVariables>;

/**
 * __useRenderNewsletterMailingMutation__
 *
 * To run a mutation, you first call `useRenderNewsletterMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenderNewsletterMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renderNewsletterMailingMutation, { data, loading, error }] = useRenderNewsletterMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRenderNewsletterMailingMutation(baseOptions?: Apollo.MutationHookOptions<RenderNewsletterMailingMutation, RenderNewsletterMailingMutationVariables>) {
        return Apollo.useMutation<RenderNewsletterMailingMutation, RenderNewsletterMailingMutationVariables>(RenderNewsletterMailingDocument, baseOptions);
      }
export type RenderNewsletterMailingMutationHookResult = ReturnType<typeof useRenderNewsletterMailingMutation>;
export type RenderNewsletterMailingMutationResult = Apollo.MutationResult<RenderNewsletterMailingMutation>;
export type RenderNewsletterMailingMutationOptions = Apollo.BaseMutationOptions<RenderNewsletterMailingMutation, RenderNewsletterMailingMutationVariables>;
export const SendNewsletterMailingDocument = gql`
    mutation sendNewsletterMailing($id: ID!) {
  sendNewsletterMailing(id: $id) {
    item {
      ...NewsletterMailingFragment
      htmlContent
      sendProcessId
    }
    process {
      errorMessage
      processId
      progress
      returnCode
    }
  }
}
    ${NewsletterMailingFragmentFragmentDoc}`;
export type SendNewsletterMailingMutationFn = Apollo.MutationFunction<SendNewsletterMailingMutation, SendNewsletterMailingMutationVariables>;

/**
 * __useSendNewsletterMailingMutation__
 *
 * To run a mutation, you first call `useSendNewsletterMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNewsletterMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNewsletterMailingMutation, { data, loading, error }] = useSendNewsletterMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendNewsletterMailingMutation(baseOptions?: Apollo.MutationHookOptions<SendNewsletterMailingMutation, SendNewsletterMailingMutationVariables>) {
        return Apollo.useMutation<SendNewsletterMailingMutation, SendNewsletterMailingMutationVariables>(SendNewsletterMailingDocument, baseOptions);
      }
export type SendNewsletterMailingMutationHookResult = ReturnType<typeof useSendNewsletterMailingMutation>;
export type SendNewsletterMailingMutationResult = Apollo.MutationResult<SendNewsletterMailingMutation>;
export type SendNewsletterMailingMutationOptions = Apollo.BaseMutationOptions<SendNewsletterMailingMutation, SendNewsletterMailingMutationVariables>;