import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import StateEdit, {HighlightColors} from './StateEdit'

import { useAppState } from '@lemonbrain/contexts'
import { prepStringWN, required } from '@lemonbrain/utils'
import { useAlert } from '@lemonbrain/hooks'

export default function StateEditBusiness({
  values,
  queryError,
  isLoading,
  removeStateId,
  stateId,
  updateStateEntry,
  createStateEntry,
  setValues,
}: {
  values: {
    isInitState: boolean
    highlightColor: string
    de: string
    it: string
    fr: string
  }
  queryError: any
  isLoading: boolean
  removeStateId: () => void
  stateId?: string | null | undefined
  updateStateEntry: (data: {
    stateId: string
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) => void
  createStateEntry: (data: {
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) => void
  setValues: React.Dispatch<
    React.SetStateAction<{
      isInitState: boolean
      highlightColor: string
      de: string
      fr: string
      it: string
    }>
  >
}) {
  const intl = useIntl()
  const { user } = useAppState()
  const setAlert = useAlert()

  const updateEntry = async ({
    stateId,
    isInitState,
    highlightColor,
    de,
    fr,
    it,
  }: {
    stateId: string
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) => {
    if (user && user.uID && stateId) {
      try {
        await updateStateEntry({
          stateId: prepStringWN(stateId) || stateId,
          isInitState,
          highlightColor,
          de: prepStringWN(de) || de,
          fr: prepStringWN(fr) || fr,
          it: prepStringWN(it) || it,
        })
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateEdit.saved',
              defaultMessage: 'Saved',
            }),
            label: intl.formatMessage({
              id: 'settings.stateEdit.saved.updated',
              defaultMessage: 'Entry is updated',
            }),
            style: 'success',
          })
        updateCancel()
      } catch (e) {
        console.error(e)
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.stateEdit.error.thereWasAServerError',
              defaultMessage: 'There was an server error, try again',
            }),
            style: 'error',
          })
      }
    } else {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateEdit.error.notLoggedIn',
            defaultMessage: 'You are not anymore logged in',
          }),
          style: 'error',
        })
    }
  }

  const createEntry = async ({
    isInitState,
    highlightColor,
    de,
    fr,
    it,
  }: {
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) => {
    if (user && user.uID) {
      try {
        await createStateEntry({
          isInitState,
          highlightColor,
          de: prepStringWN(de) || de,
          fr: prepStringWN(fr) || fr,
          it: prepStringWN(it) || it,
        })
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateEdit.saved',
              defaultMessage: 'Saved',
            }),
            label: intl.formatMessage({
              id: 'settings.stateEdit.saved.created',
              defaultMessage: 'Entry is created',
            }),
            style: 'success',
          })
        updateCancel()
      } catch (e) {
        console.error(e)
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.stateEdit.error.thereWasAServerError',
              defaultMessage: 'There was an server error, try again',
            }),
            style: 'error',
          })
      }
    } else {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateEdit.error.notLoggedIn',
            defaultMessage: 'You are not anymore logged in',
          }),
          style: 'error',
        })
    }
  }

  function updateCancel() {
    removeStateId()
    setValues((prev) => ({
      ...prev,
      highlightColor: HighlightColors.primary,
      de: '',
      fr: '',
      it: '',
    }))
  }

  function checkDe(
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string>>
  ) {
    if (required(de)) {
      setDeError('')
      return false
    }
    setDeError(
      intl.formatMessage({
        id: 'settings.stateEdit.error.deRequired',
        defaultMessage: 'Label (German) is required',
      })
    )
    return true
  }

  function checkFr(
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string>>
  ) {
    if (required(fr)) {
      setFrError('')
      return false
    }
    setFrError(
      intl.formatMessage({
        id: 'settings.stateEdit.error.frRequired',
        defaultMessage: 'Label (French) is required',
      })
    )
    return true
  }

  function checkIt(
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string>>
  ) {
    if (required(it)) {
      setItError('')
      return false
    }
    setItError(
      intl.formatMessage({
        id: 'settings.stateEdit.error.itRequired',
        defaultMessage: 'Label (Italian) is required',
      })
    )
    return true
  }

  function checkHighlightColor(
    highlightColor: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string>>
  ) {
    if (required(highlightColor)) {
      setItError('')
      return false
    }
    setItError(
      intl.formatMessage({
        id: 'settings.stateEdit.error.highlightColorRequired',
        defaultMessage: 'Highlight color is required',
      })
    )
    return true
  }

  function validate(
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string>>,
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string>>,
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string>>,
    highlightColor: string | null | undefined,
    setHighlightColorError: React.Dispatch<React.SetStateAction<string>>
  ) {
    let generalError = false

    generalError = generalError || checkDe(de, setDeError)
    generalError = generalError || checkFr(fr, setFrError)
    generalError = generalError || checkIt(it, setItError)
    generalError = generalError || checkHighlightColor(highlightColor, setHighlightColorError)

    return !generalError
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateEdit.errorMessage',
            defaultMessage: 'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  return (
    <StateEdit
      checkDe={checkDe}
      checkFr={checkFr}
      checkIt={checkIt}
      checkHighlightColor={checkHighlightColor}
      validate={validate}
      values={values}
      isLoading={isLoading}
      updateCancel={updateCancel}
      stateId={stateId}
      updateEntry={updateEntry}
      createEntry={createEntry}
      setValues={setValues}
    />
  )
}
