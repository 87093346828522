import { useState, useEffect } from 'react'

import ValidateEmail from './ValidateEmail'
import { useIntl } from 'react-intl'

import { useCaptcha, useAlert } from '@lemonbrain/hooks'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'

export default function ValidateEmailBusiness ({
    validateEmail,
    goToSignIn,
}: {
    validateEmail: (reCaptchaToken: string) => Promise<"is-valid">,
    goToSignIn: () => void,
}) {
    const setAlert = useAlert()
    const [isLoading, setIsLoading] = useState(false)
    const intl = useIntl()
    const { isLoaded, isError, getCaptchaToken } = useCaptcha()
    const [goToLoginAuto, setGoToLogin] = useState(false)

    async function onSubmit () {
        let state = false
        setIsLoading(true)

        let newState = ''
        try {
            const reCaptchaToken = await getCaptchaToken('validateEmail')
            newState = await validateEmail(reCaptchaToken)
        } catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.validateEmail.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.validateEmail.error.emailValidationFailed', defaultMessage: 'Email validation failed' }),
                style: 'error'
            })
        }
        state = newState === 'is-valid'
        if (state) {
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.validateEmail.success', defaultMessage: 'Success' }),
                label: intl.formatMessage({ id: 'auth.validateEmail.success.emailValidationSuccessfull', defaultMessage: 'The Email validation was successfull' }),
                style: 'success'
            })
        }
        setIsLoading(false)
        if (state) {
            setGoToLogin(true)
        }
        return state
    }

    if (isError) {
        setAlert && setAlert({
            title: intl.formatMessage({ id: 'auth.validateEmail.error', defaultMessage: 'Error' }),
            label: intl.formatMessage({ id: 'auth.validateEmail.error.googleRecaptchaCouldNotBeLoaded', defaultMessage: 'Google ReCaptcha could not be loaded' }),
            style: 'error'
        })
    }

    useEffect(() => {
        if (goToLoginAuto) {
            goToSignIn()
        }
    }, [goToLoginAuto, goToSignIn])

    return isLoaded ?
        (
            <ValidateEmail
                onSubmit={onSubmit}
                isLoading={isLoading}
            />
        ) : (<Loader />)
}
