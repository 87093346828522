import NewslettersItemBusiness from './NewsletterItem.business'

import {
  UpdateNewsletterMailingItemMutationVariables,
  UpdateNewsletterMailingMutationVariables,
} from '../../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../../types'

export default function NewslettersItemData({
  mailingItem,
  setNewsletterMailing,
}: {
  mailingItem:  (UpdateNewsletterMailingItemMutationVariables & {
    imageSrc: string
    task: Types.ArrayUpdate
  })
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: Types.ArrayUpdate
        })[]
      }
    >
  >
}) {
  return <NewslettersItemBusiness
      newsletterMailingItem={mailingItem}
      setNewsletterMailing={setNewsletterMailing}
    />
}
