import { FormattedMessage } from 'react-intl'

import { Block } from 'baseui/block'
import { Button } from "baseui/button";
import { Grid, Cell } from 'baseui/layout-grid'

import { useAppState } from '@lemonbrain/contexts/PageWrapper'

import { SignUpState } from '../../../../types'

export default function AddCourseRegistration({
  member,
  setMember,
  add,
  loading
}: {
  member?: { id: string, label?: string };
  setMember: React.Dispatch<React.SetStateAction<{
    id: string;
    label?: string | undefined;
  } | undefined>>
  add: (signUpState: SignUpState) => Promise<void>
  loading: boolean
}) {
  const { components } = useAppState()
  const MemberSelectorData = components && components['MemberSelectorData']
  return (
    <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
      <Cell span={[12, 12, 12]}>
        <Block paddingTop={['0', '0', '0', '40px']}>
          {MemberSelectorData && (
            //@ts-ignores
            <MemberSelectorData
              //@ts-ignore
              value={member}
              setValue={setMember} />
          )}
          </Block>
      </Cell>
      <Cell span={[6, 6, 6]}>
        <Block paddingTop={['0', '0', '0', '40px']}>
          <Button onClick={() => add(SignUpState.Unconfirmed)} isLoading={loading}>
            <FormattedMessage
              id='courses.addCourseRegistration.add'
              defaultMessage={'Add'}
            />
          </Button>
        </Block>
      </Cell>
      <Cell span={[6, 6, 6]}>
        <Block paddingTop={['0', '0', '0', '40px']}>
          <Button onClick={() => add(SignUpState.WaitingList)} isLoading={loading}>
            <FormattedMessage
              id='courses.addCourseRegistration.addWaitingList'
              defaultMessage={'Add Waiting List'}
            />
          </Button>
        </Block>
      </Cell>
    </Grid>
  )
}
