// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateSignupRecipientMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type CreateSignupRecipientMutation = (
  { __typename?: 'Mutation' }
  & { createSignupRecipient: (
    { __typename?: 'SignupRecipient' }
    & Pick<Types.SignupRecipient, 'id' | 'email'>
  ) }
);

export type DeleteSignupRecipientMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSignupRecipientMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignupRecipient: (
    { __typename?: 'SignupRecipient' }
    & Pick<Types.SignupRecipient, 'email'>
  ) }
);

export type GetSignupRecipientsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSignupRecipientsQuery = (
  { __typename?: 'Query' }
  & { getSignupRecipients?: Types.Maybe<Array<(
    { __typename?: 'SignupRecipient' }
    & Pick<Types.SignupRecipient, 'id' | 'email'>
  )>> }
);

export type CreateMembershipSignupRecipientMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type CreateMembershipSignupRecipientMutation = (
  { __typename?: 'Mutation' }
  & { createMembershipSignupRecipient: (
    { __typename?: 'MembershipSignupRecipient' }
    & Pick<Types.MembershipSignupRecipient, 'id' | 'email'>
  ) }
);

export type DeleteMembershipSignupRecipientMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteMembershipSignupRecipientMutation = (
  { __typename?: 'Mutation' }
  & { deleteMembershipSignupRecipient: (
    { __typename?: 'MembershipSignupRecipient' }
    & Pick<Types.MembershipSignupRecipient, 'email'>
  ) }
);

export type GetMembershipSignupRecipientsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMembershipSignupRecipientsQuery = (
  { __typename?: 'Query' }
  & { getMembershipSignupRecipients?: Types.Maybe<Array<(
    { __typename?: 'MembershipSignupRecipient' }
    & Pick<Types.MembershipSignupRecipient, 'id' | 'email'>
  )>> }
);


export const CreateSignupRecipientDocument = gql`
    mutation createSignupRecipient($email: String!) {
  createSignupRecipient(email: $email) {
    id
    email
  }
}
    `;
export type CreateSignupRecipientMutationFn = Apollo.MutationFunction<CreateSignupRecipientMutation, CreateSignupRecipientMutationVariables>;

/**
 * __useCreateSignupRecipientMutation__
 *
 * To run a mutation, you first call `useCreateSignupRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignupRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignupRecipientMutation, { data, loading, error }] = useCreateSignupRecipientMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateSignupRecipientMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignupRecipientMutation, CreateSignupRecipientMutationVariables>) {
        return Apollo.useMutation<CreateSignupRecipientMutation, CreateSignupRecipientMutationVariables>(CreateSignupRecipientDocument, baseOptions);
      }
export type CreateSignupRecipientMutationHookResult = ReturnType<typeof useCreateSignupRecipientMutation>;
export type CreateSignupRecipientMutationResult = Apollo.MutationResult<CreateSignupRecipientMutation>;
export type CreateSignupRecipientMutationOptions = Apollo.BaseMutationOptions<CreateSignupRecipientMutation, CreateSignupRecipientMutationVariables>;
export const DeleteSignupRecipientDocument = gql`
    mutation deleteSignupRecipient($id: ID!) {
  deleteSignupRecipient(id: $id) {
    email
  }
}
    `;
export type DeleteSignupRecipientMutationFn = Apollo.MutationFunction<DeleteSignupRecipientMutation, DeleteSignupRecipientMutationVariables>;

/**
 * __useDeleteSignupRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteSignupRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignupRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignupRecipientMutation, { data, loading, error }] = useDeleteSignupRecipientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSignupRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignupRecipientMutation, DeleteSignupRecipientMutationVariables>) {
        return Apollo.useMutation<DeleteSignupRecipientMutation, DeleteSignupRecipientMutationVariables>(DeleteSignupRecipientDocument, baseOptions);
      }
export type DeleteSignupRecipientMutationHookResult = ReturnType<typeof useDeleteSignupRecipientMutation>;
export type DeleteSignupRecipientMutationResult = Apollo.MutationResult<DeleteSignupRecipientMutation>;
export type DeleteSignupRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteSignupRecipientMutation, DeleteSignupRecipientMutationVariables>;
export const GetSignupRecipientsDocument = gql`
    query getSignupRecipients {
  getSignupRecipients {
    id
    email
  }
}
    `;

/**
 * __useGetSignupRecipientsQuery__
 *
 * To run a query within a React component, call `useGetSignupRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignupRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignupRecipientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignupRecipientsQuery(baseOptions?: Apollo.QueryHookOptions<GetSignupRecipientsQuery, GetSignupRecipientsQueryVariables>) {
        return Apollo.useQuery<GetSignupRecipientsQuery, GetSignupRecipientsQueryVariables>(GetSignupRecipientsDocument, baseOptions);
      }
export function useGetSignupRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignupRecipientsQuery, GetSignupRecipientsQueryVariables>) {
          return Apollo.useLazyQuery<GetSignupRecipientsQuery, GetSignupRecipientsQueryVariables>(GetSignupRecipientsDocument, baseOptions);
        }
export type GetSignupRecipientsQueryHookResult = ReturnType<typeof useGetSignupRecipientsQuery>;
export type GetSignupRecipientsLazyQueryHookResult = ReturnType<typeof useGetSignupRecipientsLazyQuery>;
export type GetSignupRecipientsQueryResult = Apollo.QueryResult<GetSignupRecipientsQuery, GetSignupRecipientsQueryVariables>;
export const CreateMembershipSignupRecipientDocument = gql`
    mutation createMembershipSignupRecipient($email: String!) {
  createMembershipSignupRecipient(email: $email) {
    id
    email
  }
}
    `;
export type CreateMembershipSignupRecipientMutationFn = Apollo.MutationFunction<CreateMembershipSignupRecipientMutation, CreateMembershipSignupRecipientMutationVariables>;

/**
 * __useCreateMembershipSignupRecipientMutation__
 *
 * To run a mutation, you first call `useCreateMembershipSignupRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipSignupRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembershipSignupRecipientMutation, { data, loading, error }] = useCreateMembershipSignupRecipientMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateMembershipSignupRecipientMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipSignupRecipientMutation, CreateMembershipSignupRecipientMutationVariables>) {
        return Apollo.useMutation<CreateMembershipSignupRecipientMutation, CreateMembershipSignupRecipientMutationVariables>(CreateMembershipSignupRecipientDocument, baseOptions);
      }
export type CreateMembershipSignupRecipientMutationHookResult = ReturnType<typeof useCreateMembershipSignupRecipientMutation>;
export type CreateMembershipSignupRecipientMutationResult = Apollo.MutationResult<CreateMembershipSignupRecipientMutation>;
export type CreateMembershipSignupRecipientMutationOptions = Apollo.BaseMutationOptions<CreateMembershipSignupRecipientMutation, CreateMembershipSignupRecipientMutationVariables>;
export const DeleteMembershipSignupRecipientDocument = gql`
    mutation deleteMembershipSignupRecipient($id: ID!) {
  deleteMembershipSignupRecipient(id: $id) {
    email
  }
}
    `;
export type DeleteMembershipSignupRecipientMutationFn = Apollo.MutationFunction<DeleteMembershipSignupRecipientMutation, DeleteMembershipSignupRecipientMutationVariables>;

/**
 * __useDeleteMembershipSignupRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipSignupRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipSignupRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipSignupRecipientMutation, { data, loading, error }] = useDeleteMembershipSignupRecipientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMembershipSignupRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipSignupRecipientMutation, DeleteMembershipSignupRecipientMutationVariables>) {
        return Apollo.useMutation<DeleteMembershipSignupRecipientMutation, DeleteMembershipSignupRecipientMutationVariables>(DeleteMembershipSignupRecipientDocument, baseOptions);
      }
export type DeleteMembershipSignupRecipientMutationHookResult = ReturnType<typeof useDeleteMembershipSignupRecipientMutation>;
export type DeleteMembershipSignupRecipientMutationResult = Apollo.MutationResult<DeleteMembershipSignupRecipientMutation>;
export type DeleteMembershipSignupRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteMembershipSignupRecipientMutation, DeleteMembershipSignupRecipientMutationVariables>;
export const GetMembershipSignupRecipientsDocument = gql`
    query getMembershipSignupRecipients {
  getMembershipSignupRecipients {
    id
    email
  }
}
    `;

/**
 * __useGetMembershipSignupRecipientsQuery__
 *
 * To run a query within a React component, call `useGetMembershipSignupRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipSignupRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipSignupRecipientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembershipSignupRecipientsQuery(baseOptions?: Apollo.QueryHookOptions<GetMembershipSignupRecipientsQuery, GetMembershipSignupRecipientsQueryVariables>) {
        return Apollo.useQuery<GetMembershipSignupRecipientsQuery, GetMembershipSignupRecipientsQueryVariables>(GetMembershipSignupRecipientsDocument, baseOptions);
      }
export function useGetMembershipSignupRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipSignupRecipientsQuery, GetMembershipSignupRecipientsQueryVariables>) {
          return Apollo.useLazyQuery<GetMembershipSignupRecipientsQuery, GetMembershipSignupRecipientsQueryVariables>(GetMembershipSignupRecipientsDocument, baseOptions);
        }
export type GetMembershipSignupRecipientsQueryHookResult = ReturnType<typeof useGetMembershipSignupRecipientsQuery>;
export type GetMembershipSignupRecipientsLazyQueryHookResult = ReturnType<typeof useGetMembershipSignupRecipientsLazyQuery>;
export type GetMembershipSignupRecipientsQueryResult = Apollo.QueryResult<GetMembershipSignupRecipientsQuery, GetMembershipSignupRecipientsQueryVariables>;