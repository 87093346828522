import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'
import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'

import AssociationEditData from './AssociationsEdit/AssociationEdit.data'
import AssociationEditIfManagesData from './AssociationsEdit/AssociationEditIfMember.data'
import AssociationsTableData from './AssociationsTable/AssociationsTable.data'
import AssociationsTableIfManagesData from './AssociationsTable/AssociationsTableIfMember.data'
import AssociationsFilterData from './AssociationsFilter/AssociationsFilter.data'

export default function AssociationsOverview({
  subNav,
  goToAssociation,
  associationId,
  searchQuery,
  setSearchQuery,
  user,
}: {
  subNav: InnerRoute[]
  goToAssociation: (id: string | null) => void
  associationId: string | undefined
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  user: UserType | undefined
}) {
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <Block>
              <Block>
                <Block
                  paddingTop={['15px', '15px', '15px', 0]}
                  overrides={{
                    Block: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                      },
                    },
                  }}
                >
                  <Block>
                    <Block
                      as='h3'
                      overrides={{
                        Block: {
                          style: ({ $theme }) => {
                            return {
                              ...$theme.typography.font450,
                              color: $theme.colors.primaryA,
                              marginBottom: '15px',
                            }
                          },
                        },
                      }}
                    >
                      <FormattedMessage
                        id='associations.associations_overview'
                        defaultMessage={'Overview'}
                      />
                    </Block>
                  </Block>
                </Block>
              </Block>
            </Block>
            <Block>
              {associationId && parseInt(associationId) > 0 ? (
                user &&
                isInGroupOrAdmin(user, [
                  process.env.REACT_APP_GROUP_MANAGERS || '',
                  process.env.REACT_APP_GROUP_ADMINS || '',
                ]) ? (
                  <AssociationEditData
                  goToAssociation={goToAssociation}
                    associationId={associationId}
                  />
                ) : (
                  <AssociationEditIfManagesData
                  goToAssociation={goToAssociation}
                    associationId={associationId}
                  />
                )
              ) : (
                <>
                  <Block
                    overrides={{
                      Block: {
                        style: () => {
                          return {
                            marginBottom: '15px',
                          }
                        },
                      },
                    }}
                  >
                    <AssociationsFilterData
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                    />
                  </Block>
                  {user &&
                  isInGroupOrAdmin(user, [
                    process.env.REACT_APP_GROUP_MANAGERS || '',
                    process.env.REACT_APP_GROUP_ADMINS || '',
                  ]) ? (
                    <AssociationsTableData
                    goToAssociation={goToAssociation}
                      searchQuery={searchQuery}
                    />
                  ) : (
                    <AssociationsTableIfManagesData
                    goToAssociation={goToAssociation}
                      searchQuery={searchQuery}
                    />
                  )}
                </>
              )}
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
