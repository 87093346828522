import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Spinner, SIZE } from 'baseui/spinner'
import { RadioGroup, Radio, ALIGN } from 'baseui/radio'
import { Select } from 'baseui/select'
import { Block } from 'baseui/block'
import { Textarea } from 'baseui/textarea'

import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'

import { ArrayTasks } from '../../../../gql/members/types'

export default function Association({
  associations,
  setAssociations,
  associationPayTo,
  setAssociationPayTo,
  loadingAssociations,
  associationList,
  associationTypes,
  associationComment,
  setAssociationComment,
  errors,
  user,
  memberExtend,
}: {
  associations?: {
    associationId: string
    associationTypes: {
      id: string
      label: string
      task?: ArrayTasks
    }[]
  }[]
  setAssociations: React.Dispatch<
    React.SetStateAction<
      {
        associationId: string
        associationTypes: {
          id: string
          label: string
          task?: ArrayTasks
        }[]
      }[]
    >
  >
  associationPayTo: { id: string }
  setAssociationPayTo: React.Dispatch<React.SetStateAction<{ id: string }>>
  loadingAssociations: boolean
  associationList: {
    id: string
    label: string
    prize: number
  }[]
  associationTypes: {
    id: string
    label: string
    isDefaultType: boolean
  }[]
  associationComment: string
  setAssociationComment: React.Dispatch<React.SetStateAction<string>>
  errors: {
    association: string
    associationComment: string
  }
  user: UserType | undefined
  memberExtend: {
    id: string
    prize?: number | undefined
    directInvoicing: boolean
  }
}) {
  const intl = useIntl()
  const [associationTypeMapping, setAssociationTypeMapping] = useState<
    {
      assosciationId: string
      associationTypes: {
        id: string
        label: string
        task?: ArrayTasks
      }[]
    }[]
  >(
    associationList.map((item) => ({
      assosciationId: item.id,
      associationTypes: associations?.find(
        (item1) => item1.associationId === item.id
      )?.associationTypes || [
        {
          id: associationTypes.find((item2) => item2.isDefaultType)?.id || '',
          label:
            associationTypes.find((item2) => item2.isDefaultType)?.label || '',
        },
      ],
    }))
  )

  useEffect(() => {
    setAssociationTypeMapping(
      associationList.map((item) => ({
        assosciationId: item.id,
        associationTypes: associations?.find(
          (item1) => item1.associationId === item.id
        )?.associationTypes || [
          {
            id: associationTypes.find((item2) => item2.isDefaultType)?.id || '',
            label:
              associationTypes.find((item2) => item2.isDefaultType)?.label ||
              '',
          },
        ],
      }))
    )
  }, [associationList, associations, associationTypes])

  return (
    <>
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[6, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.association.association',
              defaultMessage: 'Association',
            })}
            error={errors.association}
          >
            <></>
          </FormControl>
        </Cell>
        {user &&
        isInGroupOrAdmin(user, [
          process.env.REACT_APP_GROUP_MANAGERS || '',
          process.env.REACT_APP_GROUP_ADMINS || '',
        ]) ? (
          <Cell span={[6, 6, 4]}>
            <FormControl
              label={intl.formatMessage({
                id: 'members.association.associationType',
                defaultMessage: 'Association type',
              })}
              caption={intl.formatMessage({
                id: 'members.association.beAwareOfMoreRightsForThem',
                defaultMessage:
                  'Be aware that this persons gets maybe more permissions',
              })}
              error={errors.association}
            >
              <></>
            </FormControl>
          </Cell>
        ) : (
          <></>
        )}
        <Cell span={[12, 12, 4]}>
          {memberExtend.prize &&
          memberExtend.prize > 0 &&
          !memberExtend.directInvoicing ? (
            <FormControl
              label={intl.formatMessage({
                id: 'members.association.associationPayTo',
                defaultMessage: 'Pay to',
              })}
              error={errors.association}
            >
              <></>
            </FormControl>
          ) : (
            <></>
          )}
        </Cell>
      </Grid>
      {associationList.map((associationMap) => (
        <Grid
          gridColumns={12}
          gridGutters={15}
          gridMargins={0}
          key={associationMap.id}
        >
          <Cell span={[6, 6, 4]} key={associationMap.id + '1'}>
            {loadingAssociations ? (
              <Spinner $size={SIZE.small} />
            ) : (
              <>
                <Block key={associationMap.id}>
                  <Checkbox
                    value={associationMap.id}
                    checked={
                      associations &&
                      !!associations.find(
                        (item1) => item1.associationId === associationMap.id
                      )
                    }
                    onChange={(e) => {
                      if (associationPayTo.id === '') {
                        setAssociationPayTo({
                          id: (e.target as HTMLInputElement).value,
                        })
                      }
                      setAssociations((prev) => {
                        let hasWorkLevelNew = [...prev]
                        const value = (e.target as HTMLInputElement).value
                        const index = hasWorkLevelNew.findIndex(
                          (item1) => item1.associationId === value
                        )
                        if (index >= 0) {
                          hasWorkLevelNew.splice(index, 1)
                          if (associationPayTo.id === value) {
                            setAssociationPayTo({
                              id: hasWorkLevelNew[0]?.associationId || '',
                            })
                          }
                        } else {
                          hasWorkLevelNew.push({
                            associationId: value,
                            associationTypes:
                              associationTypeMapping.find(
                                (item2) => item2.assosciationId === value
                              )?.associationTypes || [],
                          })
                        }
                        return hasWorkLevelNew
                      })
                    }}
                    labelPlacement={LABEL_PLACEMENT.right}
                    error={errors.association !== ''}
                  >
                    {associationMap.label}
                  </Checkbox>
                </Block>
              </>
            )}
          </Cell>
          {user &&
          isInGroupOrAdmin(user, [
            process.env.REACT_APP_GROUP_MANAGERS || '',
            process.env.REACT_APP_GROUP_ADMINS || '',
          ]) ? (
            <Cell span={[6, 6, 4]} key={associationMap.id + '2'}>
              {loadingAssociations ? (
                <Spinner $size={SIZE.small} />
              ) : (
                <>
                  <Select
                    key={associationMap.id}
                    options={associationTypes.map((item) => ({
                      label: item.label,
                      id: item.id,
                    }))}
                    value={associationTypeMapping
                      .find((item) => item.assosciationId === associationMap.id)
                      ?.associationTypes.map((item) => ({
                        id: associationTypes.find(
                          (item1) => item.id === item1.id
                        )?.id,
                        label: associationTypes.find(
                          (item1) => item.id === item1.id
                        )?.label,
                      }))}
                    multi={true}
                    onChange={(params) => {
                      setAssociationTypeMapping((prev) =>
                        prev.map((item) =>
                          item.assosciationId === associationMap.id
                            ? {
                                assosciationId: item.assosciationId,
                                associationTypes: params.value.map((item) => ({
                                  id: item.id?.toString() || '',
                                  label: item.label?.toString() || '',
                                })),
                              }
                            : item
                        )
                      )
                      setAssociations((prev) => {
                        let hasWorkLevelNew = [...prev]
                        const index = hasWorkLevelNew.findIndex(
                          (item1) => item1.associationId === associationMap.id
                        )
                        if (index >= 0) {
                          hasWorkLevelNew.splice(index, 1)
                          hasWorkLevelNew.push({
                            associationId: associationMap.id,
                            associationTypes: params.value.map((item) => ({
                              id: item.id?.toString() || '',
                              label: item.label?.toString() || '',
                            })),
                          })
                        }
                        return hasWorkLevelNew
                      })
                    }}
                  />
                </>
              )}
            </Cell>
          ) : (
            <></>
          )}
          <Cell span={[12, 12, 4]} key={associationMap.id + '3'}>
            {loadingAssociations ? (
              <Spinner $size={SIZE.small} />
            ) : memberExtend.prize &&
              memberExtend.prize > 0 &&
              !memberExtend.directInvoicing && 
              associationMap.prize > 0 ? (
              <RadioGroup
                name='associationPayTo'
                align={ALIGN.vertical}
                value={associationPayTo.id}
                onChange={(e) => {
                  setAssociationPayTo({
                    id: (e.target as HTMLInputElement).value,
                  })
                  setAssociations((prev) => {
                    let hasAssociationNew = [...prev]
                    const value = (e.target as HTMLInputElement).value
                    const index = hasAssociationNew.findIndex(
                      (item1) => item1.associationId === value
                    )
                    if (index < 0) {
                      hasAssociationNew.push({
                        associationId: value,
                        associationTypes:
                          associationTypeMapping.find(
                            (item) => item.assosciationId === value
                          )?.associationTypes || [],
                      })
                    }
                    return hasAssociationNew
                  })
                }}
              >               
                <Radio
                  key={associationMap.id}
                  value={associationMap.id}
                  overrides={{
                    Label: {
                      style: () => {
                        return {
                          paddingTop: '8px',
                          paddingBottom: '8px',
                        }
                      },
                    },
                  }}
                >
                  {associationMap.label}
                </Radio>
                <></>
              </RadioGroup>
            ) : (
              <></>
            )}
          </Cell>
        </Grid>
      ))}
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 12, 12]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.association.associationComment',
              defaultMessage: 'Association comment',
            })}
            error={errors.associationComment}
          >
            <Textarea
              value={associationComment}
              onChange={(e) =>
                setAssociationComment((e.target as unknown as HTMLInputElement).value)
              }
              clearOnEscape
              error={errors.associationComment !== ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </>
  )
}
