import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'
import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'
import MemberAddFormData from './MemberAddForm/MemberAddForm.data'

export default function MembersAdd({
  subNav,
  addPersonEntry,
  values,
  setValues,
  isLoading
}: {
  subNav: InnerRoute[]
  addPersonEntry: (email: string) => void
  values: { email: string }
  setValues: React.Dispatch<React.SetStateAction<{ email: string }>>
  isLoading: boolean
}) {
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <Block>
              <Block>
                <Block
                  paddingTop={['15px', '15px', '15px', 0]}
                  overrides={{
                    Block: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                      },
                    },
                  }}
                >
                  <Block>
                    <Block
                      as='h3'
                      overrides={{
                        Block: {
                          style: ({ $theme }) => {
                            return {
                              ...$theme.typography.font450,
                              color: $theme.colors.primaryA,
                              marginBottom: '15px',
                            }
                          },
                        },
                      }}
                    >
                      <FormattedMessage
                        id='membersAdd.members_add'
                        defaultMessage={'Add'}
                      />
                    </Block>
                  </Block>
                </Block>
              </Block>
            </Block>
            <Block>
              <EditContainer
                state='create'
                onCancelClick={() => {}}
                onUpdateClick={() => {}}
                onCreateClick={() => {
                  addPersonEntry(values.email)
                }}
                isLoading={isLoading}
              >
                <MemberAddFormData
                  values={values}
                  setValues={setValues}
                />
              </EditContainer>
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
