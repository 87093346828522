import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppState } from '@lemonbrain/contexts'
import { QueryLazyOptions } from '@apollo/client'
import { Value } from 'baseui/select'

import { verifyEmail, required } from '@lemonbrain/utils'
import * as Types from '../../../../gql/members/types'

import Newsletter from './Newsletter'
import { MailingState } from '../../../../gql/members/types'

export default function NewsletterBusiness({
  isOpen,
  setIsOpen,
  mailaddresses,
  getNewsletterMailaddresses,
  loading,
  mailingId,
  addMailing,
  sendMailing,
  mailingState,
  newsletters,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  mailaddresses: string[] | []
  getNewsletterMailaddresses: (
    options?:
      | QueryLazyOptions<
          Types.Exact<{
            id: Types.Scalars
          }>
        >
      | undefined
  ) => void
  loading: boolean
  mailingId: unknown
  addMailing({
    newsletterId,
    from,
    fromName,
    to,
    subject,
  }: {
    newsletterId: number
    from: string
    fromName: string
    to: string[]
    subject: string
  }): void
  sendMailing(id: string): void
  mailingState: {
    sentCount?: number
    failedCount?: number
    recipientCount?: number
    state?: MailingState
    from?: string
    fromName?: string
    to?: string[]
    subject?: string
  }
  newsletters:
    | {
        label: string
        id: string
      }[]
    | undefined
}) {
  const { user } = useAppState()
  const [mailing, setMailing] = useState<{
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
  }>({
    fromEmail: user?.uEmail || '',
    fromNameEmail: user?.uName || '',
    toEmail: mailaddresses.join(', ') || '',
    subject: '',
  })
  const [errors, setErrors] = useState<{
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
  }>({
    fromEmail: '',
    fromNameEmail: '',
    toEmail: '',
    subject: '',
  })
  const [selectedNewsletter, setSelectedNewsletter] = useState<Value>([])
  const intl = useIntl()

  function checkFromEmail() {
    if (verifyEmail(mailing.fromEmail)) {
      setErrors((prev) => ({ ...prev, fromEmail: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      fromEmail: intl.formatMessage({
        id: 'members.newsletter.fromEmailIsRequired',
        defaultMessage:
          'From E-Mail needs to be in the right format and is required',
      }),
    }))
    return true
  }

  function checkFromNameEmail() {
    if (required(mailing.fromNameEmail)) {
      setErrors((prev) => ({ ...prev, fromNameEmail: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      fromNameEmail: intl.formatMessage({
        id: 'members.newsletter.fromNameEmailIsRequired',
        defaultMessage:
          'From E-Mail name needs to be in the right format and is required',
      }),
    }))
    return true
  }

  function checkToEmail() {
    if (required(mailing.toEmail)) {
      setErrors((prev) => ({ ...prev, toEmail: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      toEmail: intl.formatMessage({
        id: 'members.newsletter.toEmailIsRequired',
        defaultMessage: 'To E-Mail is required',
      }),
    }))
    return true
  }

  function checkSubject() {
    if (required(mailing.subject)) {
      setErrors((prev) => ({ ...prev, subject: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      subject: intl.formatMessage({
        id: 'members.newsletter.subjectIsRequired',
        defaultMessage: 'Subject is required',
      }),
    }))
    return true
  }

  function onClick() {
    setIsOpen((prev) => !prev)
  }

  function send() {
    if (mailingId) {
      sendMailing(mailingId as string)
    } else {
      if (selectedNewsletter.length > 0 && selectedNewsletter[0].id) {
      addMailing({
        newsletterId: parseInt(selectedNewsletter[0].id.toString()),
        from: mailing.fromEmail,
        fromName: mailing.fromNameEmail,
        to: mailing.toEmail.split(','),
        subject: mailing.subject,
      })
    }
    }
  }

  useEffect(() => {
    if (!mailing.subject && mailingState.subject) {
      setMailing({
        fromEmail: mailingState.from || '',
        fromNameEmail: mailingState.fromName || '',
        toEmail: (mailingState.to && mailingState.to.join(', ')) || '',
        subject: mailingState.subject || '',
      })
    }
  }, [mailingState])

  useEffect(() => {
    checkFromEmail()
    checkFromNameEmail()
    checkToEmail()
    checkSubject()
  }, [mailing])

  useEffect(() => {
    if (mailaddresses.length > 0) {
      setMailing(prev => ({
        fromEmail: prev.fromEmail || user?.uEmail || '',
        fromNameEmail: prev.fromNameEmail || user?.uName || '',
        toEmail: mailaddresses.join(', ') || '',
        subject: prev.subject || '',
      }))
    }
  }, [mailaddresses])

  useEffect(() => {
    if (selectedNewsletter.length > 0 && selectedNewsletter[0].id) {
      getNewsletterMailaddresses({
        variables: {
          id: selectedNewsletter[0].id.toString() as unknown as Types.Scalars,
        },
      })
    }
  }, [selectedNewsletter])

  return (
    <Newsletter
      mailingState={mailingState}
      loading={loading}
      mailing={mailing}
      setMailing={setMailing}
      errors={errors}
      onClick={onClick}
      send={send}
      isOpen={isOpen}
      mailingId={mailingId}
      newsletters={newsletters}
      selectedNewsletter={selectedNewsletter}
      setSelectedNewsletter={setSelectedNewsletter}
    />
  )
}
