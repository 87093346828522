import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import CoursesOverviewBusiness from './CoursesOverview.business'
import { useSendMailingMutation } from '../../../../invoices/src/gql/members/Mailing.generated'
import getReactApolloCients from '../../localReactApollo'

export default function CoursesOverviewData({ subNav, personId }: { subNav?: InnerRoute[], personId?: string }) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const { courseId } = useParams<{ courseId: string | undefined }>()

  const client = getReactApolloCients().getClient()
  const [ sendMailingMutation ] = useSendMailingMutation({
    //@ts-ignore
    client,
  })

  const goToCourse = function (id: string | null, viewRegistrations: boolean = false) {
    if (id && id !== '') {
      if (viewRegistrations) {
        pushPath(`/${language}/courses/overview/${id}/registrations`)
      } else {
        pushPath(`/${language}/courses/overview/${id}`)
      }
    } else {
      pushPath(`/${language}/courses/overview`)
    }
  }

  const sendMailing =  function (id: string) {
    sendMailingMutation({
      variables: {
        id,
      },
    })
  }

  return <CoursesOverviewBusiness subNav={subNav} goToCourse={goToCourse} courseId={courseId} sendMailing={sendMailing} personId={personId}/>
}
