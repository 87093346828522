import { prepTwoDigits, prepThreeDigits } from './prepare'

export const dateFormatter = (value: number) => {
  const date = new Date(value * 1000)
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
  return `${prepTwoDigits(date.getDate())}.${prepTwoDigits(
    date.getMonth() + 1
  )}.${date.getFullYear()}`
}

export const timeFormatter = (value: number) => {
  const date = new Date(value * 1000)
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
  return `${prepTwoDigits(date.getHours())}:${prepTwoDigits(
    date.getMinutes()
  )}`
}

export const timeFormatterUTC = (value: number) => {
  const date = new Date(value * 1000)
  return `${prepTwoDigits(date.getUTCHours())}:${prepTwoDigits(
    date.getUTCMinutes()
  )}`
}

export const localTimeFormatterSeconds = (value: number) => {
  let isNeg = false
  if(value < 0) {
    value *= -1
    isNeg =true
  }
  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value - hours * 3600) / 60)
  return `${isNeg && (hours > 0 || minutes > 0) ? '-' : ''}${prepThreeDigits(hours)}.${prepTwoDigits(
    Math.floor((100 / 60) * minutes)
  )} h`
}

export const localTimeFormatterSecondsToTime = (value: number) => {
  let isNeg = false
  if(value < 0) {
    value *= -1
    isNeg =true
  }
  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value - hours * 3600) / 60)
  return `${isNeg && (hours > 0 || minutes > 0) ? '-' : ''}${prepThreeDigits(hours)}:${prepTwoDigits(
    minutes
  )}`
}

export const priceFormater= (value: number) => {
  return value.toFixed(2).toString();
}


export function getTranslation(
  translations: {
    de: string
    fr: string
    it: string
  },
  intl: any
) {
  switch (intl.locale) {
    case 'de':
      return translations?.de
      break
    case 'fr':
      return translations?.fr
      break
    case 'it':
      return translations?.it
      break
    default:
      console.error('No Translation found for: ' + translations)
  }
  return ''
}