import AssociationType from './AssociationType'

import { InnerRoute } from '@lemonbrain/hooks'

export default function AssociationTypeBusiness({
  subNav,
  associationTypeId,
  setAssociationTypeId,
}: {
  subNav: InnerRoute[]
  associationTypeId: string | null | undefined
  setAssociationTypeId: (associationTypeId: string) => void
}) {
  return (
    <AssociationType
      subNav={subNav}
      associationTypeId={associationTypeId}
      setAssociationTypeId={setAssociationTypeId}
    />
  )
}
