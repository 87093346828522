// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type WorkLevelFragmentFragment = (
  { __typename?: 'WorkLevel' }
  & Pick<Types.WorkLevel, 'id' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetWorkLevelQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetWorkLevelQuery = (
  { __typename?: 'Query' }
  & { getWorkLevel: (
    { __typename?: 'WorkLevel' }
    & WorkLevelFragmentFragment
  ) }
);

export type AddWorkLevelMutationVariables = Types.Exact<{
  name: Types.TranslationObjectCreate;
}>;


export type AddWorkLevelMutation = (
  { __typename?: 'Mutation' }
  & { addWorkLevel: (
    { __typename?: 'WorkLevel' }
    & WorkLevelFragmentFragment
  ) }
);

export type UpdateWorkLevelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.TranslationObjectUpdate;
}>;


export type UpdateWorkLevelMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkLevel: (
    { __typename?: 'WorkLevel' }
    & WorkLevelFragmentFragment
  ) }
);

export type DeleteWorkLevelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteWorkLevelMutation = (
  { __typename?: 'Mutation' }
  & { deleteWorkLevel: (
    { __typename?: 'WorkLevel' }
    & WorkLevelFragmentFragment
  ) }
);

export type GetWorkLevelsQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetWorkLevelsQuery = (
  { __typename?: 'Query' }
  & { getWorkLevels: (
    { __typename?: 'WorkLevelPagination' }
    & Pick<Types.WorkLevelPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'WorkLevel' }
      & WorkLevelFragmentFragment
    )> }
  ) }
);

export type GetNextWorkLevelsQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextWorkLevelsQuery = (
  { __typename?: 'Query' }
  & { getNextWorkLevels: (
    { __typename?: 'WorkLevelPagination' }
    & Pick<Types.WorkLevelPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'WorkLevel' }
      & WorkLevelFragmentFragment
    )> }
  ) }
);

export const WorkLevelFragmentFragmentDoc = gql`
    fragment WorkLevelFragment on WorkLevel {
  id
  name {
    de
    fr
    it
  }
  createdFrom
}
    `;
export const GetWorkLevelDocument = gql`
    query getWorkLevel($id: ID!) {
  getWorkLevel(id: $id) {
    ...WorkLevelFragment
  }
}
    ${WorkLevelFragmentFragmentDoc}`;

/**
 * __useGetWorkLevelQuery__
 *
 * To run a query within a React component, call `useGetWorkLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkLevelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkLevelQuery(baseOptions: Apollo.QueryHookOptions<GetWorkLevelQuery, GetWorkLevelQueryVariables>) {
        return Apollo.useQuery<GetWorkLevelQuery, GetWorkLevelQueryVariables>(GetWorkLevelDocument, baseOptions);
      }
export function useGetWorkLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkLevelQuery, GetWorkLevelQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkLevelQuery, GetWorkLevelQueryVariables>(GetWorkLevelDocument, baseOptions);
        }
export type GetWorkLevelQueryHookResult = ReturnType<typeof useGetWorkLevelQuery>;
export type GetWorkLevelLazyQueryHookResult = ReturnType<typeof useGetWorkLevelLazyQuery>;
export type GetWorkLevelQueryResult = Apollo.QueryResult<GetWorkLevelQuery, GetWorkLevelQueryVariables>;
export const AddWorkLevelDocument = gql`
    mutation addWorkLevel($name: TranslationObjectCreate!) {
  addWorkLevel(name: $name) {
    ...WorkLevelFragment
  }
}
    ${WorkLevelFragmentFragmentDoc}`;
export type AddWorkLevelMutationFn = Apollo.MutationFunction<AddWorkLevelMutation, AddWorkLevelMutationVariables>;

/**
 * __useAddWorkLevelMutation__
 *
 * To run a mutation, you first call `useAddWorkLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkLevelMutation, { data, loading, error }] = useAddWorkLevelMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddWorkLevelMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkLevelMutation, AddWorkLevelMutationVariables>) {
        return Apollo.useMutation<AddWorkLevelMutation, AddWorkLevelMutationVariables>(AddWorkLevelDocument, baseOptions);
      }
export type AddWorkLevelMutationHookResult = ReturnType<typeof useAddWorkLevelMutation>;
export type AddWorkLevelMutationResult = Apollo.MutationResult<AddWorkLevelMutation>;
export type AddWorkLevelMutationOptions = Apollo.BaseMutationOptions<AddWorkLevelMutation, AddWorkLevelMutationVariables>;
export const UpdateWorkLevelDocument = gql`
    mutation updateWorkLevel($id: ID!, $name: TranslationObjectUpdate!) {
  updateWorkLevel(id: $id, name: $name) {
    ...WorkLevelFragment
  }
}
    ${WorkLevelFragmentFragmentDoc}`;
export type UpdateWorkLevelMutationFn = Apollo.MutationFunction<UpdateWorkLevelMutation, UpdateWorkLevelMutationVariables>;

/**
 * __useUpdateWorkLevelMutation__
 *
 * To run a mutation, you first call `useUpdateWorkLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkLevelMutation, { data, loading, error }] = useUpdateWorkLevelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateWorkLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkLevelMutation, UpdateWorkLevelMutationVariables>) {
        return Apollo.useMutation<UpdateWorkLevelMutation, UpdateWorkLevelMutationVariables>(UpdateWorkLevelDocument, baseOptions);
      }
export type UpdateWorkLevelMutationHookResult = ReturnType<typeof useUpdateWorkLevelMutation>;
export type UpdateWorkLevelMutationResult = Apollo.MutationResult<UpdateWorkLevelMutation>;
export type UpdateWorkLevelMutationOptions = Apollo.BaseMutationOptions<UpdateWorkLevelMutation, UpdateWorkLevelMutationVariables>;
export const DeleteWorkLevelDocument = gql`
    mutation deleteWorkLevel($id: ID!) {
  deleteWorkLevel(id: $id) {
    ...WorkLevelFragment
  }
}
    ${WorkLevelFragmentFragmentDoc}`;
export type DeleteWorkLevelMutationFn = Apollo.MutationFunction<DeleteWorkLevelMutation, DeleteWorkLevelMutationVariables>;

/**
 * __useDeleteWorkLevelMutation__
 *
 * To run a mutation, you first call `useDeleteWorkLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkLevelMutation, { data, loading, error }] = useDeleteWorkLevelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkLevelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkLevelMutation, DeleteWorkLevelMutationVariables>) {
        return Apollo.useMutation<DeleteWorkLevelMutation, DeleteWorkLevelMutationVariables>(DeleteWorkLevelDocument, baseOptions);
      }
export type DeleteWorkLevelMutationHookResult = ReturnType<typeof useDeleteWorkLevelMutation>;
export type DeleteWorkLevelMutationResult = Apollo.MutationResult<DeleteWorkLevelMutation>;
export type DeleteWorkLevelMutationOptions = Apollo.BaseMutationOptions<DeleteWorkLevelMutation, DeleteWorkLevelMutationVariables>;
export const GetWorkLevelsDocument = gql`
    query getWorkLevels($cursor: StartCursorInput!) {
  getWorkLevels(cursor: $cursor) {
    items {
      ...WorkLevelFragment
    }
    nextCursor
  }
}
    ${WorkLevelFragmentFragmentDoc}`;

/**
 * __useGetWorkLevelsQuery__
 *
 * To run a query within a React component, call `useGetWorkLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkLevelsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetWorkLevelsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkLevelsQuery, GetWorkLevelsQueryVariables>) {
        return Apollo.useQuery<GetWorkLevelsQuery, GetWorkLevelsQueryVariables>(GetWorkLevelsDocument, baseOptions);
      }
export function useGetWorkLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkLevelsQuery, GetWorkLevelsQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkLevelsQuery, GetWorkLevelsQueryVariables>(GetWorkLevelsDocument, baseOptions);
        }
export type GetWorkLevelsQueryHookResult = ReturnType<typeof useGetWorkLevelsQuery>;
export type GetWorkLevelsLazyQueryHookResult = ReturnType<typeof useGetWorkLevelsLazyQuery>;
export type GetWorkLevelsQueryResult = Apollo.QueryResult<GetWorkLevelsQuery, GetWorkLevelsQueryVariables>;
export const GetNextWorkLevelsDocument = gql`
    query getNextWorkLevels($cursor: NextCursorInput!) {
  getNextWorkLevels(cursor: $cursor) {
    items {
      ...WorkLevelFragment
    }
    nextCursor
  }
}
    ${WorkLevelFragmentFragmentDoc}`;

/**
 * __useGetNextWorkLevelsQuery__
 *
 * To run a query within a React component, call `useGetNextWorkLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextWorkLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextWorkLevelsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextWorkLevelsQuery(baseOptions: Apollo.QueryHookOptions<GetNextWorkLevelsQuery, GetNextWorkLevelsQueryVariables>) {
        return Apollo.useQuery<GetNextWorkLevelsQuery, GetNextWorkLevelsQueryVariables>(GetNextWorkLevelsDocument, baseOptions);
      }
export function useGetNextWorkLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextWorkLevelsQuery, GetNextWorkLevelsQueryVariables>) {
          return Apollo.useLazyQuery<GetNextWorkLevelsQuery, GetNextWorkLevelsQueryVariables>(GetNextWorkLevelsDocument, baseOptions);
        }
export type GetNextWorkLevelsQueryHookResult = ReturnType<typeof useGetNextWorkLevelsQuery>;
export type GetNextWorkLevelsLazyQueryHookResult = ReturnType<typeof useGetNextWorkLevelsLazyQuery>;
export type GetNextWorkLevelsQueryResult = Apollo.QueryResult<GetNextWorkLevelsQuery, GetNextWorkLevelsQueryVariables>;