import ToggleAll from './ToggleAll'

export default function ToggleAllBusiness({
  selectedInvoices,
  toggleAll,
  loading,
}: {
  selectedInvoices: string[]
  toggleAll: () => void
  loading: boolean
}) {
  return (
    <ToggleAll
      selectedInvoices={selectedInvoices}
      onClick={toggleAll}
      loading={loading}
    />
  )
}
