// @ts-nocheck
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type Auth = {
  __typename?: 'Auth';
  /** When the login was not successfull addintional information is provided here */
  authError?: Maybe<AuthError>;
  /** Includes the current user with uID, uName, uEmail, uDefaultLanguage, uGroupsPath, uAvatar, uIsValidated in the payload */
  authToken: Scalars['String']['output'];
  /** When set, two factor authentication is activated */
  nonce: Scalars['String']['output'];
};

export type AuthError = {
  __typename?: 'AuthError';
  /** Type of the error */
  type: Scalars['String']['output'];
  /** The user name of the user on which the login failed */
  uName?: Maybe<Scalars['String']['output']>;
};

export type CursorInput = {
  filtered?: InputMaybe<Array<FilterInput>>;
  offset: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sorted?: InputMaybe<Array<SortInput>>;
};

export type FilterInput = {
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Groups = {
  name: Scalars['String']['input'];
  task: Task;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Returns an error array or an empty array */
  changePassword: Array<Scalars['String']['output']>;
  /** if you use https://www.concrete5.org/marketplace/addons/two-factor-login-security you can check the nonce with this function */
  checkNonce: Auth;
  /** Returns result (id, userName and email), a validationErrors array */
  createUser: UserResult;
  /** Returns an error array or an empty array */
  forgotPassword: Array<Scalars['String']['output']>;
  /** Login a user. Request for an authToken and refreshToken in response */
  login: Auth;
  /** Logout an user. */
  logout: Scalars['Boolean']['output'];
  /** sends a validation email to the emailaddress of the user */
  sendValidationEmail: Scalars['Boolean']['output'];
  sendValidationEmailById: Scalars['Boolean']['output'];
  /** Returns result (id, userName and email), a validationErrors array */
  updateUser: UserResult;
  updateUserById: UserResult;
  /** validates the users emailaddress trough the token in the validation email */
  validateEmail: Scalars['Boolean']['output'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String']['input'];
  passwordConfirm?: InputMaybe<Scalars['String']['input']>;
  reCaptchaToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCheckNonceArgs = {
  nonce: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  u2SAPass: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  groups?: InputMaybe<Array<InputMaybe<Groups>>>;
  password: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  removeAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  userLocale?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationForgotPasswordArgs = {
  changePasswordUrl: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationSendValidationEmailArgs = {
  reCaptchaToken: Scalars['String']['input'];
  template?: InputMaybe<Scalars['String']['input']>;
  uName: Scalars['String']['input'];
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendValidationEmailByIdArgs = {
  id: Scalars['ID']['input'];
  reCaptchaToken: Scalars['String']['input'];
  template?: InputMaybe<Scalars['String']['input']>;
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Groups>>>;
  password?: InputMaybe<Scalars['String']['input']>;
  removeAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  userLocale?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserByIdArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Groups>>>;
  id: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  userLocale?: InputMaybe<Scalars['String']['input']>;
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationValidateEmailArgs = {
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** gql file without query is not working :( */
  dummy?: Maybe<Scalars['String']['output']>;
  getDisplayName: Scalars['String']['output'];
  getDisplayNameById: Scalars['String']['output'];
  getUser: UserExtended;
  getUserById: UserExtended;
  getUsers: Array<UserExtended>;
};


export type QueryGetDisplayNameArgs = {
  username: Scalars['String']['input'];
};


export type QueryGetDisplayNameByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUserArgs = {
  username: Scalars['String']['input'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUsersArgs = {
  cursor: CursorInput;
};

export type SortInput = {
  asc: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export enum Task {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type User = UserBasic & {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  uAvatar: Scalars['String']['output'];
  uDefaultLanguage?: Maybe<Scalars['String']['output']>;
  uEmail: Scalars['String']['output'];
  uID: Scalars['ID']['output'];
  uName: Scalars['String']['output'];
};

export type UserBasic = {
  id: Scalars['ID']['output'];
  uAvatar: Scalars['String']['output'];
  uDefaultLanguage?: Maybe<Scalars['String']['output']>;
  uEmail: Scalars['String']['output'];
  uID: Scalars['ID']['output'];
  uName: Scalars['String']['output'];
};

export type UserExtended = UserBasic & {
  __typename?: 'UserExtended';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  uAvatar: Scalars['String']['output'];
  uDefaultLanguage?: Maybe<Scalars['String']['output']>;
  uEmail: Scalars['String']['output'];
  uGroupsPath: Array<Maybe<Scalars['String']['output']>>;
  uID: Scalars['ID']['output'];
  uName: Scalars['String']['output'];
};

export type UserResult = {
  __typename?: 'UserResult';
  result?: Maybe<User>;
  validationErrors: Array<Maybe<Scalars['String']['output']>>;
};
