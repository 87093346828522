import { useEffect, useState } from 'react'
import { Cell, Grid } from 'baseui/layout-grid'
import { useIntl, FormattedMessage } from 'react-intl'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Checkbox } from 'baseui/checkbox'
import { RadioGroup, Radio, ALIGN } from 'baseui/radio'

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

export enum HighlightColors {
  neutral='neutral',
  primary = 'primary',
  positive='positive',
  warning='warning',
  negative='negative',
  accent='accent',
  purple='purple',
  yellow='yellow',
}

export default function StateEdit({
  values,
  updateEntry,
  createEntry,
  updateCancel,
  isLoading,
  stateId,
  checkDe,
  checkFr,
  checkIt,
  checkHighlightColor,
  validate,
  setValues,
}: {
  values: {
    isInitState: boolean
    highlightColor: string
    de: string
    it: string
    fr: string
  }
  updateEntry: ({
    stateId,
    isInitState,
    highlightColor,
    de,
    fr,
    it,
  }: {
    stateId: string
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) => void
  createEntry: ({
    isInitState,
    highlightColor,
    de,
    fr,
    it,
  }: {
    isInitState: boolean
    highlightColor: string
    de: string
    fr: string
    it: string
  }) => void
  updateCancel: () => void
  isLoading: boolean
  stateId?: string | null | undefined
  checkDe: (
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string>>
  ) => boolean
  checkFr: (
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string>>
  ) => boolean
  checkIt: (
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string>>
  ) => boolean
  checkHighlightColor: (
    highlightColor: string | null | undefined,
    setHighlightColorError: React.Dispatch<React.SetStateAction<string>>
  ) => boolean
  validate: (
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string>>,
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string>>,
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string>>,
    highlightColor: string | null | undefined,
    setHighlightColorError: React.Dispatch<React.SetStateAction<string>>,
  ) => boolean
  setValues: React.Dispatch<
    React.SetStateAction<{
      isInitState: boolean
      highlightColor: string
      de: string
      fr: string
      it: string
    }>
  >
}) {
  const intl = useIntl()
  const [deError, setDeError] = useState('')
  const [frError, setFrError] = useState('')
  const [itError, setItError] = useState('')
  const [highlightColorError, setHighlightColorError] = useState('')

  function onUpdateClick() {
    if (
      values &&
      stateId &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError,
        values.highlightColor,
        setHighlightColorError,
      )
    ) {
      updateEntry({
        stateId,
        isInitState: values.isInitState,
        highlightColor: values.highlightColor,
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  function onCopyClick() {
    if (
      values &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError,
        values.highlightColor,
        setHighlightColorError,
      )
    ) {
      createEntry({
        isInitState: values.isInitState,
        highlightColor: values.highlightColor,
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  function onCancelClick() {
    updateCancel()
  }

  function onCreateClick() {
    if (
      values &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError,
        values.highlightColor,
        setHighlightColorError,
      )
    ) {
      createEntry({
        isInitState: values.isInitState,
        highlightColor: values.highlightColor,
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  useEffect(() => {
    if (values) {
      checkDe(values.de, setDeError)
      checkFr(values.fr, setFrError)
      checkIt(values.it, setItError)
      checkHighlightColor(values.highlightColor, setHighlightColorError)
    }
  }, [values])

  return (
    <EditContainer
      state={!!stateId ? 'update' : 'create'}
      onUpdateClick={onUpdateClick}
      onCopyClick={onCopyClick}
      onCancelClick={onCancelClick}
      onCreateClick={onCreateClick}
      isLoading={isLoading}
    >
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateEdit.isInitState',
              defaultMessage: 'Init state',
            })}
          >
            <Checkbox
              checked={(values && values.isInitState) || false}
              onChange={() =>
                setValues((prev) => ({
                  ...prev,
                  isInitState: !prev.isInitState,
                }))
              }
            ></Checkbox>
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateEdit.highlightColor',
              defaultMessage: 'Highlight color',
            })}
            error={highlightColorError}
          >
            <RadioGroup
              value={values.highlightColor}
              onChange={(event) =>
                setValues((prev) => ({
                  ...prev,
                  highlightColor: (event?.target as HTMLInputElement).value,
                }))
              }
              name='higlightColor'
              align={ALIGN.horizontal}
              error={highlightColorError !== ''}
            >
              <Radio value={HighlightColors.primary}>
                <FormattedMessage
                  id='settings.stateEdit.default'
                  defaultMessage='Default'
                />
              </Radio>
              <Radio value={HighlightColors.positive}>
                <FormattedMessage
                  id='settings.stateEdit.green'
                  defaultMessage='Green'
                />
              </Radio>
              <Radio value={HighlightColors.warning}>
                <FormattedMessage
                  id='settings.stateEdit.orange'
                  defaultMessage='Orange'
                />
              </Radio>
              <Radio value={HighlightColors.negative}>
                <FormattedMessage
                  id='settings.stateEdit.red'
                  defaultMessage='Red'
                />
              </Radio>
              <Radio value={HighlightColors.accent}>
                <FormattedMessage
                  id='settings.stateEdit.blue'
                  defaultMessage='Blue'
                />
              </Radio>
              <Radio value={HighlightColors.purple}>
                <FormattedMessage
                  id='settings.stateEdit.purple'
                  defaultMessage='Purple'
                />
              </Radio>
              <Radio value={HighlightColors.yellow}>
                <FormattedMessage
                  id='settings.stateEdit.yellow'
                  defaultMessage='Yellow'
                />
              </Radio>
            </RadioGroup>
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateEdit.de',
              defaultMessage: 'Label (German)',
            })}
            error={deError}
          >
            <Input
              value={(values && values.de) || undefined}
              //@ts-ignore
              onChange={(de) =>
                setValues((prev) => ({
                  ...prev,
                  de: (de?.target as HTMLInputElement).value,
                }))
              }
              positive={deError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateEdit.fr',
              defaultMessage: 'Label (French)',
            })}
            error={frError}
          >
            <Input
              value={(values && values.fr) || undefined}
              //@ts-ignore
              onChange={(fr) =>
                setValues((prev) => ({
                  ...prev,
                  fr: (fr?.target as HTMLInputElement).value,
                }))
              }
              positive={frError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateEdit.it',
              defaultMessage: 'Label (Italian)',
            })}
            error={itError}
          >
            <Input
              value={(values && values.it) || undefined}
              //@ts-ignore
              onChange={(it) =>
                setValues((prev) => ({
                  ...prev,
                  it: (it?.target as HTMLInputElement).value,
                }))
              }
              positive={itError === ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </EditContainer>
  )
}
