import { useState } from 'react'
import { SubscriptionClient } from 'subscriptions-transport-ws'

let defaultWsClients: SubscriptionClient[] = []
export const addWsClientBeforeRender = function (wsClient: SubscriptionClient) {
    defaultWsClients = [...defaultWsClients, wsClient]
}

export const useWsClients = () => {
    const [wsClients, setWsClient] = useState(defaultWsClients)

    const addWsClient = function (wsClient: SubscriptionClient) {
        setWsClient(prev => ({ ...prev, wsClient }))
    }

    return {
        addWsClient,
        wsClients
    }
}
