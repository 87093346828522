import { FormattedMessage, useIntl } from 'react-intl'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Notification, KIND } from 'baseui/notification'
import { Select, Value } from 'baseui/select'
import { Textarea, SIZE } from 'baseui/textarea'
import { Datepicker } from 'baseui/datepicker'
import de from 'date-fns/locale/de'

import EditContainer, {
  STATE,
} from '@lemonbrain/components/UiElements/EditContainer/EditContainer'
import { UpdateCourseMutationVariables } from '../../../gql/Courses.generated'
import {
  getLanguageOptions,
} from '@lemonbrain/utils'
import {
  getCourseContentCategoryOptions,
} from '../../../options/CourseContentCategories'
import {
  getCourseOptions,
} from '../../../options/CourseOptions'

export default function CoursesEdit({
  courseStates,
  workLevels,
  goToCourse,
  courseId,
  isLoading,
  onUpdateClick,
  onCopyClick,
  onCancelClick,
  course,
  setCourse,
  errors,
}: {
  courseStates: {
    id: string
    label: string
  }[]
  workLevels: {
    id: string
    label: string
  }[]
  goToCourse: (id: string | null) => void
  courseId?: string
  isLoading: boolean
  onUpdateClick: () => void
  onCopyClick: () => void
  onCancelClick: () => void
  course: UpdateCourseMutationVariables & {
    priceText: string
    memberPriceText: string
  }
  setCourse: React.Dispatch<React.SetStateAction<UpdateCourseMutationVariables & {
    priceText: string
    memberPriceText: string
  }>>
  errors: {
    language: string
    educationalStage: string
    contentCategory: string
    number: string
    title: string
    description: string
    price: string
    memberPrice: string
    teacher: string
    targetGroup: string
    date: string
    endDate: string
    place: string
    subscribeTill: string
    minSubscribers: string
    maxSubscribers: string
    options: string
    status: string
  }
}) {
  const intl = useIntl()

  return (
    <>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
        <Button
          onClick={() => goToCourse(null)}
          startEnhancer={() => <ArrowLeft size={24} />}
        >
          <FormattedMessage
            id='courses.courses.toOverview'
            defaultMessage={'To the overview'}
          />
        </Button>
      </Block>
      <EditContainer
        state={courseId ? STATE.UPDATE : STATE.CREATE}
        onUpdateClick={onUpdateClick}
        onCreateClick={onUpdateClick}
        onCopyClick={onCopyClick}
        onCancelClick={onCancelClick}
        isLoading={isLoading}
      >

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.status',
            defaultMessage: 'Status',
          })}
          error={errors.status}
        >
          <Input
            value={
              (courseStates.find(
                (item) => item.id === course.status
              )?.label) || (courseStates[0].label)
            }
            readOnly
          />
        </FormControl>


        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.language',
            defaultMessage: 'Language',
          })}
          error={errors.language}
        >
          <Select
            options={getLanguageOptions(intl)}
            value={
              ((getLanguageOptions(intl).find(
                (item) => item.id === course.language
              ) as unknown) as Value) || undefined
            }
            placeholder={intl.formatMessage({
              id: 'courses.courseEdit.selectLanguage',
              defaultMessage: 'Select a language',
            })}
            onChange={({ value }: { value: Value }) => {
              setCourse((prev) => ({
                ...prev,
                language: value[0].id as string,
              }))
            }}
            positive={errors.language === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.educationalStage',
            defaultMessage: 'Education stage',
          })}
          error={errors.educationalStage}
        >
          <Select
            options={workLevels}
            value={
              ((workLevels.filter(
                (item) => course.educationalStageIds?.includes(item.id)
              ) as unknown) as Value) || undefined
            }
            multi
            placeholder={intl.formatMessage({
              id: 'courses.courseEdit.selectEducationalStage',
              defaultMessage: 'Select a educational stage',
            })}
            onChange={({ value }: { value: Value }) => {
              setCourse((prev) => ({
                ...prev,
                educationalStageIds: value.map(item => item.id as string),
              }))
            }}
            positive={errors.educationalStage === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.contentCategory',
            defaultMessage: 'Content category',
          })}
          error={errors.contentCategory}
        >
          <Select
            options={getCourseContentCategoryOptions(intl)}
            value={
              ((getCourseContentCategoryOptions(intl).find(
                (item) => item.id === course.contentCategory
              ) as unknown) as Value) || undefined
            }
            placeholder={intl.formatMessage({
              id: 'courses.courseEdit.selectContentCategory',
              defaultMessage: 'Select a content category',
            })}
            onChange={({ value }: { value: Value }) => {
              setCourse((prev) => ({
                ...prev,
                contentCategory: value[0].id as string,
              }))
            }}
            positive={errors.contentCategory === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.number',
            defaultMessage: 'Number',
          })}
          error={errors.number}
        >
          <Input
            value={course.number || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                number: parseInt((e.target as HTMLInputElement).value),
              }))
            }
            type='number'
            positive={errors.number === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.title.de',
            defaultMessage: 'Title German',
          })}
          error={errors.title}
        >
          <Input
            value={course.title?.de || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                title: {
                  ...prev.title,
                  de: (e.target as HTMLInputElement).value,
                }
              }))
            }
            positive={errors.title === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.title.fr',
            defaultMessage: 'Title French',
          })}
          error={errors.title}
        >
          <Input
            value={course.title?.fr || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                title: {
                  ...prev.title,
                  fr: (e.target as HTMLInputElement).value,
                }
              }))
            }
            positive={errors.title === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.title.it',
            defaultMessage: 'Title Italian',
          })}
          error={errors.title}
        >
          <Input
            value={course.title?.it || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                title: {
                  ...prev.title,
                  it: (e.target as HTMLInputElement).value,
                }
              }))
            }
            positive={errors.title === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.shortDescription.de',
            defaultMessage: 'Short description German',
          })}
          error={errors.description}
        >
          <Textarea
            size={SIZE.compact}
            value={course.description?.de || ''}
            overrides={{
              Input: {
                style: {
                  minHeight: '150px',
                  minWidth: '100%',
                  resize: 'vertical'
                }
              }
            }}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                description: {
                  ...prev.description,
                  de: (e.target as HTMLTextAreaElement).value,
                }
              }))
            }
            positive={errors.description === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.shortDescription.fr',
            defaultMessage: 'Short description French',
          })}
          error={errors.description}
        >
          <Textarea
            size={SIZE.compact}
            value={course.description?.fr || ''}
            overrides={{
              Input: {
                style: {
                  minHeight: '150px',
                  minWidth: '100%',
                  resize: 'vertical'
                }
              }
            }}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                description: {
                  ...prev.description,
                  fr: (e.target as HTMLTextAreaElement).value,
                }
              }))
            }
            positive={errors.description === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.shortDescription.it',
            defaultMessage: 'Short description Italian',
          })}
          error={errors.description}
        >
          <Textarea
            size={SIZE.compact}
            value={course.description?.it || ''}
            overrides={{
              Input: {
                style: {
                  minHeight: '150px',
                  minWidth: '100%',
                  resize: 'vertical'
                }
              }
            }}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                description: {
                  ...prev.description,
                  it: (e.target as HTMLTextAreaElement).value,
                }
              }))
            }
            positive={errors.description === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.memberPrice',
            defaultMessage: 'Member price',
          })}
          error={errors.memberPrice}
        >
          <Input
            value={course && course.memberPriceText || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                memberPriceText: (e.target as HTMLInputElement).value
              }))
            }
            positive={errors.memberPrice === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.price',
            defaultMessage: 'Price',
          })}
          error={errors.price}
        >
          <Input
            value={course && course.priceText || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                priceText: (e.target as HTMLInputElement).value
              }))
            }
            positive={errors.price === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.teacher',
            defaultMessage: 'Teacher',
          })}
          error={errors.teacher}
        >
          <Input
            value={course.teacher || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                teacher: (e.target as HTMLInputElement).value,
              }))
            }
            positive={errors.teacher === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.targetGroup',
            defaultMessage: 'Target group',
          })}
          error={errors.targetGroup}
        >
          <Textarea
            size={SIZE.compact}
            value={course.targetGroup || ''}
            overrides={{
              Input: {
                style: {
                  minHeight: '150px',
                  minWidth: '100%',
                  resize: 'vertical'
                }
              }
            }}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                targetGroup: (e.target as HTMLTextAreaElement).value,
              }))
            }
            positive={errors.targetGroup === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.special.de',
            defaultMessage: 'Special German',
          })}
        >
          <Textarea
            size={SIZE.compact}
            value={course.special?.de || ''}
            overrides={{
              Input: {
                style: {
                  minHeight: '150px',
                  minWidth: '100%',
                  resize: 'vertical'
                }
              }
            }}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                special: {
                  ...prev.special,
                  de: (e.target as HTMLTextAreaElement).value,
                }
              }))
            }
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.special.fr',
            defaultMessage: 'Special French',
          })}
        >
          <Textarea
            size={SIZE.compact}
            value={course.special?.fr || ''}
            overrides={{
              Input: {
                style: {
                  minHeight: '150px',
                  minWidth: '100%',
                  resize: 'vertical'
                }
              }
            }}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                special: {
                  ...prev.special,
                  fr: (e.target as HTMLTextAreaElement).value,
                }
              }))
            }
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.special.it',
            defaultMessage: 'Special Italian',
          })}
        >
          <Textarea
            size={SIZE.compact}
            value={course.special?.it || ''}
            overrides={{
              Input: {
                style: {
                  minHeight: '150px',
                  minWidth: '100%',
                  resize: 'vertical'
                }
              }
            }}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                special: {
                  ...prev.special,
                  it: (e.target as HTMLTextAreaElement).value,
                }
              }))
            }
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.date',
            defaultMessage: 'Date',
          })}
          error={errors.date}
        >
          <Datepicker
            locale={de}
            onChange={({ date }) =>
              setCourse((prev) => ({
                ...prev,
                //@ts-ignore
                date: date ? date.toISOString() : undefined,
              }))
            }
            value={
              course && course.date
                ? new Date(course.date)
                : undefined
            }
            formatString='dd.MM.yyyy'
            placeholder='dd.MM.yyyy'
            mask={null}
            positive={errors.date === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.time',
            defaultMessage: 'Time',
          })}
        >
          <Input
            value={course.time || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                time: (e.target as HTMLInputElement).value,
              }))
            }
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.endDate',
            defaultMessage: 'Enddate',
          })}
          error={errors.endDate}
        >
          <Datepicker
            locale={de}
            onChange={({ date }) =>
              setCourse((prev) => ({
                ...prev,
                //@ts-ignore
                endDate: date ? date.toISOString() : undefined,
              }))
            }
            value={
              course && course.endDate
                ? new Date(course.endDate)
                : undefined
            }
            formatString='dd.MM.yyyy'
            placeholder='dd.MM.yyyy'
            mask={null}
            positive={errors.endDate === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.place',
            defaultMessage: 'Place',
          })}
          error={errors.place}
        >
          <Input
            value={course.place || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                place: (e.target as HTMLInputElement).value,
              }))
            }
            positive={errors.place === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.subscribeTill',
            defaultMessage: 'Subscribe till',
          })}
          error={errors.subscribeTill}
        >
          <Datepicker
            locale={de}
            onChange={({ date }) =>
              setCourse((prev) => ({
                ...prev,
                //@ts-ignore
                subscribeTill: date ? date.toISOString() : undefined,
              }))
            }
            value={
              course && course.subscribeTill
                ? new Date(course.subscribeTill)
                : undefined
            }
            formatString='dd.MM.yyyy'
            placeholder='dd.MM.yyyy'
            mask={null}
            positive={errors.subscribeTill === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.minSubscribers',
            defaultMessage: 'Min subscribers',
          })}
          error={errors.minSubscribers}
        >
          <Input
            value={course.minSubscribers || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                minSubscribers: parseInt((e.target as HTMLInputElement).value),
              }))
            }
            type='number'
            positive={errors.minSubscribers === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.maxSubscribers',
            defaultMessage: 'Max subscribers',
          })}
          error={errors.maxSubscribers}
        >
          <Input
            value={course.maxSubscribers || ''}
            onChange={(e) =>
              setCourse((prev) => ({
                ...prev,
                maxSubscribers: parseInt((e.target as HTMLInputElement).value),
              }))
            }
            type='number'
            positive={errors.maxSubscribers === ''}
          />
        </FormControl>

        <FormControl
          label={intl.formatMessage({
            id: 'courses.courseEdit.options',
            defaultMessage: 'Options',
          })}
          error={errors.options}
        >
          <Select
            options={getCourseOptions(intl)}
            value={
              ((getCourseOptions(intl).filter(
                (item) => course.options?.includes(item.id)
              ) as unknown) as Value) || undefined
            }
            multi
            placeholder={intl.formatMessage({
              id: 'courses.courseEdit.selectOption',
              defaultMessage: 'Select a option',
            })}
            onChange={({ value }: { value: Value }) => {
              setCourse((prev) => ({
                ...prev,
                options: value.map(item => item.id as string),
              }))
            }}
            positive={errors.options === ''}
          />
        </FormControl>
      </EditContainer>
      {Object.values(errors).find((item) => item !== '') && (
        <Notification kind={KIND.negative}>
          <ul>
            {Object.keys(errors).map(
              (item) =>
                (errors as { [key: string]: string })[item] && (
                  <li key={item}>
                    {(errors as { [key: string]: string })[item]}
                  </li>
                )
            )}
          </ul>
        </Notification>
      )}
    </>
  )
}
