import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'
import { Tabs, Tab, FILL } from 'baseui/tabs-motion'
import { Notification, KIND } from 'baseui/notification'
import { StyledLink } from 'baseui/link'

import EditContainer, {
  STATE,
} from '@lemonbrain/components/UiElements/EditContainer/EditContainer'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'
import { getTranslation } from '@lemonbrain/utils'
import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'
import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'
import { useAppState } from '@lemonbrain/contexts/PageWrapper'

import PersonalBusiness from './Personal/Personal.business'
import AddressData from './Address/Address.data'
import SportsEducationData from './SportsEducation/SportsEducation.data'
import WorkLevelData from './WorkLevel/WorkLevel.data'
import StateFunctionData from './StateFunction/StateFunction.data'
import SvssFunctionData from './SvssFunction/SvssFunction.data'
import AssociationData from './Association/Association.data'
import StateData from './State/State.data'
import CommentData from './Comment/Comment.data'
import { ArrayTasks } from '../../../gql/members/types'
import { ButtonGroup } from 'baseui/button-group'
import NewsletterData from './Newsletter/Newsletter.data'

export default function MembersEdit({
  goToPerson,
  goToCourses,
  goToInvoices,
  isLoading,
  onUpdateClick,
  onCancelClick,
  personal,
  setPersonal,
  getHasPersonalErrors,
  addresses,
  setAddress,
  errors,
  getHasAddressErrors,
  sportsEducation,
  setSportsEducation,
  getHasSportsEducationErrors,
  workLevel,
  setWorkLevel,
  stateFunctions,
  setStateFunctions,
  svssFunctions,
  setSvssFunctions,
  newsletters,
  setNewsletters,
  getHasWorkLevelErrors,
  associations,
  setAssociations,
  associationPayTo,
  setAssociationPayTo,
  getHasAssociationErrors,
  state,
  setState,
  stateType,
  setStateType,
  membership,
  setMembership,
  associationComment,
  setAssociationComment,
  getHasStateErrors,
  comment,
  setComment,
  user,
  personId,
  askForSave,
  setAskForSave,
  save,
}: {
  goToPerson: (id: string | null) => void
  goToCourses: (id: string | null) => void
  goToInvoices: (id: string | null) => void
  isLoading: boolean
  onUpdateClick: () => void
  onCancelClick: () => void
  personal:
  | {
    id: string
    authId?: string
    email: string
    birthdate: string
    telephone: string
    mobile: string
    ahvNumber: string
    jsNumber: string
    slrgNumber: string
  }
  | undefined
  setPersonal: React.Dispatch<
    React.SetStateAction<
      | {
        id: string
        authId?: string
        email: string
        birthdate: string
        telephone: string
        mobile: string
        ahvNumber: string
        jsNumber: string
        slrgNumber: string
      }
      | undefined
    >
  >
  getHasPersonalErrors: (errors: { email: string; birthdate: string }) => void
  addresses: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      isFirm?: boolean
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }[]
  setAddress: (address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }) => void
  errors: string[]
  getHasAddressErrors: (
    addressTypeName: string,
    errors: {
      salutation: string
      firstName: string
      lastName: string
      firmName: string
      streetNr: string
      postCode: string
      place: string
      canton: string
      country: string
    }
  ) => void
  sportsEducation?: { id: string }[]
  setSportsEducation: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  getHasSportsEducationErrors: (errors: { sportsEducation: string }) => void
  workLevel?: { id: string }[]
  setWorkLevel: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  stateFunctions?: { id: string }[]
  setStateFunctions: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  svssFunctions?: { id: string }[]
  setSvssFunctions: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  newsletters?: { id: string }[]
  setNewsletters: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  getHasWorkLevelErrors: (errors: { workLevel: string }) => void
  associations?: {
    associationId: string
    associationTypes: {
      id: string
      label: string
      task?: ArrayTasks
    }[]
  }[]
  setAssociations: React.Dispatch<
    React.SetStateAction<
      {
        associationId: string
        associationTypes: {
          id: string
          label: string
          task?: ArrayTasks
        }[]
      }[]
    >
  >
  associationPayTo: { id: string }
  setAssociationPayTo: React.Dispatch<React.SetStateAction<{ id: string }>>
  getHasAssociationErrors: (errors: {
    association: string
    associationPayTo: string
  }) => void
  state?: string
  setState: React.Dispatch<React.SetStateAction<string>>
  stateType?: string
  setStateType: React.Dispatch<React.SetStateAction<string>>
  membership: string
  setMembership: React.Dispatch<React.SetStateAction<string>>
  associationComment: string
  setAssociationComment: React.Dispatch<React.SetStateAction<string>>
  getHasStateErrors: (errors: {
    state: string
    stateType: string
    membership: string
  }) => void
  comment: string
  setComment: React.Dispatch<React.SetStateAction<string>>
  user: UserType | undefined
  personId?: string
  askForSave: boolean
  setAskForSave: React.Dispatch<React.SetStateAction<boolean>>
  save: () => Promise<void>
}) {
  const intl = useIntl()
  const [activeKey, setActiveKey] = useState<React.Key>(0)
  const { components } = useAppState()
  const EditLanguageData = components && components['EditLanguageData']
  const authUrl = `${process.env.REACT_APP_AUTH_URL}/dashboard/users/search/edit/${personal?.authId}`


  return (
    <>
      <SimpleModal<string>
        isOpen={!!askForSave}
        onClose={() => { setAskForSave(false) }}
        headerLabel={intl.formatMessage({
          id: 'members.membersEdit.save',
          defaultMessage: 'Save',
        })}
        content={intl.formatMessage({
          id: 'members.membersEdit.saveConfirm',
          defaultMessage: 'Are you sure you want to save this member with errors',
        })}
        data={''}
        onConfirm={() => {
          save()
        }}
        confirmLabel={intl.formatMessage({
          id: 'members.membersEdit.save',
          defaultMessage: 'Save',
        })}
      ></SimpleModal>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
        <ButtonGroup>
          <Button
            onClick={() => goToPerson(null)}
            startEnhancer={() => <ArrowLeft size={24} />}
          >
            <FormattedMessage
              id='members.membersEdit.toOverview'
              defaultMessage={'To the overview'}
            />
          </Button>
          <Button
            onClick={() => goToInvoices(personId ?? null)}
          >
            <FormattedMessage
              id='members.membersEdit.toInvoices'
              defaultMessage={'To the invoices'}
            />
          </Button>
          <Button
            onClick={() => goToCourses(personId ?? null)}
          >
            <FormattedMessage
              id='members.membersEdit.toCourses'
              defaultMessage={'To the courses'}
            />
          </Button>
        </ButtonGroup>
      </Block>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
        {isLoading ? <Loader /> : (personal?.authId ? (
          <StyledLink href={authUrl} target="_blank">
            <FormattedMessage
              id='members.membersEdit.openAuthEntry'
              defaultMessage={'Open auth entry with ID: {authId}'}
              values={{ authId: personal?.authId }}
            />
          </StyledLink>
        ) : (
          <Notification kind={KIND.warning}>
            <FormattedMessage
              id='members.membersEdit.userHasNoLogin'
              defaultMessage={'User has no linked Login on Auth-Server. He needs to create a profile through the website with the same e-mail address as in the members DB. Or to connect an existing auth account with the help from lemonbrain'}
              values={{ authId: personal?.authId }}
            />
          </Notification>
        ))}
      </Block>
      <EditContainer
        state={personId ? STATE.UPDATE : STATE.CREATE}
        noCreate={true}
        onUpdateClick={onUpdateClick}
        onCancelClick={onCancelClick}
        isLoading={isLoading}
      >
        <StateData
          state={state}
          setState={setState}
          stateType={stateType}
          setStateType={setStateType}
          membership={membership}
          setMembership={setMembership}
          getHasStateErrors={getHasStateErrors}
        />
        <CommentData comment={comment} setComment={setComment} />
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => setActiveKey(activeKey)}
          fill={FILL.fixed}
          renderAll
        >
          <Tab
            title={intl.formatMessage({
              id: 'members.membersEdit.correspondence_adress',
              defaultMessage: '1/4 Correspondence address',
            })}
          >
            <AddressData
              address={addresses[0]}
              setAddress={setAddress}
              getHasAddressErrors={getHasAddressErrors.bind(
                null,
                `${intl.formatMessage({
                  id: 'members.membersEdit.correspondence_adress',
                  defaultMessage: '1/4 Correspondence address',
                })} - ${getTranslation(addresses[0].types.name, intl)}`
              )}
              isFirst={true}
            />
          </Tab>
          <Tab
            title={intl.formatMessage({
              id: 'members.membersEdit.additional_adress',
              defaultMessage: '2/4 Additional address',
            })}
          >
            <AddressData
              address={addresses[1]}
              setAddress={setAddress}
              getHasAddressErrors={getHasAddressErrors.bind(
                null,
                `${intl.formatMessage({
                  id: 'members.membersEdit.additional_adress',
                  defaultMessage: '2/4 Additional address',
                })} - ${getTranslation(addresses[1]?.types.name, intl)}`
              )}
              isFirst={false}
              otherTypeId={addresses[0].types.id}
            />
          </Tab>
          <Tab
            title={intl.formatMessage({
              id: 'members.membersEdit.personal',
              defaultMessage: '3/4 Personal',
            })}
          >
            <PersonalBusiness
              personal={personal}
              setPersonal={setPersonal}
              getHasPersonalErrors={getHasPersonalErrors}
            />
            {EditLanguageData && personal?.authId && (
              //@ts-ignores
              <EditLanguageData authId={personal?.authId} />
            )}
          </Tab>
          <Tab
            title={intl.formatMessage({
              id: 'members.membersEdit.additional',
              defaultMessage: '4/4 Additional',
            })}
          >
            <NewsletterData
              newsletters={newsletters}
              setNewsletters={setNewsletters}
            />
            <SportsEducationData
              sportsEducation={sportsEducation}
              setSportsEducation={setSportsEducation}
              getHasSportsEducationErrors={getHasSportsEducationErrors}
            />
            <WorkLevelData
              workLevel={workLevel}
              setWorkLevel={setWorkLevel}
              getHasWorkLevelErrors={getHasWorkLevelErrors}
            />
            <StateFunctionData
              stateFunctions={stateFunctions}
              setStateFunctions={setStateFunctions}
            />
            {user &&
              isInGroupOrAdmin(user, [
                process.env.REACT_APP_GROUP_MANAGERS || '',
                process.env.REACT_APP_GROUP_ADMINS || '',
              ]) ? (
              <></>
            ) : (
              <SvssFunctionData
                svssFunctions={svssFunctions}
                setSvssFunctions={setSvssFunctions}
              />
            )}
            <AssociationData
              membership={membership}
              associations={associations}
              setAssociations={setAssociations}
              associationPayTo={associationPayTo}
              setAssociationPayTo={setAssociationPayTo}
              associationComment={associationComment}
              setAssociationComment={setAssociationComment}
              getHasAssociationErrors={getHasAssociationErrors}
            />
          </Tab>
        </Tabs>
      </EditContainer>
      {errors.length > 0 && (
        <Notification kind={KIND.negative}>
          <ul>
            {errors.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Notification>
      )}
    </>
  )
}
