import { useParams } from 'react-router-dom'

import { InnerRoute } from '@lemonbrain/hooks'
import {useHistoryPush} from '@lemonbrain/hooks'

import InvoicesOverviewBusiness from './InvoicesOverview.business'

export default function InvoicesOverviewData({ subNav, personId }: { subNav?: InnerRoute[], personId?: string }) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const { invoiceId } = useParams<{ invoiceId: string | undefined }>()

  const goToInvoice = function (id: string | null) {
    if (id && id !== '') {
      pushPath(`/${language}/invoices/overview/${id}`)
    } else {
      pushPath(`/${language}/invoices/overview`)
    }
  }

  return <InvoicesOverviewBusiness subNav={subNav} goToInvoice={goToInvoice} invoiceId={invoiceId} personId={personId} />
}
