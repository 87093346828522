import React, { SyntheticEvent } from 'react'
import { Cell, Grid } from 'baseui/layout-grid'
import { Button } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { Plus, Check, Grab, Delete } from 'baseui/icon'
import { FormattedMessage } from 'react-intl'

export enum STATE {
  UPDATE = 'update',
  CREATE = 'create',
}

const EditContainer = ({
  state,
  noCreate = false,
  noUpdate = false,
  noCopy = false,
  children,
  onUpdateClick,
  onCopyClick,
  onCancelClick,
  onCreateClick,
  isLoading,
}: {
  state: 'create' | 'update' | STATE
  noCreate?: boolean
  noUpdate?: boolean
  noCopy?: boolean
  children: React.ReactNode
  onUpdateClick:
  | ((event: SyntheticEvent<HTMLButtonElement, Event>) => unknown)
  | undefined
  onCopyClick?:
  | ((event: SyntheticEvent<HTMLButtonElement, Event>) => unknown)
  | undefined
  onCancelClick:
  | ((event: SyntheticEvent<HTMLButtonElement, Event>) => unknown)
  | undefined
  onCreateClick?:
  | ((event: SyntheticEvent<HTMLButtonElement, Event>) => unknown)
  | undefined
  isLoading: boolean
}) => {
  return (
    <div style={{ width: '100%' }}>
      <div>
        {children}
      </div>
      <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
        <Cell span={[12, 12, 12]}>
          {state === STATE.UPDATE ? (
            <ButtonGroup>
              {!noUpdate && (
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type='button'
                  onClick={onUpdateClick}
                  startEnhancer={() => <Check size={24} />}
                >
                  <FormattedMessage
                    id='components.editContainer.update'
                    defaultMessage={'Update'}
                  />
                </Button>
              )}
              {!noCopy && (
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type='button'
                  onClick={onCopyClick}
                  startEnhancer={() => <Grab size={24} />}
                >
                  <FormattedMessage
                    id='components.editContainer.copy'
                    defaultMessage={'Copy'}
                  />
                </Button>
              )}
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                type='button'
                onClick={onCancelClick}
                startEnhancer={() => <Delete size={24} />}
              >
                <FormattedMessage
                  id='components.editContainer.cancel'
                  defaultMessage={'Cancel'}
                />
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <>
              {!noCreate && (
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type='button'
                  onClick={onCreateClick}
                  startEnhancer={() => <Plus size={24} />}
                >
                  <FormattedMessage
                    id='components.editContainer.create'
                    defaultMessage={'Create'}
                  />
                </Button>
              )}
              </>
              <></>
            </ButtonGroup>
          )}
        </Cell>
      </Grid>
    </div>
  )
}

export default EditContainer
