import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import {
  useGetCourseRegistrationsLazyQuery,
  useGetNextCourseRegistrationsLazyQuery,
} from '../../../../gql/Courses.generated'
import ParticipantsExportBusiness from './ParticipantsExport.business'
import getReactApolloCients from '../../../../localReactApollo'

export default function ParticipantsExportData({
  setNext,
  courseId,
}: {
  setNext: (data: any[], nextExportData: (nextCursor: string) => void, nextCursor: string) => void
  courseId: string
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [cursor, setCursor] = useState<
    | {
        pageSize: number
        filtered: {
          id: string
          value: string
        }[]
      }
    | undefined
  >()
  const client = getReactApolloCients().getClient()
  const [
    getCourseRegistrationsQuery,
    { data: dataGetCourseRegistrations, loading: loadingGetCourseRegistrations, error: errorGetCourseRegistrations },
  ] = useGetCourseRegistrationsLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })
  const [
    getNextCourseRegistrationsQuery,
    {
      data: dataGetNextCourseRegistrations,
      loading: loadingGetNextCourseRegistrations,
      error: errorGetNextCourseRegistrations,
    },
  ] = useGetNextCourseRegistrationsLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const startExport = function () {
    if (cursor) {
      getCourseRegistrationsQuery({
        variables: {
          cursor,
        },
      })
    }
  }

  const nextExportData = (nextCursor: string) => {
    if (cursor) {
      getNextCourseRegistrationsQuery({
        variables: {
          cursor: {
            ...cursor,
            nextCursor,
          },
        },
      })
    }
  }

  useEffect(() => {
      setCursor({
        pageSize: parseInt(process.env.REACT_APP_MITGLIEDER_EXPORT_PAGE_SIZE || '1'),
        filtered: (() => {
          const filter: { id: string; value: string }[] = []

          filter.push({
            id: 'courseId',
            value: courseId,
          })
          return filter
        })(),
      })
  }, [courseId])

  useEffect(() => {
    if (
      dataGetCourseRegistrations &&
      dataGetCourseRegistrations?.getCourseRegistrations.items.length > 0
    ) {
      setNext(
        dataGetCourseRegistrations?.getCourseRegistrations.items,
        nextExportData,
        dataGetCourseRegistrations.getCourseRegistrations.nextCursor || ''
      )
    }
  }, [dataGetCourseRegistrations])

  useEffect(() => {
    if (dataGetNextCourseRegistrations) {
      setNext(
        dataGetNextCourseRegistrations?.getNextCourseRegistrations.items,
        nextExportData,
        dataGetNextCourseRegistrations.getNextCourseRegistrations.nextCursor || ''
      )
    }
  }, [dataGetNextCourseRegistrations])

  useEffect(() => {
    if (errorGetCourseRegistrations || errorGetNextCourseRegistrations) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.participantsExport.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'courses.participantsExport.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorGetCourseRegistrations?.message || '' + errorGetNextCourseRegistrations?.message)
    }
  }, [errorGetCourseRegistrations, errorGetNextCourseRegistrations])

  return (
    <ParticipantsExportBusiness
      loading={loadingGetCourseRegistrations || loadingGetNextCourseRegistrations}
      startExport={startExport}
      courseId={courseId}
    />
  )
}
