import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import StateFunctionTable, { RowDataT } from './StateFunctionTable'

import { useAlert } from '@lemonbrain/hooks'

export default function StateFunctionTableBusiness({
  isLoading,
stateFunctions,
  fetchMoreStateFunctionEntries,
  refetchStateFunctionEntries,
  goToStateFunctionEntry,
  removeStateFunctionEntry,
  queryError,
}: {
  isLoading: boolean
  stateFunctions: {
    id: string
    data: RowDataT
  }[]
  fetchMoreStateFunctionEntries: () => void
  refetchStateFunctionEntries: () => void
  goToStateFunctionEntry: (id: string) => void
  removeStateFunctionEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeStateFunctionEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateFunctionTable.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchStateFunctionEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateFunctionTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateFunctionTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateFunctionTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateFunctionTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<StateFunctionTable
      isLoading={currentIsLoading}
      rows={stateFunctions}
      fetchMoreListItems={fetchMoreStateFunctionEntries}
      editRow={goToStateFunctionEntry}
      removeRow={removeRow}
    />
  )
}
