
import ToggleAll from './ToggleAll'

export default function ToggleAllBusiness({
    selectedPerson,
    toggleAll,
    loading,
}: {
    selectedPerson: string[]
    toggleAll: () => void
    loading: boolean
}) {

  return (
    <ToggleAll
      selectedPerson={selectedPerson}
      onClick={toggleAll}
      loading={loading}
    />
  )
}
