import { useIntl } from 'react-intl'

import CheckboxFilter from '@lemonbrain/components/UiElements/SearchFilters/Filters/CheckboxFilter'

export default function PayedFilterBusiness({
  payedFilter,
  setPayedFilter,
}: {
  payedFilter: string[] | null
  setPayedFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
  const intl = useIntl()

  return (
    <CheckboxFilter
      values={[{
        id: 'payed',
        label: intl.formatMessage({
            id: 'invoices.payed_filter.payed',
            defaultMessage: 'Payed',
            }),
      }]}
      loadingValues={false}
      filter={payedFilter}
      setFilter={setPayedFilter}
      label={intl.formatMessage({
        id: 'invoices.payed_filter.payed',
        defaultMessage: 'Payed',
      })}
      comment={intl.formatMessage({
        id: 'invoices.payed_filter.payedComment',
        defaultMessage: 'Is payed',
      })}
    />
  )
}
