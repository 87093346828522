import InvoicesOverview from './InvoicesOverview'

import { InnerRoute, useLocalStorage } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'

export default function InvoicesOverviewBusiness({
  subNav,
  goToInvoice,
  invoiceId,
  personId,
}: {
  subNav?: InnerRoute[]
  goToInvoice: (id: string | null) => void
  invoiceId: string | undefined
  personId?: string
}) {
  const { user } = useAppState()
  const [searchQuery, setSearchQuery] = useLocalStorage<string>('searchQuery', '')
  const [selectedInvoices, setSelectedInvoices] = useLocalStorage<string[]>(
    'selectedInvoices', []
  )
  const [payedFilter, setPayedFilter] = useLocalStorage<string[] | null>(
    'payedFilter'
  )

  return (
    <InvoicesOverview
      subNav={subNav}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      user={user}
      selectedInvoices={selectedInvoices}
      setSelectedInvoices={setSelectedInvoices}
      goToInvoice={goToInvoice}
      invoiceId={invoiceId}
      personId={personId}
      payedFilter={payedFilter}
      setPayedFilter={setPayedFilter}
    />
  )
}
