
import CoursesOverview from './CoursesOverview'

import { InnerRoute } from '@lemonbrain/hooks'

export default function NewslettersOverviewBusiness({
  subNav,
  goToCourse,
  courseId,
  sendMailing,
  personId
}: {
  subNav?: InnerRoute[]
  goToCourse: (id: string | null) => void
  courseId: string | undefined
  sendMailing: (id: string) => void
  personId?: string
}) {

  return (
    <CoursesOverview
      subNav={subNav}
      goToCourse={goToCourse}
      courseId={courseId}
      sendMailing={sendMailing}
      personId={personId}
    />
  )
}
