import AssociationsOverview from './AssociationsOverview'

import { InnerRoute, useLocalStorage } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'

export default function AssociationsOverviewBusiness({
  subNav,
  goToAssociation,
  associationId,
}: {
  subNav: InnerRoute[]
  goToAssociation: (id: string | null) => void
  associationId: string | undefined
}) {
  const { user } = useAppState()
  const [searchQuery, setSearchQuery] = useLocalStorage<string>('searchQuery', '')

  return (
    <AssociationsOverview
      subNav={subNav}
      goToAssociation={goToAssociation}
      associationId={associationId}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      user={user}
    />
  )
}
