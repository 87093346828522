import StateFunction from './StateFunction'

export default function SvssFunctionBusiness({
  stateFunctions,
  setStateFunctions,
  loadingStateFunctions,
  stateFunctionsInput,
}: {
  stateFunctions?: { id: string }[]
  setStateFunctions: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingStateFunctions: boolean
  stateFunctionsInput: {
    id: string
    label: string
  }[]
}) {
  return (
    <StateFunction
      stateFunctions={stateFunctions}
      setStateFunctions={setStateFunctions}
      loadingStateFunctions={loadingStateFunctions}
      stateFunctionsInput={stateFunctionsInput}
    />
  )
}
