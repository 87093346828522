import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'

import { UserType, isInGroupOrAdmin } from '@lemonbrain/utils'

import CreateDirectMemberInvoicesData from './CreateDirectMemberInvoices/CreateDirectMemberInvoices.data'
import CreateAssociationInvoicesData from './CreateAssociationInvoices/CreateAssociationInvoices.data'
import CreateMemberInvoicesData from './CreateMemberInvoices/CreateMemberInvoices.data'
import ToggleAllData from './ToggleAll/ToggleAll.data'
import ExportData from './Export/Export.data'
import MailData from './Mail/Mail.data'
import SepaImportData from './SepaImport/SepaImport.data'

export default function InvoicesFunctions({
  invoicesTableDataRef,
  searchQuery,
  selectedInvoices,
  setSelectedInvoices,
  user,
  personId,
}: {
  invoicesTableDataRef: React.MutableRefObject<
    | {
      refetchInvoices: () => void
    }
    | undefined
  >
  searchQuery: string
  selectedInvoices: string[]
  setSelectedInvoices: React.Dispatch<React.SetStateAction<string[]>>
  user: UserType | undefined
  personId?: string
}) {
  return (
    <Block
      overrides={{
        Block: {
          style: () => {
            return {
              paddingBottom: '15px',
            }
          },
        },
      }}
    >
      <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
        <Cell span={[12, 12, 1]}>
          <ToggleAllData
            searchQuery={searchQuery}
            selectedInvoices={selectedInvoices}
            setSelectedInvoices={setSelectedInvoices}
          />
        </Cell>
        <Cell span={[12, 12, 5]}></Cell>
        <Cell span={[12, 12, 1]}>
          <SepaImportData
            invoicesTableDataRef={invoicesTableDataRef}
          />
        </Cell>
        <Cell span={[12, 12, 1]}>
          <MailData 
            invoicesTableDataRef={invoicesTableDataRef}
            selectedInvoices={selectedInvoices} />
        </Cell>
        <Cell span={[12, 12, 1]}>
          <ExportData selectedInvoices={selectedInvoices} />
        </Cell>
        <Cell span={[12, 12, 1]}>
          {!personId && user &&
            isInGroupOrAdmin(user, [
              process.env.REACT_APP_GROUP_MANAGERS || '',
              process.env.REACT_APP_GROUP_ADMINS || '',
            ]) ? (
            <CreateDirectMemberInvoicesData
              invoicesTableDataRef={invoicesTableDataRef}
            />
          ) : (
            <></>
          )}
        </Cell>
        <Cell span={[12, 12, 1]}>
          {!personId && (
            <CreateAssociationInvoicesData
              invoicesTableDataRef={invoicesTableDataRef}
            />
          )}
        </Cell>
        <Cell span={[12, 12, 1]}>
          {!personId && (
            <CreateMemberInvoicesData
              invoicesTableDataRef={invoicesTableDataRef}
            />
          )}
        </Cell>{' '}
      </Grid>
    </Block >
  )
}
