import NewslettersTable, { RowDataT } from './NewslettersTable'

export default function NewslettersTableBusiness({
  data,
  onFetchMoreData,
  onRefetchData,
  loading,
  pageCount,
  pageSize,
  setPageSize,
  goToNewsletter,
  deleteNewsletterMailing,
}: {
  data: RowDataT[]
  onFetchMoreData: () => void
  onRefetchData: () => void
  loading: boolean
  pageCount: number
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  goToNewsletter: (id: string | null) => void
  deleteNewsletterMailing?:(id: string) => void
}) {

  return (
    <NewslettersTable
      data={data}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSizeInput={pageSize}
      setPageSizeInput={setPageSize}
      goToNewsletter={goToNewsletter}
      removeRow={deleteNewsletterMailing}
    />
  )
}
