import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAppState } from '@lemonbrain/contexts'
import { useAlert } from '@lemonbrain/hooks'
import MembershipMailingForm from './MembershipMailingForm'
import { verifyEmail } from '@lemonbrain/utils/verify'

export default function MembershipMailingFormBusiness({
  values,
  setValues,
  queryError,
  isLoading,
  createSignupRecipientEntry,
}: {
  values: {
    email: string | undefined
  }
  setValues: React.Dispatch<React.SetStateAction<{ email: string | undefined }>>
  queryError: any
  isLoading: boolean
  createSignupRecipientEntry: (data: {
    email: string
  }) => void
}) {
  const intl = useIntl()
  const { user } = useAppState()
  const setAlert = useAlert()

  const createEntry = async ({ email }: { email: string }) => {
    if (user && user.uID) {
      try {
        await createSignupRecipientEntry({ email })
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.membershipMailingForm.saved',
              defaultMessage: 'Saved',
            }),
            style: 'success',
          })
        setValues({email: ''})
      } catch (e) {
        console.error(e)
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.membershipMailingForm.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.membershipMailingForm.thereWasAnServerError',
              defaultMessage: 'There was an server error, try again',
            }),
            style: 'error',
          })
      }
    } else {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.membershipMailingForm.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.membershipMailingForm.notLoggedIn',
            defaultMessage: 'You are not anymore logged in',
          }),
          style: 'error',
        })
    }
  }


  function checkEmail(email: string | null | undefined) {
    return (email && verifyEmail(email))
  }

  useEffect(() => {
    checkEmail(values?.email)
  }, [values])

  function validate(
    email: string | null | undefined,
    setEmailError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
    if (checkEmail(email)) {
      setEmailError(null)
      return true
    }

    setEmailError(
      intl.formatMessage({
        id: 'settings.membershipMailingForm.emailError',
        defaultMessage: 'Email is not valid',
      })
    )
    return false
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.membershipMailingForm.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.membershipMailingForm.errorMessage',
            defaultMessage:
              'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  return (
    <MembershipMailingForm
      values={values}
      setValues={setValues}
      isLoading={isLoading}
      validate={validate}
      createEntry={createEntry}
    />
  )
}
