import { useState, useEffect } from 'react'

import AssociationTypeTableBusiness from './AssociationTypeTable.business'
import { RowDataT } from './AssociationTypeTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteAssociationTypeMutation,
  useGetAssociationTypesNoPersonQuery,
  GetNextAssociationTypesNoPersonDocument,
  GetNextAssociationTypesNoPersonQuery,
} from '../../../gql/AssociationType.generated'

export default function AssociationTypeTableData({
  setAssociationTypeId,
}: {
  setAssociationTypeId: (associationTypeId: string) => void
}) {
  const [associationTypes, setAssociationTypes] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetAssociationTypesNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteAssociationTypeMutation] = useDeleteAssociationTypeMutation({
    //@ts-ignore
    client,
  })

  async function removeAssociationTypeEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteAssociationTypeMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteAssociationType.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreAssociationTypeEntries() {
    if (data?.getAssociationTypesNoPerson.nextCursor) {
      fetchMore({
        query: GetNextAssociationTypesNoPersonDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getAssociationTypesNoPerson.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextAssociationTypesNoPersonQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextAssociationTypesNoPerson: {
                nextCursor: fetchMoreResult.getNextAssociationTypesNoPerson.nextCursor,
                items: [...fetchMoreResult.getNextAssociationTypesNoPerson.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextAssociationTypesNoPerson.items.length > 0 &&
            previousResult.getAssociationTypesNoPerson.items.filter((item) => {
              return item.id === fetchMoreResult.getNextAssociationTypesNoPerson.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getAssociationTypesNoPerson: {
                ...previousResult.getAssociationTypesNoPerson,
                nextCursor: fetchMoreResult.getNextAssociationTypesNoPerson.nextCursor,
                items: [
                  ...previousResult.getAssociationTypesNoPerson.items,
                  ...fetchMoreResult.getNextAssociationTypesNoPerson.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getAssociationTypesNoPerson.items) {
      setAssociationTypes(
        data?.getAssociationTypesNoPerson.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            isDefaultType: item.isDefaultType,
            userGroup: item.userGroup,
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToAssociationTypeEntry(id: string) {
    setAssociationTypeId(id)
  }

  return (
    <AssociationTypeTableBusiness
      isLoading={loading}
      queryError={queryError}
      associationTypes={associationTypes}
      fetchMoreAssociationTypeEntries={fetchMoreAssociationTypeEntries}
      refetchAssociationTypeEntries={refetch}
      goToAssociationTypeEntry={goToAssociationTypeEntry}
      removeAssociationTypeEntry={removeAssociationTypeEntry}
    />
  )
}
