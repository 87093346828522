import AssociationsFilterBusiness from './AssociationsFilter.business'

export default function AssociationsFilterData({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}) {
  return (
    <AssociationsFilterBusiness
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  )
}
