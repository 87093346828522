import React, { useEffect, useState } from 'react'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { ParagraphMedium } from 'baseui/typography'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import { ButtonGroup } from '@lemonbrain/components/PageStyles/Settings.styled'

interface iFormInput {
  email: string
}

export default function ForgotPassword({
  onSubmit,
  onLoginClick,
  isLoading,
}: {
  onSubmit: (username: string) => Promise<boolean>
  isLoading: boolean
  onLoginClick?: (a: React.SyntheticEvent<HTMLButtonElement>) => unknown,
}) {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<iFormInput>()
  const [showLogin, setShowLogin] = useState(false)
  const intl = useIntl()

  const onSubmitCalled = async ({ email }: iFormInput) => {
    setShowLogin(await onSubmit(email))
  }

  useEffect(() => {
    register('email', {
      required: true,
      pattern: /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    })
  }, [register])

  return (
    <Container>
      <Block
        overrides={{
          Block: {
            style: {
              minHeight: 'calc(100vh - 213px)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
        }}
      >
        {showLogin ? (
          <>
            <Block
              as='h1'
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      ...$theme.typography.font1250,
                      color: '#fff',
                      fontWeight: 700,
                      marginBottom: '30px',
                    }
                  },
                },
              }}
            >
              <FormattedMessage
                id='auth.forgotPassword.forgotYourPassword'
                defaultMessage={'Forgot Your Password?'}
              />
            </Block>
            <ParagraphMedium>
              <FormattedMessage
                id='auth.forgotPassword.sentEmailOnAccountCreation'
                defaultMessage='We have sent you an password reset email.'
              />
            </ParagraphMedium>
            <br />
            <ParagraphMedium>
              <b>
                {' '}
                <FormattedMessage
                  id='auth.forgotPassword.followTheInstructions'
                  defaultMessage='Please follow the instructions in the email.'
                />
              </b>
            </ParagraphMedium>
            <br />
            <ParagraphMedium>
              <FormattedMessage
                id='auth.forgotPassword.canNotFindValidationEmail'
                defaultMessage="If you can't find the password reset email, please have also a look in your spam folder!"
              />
            </ParagraphMedium>
          </>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmitCalled)}
            style={{ width: '100%' }}
          >
            <Block
              as='h1'
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      ...$theme.typography.font1250,
                      color: '#fff',
                      fontWeight: 700,
                      marginBottom: '30px',
                    }
                  },
                },
              }}
            >
              <FormattedMessage
                id='auth.forgotPassword.forgotYourPassword'
                defaultMessage={'Forgot Your Password?'}
              />
            </Block>
            <Block marginBottom='30px'>
              <FormControl
                label={intl.formatMessage({
                  id: 'auth.forgotPassword.emailAddress',
                  defaultMessage: 'Email Address',
                })}
                caption='johndoe@demo.io'
                error={
                  errors.email &&
                  (errors.email.message
                    ? errors.email.message
                    : intl.formatMessage({
                        id: 'auth.forgotPassword.error.validEmailAddress',
                        defaultMessage: 'Please enter a valid Email Address',
                      }))
                }
              >
                <Input
                  name='email'
                  overrides={{
                    InputContainer: {
                      style: () => {
                        return { backgroundColor: 'transparent' }
                      },
                    },
                  }}
                  onChange={(event) => { setValue('email', event.currentTarget.value) }}
                />
              </FormControl>
            </Block>
            <ButtonGroup>
              <Button type='submit' isLoading={isLoading}>
                <FormattedMessage
                  id='auth.forgotPassword.resetAndSend'
                  defaultMessage={'Reset and Email Password'}
                />{' '}
              </Button>
              <Button kind='tertiary' type='button' onClick={onLoginClick}>
                <FormattedMessage
                  id='auth.forgotPassword.signIn'
                  defaultMessage={'Login'}
                />
              </Button>
            </ButtonGroup>
          </form>
        )}
      </Block>
    </Container>
  )
}
