import CoursesAdd from './CoursesAdd'

import { InnerRoute } from '@lemonbrain/hooks'

export default function CoursesAddBusiness({
  subNav,
  goToCourse,
}: {
  subNav: InnerRoute[]
  goToCourse: (id: string | null) => void
}) {

  return (
    <CoursesAdd
      subNav={subNav}
      goToCourse={goToCourse}
    />
  )
}
