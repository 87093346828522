import { Button, KIND, SIZE } from "baseui/button"
import { useState } from "react"

export default function ConfirmRegistrationButton({
  icon,
  confirmRegistration,
  itemId, // this can be the id of a registration or a course in case of confirmOpenRegistrations
  disabled,
  primary
}: {
  icon: React.ReactNode,
  confirmRegistration: (id: string) => void
  itemId: string
  disabled?: boolean
  primary?: boolean
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true)
    await confirmRegistration(itemId)
    setIsLoading(false)
  }

  return (
    <Button
      onClick={() => {handleClick()}}
      isLoading={!disabled && isLoading}
      size={SIZE.compact}
      kind={primary ? KIND.primary : KIND.secondary}
      disabled={disabled}
    >
      {icon}
    </Button>
  )
}
