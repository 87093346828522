import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import WorkLevelTable, { RowDataT } from './WorkLevelTable'

import { useAlert } from '@lemonbrain/hooks'

export default function WorkLevelTableBusiness({
  isLoading,
  workLevels,
  fetchMoreWorkLevelEntries,
  refetchWorkLevelEntries,
  goToWorkLevelEntry,
  removeWorkLevelEntry,
  queryError,
}: {
  isLoading: boolean
  workLevels: {
    id: string
    data: RowDataT
  }[]
  fetchMoreWorkLevelEntries: () => void
  refetchWorkLevelEntries: () => void
  goToWorkLevelEntry: (id: string) => void
  removeWorkLevelEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeWorkLevelEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.workLevelTable.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchWorkLevelEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.workLevelTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.workLevelTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.workLevelTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.workLevelTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<WorkLevelTable
      isLoading={currentIsLoading}
      rows={workLevels}
      fetchMoreListItems={fetchMoreWorkLevelEntries}
      editRow={goToWorkLevelEntry}
      removeRow={removeRow}
    />
  )
}
