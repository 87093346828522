import InvoicesAdd from './InvoicesAdd'

import { InnerRoute } from '@lemonbrain/hooks'

export default function InvoicesAddBusiness({
  subNav,
  goToInvoice,
}: {
  subNav: InnerRoute[]
  goToInvoice: (id: string | null) => void
}) {

  return (
    <InvoicesAdd
      subNav={subNav}
      goToInvoice={goToInvoice}
    />
  )
}
