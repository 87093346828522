import { useState, useEffect } from 'react'

import SvssFunctionTableBusiness from './SvssFunctionTable.business'
import { RowDataT } from './SvssFunctionTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteSvssFunctionMutation,
  useGetSvssFunctionsNoPersonQuery,
  GetNextSvssFunctionsNoPersonDocument,
  GetNextSvssFunctionsNoPersonQuery,
} from '../../../gql/SvssFunction.generated'

export default function SvssFunctionTableData({
  setSvssFunctionId,
}: {
  setSvssFunctionId: (svssFunctionId: string | undefined) => void
}) {
  const [svssFunctions, setSvssFunctions] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetSvssFunctionsNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteSvssFunctionMutation] = useDeleteSvssFunctionMutation({
    //@ts-ignore
    client,
  })

  async function removeSvssFunctionEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteSvssFunctionMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteSvssFunction.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreSvssFunctionEntries() {
    if (data?.getSvssFunctionsNoPerson.nextCursor) {
      fetchMore({
        query: GetNextSvssFunctionsNoPersonDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getSvssFunctionsNoPerson.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextSvssFunctionsNoPersonQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextSvssFunctionsNoPerson: {
                nextCursor: fetchMoreResult.getNextSvssFunctionsNoPerson.nextCursor,
                items: [...fetchMoreResult.getNextSvssFunctionsNoPerson.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextSvssFunctionsNoPerson.items.length > 0 &&
            previousResult.getSvssFunctionsNoPerson.items.filter((item) => {
              return item.id === fetchMoreResult.getNextSvssFunctionsNoPerson.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getSvssFunctionsNoPerson: {
                ...previousResult.getSvssFunctionsNoPerson,
                nextCursor: fetchMoreResult.getNextSvssFunctionsNoPerson.nextCursor,
                items: [
                  ...previousResult.getSvssFunctionsNoPerson.items,
                  ...fetchMoreResult.getNextSvssFunctionsNoPerson.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getSvssFunctionsNoPerson.items) {
      setSvssFunctions(
        data?.getSvssFunctionsNoPerson.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToSvssFunctionEntry(id: string) {
    setSvssFunctionId(id)
  }

  return (
    <SvssFunctionTableBusiness
      isLoading={loading}
      svssFunctions={svssFunctions}
      fetchMoreSvssFunctionEntries={fetchMoreSvssFunctionEntries}
      refetchSvssFunctionEntries={refetch}
      goToSvssFunctionEntry={goToSvssFunctionEntry}
      removeSvssFunctionEntry={removeSvssFunctionEntry}
      queryError={queryError}
    />
  )
}
