// @ts-nocheck
import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SepaFragmentFragment = (
  { __typename?: 'Sepa' }
  & Pick<Types.Sepa, 'id' | 'fileId' | 'filename' | 'createdFrom'>
);

export type SepaReturnFragmentFragment = (
  { __typename?: 'SepaReturn' }
  & Pick<Types.SepaReturn, 'errorCode' | 'errorMessages' | 'successMessages'>
  & { sepa?: Types.Maybe<(
    { __typename?: 'Sepa' }
    & SepaFragmentFragment
  )> }
);

export type AddSepaMutationVariables = Types.Exact<{
  sepa: Types.Scalars['Upload'];
}>;


export type AddSepaMutation = (
  { __typename?: 'Mutation' }
  & { addSepa: (
    { __typename?: 'SepaReturn' }
    & SepaReturnFragmentFragment
  ) }
);

export type DeleteSepaMutationVariables = Types.Exact<{
  filename: Types.Scalars['String'];
}>;


export type DeleteSepaMutation = (
  { __typename?: 'Mutation' }
  & { deleteSepa: (
    { __typename?: 'SepaReturn' }
    & SepaReturnFragmentFragment
  ) }
);

export const SepaFragmentFragmentDoc = gql`
    fragment SepaFragment on Sepa {
  id
  fileId
  filename
  createdFrom
}
    `;
export const SepaReturnFragmentFragmentDoc = gql`
    fragment SepaReturnFragment on SepaReturn {
  sepa {
    ...SepaFragment
  }
  errorCode
  errorMessages
  successMessages
}
    ${SepaFragmentFragmentDoc}`;
export const AddSepaDocument = gql`
    mutation addSepa($sepa: Upload!) {
  addSepa(sepa: $sepa) {
    ...SepaReturnFragment
  }
}
    ${SepaReturnFragmentFragmentDoc}`;
export type AddSepaMutationFn = Apollo.MutationFunction<AddSepaMutation, AddSepaMutationVariables>;

/**
 * __useAddSepaMutation__
 *
 * To run a mutation, you first call `useAddSepaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSepaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSepaMutation, { data, loading, error }] = useAddSepaMutation({
 *   variables: {
 *      sepa: // value for 'sepa'
 *   },
 * });
 */
export function useAddSepaMutation(baseOptions?: Apollo.MutationHookOptions<AddSepaMutation, AddSepaMutationVariables>) {
        return Apollo.useMutation<AddSepaMutation, AddSepaMutationVariables>(AddSepaDocument, baseOptions);
      }
export type AddSepaMutationHookResult = ReturnType<typeof useAddSepaMutation>;
export type AddSepaMutationResult = Apollo.MutationResult<AddSepaMutation>;
export type AddSepaMutationOptions = Apollo.BaseMutationOptions<AddSepaMutation, AddSepaMutationVariables>;
export const DeleteSepaDocument = gql`
    mutation deleteSepa($filename: String!) {
  deleteSepa(filename: $filename) {
    ...SepaReturnFragment
  }
}
    ${SepaReturnFragmentFragmentDoc}`;
export type DeleteSepaMutationFn = Apollo.MutationFunction<DeleteSepaMutation, DeleteSepaMutationVariables>;

/**
 * __useDeleteSepaMutation__
 *
 * To run a mutation, you first call `useDeleteSepaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSepaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSepaMutation, { data, loading, error }] = useDeleteSepaMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useDeleteSepaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSepaMutation, DeleteSepaMutationVariables>) {
        return Apollo.useMutation<DeleteSepaMutation, DeleteSepaMutationVariables>(DeleteSepaDocument, baseOptions);
      }
export type DeleteSepaMutationHookResult = ReturnType<typeof useDeleteSepaMutation>;
export type DeleteSepaMutationResult = Apollo.MutationResult<DeleteSepaMutation>;
export type DeleteSepaMutationOptions = Apollo.BaseMutationOptions<DeleteSepaMutation, DeleteSepaMutationVariables>;