import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { required, verifyUrl } from '@lemonbrain/utils'

import Association from './Association'
import { UpdateAssociationIfMemberMutationVariables } from '../../../../gql/Association.generated'

export default function AssociationBusiness({
  getHasAssociationErrors,
  association,
  setAssociation,
  isLoading,
}: {
  getHasAssociationErrors: (errors: {
    deName: string,
    frName: string,
    itName: string,
    deShort: string,
    frShort: string,
    itShort: string,
    prize: string,
    associationURL: string,
  }) => void
  association: UpdateAssociationIfMemberMutationVariables & {
    prizeString: string
    imageSrc: string
  }
  setAssociation: React.Dispatch<
    React.SetStateAction<
      UpdateAssociationIfMemberMutationVariables & { prizeString: string, imageSrc: string }
    >
  >
  isLoading: boolean
}) {
  const [errors, setErrors] = useState<{
    deName: string,
    frName: string,
    itName: string,
    deShort: string,
    frShort: string,
    itShort: string,
    prize: string,
    associationURL: string,
  }>({
    deName: '',
    frName: '',
    itName: '',
    deShort: '',
    frShort: '',
    itShort: '',
    prize: '',
    associationURL: '',
  })
  const intl = useIntl()
  const [rejectedFiles, setRejectedFiles] = useState<File[]>()

  function checkDeName() {
    if (required(association.name.de)) {
      setErrors((prev) => ({
        ...prev,
        deName: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      deName: intl.formatMessage({
        id: 'associations.associationEdit.error.deRequired',
        defaultMessage: 'Label (German) is required',
      }),
    }))
    return true
  }

  function checkFrName() {
    if (required(association.name.fr)) {
      setErrors((prev) => ({
        ...prev,
      frName: ''
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      frName: intl.formatMessage({
        id: 'associations.associationEdit.error.frRequired',
        defaultMessage: 'Label (French) is required',
      })
    }))
    return true
  }

  function checkItName() {
    if (required(association.name.it)) {
      setErrors((prev) => ({
        ...prev,
        itName: ''
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      itName: intl.formatMessage({
        id: 'associations.associationEdit.error.itRequired',
        defaultMessage: 'Label (Italian) is required',
      })
    }))
    return true
  }

  function checkDeShort() {
    if (required(association.short.de)) {
      setErrors((prev) => ({
        ...prev,
        deShort:''
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      deShort: intl.formatMessage({
        id: 'associations.associationEdit.error.deShortRequired',
        defaultMessage: 'Abbreviation (German) is required',
      })
    }))
    return true
  }

  function checkFrShort() {
    if (required(association.short.fr)) {
      setErrors((prev) => ({
        ...prev,
        frShort: ''
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      frShort: intl.formatMessage({
        id: 'associations.associationEdit.error.frShortRequired',
        defaultMessage: 'Abbreviation (French) is required',
      })
    }))
    return true
  }

  function checkItShort() {
    if (required(association.short.it)) {
      setErrors((prev) => ({
        ...prev,
        itShort: ''
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      itShort: intl.formatMessage({
        id: 'associations.associationEdit.error.itShortRequired',
        defaultMessage: 'Abbreviation (Italian) is required',
      })
    }))
    return true
  }

  function checkAssociationURL() {
    if (required(association.associationURL) && verifyUrl(association.associationURL||'')) {
      setErrors((prev) => ({
        ...prev,
        associationURL: ''
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      associationURL: intl.formatMessage({
        id: 'associations.associationEdit.error.associationURLRequired',
        defaultMessage: 'Website is required',
      })
    }))
    return true
  }

  useEffect(() => {
    checkDeName()
    checkFrName()
    checkItName()
    checkDeShort()
    checkFrShort()
    checkItShort()
    checkAssociationURL()
  }, [association])

  useEffect(() => {
    getHasAssociationErrors(errors)
  }, [errors])

  useEffect(() => {
    if (rejectedFiles && rejectedFiles?.length > 0) {
      setErrors((prev) => ({
        ...prev,
        mainImage: intl.formatMessage(
          {
            id: 'newsletters.newslettersEdit.rejectFormats',
            defaultMessage: 'Following files were rejected: {files}',
          },
          {
            files: rejectedFiles.map((item) => item.name).join(', '),
          }
        ),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        mainImage: '',
      }))
    }
  }, [rejectedFiles])

  return (
    <Association
      values={association}
      setValues={setAssociation}
      errors={errors}
      isLoading={isLoading}
      setRejectedFiles={setRejectedFiles}
    />
  )
}
