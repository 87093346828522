import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'

import { useAlert } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'
import {useHistoryPush} from '@lemonbrain/hooks'
import { required, verifyFloat, verifyDate } from '@lemonbrain/utils'

import InvoiceEdit from './InvoiceEdit'
import {
  UpdateInvoiceMutationVariables,
} from '../../../gql/invoices/Invoices.generated'
import {
  GetTransactionQuery
} from '../../../gql/transaction/Transaction.generated'

export default function InvoiceEditBusiness({
  goToInvoice,
  invoiceId,
  loading,
  updateInvoiceEntry,
  invoice,
  setInvoice,
  dataTransaction,
}: {
  goToInvoice: (id: string | null) => void
  invoiceId: string
  loading: boolean
  updateInvoiceEntry(
    data: UpdateInvoiceMutationVariables
  ): Promise<void>
  invoice:
    | {
        id: string
        payAmount: string
        payDate: string
        total: string
        sendAt: string
      }
    | undefined
    setInvoice: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          payAmount: string
          payDate: string
          total: string
          sendAt: string
        }
    >
  >
  dataTransaction?: GetTransactionQuery
}) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const [prizeError, setPrizeError] = useState<string>('')
  const [dateError, setDateError] = useState<string>('')
  const [totalError, setTotalError] = useState<string>('')
  const intl = useIntl()
  const setAlert = useAlert()
  const { user } = useAppState()

  function checkPayedAt() {
    if (invoice?.payDate || Number(invoice?.payAmount) > 0) {
      if (invoice?.payDate !== '' && verifyDate(invoice?.payDate||'')) {
        setDateError('')
        return false
      }
      setDateError(
        intl.formatMessage({
          id: 'invoices.invoiceEdit.error.payedAtRequired',
          defaultMessage: 'Date needs to be in the right format',
        })
      )
      return true
    }
    setDateError('')
    return false
  }

  function checkPrize() {
    if (required(invoice?.payAmount) && verifyFloat(invoice?.payAmount || '')) {
      setPrizeError('')
      return false
    }
    setPrizeError(
      intl.formatMessage({
        id: 'invoices.invoiceEdit.error.prizeRequired',
        defaultMessage: 'Price is required and needs to be a decimal',
      })
    )
    return true
  }

  function checkTotal() {
    if (required(invoice?.total) && verifyFloat(invoice?.total || '')) {
      setTotalError('')
      return false
    }
    setTotalError(
      intl.formatMessage({
        id: 'invoices.invoiceEdit.error.totalRequired',
        defaultMessage: 'Total is required and needs to be a decimal',
      })
    )
    return true
  }

  function validate() {
    let generalError = false

    generalError = generalError || checkPrize()
    generalError = generalError || checkPayedAt()
    generalError = generalError || checkTotal()

    return !generalError
  }

  useEffect(() => {
    if (invoice) {
      checkPrize()
      checkPayedAt()
      checkTotal()
    }
  }, [invoice])

  function cancelClickHandler() {
    goToInvoice(null)
  }

  async function updateClickHandler() {
    if (invoice && validate()) {
      if (user && user.uID && invoice.id) {
        try {
          let variables: UpdateInvoiceMutationVariables = {
            id: invoice.id,
            payAmount: Number(invoice.payAmount) * 100,
            payDate: invoice.payDate || null,
            total: Number(invoice.total) * 100,
          }

          await updateInvoiceEntry(
            variables,
          )
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.invoiceEdit.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'invoices.invoiceEdit.saved.updated',
                defaultMessage: 'Entry is updated',
              }),
              style: 'success',
            })
          pushPath('/' + language + '/invoices/overview')
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.invoiceEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'invoices.invoiceEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'invoices.invoiceEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'invoices.invoiceEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  return (
    <InvoiceEdit
      onCancelClick={cancelClickHandler}
      isLoading={loading}
      onUpdateClick={updateClickHandler}
      invoice={invoice}
      setInvoice={setInvoice}
      invoiceId={invoiceId}
      prizeError={prizeError}
      totalError={totalError}
      dateError={dateError}
      dataTransaction={dataTransaction}
    />
  )
}
