import NewslettersItemsBusiness from './NewsletterItems.business'

import { UpdateNewsletterMailingItemMutationVariables,  UpdateNewsletterMailingMutationVariables } from '../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../types'

export default function NewslettersItemsData({
  newsletterMailing,
  setNewsletterMailing,
}: {
  newsletterMailing: UpdateNewsletterMailingMutationVariables & {
    toJoined: string
    mainImageSrc: string
    items: (UpdateNewsletterMailingItemMutationVariables & {
      imageSrc: string
      task: Types.ArrayUpdate
    })[]
  }
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: Types.ArrayUpdate
        })[]
      }
    >
  >
}) {

  return <NewslettersItemsBusiness
      newsletterMailing={newsletterMailing}
      setNewsletterMailing={setNewsletterMailing}
    />
}
