import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import MembersCoursesBusiness from './MembersCourses.business'

export default function MembersCoursesData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const { personId } = useParams<{ personId: string | undefined }>()

  const goToPerson = function (id: string | null) {
    if (id && id !== '') {
      pushPath(`/${language}/members/overview/${id}`)
    } else {
      pushPath(`/${language}/members/overview`)
    }
  }

  return <MembersCoursesBusiness subNav={subNav} goToPerson={goToPerson} personId={personId} />
}
