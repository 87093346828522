import SettingsData from './views/Settings/Settings.data'
import WorkLevelData from './views/WorkLevel/WorkLevel.data'
import AddressTypeData from './views/AddressType/AddressType.data'
import MembershipData from './views/Membership/Membership.data'
import SportsEducationData from './views/SportsEducation/SportsEducation.data'
import AssociationTypeData from './views/AssociationType/AssociationType.data'
import StateData from './views/State/State.data'
import StateTypeData from './views/StateType/StateType.data'
import StateFunctionData from './views/StateFunction/StateFunction.data'
import SvssFunctionData from './views/SvssFunction/SvssFunction.data'
import SvssBillingInformationsData from './views/SvssBillingInformations/SvssBillingInformations.data'
import CourseSettingsData from './views/Courses/CourseSettings.data'
import MembershipMailingData from './views/MembershipMailing/MembershipMailing.data'

import germanMessages from './messages/de'
import getReactApolloCients from './localReactApollo'

import {
  addMenuRoutesBeforeRender,
  addGermanMessagesBeforeRender,
  addWsClientBeforeRender
} from '@lemonbrain/hooks'

addMenuRoutesBeforeRender(
  'MAIN', [
  {
    title: {
      id: 'settings.settings',
      defaultMessage: 'Settings',
    },
    icon: 'settings',
    id: 'settings',
    path: '/settings/',
    routePath: '/settings',
    component: SettingsData,
    size: 'small',
    tabIndex: 10,
    visible: true,
    active: true,
    enabled: true,
    accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
    children: [
      {
        title: {
          id: 'settings.addressType',
          defaultMessage: 'Address type',
        },
        icon: 'settings',
        id: 'settingsAddressType',
        path: '/settings/address-type/:addressTypeId?',
        routePath: '/settings/address-type',
        component: AddressTypeData,
        size: 'small',
        tabIndex: 51,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.membership',
          defaultMessage: 'Membership',
        },
        icon: 'settings',
        id: 'settingsMembership',
        path: '/settings/membership/:membershipId?',
        routePath: '/settings/membership',
        component: MembershipData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.associationType',
          defaultMessage: 'Association type',
        },
        icon: 'settings',
        id: 'settingsAssociationType',
        path: '/settings/association-type/:associationTypeId?',
        routePath: '/settings/association-type',
        component: AssociationTypeData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.sportsEducation',
          defaultMessage: 'Sports education',
        },
        icon: 'settings',
        id: 'settingsSportsEducation',
        path: '/settings/sports-education/:sportsEducationId?',
        routePath: '/settings/sports-education',
        component: SportsEducationData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.workLevel',
          defaultMessage: 'Work level',
        },
        icon: 'settings',
        id: 'settingsWorkLevel',
        path: '/settings/work-level/:workLevelId?',
        routePath: '/settings/work-level',
        component: WorkLevelData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.state',
          defaultMessage: 'State',
        },
        icon: 'settings',
        id: 'settingsState',
        path: '/settings/state/:stateId?',
        routePath: '/settings/state',
        component: StateData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.state',
          defaultMessage: 'State Type',
        },
        icon: 'settings',
        id: 'settingsStateType',
        path: '/settings/state-types/:stateId/type/:stateTypeId?',
        routePath: '/settings/state-types',
        component: StateTypeData,
        size: 'small',
        tabIndex: 11,
        visible: false,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.stateFunctions',
          defaultMessage: 'State functions',
        },
        icon: 'settings',
        id: 'settingsStateFunctions',
        path: '/settings/state-function/:stateFunctionId?',
        routePath: '/settings/state-function',
        component: StateFunctionData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.svssFunctions',
          defaultMessage: 'SVSS functions',
        },
        icon: 'settings',
        id: 'settingsSvssFunction',
        path: '/settings/svss-function/:svssFunctionId?',
        routePath: '/settings/svss-function',
        component: SvssFunctionData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.svssBillingInformations',
          defaultMessage: 'SVSS billing informations',
        },
        icon: 'settings',
        id: 'settingsSvssBillingInformations',
        path: '/settings/svss-billing-informations',
        routePath: '/settings/svss-billing-informations',
        component: SvssBillingInformationsData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.courses',
          defaultMessage: 'Courses',
        },
        icon: 'settings',
        id: 'settingsCourses',
        path: '/settings/courses',
        routePath: '/settings/courses',
        component: CourseSettingsData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'settings.membershipMailing',
          defaultMessage: 'Membership mailing',
        },
        icon: 'settings',
        id: 'settingsMembershipMailing',
        path: '/settings/membership-mailing',
        routePath: '/settings/membership-mailing',
        component: MembershipMailingData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
    ],
  },
])

const wsClient = getReactApolloCients().getWsClient()
if (wsClient) {
  addWsClientBeforeRender(wsClient)
}

addGermanMessagesBeforeRender(germanMessages)
