import { useState, useEffect } from 'react'

import CheckValidationEmail from './CheckValidationEmail'
import { useIntl } from 'react-intl'

import { useCaptcha, useAlert } from '@lemonbrain/hooks'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'

export default function CheckValidationEmailBusiness ({
    sendValidationEmail,
}: {
    sendValidationEmail: (reCaptchaToken: string) => Promise<"is-valid">,
}) {
    const { isLoaded, isError, getCaptchaToken } = useCaptcha()
    const setAlert = useAlert()
    const [isLoading, setIsLoading] = useState(false)
    const intl = useIntl()

    async function onSubmit () {
        let state = false
        setIsLoading(true)

        let newState = ''
        try {
            let reCaptchaToken = ''
            try {
                reCaptchaToken = await getCaptchaToken('validationEmail')
                newState = await sendValidationEmail(reCaptchaToken)
            } catch (e) {
                setAlert && setAlert({
                    title: intl.formatMessage({ id: 'auth.checkValidationEmail.error', defaultMessage: 'Error' }),
                    label: intl.formatMessage({ id: 'auth.checkValidationEmail.error.reloadPage', defaultMessage: 'Reload the page' }),
                    style: 'success'
                })
            }

        } catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.checkValidationEmail.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.checkValidationEmail.error.notSendedMail', defaultMessage: 'We could not send you a validation mail. Try again.' }),
                style: 'error'
            })
        }
        state = newState === 'is-valid'
        if (state) {
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.checkValidationEmail.mailSent', defaultMessage: 'Mail sent' }),
                label: intl.formatMessage({ id: 'auth.checkValidationEmail.mailSent.success', defaultMessage: 'The validation email has been sent successfully. Please check your inbox and spam folder.' }),
                style: 'success'
            })
        }
        setIsLoading(false)
        return state
    }

    if (isError) {
        setAlert && setAlert({
            title: intl.formatMessage({ id: 'auth.checkValidationEmail.error', defaultMessage: 'Error' }),
            label: intl.formatMessage({ id: 'auth.checkValidationEmail.error.googleRecaptchaCouldNotBeLoaded', defaultMessage: 'Google ReCaptcha could not be loaded' }),
            style: 'error'
        })
    }

    useEffect(() => {
        onSubmit()
    }, [])

    return isLoaded ?
        (
            isLoading ?
                (<Loader />) : (
                    <CheckValidationEmail />
                )
        ) : (<Loader />)
}
