import { useRef } from 'react'
import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'
import { UserType } from '@lemonbrain/hooks'

import InvoiceTableIfToAssociationData from './../InvoicesOverview/InvoicesTable/InvoicesTableIfToAssociation.data'
import InvoicesFilterData from './../InvoicesOverview/InvoicesFilter/InvoicesFilter.data'
import InvoicesFunctions from './../InvoicesOverview/InvoicesFunctions/InvoicesFunctions'
import InvoiceEditIfToAssociationData from './MyInvoiceEdit/MyInvoiceEditIfToAssociation.data'
import { PaymentState } from './InvoicesMy.data'

export default function InvoicesMy({
  subNav,
  searchQuery,
  setSearchQuery,
  user,
  selectedInvoices,
  setSelectedInvoices,
  goToInvoice,
  invoiceId,
  paymentState,
  language,
  payedFilter,
  setPayedFilter,
}: {
  subNav: InnerRoute[]
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  user: UserType | undefined
  selectedInvoices: string[]
  setSelectedInvoices: React.Dispatch<React.SetStateAction<string[]>>
  goToInvoice: (id: string | null) => void
  invoiceId: string | undefined
  paymentState: PaymentState | undefined
  language: string
  payedFilter: string[] | null
  setPayedFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
  const invoicesTableDataRef = useRef<{
    refetchInvoices: () => void
  }>()
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <Block>
              <Block>
                <Block
                  paddingTop={['15px', '15px', '15px', 0]}
                  overrides={{
                    Block: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                      },
                    },
                  }}
                >
                  <Block>
                    <Block
                      as='h3'
                      overrides={{
                        Block: {
                          style: ({ $theme }) => {
                            return {
                              ...$theme.typography.font450,
                              color: $theme.colors.primaryA,
                              marginBottom: '15px',
                            }
                          },
                        },
                      }}
                    >
                      <FormattedMessage
                        id='invoices.invoicesMy'
                        defaultMessage={'My invoices'}
                      />
                    </Block>
                  </Block>
                </Block>
              </Block>
            </Block>
            <Block>
              {invoiceId && parseInt(invoiceId) > 0 ? (
                  <InvoiceEditIfToAssociationData
                  goToInvoice={goToInvoice}
                  paymentState={paymentState}
                  invoiceId={invoiceId}
                  language={language}
                  />
              ) : (
                <>
                  <Block
                    overrides={{
                      Block: {
                        style: () => {
                          return {
                            marginBottom: '15px',
                          }
                        },
                      },
                    }}
                  >
                    <InvoicesFilterData
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      payedFilter={payedFilter}
                      setPayedFilter={setPayedFilter}
                    />
                  </Block>
                  <Block
                    overrides={{
                      Block: {
                        style: () => {
                          return {
                            marginBottom: '0',
                          }
                        },
                      },
                    }}
                  >
                    <InvoicesFunctions
                      invoicesTableDataRef={invoicesTableDataRef}
                      searchQuery={searchQuery}
                      selectedInvoices={selectedInvoices}
                      setSelectedInvoices={setSelectedInvoices}
                      user={user}
                    />
                  </Block>
                    <InvoiceTableIfToAssociationData
                      goToInvoice={goToInvoice}
                      searchQuery={searchQuery}
                      ref={invoicesTableDataRef}
                      selectedInvoices={selectedInvoices}
                      setSelectedInvoices={setSelectedInvoices}
                    />
                </>
              )}
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
