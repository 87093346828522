import { useIntl } from 'react-intl'
import CKEditor from '@lemonbrain/components/UiElements/CKEditor/CKEditor'
import InlineEditor from '@ckeditor/ckeditor5-build-inline/build/ckeditor'

import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { FileUploader } from 'baseui/file-uploader'
import { Card, StyledBody } from 'baseui/card'

import {
  UpdateNewsletterMailingItemMutationVariables,
  UpdateNewsletterMailingMutationVariables,
} from '../../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../../types'

export default function NewslettersItem({
  newsletterMailingItem,
  setNewsletterMailing,
  errors,
  setRejectedFiles,
}: {
  newsletterMailingItem: UpdateNewsletterMailingItemMutationVariables & {
    imageSrc: string
    task: Types.ArrayUpdate
  }
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: Types.ArrayUpdate
        })[]
      }
    >
  >
  errors: {
    image: string
    imageCopyright: string
    title: string
    content: string
    link: string
  }
  setRejectedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>
}) {
  const intl = useIntl()

  return (
    <Card headerImage={newsletterMailingItem.imageSrc}>
      <StyledBody>
        <FormControl
          label={intl.formatMessage({
            id: 'newsletters.newsletterItem.image',
            defaultMessage: 'Image',
          })}
          error={errors.image || undefined}
        >
          <FileUploader
            accept={'image/*'}
            // errorMessage={errors.mainImage}
            onDrop={(acceptedFiles, rejectedFiles) => {
              if (acceptedFiles.length > 0) {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(acceptedFiles[0])
                fileReader.addEventListener('load', function () {
                  setNewsletterMailing((prev) => {
                    let cpyItem = { ...prev }

                    if (cpyItem.items) {
                      cpyItem.items = cpyItem.items.map(
                        (
                          item: UpdateNewsletterMailingItemMutationVariables & {
                            imageSrc: string
                            task: Types.ArrayUpdate
                          }
                        ) => {
                          if (item && item.id === newsletterMailingItem.id) {
                            item = {
                              ...newsletterMailingItem,
                              image: acceptedFiles[0],
                              imageSrc: this.result?.toString() || '',
                            }
                          }
                          return item
                        }
                      ) as (UpdateNewsletterMailingItemMutationVariables & {
                        imageSrc: string
                        task: Types.ArrayUpdate
                      })[]
                    }

                    return cpyItem
                  })
                })
              }
              setRejectedFiles(rejectedFiles)
            }}
            overrides={{
              ContentMessage: {
                component: () => (
                  <span>
                    {intl.formatMessage({
                      id: 'newsletters.newsletterItem.dropFilesHere',
                      defaultMessage: 'Drop file here to upload...',
                    })}
                  </span>
                ),
              },
            }}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'newsletters.newsletterItem.imageCopyright',
            defaultMessage: 'Copyright',
          })}
          error={errors.imageCopyright || undefined}
        >
          <Input
            value={newsletterMailingItem.imageCopyright || ''}
            onChange={(e) =>
              setNewsletterMailing((prev) => {
                let cpyItem = { ...prev }

                if (cpyItem.items) {
                  cpyItem.items = cpyItem.items.map((item) => {
                    console.log(
                      '%cNewsletterItem.tsx line:128 item.id, newsletterMailingItem.id',
                      'color: #007acc;',
                      item?.id,
                      newsletterMailingItem.id
                    )
                    if (item && item.id === newsletterMailingItem.id) {
                      item = {
                        ...newsletterMailingItem,
                        imageCopyright: (e.target as HTMLInputElement).value,
                      }
                    }
                    return item
                  }) as (UpdateNewsletterMailingItemMutationVariables & {
                    imageSrc: string
                    task: Types.ArrayUpdate
                  })[]
                }

                return cpyItem
              })
            }
            positive={errors.imageCopyright === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'newsletters.newsletterItem.link',
            defaultMessage: 'Link',
          })}
          error={errors.link || undefined}
        >
          <Input
            value={newsletterMailingItem.link || ''}
            onChange={(e) =>
              setNewsletterMailing((prev) => {
                let cpyItem = { ...prev }

                if (cpyItem.items) {
                  cpyItem.items = cpyItem.items.map((item) => {
                    if (item && item.id === newsletterMailingItem.id) {
                      item = {
                        ...newsletterMailingItem,
                        link: (e.target as HTMLInputElement).value,
                      }
                    }
                    return item
                  }) as (UpdateNewsletterMailingItemMutationVariables & {
                    imageSrc: string
                    task: Types.ArrayUpdate
                  })[]
                }

                return cpyItem
              })
            }
            positive={errors.link === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'newsletters.newsletterItem.title',
            defaultMessage: 'Title',
          })}
          error={errors.title || undefined}
        >
          <Input
            value={newsletterMailingItem.title || ''}
            onChange={(e) =>
              setNewsletterMailing((prev) => {
                let cpyItem = { ...prev }

                if (cpyItem.items) {
                  cpyItem.items = cpyItem.items.map((item) => {
                    if (item && item.id === newsletterMailingItem.id) {
                      item = {
                        ...newsletterMailingItem,
                        title: (e.target as HTMLInputElement).value,
                      }
                    }
                    return item
                  }) as (UpdateNewsletterMailingItemMutationVariables & {
                    imageSrc: string
                    task: Types.ArrayUpdate
                  })[]
                }

                return cpyItem
              })
            }
            positive={errors.title === ''}
          />
        </FormControl>
        <FormControl
          label={intl.formatMessage({
            id: 'newsletters.newsletterItem.content',
            defaultMessage: 'Content',
          })}
          error={errors.content || undefined}
        >
          <div
            style={{
              cursor: 'text',
            }}
            role='button'
          >
            <CKEditor
                  //@ts-ignore
              style={{
                color: '#fff',
              }}
              editor={InlineEditor}
              config={{
                language: 'de-ch',
                toolbar: [
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'insertTable',
                  'undo',
                  'redo',
                ],
              }}
              data={newsletterMailingItem.content}
              //@ts-ignore
              onChange={(event: any, editor: any) => {
                const data = editor.getData()
                setNewsletterMailing((prev) => {
                  let cpyItem = { ...prev }

                  if (cpyItem.items) {
                    cpyItem.items = cpyItem.items.map((item) => {
                      if (item && item.id === newsletterMailingItem.id) {
                        item = {
                          ...newsletterMailingItem,
                          content: data,
                        }
                      }
                      return item
                    }) as (UpdateNewsletterMailingItemMutationVariables & {
                      imageSrc: string
                      task: Types.ArrayUpdate
                    })[]
                  }

                  return cpyItem
                })
              }}
            />
          </div>
        </FormControl>
      </StyledBody>
    </Card>
  )
}
