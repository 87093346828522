import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import AssociationBusiness from './Association.business'
import getReactApolloCients from '../../../../localReactApollo'
import { useGetAssociationTypesNoPersonQuery } from '../../../../gql/members/AssociationType.generated'
import { useGetAssociationsNoPersonQuery } from '../../../../gql/members/Association.generated'
import { useGetMembershipNoPersonQuery } from '../../../../gql/members/Membership.generated'
import { ArrayTasks } from '../../../../gql/members/types'

export default function WorkLevelData({
  associations,
  setAssociations,
  associationPayTo,
  setAssociationPayTo,
  associationComment,
  setAssociationComment,
  getHasAssociationErrors,
  membership,
}: {
  associations?: { 
    associationId: string
    associationTypes: {
        id: string;
        label: string;
        task?: ArrayTasks;
    }[]
   }[]
  setAssociations: React.Dispatch<React.SetStateAction<{ 
    associationId: string
    associationTypes: {
        id: string;
        label: string;
        task?: ArrayTasks;
    }[]
   }[]>>
  associationPayTo: { id: string }
  setAssociationPayTo: React.Dispatch<React.SetStateAction<{ id: string }>>
  associationComment: string
  setAssociationComment: React.Dispatch<React.SetStateAction<string>>
  getHasAssociationErrors: (errors: { 
    association: string
    associationPayTo: string
   }) => void
   membership: string
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [associationList, setAssociationList] = useState<
    {
      id: string
      needsAComment: boolean
      prize: number
      label: string
    }[]
  >([])
  const [associationTypes, setAssociationTypes] = useState<
    {
      id: string
      label: string
      isDefaultType: boolean
    }[]
  >([])
  const [memberExtend, setMemberExtend] = useState<{
    id: string
    prize?: number
    directInvoicing: boolean
  }>({
    id: '',
    prize: undefined,
    directInvoicing: false
  })
  const client = getReactApolloCients().getClient()
  const {
    data: dataAssociations,
    loading: loadingAssociations,
    error: errorAssociations,
  } = useGetAssociationsNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })
  const { data: dataAssociationTypes, loading: loadingAssociationTypes, error: errorAssociationTypes } = useGetAssociationTypesNoPersonQuery({
     variables: {
        cursor: {
          pageSize: 100,
        },
     },
     //@ts-ignore
     client,
   });
   const { data: dataMember, loading: loadingMember } = useGetMembershipNoPersonQuery({
       variables: {
          id: membership
       },
       //@ts-ignore
       client,
     });

  useEffect(() => {
    if (errorAssociations || errorAssociationTypes) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.association.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.association.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorAssociations?.message)
    }
  }, [errorAssociations, errorAssociationTypes])

  useEffect(() => {
    if (dataAssociations?.getAssociationsNoPerson.items) {
      setAssociationList(
        dataAssociations?.getAssociationsNoPerson.items
          .map((item) => ({
            id: item.id,
            needsAComment: item.needsAComment,
            prize: item.prize,
            label: `${getTranslation(item.short, intl)}: ${getTranslation(item.name, intl)}`,
          }))
          .sort(function (a, b) {
            var labelA = a.label.toUpperCase()
            var labelB = b.label.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataAssociations])

  useEffect(() => {
    if (dataAssociationTypes?.getAssociationTypesNoPerson.items) {
      setAssociationTypes(
        dataAssociationTypes?.getAssociationTypesNoPerson.items
          .map((item) => ({
            id: item.id,
            label: getTranslation(item.name, intl),
            isDefaultType: item.isDefaultType,
          }))
          .sort(function (a, b) {
            if (a.isDefaultType || b.isDefaultType) {
              return 1
            }
            var labelA = a.label.toUpperCase()
            var labelB = b.label.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataAssociationTypes])

  useEffect(() => {
    if(dataMember?.getMembershipNoPerson) {
      setMemberExtend({
        id: dataMember.getMembershipNoPerson.id,
        prize: dataMember.getMembershipNoPerson.prize,
        directInvoicing: dataMember.getMembershipNoPerson.directInvoicing,
      })
    }

  }, [dataMember])

  return (
    <AssociationBusiness
      associations={associations}
      setAssociations={setAssociations}
      associationPayTo={associationPayTo}
      setAssociationPayTo={setAssociationPayTo}
      loadingAssociations={loadingAssociations || loadingAssociationTypes || loadingMember}
      associationList={associationList}
      associationTypes={associationTypes}
      associationComment={associationComment}
      setAssociationComment={setAssociationComment}
      getHasAssociationErrors={getHasAssociationErrors}
      memberExtend={memberExtend}
    />
  )
}
