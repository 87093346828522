// @ts-nocheck
import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type InvoiceFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Types.Invoice, 'id' | 'invoiceNumber' | 'date' | 'total' | 'payAmount' | 'payDate' | 'sendAt' | 'canceledAt' | 'account' | 'iban' | 'paymentFirstName' | 'paymentLastName' | 'paymentFirmName' | 'paymentMailbox' | 'paymentStreetNr' | 'paymentPostCode' | 'paymentPlace' | 'paymentCanton' | 'paymentCountry' | 'email' | 'salutation' | 'firstName' | 'lastName' | 'firmName' | 'mailbox' | 'streetNr' | 'postCode' | 'place' | 'canton' | 'country' | 'createdFrom'>
  & { items: Array<(
    { __typename?: 'InvoiceItem' }
    & Pick<Types.InvoiceItem, 'position' | 'count' | 'price' | 'name'>
  )>, courseRegistration?: Types.Maybe<(
    { __typename?: 'CourseRegistration' }
    & Pick<Types.CourseRegistration, 'id'>
  )> }
);

export type GetInvoiceQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { getInvoice: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export type GetInvoiceIfFromAssociationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetInvoiceIfFromAssociationQuery = (
  { __typename?: 'Query' }
  & { getInvoiceIfFromAssociation: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export type GetInvoiceIfToAssociationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetInvoiceIfToAssociationQuery = (
  { __typename?: 'Query' }
  & { getInvoiceIfToAssociation: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export type GetInvoicesIdQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetInvoicesIdQuery = (
  { __typename?: 'Query' }
  & { getInvoices: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & Pick<Types.Invoice, 'id'>
    )> }
  ) }
);

export type GetInvoicesNumberQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetInvoicesNumberQuery = (
  { __typename?: 'Query' }
  & { getInvoices: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & Pick<Types.Invoice, 'invoiceNumber'>
    )> }
  ) }
);

export type GetEMailAndAttachmentsQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetEMailAndAttachmentsQuery = (
  { __typename?: 'Query' }
  & { getInvoices: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & Pick<Types.Invoice, 'email' | 'pdfPath'>
    )> }
  ) }
);

export type GetInvoicesQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetInvoicesQuery = (
  { __typename?: 'Query' }
  & { getInvoices: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type GetNextInvoicesQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextInvoicesQuery = (
  { __typename?: 'Query' }
  & { getNextInvoices: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type GetInvoicesIfOwnerQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetInvoicesIfOwnerQuery = (
  { __typename?: 'Query' }
  & { getInvoicesIfOwner: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type GetNextInvoicesIfOwnerQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextInvoicesIfOwnerQuery = (
  { __typename?: 'Query' }
  & { getNextInvoicesIfOwner: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type GetInvoicesIfFromAssociationQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetInvoicesIfFromAssociationQuery = (
  { __typename?: 'Query' }
  & { getInvoicesIfFromAssociation: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type GetInvoicesIfToAssociationQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetInvoicesIfToAssociationQuery = (
  { __typename?: 'Query' }
  & { getInvoicesIfToAssociation: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type GetNextInvoicesIfFromAssociationQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextInvoicesIfFromAssociationQuery = (
  { __typename?: 'Query' }
  & { getNextInvoicesIfFromAssociation: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type GetNextInvoicesIfToAssociationQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextInvoicesIfToAssociationQuery = (
  { __typename?: 'Query' }
  & { getNextInvoicesIfToAssociation: (
    { __typename?: 'InvoicePagination' }
    & Pick<Types.InvoicePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )> }
  ) }
);

export type CreateDirectMemberInvoiceMutationVariables = Types.Exact<{
  processId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type CreateDirectMemberInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createDirectMemberInvoice: (
    { __typename?: 'createInvoiceReturn' }
    & Pick<Types.CreateInvoiceReturn, 'errorMessage' | 'processId' | 'progress' | 'returnCode'>
  ) }
);

export type CreateAssociationInvoiceMutationVariables = Types.Exact<{
  processId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type CreateAssociationInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createAssociationInvoice: (
    { __typename?: 'createInvoiceReturn' }
    & Pick<Types.CreateInvoiceReturn, 'errorMessage' | 'processId' | 'progress' | 'returnCode'>
  ) }
);

export type CreateOneAssociationInvoiceMutationVariables = Types.Exact<{
  processId?: Types.Maybe<Types.Scalars['ID']>;
  associationId: Types.Scalars['ID'];
}>;


export type CreateOneAssociationInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createOneAssociationInvoice: (
    { __typename?: 'createInvoiceReturn' }
    & Pick<Types.CreateInvoiceReturn, 'errorMessage' | 'processId' | 'progress' | 'returnCode'>
  ) }
);

export type CreateMemberInvoiceMutationVariables = Types.Exact<{
  processId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type CreateMemberInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createMemberInvoice: (
    { __typename?: 'createInvoiceReturn' }
    & Pick<Types.CreateInvoiceReturn, 'errorMessage' | 'processId' | 'progress' | 'returnCode'>
  ) }
);

export type AddInvoiceMutationVariables = Types.Exact<{
  personId: Types.Scalars['ID'];
  title: Types.Scalars['String'];
  itemText: Types.Scalars['String'];
  cost: Types.Scalars['Int'];
}>;


export type AddInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { addInvoice: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export type UpdateInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  payAmount: Types.Scalars['Int'];
  payDate?: Types.Maybe<Types.Scalars['String']>;
  total: Types.Scalars['Int'];
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export type UpdateInvoiceIfAssociationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  payAmount: Types.Scalars['Int'];
  payDate?: Types.Maybe<Types.Scalars['String']>;
  total: Types.Scalars['Int'];
}>;


export type UpdateInvoiceIfAssociationMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceIfAssociation: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export type DeleteInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoice: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export const InvoiceFragmentFragmentDoc = gql`
    fragment InvoiceFragment on Invoice {
  id
  invoiceNumber
  date
  items {
    position
    count
    price
    name
  }
  total
  payAmount
  payDate
  sendAt
  canceledAt
  account
  iban
  paymentFirstName
  paymentLastName
  paymentFirmName
  paymentMailbox
  paymentStreetNr
  paymentPostCode
  paymentPlace
  paymentCanton
  paymentCountry
  email
  salutation
  firstName
  lastName
  firmName
  mailbox
  streetNr
  postCode
  place
  canton
  country
  createdFrom
  courseRegistration {
    id
  }
}
    `;
export const GetInvoiceDocument = gql`
    query getInvoice($id: ID!) {
  getInvoice(id: $id) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const GetInvoiceIfFromAssociationDocument = gql`
    query getInvoiceIfFromAssociation($id: ID!) {
  getInvoiceIfFromAssociation(id: $id) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetInvoiceIfFromAssociationQuery__
 *
 * To run a query within a React component, call `useGetInvoiceIfFromAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceIfFromAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceIfFromAssociationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceIfFromAssociationQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceIfFromAssociationQuery, GetInvoiceIfFromAssociationQueryVariables>) {
        return Apollo.useQuery<GetInvoiceIfFromAssociationQuery, GetInvoiceIfFromAssociationQueryVariables>(GetInvoiceIfFromAssociationDocument, baseOptions);
      }
export function useGetInvoiceIfFromAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceIfFromAssociationQuery, GetInvoiceIfFromAssociationQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceIfFromAssociationQuery, GetInvoiceIfFromAssociationQueryVariables>(GetInvoiceIfFromAssociationDocument, baseOptions);
        }
export type GetInvoiceIfFromAssociationQueryHookResult = ReturnType<typeof useGetInvoiceIfFromAssociationQuery>;
export type GetInvoiceIfFromAssociationLazyQueryHookResult = ReturnType<typeof useGetInvoiceIfFromAssociationLazyQuery>;
export type GetInvoiceIfFromAssociationQueryResult = Apollo.QueryResult<GetInvoiceIfFromAssociationQuery, GetInvoiceIfFromAssociationQueryVariables>;
export const GetInvoiceIfToAssociationDocument = gql`
    query getInvoiceIfToAssociation($id: ID!) {
  getInvoiceIfToAssociation(id: $id) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetInvoiceIfToAssociationQuery__
 *
 * To run a query within a React component, call `useGetInvoiceIfToAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceIfToAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceIfToAssociationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceIfToAssociationQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceIfToAssociationQuery, GetInvoiceIfToAssociationQueryVariables>) {
        return Apollo.useQuery<GetInvoiceIfToAssociationQuery, GetInvoiceIfToAssociationQueryVariables>(GetInvoiceIfToAssociationDocument, baseOptions);
      }
export function useGetInvoiceIfToAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceIfToAssociationQuery, GetInvoiceIfToAssociationQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceIfToAssociationQuery, GetInvoiceIfToAssociationQueryVariables>(GetInvoiceIfToAssociationDocument, baseOptions);
        }
export type GetInvoiceIfToAssociationQueryHookResult = ReturnType<typeof useGetInvoiceIfToAssociationQuery>;
export type GetInvoiceIfToAssociationLazyQueryHookResult = ReturnType<typeof useGetInvoiceIfToAssociationLazyQuery>;
export type GetInvoiceIfToAssociationQueryResult = Apollo.QueryResult<GetInvoiceIfToAssociationQuery, GetInvoiceIfToAssociationQueryVariables>;
export const GetInvoicesIdDocument = gql`
    query getInvoicesId($cursor: StartCursorInput!) {
  getInvoices(cursor: $cursor) {
    items {
      id
    }
    nextCursor
  }
}
    `;

/**
 * __useGetInvoicesIdQuery__
 *
 * To run a query within a React component, call `useGetInvoicesIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesIdQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetInvoicesIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesIdQuery, GetInvoicesIdQueryVariables>) {
        return Apollo.useQuery<GetInvoicesIdQuery, GetInvoicesIdQueryVariables>(GetInvoicesIdDocument, baseOptions);
      }
export function useGetInvoicesIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesIdQuery, GetInvoicesIdQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoicesIdQuery, GetInvoicesIdQueryVariables>(GetInvoicesIdDocument, baseOptions);
        }
export type GetInvoicesIdQueryHookResult = ReturnType<typeof useGetInvoicesIdQuery>;
export type GetInvoicesIdLazyQueryHookResult = ReturnType<typeof useGetInvoicesIdLazyQuery>;
export type GetInvoicesIdQueryResult = Apollo.QueryResult<GetInvoicesIdQuery, GetInvoicesIdQueryVariables>;
export const GetInvoicesNumberDocument = gql`
    query getInvoicesNumber($cursor: StartCursorInput!) {
  getInvoices(cursor: $cursor) {
    items {
      invoiceNumber
    }
    nextCursor
  }
}
    `;

/**
 * __useGetInvoicesNumberQuery__
 *
 * To run a query within a React component, call `useGetInvoicesNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesNumberQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetInvoicesNumberQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesNumberQuery, GetInvoicesNumberQueryVariables>) {
        return Apollo.useQuery<GetInvoicesNumberQuery, GetInvoicesNumberQueryVariables>(GetInvoicesNumberDocument, baseOptions);
      }
export function useGetInvoicesNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesNumberQuery, GetInvoicesNumberQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoicesNumberQuery, GetInvoicesNumberQueryVariables>(GetInvoicesNumberDocument, baseOptions);
        }
export type GetInvoicesNumberQueryHookResult = ReturnType<typeof useGetInvoicesNumberQuery>;
export type GetInvoicesNumberLazyQueryHookResult = ReturnType<typeof useGetInvoicesNumberLazyQuery>;
export type GetInvoicesNumberQueryResult = Apollo.QueryResult<GetInvoicesNumberQuery, GetInvoicesNumberQueryVariables>;
export const GetEMailAndAttachmentsDocument = gql`
    query getEMailAndAttachments($cursor: StartCursorInput!) {
  getInvoices(cursor: $cursor) {
    items {
      email
      pdfPath
    }
    nextCursor
  }
}
    `;

/**
 * __useGetEMailAndAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetEMailAndAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEMailAndAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEMailAndAttachmentsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetEMailAndAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetEMailAndAttachmentsQuery, GetEMailAndAttachmentsQueryVariables>) {
        return Apollo.useQuery<GetEMailAndAttachmentsQuery, GetEMailAndAttachmentsQueryVariables>(GetEMailAndAttachmentsDocument, baseOptions);
      }
export function useGetEMailAndAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEMailAndAttachmentsQuery, GetEMailAndAttachmentsQueryVariables>) {
          return Apollo.useLazyQuery<GetEMailAndAttachmentsQuery, GetEMailAndAttachmentsQueryVariables>(GetEMailAndAttachmentsDocument, baseOptions);
        }
export type GetEMailAndAttachmentsQueryHookResult = ReturnType<typeof useGetEMailAndAttachmentsQuery>;
export type GetEMailAndAttachmentsLazyQueryHookResult = ReturnType<typeof useGetEMailAndAttachmentsLazyQuery>;
export type GetEMailAndAttachmentsQueryResult = Apollo.QueryResult<GetEMailAndAttachmentsQuery, GetEMailAndAttachmentsQueryVariables>;
export const GetInvoicesDocument = gql`
    query getInvoices($cursor: StartCursorInput!) {
  getInvoices(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetNextInvoicesDocument = gql`
    query getNextInvoices($cursor: NextCursorInput!) {
  getNextInvoices(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetNextInvoicesQuery__
 *
 * To run a query within a React component, call `useGetNextInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextInvoicesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetNextInvoicesQuery, GetNextInvoicesQueryVariables>) {
        return Apollo.useQuery<GetNextInvoicesQuery, GetNextInvoicesQueryVariables>(GetNextInvoicesDocument, baseOptions);
      }
export function useGetNextInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextInvoicesQuery, GetNextInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<GetNextInvoicesQuery, GetNextInvoicesQueryVariables>(GetNextInvoicesDocument, baseOptions);
        }
export type GetNextInvoicesQueryHookResult = ReturnType<typeof useGetNextInvoicesQuery>;
export type GetNextInvoicesLazyQueryHookResult = ReturnType<typeof useGetNextInvoicesLazyQuery>;
export type GetNextInvoicesQueryResult = Apollo.QueryResult<GetNextInvoicesQuery, GetNextInvoicesQueryVariables>;
export const GetInvoicesIfOwnerDocument = gql`
    query getInvoicesIfOwner($cursor: StartCursorInput!) {
  getInvoicesIfOwner(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetInvoicesIfOwnerQuery__
 *
 * To run a query within a React component, call `useGetInvoicesIfOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesIfOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesIfOwnerQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetInvoicesIfOwnerQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesIfOwnerQuery, GetInvoicesIfOwnerQueryVariables>) {
        return Apollo.useQuery<GetInvoicesIfOwnerQuery, GetInvoicesIfOwnerQueryVariables>(GetInvoicesIfOwnerDocument, baseOptions);
      }
export function useGetInvoicesIfOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesIfOwnerQuery, GetInvoicesIfOwnerQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoicesIfOwnerQuery, GetInvoicesIfOwnerQueryVariables>(GetInvoicesIfOwnerDocument, baseOptions);
        }
export type GetInvoicesIfOwnerQueryHookResult = ReturnType<typeof useGetInvoicesIfOwnerQuery>;
export type GetInvoicesIfOwnerLazyQueryHookResult = ReturnType<typeof useGetInvoicesIfOwnerLazyQuery>;
export type GetInvoicesIfOwnerQueryResult = Apollo.QueryResult<GetInvoicesIfOwnerQuery, GetInvoicesIfOwnerQueryVariables>;
export const GetNextInvoicesIfOwnerDocument = gql`
    query getNextInvoicesIfOwner($cursor: NextCursorInput!) {
  getNextInvoicesIfOwner(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetNextInvoicesIfOwnerQuery__
 *
 * To run a query within a React component, call `useGetNextInvoicesIfOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextInvoicesIfOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextInvoicesIfOwnerQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextInvoicesIfOwnerQuery(baseOptions: Apollo.QueryHookOptions<GetNextInvoicesIfOwnerQuery, GetNextInvoicesIfOwnerQueryVariables>) {
        return Apollo.useQuery<GetNextInvoicesIfOwnerQuery, GetNextInvoicesIfOwnerQueryVariables>(GetNextInvoicesIfOwnerDocument, baseOptions);
      }
export function useGetNextInvoicesIfOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextInvoicesIfOwnerQuery, GetNextInvoicesIfOwnerQueryVariables>) {
          return Apollo.useLazyQuery<GetNextInvoicesIfOwnerQuery, GetNextInvoicesIfOwnerQueryVariables>(GetNextInvoicesIfOwnerDocument, baseOptions);
        }
export type GetNextInvoicesIfOwnerQueryHookResult = ReturnType<typeof useGetNextInvoicesIfOwnerQuery>;
export type GetNextInvoicesIfOwnerLazyQueryHookResult = ReturnType<typeof useGetNextInvoicesIfOwnerLazyQuery>;
export type GetNextInvoicesIfOwnerQueryResult = Apollo.QueryResult<GetNextInvoicesIfOwnerQuery, GetNextInvoicesIfOwnerQueryVariables>;
export const GetInvoicesIfFromAssociationDocument = gql`
    query getInvoicesIfFromAssociation($cursor: StartCursorInput!) {
  getInvoicesIfFromAssociation(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetInvoicesIfFromAssociationQuery__
 *
 * To run a query within a React component, call `useGetInvoicesIfFromAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesIfFromAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesIfFromAssociationQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetInvoicesIfFromAssociationQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesIfFromAssociationQuery, GetInvoicesIfFromAssociationQueryVariables>) {
        return Apollo.useQuery<GetInvoicesIfFromAssociationQuery, GetInvoicesIfFromAssociationQueryVariables>(GetInvoicesIfFromAssociationDocument, baseOptions);
      }
export function useGetInvoicesIfFromAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesIfFromAssociationQuery, GetInvoicesIfFromAssociationQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoicesIfFromAssociationQuery, GetInvoicesIfFromAssociationQueryVariables>(GetInvoicesIfFromAssociationDocument, baseOptions);
        }
export type GetInvoicesIfFromAssociationQueryHookResult = ReturnType<typeof useGetInvoicesIfFromAssociationQuery>;
export type GetInvoicesIfFromAssociationLazyQueryHookResult = ReturnType<typeof useGetInvoicesIfFromAssociationLazyQuery>;
export type GetInvoicesIfFromAssociationQueryResult = Apollo.QueryResult<GetInvoicesIfFromAssociationQuery, GetInvoicesIfFromAssociationQueryVariables>;
export const GetInvoicesIfToAssociationDocument = gql`
    query getInvoicesIfToAssociation($cursor: StartCursorInput!) {
  getInvoicesIfToAssociation(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetInvoicesIfToAssociationQuery__
 *
 * To run a query within a React component, call `useGetInvoicesIfToAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesIfToAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesIfToAssociationQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetInvoicesIfToAssociationQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesIfToAssociationQuery, GetInvoicesIfToAssociationQueryVariables>) {
        return Apollo.useQuery<GetInvoicesIfToAssociationQuery, GetInvoicesIfToAssociationQueryVariables>(GetInvoicesIfToAssociationDocument, baseOptions);
      }
export function useGetInvoicesIfToAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesIfToAssociationQuery, GetInvoicesIfToAssociationQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoicesIfToAssociationQuery, GetInvoicesIfToAssociationQueryVariables>(GetInvoicesIfToAssociationDocument, baseOptions);
        }
export type GetInvoicesIfToAssociationQueryHookResult = ReturnType<typeof useGetInvoicesIfToAssociationQuery>;
export type GetInvoicesIfToAssociationLazyQueryHookResult = ReturnType<typeof useGetInvoicesIfToAssociationLazyQuery>;
export type GetInvoicesIfToAssociationQueryResult = Apollo.QueryResult<GetInvoicesIfToAssociationQuery, GetInvoicesIfToAssociationQueryVariables>;
export const GetNextInvoicesIfFromAssociationDocument = gql`
    query getNextInvoicesIfFromAssociation($cursor: NextCursorInput!) {
  getNextInvoicesIfFromAssociation(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetNextInvoicesIfFromAssociationQuery__
 *
 * To run a query within a React component, call `useGetNextInvoicesIfFromAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextInvoicesIfFromAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextInvoicesIfFromAssociationQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextInvoicesIfFromAssociationQuery(baseOptions: Apollo.QueryHookOptions<GetNextInvoicesIfFromAssociationQuery, GetNextInvoicesIfFromAssociationQueryVariables>) {
        return Apollo.useQuery<GetNextInvoicesIfFromAssociationQuery, GetNextInvoicesIfFromAssociationQueryVariables>(GetNextInvoicesIfFromAssociationDocument, baseOptions);
      }
export function useGetNextInvoicesIfFromAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextInvoicesIfFromAssociationQuery, GetNextInvoicesIfFromAssociationQueryVariables>) {
          return Apollo.useLazyQuery<GetNextInvoicesIfFromAssociationQuery, GetNextInvoicesIfFromAssociationQueryVariables>(GetNextInvoicesIfFromAssociationDocument, baseOptions);
        }
export type GetNextInvoicesIfFromAssociationQueryHookResult = ReturnType<typeof useGetNextInvoicesIfFromAssociationQuery>;
export type GetNextInvoicesIfFromAssociationLazyQueryHookResult = ReturnType<typeof useGetNextInvoicesIfFromAssociationLazyQuery>;
export type GetNextInvoicesIfFromAssociationQueryResult = Apollo.QueryResult<GetNextInvoicesIfFromAssociationQuery, GetNextInvoicesIfFromAssociationQueryVariables>;
export const GetNextInvoicesIfToAssociationDocument = gql`
    query getNextInvoicesIfToAssociation($cursor: NextCursorInput!) {
  getNextInvoicesIfToAssociation(cursor: $cursor) {
    items {
      ...InvoiceFragment
    }
    nextCursor
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useGetNextInvoicesIfToAssociationQuery__
 *
 * To run a query within a React component, call `useGetNextInvoicesIfToAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextInvoicesIfToAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextInvoicesIfToAssociationQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextInvoicesIfToAssociationQuery(baseOptions: Apollo.QueryHookOptions<GetNextInvoicesIfToAssociationQuery, GetNextInvoicesIfToAssociationQueryVariables>) {
        return Apollo.useQuery<GetNextInvoicesIfToAssociationQuery, GetNextInvoicesIfToAssociationQueryVariables>(GetNextInvoicesIfToAssociationDocument, baseOptions);
      }
export function useGetNextInvoicesIfToAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextInvoicesIfToAssociationQuery, GetNextInvoicesIfToAssociationQueryVariables>) {
          return Apollo.useLazyQuery<GetNextInvoicesIfToAssociationQuery, GetNextInvoicesIfToAssociationQueryVariables>(GetNextInvoicesIfToAssociationDocument, baseOptions);
        }
export type GetNextInvoicesIfToAssociationQueryHookResult = ReturnType<typeof useGetNextInvoicesIfToAssociationQuery>;
export type GetNextInvoicesIfToAssociationLazyQueryHookResult = ReturnType<typeof useGetNextInvoicesIfToAssociationLazyQuery>;
export type GetNextInvoicesIfToAssociationQueryResult = Apollo.QueryResult<GetNextInvoicesIfToAssociationQuery, GetNextInvoicesIfToAssociationQueryVariables>;
export const CreateDirectMemberInvoiceDocument = gql`
    mutation createDirectMemberInvoice($processId: ID) {
  createDirectMemberInvoice(processId: $processId) {
    errorMessage
    processId
    progress
    returnCode
  }
}
    `;
export type CreateDirectMemberInvoiceMutationFn = Apollo.MutationFunction<CreateDirectMemberInvoiceMutation, CreateDirectMemberInvoiceMutationVariables>;

/**
 * __useCreateDirectMemberInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateDirectMemberInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectMemberInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectMemberInvoiceMutation, { data, loading, error }] = useCreateDirectMemberInvoiceMutation({
 *   variables: {
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useCreateDirectMemberInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectMemberInvoiceMutation, CreateDirectMemberInvoiceMutationVariables>) {
        return Apollo.useMutation<CreateDirectMemberInvoiceMutation, CreateDirectMemberInvoiceMutationVariables>(CreateDirectMemberInvoiceDocument, baseOptions);
      }
export type CreateDirectMemberInvoiceMutationHookResult = ReturnType<typeof useCreateDirectMemberInvoiceMutation>;
export type CreateDirectMemberInvoiceMutationResult = Apollo.MutationResult<CreateDirectMemberInvoiceMutation>;
export type CreateDirectMemberInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateDirectMemberInvoiceMutation, CreateDirectMemberInvoiceMutationVariables>;
export const CreateAssociationInvoiceDocument = gql`
    mutation createAssociationInvoice($processId: ID) {
  createAssociationInvoice(processId: $processId) {
    errorMessage
    processId
    progress
    returnCode
  }
}
    `;
export type CreateAssociationInvoiceMutationFn = Apollo.MutationFunction<CreateAssociationInvoiceMutation, CreateAssociationInvoiceMutationVariables>;

/**
 * __useCreateAssociationInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateAssociationInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssociationInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssociationInvoiceMutation, { data, loading, error }] = useCreateAssociationInvoiceMutation({
 *   variables: {
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useCreateAssociationInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssociationInvoiceMutation, CreateAssociationInvoiceMutationVariables>) {
        return Apollo.useMutation<CreateAssociationInvoiceMutation, CreateAssociationInvoiceMutationVariables>(CreateAssociationInvoiceDocument, baseOptions);
      }
export type CreateAssociationInvoiceMutationHookResult = ReturnType<typeof useCreateAssociationInvoiceMutation>;
export type CreateAssociationInvoiceMutationResult = Apollo.MutationResult<CreateAssociationInvoiceMutation>;
export type CreateAssociationInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateAssociationInvoiceMutation, CreateAssociationInvoiceMutationVariables>;
export const CreateOneAssociationInvoiceDocument = gql`
    mutation createOneAssociationInvoice($processId: ID, $associationId: ID!) {
  createOneAssociationInvoice(
    processId: $processId
    associationId: $associationId
  ) {
    errorMessage
    processId
    progress
    returnCode
  }
}
    `;
export type CreateOneAssociationInvoiceMutationFn = Apollo.MutationFunction<CreateOneAssociationInvoiceMutation, CreateOneAssociationInvoiceMutationVariables>;

/**
 * __useCreateOneAssociationInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateOneAssociationInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneAssociationInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneAssociationInvoiceMutation, { data, loading, error }] = useCreateOneAssociationInvoiceMutation({
 *   variables: {
 *      processId: // value for 'processId'
 *      associationId: // value for 'associationId'
 *   },
 * });
 */
export function useCreateOneAssociationInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneAssociationInvoiceMutation, CreateOneAssociationInvoiceMutationVariables>) {
        return Apollo.useMutation<CreateOneAssociationInvoiceMutation, CreateOneAssociationInvoiceMutationVariables>(CreateOneAssociationInvoiceDocument, baseOptions);
      }
export type CreateOneAssociationInvoiceMutationHookResult = ReturnType<typeof useCreateOneAssociationInvoiceMutation>;
export type CreateOneAssociationInvoiceMutationResult = Apollo.MutationResult<CreateOneAssociationInvoiceMutation>;
export type CreateOneAssociationInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateOneAssociationInvoiceMutation, CreateOneAssociationInvoiceMutationVariables>;
export const CreateMemberInvoiceDocument = gql`
    mutation createMemberInvoice($processId: ID) {
  createMemberInvoice(processId: $processId) {
    errorMessage
    processId
    progress
    returnCode
  }
}
    `;
export type CreateMemberInvoiceMutationFn = Apollo.MutationFunction<CreateMemberInvoiceMutation, CreateMemberInvoiceMutationVariables>;

/**
 * __useCreateMemberInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateMemberInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberInvoiceMutation, { data, loading, error }] = useCreateMemberInvoiceMutation({
 *   variables: {
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useCreateMemberInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberInvoiceMutation, CreateMemberInvoiceMutationVariables>) {
        return Apollo.useMutation<CreateMemberInvoiceMutation, CreateMemberInvoiceMutationVariables>(CreateMemberInvoiceDocument, baseOptions);
      }
export type CreateMemberInvoiceMutationHookResult = ReturnType<typeof useCreateMemberInvoiceMutation>;
export type CreateMemberInvoiceMutationResult = Apollo.MutationResult<CreateMemberInvoiceMutation>;
export type CreateMemberInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateMemberInvoiceMutation, CreateMemberInvoiceMutationVariables>;
export const AddInvoiceDocument = gql`
    mutation addInvoice($personId: ID!, $title: String!, $itemText: String!, $cost: Int!) {
  addInvoice(personId: $personId, title: $title, itemText: $itemText, cost: $cost) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;
export type AddInvoiceMutationFn = Apollo.MutationFunction<AddInvoiceMutation, AddInvoiceMutationVariables>;

/**
 * __useAddInvoiceMutation__
 *
 * To run a mutation, you first call `useAddInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceMutation, { data, loading, error }] = useAddInvoiceMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      title: // value for 'title'
 *      itemText: // value for 'itemText'
 *      cost: // value for 'cost'
 *   },
 * });
 */
export function useAddInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AddInvoiceMutation, AddInvoiceMutationVariables>) {
        return Apollo.useMutation<AddInvoiceMutation, AddInvoiceMutationVariables>(AddInvoiceDocument, baseOptions);
      }
export type AddInvoiceMutationHookResult = ReturnType<typeof useAddInvoiceMutation>;
export type AddInvoiceMutationResult = Apollo.MutationResult<AddInvoiceMutation>;
export type AddInvoiceMutationOptions = Apollo.BaseMutationOptions<AddInvoiceMutation, AddInvoiceMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation updateInvoice($id: ID!, $payAmount: Int!, $payDate: String, $total: Int!) {
  updateInvoice(id: $id, payAmount: $payAmount, payDate: $payDate, total: $total) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payAmount: // value for 'payAmount'
 *      payDate: // value for 'payDate'
 *      total: // value for 'total'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, baseOptions);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const UpdateInvoiceIfAssociationDocument = gql`
    mutation updateInvoiceIfAssociation($id: ID!, $payAmount: Int!, $payDate: String, $total: Int!) {
  updateInvoiceIfAssociation(
    id: $id
    payAmount: $payAmount
    payDate: $payDate
    total: $total
  ) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;
export type UpdateInvoiceIfAssociationMutationFn = Apollo.MutationFunction<UpdateInvoiceIfAssociationMutation, UpdateInvoiceIfAssociationMutationVariables>;

/**
 * __useUpdateInvoiceIfAssociationMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceIfAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceIfAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceIfAssociationMutation, { data, loading, error }] = useUpdateInvoiceIfAssociationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payAmount: // value for 'payAmount'
 *      payDate: // value for 'payDate'
 *      total: // value for 'total'
 *   },
 * });
 */
export function useUpdateInvoiceIfAssociationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceIfAssociationMutation, UpdateInvoiceIfAssociationMutationVariables>) {
        return Apollo.useMutation<UpdateInvoiceIfAssociationMutation, UpdateInvoiceIfAssociationMutationVariables>(UpdateInvoiceIfAssociationDocument, baseOptions);
      }
export type UpdateInvoiceIfAssociationMutationHookResult = ReturnType<typeof useUpdateInvoiceIfAssociationMutation>;
export type UpdateInvoiceIfAssociationMutationResult = Apollo.MutationResult<UpdateInvoiceIfAssociationMutation>;
export type UpdateInvoiceIfAssociationMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceIfAssociationMutation, UpdateInvoiceIfAssociationMutationVariables>;
export const DeleteInvoiceDocument = gql`
    mutation deleteInvoice($id: ID!) {
  deleteInvoice(id: $id) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>) {
        return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, baseOptions);
      }
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;