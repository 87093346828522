import { useEffect } from 'react'
import { Select } from 'baseui/select'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { useIntl } from 'react-intl'
import { Input } from 'baseui/input'
import { Spinner, SIZE } from 'baseui/spinner'

import {
  getTranslation,
  getCantonsOptions,
  getCountryOptions,
} from '@lemonbrain/utils'

type Types = {
  id: string
  isFirm: boolean
  label: string
}[]

type Cantons = ReturnType<typeof getCantonsOptions>
type Country = ReturnType<typeof getCountryOptions>

export default function Address({
  types,
  setAddress,
  address,
  loadingAddressType,
  errors,
}: {
  types: Types
  setAddress: (address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      isFirm?: boolean
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }) => void
  address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      isFirm?: boolean
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }
  loadingAddressType: boolean
  errors: {
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
  }
}) {
  const intl = useIntl()

  useEffect(() => {
    let tmpAddress: {
      id?: string
      salutation?: string
      firstName?: string
      lastName?: string
      firmName?: string
      mailbox?: string
      streetNr?: string
      postCode?: string
      place?: string
      canton?: string
      country?: string
      types?: {
        id: string
        isFirm?: boolean
        name: {
          de: string
          fr: string
          it: string
        }
      }
    } = {}
    if (types.length > 0 && !loadingAddressType && !address.types.id) {
      tmpAddress = {
        types: {
          id: types[0].id,
          isFirm: types[0].isFirm,
          name: {
            de: types[0].label,
            fr: types[0].label,
            it: types[0].label,
          },
        },
      }
    }
    if (!address.canton) {
      tmpAddress = {
        ...tmpAddress,
        canton: getCantonsOptions(intl)[0].id,
      }
    }
    if (!address.country) {
      tmpAddress = {
        ...tmpAddress,
        country: getCountryOptions(intl)[0].id,
      }
    }

    if (Object.keys(tmpAddress).length > 0) {
      setAddress({
        ...address,
        ...tmpAddress,
      })
    }
  }, [types, loadingAddressType, address])

  return (
    <>
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.type',
              defaultMessage: 'Type',
            })}
          >
            {loadingAddressType ? (
                  <Spinner $size={SIZE.small} />
            ) : (
              <Select
                clearable={false}
                options={types}
                backspaceRemoves={false}
                deleteRemoves={false}
                onChange={({ value }) =>
                  setAddress({
                    ...address,
                    types: {
                      id: (value as Types)[0].id,
                      isFirm: (value as Types)[0].isFirm,
                      name: {
                        de: (value as Types)[0].label,
                        fr: (value as Types)[0].label,
                        it: (value as Types)[0].label,
                      },
                    },
                  })
                }
                value={
                  address.types.id
                    ? [
                        {
                          id: address.types.id,
                          isFirm: address.types.isFirm,
                          label:
                            address.types.name &&
                            getTranslation(address.types.name, intl),
                        },
                      ]
                    : [
                        {
                          ...types[0],
                        },
                      ]
                }
              />
            )}
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.salutation',
              defaultMessage: 'Salutation',
            })}
            error={errors.salutation}
          >
            <Input
              value={address.salutation}
              onChange={(e) =>
                setAddress({
                  ...address,
                  salutation: (e.target as HTMLInputElement).value,
                })
              }
              positive={errors.salutation === ''}
            />
          </FormControl>
        </Cell>
        {address.types.isFirm && (
          <Cell span={[12, 6, 6]}>
            <FormControl
              label={intl.formatMessage({
                id: 'members.address.firmName',
                defaultMessage: 'Firm name',
              })}
              error={errors.firmName}
            >
              <Input
                value={address.firmName}
                onChange={(e) =>
                  setAddress({
                    ...address,
                    firmName: (e.target as HTMLInputElement).value,
                  })
                }
                positive={errors.firmName === ''}
              />
            </FormControl>
          </Cell>
        )}
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.firstName',
              defaultMessage: 'First name',
            })}
            error={errors.firstName}
          >
            <Input
              value={address.firstName}
              onChange={(e) =>
                setAddress({
                  ...address,
                  firstName: (e.target as HTMLInputElement).value,
                })
              }
              positive={errors.firstName === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.lastName',
              defaultMessage: 'Last name',
            })}
            error={errors.lastName}
          >
            <Input
              value={address.lastName}
              onChange={(e) =>
                setAddress({
                  ...address,
                  lastName: (e.target as HTMLInputElement).value,
                })
              }
              positive={errors.lastName === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.mailbox',
              defaultMessage: 'Mailbox',
            })}
          >
            <Input
              value={address.mailbox}
              onChange={(e) =>
                setAddress({
                  ...address,
                  mailbox: (e.target as HTMLInputElement).value,
                })
              }
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.streetNr',
              defaultMessage: 'Street Nr',
            })}
            error={errors.streetNr}
          >
            <Input
              value={address.streetNr}
              onChange={(e) =>
                setAddress({
                  ...address,
                  streetNr: (e.target as HTMLInputElement).value,
                })
              }
              positive={errors.streetNr === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.postCode',
              defaultMessage: 'Post code',
            })}
            error={errors.postCode}
          >
            <Input
              value={address.postCode}
              onChange={(e) =>
                setAddress({
                  ...address,
                  postCode: (e.target as HTMLInputElement).value,
                })
              }
              positive={errors.postCode === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.place',
              defaultMessage: 'Place',
            })}
            error={errors.place}
          >
            <Input
              value={address.place}
              onChange={(e) =>
                setAddress({
                  ...address,
                  place: (e.target as HTMLInputElement).value,
                })
              }
              positive={errors.place === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.canton',
              defaultMessage: 'Canton',
            })}
            error={errors.canton}
          >
            <Select
              clearable={false}
              options={getCantonsOptions(intl)}
              backspaceRemoves={false}
              deleteRemoves={false}
              onChange={({ value }) =>
                setAddress({
                  ...address,
                  canton: (value as Cantons)[0].id,
                })
              }
              value={
                address.canton
                  ? [
                      {
                        ...getCantonsOptions(intl).find(
                          (item) => item.id === address.canton
                        ),
                      },
                    ]
                  : [
                      {
                        ...getCantonsOptions(intl)[0],
                      },
                    ]
              }
              positive={errors.canton === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'members.address.country',
              defaultMessage: 'Country',
            })}
            error={errors.country}
          >
            <Select
              clearable={false}
              options={getCountryOptions(intl)}
              backspaceRemoves={false}
              deleteRemoves={false}
              onChange={({ value }) =>
                setAddress({
                  ...address,
                  country: (value as Country)[0].id,
                })
              }
              value={
                address.country
                  ? [
                      {
                        ...getCountryOptions(intl).find(
                          (item) => item.id === address.country
                        ),
                      },
                    ]
                  : [
                      {
                        ...getCountryOptions(intl)[0],
                      },
                    ]
              }
              positive={errors.country === ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </>
  )
}
