import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { priceFormater} from '@lemonbrain/utils'

import InvoiceEditBusiness from './InvoiceEdit.business'
import getReactApolloCients from '../../../localReactApollo'
import {
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
  GetInvoiceQuery,
  UpdateInvoiceMutationVariables,
} from '../../../gql/invoices/Invoices.generated'
import * as Apollo from '@apollo/client';
import * as Types from '../../../types';
import {
  useGetTransactionQuery
} from '../../../gql/transaction/Transaction.generated'

export default function InvoiceEditData({
  goToInvoice,
  invoiceId,
}: {
  goToInvoice: (id: string | null) => void
  invoiceId: string
}) {
  const intl = useIntl()
  const [invoice, setInvoice] = useState<
    | {
        id: string
        payAmount: string
        payDate: string
        total: string
        sendAt: string
      }
  >({
    id: '',
    payAmount: '',
    payDate: '',
    total: '',
    sendAt: '',
  })
  const [loading, setLoading] = useState(false)
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  let dataInvoice: GetInvoiceQuery | undefined,
  loadingInvoice: boolean = false,
  errorInvoice: Apollo.ApolloError | undefined,
    refetch: (variables?: Partial<Types.Exact<{
      id: string;
  }>> | undefined) => Promise<Apollo.ApolloQueryResult<GetInvoiceQuery>> | undefined

  const result = useGetInvoiceQuery({
    variables: {
      id: invoiceId,
    },
    //@ts-ignore
    client,
  })
  dataInvoice = result.data
  loadingInvoice = result.loading
  errorInvoice = result.error
  refetch = result.refetch

  const [
    updateInvoiceMutation,
    { loading: loadingUpdate, error: loadingError },
  ] = useUpdateInvoiceMutation({
    //@ts-ignore
    client,
  })

  async function updateInvoiceEntry(
    data: UpdateInvoiceMutationVariables
  ) {
    if (data.id && invoiceId) {
      await updateInvoiceMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    }
  }

  const { data: dataTransaction, loading: loadingTransaction, error: errorTransaction } = useGetTransactionQuery({
    variables: {
       invoiceId
    },
    //@ts-ignore
    client,
  });

  useEffect(() => {
    if (dataInvoice?.getInvoice) {
      setInvoice({
        id: dataInvoice.getInvoice.id,
        payAmount: priceFormater((dataInvoice.getInvoice.payAmount || 0) / 100),
        payDate: dataInvoice.getInvoice.payDate || '',
        total: priceFormater((dataInvoice.getInvoice.total || 0) / 100),
        sendAt: dataInvoice.getInvoice.sendAt || '',
      })
    }
  }, [dataInvoice])

  useEffect(() => {
    setLoading(loadingInvoice || loadingUpdate || loadingTransaction)
  }, [loadingInvoice, loadingUpdate, loadingTransaction])

  useEffect(() => {
    if (!loadingUpdate) {
      refetch && refetch()
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (errorInvoice || loadingError || errorTransaction) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.invoiceEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.invoiceEdit.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorInvoice?.message || loadingError?.message || errorTransaction?.message)
    }
  }, [errorInvoice, loadingError, errorTransaction])

  return (
    <InvoiceEditBusiness
      goToInvoice={goToInvoice}
      invoiceId={invoiceId}
      loading={loading}
      updateInvoiceEntry={updateInvoiceEntry}
      invoice={invoice}
      setInvoice={setInvoice}
      dataTransaction={dataTransaction}
    />
  )
}
