import { PropsWithChildren } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
  ROLE
} from 'baseui/modal'
import { ParagraphMedium } from 'baseui/typography'
import { FormattedMessage } from 'react-intl'

type SimpleModalProps<dataT> = {
  isOpen: boolean,
  content: string
  onConfirm: (input: dataT) => void,
  onClose: () => void,
  data: dataT,
  headerLabel: string,
  confirmLabel?: string,
  cancelLabel?: string,
}

const SimpleModal = <dataT extends unknown> ({
  isOpen,
  content,
  onConfirm,
  onClose,
  data,
  headerLabel,
  confirmLabel,
  cancelLabel
}: PropsWithChildren<SimpleModalProps<dataT>>) => {
 
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeable
      role={ROLE.alertdialog}
      overrides={{
        Root: {
          style: () => ({
            zIndex: 999
          })
        },
      }}
    >
      <FocusOnce>
        <ModalHeader>{headerLabel}</ModalHeader>
      </FocusOnce>
      <ModalBody>
        <ParagraphMedium paddingBottom="scale400">{content}</ParagraphMedium>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose}>
          {cancelLabel ? cancelLabel: (
            <FormattedMessage
              id='cancel'
              defaultMessage='Cancel'
            />
          )}
        </ModalButton>
        <ModalButton onClick={() => {
          onConfirm(data)
          onClose()
        }}>
          {confirmLabel ? confirmLabel : (
            <FormattedMessage
              id='confirm'
              defaultMessage='Confirm'
            />
          )}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default SimpleModal
