// @ts-nocheck
import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type BillingInformationFragmentFragment = (
  { __typename?: 'BillingInformation' }
  & Pick<Types.BillingInformation, 'id' | 'firstName' | 'lastName' | 'firmName' | 'mailbox' | 'streetNr' | 'postCode' | 'place' | 'canton' | 'country' | 'account' | 'iban'>
);

export type GetSvssBillingInformationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSvssBillingInformationQuery = (
  { __typename?: 'Query' }
  & { getBillingInformation: (
    { __typename?: 'BillingInformation' }
    & BillingInformationFragmentFragment
  ) }
);

export const BillingInformationFragmentFragmentDoc = gql`
    fragment BillingInformationFragment on BillingInformation {
  id
  firstName
  lastName
  firmName
  mailbox
  streetNr
  postCode
  place
  canton
  country
  account
  iban
}
    `;
export const GetSvssBillingInformationDocument = gql`
    query getSvssBillingInformation {
  getBillingInformation(id: "1") {
    ...BillingInformationFragment
  }
}
    ${BillingInformationFragmentFragmentDoc}`;

/**
 * __useGetSvssBillingInformationQuery__
 *
 * To run a query within a React component, call `useGetSvssBillingInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSvssBillingInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSvssBillingInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSvssBillingInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetSvssBillingInformationQuery, GetSvssBillingInformationQueryVariables>) {
        return Apollo.useQuery<GetSvssBillingInformationQuery, GetSvssBillingInformationQueryVariables>(GetSvssBillingInformationDocument, baseOptions);
      }
export function useGetSvssBillingInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSvssBillingInformationQuery, GetSvssBillingInformationQueryVariables>) {
          return Apollo.useLazyQuery<GetSvssBillingInformationQuery, GetSvssBillingInformationQueryVariables>(GetSvssBillingInformationDocument, baseOptions);
        }
export type GetSvssBillingInformationQueryHookResult = ReturnType<typeof useGetSvssBillingInformationQuery>;
export type GetSvssBillingInformationLazyQueryHookResult = ReturnType<typeof useGetSvssBillingInformationLazyQuery>;
export type GetSvssBillingInformationQueryResult = Apollo.QueryResult<GetSvssBillingInformationQuery, GetSvssBillingInformationQueryVariables>;