import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'

import getReactApolloCients from '../../../../localReactApollo'
import { useGetPersonsIfManagesEMailLazyQuery } from '../../../../gql/members/Person.generated'
import {
  useGetMailingLazyQuery,
  useAddMailingMutation,
  useSendMailingMutation,
} from '../../../../gql/members/Mailing.generated'
import MailBusiness from './Mail.business'

import { MailingState } from '../../../../gql/members/types'

function MailIfManagesData({
  selectedPerson,
}: {
  selectedPerson: string[]
}) {
  let refreshId: NodeJS.Timeout | null = null;
  const [mailingId, setMailingId] = useLocalStorage<string | null>('mailingId')
  const [mailingState, setMailingState] = useState<{
    sentCount?: number
    failedCount?: number
    recipientCount?: number
    state?: MailingState
    from?: string
    fromName?: string
    to?: string[]
    subject?: string
    content?: string
  }>({})
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const [
    getPersonsEMail,
    { data: dataGetPerson, loading: loadingGetPerson, error: errorGetPerson },
  ] = useGetPersonsIfManagesEMailLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [
    getMailingLazy,
    {
      data: dataGetMailing,
      loading: loadingGetMailing,
      error: errorGetMailing,
    },
  ] = useGetMailingLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })
  const [
    addMailingMutation,
    {
      data: dataAddMailing,
      loading: loadingAddMailing,
      error: errorAddMailing,
    },
  ] = useAddMailingMutation({
    //@ts-ignore
    client,
  })
  const [
    sendMailingMutation,
    {
      data: dataSendMailing,
      loading: loadingSendMailing,
      error: errorSendMailing,
    },
  ] = useSendMailingMutation({
    //@ts-ignore
    client,
  })

  function addMailing({
    from,
    fromName,
    to,
    subject,
    content,
  }: {
    from: string
    fromName: string
    to: string[]
    subject: string
    content: string
  }) {
    setMailingState({})
    addMailingMutation({
      variables: {
        from,
        fromName,
        to,
        subject,
        content,
      },
    })
  }

  function sendMailing(id: string) {
    sendMailingMutation({
      variables: {
        id,
      },
    })
  }

  useEffect(() => {
    if (dataAddMailing?.addMailing.id) {
      setMailingId(dataAddMailing.addMailing.id)
    }
  }, [dataAddMailing])

  useEffect(() => {
    if (mailingId && dataSendMailing?.sendMailing.id === mailingId) {
      getMailingLazy({
        variables: {
          id: mailingId,
        },
      })
    }
  }, [dataSendMailing])

  useEffect(() => {
    if (mailingId) {
      getMailingLazy({
        variables: {
          id: mailingId,
        },
      })
    }
  }, [mailingId])

  useEffect(() => {
    if (dataGetMailing?.getMailing) {
      if (mailingId && dataGetMailing?.getMailing.state === MailingState.Pending) {
        refreshId && clearTimeout(refreshId)
        sendMailingMutation({
          variables: {
            id: mailingId,
          },
        })
      } else if (
        dataGetMailing?.getMailing.state === MailingState.Completed
      ) {
        refreshId && clearTimeout(refreshId)
        setMailingId(null)
        setLoading(false)
      }

      if (dataGetMailing.getMailing) {
        setMailingState({
          sentCount: dataGetMailing.getMailing.sent.length,
          failedCount: dataGetMailing.getMailing.failed.length,
          recipientCount: dataGetMailing.getMailing.to.length,
          state: dataGetMailing.getMailing.state as MailingState,
          from: dataGetMailing.getMailing.from,
          fromName: dataGetMailing.getMailing.fromName,
          to: dataGetMailing.getMailing.to,
          subject: dataGetMailing.getMailing.subject,
          content: dataGetMailing.getMailing.content,
        })
      }

      if (
        dataGetMailing.getMailing.id &&
        dataGetMailing?.getMailing.state !== MailingState.Completed &&
        dataGetMailing?.getMailing.state !== MailingState.Interrupted
      ) {
        setLoading(true)
        refreshId = setTimeout(() => {
          getMailingLazy({
            variables: {
              id: dataGetMailing.getMailing.id,
            },
          })
        }, 10000)
      } else if (
        dataGetMailing.getMailing.id &&
        (dataGetMailing?.getMailing.state === MailingState.Completed ||
        dataGetMailing?.getMailing.state === MailingState.Interrupted
      )) {
        setLoading(false)
      }
    }
  }, [dataGetMailing])

  useEffect(() => {
    if (!loading) {
      setLoading(loadingGetMailing || loadingAddMailing || loadingSendMailing)
    }
  }, [loadingGetMailing, loadingAddMailing, loadingSendMailing])

  useEffect(() => {
    if (
      errorGetPerson ||
      errorGetMailing ||
      errorAddMailing ||
      errorSendMailing
    ) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.mail.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.mail.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorGetPerson?.message ||
          errorGetMailing?.message ||
          errorAddMailing?.message ||
          errorSendMailing?.message
      )
    }
  }, [errorGetPerson, errorGetMailing, errorAddMailing, errorSendMailing])

  useEffect(() => {
    if (!isOpen && !mailingId) {
      setMailingState({})
    }
  }, [isOpen])

  return loadingGetPerson ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <MailBusiness
      mailingId={mailingId}
      addMailing={addMailing}
      sendMailing={sendMailing}
      mailingState={mailingState}
      loading={loading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selectedPerson={selectedPerson}
      getPersons={dataGetPerson?.getPersonsIfManages}
      getPersonsEMail={getPersonsEMail}
    />
  )
}

export default MailIfManagesData