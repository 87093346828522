import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import StateFunctionBusiness from './StateFunction.business'
import getReactApolloCients from '../../../../localReactApollo'
import {useGetStateFunctionsNoPersonQuery} from '../../../../gql/members/StateFunction.generated'

export default function StateFunctionData({
    stateFunctions,
    setStateFunctions,
  }: {
    stateFunctions?: { id: string }[]
    setStateFunctions: React.Dispatch<
      React.SetStateAction<{ id: string }[]>
    >
  }) {
    const intl = useIntl()
    const setAlert = useAlert()
    const [stateFunctionsInput, setStateFunctionsInput] = useState<
      {
        id: string
        label: string
      }[]
    >([])
    const client = getReactApolloCients().getClient()
      const { data: dataStateFunctions, loading: loadingStateFunctions, error: errorStateFunctions } = useGetStateFunctionsNoPersonQuery({
        variables: {
          cursor: {
            pageSize: 100,
          },
        },
        //@ts-ignore
        client,
     });

     useEffect(() => {
       if (errorStateFunctions) {
         setAlert &&
           setAlert({
             title: intl.formatMessage({
               id: 'members.stateFunction.error',
               defaultMessage: 'Error',
             }),
             label: intl.formatMessage({
               id: 'members.stateFunction.networkError',
               defaultMessage: 'We had network problem. Please try again',
             }),
             style: 'error',
           })
         console.error(errorStateFunctions?.message)
       }
     }, [errorStateFunctions])
   
     useEffect(() => {
       if (dataStateFunctions?.getStateFunctionsNoPerson.items) {
        setStateFunctionsInput(
          dataStateFunctions?.getStateFunctionsNoPerson.items.map((item) => ({
             id: item.id,
             label: getTranslation(item.name, intl),
           })).sort(function(a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            
            return 0;
          })
         )
       }
     }, [dataStateFunctions])
    
      return <StateFunctionBusiness stateFunctions={stateFunctions} setStateFunctions={setStateFunctions} loadingStateFunctions={loadingStateFunctions} stateFunctionsInput={stateFunctionsInput} />
  }