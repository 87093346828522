import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import AssociationsOverviewBusiness from './AssociationsOverview.business'

export default function AssociationsOverviewData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const { associationId } = useParams<{ associationId: string | undefined }>()

  const goToAssociation = function (id: string | null) {
    if (id && id !== '') {
      pushPath(`/${language}/associations/overview/${id}`)
    } else {
      pushPath(`/${language}/associations/overview`)
    }
  }

  return <AssociationsOverviewBusiness subNav={subNav} goToAssociation={goToAssociation} associationId={associationId} />
}
