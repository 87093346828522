import { useScript } from './useScripts'

export const useCaptcha = () => {
    const { isLoaded, isError } = useScript(`${process.env.REACT_APP_RECAPTCHA_URL || ''}?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}`)

    const getCaptchaToken = (action: string) => new Promise<string>((resolve, rejects) => {
        if (isError) {
            rejects('ReCaptcha hat beim Laden einen Fehler erzeugt')
        }
        //@ts-ignore
        if (isLoaded && window.grecaptcha) {
            //@ts-ignore
            window.grecaptcha.ready(function () {
                //@ts-ignore
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '', { action }).then(function (token: string) {
                    resolve(token)
                }).catch((e: any) => rejects(e))
            })
        } else {
            rejects('ReCaptcha ist nicht geladen')
        }

    })

    return {
        isLoaded,
        isError,
        getCaptchaToken
    }
}