import { InnerRoute } from '@lemonbrain/hooks'
import { useState } from 'react'

import MembershipMailingBusiness from './MembershipMailing.business'

export default function MembershipMailingData({ subNav }: { subNav: InnerRoute[] }) {
  const [refresh, setRefresh] = useState(false)

  const newEntry = function() {
    setRefresh(!refresh)
  }

  return (
    <MembershipMailingBusiness
      subNav={subNav}
      newEntry={newEntry}
      refresh={refresh}
    />
  )
}
