import { useIntl } from 'react-intl'
import { Button } from 'baseui/button'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'
import { Badge } from 'baseui/badge'
import AssociationIcon from '../../../../icons/AssociationIcon'

export default function CreateOneAssociationInvoices({
    loading,
    onClick,
    progress,
  }: {
    loading: boolean
    onClick: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => any
    progress: number | null
  }) {
    const intl = useIntl()

    return (
      <StatefulTooltip
        placement={PLACEMENT.right}
        content={intl.formatMessage({
          id: 'invoices.createAssociationInvoices.createAssociation',
          defaultMessage: 'Create association',
        })}
        overrides={{
          Body: {
            style: () => ({
              zIndex: 99999,
            }),
          },
        }}
      >
      <Badge
        content={`${progress}%`}
        placement={PLACEMENT.topRight}
        horizontalOffset='0px'
        verticalOffset='0px'
        hidden={!progress}
        >
        <Button
          isLoading={loading}
          onClick={onClick}
          title={intl.formatMessage({
            id: 'invoices.createAssociationInvoices.createAssociation',
            defaultMessage: 'Create association',
          })}
        >
          <AssociationIcon size='24' />
        </Button>
      </Badge>
      </StatefulTooltip>
    )
  }
