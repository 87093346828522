import { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'
import { priceFormater } from '@lemonbrain/utils'

import {
  useGetInvoicesIfFromAssociationQuery,
  GetNextInvoicesIfFromAssociationQuery,
  GetNextInvoicesIfFromAssociationDocument,
} from '../../../gql/invoices/Invoices.generated'
import InvoicesTableBusiness from './InvoicesTable.business'
import { RowDataT } from './InvoicesTable'
import getReactApolloCients from '../../../localReactApollo'

export default forwardRef(({
  searchQuery,
  selectedInvoices,
  setSelectedInvoices,
  goToInvoice,
  personId,
  payedFilter,
}: {
  searchQuery: string
  selectedInvoices: string[]
  setSelectedInvoices: React.Dispatch<React.SetStateAction<string[]>>
  goToInvoice: (id: string | null) => void
  personId?: string
  payedFilter: string[] | null
}, ref) => {
  const intl = useIntl()
  const setAlert = useAlert()
  const [data, setData] = useState<RowDataT[]>([])
  const [nextCursor, setNextCursor] = useState<string | null | undefined>()
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useLocalStorage<number>('pageSize', 10)
  const [pageCount, setPageCount] = useState(1)
  const client = getReactApolloCients().getClient()
  const {
    data: dataGetInvoice,
    loading: loadingGetInvoice,
    error: errorGetInvoice,
    fetchMore,
    refetch,
  } = useGetInvoicesIfFromAssociationQuery({
    variables: {
      cursor: {
        pageSize,
        filtered: getFilter(),
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const onFetchMoreData = function () {
    if (nextCursor) {
      fetchMore({
        query: GetNextInvoicesIfFromAssociationDocument,
        variables: {
          cursor: {
            pageSize,
            filtered: getFilter(),
            nextCursor: nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextInvoicesIfFromAssociationQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextInvoices: {
                nextCursor: fetchMoreResult.getNextInvoicesIfFromAssociation.nextCursor,
                items: [...fetchMoreResult.getNextInvoicesIfFromAssociation.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextInvoicesIfFromAssociation.items.length > 0 &&
            previousResult.getInvoicesIfFromAssociation.items.filter((item) => {
              return item.id === fetchMoreResult.getNextInvoicesIfFromAssociation.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getInvoices: {
                ...previousResult.getInvoicesIfFromAssociation,
                nextCursor: fetchMoreResult.getNextInvoicesIfFromAssociation.nextCursor,
                items: [
                  ...previousResult.getInvoicesIfFromAssociation.items,
                  ...fetchMoreResult.getNextInvoicesIfFromAssociation.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }
  const onRefetchData = function () {
    setPageCount(1)
    refetch({
      cursor: {
        pageSize,
        filtered: getFilter(),
      },
    })
  }

  function getFilter() {
    const filter: { id: string; value: string }[] = []

    filter.push({
      id: 'query',
      value: searchQuery,
    })
    if(personId) {
      filter.push({
        id: 'person_id',
        value: personId,
      })
    }

    if (payedFilter) {
      filter.push({
        id: 'payed',
        value: payedFilter.join(','),
      })
    }
    return filter
  }

  useEffect(() => {
    setLoading(loadingGetInvoice)
  }, [loadingGetInvoice])

  useEffect(() => {
    if (errorGetInvoice) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.invoicesTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.invoicesTable.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorGetInvoice?.message || '')
    }
  }, [errorGetInvoice])

  useEffect(() => {
    if (dataGetInvoice && dataGetInvoice?.getInvoicesIfFromAssociation.items.length > 0) {
      setData(
        dataGetInvoice.getInvoicesIfFromAssociation.items.map((item) => ({
          id: item.id,
          selected: !!selectedInvoices.find(item1 => item1 === item.id),
          invoiceNumber: item.invoiceNumber,
          from: `${item.paymentFirmName} ${item.paymentFirstName} ${item.paymentLastName}`,
          to: `${item.firmName} ${item.firstName} ${item.lastName}`,
          date: new Date(item.date).toLocaleDateString('de-CH'),
          total: priceFormater(item.total / 100),
          sendAt:
            (item.sendAt &&
              new Date(item.sendAt).toLocaleDateString('de-CH')) ||
            '',
          payDate:
            (item.payDate &&
              new Date(item.payDate).toLocaleDateString('de-CH')) ||
            '',
          payAmount: item.payAmount && priceFormater(item.payAmount / 100) || '0',
          canceledAt:
            (item.canceledAt &&
              new Date(item.canceledAt).toLocaleDateString('de-CH')) ||
            '',
          createdFrom: item.createdFrom.toString(),
        }))
      )
      if (
        dataGetInvoice.getInvoicesIfFromAssociation.nextCursor &&
        dataGetInvoice.getInvoicesIfFromAssociation.nextCursor !== nextCursor
      ) {
        if (nextCursor !== dataGetInvoice.getInvoicesIfFromAssociation.nextCursor) {
          setPageCount((prev) => ++prev)
        }
        setNextCursor(dataGetInvoice.getInvoicesIfFromAssociation.nextCursor)
      }
    } else {
      setData([])
    }
  }, [dataGetInvoice, selectedInvoices])

  useImperativeHandle(ref, () => ({
    refetchInvoices: onRefetchData
  }));

  return (
    <InvoicesTableBusiness
      data={data}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      setSelectedInvoices={setSelectedInvoices}
      goToInvoice={goToInvoice}
    />
  )
})
