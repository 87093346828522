// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type WorkLevelFragmentFragment = (
  { __typename?: 'WorkLevel' }
  & Pick<Types.WorkLevel, 'id'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetWorkLevelsNoPersonQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetWorkLevelsNoPersonQuery = (
  { __typename?: 'Query' }
  & { getWorkLevelsNoPerson: (
    { __typename?: 'WorkLevelPagination' }
    & Pick<Types.WorkLevelPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'WorkLevel' }
      & WorkLevelFragmentFragment
    )> }
  ) }
);

export const WorkLevelFragmentFragmentDoc = gql`
    fragment workLevelFragment on WorkLevel {
  id
  name {
    de
    fr
    it
  }
}
    `;
export const GetWorkLevelsNoPersonDocument = gql`
    query getWorkLevelsNoPerson($cursor: StartCursorInput!) {
  getWorkLevelsNoPerson(cursor: $cursor) {
    items {
      ...workLevelFragment
    }
    nextCursor
  }
}
    ${WorkLevelFragmentFragmentDoc}`;

/**
 * __useGetWorkLevelsNoPersonQuery__
 *
 * To run a query within a React component, call `useGetWorkLevelsNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkLevelsNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkLevelsNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetWorkLevelsNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetWorkLevelsNoPersonQuery, GetWorkLevelsNoPersonQueryVariables>) {
        return Apollo.useQuery<GetWorkLevelsNoPersonQuery, GetWorkLevelsNoPersonQueryVariables>(GetWorkLevelsNoPersonDocument, baseOptions);
      }
export function useGetWorkLevelsNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkLevelsNoPersonQuery, GetWorkLevelsNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkLevelsNoPersonQuery, GetWorkLevelsNoPersonQueryVariables>(GetWorkLevelsNoPersonDocument, baseOptions);
        }
export type GetWorkLevelsNoPersonQueryHookResult = ReturnType<typeof useGetWorkLevelsNoPersonQuery>;
export type GetWorkLevelsNoPersonLazyQueryHookResult = ReturnType<typeof useGetWorkLevelsNoPersonLazyQuery>;
export type GetWorkLevelsNoPersonQueryResult = Apollo.QueryResult<GetWorkLevelsNoPersonQuery, GetWorkLevelsNoPersonQueryVariables>;