export const courseOptions = [
    'js_accepted',
    'pickup_course',
    'certificated_course',
  ]
  
  export function getCourseOptions(intl: any) {
    const languageOptions: {
      id: string
      label: string
    }[] = []
    courseOptions.forEach((courseOption) => {
        languageOptions.push({
        id: courseOption,
        label: intl.formatMessage({
          id: 'courseOptions.' + courseOption,
          defaultMessgae: courseOption,
        }),
      })
    })
    return languageOptions
  }
  