// @ts-nocheck
import * as Types from './auth/src/gql/auth/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type Auth = {
  __typename?: 'Auth';
  /** When the login was not successfull addintional information is provided here */
  authError?: Maybe<AuthError>;
  /** Includes the current user with uID, uName, uEmail, uDefaultLanguage, uGroupsPath, uAvatar, uIsValidated in the payload */
  authToken: Scalars['String']['output'];
  /** When set, two factor authentication is activated */
  nonce: Scalars['String']['output'];
};

export type AuthError = {
  __typename?: 'AuthError';
  /** Type of the error */
  type: Scalars['String']['output'];
  /** The user name of the user on which the login failed */
  uName?: Maybe<Scalars['String']['output']>;
};

export type CursorInput = {
  filtered?: InputMaybe<Array<FilterInput>>;
  offset: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sorted?: InputMaybe<Array<SortInput>>;
};

export type FilterInput = {
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Groups = {
  name: Scalars['String']['input'];
  task: Task;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Returns an error array or an empty array */
  changePassword: Array<Scalars['String']['output']>;
  /** if you use https://www.concrete5.org/marketplace/addons/two-factor-login-security you can check the nonce with this function */
  checkNonce: Auth;
  /** Returns result (id, userName and email), a validationErrors array */
  createUser: UserResult;
  /** Returns an error array or an empty array */
  forgotPassword: Array<Scalars['String']['output']>;
  /** Login a user. Request for an authToken and refreshToken in response */
  login: Auth;
  /** Logout an user. */
  logout: Scalars['Boolean']['output'];
  /** sends a validation email to the emailaddress of the user */
  sendValidationEmail: Scalars['Boolean']['output'];
  sendValidationEmailById: Scalars['Boolean']['output'];
  /** Returns result (id, userName and email), a validationErrors array */
  updateUser: UserResult;
  updateUserById: UserResult;
  /** validates the users emailaddress trough the token in the validation email */
  validateEmail: Scalars['Boolean']['output'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String']['input'];
  passwordConfirm?: InputMaybe<Scalars['String']['input']>;
  reCaptchaToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCheckNonceArgs = {
  nonce: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  u2SAPass: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  groups?: InputMaybe<Array<InputMaybe<Groups>>>;
  password: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  removeAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  userLocale?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationForgotPasswordArgs = {
  changePasswordUrl: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationSendValidationEmailArgs = {
  reCaptchaToken: Scalars['String']['input'];
  template?: InputMaybe<Scalars['String']['input']>;
  uName: Scalars['String']['input'];
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendValidationEmailByIdArgs = {
  id: Scalars['ID']['input'];
  reCaptchaToken: Scalars['String']['input'];
  template?: InputMaybe<Scalars['String']['input']>;
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Groups>>>;
  password?: InputMaybe<Scalars['String']['input']>;
  removeAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  userLocale?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserByIdArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Groups>>>;
  id: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  userLocale?: InputMaybe<Scalars['String']['input']>;
  validationUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationValidateEmailArgs = {
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** gql file without query is not working :( */
  dummy?: Maybe<Scalars['String']['output']>;
  getDisplayName: Scalars['String']['output'];
  getDisplayNameById: Scalars['String']['output'];
  getUser: UserExtended;
  getUserById: UserExtended;
  getUsers: Array<UserExtended>;
};


export type QueryGetDisplayNameArgs = {
  username: Scalars['String']['input'];
};


export type QueryGetDisplayNameByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUserArgs = {
  username: Scalars['String']['input'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUsersArgs = {
  cursor: CursorInput;
};

export type SortInput = {
  asc: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export enum Task {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type User = UserBasic & {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  uAvatar: Scalars['String']['output'];
  uDefaultLanguage?: Maybe<Scalars['String']['output']>;
  uEmail: Scalars['String']['output'];
  uID: Scalars['ID']['output'];
  uName: Scalars['String']['output'];
};

export type UserBasic = {
  id: Scalars['ID']['output'];
  uAvatar: Scalars['String']['output'];
  uDefaultLanguage?: Maybe<Scalars['String']['output']>;
  uEmail: Scalars['String']['output'];
  uID: Scalars['ID']['output'];
  uName: Scalars['String']['output'];
};

export type UserExtended = UserBasic & {
  __typename?: 'UserExtended';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  uAvatar: Scalars['String']['output'];
  uDefaultLanguage?: Maybe<Scalars['String']['output']>;
  uEmail: Scalars['String']['output'];
  uGroupsPath: Array<Maybe<Scalars['String']['output']>>;
  uID: Scalars['ID']['output'];
  uName: Scalars['String']['output'];
};

export type UserResult = {
  __typename?: 'UserResult';
  result?: Maybe<User>;
  validationErrors: Array<Maybe<Scalars['String']['output']>>;
};

export type LoginMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  reCaptchaToken: Types.Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Auth', nonce: string, authToken: string, authError?: { __typename?: 'AuthError', type: string, uName?: string | null } | null } };

export type CheckNonceMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
  nonce: Types.Scalars['String']['input'];
  u2SAPass: Types.Scalars['String']['input'];
  reCaptchaToken: Types.Scalars['String']['input'];
}>;


export type CheckNonceMutation = { __typename?: 'Mutation', checkNonce: { __typename?: 'Auth', authToken: string, authError?: { __typename?: 'AuthError', type: string, uName?: string | null } | null } };

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type ForgotPasswordMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
  changePasswordUrl: Types.Scalars['String']['input'];
  reCaptchaToken: Types.Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: Array<string> };

export type ChangePasswordMutationVariables = Types.Exact<{
  password: Types.Scalars['String']['input'];
  token: Types.Scalars['String']['input'];
  reCaptchaToken: Types.Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: Array<string> };

export type SendValidationEmailMutationVariables = Types.Exact<{
  uName: Types.Scalars['String']['input'];
  reCaptchaToken: Types.Scalars['String']['input'];
  validationUrl: Types.Scalars['String']['input'];
}>;


export type SendValidationEmailMutation = { __typename?: 'Mutation', sendValidationEmail: boolean };

export type ValidateEmailMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
  reCaptchaToken: Types.Scalars['String']['input'];
}>;


export type ValidateEmailMutation = { __typename?: 'Mutation', validateEmail: boolean };

export type CreateUserMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  reCaptchaToken: Types.Scalars['String']['input'];
  validationUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
  groups?: Types.InputMaybe<Array<Types.InputMaybe<Types.Groups>> | Types.InputMaybe<Types.Groups>>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserResult', validationErrors: Array<string | null>, result?: { __typename?: 'User', uID: string, uName: string, uEmail: string } | null } };

export type UpdateUserByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  userLocale?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateUserByIdMutation = { __typename?: 'Mutation', updateUserById: { __typename?: 'UserResult', validationErrors: Array<string | null>, result?: { __typename?: 'User', uID: string, uName: string, uAvatar: string, uDefaultLanguage?: string | null } | null } };

export type GetDisplayNameQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type GetDisplayNameQuery = { __typename?: 'Query', getDisplayName: string };

export type GetDisplayNameByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetDisplayNameByIdQuery = { __typename?: 'Query', getDisplayNameById: string };

export type GetUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetUserByIdQuery = { __typename?: 'Query', getUserById: { __typename?: 'UserExtended', uID: string, uName: string, uAvatar: string, uDefaultLanguage?: string | null } };


export const LoginDocument = gql`
    mutation login($username: String!, $password: String!, $reCaptchaToken: String!) {
  login(username: $username, password: $password, reCaptchaToken: $reCaptchaToken) {
    nonce
    authToken
    authError {
      type
      uName
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CheckNonceDocument = gql`
    mutation checkNonce($username: String!, $nonce: String!, $u2SAPass: String!, $reCaptchaToken: String!) {
  checkNonce(
    username: $username
    nonce: $nonce
    u2SAPass: $u2SAPass
    reCaptchaToken: $reCaptchaToken
  ) {
    authToken
    authError {
      type
      uName
    }
  }
}
    `;
export type CheckNonceMutationFn = Apollo.MutationFunction<CheckNonceMutation, CheckNonceMutationVariables>;

/**
 * __useCheckNonceMutation__
 *
 * To run a mutation, you first call `useCheckNonceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckNonceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkNonceMutation, { data, loading, error }] = useCheckNonceMutation({
 *   variables: {
 *      username: // value for 'username'
 *      nonce: // value for 'nonce'
 *      u2SAPass: // value for 'u2SAPass'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *   },
 * });
 */
export function useCheckNonceMutation(baseOptions?: Apollo.MutationHookOptions<CheckNonceMutation, CheckNonceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckNonceMutation, CheckNonceMutationVariables>(CheckNonceDocument, options);
      }
export type CheckNonceMutationHookResult = ReturnType<typeof useCheckNonceMutation>;
export type CheckNonceMutationResult = Apollo.MutationResult<CheckNonceMutation>;
export type CheckNonceMutationOptions = Apollo.BaseMutationOptions<CheckNonceMutation, CheckNonceMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($username: String!, $changePasswordUrl: String!, $reCaptchaToken: String!) {
  forgotPassword(
    username: $username
    changePasswordUrl: $changePasswordUrl
    reCaptchaToken: $reCaptchaToken
  )
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *      changePasswordUrl: // value for 'changePasswordUrl'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($password: String!, $token: String!, $reCaptchaToken: String!) {
  changePassword(
    password: $password
    token: $token
    reCaptchaToken: $reCaptchaToken
  )
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const SendValidationEmailDocument = gql`
    mutation sendValidationEmail($uName: String!, $reCaptchaToken: String!, $validationUrl: String!) {
  sendValidationEmail(
    uName: $uName
    reCaptchaToken: $reCaptchaToken
    validationUrl: $validationUrl
  )
}
    `;
export type SendValidationEmailMutationFn = Apollo.MutationFunction<SendValidationEmailMutation, SendValidationEmailMutationVariables>;

/**
 * __useSendValidationEmailMutation__
 *
 * To run a mutation, you first call `useSendValidationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendValidationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendValidationEmailMutation, { data, loading, error }] = useSendValidationEmailMutation({
 *   variables: {
 *      uName: // value for 'uName'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *      validationUrl: // value for 'validationUrl'
 *   },
 * });
 */
export function useSendValidationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendValidationEmailMutation, SendValidationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendValidationEmailMutation, SendValidationEmailMutationVariables>(SendValidationEmailDocument, options);
      }
export type SendValidationEmailMutationHookResult = ReturnType<typeof useSendValidationEmailMutation>;
export type SendValidationEmailMutationResult = Apollo.MutationResult<SendValidationEmailMutation>;
export type SendValidationEmailMutationOptions = Apollo.BaseMutationOptions<SendValidationEmailMutation, SendValidationEmailMutationVariables>;
export const ValidateEmailDocument = gql`
    mutation validateEmail($token: String!, $reCaptchaToken: String!) {
  validateEmail(token: $token, reCaptchaToken: $reCaptchaToken)
}
    `;
export type ValidateEmailMutationFn = Apollo.MutationFunction<ValidateEmailMutation, ValidateEmailMutationVariables>;

/**
 * __useValidateEmailMutation__
 *
 * To run a mutation, you first call `useValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailMutation, { data, loading, error }] = useValidateEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *   },
 * });
 */
export function useValidateEmailMutation(baseOptions?: Apollo.MutationHookOptions<ValidateEmailMutation, ValidateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateEmailMutation, ValidateEmailMutationVariables>(ValidateEmailDocument, options);
      }
export type ValidateEmailMutationHookResult = ReturnType<typeof useValidateEmailMutation>;
export type ValidateEmailMutationResult = Apollo.MutationResult<ValidateEmailMutation>;
export type ValidateEmailMutationOptions = Apollo.BaseMutationOptions<ValidateEmailMutation, ValidateEmailMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($email: String!, $password: String!, $reCaptchaToken: String!, $validationUrl: String, $groups: [Groups]) {
  createUser(
    email: $email
    password: $password
    reCaptchaToken: $reCaptchaToken
    validationUrl: $validationUrl
    groups: $groups
  ) {
    result {
      uID
      uName
      uEmail
    }
    validationErrors
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *      validationUrl: // value for 'validationUrl'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserByIdDocument = gql`
    mutation updateUserById($id: ID!, $userLocale: String) {
  updateUserById(id: $id, userLocale: $userLocale) {
    result {
      uID
      uName
      uAvatar
      uDefaultLanguage
    }
    validationErrors
  }
}
    `;
export type UpdateUserByIdMutationFn = Apollo.MutationFunction<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;

/**
 * __useUpdateUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByIdMutation, { data, loading, error }] = useUpdateUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userLocale: // value for 'userLocale'
 *   },
 * });
 */
export function useUpdateUserByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>(UpdateUserByIdDocument, options);
      }
export type UpdateUserByIdMutationHookResult = ReturnType<typeof useUpdateUserByIdMutation>;
export type UpdateUserByIdMutationResult = Apollo.MutationResult<UpdateUserByIdMutation>;
export type UpdateUserByIdMutationOptions = Apollo.BaseMutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;
export const GetDisplayNameDocument = gql`
    query getDisplayName($username: String!) {
  getDisplayName(username: $username)
}
    `;

/**
 * __useGetDisplayNameQuery__
 *
 * To run a query within a React component, call `useGetDisplayNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayNameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetDisplayNameQuery(baseOptions: Apollo.QueryHookOptions<GetDisplayNameQuery, GetDisplayNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisplayNameQuery, GetDisplayNameQueryVariables>(GetDisplayNameDocument, options);
      }
export function useGetDisplayNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayNameQuery, GetDisplayNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisplayNameQuery, GetDisplayNameQueryVariables>(GetDisplayNameDocument, options);
        }
export type GetDisplayNameQueryHookResult = ReturnType<typeof useGetDisplayNameQuery>;
export type GetDisplayNameLazyQueryHookResult = ReturnType<typeof useGetDisplayNameLazyQuery>;
export type GetDisplayNameQueryResult = Apollo.QueryResult<GetDisplayNameQuery, GetDisplayNameQueryVariables>;
export const GetDisplayNameByIdDocument = gql`
    query getDisplayNameById($id: ID!) {
  getDisplayNameById(id: $id)
}
    `;

/**
 * __useGetDisplayNameByIdQuery__
 *
 * To run a query within a React component, call `useGetDisplayNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayNameByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDisplayNameByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDisplayNameByIdQuery, GetDisplayNameByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisplayNameByIdQuery, GetDisplayNameByIdQueryVariables>(GetDisplayNameByIdDocument, options);
      }
export function useGetDisplayNameByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayNameByIdQuery, GetDisplayNameByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisplayNameByIdQuery, GetDisplayNameByIdQueryVariables>(GetDisplayNameByIdDocument, options);
        }
export type GetDisplayNameByIdQueryHookResult = ReturnType<typeof useGetDisplayNameByIdQuery>;
export type GetDisplayNameByIdLazyQueryHookResult = ReturnType<typeof useGetDisplayNameByIdLazyQuery>;
export type GetDisplayNameByIdQueryResult = Apollo.QueryResult<GetDisplayNameByIdQuery, GetDisplayNameByIdQueryVariables>;
export const GetUserByIdDocument = gql`
    query getUserById($id: ID!) {
  getUserById(id: $id) {
    uID
    uName
    uAvatar
    uDefaultLanguage
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;