import { useState } from 'react'

let defaultComponents: {[name:string]: (...inputs: any)=>JSX.Element} = {}
export const addComponentsBeforeRender = function (newComponents: {[name:string]: (...inputs: any)=>JSX.Element}) {
    defaultComponents = { ...defaultComponents, ...newComponents };
};

export const useComponents = () => {
    const [components, setComponents] = useState(defaultComponents)

    const addComponents = function (newComponents: {[name:string]: (...inputs: any)=>JSX.Element}) {
        setComponents(prev => ({...prev, ...newComponents}))
    }
    
    return {
        addComponents,
        components
    }
}
