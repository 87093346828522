import {
  AddInvoiceMutationVariables,
} from '../../gql/invoices/Invoices.generated'
import InvoicesEdit from './InvoicesEdit'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAlert } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'
import { prepStringWN, required } from '@lemonbrain/utils'

export default function InvoicesEditBusiness({
  goToInvoice,
  loading,
  createEntry,
  invoice,
  setInvoice,
}: {
  goToInvoice: (id: string | null) => void
  loading: boolean
  createEntry(variables: AddInvoiceMutationVariables): Promise<void>
  invoice: AddInvoiceMutationVariables & {
    costText: string,
    person?: {
      id: string
      label?: string
    }
  }
  setInvoice: React.Dispatch<React.SetStateAction<AddInvoiceMutationVariables & {
    costText: string,
    person?: {
      id: string
      label?: string
    }
  }>>
}) {
  const [errors, setErrors] = useState<{
    personId: string
    title: string
    itemText: string
    cost: string
  }>({
    personId: '',
    title: '',
    itemText: '',
    cost: '',
  })
  const intl = useIntl()
  const setAlert = useAlert()
  const { user } = useAppState()

  function cancelClickHandler() {
    goToInvoice(null)
  }


  function validate() {
    return errors.cost === '' && errors.itemText === '' && errors.personId === '' && errors.title === ''
  }

  async function createClickHandler() {
    if (invoice && validate()) {
      if (user && user.uID) {
        try {
          let variables: AddInvoiceMutationVariables = {
            personId: invoice.person?.id || '',
            title: prepStringWN(invoice.title) || '',
            itemText: prepStringWN(invoice.itemText) || '',
            cost: Math.round(parseFloat(invoice.costText) * 100),
          }

          await createEntry(variables)
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.invoicesEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'invoices.invoicesEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'invoices.invoicesEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'invoices.invoicesEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  function checkPerson() {
    if (required(invoice.person?.id)) {
      setErrors((prev) => ({
        ...prev,
        personId: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      personId: intl.formatMessage({
        id: 'invoices.invoicesEdit.error.personIdRequired',
        defaultMessage: 'Person is required',
      }),
    }))
    return true
  }

  function checkTitle() {
    if (required(invoice.title)) {
      setErrors((prev) => ({
        ...prev,
        title: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      title: intl.formatMessage({
        id: 'invoices.invoicesEdit.error.titleRequired',
        defaultMessage: 'Title is required',
      }),
    }))
    return true
  }

  function checkItemText() {
    if (required(invoice.itemText)) {
      setErrors((prev) => ({
        ...prev,
        itemText: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      itemText: intl.formatMessage({
        id: 'invoices.invoicesEdit.error.itemTextRequired',
        defaultMessage: 'Item Text is required',
      }),
    }))
    return true
  }

  function checkCost() {
    if (required(invoice.costText)) {
      setErrors((prev) => ({
        ...prev,
        cost: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      cost: intl.formatMessage({
        id: 'invoices.invoicesEdit.error.costRequired',
        defaultMessage: 'Cost is required',
      }),
    }))
    return true
  }

  useEffect(() => {
    checkPerson()
    checkTitle()
    checkItemText()
    checkCost()
  }, [invoice])

  return (
    <InvoicesEdit
      goToInvoice={goToInvoice}
      errors={errors}
      onCancelClick={cancelClickHandler}
      isLoading={loading}
      onCreateClick={createClickHandler}
      invoice={invoice}
      setInvoice={setInvoice}
    />
  )
}
