// @ts-nocheck
import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type MailingFragmentFragment = (
  { __typename?: 'Mailing' }
  & Pick<Types.Mailing, 'id' | 'from' | 'fromName' | 'to' | 'subject' | 'content' | 'attachments' | 'sent' | 'failed' | 'state'>
);

export type GetMailingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetMailingQuery = (
  { __typename?: 'Query' }
  & { getMailing: (
    { __typename?: 'Mailing' }
    & MailingFragmentFragment
  ) }
);

export type AddMailingMutationVariables = Types.Exact<{
  from: Types.Scalars['String'];
  fromName: Types.Scalars['String'];
  to: Array<Types.Scalars['String']>;
  subject: Types.Scalars['String'];
  content: Types.Scalars['String'];
  attachments?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>>>;
}>;


export type AddMailingMutation = (
  { __typename?: 'Mutation' }
  & { addMailing: (
    { __typename?: 'Mailing' }
    & MailingFragmentFragment
  ) }
);

export type SendMailingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SendMailingMutation = (
  { __typename?: 'Mutation' }
  & { sendMailing: (
    { __typename?: 'Mailing' }
    & MailingFragmentFragment
  ) }
);

export const MailingFragmentFragmentDoc = gql`
    fragment MailingFragment on Mailing {
  id
  from
  fromName
  to
  subject
  content
  attachments
  sent
  failed
  state
}
    `;
export const GetMailingDocument = gql`
    query getMailing($id: ID!) {
  getMailing(id: $id) {
    ...MailingFragment
  }
}
    ${MailingFragmentFragmentDoc}`;

/**
 * __useGetMailingQuery__
 *
 * To run a query within a React component, call `useGetMailingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMailingQuery(baseOptions: Apollo.QueryHookOptions<GetMailingQuery, GetMailingQueryVariables>) {
        return Apollo.useQuery<GetMailingQuery, GetMailingQueryVariables>(GetMailingDocument, baseOptions);
      }
export function useGetMailingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailingQuery, GetMailingQueryVariables>) {
          return Apollo.useLazyQuery<GetMailingQuery, GetMailingQueryVariables>(GetMailingDocument, baseOptions);
        }
export type GetMailingQueryHookResult = ReturnType<typeof useGetMailingQuery>;
export type GetMailingLazyQueryHookResult = ReturnType<typeof useGetMailingLazyQuery>;
export type GetMailingQueryResult = Apollo.QueryResult<GetMailingQuery, GetMailingQueryVariables>;
export const AddMailingDocument = gql`
    mutation addMailing($from: String!, $fromName: String!, $to: [String!]!, $subject: String!, $content: String!, $attachments: [String]) {
  addMailing(
    from: $from
    fromName: $fromName
    to: $to
    subject: $subject
    content: $content
    attachments: $attachments
  ) {
    ...MailingFragment
  }
}
    ${MailingFragmentFragmentDoc}`;
export type AddMailingMutationFn = Apollo.MutationFunction<AddMailingMutation, AddMailingMutationVariables>;

/**
 * __useAddMailingMutation__
 *
 * To run a mutation, you first call `useAddMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMailingMutation, { data, loading, error }] = useAddMailingMutation({
 *   variables: {
 *      from: // value for 'from'
 *      fromName: // value for 'fromName'
 *      to: // value for 'to'
 *      subject: // value for 'subject'
 *      content: // value for 'content'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddMailingMutation(baseOptions?: Apollo.MutationHookOptions<AddMailingMutation, AddMailingMutationVariables>) {
        return Apollo.useMutation<AddMailingMutation, AddMailingMutationVariables>(AddMailingDocument, baseOptions);
      }
export type AddMailingMutationHookResult = ReturnType<typeof useAddMailingMutation>;
export type AddMailingMutationResult = Apollo.MutationResult<AddMailingMutation>;
export type AddMailingMutationOptions = Apollo.BaseMutationOptions<AddMailingMutation, AddMailingMutationVariables>;
export const SendMailingDocument = gql`
    mutation sendMailing($id: ID!) {
  sendMailing(id: $id) {
    ...MailingFragment
  }
}
    ${MailingFragmentFragmentDoc}`;
export type SendMailingMutationFn = Apollo.MutationFunction<SendMailingMutation, SendMailingMutationVariables>;

/**
 * __useSendMailingMutation__
 *
 * To run a mutation, you first call `useSendMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMailingMutation, { data, loading, error }] = useSendMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendMailingMutation(baseOptions?: Apollo.MutationHookOptions<SendMailingMutation, SendMailingMutationVariables>) {
        return Apollo.useMutation<SendMailingMutation, SendMailingMutationVariables>(SendMailingDocument, baseOptions);
      }
export type SendMailingMutationHookResult = ReturnType<typeof useSendMailingMutation>;
export type SendMailingMutationResult = Apollo.MutationResult<SendMailingMutation>;
export type SendMailingMutationOptions = Apollo.BaseMutationOptions<SendMailingMutation, SendMailingMutationVariables>;