import CoursesData from './views/Courses/Courses.data'
import CoursesOverviewData from './views/CoursesOverview/CoursesOverview.data'
import CoursesAddData from './views/CoursesAdd/CoursesAdd.data'
import CoursesRegistrationsData from './views/CoursesOverview/CoursesRegistrations/CoursesRegistrations.data'

import germanMessages from './messages/de'
import getReactApolloCients from './localReactApollo'

import {
  addMenuRoutesBeforeRender,
  addGermanMessagesBeforeRender,
  addWsClientBeforeRender,
  addComponentsBeforeRender
} from '@lemonbrain/hooks'

addMenuRoutesBeforeRender('MAIN', [
  {
    title: {
      id: 'courses.courses',
      defaultMessage: 'Courses',
    },
    icon: 'courses',
    id: 'courses',
    path: '/courses/',
    routePath: '/courses',
    component: CoursesData,
    size: 'small',
    tabIndex: 10,
    visible: true,
    active: true,
    enabled: true,
    accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
    children: [
      {
        title: {
          id: 'courses.overview',
          defaultMessage: 'Overview',
        },
        icon: 'courses',
        id: 'coursesOverview',
        path: '/courses/overview/:courseId?',
        routePath: '/courses/overview',
        component: CoursesOverviewData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'courses.add',
          defaultMessage: 'Add',
        },
        icon: 'courses',
        id: 'coursesAdd',
        path: '/courses/add',
        routePath: '/courses/add',
        component: CoursesAddData,
        size: 'small',
        tabIndex: 12,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'courses.registrations',
          defaultMessage: 'Registrations',
        },
        icon: 'courses',
        id: 'coursesRegistrations',
        path: '/courses/overview/:courseId/registrations',
        routePath: '/courses/overview/:courseId/registrations',
        component: CoursesRegistrationsData,
        size: 'small',
        tabIndex: 13,
        visible: false,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
    ],
  },
])

const wsClient = getReactApolloCients().getWsClient()
if (wsClient) {
  addWsClientBeforeRender(wsClient)
}

addGermanMessagesBeforeRender(germanMessages)

let components = {
  CoursesOverviewData
}

addComponentsBeforeRender(components)
