import React, { useEffect } from 'react'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import {
    ButtonGroup,
} from '@lemonbrain/components/PageStyles/Settings.styled'


interface iFormInput { password: string, passwordConfirm: string }

export default function ChangePassword ({
    onSubmit,
    isLoading,
    onForgotPasswordClick
}: {
    onSubmit: (password: string) => Promise<boolean>,
    isLoading: boolean,
    onForgotPasswordClick?: (a: React.SyntheticEvent<HTMLButtonElement>) => unknown,
}) {
    const { setValue, register, handleSubmit, setError, formState: { errors } } = useForm<iFormInput>()
    const intl = useIntl()

    useEffect(() => {
        register('password', {
            required: true,
            minLength: 6
        })
        register('passwordConfirm', {
            required: true,
            minLength: 6
        })
    }, [register])

    const onSubmitCalled = async ({ password, passwordConfirm }: iFormInput) => {
        if (password === passwordConfirm) {
            await onSubmit(password)
        } else {
            setError('password', {
                type: "manual",
                message: intl.formatMessage({ id: 'auth.changePassword.error.needToBeTheSame', defaultMessage: 'The two password needs to be the same' }),
            })
            setError('passwordConfirm', {
                type: "manual",
                message: intl.formatMessage({ id: 'auth.changePassword.error.needToBeTheSame', defaultMessage: 'The two password needs to be the same' }),
            })
        }
    }

    return (

        <Container>
            <Block
                overrides={{
                    Block: {
                        style: {
                            minHeight: 'calc(100vh - 213px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    },
                }}
            >
                <form onSubmit={handleSubmit(onSubmitCalled)} style={{ width: '100%' }}>
                    <Block
                        as="h1"
                        overrides={{
                            Block: {
                                style: ({ $theme }) => {
                                    return {
                                        ...$theme.typography.font1250,
                                        color: '#fff',
                                        fontWeight: 700,
                                        marginBottom: '30px',
                                    }
                                },
                            },
                        }}
                    >
                        <FormattedMessage
                            id='auth.changePassword.changePassword'
                            defaultMessage={'Change password'}
                        />
                    </Block>
                    <Block marginBottom="30px">
                        <FormControl
                            label={intl.formatMessage({ id: 'auth.changePassword.password', defaultMessage: 'Password' })}
                            error={errors.password && (errors.password.message ? errors.password.message : intl.formatMessage({ id: 'auth.changePassword.error.password', defaultMessage: 'Please enter a password width at least 6 signs' }))}
                        >
                            <Input
                                name="password"
                                type="password"
                                overrides={{
                                    InputContainer: {
                                        style: () => {
                                            return { backgroundColor: 'transparent' }
                                        },
                                    },
                                }}
                                onChange={(event) => { setValue('password', event.currentTarget.value) }}
                            />
                        </FormControl>
                    </Block>
                    <Block marginBottom="30px">
                        <FormControl
                            label={intl.formatMessage({ id: 'auth.login.passwordConfirm', defaultMessage: 'Confirm password' })}
                            error={errors.password && (errors.password.message ? errors.password.message : intl.formatMessage({ id: 'auth.changePassword.error.confirmPassword', defaultMessage: 'Please enter a confirmation password width at least 6 signs' }))}
                        >
                            <Input
                                name="passwordConfirm"
                                type="password"
                                overrides={{
                                    InputContainer: {
                                        style: () => {
                                            return { backgroundColor: 'transparent' }
                                        },
                                    },
                                }}
                                onChange={(event) => { setValue('passwordConfirm', event.currentTarget.value) }}
                            />
                        </FormControl>
                    </Block>
                    <ButtonGroup>
                        <Button type="submit" isLoading={isLoading}>
                            <FormattedMessage
                                id='auth.changePassword.changePassword'
                                defaultMessage={'Change password'}
                            />  </Button>
                        <Button kind="tertiary" type="button" onClick={onForgotPasswordClick}>
                            <FormattedMessage
                                id='auth.changePassword.forgotPassword'
                                defaultMessage={'Forgot password'}
                            />
                        </Button>
                    </ButtonGroup>
                </form>
            </Block>
        </Container >
    )
}
