import CreateAssociationInvoices from './CreateAssociationInvoices'

export default function CreateDirectMemberInvoicesBusiness({
    loading,
    createAssociationInvoices,
    progress,
}: {
    loading: boolean
    createAssociationInvoices: () => void
    progress: number | null
}) {

  return <CreateAssociationInvoices loading={loading} onClick={createAssociationInvoices} progress={progress} />
}
