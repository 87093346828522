import { InnerRoute } from '@lemonbrain/hooks'
import { useState } from 'react'

import CourseSettingsBusiness from './CourseSettings.business'

export default function CourseSettingsData({ subNav }: { subNav: InnerRoute[] }) {
  const [refresh, setRefresh] = useState(false)

  const newEntry = function() {
    setRefresh(!refresh)
  }

  return (
    <CourseSettingsBusiness
      subNav={subNav}
      newEntry={newEntry}
      refresh={refresh}
    />
  )
}
