import { useIntl } from 'react-intl'

import RadioFilter from '@lemonbrain/components/UiElements/SearchFilters/Filters/RadioFilter'

export default function StateBusiness({
  states,
  memberships,
  loadingStates,
  stateFilter,
  setStateFilter,
  stateTypeFilter,
  setStateTypeFilter,
  membershipFilter,
  setMembershipFilter,
}: {
  states: {
    id: string
    label: string
    types: {
      id: string
      label: string
      requireMembership: boolean
    }[]
  }[]
  memberships: {
    id: string
    label: string
  }[]
  loadingStates: boolean
  stateFilter: string | null
  setStateFilter: React.Dispatch<React.SetStateAction<string | null>>
  stateTypeFilter: string | null
  setStateTypeFilter: React.Dispatch<React.SetStateAction<string | null>>
  membershipFilter: string | null
  setMembershipFilter: React.Dispatch<React.SetStateAction<string | null>>
}) {
  const intl = useIntl()

  return (
    <>
      <RadioFilter
        values={states}
        loadingValues={loadingStates}
        filter={stateFilter}
        setFilter={(value) => {
          setStateTypeFilter(null)
          setStateFilter(value)
        }}
        label={intl.formatMessage({
          id: 'members.state_filter.state',
          defaultMessage: 'State',
        })}
        comment={intl.formatMessage({
          id: 'members.state_filter.stateComment',
          defaultMessage: 'Has this state',
        })}
      />
      {stateFilter !== '' &&
        states.find((item) => item.id === stateFilter)?.types?.length && (
          <>
            <RadioFilter
              values={
                states
                  .filter((item) => item.id === stateFilter)
                  .map((item) => item.types)[0]
              }
              loadingValues={loadingStates}
              filter={stateTypeFilter}
              setFilter={setStateTypeFilter}
              label={intl.formatMessage({
                id: 'members.state_filter.reason',
                defaultMessage: 'Reason',
              })}
              comment={intl.formatMessage({
                id: 'members.state_filter.reasonComment',
                defaultMessage: 'Has this reason',
              })}
            />

            {stateTypeFilter &&
              states
                .filter((item) => item.id === stateFilter)
                .map((item) => item.types)[0]
                .filter((item) => item.id === stateTypeFilter)[0]
                .requireMembership &&
              (
            <RadioFilter
            values={memberships}
            loadingValues={loadingStates}
            filter={membershipFilter}
            setFilter={setMembershipFilter}
            label={intl.formatMessage({
              id: 'members.state_filter.membership',
              defaultMessage: 'Membership',
            })}
            comment={intl.formatMessage({
              id: 'members.state_filter.membershipComment',
              defaultMessage: 'Has this membbership',
            })}
          />
              )}
          </>
        )}
    </>
  )
}
