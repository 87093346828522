import ParticipantsExport from './ParticipantsExport'

export default function ParticipantsExportBusiness({
  loading,
  startExport,
  courseId,
}: {
  loading: boolean
  startExport: () => void
  courseId: string
}) {
  return (
    <ParticipantsExport
      loading={loading}
      onClick={startExport}
      courseId={courseId}
    />
  )
}
