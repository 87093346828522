import PaymentInfos from './PaymentInfos'

import { BillingInformationFragmentFragment } from '../../../../gql/billing_information/BillingInformation.generated'
import { InvoiceFragmentFragment } from '../../../../gql/invoices/Invoices.generated'


export default function PaymentInfosBusiness({
    loading,
    invoice,
    billingInformation,
}: {
    loading: boolean
    invoice?: InvoiceFragmentFragment
    billingInformation?: BillingInformationFragmentFragment
}) {

  return (
    <PaymentInfos
    loading={loading}
    firstName={billingInformation?.firstName || ''}
    lastName={billingInformation?.lastName || ''}
    firmName={billingInformation?.firmName || ''}
    mailbox={billingInformation?.mailbox || ''}
    streetNr={billingInformation?.streetNr || ''}
    postCode={billingInformation?.postCode || 0}
    place={billingInformation?.place || ''}
    account={billingInformation?.account || ''}
    iban={billingInformation?.iban || ''}
    total={invoice?.total || 0}
    />
  )
}
