import NewslettersOverview from './NewslettersOverview'

import { InnerRoute } from '@lemonbrain/hooks'

export default function NewslettersOverviewBusiness({
  subNav,
  goToNewsletter,
  newsletterId,
}: {
  subNav: InnerRoute[]
  goToNewsletter: (id: string | null) => void
  newsletterId: string | undefined
}) {

  return (
    <NewslettersOverview
      subNav={subNav}
      goToNewsletter={goToNewsletter}
      newsletterId={newsletterId}
    />
  )
}
