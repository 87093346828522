import { Route } from 'react-router-dom'

import { Route as RouteType } from '@lemonbrain/hooks'
import PrivateRoute from '@lemonbrain/components/UiElements/PrivateRoute/PrivateRoute'

export function buildRoutes(routeItems: {
  MAIN: RouteType[]
  AVATAR: RouteType[]
  TOP: RouteType[]
}) {
  let childrenResult: any[] = []

  let result = Object.values(routeItems).map((routes) =>
    routes.map((navItem) =>
      navItem.accessibleBy.indexOf(process.env.REACT_APP_GROUP_GUESTS || '') >
      -1 ? (
        navItem.path.lastIndexOf('/') === navItem.path.length - 1 ? (
          <Route
            key={navItem.id}
            path={`/:language${navItem.path}`}
            element={<navItem.component subNav={navItem.children} />}
          />
        ) : (
          <Route
            key={navItem.id}
            path={`/:language${navItem.path}`}
            element={<navItem.component subNav={navItem.children} />}
          />
        )
      ) : navItem.path.lastIndexOf('/') === navItem.path.length - 1 ? (
        <Route
          key={navItem.id}
          path={`/:language${navItem.path}`}
          element={
            <PrivateRoute
              accessibleBy={navItem.accessibleBy}
              component={(props: any) => (
                <navItem.component {...props} subNav={navItem.children} />
              )}
            />
          }
        />
      ) : (
        <Route
          key={navItem.id}
          path={`/:language${navItem.path}`}
          element={
            <PrivateRoute
              accessibleBy={navItem.accessibleBy}
              component={(props: any) => (
                <navItem.component {...props} subNav={navItem.children} />
              )}
            />
          }
        />
      )
    )
  )

  Object.values(routeItems).forEach((navItems) => {
    for (let i = 0; i < navItems.length; i++) {
      const navItem = navItems[i]
      if (navItem.children) {
        navItem.children.map((navChild) => {
          childrenResult.push(
            navChild.accessibleBy.indexOf(
              process.env.REACT_APP_GROUP_GUESTS || ''
            ) > -1 ? (
              navChild.path.lastIndexOf('/') === navChild.path.length - 1 ? (
                <Route
                  key={navChild.id}
                  path={`/:language${navChild.path}`}
                  element={<navChild.component subNav={navItem.children} />}
                />
              ) : (
                <Route
                  key={navChild.id}
                  path={`/:language${navChild.path}`}
                  element={<navChild.component subNav={navItem.children} />}
                />
              )
            ) : //@ts-ignore
            navChild.path.lastIndexOf('/') === navChild.path.length - 1 ? (
              <Route
                key={navChild.id}
                path={`/:language${navChild.path}`}
                element={
                  <PrivateRoute
                    accessibleBy={navChild.accessibleBy}
                    component={(props: any) => (
                      <navChild.component
                        {...props}
                        subNav={navItem.children}
                      />
                    )}
                  />
                }
              />
            ) : (
              <Route
                key={navChild.id}
                path={`/:language${navChild.path}`}
                element={
                  <PrivateRoute
                    accessibleBy={navChild.accessibleBy}
                    component={(props: any) => (
                      <navChild.component
                        {...props}
                        subNav={navItem.children}
                      />
                    )}
                  />
                }
              />
            )
          )
          return null
        })
      }
    }
  })

  result = [...result, ...childrenResult]

  return result.length > 0 ? result : <></>
}
