import { ToasterContainer } from 'baseui/toast';

const Toaster = () => {
  return (
    <ToasterContainer
      placement="bottomRight"
      overrides={{
        Root: {
          style: () => {
            return {
              zIndex: 9999,
            };
          },
        },
      }}
    >
    </ToasterContainer>
  );
};

export default Toaster;
