import { useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { debounce } from '@lemonbrain/utils'
import SearchFilters, { ModalStatesBasic }  from '@lemonbrain/components/UiElements/SearchFilters/SearchFilters'
import PayedFilterData from './PayedFilter/PayedFilter.data'

export enum ModalStates {
  payed = 'payed',
}

export default function InvoicesFilterBusiness({
  searchQuery,
  setSearchQuery,
  payedFilter,
  setPayedFilter,
}: {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  payedFilter: string[] | null
  setPayedFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
  const intl = useIntl()
  const optionValues = [
    {
      id: ModalStates.payed,
      label: getMessage(ModalStates.payed),
    },
  ]

  const [
    currentPayedFilter,
    setCurrentPayedFilter,
  ] = useState(payedFilter)
  const [query, setQuery] = useState(searchQuery)
  const debouncedSearch = useCallback(debounce(setSearchQuery, 500), [])
  const [modalState, setModalState] = useState<ModalStates | ModalStatesBasic>(
    ModalStatesBasic.empty
  )
  const [options, setOptions] = useState(
    optionValues.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0))
  )
  const [values, setValues] = useState<ModalStates[]>(() => {
    let initValues = []
    if (payedFilter) {
      initValues.push(ModalStates.payed)
    }
    return initValues
  })

  useEffect(() => {
    debouncedSearch(query)
  }, [query])

  const debouncedStateFilter = useCallback(
    debounce(setPayedFilter, 500),
    []
  )

  useEffect(() => {
    debouncedStateFilter(currentPayedFilter)
  }, [currentPayedFilter])

  function addFilter(newValue: ModalStates[]) {
    const diff = newValue.filter((x) => !values.includes(x))
    if (diff && diff.length > 0) {
      const newV = diff[0].toString() as ModalStates
      setModalState(newV)
      setOptions((prev) => prev.filter((item) => item.id !== newV))
    }

    setValues((prev) => [...prev, ...newValue])
  }

  function removeFilter(oldValue: ModalStates) {
    switch (oldValue) {
      case ModalStates.payed:
        setPayedFilter(null)
        break
    }
    setValues((prev) => prev.filter((item) => item !== oldValue))
    setOptions((prev) =>
      [
        ...prev,
        optionValues.filter((item) => item.id === oldValue.toString())[0],
      ].sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0))
    )
  }

  function editFilter(value: ModalStates) {
    setModalState(value)
  }

  function getMessage(modalState: ModalStates) {
    switch (modalState) {
      case ModalStates.payed:
        return intl.formatMessage({
          id: 'invoices.invoices_filter.payed',
          defaultMessage: 'Bezahlt',
        })
      default:
        return ''
    }
  }

  return (
    <SearchFilters<ModalStates>
      modalState={modalState}
      setModalState={setModalState}
      query={query}
      setQuery={setQuery}
      values={values}
      options={options}
      addFilter={addFilter}
      editFilter={editFilter}
      removeFilter={removeFilter}
      getMessage={getMessage}
      filters={[
        {
          id: ModalStates.payed,
          Component: (
            <PayedFilterData
              payedFilter={currentPayedFilter}
              setPayedFilter={setCurrentPayedFilter}
            />
          ),
        },
      ]}
    />
  )
}
