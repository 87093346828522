// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type MembershipFragmentFragment = (
  { __typename?: 'Membership' }
  & Pick<Types.Membership, 'id' | 'prize' | 'directInvoicing' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetMembershipQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetMembershipQuery = (
  { __typename?: 'Query' }
  & { getMembership: (
    { __typename?: 'Membership' }
    & MembershipFragmentFragment
  ) }
);

export type AddMembershipMutationVariables = Types.Exact<{
  name: Types.TranslationObjectCreate;
  prize: Types.Scalars['Int'];
  directInvoicing: Types.Scalars['Boolean'];
}>;


export type AddMembershipMutation = (
  { __typename?: 'Mutation' }
  & { addMembership: (
    { __typename?: 'Membership' }
    & MembershipFragmentFragment
  ) }
);

export type UpdateMembershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.TranslationObjectUpdate;
  prize: Types.Scalars['Int'];
  directInvoicing?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type UpdateMembershipMutation = (
  { __typename?: 'Mutation' }
  & { updateMembership: (
    { __typename?: 'Membership' }
    & MembershipFragmentFragment
  ) }
);

export type DeleteMembershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteMembershipMutation = (
  { __typename?: 'Mutation' }
  & { deleteMembership: (
    { __typename?: 'Membership' }
    & MembershipFragmentFragment
  ) }
);

export type GetMembershipsQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetMembershipsQuery = (
  { __typename?: 'Query' }
  & { getMemberships: (
    { __typename?: 'MembershipPagination' }
    & Pick<Types.MembershipPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Membership' }
      & MembershipFragmentFragment
    )> }
  ) }
);

export type GetNextMembershipsQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextMembershipsQuery = (
  { __typename?: 'Query' }
  & { getNextMemberships: (
    { __typename?: 'MembershipPagination' }
    & Pick<Types.MembershipPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Membership' }
      & MembershipFragmentFragment
    )> }
  ) }
);

export const MembershipFragmentFragmentDoc = gql`
    fragment MembershipFragment on Membership {
  id
  name {
    de
    fr
    it
  }
  prize
  directInvoicing
  createdFrom
}
    `;
export const GetMembershipDocument = gql`
    query getMembership($id: ID!) {
  getMembership(id: $id) {
    ...MembershipFragment
  }
}
    ${MembershipFragmentFragmentDoc}`;

/**
 * __useGetMembershipQuery__
 *
 * To run a query within a React component, call `useGetMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMembershipQuery(baseOptions: Apollo.QueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
        return Apollo.useQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, baseOptions);
      }
export function useGetMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
          return Apollo.useLazyQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, baseOptions);
        }
export type GetMembershipQueryHookResult = ReturnType<typeof useGetMembershipQuery>;
export type GetMembershipLazyQueryHookResult = ReturnType<typeof useGetMembershipLazyQuery>;
export type GetMembershipQueryResult = Apollo.QueryResult<GetMembershipQuery, GetMembershipQueryVariables>;
export const AddMembershipDocument = gql`
    mutation addMembership($name: TranslationObjectCreate!, $prize: Int!, $directInvoicing: Boolean!) {
  addMembership(name: $name, prize: $prize, directInvoicing: $directInvoicing) {
    ...MembershipFragment
  }
}
    ${MembershipFragmentFragmentDoc}`;
export type AddMembershipMutationFn = Apollo.MutationFunction<AddMembershipMutation, AddMembershipMutationVariables>;

/**
 * __useAddMembershipMutation__
 *
 * To run a mutation, you first call `useAddMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembershipMutation, { data, loading, error }] = useAddMembershipMutation({
 *   variables: {
 *      name: // value for 'name'
 *      prize: // value for 'prize'
 *      directInvoicing: // value for 'directInvoicing'
 *   },
 * });
 */
export function useAddMembershipMutation(baseOptions?: Apollo.MutationHookOptions<AddMembershipMutation, AddMembershipMutationVariables>) {
        return Apollo.useMutation<AddMembershipMutation, AddMembershipMutationVariables>(AddMembershipDocument, baseOptions);
      }
export type AddMembershipMutationHookResult = ReturnType<typeof useAddMembershipMutation>;
export type AddMembershipMutationResult = Apollo.MutationResult<AddMembershipMutation>;
export type AddMembershipMutationOptions = Apollo.BaseMutationOptions<AddMembershipMutation, AddMembershipMutationVariables>;
export const UpdateMembershipDocument = gql`
    mutation updateMembership($id: ID!, $name: TranslationObjectUpdate!, $prize: Int!, $directInvoicing: Boolean) {
  updateMembership(
    id: $id
    name: $name
    prize: $prize
    directInvoicing: $directInvoicing
  ) {
    ...MembershipFragment
  }
}
    ${MembershipFragmentFragmentDoc}`;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      prize: // value for 'prize'
 *      directInvoicing: // value for 'directInvoicing'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, baseOptions);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const DeleteMembershipDocument = gql`
    mutation deleteMembership($id: ID!) {
  deleteMembership(id: $id) {
    ...MembershipFragment
  }
}
    ${MembershipFragmentFragmentDoc}`;
export type DeleteMembershipMutationFn = Apollo.MutationFunction<DeleteMembershipMutation, DeleteMembershipMutationVariables>;

/**
 * __useDeleteMembershipMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipMutation, { data, loading, error }] = useDeleteMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMembershipMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipMutation, DeleteMembershipMutationVariables>) {
        return Apollo.useMutation<DeleteMembershipMutation, DeleteMembershipMutationVariables>(DeleteMembershipDocument, baseOptions);
      }
export type DeleteMembershipMutationHookResult = ReturnType<typeof useDeleteMembershipMutation>;
export type DeleteMembershipMutationResult = Apollo.MutationResult<DeleteMembershipMutation>;
export type DeleteMembershipMutationOptions = Apollo.BaseMutationOptions<DeleteMembershipMutation, DeleteMembershipMutationVariables>;
export const GetMembershipsDocument = gql`
    query getMemberships($cursor: StartCursorInput!) {
  getMemberships(cursor: $cursor) {
    items {
      ...MembershipFragment
    }
    nextCursor
  }
}
    ${MembershipFragmentFragmentDoc}`;

/**
 * __useGetMembershipsQuery__
 *
 * To run a query within a React component, call `useGetMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetMembershipsQuery(baseOptions: Apollo.QueryHookOptions<GetMembershipsQuery, GetMembershipsQueryVariables>) {
        return Apollo.useQuery<GetMembershipsQuery, GetMembershipsQueryVariables>(GetMembershipsDocument, baseOptions);
      }
export function useGetMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipsQuery, GetMembershipsQueryVariables>) {
          return Apollo.useLazyQuery<GetMembershipsQuery, GetMembershipsQueryVariables>(GetMembershipsDocument, baseOptions);
        }
export type GetMembershipsQueryHookResult = ReturnType<typeof useGetMembershipsQuery>;
export type GetMembershipsLazyQueryHookResult = ReturnType<typeof useGetMembershipsLazyQuery>;
export type GetMembershipsQueryResult = Apollo.QueryResult<GetMembershipsQuery, GetMembershipsQueryVariables>;
export const GetNextMembershipsDocument = gql`
    query getNextMemberships($cursor: NextCursorInput!) {
  getNextMemberships(cursor: $cursor) {
    items {
      ...MembershipFragment
    }
    nextCursor
  }
}
    ${MembershipFragmentFragmentDoc}`;

/**
 * __useGetNextMembershipsQuery__
 *
 * To run a query within a React component, call `useGetNextMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextMembershipsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextMembershipsQuery(baseOptions: Apollo.QueryHookOptions<GetNextMembershipsQuery, GetNextMembershipsQueryVariables>) {
        return Apollo.useQuery<GetNextMembershipsQuery, GetNextMembershipsQueryVariables>(GetNextMembershipsDocument, baseOptions);
      }
export function useGetNextMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextMembershipsQuery, GetNextMembershipsQueryVariables>) {
          return Apollo.useLazyQuery<GetNextMembershipsQuery, GetNextMembershipsQueryVariables>(GetNextMembershipsDocument, baseOptions);
        }
export type GetNextMembershipsQueryHookResult = ReturnType<typeof useGetNextMembershipsQuery>;
export type GetNextMembershipsLazyQueryHookResult = ReturnType<typeof useGetNextMembershipsLazyQuery>;
export type GetNextMembershipsQueryResult = Apollo.QueryResult<GetNextMembershipsQuery, GetNextMembershipsQueryVariables>;