import CreateMemberInvoices from './CreateMemberInvoices'

export default function CreateMemberInvoicesBusiness({
    loading,
    createMemberInvoices,
    progress,
}: {
    loading: boolean
    createMemberInvoices: () => void
    progress: number | null
}) {

  return <CreateMemberInvoices loading={loading} onClick={createMemberInvoices} progress={progress} />
}
