import React from 'react'

import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider, LightTheme, DarkTheme } from 'baseui'
import { useEffect } from 'react'
import { useParams, Route, Routes, useNavigate, Outlet } from 'react-router-dom'
import { IntlProvider } from 'react-intl'

import { styletron, debug } from './styletron'
import './css/reset.css'
import logoSvg from './assets/images/Logo_SVSS.svg'
import logoSvgColor from './assets/images/Logo_SVSS_color.svg'
import avatarSvg from './assets/images/avatar.jpg'
import { buildRoutes } from './buildRoutesHelper'

import {
  ThemeSwitcherProvider,
  THEME,
} from '@lemonbrain/contexts/theme/theme.provider'
import Layout from '@lemonbrain/components/Layout/Layout'
import { AlertProvider } from '@lemonbrain/contexts/AlertProvider'
import {
  useGermanMessages,
  defaultOpenMenuRoutes,
  // useHistoryPush,
  // isTokenExpiredWithoutRender,
} from '@lemonbrain/hooks'

import { useAppState } from '@lemonbrain/contexts'

function InnerApp() {
  const [theme, setTheme] = React.useState(THEME.dark)
  const [logo, setLogo] = React.useState(logoSvg)
  const [logoColor, setLogoColor] = React.useState(logoSvgColor)
  const [avatar, setAvatar] = React.useState(avatarSvg)
  const { language } = useParams<{ language: string }>()
  const { germanMessages } = useGermanMessages()
  
  return (
    <IntlProvider
      locale={language || 'de'}
      messages={language === 'de' ? germanMessages : {}}
    >
        <ThemeSwitcherProvider
          value={{
            theme,
            setTheme,
            logo,
            setLogo,
            logoColor,
            setLogoColor,
            avatar,
            setAvatar,
          }}
        >
          {/* @ts-ignore */}
          <StyletronProvider
            value={styletron}
            debug={debug}
            debugAfterHydration
          >
            <BaseProvider
              theme={
                theme === THEME.light
                  ? {
                      ...LightTheme,
                      direction: 'ltr',
                      grid: { ...LightTheme.grid, maxWidth: 2000 },
                    }
                  : {
                      ...DarkTheme,
                      direction: 'ltr',
                      grid: { ...DarkTheme.grid, maxWidth: 2000 },
                    }
              }
            >
              <AlertProvider>
                <Layout>
                  <Outlet />
                </Layout>
              </AlertProvider>
            </BaseProvider>
          </StyletronProvider>
        </ThemeSwitcherProvider>
    </IntlProvider>
  )
}

function App() {
  const initLocation =
    window.location.href.indexOf('/en') >= 0
      ? 'en'
      : window.location.href.indexOf('/de') >= 0
      ? 'de'
      : ''
  const navigate = useNavigate()
  const { mainTyedRoutesItems } = useAppState()
  // const pushPath = useHistoryPush()
  // useEffect(() => {
  //   let intervalId: any
  //   intervalId = setInterval(() => {
  //     if (isTokenExpiredWithoutRender()) {
  //       pushPath(`/${initLocation}/login`)
  //     }
  //   }, parseInt(process.env.REACT_APP_CHECK_LOGGEDIN_INTERVAL || ''))

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [initLocation, pushPath])

  useEffect(() => {
    if (!initLocation) {
      navigate(`/de`)
    }
  }, [initLocation, navigate])

  return (
    <Routes>
      <Route path='/:language' element={<InnerApp />}>
        {buildRoutes(defaultOpenMenuRoutes)}
        {buildRoutes(mainTyedRoutesItems)}
      </Route>
    </Routes>
  )
}

export default App
