export const debounce = function(fn: any, delay: number = 5000) {
    let timeoutId: any;

    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn.apply(null, args);
      }, delay);
    }
  }
