import React from 'react';
import Container from '../UiElements/Container/Container';
import FooterWrapper, { Text } from './Footer.styled';

const Footer: React.FC<{}> = () => {
  return (
    <FooterWrapper>
      <Container>
        {window.location.hostname.includes('localhost') || window.location.hostname.includes('lemonbrain.ch') ? (
          <Text>YOU ARE ON DEV, TEST, STAGING SYSTEMS</Text>
        ) : (
          <Text>lemonbrain ©{new Date().getFullYear()} Created with love</Text>
        )}
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
