import MembersTable, { RowDataT } from './MembersTable'

import {
  GetStatesNoPersonQuery
} from '../../../gql/members/State.generated'

export default function MembersTableBusiness({
  data,
  onFetchMoreData,
  onRefetchData,
  loading,
  pageCount,
  pageSize,
  setPageSize,
  goToPerson,
  deletePerson,
  dataState,
  setSelectedPerson,
}: {
  data: RowDataT[]
  onFetchMoreData: () => void
  onRefetchData: () => void
  loading: boolean
  pageCount: number
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  goToPerson: (id: string | null) => void
  deletePerson:(id: string) => void
  dataState: GetStatesNoPersonQuery | undefined
  setSelectedPerson: React.Dispatch<React.SetStateAction<string[]>>
}) {
  return (
    <MembersTable
      data={data}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSizeInput={pageSize}
      setPageSizeInput={setPageSize}
      goToPerson={goToPerson}
      removeRow={deletePerson}
      dataState={dataState}
      setSelectedPerson={setSelectedPerson}
    />
  )
}
