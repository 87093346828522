import { useIntl } from 'react-intl'
import MembersAdd from './MembersAdd'

import { InnerRoute, useAlert } from '@lemonbrain/hooks'
import { useState } from 'react'

export default function MembersAddBusiness({
  subNav,
  createUser,
  createPerson,
  values,
  setValues
}: {
  subNav: InnerRoute[]
  createUser: (email: string) => Promise<{ id: string, uName: string, uEmail: string, state: "is-valid" }>
  createPerson: (authId: string, email: string) => Promise<void>
  values: { email: string }
  setValues: React.Dispatch<React.SetStateAction<{ email: string }>>
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  async function addPersonEntryClickHandler(email: string) {
    setLoading(true)

    let state = false

    let answer: { id: string, uName: string, uEmail: string, state: "is-valid" } | undefined = undefined

    try {
      answer = await createUser(email)
    } catch (e) {
      setAlert && (e as Array<String>).length > 0 && setAlert({
        title: intl.formatMessage({ id: 'auth.signUp.error', defaultMessage: 'Error' }),
        label: (e as Array<String>).map((error: any) => error).join(', '),
        style: 'error'
      })
    }

    state = answer?.state === 'is-valid'

    if (state && answer?.uEmail) {
      // create empty "person" and redirect to that edit page
      await createPerson(answer.id, answer.uEmail)
    }

    return state
  }

  return (
    <MembersAdd
      subNav={subNav}
      addPersonEntry={addPersonEntryClickHandler}
      values={values}
      setValues={setValues}
      isLoading={loading}
    />
  )
}
