import StateFunctionEditBusiness from './StateFunctionEdit.business'
import {
    useGetStateFunctionLazyQuery,
  useUpdateStateFunctionMutation,
  useAddStateFunctionMutation,
} from '../../../gql/StateFunction.generated'
import getReactApolloCients from '../../../localReactApollo'

import { useEffect, useState } from 'react'

export default function StateFunctionEditData({
  stateFunctionId,
  setStateFunctionId,
}: {
  stateFunctionId: string | null | undefined
  setStateFunctionId: (stateFunctionId: string | undefined) => void
}) {
  const [values, setValues] = useState<{
    de: string | undefined
    fr: string | undefined
    it: string | undefined
  }>({
    de: undefined,
    fr: undefined,
    it: undefined,
  })
  const client = getReactApolloCients().getClient()
  const [
    getStateFunctionQuery,
    { data, loading, error: queryError },
  ] = useGetStateFunctionLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateStateFunctionMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateStateFunctionMutation({
    //@ts-ignore
    client,
  })
  const [
    addStateFunctionMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddStateFunctionMutation({
    //@ts-ignore
    client,
  })

  async function updateStateFunctionEntry(data: {
    stateFunctionId: string
    de: string
    fr: string
    it: string
  }) {
    if (data.stateFunctionId) {
      await updateStateFunctionMutation({
        variables: {
          id: data.stateFunctionId,
          name: {
              de: data.de,
              fr: data.fr,
              it: data.it
          }
        },
      })
    }
  }

  async function createStateFunctionEntry(data: {
    de: string
    fr: string
    it: string
  }) {
    await addStateFunctionMutation({
      variables: {
        name: {
            de: data.de,
            fr: data.fr,
            it: data.it
        }
      },
    })
  }

  function removeStateFunctionId() {
    setStateFunctionId(undefined)
  }

  useEffect(() => {
    if (stateFunctionId) {
        getStateFunctionQuery({
        variables: {
          id: stateFunctionId,
        },
      })
    }
  }, [stateFunctionId])

  useEffect(() => {
    if (data?.getStateFunction) {

      setValues({
        de: data.getStateFunction.name.de,
        fr: data.getStateFunction.name.fr,
        it: data.getStateFunction.name.it,
      })
    }
  }, [data])

  return (
    <StateFunctionEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      queryError={queryError || queryChangeError || queryAddError}
      removeStateFunctionId={removeStateFunctionId}
      stateFunctionId={stateFunctionId}
      updateStateFunctionEntry={updateStateFunctionEntry}
      createStateFunctionEntry={createStateFunctionEntry}
    />
  )
}
