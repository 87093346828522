import {
    InMemoryCache,
} from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'

let clientAuth: any = null
export const getApolloClientAuth = () => {
    if (!clientAuth) {
        const cache = new InMemoryCache()

        const httpLink = createHttpLink({
            uri: process.env.REACT_APP_AUTH_URL + '/graphql',
            credentials: 'include',
        })

        clientAuth = new ApolloClient({
            link: httpLink,
            cache,
        })
    }

    return clientAuth
}
