import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import StateFilterBusiness from './StateFilter.business'
import getReactApolloCients from '../../../../localReactApollo'
import { useGetStatesNoPersonQuery } from '../../../../gql/members/State.generated'
import { useGetMembershipsNoPersonQuery } from '../../../../gql/members/Membership.generated'
import { StateType } from '../../../../gql/members/types'

export default function StateFilterData({
  stateFilter,
  setStateFilter,
  stateTypeFilter,
  setStateTypeFilter,
  membershipFilter,
  setMembershipFilter,
}: {
  stateFilter: string | null
  setStateFilter: React.Dispatch<React.SetStateAction<string | null>>
  stateTypeFilter: string | null
  setStateTypeFilter: React.Dispatch<React.SetStateAction<string | null>>
  membershipFilter: string | null
  setMembershipFilter: React.Dispatch<React.SetStateAction<string | null>>
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [states, setStates] = useState<
    {
      id: string
      label: string
      types: {
        id: string
        label: string
        requireMembership: boolean
      }[]
    }[]
  >([])
  const [memberships, setMemberships] = useState<
    {
      id: string
      label: string
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data: dataStates,
    loading: loadingStates,
    error: errorStates,
  } = useGetStatesNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })
  const {
    data: dataMemberships,
    loading: loadingMemberships,
    error: errorMemberships,
  } = useGetMembershipsNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (errorStates || errorMemberships) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.state_filter.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.state_filter.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorStates?.message || errorMemberships?.message)
    }
  }, [errorStates, errorMemberships])

  useEffect(() => {
    if (dataStates?.getStatesNoPerson.items) {
      setStates(
        dataStates?.getStatesNoPerson.items
          .map((item) => ({
            id: item.id,
            label: getTranslation(item.name, intl),
            types: item.types
              ? item.types
                //@ts-ignore
                  .map((item1: StateType) => ({
                    id: item1.id,
                    label: getTranslation(item1.name, intl),
                    requireMembership: item1.requireMembership,
                  }))
                  .sort(function (a: { label: string }, b: { label: string }) {
                    var labelA = a.label.toUpperCase()
                    var labelB = b.label.toUpperCase()
                    if (labelA < labelB) {
                      return -1
                    }
                    if (labelA > labelB) {
                      return 1
                    }

                    return 0
                  })
              : [],
          }))
          .sort(function (a, b) {
            var labelA = a.label.toUpperCase()
            var labelB = b.label.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataStates])

  useEffect(() => {
    if (dataMemberships?.getMembershipsNoPerson.items) {
      setMemberships(
        dataMemberships?.getMembershipsNoPerson.items
          .map((item) => ({
            id: item.id,
            label: getTranslation(item.name, intl),
          }))
          .sort(function (a, b) {
            var labelA = a.label.toUpperCase()
            var labelB = b.label.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataMemberships])

  return (
    <StateFilterBusiness
      states={states}
      loadingStates={loadingStates || loadingMemberships}
      stateFilter={stateFilter}
      memberships={memberships}
      setStateFilter={setStateFilter}
      stateTypeFilter={stateTypeFilter}
      setStateTypeFilter={setStateTypeFilter}
      membershipFilter={membershipFilter}
      setMembershipFilter={setMembershipFilter}
    />
  )
}
