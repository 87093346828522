import { Button } from 'baseui/button'
import { FormattedMessage } from 'react-intl'
import { Notification, KIND } from 'baseui/notification'

import { PaymentState } from '../../InvoicesMy.data'

export default function OnlinePayer({
  isLoading,
  clickPayHandler,
  paymentState,
}: {
  isLoading: boolean
  clickPayHandler: () => void
  paymentState: PaymentState | undefined
}) {
  return !paymentState ? (
    <Button onClick={clickPayHandler} isLoading={isLoading}>
      <FormattedMessage
        id='invoices.onlinePayer.payOnline'
        defaultMessage={'Pay online'}
      />
    </Button>
  ) : paymentState === PaymentState.Success ? (
    <Notification kind={KIND.positive}>
      <FormattedMessage
        id='invoices.onlinePayer.paySuccessed'
        defaultMessage={'Payment successed'}
      />
    </Notification>
  ) : (
    <Notification kind={KIND.negative}>
      <FormattedMessage
        id='invoices.onlinePayer.payFailed'
        defaultMessage={'Paymen failed'}
      />
    </Notification>
  )
}
