import { useStyletron } from "baseui"

export default function DocumentIcon({
  size='16',
  inverse=false
}: {
  size?: string
  inverse?: boolean
}) {
  //@ts-ignore
  const [css, theme] = useStyletron()

  let color = theme.name === 'dark-theme' ? 'white' : 'black'
  if (inverse) {
    color = theme.name === 'dark-theme' ? 'black' : 'white'
  }

  return (
    <svg width={size + 'px'} height={size + 'px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1C4.34315 1 3 2.34315 3 4V20C3 21.6569 4.34315 23 6 23H18C19.6569 23 21 21.6569 21 20V8.82843C21 8.03278 20.6839 7.26972 20.1213 6.70711L15.2929 1.87868C14.7303 1.31607 13.9672 1 13.1716 1H6ZM5 4C5 3.44772 5.44772 3 6 3H12V8C12 9.10457 12.8954 10 14 10H19V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V4ZM18.5858 8L14 3.41421V8H18.5858Z" fill={color}/>
    </svg>
  )
}
