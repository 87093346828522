import MemberSelector from './MemberSelector'

export default function MemberSelectorBusiness({
  isLoading,
  options,
  setValue,
  value,
  handleInputChange,
}: {
  isLoading: boolean;
  options?: { id: string, label: string }[];
  setValue: React.Dispatch<React.SetStateAction<{ id: string; label: string } | undefined>>
  value?: { id: string; label: string };
  handleInputChange: (value: string) => void;
}) {

  return (
    <MemberSelector
      isLoading={isLoading}
      options={options}
      setValue={setValue}
      value={value}
      handleInputChange={handleInputChange}
    />
  )
}
