// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type StateFragmentFragment = (
  { __typename?: 'State' }
  & Pick<Types.State, 'id' | 'isInitState' | 'highlightColor' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetStateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetStateQuery = (
  { __typename?: 'Query' }
  & { getState: (
    { __typename?: 'State' }
    & StateFragmentFragment
  ) }
);

export type AddStateMutationVariables = Types.Exact<{
  isInitState: Types.Scalars['Boolean'];
  name: Types.TranslationObjectCreate;
  highlightColor: Types.Scalars['String'];
}>;


export type AddStateMutation = (
  { __typename?: 'Mutation' }
  & { addState: (
    { __typename?: 'State' }
    & StateFragmentFragment
  ) }
);

export type UpdateStateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  isInitState?: Types.Maybe<Types.Scalars['Boolean']>;
  name: Types.TranslationObjectUpdate;
  highlightColor?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdateStateMutation = (
  { __typename?: 'Mutation' }
  & { updateState: (
    { __typename?: 'State' }
    & StateFragmentFragment
  ) }
);

export type DeleteStateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteStateMutation = (
  { __typename?: 'Mutation' }
  & { deleteState: (
    { __typename?: 'State' }
    & StateFragmentFragment
  ) }
);

export type GetStatesQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetStatesQuery = (
  { __typename?: 'Query' }
  & { getStates: (
    { __typename?: 'StatePagination' }
    & Pick<Types.StatePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'State' }
      & StateFragmentFragment
    )> }
  ) }
);

export type GetNextStatesQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextStatesQuery = (
  { __typename?: 'Query' }
  & { getNextStates: (
    { __typename?: 'StatePagination' }
    & Pick<Types.StatePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'State' }
      & StateFragmentFragment
    )> }
  ) }
);

export const StateFragmentFragmentDoc = gql`
    fragment StateFragment on State {
  id
  isInitState
  highlightColor
  name {
    de
    fr
    it
  }
  createdFrom
}
    `;
export const GetStateDocument = gql`
    query getState($id: ID!) {
  getState(id: $id) {
    ...StateFragment
  }
}
    ${StateFragmentFragmentDoc}`;

/**
 * __useGetStateQuery__
 *
 * To run a query within a React component, call `useGetStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStateQuery(baseOptions: Apollo.QueryHookOptions<GetStateQuery, GetStateQueryVariables>) {
        return Apollo.useQuery<GetStateQuery, GetStateQueryVariables>(GetStateDocument, baseOptions);
      }
export function useGetStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStateQuery, GetStateQueryVariables>) {
          return Apollo.useLazyQuery<GetStateQuery, GetStateQueryVariables>(GetStateDocument, baseOptions);
        }
export type GetStateQueryHookResult = ReturnType<typeof useGetStateQuery>;
export type GetStateLazyQueryHookResult = ReturnType<typeof useGetStateLazyQuery>;
export type GetStateQueryResult = Apollo.QueryResult<GetStateQuery, GetStateQueryVariables>;
export const AddStateDocument = gql`
    mutation addState($isInitState: Boolean!, $name: TranslationObjectCreate!, $highlightColor: String!) {
  addState(
    isInitState: $isInitState
    name: $name
    highlightColor: $highlightColor
  ) {
    ...StateFragment
  }
}
    ${StateFragmentFragmentDoc}`;
export type AddStateMutationFn = Apollo.MutationFunction<AddStateMutation, AddStateMutationVariables>;

/**
 * __useAddStateMutation__
 *
 * To run a mutation, you first call `useAddStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStateMutation, { data, loading, error }] = useAddStateMutation({
 *   variables: {
 *      isInitState: // value for 'isInitState'
 *      name: // value for 'name'
 *      highlightColor: // value for 'highlightColor'
 *   },
 * });
 */
export function useAddStateMutation(baseOptions?: Apollo.MutationHookOptions<AddStateMutation, AddStateMutationVariables>) {
        return Apollo.useMutation<AddStateMutation, AddStateMutationVariables>(AddStateDocument, baseOptions);
      }
export type AddStateMutationHookResult = ReturnType<typeof useAddStateMutation>;
export type AddStateMutationResult = Apollo.MutationResult<AddStateMutation>;
export type AddStateMutationOptions = Apollo.BaseMutationOptions<AddStateMutation, AddStateMutationVariables>;
export const UpdateStateDocument = gql`
    mutation updateState($id: ID!, $isInitState: Boolean, $name: TranslationObjectUpdate!, $highlightColor: String) {
  updateState(
    id: $id
    isInitState: $isInitState
    name: $name
    highlightColor: $highlightColor
  ) {
    ...StateFragment
  }
}
    ${StateFragmentFragmentDoc}`;
export type UpdateStateMutationFn = Apollo.MutationFunction<UpdateStateMutation, UpdateStateMutationVariables>;

/**
 * __useUpdateStateMutation__
 *
 * To run a mutation, you first call `useUpdateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateMutation, { data, loading, error }] = useUpdateStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isInitState: // value for 'isInitState'
 *      name: // value for 'name'
 *      highlightColor: // value for 'highlightColor'
 *   },
 * });
 */
export function useUpdateStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateMutation, UpdateStateMutationVariables>) {
        return Apollo.useMutation<UpdateStateMutation, UpdateStateMutationVariables>(UpdateStateDocument, baseOptions);
      }
export type UpdateStateMutationHookResult = ReturnType<typeof useUpdateStateMutation>;
export type UpdateStateMutationResult = Apollo.MutationResult<UpdateStateMutation>;
export type UpdateStateMutationOptions = Apollo.BaseMutationOptions<UpdateStateMutation, UpdateStateMutationVariables>;
export const DeleteStateDocument = gql`
    mutation deleteState($id: ID!) {
  deleteState(id: $id) {
    ...StateFragment
  }
}
    ${StateFragmentFragmentDoc}`;
export type DeleteStateMutationFn = Apollo.MutationFunction<DeleteStateMutation, DeleteStateMutationVariables>;

/**
 * __useDeleteStateMutation__
 *
 * To run a mutation, you first call `useDeleteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStateMutation, { data, loading, error }] = useDeleteStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStateMutation, DeleteStateMutationVariables>) {
        return Apollo.useMutation<DeleteStateMutation, DeleteStateMutationVariables>(DeleteStateDocument, baseOptions);
      }
export type DeleteStateMutationHookResult = ReturnType<typeof useDeleteStateMutation>;
export type DeleteStateMutationResult = Apollo.MutationResult<DeleteStateMutation>;
export type DeleteStateMutationOptions = Apollo.BaseMutationOptions<DeleteStateMutation, DeleteStateMutationVariables>;
export const GetStatesDocument = gql`
    query getStates($cursor: StartCursorInput!) {
  getStates(cursor: $cursor) {
    items {
      ...StateFragment
    }
    nextCursor
  }
}
    ${StateFragmentFragmentDoc}`;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetStatesQuery(baseOptions: Apollo.QueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
        return Apollo.useQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, baseOptions);
      }
export function useGetStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
          return Apollo.useLazyQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, baseOptions);
        }
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<typeof useGetStatesLazyQuery>;
export type GetStatesQueryResult = Apollo.QueryResult<GetStatesQuery, GetStatesQueryVariables>;
export const GetNextStatesDocument = gql`
    query getNextStates($cursor: NextCursorInput!) {
  getNextStates(cursor: $cursor) {
    items {
      ...StateFragment
    }
    nextCursor
  }
}
    ${StateFragmentFragmentDoc}`;

/**
 * __useGetNextStatesQuery__
 *
 * To run a query within a React component, call `useGetNextStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextStatesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextStatesQuery(baseOptions: Apollo.QueryHookOptions<GetNextStatesQuery, GetNextStatesQueryVariables>) {
        return Apollo.useQuery<GetNextStatesQuery, GetNextStatesQueryVariables>(GetNextStatesDocument, baseOptions);
      }
export function useGetNextStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextStatesQuery, GetNextStatesQueryVariables>) {
          return Apollo.useLazyQuery<GetNextStatesQuery, GetNextStatesQueryVariables>(GetNextStatesDocument, baseOptions);
        }
export type GetNextStatesQueryHookResult = ReturnType<typeof useGetNextStatesQuery>;
export type GetNextStatesLazyQueryHookResult = ReturnType<typeof useGetNextStatesLazyQuery>;
export type GetNextStatesQueryResult = Apollo.QueryResult<GetNextStatesQuery, GetNextStatesQueryVariables>;