import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import WorkLevelFilterBusiness from './WorkLevelFilter.business'
import getReactApolloCients from '../../../../../localReactApollo'
import {useGetWorkLevelsNoPersonQuery} from '../../../../../gql/members/WorkLevel.generated'

export default function WorkLevelFilterData({
  workLevelFilter,
  setWorkLevelFilter,
}: {
  workLevelFilter: string[] | null
  setWorkLevelFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
    const intl = useIntl()
    const setAlert = useAlert()
    const [workLevels, setWorkLevels] = useState<
      {
        id: string
        label: string
      }[]
    >([])
    const client = getReactApolloCients().getClient()
      const { data: dataWorkLevels, loading: loadingWorkLevels, error: errorWorkLevels } = useGetWorkLevelsNoPersonQuery({
        variables: {
          cursor: {
            pageSize: 100,
          },
        },
        //@ts-ignore
        client,
     });

     useEffect(() => {
       if (errorWorkLevels) {
         setAlert &&
           setAlert({
             title: intl.formatMessage({
               id: 'members.workLevel_filter.error',
               defaultMessage: 'Error',
             }),
             label: intl.formatMessage({
               id: 'members.workLevel_filter.networkError',
               defaultMessage: 'We had network problem. Please try again',
             }),
             style: 'error',
           })
         console.error(errorWorkLevels?.message)
       }
     }, [errorWorkLevels])
   
     useEffect(() => {
       if (dataWorkLevels?.getWorkLevelsNoPerson.items) {
        setWorkLevels(
          dataWorkLevels?.getWorkLevelsNoPerson.items.map((item) => ({
             id: item.id,
             label: getTranslation(item.name, intl),
           })).sort(function(a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            
            return 0;
          })
         )
       }
     }, [dataWorkLevels])

  return <WorkLevelFilterBusiness workLevels={workLevels} loadingWorkLevels={loadingWorkLevels} workLevelFilter={workLevelFilter} setWorkLevelFilter={setWorkLevelFilter}/>
}
