import { useState, useEffect } from 'react'

import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteMembershipSignupRecipientMutation,
  useGetMembershipSignupRecipientsQuery
} from '../../../gql/SignupRecipient.generated'
import MembershipMailingTableBusiness from './MembershipMailingTable.business'

export default function MembershipMailingTableData({
  refresh
}: {
  refresh: boolean
}) {
  const [signupRecipients, setSignupRecipients] = useState<
    {
      id: string
      data: {
        email: string
      }
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    refetch,
  } = useGetMembershipSignupRecipientsQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteSignupRecipientMutation] = useDeleteMembershipSignupRecipientMutation({
    //@ts-ignore
    client,
  })

  async function removeSignupRecipientEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteSignupRecipientMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteMembershipSignupRecipient.email) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  useEffect(() => {
    if (data?.getMembershipSignupRecipients) {
      setSignupRecipients(
        data?.getMembershipSignupRecipients.map((item) => ({
          id: item.id?.toString() || '',
          data: {
            email: item.email,
          },
        }))
      )
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [refresh])

  return (
    <MembershipMailingTableBusiness
      isLoading={loading}
      signupRecipients={signupRecipients}
      refetchSignupRecipientsEntries={refetch}
      removeSignupRecipientEntry={removeSignupRecipientEntry}
      queryError={queryError}
    />
  )
}
