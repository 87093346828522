import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  verifyFloat,
  required,
  verifyPostcheck,
  verifyIBAN,
} from '@lemonbrain/utils'

import BillingInformations from './BillingInformations'
import { UpdateAssociationIfMemberMutationVariables } from '../../../../gql/Association.generated'

export default function BillingInformationsBusiness({
  getHasBillingInformaitinosErrors,
  association,
  setAssociation,
}: {
  getHasBillingInformaitinosErrors: (errors: {
    firmname: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    account: string
    iban: string
    qrIban: string
    prize: string
    associationURL: string
  }) => void
  association: UpdateAssociationIfMemberMutationVariables & {
    prizeString: string
  }
  setAssociation: React.Dispatch<
    React.SetStateAction<
      UpdateAssociationIfMemberMutationVariables & { prizeString: string, imageSrc: string }
    >
  >
}) {
  const [errors, setErrors] = useState<{
    firmname: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    account: string
    iban: string
    qrIban: string
    prize: string
    associationURL: string
  }>({
    firmname: '',
    streetNr: '',
    postCode: '',
    place: '',
    canton: '',
    account: '',
    iban: '',
    qrIban: '',
    prize: '',
    associationURL: '',
  })
  const intl = useIntl()

  function checkPrize() {
    if (
      required(association?.prizeString) &&
      verifyFloat(association?.prizeString)
    ) {
      setErrors((prev) => ({
        ...prev,
        prize: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      prize: intl.formatMessage({
        id: 'associations.billingInformations.error.prizeRequired',
        defaultMessage: 'Price is required and needs to be a decimal',
      }),
    }))
    return true
  }

  function checkFirmname() {
    if (required(association?.billingInformation?.firmName)) {
      setErrors((prev) => ({ ...prev, firmname: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      firmname: intl.formatMessage({
        id: 'associations.billingInformations.error.firmnameRequired',
        defaultMessage: 'Firmname is required',
      }),
    }))
    return true
  }

  function checkStreetNr() {
    if (required(association?.billingInformation?.streetNr)) {
      setErrors((prev) => ({ ...prev, streetNr: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      streetNr: intl.formatMessage({
        id: 'associations.billingInformations.error.streetNrRequired',
        defaultMessage: 'Street and Nr is required',
      }),
    }))
    return true
  }

  function checkPostCode() {
    if (required(association?.billingInformation?.postCode)) {
      setErrors((prev) => ({ ...prev, postCode: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      postCode: intl.formatMessage({
        id: 'associations.billingInformations.error.postCodeRequired',
        defaultMessage: 'Post code is required',
      }),
    }))
    return true
  }

  function checkPlace() {
    if (required(association?.billingInformation?.place)) {
      setErrors((prev) => ({ ...prev, place: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      place: intl.formatMessage({
        id: 'associations.billingInformations.error.placeRequired',
        defaultMessage: 'Place is required',
      }),
    }))
    return true
  }

  function checkCanton() {
    if (required(association?.billingInformation?.canton)) {
      setErrors((prev) => ({ ...prev, canton: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      canton: intl.formatMessage({
        id: 'associations.billingInformations.error.cantonRequired',
        defaultMessage: 'Canton is required',
      }),
    }))
    return true
  }

  function checkAccount() {
    if (
      association?.billingInformation?.account === '' ||
      (required(association?.billingInformation?.account) &&
        association?.billingInformation?.account &&
        verifyPostcheck(association?.billingInformation?.account))
    ) {
      setErrors((prev) => ({ ...prev, account: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      account: intl.formatMessage({
        id: 'associations.billingInformations.error.accountRequired',
        defaultMessage: 'Account is required and needs the correct form',
      }),
    }))
    return true
  }

  function checkIban() {
    if (
      required(association?.billingInformation?.iban) &&
      association?.billingInformation?.iban &&
      verifyIBAN(association?.billingInformation?.iban)
    ) {
      setErrors((prev) => ({ ...prev, iban: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      iban: intl.formatMessage({
        id: 'associations.billingInformations.error.ibanRequired',
        defaultMessage: 'IBAN is required and needs the correct form',
      }),
    }))
    return true
  }

  function checkQrIban() {
    if(association?.billingInformation?.qrIban && association?.billingInformation?.qrIban !== '') {
      if (verifyIBAN(association?.billingInformation?.qrIban)
      ) {
        setErrors((prev) => ({ ...prev, qrIban: '' }))
        return false
      }
      setErrors((prev) => ({
        ...prev,
        qrIban: intl.formatMessage({
          id: 'associations.billingInformations.error.ibanQrRequired',
          defaultMessage: 'QR IBAN is required and needs the correct form',
        }),
      }))
    }
    return true
  }

  useEffect(() => {
    checkPrize()
    checkFirmname()
    checkStreetNr()
    checkPostCode()
    checkPlace()
    checkCanton()
    checkAccount()
    checkIban()
    checkQrIban()
  }, [association])

  useEffect(() => {
    getHasBillingInformaitinosErrors(errors)
  }, [errors])

  return (
    <BillingInformations
      association={association}
      setAssociation={setAssociation}
      errors={errors}
    />
  )
}
