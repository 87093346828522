import { useIntl } from 'react-intl'

import CheckboxFilter from '@lemonbrain/components/UiElements/SearchFilters/Filters/CheckboxFilter'

export default function AssociationFilterBusiness({
  sportsEducations,
  loadingSportsEducation,
  sportsEducationFilter,
  setSportsEducationFilter,
}: {
  sportsEducations: {
    id: string
    label: string
  }[]
  loadingSportsEducation: boolean
  sportsEducationFilter: string[] | null
  setSportsEducationFilter: React.Dispatch<
    React.SetStateAction<string[] | null>
  >
}) {
  const intl = useIntl()

  return (
    <CheckboxFilter
      values={sportsEducations}
      loadingValues={loadingSportsEducation}
      filter={sportsEducationFilter}
      setFilter={setSportsEducationFilter}
      label={intl.formatMessage({
        id: 'members.sportsEducation_filter.sportsEducation',
        defaultMessage: 'Sports education',
      })}
      comment={intl.formatMessage({
        id: 'members.sportsEducation_filter.sportsEducationComment',
        defaultMessage: 'Has following sports education',
      })}
    />
  )
}
