import { useIntl } from 'react-intl'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Spinner, SIZE } from 'baseui/spinner'

export default function Newsletter({
  newsletters,
  setNewsletters,
  loadingNewsletters,
  newslettersInput,
}: {
  newsletters?: { id: string }[]
  setNewsletters: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingNewsletters: boolean
  newslettersInput: {
    id: string
    label: string
  }[]
}) {
  const intl = useIntl()

  return (
    <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.newsletter.newsletters',
            defaultMessage: 'Newsletters',
          })}
        >
          <>
            {loadingNewsletters ? (
              <Spinner $size={SIZE.small} />
            ) : (
              newslettersInput.map((item) => (
                <Checkbox
                  key={item.id}
                  value={item.id}
                  checked={
                    newsletters &&
                    !!newsletters.find((item1) => item1.id === item.id)
                  }
                  onChange={(e) => {
                    setNewsletters((prev) => {
                      let hasNewslettersNew = [...prev]
                      const value = (e.target as HTMLInputElement).value
                      const index = hasNewslettersNew.findIndex(
                        (item1) => item1.id === value
                      )
                      if (index >= 0) {
                        hasNewslettersNew.splice(index, 1)
                      } else {
                        hasNewslettersNew.push({ id: value })
                      }
                      return hasNewslettersNew
                    })
                  }}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  {item.label}
                </Checkbox>
              ))
            )}
          </>
        </FormControl>
      </Cell>
    </Grid>
  )
}
