// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type StateTypeFragmentFragment = (
  { __typename?: 'StateType' }
  & Pick<Types.StateType, 'id' | 'isTrialType' | 'requireMembership' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetStateTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetStateTypeQuery = (
  { __typename?: 'Query' }
  & { getStateType: (
    { __typename?: 'StateType' }
    & StateTypeFragmentFragment
  ) }
);

export type GetStateTypesQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetStateTypesQuery = (
  { __typename?: 'Query' }
  & { getStateTypes: (
    { __typename?: 'StateTypePagination' }
    & Pick<Types.StateTypePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'StateType' }
      & StateTypeFragmentFragment
    )> }
  ) }
);

export type GetNextStateTypesQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextStateTypesQuery = (
  { __typename?: 'Query' }
  & { getNextStateTypes: (
    { __typename?: 'StateTypePagination' }
    & Pick<Types.StateTypePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'StateType' }
      & StateTypeFragmentFragment
    )> }
  ) }
);

export type AddStateTypeMutationVariables = Types.Exact<{
  name: Types.TranslationObjectCreate;
  isTrialType: Types.Scalars['Boolean'];
  requireMembership: Types.Scalars['Boolean'];
  stateId: Types.Scalars['ID'];
}>;


export type AddStateTypeMutation = (
  { __typename?: 'Mutation' }
  & { addStateType: (
    { __typename?: 'StateType' }
    & StateTypeFragmentFragment
  ) }
);

export type UpdateStateTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.TranslationObjectUpdate;
  isTrialType?: Types.Maybe<Types.Scalars['Boolean']>;
  requireMembership?: Types.Maybe<Types.Scalars['Boolean']>;
  stateId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type UpdateStateTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateStateType: (
    { __typename?: 'StateType' }
    & StateTypeFragmentFragment
  ) }
);

export type DeleteStateTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteStateTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteStateType: (
    { __typename?: 'StateType' }
    & StateTypeFragmentFragment
  ) }
);

export const StateTypeFragmentFragmentDoc = gql`
    fragment StateTypeFragment on StateType {
  id
  name {
    de
    fr
    it
  }
  isTrialType
  requireMembership
  createdFrom
}
    `;
export const GetStateTypeDocument = gql`
    query getStateType($id: ID!) {
  getStateType(id: $id) {
    ...StateTypeFragment
  }
}
    ${StateTypeFragmentFragmentDoc}`;

/**
 * __useGetStateTypeQuery__
 *
 * To run a query within a React component, call `useGetStateTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStateTypeQuery(baseOptions: Apollo.QueryHookOptions<GetStateTypeQuery, GetStateTypeQueryVariables>) {
        return Apollo.useQuery<GetStateTypeQuery, GetStateTypeQueryVariables>(GetStateTypeDocument, baseOptions);
      }
export function useGetStateTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStateTypeQuery, GetStateTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetStateTypeQuery, GetStateTypeQueryVariables>(GetStateTypeDocument, baseOptions);
        }
export type GetStateTypeQueryHookResult = ReturnType<typeof useGetStateTypeQuery>;
export type GetStateTypeLazyQueryHookResult = ReturnType<typeof useGetStateTypeLazyQuery>;
export type GetStateTypeQueryResult = Apollo.QueryResult<GetStateTypeQuery, GetStateTypeQueryVariables>;
export const GetStateTypesDocument = gql`
    query getStateTypes($cursor: StartCursorInput!) {
  getStateTypes(cursor: $cursor) {
    items {
      ...StateTypeFragment
    }
    nextCursor
  }
}
    ${StateTypeFragmentFragmentDoc}`;

/**
 * __useGetStateTypesQuery__
 *
 * To run a query within a React component, call `useGetStateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateTypesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetStateTypesQuery(baseOptions: Apollo.QueryHookOptions<GetStateTypesQuery, GetStateTypesQueryVariables>) {
        return Apollo.useQuery<GetStateTypesQuery, GetStateTypesQueryVariables>(GetStateTypesDocument, baseOptions);
      }
export function useGetStateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStateTypesQuery, GetStateTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetStateTypesQuery, GetStateTypesQueryVariables>(GetStateTypesDocument, baseOptions);
        }
export type GetStateTypesQueryHookResult = ReturnType<typeof useGetStateTypesQuery>;
export type GetStateTypesLazyQueryHookResult = ReturnType<typeof useGetStateTypesLazyQuery>;
export type GetStateTypesQueryResult = Apollo.QueryResult<GetStateTypesQuery, GetStateTypesQueryVariables>;
export const GetNextStateTypesDocument = gql`
    query getNextStateTypes($cursor: NextCursorInput!) {
  getNextStateTypes(cursor: $cursor) {
    items {
      ...StateTypeFragment
    }
    nextCursor
  }
}
    ${StateTypeFragmentFragmentDoc}`;

/**
 * __useGetNextStateTypesQuery__
 *
 * To run a query within a React component, call `useGetNextStateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextStateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextStateTypesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextStateTypesQuery(baseOptions: Apollo.QueryHookOptions<GetNextStateTypesQuery, GetNextStateTypesQueryVariables>) {
        return Apollo.useQuery<GetNextStateTypesQuery, GetNextStateTypesQueryVariables>(GetNextStateTypesDocument, baseOptions);
      }
export function useGetNextStateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextStateTypesQuery, GetNextStateTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetNextStateTypesQuery, GetNextStateTypesQueryVariables>(GetNextStateTypesDocument, baseOptions);
        }
export type GetNextStateTypesQueryHookResult = ReturnType<typeof useGetNextStateTypesQuery>;
export type GetNextStateTypesLazyQueryHookResult = ReturnType<typeof useGetNextStateTypesLazyQuery>;
export type GetNextStateTypesQueryResult = Apollo.QueryResult<GetNextStateTypesQuery, GetNextStateTypesQueryVariables>;
export const AddStateTypeDocument = gql`
    mutation addStateType($name: TranslationObjectCreate!, $isTrialType: Boolean!, $requireMembership: Boolean!, $stateId: ID!) {
  addStateType(
    name: $name
    isTrialType: $isTrialType
    requireMembership: $requireMembership
    stateId: $stateId
  ) {
    ...StateTypeFragment
  }
}
    ${StateTypeFragmentFragmentDoc}`;
export type AddStateTypeMutationFn = Apollo.MutationFunction<AddStateTypeMutation, AddStateTypeMutationVariables>;

/**
 * __useAddStateTypeMutation__
 *
 * To run a mutation, you first call `useAddStateTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStateTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStateTypeMutation, { data, loading, error }] = useAddStateTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isTrialType: // value for 'isTrialType'
 *      requireMembership: // value for 'requireMembership'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useAddStateTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddStateTypeMutation, AddStateTypeMutationVariables>) {
        return Apollo.useMutation<AddStateTypeMutation, AddStateTypeMutationVariables>(AddStateTypeDocument, baseOptions);
      }
export type AddStateTypeMutationHookResult = ReturnType<typeof useAddStateTypeMutation>;
export type AddStateTypeMutationResult = Apollo.MutationResult<AddStateTypeMutation>;
export type AddStateTypeMutationOptions = Apollo.BaseMutationOptions<AddStateTypeMutation, AddStateTypeMutationVariables>;
export const UpdateStateTypeDocument = gql`
    mutation updateStateType($id: ID!, $name: TranslationObjectUpdate!, $isTrialType: Boolean, $requireMembership: Boolean, $stateId: ID) {
  updateStateType(
    id: $id
    name: $name
    isTrialType: $isTrialType
    requireMembership: $requireMembership
    stateId: $stateId
  ) {
    ...StateTypeFragment
  }
}
    ${StateTypeFragmentFragmentDoc}`;
export type UpdateStateTypeMutationFn = Apollo.MutationFunction<UpdateStateTypeMutation, UpdateStateTypeMutationVariables>;

/**
 * __useUpdateStateTypeMutation__
 *
 * To run a mutation, you first call `useUpdateStateTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateTypeMutation, { data, loading, error }] = useUpdateStateTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isTrialType: // value for 'isTrialType'
 *      requireMembership: // value for 'requireMembership'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useUpdateStateTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateTypeMutation, UpdateStateTypeMutationVariables>) {
        return Apollo.useMutation<UpdateStateTypeMutation, UpdateStateTypeMutationVariables>(UpdateStateTypeDocument, baseOptions);
      }
export type UpdateStateTypeMutationHookResult = ReturnType<typeof useUpdateStateTypeMutation>;
export type UpdateStateTypeMutationResult = Apollo.MutationResult<UpdateStateTypeMutation>;
export type UpdateStateTypeMutationOptions = Apollo.BaseMutationOptions<UpdateStateTypeMutation, UpdateStateTypeMutationVariables>;
export const DeleteStateTypeDocument = gql`
    mutation deleteStateType($id: ID!) {
  deleteStateType(id: $id) {
    ...StateTypeFragment
  }
}
    ${StateTypeFragmentFragmentDoc}`;
export type DeleteStateTypeMutationFn = Apollo.MutationFunction<DeleteStateTypeMutation, DeleteStateTypeMutationVariables>;

/**
 * __useDeleteStateTypeMutation__
 *
 * To run a mutation, you first call `useDeleteStateTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStateTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStateTypeMutation, { data, loading, error }] = useDeleteStateTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStateTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStateTypeMutation, DeleteStateTypeMutationVariables>) {
        return Apollo.useMutation<DeleteStateTypeMutation, DeleteStateTypeMutationVariables>(DeleteStateTypeDocument, baseOptions);
      }
export type DeleteStateTypeMutationHookResult = ReturnType<typeof useDeleteStateTypeMutation>;
export type DeleteStateTypeMutationResult = Apollo.MutationResult<DeleteStateTypeMutation>;
export type DeleteStateTypeMutationOptions = Apollo.BaseMutationOptions<DeleteStateTypeMutation, DeleteStateTypeMutationVariables>;