import React, { useEffect } from 'react';
import { useIntl } from 'react-intl'

import { debounce } from '@lemonbrain/utils'
import { useAlert } from '@lemonbrain/hooks'

import MemberSelectorBusiness from './MemberSelector.business'
import getReactApolloCients from '../../localReactApollo'
import {
  useGetPersonsQuery,
} from '../../gql/members/Person.generated'

export default function MemberSelectorData({
  value,
  setValue,
}: {
  value?: { id: string, label?: string};
  setValue: React.Dispatch<React.SetStateAction<{ id: string; label: string } | undefined>>
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [options, setOptions] = React.useState<{ label: string, id: string }[]>();
  const [searchQuery, setSearchQuery] = React.useState('')
  const [valueLoaded, setValueLoaded] = React.useState<{ id: string; label: string }>()
  const client = getReactApolloCients().getClient()
  const {
    data: dataGetPerson,
    loading: loadingGetPerson,
    error: errorGetPerson,
    refetch,
  } = useGetPersonsQuery({
    variables: {
      cursor: {
        pageSize: 10,
        filtered: getFilter(),
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  function getFilter() {
    const filter: { id: string; value: string }[] = []

    filter.push({
      id: 'query',
      value: searchQuery,
    })

    if (value?.id) {
      filter.push({
        id: 'authIds',
        value: value.id,
      })
    }

    return filter
  }


  const handleInputChange = debounce(function (term: string) {
    if (term) {
      setSearchQuery(term.toLowerCase());
    } else {
      setSearchQuery('')
    }
  }, 400);

  useEffect(() => {
    if (searchQuery) {
      setValue(undefined)
      refetch({
        cursor: {
          pageSize: 10,
          filtered: getFilter(),
        },
      })
    } else {
      setValue(undefined)
      setValueLoaded(undefined)
    }
  }, [searchQuery])

  useEffect(() => {
    if (dataGetPerson) {
      setOptions(dataGetPerson.getPersons.items.map(person => ({
        label: parseInt(person.authId) > 0 ? `${person.addresses[0]?.firstName} ${person.addresses[0]?.lastName} (${person.email})` : `Kein Login!! ${person.addresses[0]?.firstName} ${person.addresses[0]?.lastName} (${person.email})`,
        id: person.authId
      })))
      if(value?.id) {
        const person = dataGetPerson.getPersons.items.find(person => person.authId === value.id)
        if(person) {
          setValueLoaded({
            label: `${person.addresses[0].firstName} ${person.addresses[0].lastName} (${person.email})`,
            id: person.authId
          })
        }
      }
    }
  }, [dataGetPerson])

  useEffect(() => {
    if (errorGetPerson) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.memberSelector.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.memberSelector.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorGetPerson?.message
      )
    }
  }, [errorGetPerson])

  useEffect(() => {
    if (value && value.label) {
      setValueLoaded({id: value.id, label: value.label})
    }
  }, [value])

  return <MemberSelectorBusiness
    isLoading={loadingGetPerson}
    options={options}
    setValue={setValue}
    value={valueLoaded}
    handleInputChange={handleInputChange}
  />
}
