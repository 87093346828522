import { useIntl } from 'react-intl'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Spinner, SIZE } from 'baseui/spinner'

export default function SportsEducation({
  sportsEducation,
  setSportsEducation,
  loadingSportsEducations,
  sportsEducations,
  errors,
}: {
  sportsEducation?: { id: string }[]
  setSportsEducation: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingSportsEducations: boolean
  sportsEducations: {
    id: string
    label: string
  }[]
  errors: {
    sportsEducation: string;
}
}) {
  const intl = useIntl()

  return (
    <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.sportsEducation.hasSportsEducation',
            defaultMessage: 'Has sports education',
          })}
          error={errors.sportsEducation}
        >
          {loadingSportsEducations ? (
                  <Spinner $size={SIZE.small} />
          ) : (
            <>
              {sportsEducations.map((item) => (
                <Checkbox
                  key={item.id}
                  value={item.id}
                  checked={
                    sportsEducation &&
                    !!sportsEducation.find((item1) => item1.id === item.id)
                  }
                  onChange={(e) => {
                    setSportsEducation((prev) => {
                      let hasSportsEducationNew = [...prev]
                      const value = (e.target as HTMLInputElement).value
                      const index = hasSportsEducationNew.findIndex(
                        (item1) => item1.id === value
                      )
                      if (index >= 0) {
                        hasSportsEducationNew.splice(index, 1)
                      } else {
                        hasSportsEducationNew.push({ id: value })
                      }
                      return hasSportsEducationNew
                    })
                  }}
                  labelPlacement={LABEL_PLACEMENT.right}
                  error={errors.sportsEducation !== ''}
                >
                  {item.label}
                </Checkbox>
              ))}
            </>
          )}
        </FormControl>
      </Cell>
    </Grid>
  )
}
