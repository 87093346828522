import { useState, useEffect } from 'react'

export function useLocalStorage<T>(key: string, initialValue: T | null = null): [T, React.Dispatch<React.SetStateAction<T>>] {
    const initial = (!window.localStorage.getItem(key) && initialValue !== null) ? initialValue : JSON.parse(window.localStorage.getItem(key) as string) as unknown as T
    const [localStorage, setLocalStorage] = useState<T>(initial)

    useEffect(() => {
        if (!localStorage) {
            window.localStorage.removeItem(key)
        } else {
            window.localStorage.setItem(key, JSON.stringify(localStorage))
        }
    }, [localStorage, key])

    return [
        localStorage, 
        setLocalStorage,
    ]
}