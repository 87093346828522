import { useEffect } from 'react'
import { ModalHeader, ModalBody } from 'baseui/modal'
import { RadioGroup, Radio, ALIGN } from 'baseui/radio'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Spinner, SIZE } from 'baseui/spinner'
import { Block } from 'baseui/block'

export default function RadioFilter({
  values,
  loadingValues,
  filter,
  setFilter,
  label,
  comment,
}: {
  values: {
    id: string
    label: string
  }[]
  loadingValues: boolean
  filter: string | null
  setFilter: React.Dispatch<React.SetStateAction<string | null>>
  label: string
  comment: string
}) {
  useEffect(() => {
    if (!filter && setFilter) {
      setFilter('')
    }
  }, [setFilter])

  return (
    <>
      <ModalHeader>{label}</ModalHeader>
      <ModalBody>
        <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
          <Cell span={[12, 12, 12]}>
            <FormControl label={comment}>
              {loadingValues ? (
                <Block
                  overrides={{
                    Block: {
                      style: {
                        width: '100%',
                        display: 'block',
                        margin: '15px auto',
                      },
                    },
                  }}
                >
                  <Spinner $size={SIZE.small} />
                </Block>
              ) : (
                <RadioGroup
                  value={filter || ''}
                  onChange={(e) => {
                    setFilter((e.target as HTMLInputElement).value)
                  }}
                  name='radio_filter'
                  align={ALIGN.vertical}
                >
                  {values.map((item) => (
                    <Radio key={item.id} value={item.id}>
                      {item.label}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            </FormControl>
          </Cell>
        </Grid>
      </ModalBody>
    </>
  )
}
