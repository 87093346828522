import { useState, useEffect } from 'react'

import ChangePassword from './ChangePassword'
import { useIntl } from 'react-intl'

import { useCaptcha, useAlert } from '@lemonbrain/hooks'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'

export default function ChangePasswordBusiness ({
    changePassword,
    goToSignIn,
    goToForgotPassword,
}: {
    changePassword: (password: string, reCaptchaToken: string) => Promise<"is-valid">,
    goToForgotPassword: () => void,
    goToSignIn: () => void,
}) {
    const setAlert = useAlert()
    const [isLoading, setIsLoading] = useState(false)
    const intl = useIntl()
    const { isLoaded, isError, getCaptchaToken } = useCaptcha()
    const [goToLoginAuto, setGoToLogin] = useState(false)
    const [goToForgotPasswordAuto, setGoToForgotPasswort] = useState(false)

    async function onSubmit (password: string) {
        let state = false
        setIsLoading(true)

        let newState = ''
        try {
            const reCaptchaToken = await getCaptchaToken('changePassword')
            newState = await changePassword(password, reCaptchaToken)
        } catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.changePassword.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.changePassword.error.tokenNoLongerValid', defaultMessage: 'Your token is no longer valid. Please resend your passwort reset E-Mail' }),
                style: 'error'
            })
        }
        state = newState === 'is-valid'
        if (state) {
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.changePassword.changed', defaultMessage: 'Changed' }),
                label: intl.formatMessage({ id: 'auth.changePassword.changed.success', defaultMessage: 'Your password is changed' }),
                style: 'success'
            })
        }
        setIsLoading(false)
        if (state) {
            setGoToLogin(true)
        } else {
            setGoToForgotPasswort(true)
        }
        return state
    }

    if (isError) {
        setAlert && setAlert({
            title: intl.formatMessage({ id: 'auth.changePassword.error', defaultMessage: 'Error' }),
            label: intl.formatMessage({ id: 'auth.changePassword.error.googleRecaptchaCouldNotBeLoaded', defaultMessage: 'Google ReCaptcha could not be loaded' }),
            style: 'error'
        })
    }

    useEffect(() => {
        if (!!goToLoginAuto) {
            goToSignIn()
        }
    }, [goToLoginAuto, goToSignIn])

    useEffect(() => {
        if (!!goToForgotPasswordAuto) {
            goToForgotPassword()
        }
    }, [goToForgotPasswordAuto, goToForgotPassword])

    return isLoaded ?
    (
        <ChangePassword
            onSubmit={onSubmit}
            onForgotPasswordClick={goToForgotPassword}
            isLoading={isLoading}
        />
    ) : (<Loader />)
}
