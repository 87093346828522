import { Fragment, useState } from 'react'
import { Block } from 'baseui/block'
import { FormControl } from 'baseui/form-control'
import { useIntl, FormattedMessage } from 'react-intl'
import { AiFillCloseSquare } from 'react-icons/ai'
import { IoIosEye } from 'react-icons/io'
import { useStyletron } from 'baseui'
import { StyledTable, StyledBodyCell } from 'baseui/table-grid'
import { Checkbox } from 'baseui/checkbox'

import { useAppState } from '@lemonbrain/contexts/PageWrapper'
import { StyledTableHeadAlt } from '@lemonbrain/components/PageStyles/Apps.styled'
import IconButton from '@lemonbrain/components/UiElements/IconButton/IconButton'
import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'
export type RowDataT = {
  name: {
    de: string
    fr: string
    it: string
  }
  isTrialType: boolean
  requireMembership: boolean
  createdFrom: string
}

export default function StateTypeTable({
    rows,
    editRow,
    removeRow,
    isLoading,
}: {
    rows: {
        id: string
        data: RowDataT
    }[],
    editRow: (id: string) => void
    removeRow: (id: string) => void
    isLoading: boolean
}) {
  const { components } = useAppState()
  const [css] = useStyletron()
  const intl = useIntl()
  const [deleteId, setDeleteId] = useState<string>('')
  const DisplayNameData = components && components['DisplayNameData']

  return (
    <>
      <SimpleModal<string>
        isOpen={!!deleteId}
        onClose={() => setDeleteId('')}
        headerLabel={intl.formatMessage({
          id: 'settings.stateTypeTable.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'settings.stateTypeTable.deleteConfirm',
          defaultMessage: 'Are you sure to delete this state?',
        })}
        data={deleteId}
        onConfirm={(deleteId: string) => {
          removeRow(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'settings.stateTypeTable.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>
      <FormControl
        label={intl.formatMessage({
          id: 'settings.stateTypeTable.stateTypes',
          defaultMessage: 'State Types',
        })}
        caption={intl.formatMessage({
          id: 'settings.stateTypeTable.editOrDeleteAState',
          defaultMessage: 'Edit or delete a state',
        })}
      >
        <>
          {rows.length > 0 ? (
            <>
              <StyledTable $gridTemplateColumns='max-content max-content max-content max-content max-content max-content max-content'>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.stateTypeTable.action',
                    defaultMessage: 'Action',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.stateTypeTable.de',
                    defaultMessage: 'Label (German)',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.stateTypeTable.fr',
                    defaultMessage: 'Label (French)',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.stateTypeTable.it',
                    defaultMessage: 'Label (Italien)',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.stateTypeTable.isTrialType',
                    defaultMessage: 'Is Trial',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.stateTypeTable.requireMembership',
                    defaultMessage: 'Requires to set a membership',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.stateTypeTable.createdFrom',
                    defaultMessage: 'Created from',
                  })}
                </StyledTableHeadAlt>
                {rows.map((item, index) => {
                  const striped = index % 2 === 0
                  return (
                    <Fragment key={index}>
                      <StyledBodyCell $striped={striped}>
                        <IconButton onClick={() => editRow(item.id)}>
                          <IoIosEye />
                        </IconButton>
                        <IconButton onClick={() => setDeleteId(item.id)}>
                          <AiFillCloseSquare />
                        </IconButton>
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.name.de}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.name.fr}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.name.it}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.isTrialType ? (
                          <Checkbox checked disabled />
                        ) : (
                          <Checkbox disabled />
                        )}
                        {item.data.isTrialType}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.requireMembership ? (
                          <Checkbox checked disabled />
                        ) : (
                          <Checkbox disabled />
                        )}
                        {item.data.requireMembership}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {DisplayNameData && (
                          //@ts-ignores
                          <DisplayNameData userId={item.data.createdFrom} />
                        )}
                      </StyledBodyCell>
                    </Fragment>
                  )
                })}
              </StyledTable>
            </>
          ) : (
            <Block
              as='span'
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      color: $theme.colors.primaryA,
                    }
                  },
                },
              }}
            >
              {intl.formatMessage({
                id: 'settings.stateTypeTable.noEntries',
                defaultMessage: 'There are no entries',
              })}
            </Block>
          )}
        </>
      </FormControl>
      {isLoading && (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <Loader />
          <Block
            as='p'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    textAlign: 'center',
                    marginTop: '15px',
                    color: $theme.colors.primaryA,
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='settings.stateTypeTable.fetchingMore'
              defaultMessage={'Fetching more list items...'}
            />
          </Block>
        </div>
      )}
    </>
  )
}
