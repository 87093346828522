import InvoicesTable, { RowDataT } from './InvoicesTable'

import { useAppState } from '@lemonbrain/contexts'

export default function InvoicesTableBusiness({
  data,
  onFetchMoreData,
  onRefetchData,
  loading,
  pageCount,
  pageSize,
  setPageSize,
  goToInvoice,
  deleteInvoice,
  setSelectedInvoices,
}: {
  data: RowDataT[]
  onFetchMoreData: () => void
  onRefetchData: () => void
  loading: boolean
  pageCount: number
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  goToInvoice: (id: string | null) => void
  deleteInvoice?:(id: string) => void
  setSelectedInvoices: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const { user } = useAppState()

  return (
    <InvoicesTable
      data={data}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSizeInput={pageSize}
      setPageSizeInput={setPageSize}
      goToInvoice={goToInvoice}
      removeRow={deleteInvoice}
      user={user}
      setSelectedInvoices={setSelectedInvoices}
    />
  )
}
