import { Fragment, useState } from 'react'
import { Block } from 'baseui/block'
import { FormControl } from 'baseui/form-control'
import { useIntl } from 'react-intl'
import { AiFillCloseSquare } from 'react-icons/ai'
import { useStyletron } from 'baseui'
import { StyledTable, StyledBodyCell } from 'baseui/table-grid'

import { StyledTableHeadAlt } from '@lemonbrain/components/PageStyles/Apps.styled'
import IconButton from '@lemonbrain/components/UiElements/IconButton/IconButton'
import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'


export default function MembershipMailingTable({
  isLoading,
  rows,
  removeRow,
}: {
  isLoading: boolean
  rows: {
    id: string
    data: { email: string }
  }[]
  removeRow: (id: string) => void
}) {
  const [css] = useStyletron()
  const intl = useIntl()
  const [deleteId, setDeleteId] = useState<string | undefined>()

  return (
    <>
      <SimpleModal
        isOpen={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'settings.membershipMailingTable.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'settings.membershipMailingTable.deleteConfirm',
          defaultMessage: 'Are you sure to delete this entry?',
        })}
        data={deleteId || ''}
        onConfirm={(deleteId: string) => {
          removeRow(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'settings.membershipMailingTable.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>

      <FormControl
        label={intl.formatMessage({
          id: 'settings.membershipMailingTable.signupRecipients',
          defaultMessage: 'Receipients of new signups',
        })}
      >
        <>
          {rows.length > 0 ? (
            <>
              <StyledTable $gridTemplateColumns='max-content 1fr'>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.membershipMailingTable.delete',
                    defaultMessage: 'Delete',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.membershipMailingTable.email',
                    defaultMessage: 'E-Mail',
                  })}
                </StyledTableHeadAlt>
                {rows.map((item, index) => {
                  const striped = index % 2 === 0
                  return (
                    <Fragment key={index}>
                      <StyledBodyCell $striped={striped}>
                        <IconButton onClick={() => setDeleteId(item.id)}>
                          <AiFillCloseSquare />
                        </IconButton>
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.email}
                      </StyledBodyCell>
                    </Fragment>
                  )
                })}
              </StyledTable>
            </>
          ) : (
            <Block
              as='span'
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      color: $theme.colors.primaryA,
                    }
                  },
                },
              }}
            >
              {intl.formatMessage({
                id: 'settings.membershipMailingTable.noEntries',
                defaultMessage: 'There are no entries',
              })}
            </Block>
          )}
        </>
      </FormControl>
      {(isLoading) && (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <Loader />
        </div>
      )}
    </>
  )
}
