import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import ToggleAllBusiness from './ToggleAll.business'
import getReactApolloCients from '../../../../localReactApollo'

import { useGetPersonsIdLazyQuery } from '../../../../gql/members/Person.generated'

export default function ToggleAllData({
  searchQuery,
  associationFilter,
  workLevelFilter,
  sportsEducationFilter,
  stateFilter,
  stateTypeFilter,
  membershipFilter,
  selectedPerson,
  setSelectedPerson,
}: {
  searchQuery: string
  associationFilter: string[] | null
  workLevelFilter: string[] | null
  sportsEducationFilter: string[] | null
  stateFilter: string | null
  stateTypeFilter: string | null
  membershipFilter: string | null
  selectedPerson: string[]
  setSelectedPerson: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const [
    getPersonsId,
    { data: dataGetPerson, loading: loadingGetPerson, error: errorGetPerson },
  ] = useGetPersonsIdLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  function getFilter() {
    const filter: { id: string; value: string }[] = []

    filter.push({
      id: 'query',
      value: searchQuery,
    })

    if (associationFilter) {
      filter.push({
        id: 'association',
        value: associationFilter.join(','),
      })
    }

    if (workLevelFilter) {
      filter.push({
        id: 'workLevel',
        value: workLevelFilter.join(','),
      })
    }

    if (sportsEducationFilter) {
      filter.push({
        id: 'sportsEducation',
        value: sportsEducationFilter.join(','),
      })
    }

    if (stateFilter) {
      filter.push({
        id: 'state',
        value: stateFilter,
      })
    }

    if (stateTypeFilter) {
      filter.push({
        id: 'stateType',
        value: stateTypeFilter,
      })
    }

    if (membershipFilter) {
      filter.push({
        id: 'membership',
        value: membershipFilter,
      })
    }
    return filter
  }

  const toggleAll = function () {
    if (selectedPerson.length > 0) {
      setSelectedPerson([])
    } else {
      getPersonsId({
        variables: {
          cursor: {
            pageSize: 9999,
            filtered: getFilter(),
          },
        },
      })
    }
  }

  useEffect(() => {
    if (
      !loadingGetPerson &&
      dataGetPerson &&
      dataGetPerson?.getPersons.items.length > 0
    ) {
      setSelectedPerson(dataGetPerson?.getPersons.items.map((item) => item.id))
    }
  }, [dataGetPerson])

  useEffect(() => {
    if (errorGetPerson) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.toggleAll.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.toggleAll.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorGetPerson?.message)
    }
  }, [errorGetPerson])

  return (
    <ToggleAllBusiness
      loading={loadingGetPerson}
      selectedPerson={selectedPerson}
      toggleAll={toggleAll}
    />
  )
}
