import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import AssociationTypeEditBusiness from './AssociationTypeEdit.business'
import {
  useGetAssociationTypeNoPersonLazyQuery,
  useUpdateAssociationTypeMutation,
  useAddAssociationTypeMutation,
} from '../../../gql/AssociationType.generated'
import getReactApolloCients from '../../../localReactApollo'


export default function AssociationTypeEditData({
  associationTypeId,
  setAssociationTypeId,
}: {
  associationTypeId: string | null | undefined
  setAssociationTypeId: (associationTypeId: string) => void
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [values, setValues] = useState<{
    isDefaultType: boolean
    userGroup: string
    de: string
    fr: string
    it: string
  }>({
    isDefaultType: false,
    userGroup: '',
    de: '',
    fr: '',
    it: '',
  })
  const client = getReactApolloCients().getClient()
  const [
    getAssociationTypeQuery,
    { data, loading, error: queryError },
  ] = useGetAssociationTypeNoPersonLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateAssociationTypeMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateAssociationTypeMutation({
    //@ts-ignore
    client,
  })
  const [
    addAssociaionTypeMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddAssociationTypeMutation({
    //@ts-ignore
    client,
  })

  async function updateAssociationTypeEntry({
    id,
    isDefaultType,
    userGroup,
    de,
    fr,
    it,
  }: {
    id: string
    isDefaultType: boolean
    userGroup: string
    de: string
    fr: string
    it: string
  }) {
      await updateAssociationTypeMutation({
        variables: {
          id,
          isDefaultType,
          userGroup,
          name: {
            de,
            fr,
            it,
          },
        },
      })
  }

  async function createAssociationTypeEntry({
    isDefaultType,
    userGroup,
    de,
    fr,
    it,
  }: {
    isDefaultType: boolean
    userGroup: string
    de: string
    fr: string
    it: string
  }) {
    await addAssociaionTypeMutation({
      variables: {
        isDefaultType,
        userGroup,
        name: {
          de,
          fr,
          it,
        },
      },
    })
  }

  function removeAssociationTypeId() {
    setAssociationTypeId('')
  }

  useEffect(() => {
    if (associationTypeId) {
      getAssociationTypeQuery({
        variables: {
          id: associationTypeId,
        },
      })
    }
  }, [associationTypeId])

  useEffect(() => {
    if (data?.getAssociationTypeNoPerson) {
      setValues({
        isDefaultType: data.getAssociationTypeNoPerson.isDefaultType,
        userGroup: data.getAssociationTypeNoPerson.userGroup,
        de: data.getAssociationTypeNoPerson.name.de,
        fr: data.getAssociationTypeNoPerson.name.fr,
        it: data.getAssociationTypeNoPerson.name.it,
      })
    }
  }, [data])

  useEffect(() => {
    if (queryError || queryChangeError || queryAddError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.associationTypeEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.associationTypeEdit.errorMessage',
            defaultMessage: 'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError, queryChangeError, queryAddError])

  return (
    <AssociationTypeEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      removeAssociationTypeId={removeAssociationTypeId}
      associationTypeId={associationTypeId}
      updateAssociationTypeEntry={updateAssociationTypeEntry}
      createAssociationTypeEntry={createAssociationTypeEntry}
    />
  )
}
