import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import StateFunctionBusiness from './StateFunction.business'

export default function StateFunctionData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { stateFunctionId } = useParams<{ stateFunctionId: string }>()
  const { language } = useParams<{ language: string }>()

  function setStateFunctionId(stateFunctionId: string | undefined) {
    if (stateFunctionId && stateFunctionId !== '') {
      pushPath(`/${language}/settings/state-function/${stateFunctionId}`)
    } else {
      pushPath(`/${language}/settings/state-function`)
    }
  }

  return (
    <StateFunctionBusiness
      subNav={subNav}
      stateFunctionId={stateFunctionId}
      setStateFunctionId={setStateFunctionId}
    />
  )
}
