import React, { useMemo, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useStyletron } from 'baseui'
import { useTable, usePagination } from 'react-table'
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Button } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { Delete, CheckIndeterminate, Plus } from 'baseui/icon'
import { Tag, VARIANT } from 'baseui/tag'
import { Select } from 'baseui/select'

import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'
import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'
import InvoiceIcon from '../../../icons/InvoiceIcon'

export type RowDataT = {
  id: string
  invoiceNumber: string
  from: string
  to: string
  date: string
  total: string
  sendAt: string
  payDate: string
  payAmount: string
  canceledAt: string
  createdFrom: string
}

export default function InvoicesTable({
  data,
  onFetchMoreData,
  onRefetchData,
  loading,
  pageCount: controlledPageCount,
  pageSizeInput,
  setPageSizeInput,
  goToInvoice,
  removeRow,
  user,
  setSelectedInvoices,
}: {
  data: RowDataT[]
  onFetchMoreData: () => void
  onRefetchData: () => void
  loading: boolean
  pageCount: number
  pageSizeInput: number
  setPageSizeInput: React.Dispatch<React.SetStateAction<number>>
  goToInvoice: (id: string | null) => void
  removeRow?: (id: string) => void
  user: UserType | undefined
  setSelectedInvoices: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const intl = useIntl()
  const [css] = useStyletron()
  const [deleteId, setDeleteId] = useState<string | undefined>()

  const columns = useMemo(() => {
    const columns = [
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.invoiceNumber',
          defaultMessage: 'Invoice number',
        }),
        accessor: 'invoiceNumber', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.from',
          defaultMessage: 'From',
        }),
        accessor: 'from',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.to',
          defaultMessage: 'To',
        }),
        accessor: 'to',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.date',
          defaultMessage: 'Date',
        }),
        accessor: 'date',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.total',
          defaultMessage: 'Total',
        }),
        accessor: 'total',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.sendAt',
          defaultMessage: 'Send at',
        }),
        accessor: 'sendAt',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.payDate',
          defaultMessage: 'Pay date',
        }),
        accessor: 'payDate',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.payAmount',
          defaultMessage: 'Pay Amount',
        }),
        accessor: 'payAmount',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.invoicesTable.canceledAt',
          defaultMessage: 'Canceled at',
        }),
        accessor: 'canceledAt',
      },
    ]

    return columns
  }, [])

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    //@ts-ignore
    page,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    setPageSize,
    //@ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      //@ts-ignore
      columns,
      data,
      initialState: {
        //@ts-ignore
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      pageSize: pageSizeInput,
    },
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'actions',
          Header: () => (
            <div>
              <FormattedMessage
                id='invoices.invoicesTable.actions'
                defaultMessage={'Actions'}
              />
            </div>
          ),
          //@ts-ignore
          Cell: ({ row }) => (
            <ButtonGroup>
            <Button onClick={() => goToInvoice(row.original.id)}>
              <InvoiceIcon />
            </Button>
              {user &&
                isInGroupOrAdmin(user, [
                  process.env.REACT_APP_GROUP_MANAGERS || '',
                  process.env.REACT_APP_GROUP_ADMINS || '',
                ]) &&
                removeRow &&
                row.original.canceledAt === '' && (
                  <Button onClick={() => setDeleteId(row.original.id)}>
                    <Delete />
                  </Button>
                )}
                {
          //@ts-ignore
          row.original.selected ? (
                  <Button
                    onClick={() =>
                      setSelectedInvoices((prev) => {
                        prev.splice(prev.indexOf(row.original.id), 1);
                        return [...prev]
                      })
                    }
                  >
                    <CheckIndeterminate />
                  </Button>
                ) : (
                  <Button
                    onClick={() => setSelectedInvoices((prev) => [...prev, row.original.id])}
                  >
                    <Plus />
                  </Button>
                )}
            </ButtonGroup>
          ),
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    if (pageSize !== pageSizeInput) {
      setPageSize(pageSizeInput)
      onRefetchData()
    } else {
      if (pageIndex !== controlledPageCount) {
        onFetchMoreData()
      }
    }
  }, [pageIndex, pageSizeInput])
  return (
    <>
      <SimpleModal<string>
        isOpen={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'associations.associationsTable.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'associations.associationsTable.deleteConfirm',
          defaultMessage: 'Are you sure to delete this entry?',
        })}
        data={deleteId || ''}
        onConfirm={(deleteId: string) => {
          removeRow && removeRow(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'associations.associationsTable.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>
      <StyledTable
        role='grid'
        $gridTemplateColumns={`repeat(${columns.length + 1},1fr)`}
        {...getTableProps()}
      >
        {headerGroups.map((headerGroup, i) => (
          <React.Fragment key={i}>
            {
          //@ts-ignore
          headerGroup.headers.map((column, j) => (
              <StyledHeadCell {...column.getHeaderProps()}>
                {column.render('Header')}
                <span>
                  {
          //@ts-ignore
          column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </StyledHeadCell>
            ))}
          </React.Fragment>
        ))}
        {
        //@ts-ignore
        page.map((row, i) => {
          prepareRow(row)
          const isStriked = row.original.canceledAt !== ''
          return (
            <React.Fragment key={i}>
            {
              //@ts-ignore
              row.cells.map((cell, j) => {
                return (
                  <StyledBodyCell
                    key={i.toString() + j.toString()}
                    {...cell.getCellProps()}
                    $striped={true}
                  >
                    {isStriked && cell.column.id !== 'canceledAt' ?
                    (
                      //@ts-ignore
                      <strike>{cell.render('Cell')}</strike>
                    ) : (
                      cell.render('Cell')
                    )}
                  </StyledBodyCell>
                )
              })}
            </React.Fragment>
          )
        })}
        {loading ? (
          // Use our custom loading state to show a loading indicator
          //@ts-ignore
          <StyledBodyCell colSpan='10000'>
            <FormattedMessage
              id='associations.associationsTable.loading'
              defaultMessage={'Loading...'}
            />
          </StyledBodyCell>
        ) : (
          //@ts-ignore
          <StyledBodyCell colSpan='10000'>
            <FormattedMessage
              id='associations.associationsTable.results_count'
              defaultMessage='Showing {pageLength} results'
              values={{
                pageLength: page.length,
              }}
            />
          </StyledBodyCell>
        )}
      </StyledTable>
      <div
        className={css({
          paddingTop: '1rem',
        })}
      >
        <Grid align={ALIGNMENT.center}>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                <FormattedMessage
                  id='associations.associationsTable.load_mores'
                  defaultMessage={'Load mores'}
                />
              </Button>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Tag closeable={false} variant={VARIANT.light} kind='neutral'>
                <FormattedMessage
                  id='associations.associationsTable.page_count'
                  defaultMessage='Page {currentPage}'
                  values={{
                    currentPage: pageIndex + 1,
                  }}
                />
              </Tag>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Select
                clearable={false}
                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                  id: intl.formatMessage(
                    {
                      id: 'associations.associationsTable.page_size_selection',
                      defaultMessage: 'Show {pageSize} Items per Page',
                    },
                    {
                      pageSize,
                    }
                  ),
                  pageSize,
                }))}
                labelKey='id'
                valueKey='pageSize'
                onChange={({ value }) => {
                  setPageSizeInput(Number(value[0].pageSize))
                }}
                value={[{ id: pageSize.toString(), pageSize }]}
              />
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  )
}
