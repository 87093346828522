import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'

import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'

import ExportFile from './Export/Export.file'
import MailData from './Mail/Mail.data'
import MailIfManagesData from './Mail/MailIfManages.data'
import ToggleAllData from './ToggleAll/ToggleAll.data'
import NewsletterData from './Newsletter/Newsletter.data'
import SelectedItems from './SelectedItems'

export default function MembersFunctions({
  searchQuery,
  associationFilter,
  workLevelFilter,
  sportsEducationFilter,
  stateFilter,
  stateTypeFilter,
  membershipFilter,
  selectedPerson,
  setSelectedPerson,
  user,
}: {
  searchQuery: string
  associationFilter: string[] | null
  workLevelFilter: string[] | null
  sportsEducationFilter: string[] | null
  stateFilter: string | null
  stateTypeFilter: string | null
  membershipFilter: string | null
  selectedPerson: string[]
  setSelectedPerson: React.Dispatch<React.SetStateAction<string[]>>
  user: UserType | undefined
}) {
  return (
    <Block
      overrides={{
        Block: {
          style: () => {
            return {
              paddingBottom: '15px',
            }
          },
        },
      }}
    >
      <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
        <Cell span={[12, 12, 1]}>
          <ToggleAllData
            searchQuery={searchQuery}
            associationFilter={associationFilter}
            workLevelFilter={workLevelFilter}
            sportsEducationFilter={sportsEducationFilter}
            stateFilter={stateFilter}
            stateTypeFilter={stateTypeFilter}
            membershipFilter={membershipFilter}
            selectedPerson={selectedPerson}
            setSelectedPerson={setSelectedPerson}
          />
        </Cell>
        <Cell span={[12, 12, 1]}>
          {user &&
          isInGroupOrAdmin(user, [
            process.env.REACT_APP_GROUP_MANAGERS || '',
            process.env.REACT_APP_GROUP_ADMINS || '',
          ]) && (
            <NewsletterData />
          )}
        </Cell>
        <Cell span={[12, 12, 6]}></Cell>
        <Cell span={[12, 12, 1]}>
          <ExportFile selectedPerson={selectedPerson} user={user} />
        </Cell>
        <Cell span={[12, 12, 2]}>
          {user &&
          isInGroupOrAdmin(user, [
            process.env.REACT_APP_GROUP_MANAGERS || '',
            process.env.REACT_APP_GROUP_ADMINS || '',
          ]) ? (
            <MailData selectedPerson={selectedPerson} />
          ) : (
            <MailIfManagesData selectedPerson={selectedPerson} />
          )}
        </Cell>
        <Cell span={[12, 12, 1]}>
          <SelectedItems selectedPerson={selectedPerson} />
        </Cell>
      </Grid>
    </Block>
  )
}
