import React, { useEffect } from 'react'
import { Block } from 'baseui/block'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader';

export default function CheckValidationEmail ({
    onSubmit,
    isLoading,
}: {
    onSubmit: () => Promise<boolean>,
    isLoading: boolean,
}) {
    useEffect(() => {
        try {
            (async function () {
                await onSubmit()
            })()
        } catch (e) {

        }
    }, [])

    return (
        <Container>
            <Block
                overrides={{
                    Block: {
                        style: {
                            minHeight: 'calc(100vh - 213px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    },
                }}
            >
                <Block
                    as="h1"
                    overrides={{
                        Block: {
                            style: ({ $theme }) => {
                                return {
                                    ...$theme.typography.font1250,
                                    color: '#fff',
                                    fontWeight: 700,
                                    marginBottom: '30px',
                                }
                            },
                        },
                    }}
                >
                    <FormattedMessage
                        id='auth.validateEmail.validateEmail'
                        defaultMessage={'Validate Email'}
                    />
                </Block>

                {isLoading && (<Loader />)}
            </Block>
        </Container>
    )
}
