import SvssFunction from './SvssFunction'

export default function SvssFunctionBusiness({
  svssFunctions,
  setSvssFunctions,
  loadingSvssFunctions,
  svssFunctionsInput,
}: {
  svssFunctions?: { id: string }[]
  setSvssFunctions: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingSvssFunctions: boolean
  svssFunctionsInput: {
    id: string
    label: string
  }[]
}) {
  return (
    <SvssFunction
      svssFunctions={svssFunctions}
      setSvssFunctions={setSvssFunctions}
      loadingSvssFunctions={loadingSvssFunctions}
      svssFunctionsInput={svssFunctionsInput}
    />
  )
}
