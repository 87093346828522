import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import StateFunctionEdit from './StateFunctionEdit'

import { useAppState } from '@lemonbrain/contexts'
import {
  prepStringWN
} from '@lemonbrain/utils'
import { useAlert } from '@lemonbrain/hooks'

export default function StateFunctionEditBusiness({
  values,
  queryError,
  isLoading,
  removeStateFunctionId,
  stateFunctionId,
  updateStateFunctionEntry,
  createStateFunctionEntry,
  setValues,
}: {
  values: {
    de: string | undefined
    it: string | undefined
    fr: string | undefined
  }
  queryError: any
  isLoading: boolean
  removeStateFunctionId: () => void
  stateFunctionId?: string | null | undefined
  updateStateFunctionEntry: (data: {
    stateFunctionId: string
    de: string
    fr: string
    it: string
  }) => void
  createStateFunctionEntry: (data: {
    de: string
    fr: string
    it: string
  }) => void
  setValues: React.Dispatch<
    React.SetStateAction<{
      de: string | undefined
      fr: string | undefined
      it: string | undefined
    }>
  >
}) {
  const intl = useIntl()
  const { user } = useAppState()
  const setAlert = useAlert()

  const updateEntry = async ({
    stateFunctionId,
    de,
    fr,
    it,
  }: {
    stateFunctionId: string
    de: string
    fr: string
    it: string
  }) => {
    if (user && user.uID && stateFunctionId) {
      try {
        await updateStateFunctionEntry({
          stateFunctionId: prepStringWN(stateFunctionId) || stateFunctionId,
            de: prepStringWN(de) || de,
            fr: prepStringWN(fr) || fr,
            it: prepStringWN(it) || it,
        })
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateFunctionEdit.saved',
              defaultMessage: 'Saved',
            }),
            label: intl.formatMessage({
              id: 'settings.stateFunctionEdit.saved.updated',
              defaultMessage: 'Entry is updated',
            }),
            style: 'success',
          })
        updateCancel()
      } catch (e) {
        console.error(e)
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateFunctionEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.stateFunctionEdit.error.thereWasAnServerError',
              defaultMessage: 'There was an server error, try again',
            }),
            style: 'error',
          })
      }
    } else {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateFunctionEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateFunctionEdit.error.notLoggedIn',
            defaultMessage: 'You are not anymore logged in',
          }),
          style: 'error',
        })
    }
  }

  const createEntry = async ({
    de,
    fr,
    it,
  }: {
    de: string
    fr: string
    it: string
  }) => {
    if (user && user.uID) {
      try {
        await createStateFunctionEntry({
            de: prepStringWN(de) || de,
            fr: prepStringWN(fr) || fr,
            it: prepStringWN(it) || it,
        })
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateFunctionEdit.saved',
              defaultMessage: 'Saved',
            }),
            label: intl.formatMessage({
              id: 'settings.stateFunctionEdit.saved.created',
              defaultMessage: 'Entry is created',
            }),
            style: 'success',
          })
        updateCancel()
      } catch (e) {
        console.error(e)
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.stateFunctionEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.stateFunctionEdit.error.thereWasAnServerError',
              defaultMessage: 'There was an server error, try again',
            }),
            style: 'error',
          })
      }
    } else {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateFunctionEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateFunctionEdit.error.notLoggedIn',
            defaultMessage: 'You are not anymore logged in',
          }),
          style: 'error',
        })
    }
  }

  function updateCancel() {
    removeStateFunctionId()
    setValues((prev) => ({
      ...prev,
      de: '',
      fr: '',
      it: '',
    }))
  }

  function checkDe(
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
      if (!de || de === '') {
        setDeError(
          intl.formatMessage({
            id: 'settings.stateFunctionEdit.error.deRequired',
            defaultMessage: 'Label (German) is required',
          })
        )
        return true
      }
      setDeError('')
    return false
  }

  function checkFr(
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
      if (!fr || fr === '') {
        setFrError(
          intl.formatMessage({
            id: 'settings.stateFunctionEdit.error.frRequired',
            defaultMessage: 'Label (French) is required',
          })
        )
        return true
      }
      setFrError('')
    return false
  }

  function checkIt(
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
      if (!it || it === '') {
        setItError(
          intl.formatMessage({
            id: 'settings.stateFunctionEdit.error.itRequired',
            defaultMessage: 'Label (Italian) is required',
          })
        )
        return true
      }
      setItError('')
    return false
  }

  function validate(
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>,
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>,
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>,
  ) {
    let generalError = false

    generalError = generalError || checkDe(de, setDeError)
    generalError = generalError || checkFr(fr, setFrError)
    generalError = generalError || checkIt(it, setItError)

    return !generalError
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateFunctionEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateFunctionEdit.errorMessage',
            defaultMessage:
              'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  return (
    <StateFunctionEdit
      checkDe={checkDe}
      checkFr={checkFr}
      checkIt={checkIt}
      validate={validate}
      values={values}
      isLoading={isLoading}
      updateCancel={updateCancel}
      stateFunctionId={stateFunctionId}
      updateEntry={updateEntry}
      createEntry={createEntry}
      setValues={setValues}
    />
  )
}
