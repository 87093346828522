import { useIntl } from 'react-intl'
import { Button } from 'baseui/button'
import { Plus, CheckIndeterminate } from 'baseui/icon'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'

export default function ToggleAll({
  selectedPerson,
  onClick,
  loading,
}: {
  selectedPerson: string[]
  onClick: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => any
  loading: boolean
}) {
  const intl = useIntl()

  return (
      <StatefulTooltip
        placement={PLACEMENT.left}
        content={intl.formatMessage({
          id: 'members.toggleAll.toggleAll',
          defaultMessage: 'Toggle all',
        })}
      >
      <Button isLoading={loading} onClick={onClick}>
        {
          selectedPerson.length > 0 ? (
            <CheckIndeterminate
              title={intl.formatMessage({
                id: 'members.toggleAll.toggleAll',
                defaultMessage: 'Toggle all',
              })}
              size={24}
            />
          ) : (
            <Plus
              title={intl.formatMessage({
                id: 'members.toggleAll.toggleAll',
                defaultMessage: 'Toggle all',
              })}
              size={24}
            />
          )
        }
      </Button>
      </StatefulTooltip>
  )
}
