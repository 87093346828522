import MembersOverview from './MembersOverview'

import { InnerRoute, useLocalStorage } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'

export default function MembersOverviewBusiness({
  subNav,
  goToPerson,
  goToCourses,
  goToInvoices,
  personId,
}: {
  subNav: InnerRoute[]
  goToPerson: (id: string | null) => void
  goToCourses: (id: string | null) => void
  goToInvoices: (id: string | null) => void
  personId: string | undefined
}) {
  const { user } = useAppState()
  const [searchQuery, setSearchQuery] = useLocalStorage<string>('searchQuery', '')
  const [associationFilter, setAssociationFilter] = useLocalStorage<string[] | null>(
    'associationFilter'
  )
  const [workLevelFilter, setWorkLevelFilter] = useLocalStorage<string[] | null>('workLevelFilter')
  const [sportsEducationFilter, setSportsEducationFilter] = useLocalStorage<string[] | null>(
    'sportsEducationFilter'
  )
  const [stateFilter, setStateFilter] = useLocalStorage<string | null>(
    'stateFilter'
  )
  const [stateTypeFilter, setStateTypeFilter] = useLocalStorage<string | null>(
    'stateTypeFilter'
  )
  const [membershipFilter, setMembershipFilter] = useLocalStorage<string | null>(
    'membershipFilter'
  )
  const [selectedPerson, setSelectedPerson] = useLocalStorage<string[]>(
    'selectedPerson', []
  )

  return (
    <MembersOverview
      subNav={subNav}
      goToPerson={goToPerson}
      goToCourses={goToCourses}
      goToInvoices={goToInvoices}
      personId={personId}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      associationFilter={associationFilter}
      setAssociationFilter={setAssociationFilter}
      workLevelFilter={workLevelFilter}
      setWorkLevelFilter={setWorkLevelFilter}
      sportsEducationFilter={sportsEducationFilter}
      setSportsEducationFilter={setSportsEducationFilter}
      stateFilter={stateFilter}
      setStateFilter={setStateFilter}
      stateTypeFilter={stateTypeFilter}
      setStateTypeFilter={setStateTypeFilter}
      membershipFilter={membershipFilter}
      setMembershipFilter={setMembershipFilter}
      selectedPerson={selectedPerson}
      setSelectedPerson={setSelectedPerson}
      user={user}
    />
  )
}
