import { useIntl } from 'react-intl'

import CheckboxFilter from '@lemonbrain/components/UiElements/SearchFilters/Filters/CheckboxFilter'

export default function AssociationFilterBusiness({
  workLevels,
  loadingWorkLevels,
  workLevelFilter,
  setWorkLevelFilter,
}: {
  workLevels: {
    id: string
    label: string
  }[]
  loadingWorkLevels: boolean
  workLevelFilter: string[] | null
  setWorkLevelFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
  const intl = useIntl()

  return (
    <CheckboxFilter
      values={workLevels}
      loadingValues={loadingWorkLevels}
      filter={workLevelFilter}
      setFilter={setWorkLevelFilter}
      label={intl.formatMessage({
        id: 'members.workLevel_filter.workLevel',
        defaultMessage: 'Work level',
      })}
      comment={intl.formatMessage({
        id: 'members.workLevel_filter.workLevelComment',
        defaultMessage: 'Works on this levels',
      })}
    />
  )
}
