import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import { useCreateAttendeesListMutation } from '../../../../gql/Courses.generated'
import ExportAttendeesListBusiness from './ExportAttendeesList.business'
import getReactApolloCients from '../../../../localReactApollo'

export default function ExportAttendeesListData({
  courseId,
}: {
  courseId: string
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const [
    createAttendeesList,
    {
      data: dataCreateAttendeesList,
      loading: loadingCreateAttendeesList,
      error: errorCreateAttendeesList,
    },
  ] = useCreateAttendeesListMutation({
    //@ts-ignore
    client,
  })

  const startExport = function () {
      createAttendeesList({
        variables: {
          courseId,
        },
      })
  }

  useEffect(() => {
    if (dataCreateAttendeesList && dataCreateAttendeesList.createAttendeesList !== '') {
      download_files(dataCreateAttendeesList.createAttendeesList)
    }
  }, [dataCreateAttendeesList])

  function download_files(
    fileName: string
  ) {
    function download_next() {
      var a: HTMLAnchorElement = document.createElement('a')
      a.href = `${process.env.REACT_APP_MITGLIEDER_URL}/application/files/attendees_list/${fileName}`
      a.target = '_blank'
      // Use a.download if available, it prevents plugins from opening.
      if ('download' in a) {
        a.download = `${fileName}.pdf`
      }
      // Add a to the doc for click to work.
      ;(document.body || document.documentElement).appendChild(a)
      if (a.click) {
        a.click() // The click method is supported by most browsers.
      }
      // Delete the temporary link.
      a.parentNode?.removeChild(a)
    }
    
    download_next()
  }

  useEffect(() => {
    if (errorCreateAttendeesList) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.exportAttendeesList.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'courses.exportAttendeesList.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorCreateAttendeesList?.message)
    }
  }, [errorCreateAttendeesList])

  return (
    <ExportAttendeesListBusiness
      loading={loadingCreateAttendeesList}
      startExport={startExport}
    />
  )
}
