import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import NewsletterBusiness from './Newsletter.business'
import getReactApolloCients from '../../../../localReactApollo'
import {useGetNewslettersNoPersonQuery} from '../../../../gql/members/Newsletter.generated'

export default function NewsletterData({
    newsletters,
    setNewsletters,
  }: {
    newsletters?: { id: string }[]
    setNewsletters: React.Dispatch<
      React.SetStateAction<{ id: string }[]>
    >
  }) {
    const intl = useIntl()
    const setAlert = useAlert()
    const [newslettersInput, setNewslettersInput] = useState<
      {
        id: string
        label: string
      }[]
    >([])
    const client = getReactApolloCients().getClient()
      const { data: dataNewsletters, loading: loadingNewsletters, error: errorNewsletters } = useGetNewslettersNoPersonQuery({
        variables: {
          cursor: {
            pageSize: 100,
          },
        },
        //@ts-ignore
        client,
     });

     useEffect(() => {
       if (errorNewsletters) {
         setAlert &&
           setAlert({
             title: intl.formatMessage({
               id: 'members.newsletter.error',
               defaultMessage: 'Error',
             }),
             label: intl.formatMessage({
               id: 'members.newsletter.networkError',
               defaultMessage: 'We had network problem. Please try again',
             }),
             style: 'error',
           })
         console.error(errorNewsletters?.message)
       }
     }, [errorNewsletters])
   
     useEffect(() => {
       if (dataNewsletters?.getNewslettersNoPerson.items) {
        setNewslettersInput(
          dataNewsletters?.getNewslettersNoPerson.items.map((item) => ({
             id: item.id,
             label: getTranslation(item.name, intl),
           })).sort(function(a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            
            return 0;
          })
         )
       }
     }, [dataNewsletters])
    
      return <NewsletterBusiness newsletters={newsletters} setNewsletters={setNewsletters} loadingNewsletters={loadingNewsletters} newslettersInput={newslettersInput} />
  }