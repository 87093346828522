import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import WorkLevel from './WorkLevel'

export default function WorkLevelBusiness({
  workLevel,
  setWorkLevel,
  loadingWorkLevels,
  workLevels,
  getHasWorkLevelErrors,
}: {
  workLevel?: { id: string }[]
  setWorkLevel: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingWorkLevels: boolean
  workLevels: {
    id: string
    label: string
  }[]
  getHasWorkLevelErrors: (errors: { workLevel: string }) => void
}) {
  const [errors, setErrors] = useState<{
    workLevel: string
  }>({
    workLevel: '',
  })
  const intl = useIntl()

  function checkWorkLevel(workLevel: { id: string }[] | undefined) {
    if (workLevel && workLevel.length > 0) {
      setErrors({ workLevel: '' })
      return false
    }
    setErrors({
      workLevel: intl.formatMessage({
        id: 'members.workLevel.isRequired',
        defaultMessage: 'Work level is required',
      }),
    })
    return true
  }

  useEffect(() => {
    checkWorkLevel(workLevel)
  }, [workLevel])

  useEffect(() => {
    getHasWorkLevelErrors(errors)
  }, [errors])

  return (
    <WorkLevel
      workLevel={workLevel}
      setWorkLevel={setWorkLevel}
      loadingWorkLevels={loadingWorkLevels}
      workLevels={workLevels}
      errors={errors} 
    />
  )
}
