import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import SportsEducation from './SportsEducation'

export default function SportsEducationBusiness({
  sportsEducation,
  setSportsEducation,
  loadingSportsEducations,
  sportsEducations,
  getHasSportsEducationErrors,
}: {
  sportsEducation?: { id: string }[]
  setSportsEducation: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingSportsEducations: boolean
  sportsEducations: {
    id: string
    label: string
  }[]
  getHasSportsEducationErrors: (errors: { sportsEducation: string }) => void
}) {
  const [errors, setErrors] = useState<{
    sportsEducation: string
  }>({
    sportsEducation: '',
  })
  const intl = useIntl()

  function checkSportsEducation(sportsEducation: { id: string }[] | undefined) {
    if (sportsEducation && sportsEducation.length > 0) {
      setErrors({ sportsEducation: '' })
      return false
    }
    setErrors({
      sportsEducation: intl.formatMessage({
        id: 'members.sportsEducation.isRequired',
        defaultMessage: 'Sports education is required',
      }),
    })
    return true
  }

  useEffect(() => {
    checkSportsEducation(sportsEducation)
  }, [sportsEducation])

  useEffect(() => {
    getHasSportsEducationErrors(errors)
  }, [errors])

  return (
    <SportsEducation
      sportsEducation={sportsEducation}
      setSportsEducation={setSportsEducation}
      loadingSportsEducations={loadingSportsEducations}
      sportsEducations={sportsEducations}
      errors={errors} 
    />
  )
}
