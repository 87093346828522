import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Upload } from 'baseui/icon'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'
import { StatefulPopover } from 'baseui/popover'
import { Button } from 'baseui/button'
import { FileUploader } from 'baseui/file-uploader'
import { ParagraphMedium } from 'baseui/typography'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Notification, KIND } from 'baseui/notification'

import { Maybe } from '../../../../types'

export default function SepaImport({
  importLoader,
  deleteLoader,
  errorMessage,
  setAcceptedFiles,
  setRejectedFiles,
  deleteOptionActivated,
  deleteSepa,
  errorMessages,
  successMessages,
}: {
  importLoader: boolean
  deleteLoader: boolean
  errorMessage: string
  setAcceptedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>
  setRejectedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>
  deleteOptionActivated: string
  deleteSepa: () => void
  errorMessages: Maybe<string>[]
  successMessages: Maybe<string>[]
}) {
  const intl = useIntl()
  const [sure, setSure] = useState(false)

  return (
    <StatefulTooltip
      placement={PLACEMENT.right}
      content={intl.formatMessage({
        id: 'invoices.sepaImport.sepaImport',
        defaultMessage: 'SEPA import',
      })}
      overrides={{
        Body: {
          style: () => ({
            zIndex: 99999,
          }),
        },
      }}
    >
      <StatefulPopover
        content={
          <>
            <FileUploader
              accept={'.xml'}
              errorMessage={errorMessage}
              onDrop={(acceptedFiles, rejectedFiles) => {
                setAcceptedFiles(acceptedFiles)
                setRejectedFiles(rejectedFiles)
              }}
              progressMessage={importLoader ? `Uploading...` : ''}
              overrides={{
                ContentMessage: {
                  component: () => (
                    <span>
                      {intl.formatMessage({
                        id: 'invoices.sepaImport.dropSepaFilesHere',
                        defaultMessage: 'Drop SEPA file here to upload...',
                      })}
                    </span>
                  ),
                },
              }}
            />
            {errorMessages.length > 0 && (
              <Notification
                kind={KIND.negative}
                closeable
                overrides={{
                  Body: {
                    style: () => ({
                      width: 'auto',
                      padding: '30px',
                    }),
                  },
                }}
              >
                <ul>
                  {errorMessages.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Notification>
            )}
            {successMessages.length > 0 && (
              <Notification
                kind={KIND.positive}
                closeable
                overrides={{
                  Body: {
                    style: () => ({
                      width: 'auto',
                      padding: '30px',
                    }),
                  }
                }}
              >
                <ul>
                  {successMessages.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Notification>
            )}
            {deleteOptionActivated !== '' &&
              (!sure ? (
                <Button onClick={() => setSure(true)}>
                  {intl.formatMessage(
                    {
                      id: 'invoices.sepaImport.deleteSepa',
                      defaultMessage:
                        'Delete SEPA file {filename} on server and all its dependencies',
                    },
                    { filename: deleteOptionActivated }
                  )}
                </Button>
              ) : (
                <FlexGrid flexGridColumnCount={3}>
                  <FlexGridItem
                    alignSelf={'center'}
                    style={{ textAlign: 'right' }}
                  >
                    <ParagraphMedium>
                      {intl.formatMessage({
                        id: 'invoices.sepaImport.sure',
                        defaultMessage: 'Are you sure?',
                      })}
                    </ParagraphMedium>
                  </FlexGridItem>
                  <FlexGridItem
                    alignSelf={'center'}
                    style={{ textAlign: 'right' }}
                  >
                    <Button
                      onClick={() => deleteSepa()}
                      isLoading={deleteLoader}
                    >
                      {intl.formatMessage({
                        id: 'invoices.sepaImport.yes',
                        defaultMessage: 'Yes',
                      })}
                    </Button>
                  </FlexGridItem>
                  <FlexGridItem
                    alignSelf={'center'}
                    style={{ textAlign: 'right' }}
                  >
                    <Button onClick={() => setSure(false)}>
                      {intl.formatMessage({
                        id: 'invoices.sepaImport.no',
                        defaultMessage: 'No',
                      })}
                    </Button>
                  </FlexGridItem>
                </FlexGrid>
              ))}
          </>
        }
        accessibilityType={'tooltip'}
      >
        <Button>
          <Upload
            title={intl.formatMessage({
              id: 'invoices.sepaImport.sepaImport',
              defaultMessage: 'SEPA import',
            })}
            size={24}
          />
        </Button>
      </StatefulPopover>
    </StatefulTooltip>
  )
}
