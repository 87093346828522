import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'

import { InnerRoute } from '@lemonbrain/hooks'

import AddressTypeBusiness from './AddressType.business'

export default function AddressTypeData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { addressTypeId } = useParams<{ addressTypeId: string }>()
  const { language } = useParams<{ language: string }>()

  function setAddressTypelId(addressTypeId: string | undefined) {
    if (addressTypeId && addressTypeId !== '') {
      pushPath(`/${language}/settings/address-type/${addressTypeId}`)
    } else {
      pushPath(`/${language}/settings/address-type`)
    }
  }

  return (
    <AddressTypeBusiness
      subNav={subNav}
      addressTypeId={addressTypeId}
      setAddressTypelId={setAddressTypelId}
    />
  )
}
