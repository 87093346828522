import EditLanguage from './EditLanguage'

export default function EditLanguageBusiness({
    language,
    setLanguage,
    isLoading,
}: {
    language: string
    setLanguage: React.Dispatch<React.SetStateAction<string>>
    isLoading: boolean,
}) {

    return (
        <EditLanguage
            language={language}
            setLanguage={setLanguage}
            isLoading={isLoading}
        />
    )
}
