import { useIntl } from 'react-intl'
import { Button, SIZE } from 'baseui/button'
import { ArrowDown } from 'baseui/icon'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'
import DocumentIcon from '../../../../icons/DocumentIcon'

export default function ParticipantsExport({
  loading,
  onClick,
  courseId,
}: {
  loading: boolean
  onClick: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => any
  courseId: string
}) {
  const intl = useIntl()

  return parseInt(courseId) > 0 && (
    <StatefulTooltip
      placement={PLACEMENT.right}
      content={intl.formatMessage({
        id: 'courses.participantsExport.courseRegistrations',
        defaultMessage: 'Export participants',
      })}
      overrides={{
        Body: {
          style: () => ({
            zIndex: 1,
          }),
        },
      }}
    >
      <Button isLoading={loading} onClick={onClick}
                size={SIZE.compact}>
        <ArrowDown
          title={intl.formatMessage({
            id: 'courses.participantsExport.courseRegistrations',
            defaultMessage: 'Export participants',
          })}
        />
        <DocumentIcon inverse={true} />
      </Button>
    </StatefulTooltip>
  ) || <></>
}
