import { useState } from 'react'
import OnlinePayer from './OnlinePayer'

import { Currencies } from '@lemonbrain/utils/Options/Currencies'
import { Countries } from '@lemonbrain/utils/Options/Countries'
import { appendScript } from '@lemonbrain/utils/scriptHelper'

import { InvoiceFragmentFragment } from '../../../../gql/invoices/Invoices.generated'
import { PayMutationFn } from '../../../../gql/payment/Postfinance.generated'
import { AddTransactionMutationFn } from '../../../../gql/transaction/Transaction.generated'
import { PaymentState } from '../../InvoicesMy.data'

export default function OnlinePayerBusiness({
  invoice,
  invoiceId,
  paymentState,
  language,
  payMutation,
  addTransactionMutation,
}: {
  invoice?: InvoiceFragmentFragment
  invoiceId: string
  paymentState: PaymentState | undefined
  language: string
  payMutation: PayMutationFn
  addTransactionMutation: AddTransactionMutationFn
}) {
  const [loading, setLoading] = useState(false)

  async function pay() {
    setLoading(true)
    if (invoice) {
      const products = [
        {
          uniqueId: invoice.invoiceNumber,
          sku: invoice.invoiceNumber,
          name: invoice.items[0].name,
          quantity: 1,
          amountIncludingTax: invoice.total,
        },
      ]

      const billShippAddress = {
        email: invoice.email || '',
        organizationName: invoice.firmName || '',
        phoneNumber: '',
        salutation: invoice.salutation || '',
        givenName: invoice.firstName || '',
        familyName: invoice.lastName || '',
        postCode: invoice.postCode.toString(),
        city: invoice.place || '',
        postalState: invoice.canton || '',
        country: invoice.country || '',
      }

      const currency = Currencies.CHF
      const successUrl = `/${language}/invoices/my/${invoiceId}/${PaymentState.Success}`
      const failedUrl = `/${language}/invoices/my/${invoiceId}/${PaymentState.Failed}`
      const languageCode = `${language}-${Countries.CH}`

      const answer = await payMutation({
        variables: {
          products,
          billingAddress: billShippAddress,
          shippingAddress: billShippAddress,
          currency,
          successUrl,
          failedUrl,
          language: languageCode,
        },
      })

      if (answer && answer.data?.pay.transactionId) {
        const response = await addTransactionMutation({
          variables: {
            invoiceId: invoice.id,
            trsansactionId: answer.data?.pay.transactionId,
          },
        })
        if (!!response) {
          const onLoad = () => {
            //@ts-ignore
            window.LightboxCheckoutHandler.startPayment(undefined, function () {
              console.error(
                'something went wrong with loading the fancybox script'
              )
            })
          }
          const onError = () => {
            console.error(
              'something went wrong with loading the fancybox script'
            )
            setLoading(false)
          }

          appendScript(answer.data?.pay.fancyboxUrl, onLoad, onError)
        }
      }

      setLoading(false)
    }
  }

  return (
    <OnlinePayer
      isLoading={loading}
      clickPayHandler={pay}
      paymentState={paymentState}
    />
  )
}
