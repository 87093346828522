// @ts-nocheck
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode: Scalars['Int'];
  place: Scalars['String'];
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  validFrom: Scalars['Int'];
  types: Array<AddressType>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type AddressCreate = {
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode: Scalars['Int'];
  place: Scalars['String'];
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['Int']>;
  types: Array<AddressTypeElemCreate>;
};

export type AddressInput = {
  email: Scalars['String'];
  organizationName: Scalars['String'];
  phoneNumber: Scalars['String'];
  salutation: Scalars['String'];
  givenName: Scalars['String'];
  familyName: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  postalState: Scalars['String'];
  country: Scalars['String'];
};

export type AddressPagination = {
  __typename?: 'AddressPagination';
  items: Array<Address>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  id: Scalars['ID'];
  isInitType: Scalars['Boolean'];
  isFirm: Scalars['Boolean'];
  name: TranslationObject;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type AddressTypeElemCreate = {
  id: Scalars['ID'];
};

export type AddressTypeElemUpdate = {
  id: Scalars['ID'];
  task: ArrayTasks;
};

export type AddressTypePagination = {
  __typename?: 'AddressTypePagination';
  items: Array<AddressType>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type AddressUpdate = {
  id?: Maybe<Scalars['ID']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<AddressTypeElemUpdate>>;
  task: ArrayUpdate;
};

export enum ArrayTasks {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export enum ArrayUpdate {
  Add = 'ADD',
  Update = 'UPDATE',
  Remove = 'REMOVE'
}

export type Association = {
  __typename?: 'Association';
  id: Scalars['ID'];
  /** Name of the Association */
  name: TranslationObject;
  /** Short name of Association */
  short: TranslationObject;
  imageId?: Maybe<Scalars['ID']>;
  imageSrc?: Maybe<Scalars['String']>;
  associationURL?: Maybe<Scalars['String']>;
  isStufenvereinigung: Scalars['Boolean'];
  /** Prize to pay for beeing in this Association (without SVSS cost: 50.-) in rp */
  prize: Scalars['Int'];
  /** All persons who are in this Association */
  associationPersons?: Maybe<Array<AssociationPerson>>;
  /** Persons who have this Association selected for the SVSS payment */
  membershipPayers?: Maybe<Array<Person>>;
  needsAComment: Scalars['Boolean'];
  defaultComment?: Maybe<Scalars['String']>;
  membersCalculationAt?: Maybe<Scalars['String']>;
  billingInformation?: Maybe<BillingInformation>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type AssociationElemCreate = {
  /** id of of the Association to create */
  id: Scalars['ID'];
};

export type AssociationElemUpdate = {
  /** id of of the Association to update */
  id: Scalars['ID'];
  /** which action do you want to do with that Association */
  task: ArrayTasks;
};

export type AssociationPagination = {
  __typename?: 'AssociationPagination';
  items: Array<Association>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type AssociationPerson = {
  __typename?: 'AssociationPerson';
  id: Scalars['ID'];
  association: Association;
  person: Person;
  associationTypes?: Maybe<Array<AssociationType>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type AssociationPersonAssociationElemCreate = {
  personId: Scalars['ID'];
  associationTypes: Array<Maybe<AssociationTypeElemCreate>>;
};

export type AssociationPersonAssociationElemUpdate = {
  personId: Scalars['ID'];
  associationTypes: Array<AssociationTypeElemUpdate>;
  /** which action do you want to do with that Association */
  task: ArrayUpdate;
};

export type AssociationPersonPersonElemCreate = {
  associationId: Scalars['ID'];
  associationTypes: Array<Maybe<AssociationTypeElemCreate>>;
};

export type AssociationPersonPersonElemUpdate = {
  associationId: Scalars['ID'];
  associationTypes: Array<AssociationTypeElemUpdate>;
  /** which action do you want to do with that Association */
  task: ArrayUpdate;
};

export type AssociationType = {
  __typename?: 'AssociationType';
  id: Scalars['ID'];
  name: TranslationObject;
  associationPersons?: Maybe<Array<AssociationPerson>>;
  isDefaultType: Scalars['Boolean'];
  userGroup: Scalars['String'];
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type AssociationTypeElemCreate = {
  id: Scalars['ID'];
};

export type AssociationTypeElemUpdate = {
  id: Scalars['ID'];
  task: ArrayTasks;
};

export type AssociationTypePagination = {
  __typename?: 'AssociationTypePagination';
  items: Array<AssociationType>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type AssociationWithPresident = {
  __typename?: 'AssociationWithPresident';
  association: Association;
  presidentName?: Maybe<Scalars['String']>;
  presidentEmail?: Maybe<Scalars['String']>;
};

export type BillingInformation = {
  __typename?: 'BillingInformation';
  id: Scalars['ID'];
  firstNameFrom?: Maybe<Scalars['String']>;
  lastNameFrom?: Maybe<Scalars['String']>;
  firmNameFrom?: Maybe<Scalars['String']>;
  mailboxFrom?: Maybe<Scalars['String']>;
  streetNrFrom?: Maybe<Scalars['String']>;
  postCodeFrom: Scalars['Int'];
  placeFrom: Scalars['String'];
  cantonFrom?: Maybe<Scalars['String']>;
  countryFrom?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode: Scalars['Int'];
  place: Scalars['String'];
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  iban: Scalars['String'];
  qrIban?: Maybe<Scalars['String']>;
  besrId?: Maybe<Scalars['Int']>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type BillingInformationCreate = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr: Scalars['String'];
  postCode: Scalars['Int'];
  place?: Maybe<Scalars['String']>;
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  iban: Scalars['String'];
  qrIban?: Maybe<Scalars['String']>;
  besrId?: Maybe<Scalars['Int']>;
};

export type BillingInformationUpdate = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  qrIban?: Maybe<Scalars['String']>;
  besrId?: Maybe<Scalars['Int']>;
};

export type Course = {
  __typename?: 'Course';
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  educationalStages: Array<Maybe<WorkLevel>>;
  contentCategory?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  title: TranslationObject;
  description?: Maybe<TranslationObject>;
  price?: Maybe<Scalars['Int']>;
  memberPrice?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Scalars['String']>;
  targetGroup?: Maybe<Scalars['String']>;
  special?: Maybe<TranslationObject>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  subscribeTill?: Maybe<Scalars['String']>;
  minSubscribers?: Maybe<Scalars['Int']>;
  maxSubscribers?: Maybe<Scalars['Int']>;
  options: Array<Scalars['String']>;
  courseRegistrations?: Maybe<Array<Maybe<CourseRegistration>>>;
  status?: Maybe<CourseStatus>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type CoursePagination = {
  __typename?: 'CoursePagination';
  items: Array<Course>;
  nextCursor?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CourseRegistration = {
  __typename?: 'CourseRegistration';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  participant: Person;
  signUpStatus: SignUpState;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  course: Course;
};

export type CourseRegistrationPagination = {
  __typename?: 'CourseRegistrationPagination';
  items: Array<CourseRegistration>;
  nextCursor?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum CourseStatus {
  Open = 'open',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled'
}

export type FilterInput = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  invoiceNumber: Scalars['String'];
  date: Scalars['String'];
  items: Array<InvoiceItem>;
  total: Scalars['Int'];
  payAmount?: Maybe<Scalars['Int']>;
  payDate?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['String']>;
  account: Scalars['String'];
  iban: Scalars['String'];
  qrIban?: Maybe<Scalars['String']>;
  besrId?: Maybe<Scalars['Int']>;
  paymentFirstName?: Maybe<Scalars['String']>;
  paymentLastName?: Maybe<Scalars['String']>;
  paymentFirmName?: Maybe<Scalars['String']>;
  paymentMailbox?: Maybe<Scalars['String']>;
  paymentStreetNr?: Maybe<Scalars['String']>;
  paymentPostCode: Scalars['Int'];
  paymentPlace: Scalars['String'];
  paymentCanton?: Maybe<Scalars['String']>;
  paymentCountry?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode: Scalars['Int'];
  place: Scalars['String'];
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  pdfPath: Scalars['String'];
  mailing?: Maybe<Mailing>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
  courseRegistration?: Maybe<CourseRegistration>;
  reminderMailing?: Maybe<Mailing>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  position: Scalars['Int'];
  count: Scalars['Int'];
  price: Scalars['Int'];
  name: Scalars['String'];
};

export type InvoicePagination = {
  __typename?: 'InvoicePagination';
  items: Array<Invoice>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type Mailing = {
  __typename?: 'Mailing';
  id: Scalars['ID'];
  from: Scalars['String'];
  fromName: Scalars['String'];
  to: Array<Scalars['String']>;
  subject: Scalars['String'];
  content: Scalars['String'];
  attachment?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  sent: Array<Maybe<Scalars['String']>>;
  failed: Array<Maybe<Scalars['String']>>;
  state: MailingState;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export enum MailingState {
  Draft = 'draft',
  Pending = 'pending',
  Running = 'running',
  Sleeping = 'sleeping',
  Interrupted = 'interrupted',
  Completed = 'completed'
}

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['ID'];
  /** Name of the Membership */
  name: TranslationObject;
  /** Prize to pay for beeing in this Membership in rp */
  prize: Scalars['Int'];
  directInvoicing: Scalars['Boolean'];
  /** Persons who are members of this Membership */
  members?: Maybe<Array<Person>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type MembershipElemCreate = {
  id: Scalars['ID'];
};

export type MembershipElemUpdate = {
  /** id of of the membership to update */
  id: Scalars['ID'];
  /** which action do you want to do with that membership */
  task: ArrayTasks;
};

export type MembershipPagination = {
  __typename?: 'MembershipPagination';
  items: Array<Membership>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMailing: Mailing;
  updateMailing: Mailing;
  deleteMailing: Mailing;
  sendMailing: Mailing;
  addNewsletterMailing: NewsletterMailing;
  updateNewsletterMailing: NewsletterMailing;
  deleteNewsletterMailing: NewsletterMailing;
  addNewsletterMailingItem: NewsletterMailingItem;
  updateNewsletterMailingItem: NewsletterMailingItem;
  deleteNewsletterMailingItem: NewsletterMailingItem;
  renderNewsletterMailing: NewsletterMailing;
  sendNewsletterMailing: SendReturn;
  addAddressType: AddressType;
  updateAddressType: AddressType;
  deleteAddressType: AddressType;
  addAddress: Address;
  updateAddress: Address;
  deleteAddress: Address;
  addStateType: StateType;
  updateStateType: StateType;
  deleteStateType: StateType;
  addState: State;
  updateState: State;
  deleteState: State;
  addMembership: Membership;
  updateMembership: Membership;
  deleteMembership: Membership;
  addAssociation: Association;
  updateAssociation: Association;
  updateAssociationIfMember: Association;
  deleteAssociation: Association;
  addAssociationType: AssociationType;
  updateAssociationType: AssociationType;
  deleteAssociationType: AssociationType;
  addPerson: Person;
  addEmptyPerson: Person;
  addPersonIfOwns: Person;
  updatePerson: Person;
  updatePersonIfManages: Person;
  updatePersonIfOwns: Person;
  deletePerson: Person;
  addWorkLevel: WorkLevel;
  updateWorkLevel: WorkLevel;
  deleteWorkLevel: WorkLevel;
  addSvssFunction: SvssFunction;
  updateSvssFunction: SvssFunction;
  deleteSvssFunction: SvssFunction;
  addStateFunction: StateFunction;
  updateStateFunction: StateFunction;
  deleteStateFunction: StateFunction;
  addSportsEducation: SportsEducation;
  updateSportsEducation: SportsEducation;
  deleteSportsEducation: SportsEducation;
  addNewsletter: Newsletter;
  updateNewsletter: Newsletter;
  deleteNewsletter: Newsletter;
  registerForNewsletter?: Maybe<Scalars['Boolean']>;
  unregisterForNewsletter?: Maybe<Scalars['Boolean']>;
  addBillingInformation: BillingInformation;
  updateBillingInformation: BillingInformation;
  deleteInvoice: Invoice;
  createDirectMemberInvoice: CreateInvoiceReturn;
  createAssociationInvoice: CreateInvoiceReturn;
  createOneAssociationInvoice: CreateInvoiceReturn;
  createMemberInvoice: CreateInvoiceReturn;
  updateInvoice: Invoice;
  updateInvoiceIfAssociation: Invoice;
  addInvoice: Invoice;
  addTransaction: SvssTransaction;
  addSepa: SepaReturn;
  deleteSepa: SepaReturn;
  pay: Transaction;
  createCourse: Course;
  updateCourse: Course;
  deleteCourse: Course;
  registerForCourse: Course;
  unregisterFromCourse: Course;
  unregisterFromCourseById: Course;
  confirmOpenRegistrations: Course;
  cancelRegistrations: Course;
  confirmWaitingList: Course;
  confirmRegistration: CourseRegistration;
  createSignupRecipient: SignupRecipient;
  deleteSignupRecipient: SignupRecipient;
  createAttendeesList: Scalars['String'];
};


export type MutationAddMailingArgs = {
  from: Scalars['String'];
  fromName: Scalars['String'];
  to: Array<Scalars['String']>;
  subject: Scalars['String'];
  content: Scalars['String'];
  attachment?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateMailingArgs = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  sent?: Maybe<Array<Maybe<Scalars['String']>>>;
  failed?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<MailingState>;
};


export type MutationDeleteMailingArgs = {
  id: Scalars['ID'];
};


export type MutationSendMailingArgs = {
  id: Scalars['ID'];
};


export type MutationAddNewsletterMailingArgs = {
  newsletterId: Scalars['ID'];
  from: Scalars['String'];
  fromName: Scalars['String'];
  to: Array<Scalars['String']>;
  subject: Scalars['String'];
  mailingDate: Scalars['String'];
  items: Array<Maybe<NewsletterMailingItemElemUpdate>>;
  mainImage?: Maybe<Scalars['Upload']>;
  mainImageId?: Maybe<Scalars['ID']>;
  mainImageCopyright?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  leftColoumn?: Maybe<Scalars['String']>;
  rightColoumn?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  sent?: Maybe<Array<Maybe<Scalars['String']>>>;
  failed?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<MailingState>;
  showOnWebsite?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateNewsletterMailingArgs = {
  id: Scalars['ID'];
  newsletterId: Scalars['ID'];
  items: Array<Maybe<NewsletterMailingItemElemUpdate>>;
  from: Scalars['String'];
  fromName: Scalars['String'];
  to: Array<Scalars['String']>;
  subject: Scalars['String'];
  mailingDate: Scalars['String'];
  mainImage?: Maybe<Scalars['Upload']>;
  mainImageId?: Maybe<Scalars['ID']>;
  mainImageCopyright: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  leftColoumn: Scalars['String'];
  rightColoumn: Scalars['String'];
  attachment?: Maybe<Scalars['Upload']>;
  sent?: Maybe<Array<Maybe<Scalars['String']>>>;
  failed?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<MailingState>;
  showOnWebsite?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteNewsletterMailingArgs = {
  id: Scalars['ID'];
};


export type MutationAddNewsletterMailingItemArgs = {
  newsletterMailingId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  imageId?: Maybe<Scalars['ID']>;
  imageCopyright?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};


export type MutationUpdateNewsletterMailingItemArgs = {
  id: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  imageId?: Maybe<Scalars['ID']>;
  imageCopyright?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  orderNr?: Maybe<Scalars['Int']>;
};


export type MutationDeleteNewsletterMailingItemArgs = {
  id: Scalars['ID'];
};


export type MutationRenderNewsletterMailingArgs = {
  id: Scalars['ID'];
};


export type MutationSendNewsletterMailingArgs = {
  id: Scalars['ID'];
};


export type MutationAddAddressTypeArgs = {
  isInitType: Scalars['Boolean'];
  isFirm: Scalars['Boolean'];
  name: TranslationObjectCreate;
};


export type MutationUpdateAddressTypeArgs = {
  id: Scalars['ID'];
  isInitType?: Maybe<Scalars['Boolean']>;
  isFirm?: Maybe<Scalars['Boolean']>;
  name?: Maybe<TranslationObjectUpdate>;
};


export type MutationDeleteAddressTypeArgs = {
  id: Scalars['ID'];
};


export type MutationAddAddressArgs = {
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode: Scalars['Int'];
  place: Scalars['String'];
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['Int']>;
  types: Array<AddressTypeElemCreate>;
};


export type MutationUpdateAddressArgs = {
  id: Scalars['ID'];
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<AddressTypeElemUpdate>>;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID'];
};


export type MutationAddStateTypeArgs = {
  name: TranslationObjectCreate;
  stateId: Scalars['ID'];
  isTrialType: Scalars['Boolean'];
  requireMembership: Scalars['Boolean'];
};


export type MutationUpdateStateTypeArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  stateId?: Maybe<Scalars['ID']>;
  isTrialType?: Maybe<Scalars['Boolean']>;
  requireMembership?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteStateTypeArgs = {
  id: Scalars['ID'];
};


export type MutationAddStateArgs = {
  name: TranslationObjectCreate;
  isInitState: Scalars['Boolean'];
  highlightColor: Scalars['String'];
  typeIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUpdateStateArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  isInitState?: Maybe<Scalars['Boolean']>;
  highlightColor?: Maybe<Scalars['String']>;
  typeIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteStateArgs = {
  id: Scalars['ID'];
};


export type MutationAddMembershipArgs = {
  name: TranslationObjectCreate;
  prize: Scalars['Int'];
  directInvoicing: Scalars['Boolean'];
  members?: Maybe<Array<PersonElemCreate>>;
};


export type MutationUpdateMembershipArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  prize?: Maybe<Scalars['Int']>;
  directInvoicing?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<PersonElemUpdate>>;
};


export type MutationDeleteMembershipArgs = {
  id: Scalars['ID'];
};


export type MutationAddAssociationArgs = {
  name: TranslationObjectCreate;
  short: TranslationObjectCreate;
  prize: Scalars['Int'];
  isStufenvereinigung: Scalars['Boolean'];
  associationPersons?: Maybe<Array<AssociationPersonAssociationElemCreate>>;
  membershipPayers?: Maybe<Array<PersonElemCreate>>;
  needsAComment: Scalars['Boolean'];
  defaultComment?: Maybe<Scalars['String']>;
  membersCalculationAt?: Maybe<Scalars['String']>;
  billingInformation: BillingInformationCreate;
  associationURL: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateAssociationArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  short?: Maybe<TranslationObjectUpdate>;
  associationURL: Scalars['String'];
  isStufenvereinigung?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['Upload']>;
  imageId?: Maybe<Scalars['ID']>;
  prize?: Maybe<Scalars['Int']>;
  associationPersons?: Maybe<Array<AssociationPersonAssociationElemUpdate>>;
  membershipPayers?: Maybe<Array<PersonElemUpdate>>;
  needsAComment?: Maybe<Scalars['Boolean']>;
  defaultComment?: Maybe<Scalars['String']>;
  membersCalculationAt?: Maybe<Scalars['String']>;
  billingInformation: BillingInformationUpdate;
};


export type MutationUpdateAssociationIfMemberArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  short?: Maybe<TranslationObjectUpdate>;
  associationURL: Scalars['String'];
  isStufenvereinigung?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['Upload']>;
  imageId?: Maybe<Scalars['ID']>;
  prize?: Maybe<Scalars['Int']>;
  associationPersons?: Maybe<Array<AssociationPersonAssociationElemUpdate>>;
  membershipPayers?: Maybe<Array<PersonElemUpdate>>;
  needsAComment?: Maybe<Scalars['Boolean']>;
  defaultComment?: Maybe<Scalars['String']>;
  membersCalculationAt?: Maybe<Scalars['String']>;
  billingInformation: BillingInformationUpdate;
};


export type MutationDeleteAssociationArgs = {
  id: Scalars['ID'];
};


export type MutationAddAssociationTypeArgs = {
  name: TranslationObjectCreate;
  isDefaultType: Scalars['Boolean'];
  userGroup: Scalars['String'];
};


export type MutationUpdateAssociationTypeArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  isDefaultType?: Maybe<Scalars['Boolean']>;
  userGroup?: Maybe<Scalars['String']>;
};


export type MutationDeleteAssociationTypeArgs = {
  id: Scalars['ID'];
};


export type MutationAddPersonArgs = {
  email: Scalars['String'];
  birthdate: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  ahvNumber: Scalars['String'];
  jsNumber?: Maybe<Scalars['String']>;
  slrgNumber?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Array<NewsletterElemCreate>>;
  stateId: Scalars['ID'];
  stateTypeId?: Maybe<Scalars['ID']>;
  hasSportsEducation?: Maybe<Array<SportsEducationElemCreate>>;
  currentWork?: Maybe<Array<WorkLevelElemCreate>>;
  svssFunctions?: Maybe<Array<SvssFunctionElemCreate>>;
  stateFunctions?: Maybe<Array<StateFunctionElemCreate>>;
  membershipId?: Maybe<Scalars['ID']>;
  associationPayTo?: Maybe<AssociationElemCreate>;
  associationPersons?: Maybe<Array<AssociationPersonPersonElemCreate>>;
  associationComment?: Maybe<Scalars['String']>;
  addresses: Array<AddressCreate>;
  comment?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['ID']>;
};


export type MutationAddEmptyPersonArgs = {
  authId: Scalars['ID'];
  email: Scalars['String'];
};


export type MutationAddPersonIfOwnsArgs = {
  email: Scalars['String'];
  birthdate: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  ahvNumber: Scalars['String'];
  jsNumber?: Maybe<Scalars['String']>;
  slrgNumber?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Array<NewsletterElemCreate>>;
  stateId?: Maybe<Scalars['ID']>;
  stateTypeId?: Maybe<Scalars['ID']>;
  hasSportsEducation?: Maybe<Array<SportsEducationElemCreate>>;
  currentWork?: Maybe<Array<WorkLevelElemCreate>>;
  svssFunctions?: Maybe<Array<SvssFunctionElemCreate>>;
  stateFunctions?: Maybe<Array<StateFunctionElemCreate>>;
  membershipId?: Maybe<Scalars['ID']>;
  associationPayTo?: Maybe<AssociationElemCreate>;
  associationPersons?: Maybe<Array<AssociationPersonPersonElemCreate>>;
  associationComment?: Maybe<Scalars['String']>;
  addresses: Array<AddressCreate>;
  comment?: Maybe<Scalars['String']>;
  authId: Scalars['ID'];
};


export type MutationUpdatePersonArgs = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  ahvNumber?: Maybe<Scalars['String']>;
  jsNumber?: Maybe<Scalars['String']>;
  slrgNumber?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Array<NewsletterElemUpdate>>;
  stateId?: Maybe<Scalars['ID']>;
  stateTypeId?: Maybe<Scalars['ID']>;
  hasSportsEducation?: Maybe<Array<SportsEducationElemUpdate>>;
  currentWork?: Maybe<Array<WorkLevelElemUpdate>>;
  svssFunctions?: Maybe<Array<SvssFunctionElemUpdate>>;
  stateFunctions?: Maybe<Array<StateFunctionElemUpdate>>;
  membershipId?: Maybe<Scalars['ID']>;
  associationPayTo?: Maybe<AssociationElemUpdate>;
  associationPersons?: Maybe<Array<AssociationPersonPersonElemUpdate>>;
  associationComment?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<AddressUpdate>>;
  comment?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['String']>;
};


export type MutationUpdatePersonIfManagesArgs = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  ahvNumber?: Maybe<Scalars['String']>;
  jsNumber?: Maybe<Scalars['String']>;
  slrgNumber?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Array<NewsletterElemUpdate>>;
  stateId?: Maybe<Scalars['ID']>;
  stateTypeId?: Maybe<Scalars['ID']>;
  hasSportsEducation?: Maybe<Array<SportsEducationElemUpdate>>;
  currentWork?: Maybe<Array<WorkLevelElemUpdate>>;
  svssFunctions?: Maybe<Array<SvssFunctionElemUpdate>>;
  stateFunctions?: Maybe<Array<StateFunctionElemUpdate>>;
  membershipId?: Maybe<Scalars['ID']>;
  associationPayTo?: Maybe<AssociationElemUpdate>;
  associationPersons?: Maybe<Array<AssociationPersonPersonElemUpdate>>;
  associationComment?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<AddressUpdate>>;
  comment?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['String']>;
};


export type MutationUpdatePersonIfOwnsArgs = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  ahvNumber?: Maybe<Scalars['String']>;
  jsNumber?: Maybe<Scalars['String']>;
  slrgNumber?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Array<NewsletterElemUpdate>>;
  stateId?: Maybe<Scalars['ID']>;
  stateTypeId?: Maybe<Scalars['ID']>;
  hasSportsEducation?: Maybe<Array<SportsEducationElemUpdate>>;
  currentWork?: Maybe<Array<WorkLevelElemUpdate>>;
  svssFunctions?: Maybe<Array<SvssFunctionElemUpdate>>;
  stateFunctions?: Maybe<Array<StateFunctionElemUpdate>>;
  membershipId?: Maybe<Scalars['ID']>;
  associationPayTo?: Maybe<AssociationElemUpdate>;
  associationPersons?: Maybe<Array<AssociationPersonPersonElemUpdate>>;
  associationComment?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<AddressUpdate>>;
  comment?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['String']>;
};


export type MutationDeletePersonArgs = {
  id: Scalars['ID'];
};


export type MutationAddWorkLevelArgs = {
  name: TranslationObjectCreate;
  worksOnLevel?: Maybe<Array<PersonElemCreate>>;
};


export type MutationUpdateWorkLevelArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  worksOnLevel?: Maybe<Array<PersonElemUpdate>>;
};


export type MutationDeleteWorkLevelArgs = {
  id: Scalars['ID'];
};


export type MutationAddSvssFunctionArgs = {
  name: TranslationObjectCreate;
  people?: Maybe<Array<PersonElemCreate>>;
};


export type MutationUpdateSvssFunctionArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  people?: Maybe<Array<PersonElemUpdate>>;
};


export type MutationDeleteSvssFunctionArgs = {
  id: Scalars['ID'];
};


export type MutationAddStateFunctionArgs = {
  name: TranslationObjectCreate;
  people?: Maybe<Array<PersonElemCreate>>;
};


export type MutationUpdateStateFunctionArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  people?: Maybe<Array<PersonElemUpdate>>;
};


export type MutationDeleteStateFunctionArgs = {
  id: Scalars['ID'];
};


export type MutationAddSportsEducationArgs = {
  name: TranslationObjectCreate;
  persons?: Maybe<Array<PersonElemCreate>>;
};


export type MutationUpdateSportsEducationArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  persons?: Maybe<Array<PersonElemUpdate>>;
};


export type MutationDeleteSportsEducationArgs = {
  id: Scalars['ID'];
};


export type MutationAddNewsletterArgs = {
  name: TranslationObjectCreate;
  wantsNewsletter?: Maybe<Array<PersonElemCreate>>;
};


export type MutationUpdateNewsletterArgs = {
  id: Scalars['ID'];
  name?: Maybe<TranslationObjectUpdate>;
  wantsNewsletter?: Maybe<Array<PersonElemUpdate>>;
};


export type MutationDeleteNewsletterArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterForNewsletterArgs = {
  id: Scalars['ID'];
  email: Scalars['String'];
  recaptchaResponse: Scalars['String'];
};


export type MutationUnregisterForNewsletterArgs = {
  id: Scalars['ID'];
  email: Scalars['String'];
  recaptchaResponse: Scalars['String'];
};


export type MutationAddBillingInformationArgs = {
  firstNameFrom?: Maybe<Scalars['String']>;
  lastNameFrom?: Maybe<Scalars['String']>;
  firmNameFrom?: Maybe<Scalars['String']>;
  mailboxFrom?: Maybe<Scalars['String']>;
  streetNrFrom?: Maybe<Scalars['String']>;
  postCodeFrom: Scalars['Int'];
  placeFrom: Scalars['String'];
  cantonFrom?: Maybe<Scalars['String']>;
  countryFrom?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode: Scalars['Int'];
  place: Scalars['String'];
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  iban: Scalars['String'];
  qrIban?: Maybe<Scalars['String']>;
  besrId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateBillingInformationArgs = {
  id: Scalars['ID'];
  firstNameFrom?: Maybe<Scalars['String']>;
  lastNameFrom?: Maybe<Scalars['String']>;
  firmNameFrom?: Maybe<Scalars['String']>;
  mailboxFrom?: Maybe<Scalars['String']>;
  streetNrFrom?: Maybe<Scalars['String']>;
  postCodeFrom?: Maybe<Scalars['Int']>;
  placeFrom?: Maybe<Scalars['String']>;
  cantonFrom?: Maybe<Scalars['String']>;
  countryFrom?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  canton?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  qrIban?: Maybe<Scalars['String']>;
  besrId?: Maybe<Scalars['Int']>;
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDirectMemberInvoiceArgs = {
  processId?: Maybe<Scalars['ID']>;
};


export type MutationCreateAssociationInvoiceArgs = {
  processId?: Maybe<Scalars['ID']>;
};


export type MutationCreateOneAssociationInvoiceArgs = {
  processId?: Maybe<Scalars['ID']>;
  associationId: Scalars['ID'];
};


export type MutationCreateMemberInvoiceArgs = {
  processId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID'];
  payAmount: Scalars['Int'];
  payDate?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};


export type MutationUpdateInvoiceIfAssociationArgs = {
  id: Scalars['ID'];
  payAmount: Scalars['Int'];
  payDate?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};


export type MutationAddInvoiceArgs = {
  personId: Scalars['ID'];
  title: Scalars['String'];
  itemText: Scalars['String'];
  cost: Scalars['Int'];
};


export type MutationAddTransactionArgs = {
  invoiceId: Scalars['ID'];
  trsansactionId: Scalars['ID'];
};


export type MutationAddSepaArgs = {
  sepa: Scalars['Upload'];
};


export type MutationDeleteSepaArgs = {
  filename: Scalars['String'];
};


export type MutationPayArgs = {
  products: Array<Product>;
  billingAddress: AddressInput;
  shippingAddress: AddressInput;
  currency: Scalars['String'];
  successUrl: Scalars['String'];
  failedUrl: Scalars['String'];
  language: Scalars['String'];
};


export type MutationCreateCourseArgs = {
  language?: Maybe<Scalars['String']>;
  educationalStageIds: Array<Scalars['ID']>;
  contentCategory?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  title?: Maybe<TranslationObjectUpdate>;
  description?: Maybe<TranslationObjectUpdate>;
  price?: Maybe<Scalars['Int']>;
  memberPrice?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Scalars['String']>;
  targetGroup?: Maybe<Scalars['String']>;
  special?: Maybe<TranslationObjectUpdate>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  subscribeTill?: Maybe<Scalars['String']>;
  minSubscribers?: Maybe<Scalars['Int']>;
  maxSubscribers?: Maybe<Scalars['Int']>;
  options: Array<Scalars['String']>;
  status?: Maybe<CourseStatus>;
};


export type MutationUpdateCourseArgs = {
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  educationalStageIds?: Maybe<Array<Scalars['ID']>>;
  contentCategory?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  title?: Maybe<TranslationObjectUpdate>;
  description?: Maybe<TranslationObjectUpdate>;
  price?: Maybe<Scalars['Int']>;
  memberPrice?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Scalars['String']>;
  targetGroup?: Maybe<Scalars['String']>;
  special?: Maybe<TranslationObjectUpdate>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  subscribeTill?: Maybe<Scalars['String']>;
  minSubscribers?: Maybe<Scalars['Int']>;
  maxSubscribers?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<CourseStatus>;
};


export type MutationDeleteCourseArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterForCourseArgs = {
  courseId: Scalars['ID'];
  participantId: Scalars['ID'];
  signUpStatus: SignUpState;
};


export type MutationUnregisterFromCourseArgs = {
  courseId: Scalars['ID'];
  participantId: Scalars['ID'];
};


export type MutationUnregisterFromCourseByIdArgs = {
  id: Scalars['ID'];
};


export type MutationConfirmOpenRegistrationsArgs = {
  id: Scalars['ID'];
};


export type MutationCancelRegistrationsArgs = {
  id: Scalars['ID'];
};


export type MutationConfirmWaitingListArgs = {
  id: Scalars['ID'];
};


export type MutationConfirmRegistrationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSignupRecipientArgs = {
  email: Scalars['String'];
};


export type MutationDeleteSignupRecipientArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAttendeesListArgs = {
  courseId: Scalars['ID'];
};

export type Newsletter = {
  __typename?: 'Newsletter';
  id: Scalars['ID'];
  /** Name of the newsletter */
  name: TranslationObject;
  /** Persons who are subscribed to this newsletter */
  wantsNewsletter?: Maybe<Array<Person>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type NewsletterElemCreate = {
  id: Scalars['ID'];
};

export type NewsletterElemUpdate = {
  /** id of of the newsletter to update */
  id: Scalars['ID'];
  /** which action do you want to do with that newsletter */
  task: ArrayTasks;
};

export type NewsletterMailing = {
  __typename?: 'NewsletterMailing';
  id: Scalars['ID'];
  newsletter: Newsletter;
  items: Array<Maybe<NewsletterMailingItem>>;
  from: Scalars['String'];
  fromName: Scalars['String'];
  to: Array<Scalars['String']>;
  subject: Scalars['String'];
  mailingDate: Scalars['String'];
  mainImageId?: Maybe<Scalars['ID']>;
  mainImageSrc?: Maybe<Scalars['String']>;
  mainImageCopyright?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  leftColoumn?: Maybe<Scalars['String']>;
  rightColoumn?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  sent: Array<Maybe<Scalars['String']>>;
  failed: Array<Maybe<Scalars['String']>>;
  state: MailingState;
  sendProcessId?: Maybe<Scalars['String']>;
  showOnWebsite: Scalars['Boolean'];
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type NewsletterMailingHtml = {
  __typename?: 'NewsletterMailingHtml';
  id: Scalars['ID'];
  mailingDate: Scalars['String'];
  subject: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
};

export type NewsletterMailingHtmlPagination = {
  __typename?: 'NewsletterMailingHtmlPagination';
  items: Array<NewsletterMailingHtml>;
  nextCursor?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type NewsletterMailingItem = {
  __typename?: 'NewsletterMailingItem';
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['ID']>;
  imageSrc?: Maybe<Scalars['String']>;
  imageCopyright?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  orderNr?: Maybe<Scalars['Int']>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type NewsletterMailingItemElemUpdate = {
  id: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  imageId?: Maybe<Scalars['ID']>;
  imageCopyright?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  orderNr?: Maybe<Scalars['Int']>;
  task: ArrayUpdate;
};

export type NewsletterMailingPagination = {
  __typename?: 'NewsletterMailingPagination';
  items: Array<NewsletterMailing>;
  nextCursor?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type NewsletterPagination = {
  __typename?: 'NewsletterPagination';
  items: Array<Newsletter>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type NextCursorInput = {
  pageSize: Scalars['Int'];
  nextCursor: Scalars['String'];
  sorted?: Maybe<Array<SortInput>>;
  filtered?: Maybe<Array<FilterInput>>;
};

export type Person = {
  __typename?: 'Person';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  birthdate: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  /** The persons AHV number */
  ahvNumber?: Maybe<Scalars['String']>;
  /** The persons J+S number */
  jsNumber?: Maybe<Scalars['String']>;
  /** The persons SLRG number */
  slrgNumber?: Maybe<Scalars['String']>;
  /** All the newsletter the person wants */
  newsletter?: Maybe<Array<Newsletter>>;
  state?: Maybe<State>;
  /** Which Type of State the user is in */
  stateType?: Maybe<StateType>;
  /** The highest sport education of the user */
  hasSportsEducation?: Maybe<Array<SportsEducation>>;
  /** All the current working levels */
  currentWork?: Maybe<Array<WorkLevel>>;
  svssFunctions?: Maybe<Array<SvssFunction>>;
  stateFunctions?: Maybe<Array<StateFunction>>;
  /** Which kind of SVSS member is the person */
  membership?: Maybe<Membership>;
  /** To which association is the SVSS bill payed (only needed if membership = association) */
  associationPayTo?: Maybe<Association>;
  /** All the associations the person is in */
  associationPersons?: Maybe<Array<AssociationPerson>>;
  associationComment?: Maybe<Scalars['String']>;
  /** All the addresses of the person */
  addresses: Array<Address>;
  /** Internal comment for the user */
  comment?: Maybe<Scalars['String']>;
  /** authId of the user on the auth server */
  authId: Scalars['String'];
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type PersonElemCreate = {
  id: Scalars['ID'];
};

export type PersonElemUpdate = {
  id: Scalars['ID'];
  task: ArrayTasks;
};

export type PersonPagination = {
  __typename?: 'PersonPagination';
  items: Array<Person>;
  nextCursor?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProcessState = {
  __typename?: 'ProcessState';
  errorMessage: Scalars['String'];
  processId: Scalars['ID'];
  progress: Scalars['Int'];
  returnCode: Scalars['Int'];
};

export type Product = {
  uniqueId: Scalars['ID'];
  sku: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  amountIncludingTax: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getMailing: Mailing;
  getNewsletterMailing: NewsletterMailing;
  getNewsletterMailingItem: NewsletterMailingItem;
  getNewsletterMailings: NewsletterMailingPagination;
  getNextNewsletterMailings: NewsletterMailingPagination;
  getNewsletterMailingHtml: NewsletterMailingHtml;
  getNewsletterMailingsHtml: NewsletterMailingHtmlPagination;
  getNextNewsletterMailingsHtml: NewsletterMailingHtmlPagination;
  getAddress: Address;
  getAddresses: AddressPagination;
  getNextAddresses: AddressPagination;
  getAddressType: AddressType;
  getAddressInitType: AddressType;
  getAddressTypes: AddressTypePagination;
  getNextAddressTypes: AddressTypePagination;
  getState: State;
  getInitState: State;
  getStateNoPerson: State;
  getStates: StatePagination;
  getStatesNoPerson: StatePagination;
  getNextStates: StatePagination;
  getNextStatesNoPerson: StatePagination;
  getStateType: StateType;
  getStateTypes: StateTypePagination;
  getNextStateTypes: StateTypePagination;
  getMembership: Membership;
  getMembershipNoPerson: Membership;
  getMembershipIfMember: Membership;
  getMemberships: MembershipPagination;
  getMembershipsNoPerson: MembershipPagination;
  getNextMemberships: MembershipPagination;
  getNextMembershipsNoPerson: MembershipPagination;
  getAssociation: Association;
  getAssociationNoPerson: Association;
  getAssociationIfMember: Association;
  getAssociations: AssociationPagination;
  getAssociationsNoPerson: AssociationPagination;
  getAssociationsIfMember: AssociationPagination;
  getAssociationsForFrontend: Array<AssociationWithPresident>;
  getNextAssociations: AssociationPagination;
  getNextAssociationsNoPerson: AssociationPagination;
  getNextAssociationsIfMember: AssociationPagination;
  getDefaultAssociationType: AssociationType;
  getAssociationType: AssociationType;
  getAssociationTypeNoPerson: AssociationType;
  getAssociationTypes: AssociationTypePagination;
  getAssociationTypesNoPerson: AssociationTypePagination;
  getNextAssociationTypes: AssociationTypePagination;
  getNextAssociationTypesNoPerson: AssociationTypePagination;
  getPerson: Person;
  getPersonIfOwns: Person;
  getPersonIfManages: Person;
  getPersonByAuthId: Person;
  getPersonIfOwnsByAuthId: Person;
  getPersonIfManagesByAuthId: Person;
  getPersons: PersonPagination;
  getPersonsIfManages: PersonPagination;
  getPersonsIfOwns: PersonPagination;
  getNextPersons: PersonPagination;
  getNextPersonsIfManages: PersonPagination;
  getNextPersonsIfOwns: PersonPagination;
  getWorkLevel: WorkLevel;
  getWorkLevelNoPerson: WorkLevel;
  getWorkLevels: WorkLevelPagination;
  getWorkLevelsNoPerson: WorkLevelPagination;
  getNextWorkLevels: WorkLevelPagination;
  getNextWorkLevelsNoPerson: WorkLevelPagination;
  getSvssFunction: SvssFunction;
  getSvssFunctionNoPerson: SvssFunction;
  getSvssFunctions: SvssFunctionPagination;
  getSvssFunctionsNoPerson: SvssFunctionPagination;
  getNextSvssFunctions: SvssFunctionPagination;
  getNextSvssFunctionsNoPerson: SvssFunctionPagination;
  getStateFunction: StateFunction;
  getStateFunctionNoPerson: StateFunction;
  getStateFunctions: StateFunctionPagination;
  getStateFunctionsNoPerson: StateFunctionPagination;
  getNextStateFunctions: StateFunctionPagination;
  getNextStateFunctionsNoPerson: StateFunctionPagination;
  getSportsEducation: SportsEducation;
  getSportsEducationNoPerson: SportsEducation;
  getSportsEducations: SportsEducationPagination;
  getSportsEducationsNoPerson: SportsEducationPagination;
  getNextSportsEducations: SportsEducationPagination;
  getNextSportsEducationsNoPerson: SportsEducationPagination;
  getNewsletter: Newsletter;
  getNewsletterNoPerson: Newsletter;
  getNewsletters: NewsletterPagination;
  getNewslettersNoPerson: NewsletterPagination;
  getNewslettersToRegisterNoPerson: NewsletterPagination;
  getNextNewsletters: NewsletterPagination;
  getNextNewslettersNoPerson: NewsletterPagination;
  getNextNewslettersToRegisterNoPerson: NewsletterPagination;
  getNewsletterMailaddresses: Array<Scalars['String']>;
  getBillingInformation: BillingInformation;
  getInvoice: Invoice;
  getInvoices: InvoicePagination;
  getNextInvoices: InvoicePagination;
  getInvoiceIfFromAssociation: Invoice;
  getInvoicesIfFromAssociation: InvoicePagination;
  getNextInvoicesIfFromAssociation: InvoicePagination;
  getInvoiceIfToAssociation: Invoice;
  getInvoicesIfToAssociation: InvoicePagination;
  getNextInvoicesIfToAssociation: InvoicePagination;
  getInvoiceIfOwner: Invoice;
  getInvoicesIfOwner: InvoicePagination;
  getNextInvoicesIfOwner: InvoicePagination;
  dummy?: Maybe<Scalars['String']>;
  getCourse: Course;
  getCourses: CoursePagination;
  getNextCourses: CoursePagination;
  getMyCourses?: Maybe<Array<CourseRegistration>>;
  getSignupRecipients?: Maybe<Array<SignupRecipient>>;
  getCourseRegistrations: CourseRegistrationPagination;
  getNextCourseRegistrations: CourseRegistrationPagination;
};


export type QueryGetMailingArgs = {
  id: Scalars['ID'];
};


export type QueryGetNewsletterMailingArgs = {
  id: Scalars['ID'];
};


export type QueryGetNewsletterMailingItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetNewsletterMailingsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextNewsletterMailingsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNewsletterMailingHtmlArgs = {
  id: Scalars['ID'];
};


export type QueryGetNewsletterMailingsHtmlArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextNewsletterMailingsHtmlArgs = {
  cursor: NextCursorInput;
};


export type QueryGetAddressArgs = {
  id: Scalars['ID'];
};


export type QueryGetAddressesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextAddressesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetAddressTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetAddressTypesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextAddressTypesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetStateArgs = {
  id: Scalars['ID'];
};


export type QueryGetStateNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetStatesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetStatesNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextStatesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextStatesNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetStateTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetStateTypesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextStateTypesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetMembershipArgs = {
  id: Scalars['ID'];
};


export type QueryGetMembershipNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetMembershipIfMemberArgs = {
  id: Scalars['ID'];
};


export type QueryGetMembershipsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetMembershipsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextMembershipsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextMembershipsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetAssociationArgs = {
  id: Scalars['ID'];
};


export type QueryGetAssociationNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetAssociationIfMemberArgs = {
  id: Scalars['ID'];
};


export type QueryGetAssociationsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationsIfMemberArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextAssociationsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationsIfMemberArgs = {
  cursor: NextCursorInput;
};


export type QueryGetAssociationTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetAssociationTypeNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetAssociationTypesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationTypesNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextAssociationTypesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationTypesNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetPersonIfOwnsArgs = {
  id: Scalars['ID'];
};


export type QueryGetPersonIfManagesArgs = {
  id: Scalars['ID'];
};


export type QueryGetPersonByAuthIdArgs = {
  authId: Scalars['ID'];
};


export type QueryGetPersonIfOwnsByAuthIdArgs = {
  authId: Scalars['ID'];
};


export type QueryGetPersonIfManagesByAuthIdArgs = {
  authId: Scalars['ID'];
};


export type QueryGetPersonsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetPersonsIfManagesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetPersonsIfOwnsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextPersonsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextPersonsIfManagesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextPersonsIfOwnsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetWorkLevelArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkLevelNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkLevelsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetWorkLevelsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextWorkLevelsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextWorkLevelsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetSvssFunctionArgs = {
  id: Scalars['ID'];
};


export type QueryGetSvssFunctionNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetSvssFunctionsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetSvssFunctionsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextSvssFunctionsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextSvssFunctionsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetStateFunctionArgs = {
  id: Scalars['ID'];
};


export type QueryGetStateFunctionNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetStateFunctionsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetStateFunctionsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextStateFunctionsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextStateFunctionsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetSportsEducationArgs = {
  id: Scalars['ID'];
};


export type QueryGetSportsEducationNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetSportsEducationsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetSportsEducationsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextSportsEducationsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextSportsEducationsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNewsletterArgs = {
  id: Scalars['ID'];
};


export type QueryGetNewsletterNoPersonArgs = {
  id: Scalars['ID'];
};


export type QueryGetNewslettersArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNewslettersNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNewslettersToRegisterNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextNewslettersArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextNewslettersNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextNewslettersToRegisterNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNewsletterMailaddressesArgs = {
  id: Scalars['ID'];
};


export type QueryGetBillingInformationArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvoicesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextInvoicesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetInvoiceIfFromAssociationArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvoicesIfFromAssociationArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextInvoicesIfFromAssociationArgs = {
  cursor: NextCursorInput;
};


export type QueryGetInvoiceIfToAssociationArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvoicesIfToAssociationArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextInvoicesIfToAssociationArgs = {
  cursor: NextCursorInput;
};


export type QueryGetInvoiceIfOwnerArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvoicesIfOwnerArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextInvoicesIfOwnerArgs = {
  cursor: NextCursorInput;
};


export type QueryGetCourseArgs = {
  id: Scalars['ID'];
};


export type QueryGetCoursesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextCoursesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetMyCoursesArgs = {
  userID: Scalars['ID'];
};


export type QueryGetCourseRegistrationsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextCourseRegistrationsArgs = {
  cursor: NextCursorInput;
};

export type SendReturn = {
  __typename?: 'SendReturn';
  item: NewsletterMailing;
  process: ProcessState;
};

export type Sepa = {
  __typename?: 'Sepa';
  id: Scalars['ID'];
  fileId: Scalars['Int'];
  filename: Scalars['String'];
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type SepaReturn = {
  __typename?: 'SepaReturn';
  sepa?: Maybe<Sepa>;
  errorCode: Scalars['Int'];
  errorMessages: Array<Maybe<Scalars['String']>>;
  successMessages: Array<Maybe<Scalars['String']>>;
};

export enum SignUpState {
  Confirmed = 'confirmed',
  Unconfirmed = 'unconfirmed',
  WaitingList = 'waitingList',
  Cancelled = 'cancelled',
  Payed = 'payed',
  LogInFirst = 'logInFirst',
  CompleteProfile = 'completeProfile',
  AlreadyRegistered = 'alreadyRegistered',
  AlreadyRegisteredAndPayed = 'alreadyRegisteredAndPayed',
  FullyBooked = 'fullyBooked',
  SignUp = 'signUp'
}

export type SignupRecipient = {
  __typename?: 'SignupRecipient';
  id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
};

export type SortInput = {
  id: Scalars['String'];
  asc: Scalars['Boolean'];
};

export type SportsEducation = {
  __typename?: 'SportsEducation';
  id: Scalars['ID'];
  /** Name of the sports education */
  name: TranslationObject;
  /** People who work at this level */
  persons?: Maybe<Array<Person>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type SportsEducationElemCreate = {
  /** id of of the sports education to create */
  id: Scalars['ID'];
};

export type SportsEducationElemUpdate = {
  /** id of of the sports education to update */
  id: Scalars['ID'];
  /** which action do you want to do with that sports education */
  task: ArrayTasks;
};

export type SportsEducationPagination = {
  __typename?: 'SportsEducationPagination';
  items: Array<SportsEducation>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type StartCursorInput = {
  includeTotalCount?: Maybe<Scalars['Boolean']>;
  pageSize: Scalars['Int'];
  sorted?: Maybe<Array<SortInput>>;
  filtered?: Maybe<Array<FilterInput>>;
};

export type State = {
  __typename?: 'State';
  id: Scalars['ID'];
  name: TranslationObject;
  isInitState: Scalars['Boolean'];
  highlightColor: Scalars['String'];
  types?: Maybe<Array<StateType>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type StateFunction = {
  __typename?: 'StateFunction';
  id: Scalars['ID'];
  /** Name of the work level */
  name: TranslationObject;
  /** People who work at this level */
  people?: Maybe<Array<Person>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type StateFunctionElemCreate = {
  /** id of of the work level to create */
  id: Scalars['ID'];
};

export type StateFunctionElemUpdate = {
  /** id of of the work level to update */
  id: Scalars['ID'];
  /** which action do you want to do with that work level */
  task: ArrayTasks;
};

export type StateFunctionPagination = {
  __typename?: 'StateFunctionPagination';
  items: Array<StateFunction>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type StatePagination = {
  __typename?: 'StatePagination';
  items: Array<State>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type StateType = {
  __typename?: 'StateType';
  id: Scalars['ID'];
  name: TranslationObject;
  state: State;
  persons?: Maybe<Array<Person>>;
  isTrialType: Scalars['Boolean'];
  requireMembership: Scalars['Boolean'];
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type StateTypePagination = {
  __typename?: 'StateTypePagination';
  items: Array<StateType>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  addedMailing: Mailing;
  updatedMailing: Mailing;
  deletedMailing: Mailing;
  addedNewsletterMailing: NewsletterMailing;
  updatedNewsletterMailing: NewsletterMailing;
  deletedNewsletterMailing: NewsletterMailing;
  addedNewsletterMailingItem: NewsletterMailingItem;
  updatedNewsletterMailingItem: NewsletterMailingItem;
  deletedNewsletterMailingItem: NewsletterMailingItem;
  addedAddress: Address;
  updatedAddress: Address;
  deletedAddress: Address;
  addedAddressType: AddressType;
  updatedAddressType: AddressType;
  deletedAddressType: AddressType;
  addedState: State;
  updatedState: State;
  deletedState: State;
  addedStateType: StateType;
  updatedStateType: StateType;
  deletedStateType: StateType;
  addedMembership: Membership;
  updatedMembership: Membership;
  deletedMembership: Membership;
  addedAssociation: Association;
  updatedAssociation: Association;
  updatedAssociationIfMember: Association;
  deletedAssociation: Association;
  deletedAssociationIfMember: Association;
  addedAssociationType: AssociationType;
  updatedAssociationType: AssociationType;
  deletedAssociationType: AssociationType;
  addedPerson: Person;
  updatedPerson: Person;
  updatedPersonIfOwns: Person;
  deletedPerson: Person;
  addedWorkLevel: WorkLevel;
  updatedWorkLevel: WorkLevel;
  deletedWorkLevel: WorkLevel;
  addedSvssFunction: SvssFunction;
  updatedSvssFunction: SvssFunction;
  deletedSvssFunction: SvssFunction;
  addedStateFunction: StateFunction;
  updatedStateFunction: StateFunction;
  deletedStateFunction: StateFunction;
  addedSportsEducation: SportsEducation;
  updatedSportsEducation: SportsEducation;
  deletedSportsEducation: SportsEducation;
  addedNewsletter: Newsletter;
  updatedNewsletter: Newsletter;
  deletedNewsletter: Newsletter;
  addedBillingInformation: BillingInformation;
  updatedBillingInformation: BillingInformation;
  deletedBillingInformation: BillingInformation;
  addedInvoice: Invoice;
  updatedInvoice: Invoice;
  updatedInvoiceIfAssociation: Invoice;
  updatedInvoiceIfOwner: Invoice;
  deletedInvoice: Invoice;
  addedTransaction: SvssTransaction;
  addedSepa: Sepa;
  deletedSepa: Sepa;
  addedCourse: Course;
  updatedCourse: Course;
  deletedCourse: Course;
};

export type SvssFunction = {
  __typename?: 'SvssFunction';
  id: Scalars['ID'];
  /** Name of the work level */
  name: TranslationObject;
  /** People who work at this level */
  people?: Maybe<Array<Person>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type SvssFunctionElemCreate = {
  /** id of of the work level to create */
  id: Scalars['ID'];
};

export type SvssFunctionElemUpdate = {
  /** id of of the work level to update */
  id: Scalars['ID'];
  /** which action do you want to do with that work level */
  task: ArrayTasks;
};

export type SvssFunctionPagination = {
  __typename?: 'SvssFunctionPagination';
  items: Array<SvssFunction>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type SvssTransaction = {
  __typename?: 'SvssTransaction';
  id: Scalars['ID'];
  state: Scalars['String'];
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type Transaction = {
  __typename?: 'Transaction';
  fancyboxUrl: Scalars['String'];
  transactionId: Scalars['ID'];
};

export enum TranslationLanguage {
  De = 'de',
  Fr = 'fr',
  It = 'it'
}

export type TranslationObject = {
  __typename?: 'TranslationObject';
  de: Scalars['String'];
  fr: Scalars['String'];
  it: Scalars['String'];
};

export type TranslationObjectCreate = {
  de: Scalars['String'];
  fr: Scalars['String'];
  it: Scalars['String'];
};

export type TranslationObjectUpdate = {
  de?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
};


export type WorkLevel = {
  __typename?: 'WorkLevel';
  id: Scalars['ID'];
  /** Name of the work level */
  name: TranslationObject;
  /** People who work at this level */
  worksOnLevel?: Maybe<Array<Person>>;
  /** When the object was created */
  createdAt: Scalars['String'];
  /** Which user created the object */
  createdFrom: Scalars['Int'];
  /** When the object was updated at last */
  updatedAt: Scalars['String'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int'];
};

export type WorkLevelElemCreate = {
  /** id of of the work level to create */
  id: Scalars['ID'];
};

export type WorkLevelElemUpdate = {
  /** id of of the work level to update */
  id: Scalars['ID'];
  /** which action do you want to do with that work level */
  task: ArrayTasks;
};

export type WorkLevelPagination = {
  __typename?: 'WorkLevelPagination';
  items: Array<WorkLevel>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type CreateInvoiceReturn = {
  __typename?: 'createInvoiceReturn';
  errorMessage: Scalars['String'];
  processId: Scalars['ID'];
  progress: Scalars['Int'];
  returnCode: Scalars['Int'];
};
