import { useRef } from 'react'
import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'
import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'

import InvoicesTableData from './InvoicesTable/InvoicesTable.data'
import InvoiceTableIfAssociationData from './InvoicesTable/InvoicesTableIfAssociation.data'
import InvoicesFilterData from './InvoicesFilter/InvoicesFilter.data'
import InvoicesFunctions from './InvoicesFunctions/InvoicesFunctions'
import InvoiceEditData from './InvoiceEdit/InvoiceEdit.data'
import InvoiceEditIfAssociationData from './InvoiceEdit/InvoiceEditIfAssociation.data'

export default function InvoicesOverview({
  subNav,
  searchQuery,
  setSearchQuery,
  user,
  selectedInvoices,
  setSelectedInvoices,
  goToInvoice,
  invoiceId,
  personId,
  payedFilter,
  setPayedFilter,
}: {
  subNav?: InnerRoute[]
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  user: UserType | undefined
  selectedInvoices: string[]
  setSelectedInvoices: React.Dispatch<React.SetStateAction<string[]>>
  goToInvoice: (id: string | null) => void
  invoiceId: string | undefined
  personId?: string
  payedFilter: string[] | null
  setPayedFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
  const invoicesTableDataRef = useRef<{
    refetchInvoices: () => void
  }>()
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          {subNav && (
            <Cell span={[12, 12, 3]}>
              <SideMenu menuItems={subNav} />
            </Cell>
          )}
          <Cell span={[12, 12, (subNav ? 9 : 12)]}>
            {!personId && (
              <Block>
                <Block>
                  <Block
                    paddingTop={['15px', '15px', '15px', 0]}
                    overrides={{
                      Block: {
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '30px',
                        },
                      },
                    }}
                  >
                    <Block>
                      <Block
                        as='h3'
                        overrides={{
                          Block: {
                            style: ({ $theme }) => {
                              return {
                                ...$theme.typography.font450,
                                color: $theme.colors.primaryA,
                                marginBottom: '15px',
                              }
                            },
                          },
                        }}
                      >
                        <FormattedMessage
                          id='invoices.invoicesOverview'
                          defaultMessage={'Overview'}
                        />
                      </Block>
                    </Block>
                  </Block>
                </Block>
              </Block>
            )}
            <Block>
              {invoiceId && parseInt(invoiceId) > 0 ? (
                user &&
                  isInGroupOrAdmin(user, [
                    process.env.REACT_APP_GROUP_MANAGERS || '',
                    process.env.REACT_APP_GROUP_ADMINS || '',
                  ]) ? (
                  <InvoiceEditData
                    goToInvoice={goToInvoice}
                    invoiceId={invoiceId}
                  />
                ) : (
                  <InvoiceEditIfAssociationData
                    goToInvoice={goToInvoice}
                    invoiceId={invoiceId}
                  />
                )
              ) : (
                <>
                  <Block
                    overrides={{
                      Block: {
                        style: () => {
                          return {
                            marginBottom: '15px',
                          }
                        },
                      },
                    }}
                  >
                    <InvoicesFilterData
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      payedFilter={payedFilter}
                      setPayedFilter={setPayedFilter}
                    />
                  </Block>
                  <Block
                    overrides={{
                      Block: {
                        style: () => {
                          return {
                            marginBottom: '0',
                          }
                        },
                      },
                    }}
                  >
                    <InvoicesFunctions
                      invoicesTableDataRef={invoicesTableDataRef}
                      searchQuery={searchQuery}
                      selectedInvoices={selectedInvoices}
                      setSelectedInvoices={setSelectedInvoices}
                      user={user}
                      personId={personId} 
                    />
                  </Block>
                  {user &&
                    isInGroupOrAdmin(user, [
                      process.env.REACT_APP_GROUP_MANAGERS || '',
                      process.env.REACT_APP_GROUP_ADMINS || '',
                    ]) ? (
                    <InvoicesTableData
                      goToInvoice={goToInvoice}
                      searchQuery={searchQuery}
                      payedFilter={payedFilter}
                      ref={invoicesTableDataRef}
                      selectedInvoices={selectedInvoices}
                      setSelectedInvoices={setSelectedInvoices}
                      personId={personId}
                    />
                  ) : (
                    <InvoiceTableIfAssociationData
                      goToInvoice={goToInvoice}
                      searchQuery={searchQuery}
                      payedFilter={payedFilter}
                      ref={invoicesTableDataRef}
                      selectedInvoices={selectedInvoices}
                      setSelectedInvoices={setSelectedInvoices}
                      personId={personId}
                    />
                  )}
                </>
              )}
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
