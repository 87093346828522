import { useIntl } from 'react-intl'

import CheckboxFilter from '@lemonbrain/components/UiElements/SearchFilters/Filters/CheckboxFilter'

export default function AssociationFilterBusiness({
  associations,
  loadingAssociations,
  associationFilter,
  setAssociationFilter,
}: {
  associations: {
    id: string
    label: string
  }[]
  loadingAssociations: boolean
  associationFilter: string[] | null
  setAssociationFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
  const intl = useIntl()

  return (
    <CheckboxFilter
      values={associations}
      loadingValues={loadingAssociations}
      filter={associationFilter}
      setFilter={setAssociationFilter}
      label={intl.formatMessage({
        id: 'members.association_filter.association',
        defaultMessage: 'Association',
      })}
      comment={intl.formatMessage({
        id: 'members.association_filter.associationComment',
        defaultMessage: 'Is in following Associations',
      })}
    />
  )
}
