import WorkLevelEditBusiness from './WorkLevelEdit.business'
import {
    useGetWorkLevelLazyQuery,
  useUpdateWorkLevelMutation,
  useAddWorkLevelMutation,
} from '../../../gql/WorkLevel.generated'
import getReactApolloCients from '../../../localReactApollo'

import { useEffect, useState } from 'react'

export default function WorkLevelEditData({
  workLevelId,
  setWorkLevelId,
}: {
  workLevelId: string | null | undefined
  setWorkLevelId: (workLevelId: string | undefined) => void
}) {
  const [values, setValues] = useState<{
    de: string | undefined
    fr: string | undefined
    it: string | undefined
  }>({
    de: undefined,
    fr: undefined,
    it: undefined,
  })
  const client = getReactApolloCients().getClient()
  const [
    getWorkLevelQuery,
    { data, loading, error: queryError },
  ] = useGetWorkLevelLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateWorkLevelMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateWorkLevelMutation({
    //@ts-ignore
    client,
  })
  const [
    addWorkLevelMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddWorkLevelMutation({
    //@ts-ignore
    client,
  })

  async function updateWorkLevelEntry(data: {
    workLevelId: string
    de: string
    fr: string
    it: string
  }) {
    if (data.workLevelId) {
      await updateWorkLevelMutation({
        variables: {
          id: data.workLevelId,
          name: {
              de: data.de,
              fr: data.fr,
              it: data.it
          }
        },
      })
    }
  }

  async function createWorkLevelEntry(data: {
    de: string
    fr: string
    it: string
  }) {
    await addWorkLevelMutation({
      variables: {
        name: {
            de: data.de,
            fr: data.fr,
            it: data.it
        }
      },
    })
  }

  function removeWorkLevelId() {
    setWorkLevelId(undefined)
  }

  useEffect(() => {
    if (workLevelId) {
        getWorkLevelQuery({
        variables: {
          id: workLevelId,
        },
      })
    }
  }, [workLevelId])

  useEffect(() => {
    if (data?.getWorkLevel) {

      setValues({
        de: data.getWorkLevel.name.de,
        fr: data.getWorkLevel.name.fr,
        it: data.getWorkLevel.name.it,
      })
    }
  }, [data])

  return (
    <WorkLevelEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      queryError={queryError || queryChangeError || queryAddError}
      removeWorkLevelId={removeWorkLevelId}
      workLevelId={workLevelId}
      updateWorkLevelEntry={updateWorkLevelEntry}
      createWorkLevelEntry={createWorkLevelEntry}
    />
  )
}
