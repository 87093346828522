import { useIntl } from 'react-intl'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Datepicker } from 'baseui/datepicker'

export default function Personal({
  values,
  setValues,
  errors,
}: {
  values:
    | {
        id: string
        email: string
        birthdate: string
        telephone: string
        mobile: string
        ahvNumber: string
        jsNumber: string
        slrgNumber: string
      }
    | undefined
  setValues: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          email: string
          birthdate: string
          telephone: string
          mobile: string
          ahvNumber: string
          jsNumber: string
          slrgNumber: string
        }
      | undefined
    >
  >
  errors: {
    email: string
    birthdate: string
    ahvNumber: string
  }
}) {
  const intl = useIntl()

  function getBirthdate() {
    let birthdate = new Date(
      new Date().getFullYear() - 50,
      new Date().getMonth(),
      new Date().getDate()
    )
    if (values && values.birthdate && values.birthdate !== '') {
      birthdate = new Date(values?.birthdate || '')
    }

    return birthdate
  }

  return (
    <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.personal.email',
            defaultMessage: 'Correspondence e-mail',
          })}
          error={errors.email}
        >
          <Input
            value={values && values.email}
            onChange={(e) =>
              setValues(
                (prev) =>
                  prev &&
                  prev && {
                    ...prev,
                    email: (e.target as HTMLInputElement).value,
                  }
              )
            }
            positive={errors.email === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.personal.birthdate',
            defaultMessage: 'Birthdate',
          })}
          error={errors.birthdate}
        >
          <Datepicker
            value={getBirthdate()}
            onChange={({ date }) => {
              date &&
                (date as Date).getFullYear() > 1900 &&
                setValues(
                  (prev) =>
                    prev &&
                    prev && {
                      ...prev,
                      birthdate: (date as Date).toISOString().split('T')[0],
                    }
                )
            }}
            formatString='dd.MM.yyyy'
            placeholder='dd.mm.yyyy'
            positive={errors.birthdate === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.personal.telephone',
            defaultMessage: 'Telephonenumber',
          })}
        >
          <Input
            value={values && values.telephone}
            onChange={(e) =>
              setValues(
                (prev) =>
                  prev &&
                  prev && {
                    ...prev,
                    telephone: (e.target as HTMLInputElement).value,
                  }
              )
            }
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.personal.mobile',
            defaultMessage: 'Mobilenumber',
          })}
        >
          <Input
            value={values && values.mobile}
            onChange={(e) =>
              setValues(
                (prev) =>
                  prev &&
                  prev && {
                    ...prev,
                    mobile: (e.target as HTMLInputElement).value,
                  }
              )
            }
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.personal.ahvNumber',
            defaultMessage: 'AHV Number',
          })}
          error={errors.ahvNumber}
        >
          <Input
            value={values && values.ahvNumber}
            onChange={(e) =>
              setValues(
                (prev) =>
                  prev &&
                  prev && {
                    ...prev,
                    ahvNumber: (e.target as HTMLInputElement).value,
                  }
              )
            }
            positive={errors.ahvNumber === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.personal.jsNumber',
            defaultMessage: 'J+S Number',
          })}
        >
          <Input
            value={values && values.jsNumber}
            onChange={(e) =>
              setValues(
                (prev) =>
                  prev &&
                  prev && {
                    ...prev,
                    jsNumber: (e.target as HTMLInputElement).value,
                  }
              )
            }
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.personal.slrgNumber',
            defaultMessage: 'SLRG Number',
          })}
        >
          <Input
            value={values && values.slrgNumber}
            onChange={(e) =>
              setValues(
                (prev) =>
                  prev &&
                  prev && {
                    ...prev,
                    slrgNumber: (e.target as HTMLInputElement).value,
                  }
              )
            }
          />
        </FormControl>
      </Cell>
    </Grid>
  )
}
