import ExportAttendeesList from './ExportAttendeesList'

export default function ExportAttendeesListBusiness({
  loading,
  startExport,
}: {
  loading: boolean
  startExport: () => void
}) {
  return (
    <ExportAttendeesList
      loading={loading}
      onClick={startExport}
    />
  )
}
