import { useIntl } from 'react-intl'

import { useJson2CsvExporter } from '@lemonbrain/hooks'
import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'

import ExportData from './Export.data'
import ExportIfManagesData from './ExportIfManages.data'

export default function ExportFile({
  selectedPerson,
  user,
}: {
  selectedPerson: string[]
  user: UserType | undefined
}) {
  const intl = useIntl()
  const setNext = useJson2CsvExporter(
    //@ts-ignore
    `${new Date().toLocaleDateString().replaceAll('.', '_')}_MembersExport.csv`,
    {
      fields: [
        {
          label: intl.formatMessage({
            id: 'members.export.addressSalutation',
            defaultMessage: 'Salutation',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].salutation : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressFirstName',
            defaultMessage: 'First name',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].firstName : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressLastName',
            defaultMessage: 'Last name',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].lastName : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.email',
            defaultMessage: 'E-Mail',
          }),
          value: 'email',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.birthdate',
            defaultMessage: 'Birthdate',
          }),
          value: (
            row: any,
            field: {
              label: string
              default?: string
            }
          ) => {
            let birthdate: Date | string | undefined = field.default
            if (row && row.birthdate !== '') {
              birthdate = new Date(row.birthdate)
            }
            return birthdate instanceof Date
              ? birthdate.toLocaleDateString()
              : birthdate
          },
        },
        {
          label: intl.formatMessage({
            id: 'members.export.telephone',
            defaultMessage: 'Telephone',
          }),
          value: 'telephone',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.mobile',
            defaultMessage: 'Mobile',
          }),
          value: 'mobile',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.ahvNumber',
            defaultMessage: 'AHV Number',
          }),
          value: 'ahvNumber',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.jsNumber',
            defaultMessage: 'J+S Number',
          }),
          value: 'jsNumber',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.slrgNumber',
            defaultMessage: 'SLRG Number',
          }),
          value: 'slrgNumber',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.comment',
            defaultMessage: 'Comment',
          }),
          value: 'comment',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressFirmName',
            defaultMessage: 'Firm name',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].firmName : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressMailbox',
            defaultMessage: 'Mailbox',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].mailbox : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressStreetNr',
            defaultMessage: 'Street nr',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].streetNr : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressPostCode',
            defaultMessage: 'Post code',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].postCode : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressPlace',
            defaultMessage: 'Place',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].place : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressCanton',
            defaultMessage: 'Canton',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].canton : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressCountry',
            defaultMessage: 'Country',
          }),
          value: (row: any) => row.addresses[0] ? row.addresses[0].country : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressIsFirm',
            defaultMessage: 'Is firmaddress',
          }),
          value: (row: any) => {
            return row.addresses[0] && row.addresses[0].types[0] && row.addresses[0].types[0].isFirm
              ? intl.formatMessage({
                  id: 'members.export.addressIsFirm.yes',
                  defaultMessage: 'Yes',
                })
              : intl.formatMessage({
                  id: 'members.export.addressIsFirm.no',
                  defaultMessage: 'No',
                })
          },
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressFirmName',
            defaultMessage: 'Firm name',
          }),
          value: (row: any) => row.addresses[1] ? row.addresses[1].firmName : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressMailbox',
            defaultMessage: 'Mailbox',
          }),
          value: (row: any) => row.addresses[1] ? row.addresses[1].mailbox : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressStreetNr',
            defaultMessage: 'Street nr',
          }),
          value: (row: any) => row.addresses[1] ? row.addresses[1].streetNr : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressPostCode',
            defaultMessage: 'Post code',
          }),
          value: (row: any) => row.addresses[1] ? row.addresses[1].postCode : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressPlace',
            defaultMessage: 'Place',
          }),
          value: (row: any) => row.addresses[1] ? row.addresses[1].place : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressCanton',
            defaultMessage: 'Canton',
          }),
          value: (row: any) => row.addresses[1] ? row.addresses[1].canton : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressCountry',
            defaultMessage: 'Country',
          }),
          value: (row: any) => row.addresses[1] ? row.addresses[1].country : '',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.addressIsFirm',
            defaultMessage: 'Is firmaddress',
          }),
          value: (row: any) => {
            return row.addresses[1] && row.addresses[1].types[0] && row.addresses[1].types[0].isFirm
              ? intl.formatMessage({
                  id: 'members.export.addressIsFirm.yes',
                  defaultMessage: 'Yes',
                })
              : intl.formatMessage({
                  id: 'members.export.addressIsFirm.no',
                  defaultMessage: 'No',
                })
          },
        },
        {
          label: intl.formatMessage({
            id: 'members.export.AddressType',
            defaultMessage: 'Type',
          }),
          value: 'addresses.types.name.de',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.hasSportsEducation',
            defaultMessage: 'Has sports education',
          }),
          value: (row: any) => {
            return row.hasSportsEducation && row.hasSportsEducation
              .map((item: { name: { de: string } }) => item.name.de)
              .join(', ')
          },
        },
        {
          label: intl.formatMessage({
            id: 'members.export.currentWork',
            defaultMessage: 'Current work',
          }),
          value: (row: any) => {
            return row.currentWork
              .map((item: { name: { de: string } }) => item.name.de)
              .join(', ')
          },
        },
        {
          label: intl.formatMessage({
            id: 'members.export.associationPersons',
            defaultMessage: 'Association',
          }),
          value: (row: any) => {
            return row.associationPersons && row.associationPersons
              .map(
                (item: {
                  association: {
                    name: { de: string }
                  }
                  associationType?: {
                    name: { de: string }
                  }
                }) =>
                  `${item.association.name.de} ${item.associationType && (`[${item.associationType.name.de}]`)}`
              )
              .join(', ')
          },
        },
        {
          label: intl.formatMessage({
            id: 'members.export.associationPayTo',
            defaultMessage: 'Association pay to',
          }),
          value: 'associationPayTo.name.de',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.state',
            defaultMessage: 'State',
          }),
          value: 'state.name.de',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.stateType',
            defaultMessage: 'State type',
          }),
          value: 'stateType.name.de',
        },
        {
          label: intl.formatMessage({
            id: 'members.export.id',
            defaultMessage: 'ID',
          }),
          value: 'id',
        },
      ],
    }
  )

  return user &&
            isInGroupOrAdmin(user, [
              process.env.REACT_APP_GROUP_MANAGERS || '',
              process.env.REACT_APP_GROUP_ADMINS || '',
            ]) ? (
    <ExportData
      setNext={setNext}
      selectedPerson={selectedPerson}
    />
  ) : (
    <ExportIfManagesData
      setNext={setNext}
      selectedPerson={selectedPerson}
    />
  )
}
