
import { InnerRoute } from '@lemonbrain/hooks'

import StateType from './StateType'

export default function StateTypeBusiness({
  subNav,
  stateId,
  stateTypeId,
  setStateTypeId,
}: {
  subNav: InnerRoute[]
  stateId: string
  stateTypeId: string
  setStateTypeId: (stateTypeId: string) => void
}) {
  return (
    <StateType
      subNav={subNav}
      stateId={stateId}
      stateTypeId={stateTypeId}
      setStateTypeId={setStateTypeId}
    />
  )
}
