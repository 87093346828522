// @ts-nocheck
import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type PayMutationVariables = Types.Exact<{
  products: Array<Types.Product>;
  billingAddress: Types.AddressInput;
  shippingAddress: Types.AddressInput;
  currency: Types.Scalars['String'];
  successUrl: Types.Scalars['String'];
  failedUrl: Types.Scalars['String'];
  language: Types.Scalars['String'];
}>;


export type PayMutation = (
  { __typename?: 'Mutation' }
  & { pay: (
    { __typename?: 'Transaction' }
    & Pick<Types.Transaction, 'fancyboxUrl' | 'transactionId'>
  ) }
);


export const PayDocument = gql`
    mutation pay($products: [Product!]!, $billingAddress: AddressInput!, $shippingAddress: AddressInput!, $currency: String!, $successUrl: String!, $failedUrl: String!, $language: String!) {
  pay(
    products: $products
    billingAddress: $billingAddress
    shippingAddress: $shippingAddress
    currency: $currency
    successUrl: $successUrl
    failedUrl: $failedUrl
    language: $language
  ) {
    fancyboxUrl
    transactionId
  }
}
    `;
export type PayMutationFn = Apollo.MutationFunction<PayMutation, PayMutationVariables>;

/**
 * __usePayMutation__
 *
 * To run a mutation, you first call `usePayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payMutation, { data, loading, error }] = usePayMutation({
 *   variables: {
 *      products: // value for 'products'
 *      billingAddress: // value for 'billingAddress'
 *      shippingAddress: // value for 'shippingAddress'
 *      currency: // value for 'currency'
 *      successUrl: // value for 'successUrl'
 *      failedUrl: // value for 'failedUrl'
 *      language: // value for 'language'
 *   },
 * });
 */
export function usePayMutation(baseOptions?: Apollo.MutationHookOptions<PayMutation, PayMutationVariables>) {
        return Apollo.useMutation<PayMutation, PayMutationVariables>(PayDocument, baseOptions);
      }
export type PayMutationHookResult = ReturnType<typeof usePayMutation>;
export type PayMutationResult = Apollo.MutationResult<PayMutation>;
export type PayMutationOptions = Apollo.BaseMutationOptions<PayMutation, PayMutationVariables>;