import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import Container from '@lemonbrain/components/UiElements/Container/Container'
import { InnerRoute } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'
import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { SIZE, Spinner } from 'baseui/spinner'
import { useStyletron } from 'baseui';
import {
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
} from 'baseui/table-grid';
import { FormattedMessage, useIntl } from 'react-intl'
import { CourseFragmentFragment } from '../../../gql/Courses.generated'
import AddCourseRegistrationData from './AddCourseRegistration/AddCourseRegistration.data'
import CourseRegistrationRow from './CourseRegistrationRow/CourseRegistrationRow'


export default function CoursesRegistrations({
  subNav,
  course,
  loading,
  confirmRegistration,
  sendMailing,
  unregister,
  saveComment,
  saveFinalPrice
}: {
  subNav: InnerRoute[]
  course: CourseFragmentFragment | undefined
  loading: boolean,
  confirmRegistration: (id: string) => void
  sendMailing: (id: string) => void
  unregister: (registraionId: string) => Promise<void>
  saveComment: (id: string, comment: string) => void
  saveFinalPrice: (id: string, finalPrice: number) => void
}) {
  const intl = useIntl()
  const [css] = useStyletron()

  const registrationData = course?.courseRegistrations?.sort(
    (a, b) => new Date(b?.createdAt || 0).getTime() - new Date(a?.createdAt || 0).getTime()
  )

  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          {loading ? (<Spinner $size={SIZE.large} />) : (
            <Cell span={[12, 12, 9]}>
              <Block>
                <Block>
                  <Block
                    paddingTop={['15px', '15px', '15px', 0]}
                    overrides={{
                      Block: {
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '30px',
                        },
                      },
                    }}
                  >
                    <Block>
                      <Block
                        as='h3'
                        overrides={{
                          Block: {
                            style: ({ $theme }) => {
                              return {
                                ...$theme.typography.font450,
                                color: $theme.colors.primaryA,
                                marginBottom: '15px',
                              }
                            },
                          },
                        }}
                      >
                        <FormattedMessage
                          id='courses.registrationsFor'
                          defaultMessage={'Registrations for'}
                          values={{
                            courseTitle: getTranslation({
                              de: course?.title?.de || '',
                              fr: course?.title?.fr || '',
                              it: course?.title?.it || ''
                            }, intl) || ''
                          }}
                        />
                      </Block>
                    </Block>
                  </Block>
                </Block>
              </Block>
              <Block>
                <StyledTable
                  role="grid"
                  $gridTemplateColumns="3fr 2fr 1.5fr 2fr 1fr"
                >
                  <div role="row" className={css({ display: 'contents' })}>
                    <StyledHeadCell>
                      <FormattedMessage
                        id='courses.registrations.member'
                        defaultMessage={'Member'}
                      />
                    </StyledHeadCell>
                    <StyledHeadCell>
                      <FormattedMessage
                        id='courses.registrations.createdAt'
                        defaultMessage={'created at'}
                      />
                    </StyledHeadCell>
                    <StyledHeadCell>
                      <FormattedMessage
                        id='courses.registrations.status'
                        defaultMessage={'Status'}
                      />
                    </StyledHeadCell>
                    <StyledHeadCell>
                      <FormattedMessage
                        id='courses.registrations.invoice'
                        defaultMessage={'Invoice'}
                      />
                    </StyledHeadCell>
                    <StyledHeadCell>
                      <FormattedMessage
                        id='courses.registrations.actions'
                        defaultMessage={'Actions'}
                      />
                    </StyledHeadCell>
                  </div>
                  {registrationData!.length === 0 && (
                    <div role="row" className={css({ display: 'contents' })}>
                      <StyledBodyCell $gridRow={`span 4`}>
                        <FormattedMessage
                          id='courses.registrations.noRegistrations'
                          defaultMessage={'No registrations'}
                        />
                      </StyledBodyCell>
                    </div>
                  )}
                  {registrationData?.map((row) => {
                    return (row &&
                      <CourseRegistrationRow
                        course={course!}
                        key={row.id}
                        row={row}
                        confirmRegistration={confirmRegistration}
                        sendMailing={sendMailing}
                        unregister={unregister}
                        saveComment={saveComment}
                        saveFinalPrice={saveFinalPrice}
                        loading={loading}
                      />
                    )
                  })}
                </StyledTable>
              </Block>
              <AddCourseRegistrationData course={course} />
            </Cell>
          )}
        </Grid>
      </Block>
    </Container>
  )
}
