import { useParams } from "react-router-dom"

import {useHistoryPush} from '@lemonbrain/hooks'

import ForgotPasswordBusiness from './ForgotPassword.business'

import { getApolloClientAuth } from '../../react-apollo'
import { useForgotPasswordMutation } from '../../gql/Auth.generated'

export default function ForgotPasswordData () {
    const { language } = useParams<{ language: string }>()
    const pathPush = useHistoryPush()
    const clientAuth = getApolloClientAuth()

    const [forgotPasswordMutation] = useForgotPasswordMutation({
        client: clientAuth,
    })

    async function forgotPassword (username: string, reCaptchaToken: string) {
        return new Promise<"is-valid">(async (resolve, reject) => {
            try {
                const result = await forgotPasswordMutation({
                    variables: {
                        username,
                        changePasswordUrl: `${window.location.host}/${language}/change-password`,
                        reCaptchaToken
                    }
                })

                if (result.data?.forgotPassword.length === 0) {
                    resolve('is-valid')
                } else {
                    reject()
                }
            } catch (e) {
                reject(e)
            }
            reject()
        })
    }

    function goToSignIn () {
        pathPush(`/${language}/login`)
    }

    return (
        <ForgotPasswordBusiness
            forgotPassword={forgotPassword}
            goToSignIn={goToSignIn}
        />
    )
}
