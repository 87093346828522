import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import SportsEducationBusiness from './SportsEducation.business'
import getReactApolloCients from '../../../../localReactApollo'
import {useGetSportsEducationsNoPersonQuery} from '../../../../gql/members/SportsEducation.generated'

export default function SportsEducationData({
    sportsEducation,
    setSportsEducation,
    getHasSportsEducationErrors
  }: {
    sportsEducation?: { id: string }[]
    setSportsEducation: React.Dispatch<
      React.SetStateAction<{ id: string }[]>
    >
    getHasSportsEducationErrors: (errors: { sportsEducation: string }) => void
  }) {
    const intl = useIntl()
    const setAlert = useAlert()
    const [sportsEducations, setSportsEducations] = useState<
      {
        id: string
        label: string
      }[]
    >([])
    const client = getReactApolloCients().getClient()
      const { data: dataSportsEducations, loading: loadingSportsEducations, error: errorSportsEducations } = useGetSportsEducationsNoPersonQuery({
        variables: {
          cursor: {
            pageSize: 100,
          },
        },
        //@ts-ignore
        client,
     });

     useEffect(() => {
       if (errorSportsEducations) {
         setAlert &&
           setAlert({
             title: intl.formatMessage({
               id: 'members.sportsEducation.error',
               defaultMessage: 'Error',
             }),
             label: intl.formatMessage({
               id: 'members.sportsEducation.networkError',
               defaultMessage: 'We had network problem. Please try again',
             }),
             style: 'error',
           })
         console.error(errorSportsEducations?.message)
       }
     }, [errorSportsEducations])
   
     useEffect(() => {
       if (dataSportsEducations?.getSportsEducationsNoPerson.items) {
        setSportsEducations(
            dataSportsEducations?.getSportsEducationsNoPerson.items.map((item) => ({
             id: item.id,
             label: getTranslation(item.name, intl),
           })).sort(function(a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            
            return 0;
          })
         )
       }
     }, [dataSportsEducations])
    
      return <SportsEducationBusiness sportsEducation={sportsEducation} setSportsEducation={setSportsEducation} loadingSportsEducations={loadingSportsEducations} sportsEducations={sportsEducations} getHasSportsEducationErrors={getHasSportsEducationErrors} />
  }