import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'
import { priceFormater} from '@lemonbrain/utils'

import {
  useGetAssociationsIfMemberQuery,
  GetNextAssociationsIfMemberQuery,
  GetNextAssociationsIfMemberDocument,
} from '../../../gql/Association.generated'
import AssociationsTableBusiness from './AssociationsTable.business'
import { RowDataT } from './AssociationsTable'
import getReactApolloCients from '../../../localReactApollo'

export default function AssociationsTableIfMemberData({
  goToAssociation,
  searchQuery,
}: {
  goToAssociation: (id: string | null) => void
  searchQuery: string
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [data, setData] = useState<RowDataT[]>([])
  const [nextCursor, setNextCursor] = useState<string | null | undefined>()
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useLocalStorage<number>('pageSize', 10)
  const [pageCount, setPageCount] = useState(1)
  const client = getReactApolloCients().getClient()
  const {
    data: dataGetAssociation,
    loading: loadingGetAssociation,
    error: errorGetAssociation,
    fetchMore,
    refetch,
  } = useGetAssociationsIfMemberQuery({
    variables: {
      cursor: {
        pageSize,
        filtered: getFilter(),
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const onFetchMoreData = function () {
    if (nextCursor) {
      fetchMore({
        query: GetNextAssociationsIfMemberDocument,
        variables: {
          cursor: {
            pageSize,
            filtered: getFilter(),
            nextCursor: nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextAssociationsIfMemberQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextAssociationsIfMember: {
                nextCursor: fetchMoreResult.getNextAssociationsIfMember.nextCursor,
                items: [...fetchMoreResult.getNextAssociationsIfMember.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextAssociationsIfMember.items.length > 0 &&
            previousResult.getAssociationsIfMember.items.filter((item) => {
              return item.id === fetchMoreResult.getNextAssociationsIfMember.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getAssociationsIfMember: {
                ...previousResult.getAssociationsIfMember,
                nextCursor: fetchMoreResult.getNextAssociationsIfMember.nextCursor,
                items: [
                  ...previousResult.getAssociationsIfMember.items,
                  ...fetchMoreResult.getNextAssociationsIfMember.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }
  const onRefetchData = function () {
    setPageCount(1)
    refetch({
      cursor: {
        pageSize,
        filtered: getFilter(),
      },
    })
  }

  function getFilter() {
    const filter: { id: string; value: string }[] = []

    filter.push({
      id: 'query',
      value: searchQuery,
    })
    return filter
  }

  useEffect(() => {
    setLoading(loadingGetAssociation)
  }, [loadingGetAssociation])

  useEffect(() => {
    if (errorGetAssociation) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'associations.associationsTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'associations.associationsTable.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorGetAssociation?.message
      )
    }
  }, [errorGetAssociation])

  useEffect(() => {
    if (dataGetAssociation && dataGetAssociation?.getAssociationsIfMember.items.length > 0) {
      setData(
        dataGetAssociation.getAssociationsIfMember.items.map((item) => ({
          id: item.id,
          name: {
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
          },
          short: {
            de: item.short.de,
            fr: item.short.fr,
            it: item.short.it,
          },
          prize: priceFormater(item.prize / 100),
          needsAComment: item.needsAComment,
          createdFrom: item.createdFrom.toString(),
          associationURL: item.associationURL??'',
        }))
      )
      if (
        dataGetAssociation.getAssociationsIfMember.nextCursor &&
        dataGetAssociation.getAssociationsIfMember.nextCursor !== nextCursor
      ) {
        if (nextCursor !== dataGetAssociation.getAssociationsIfMember.nextCursor) {
          setPageCount((prev) => ++prev)
        }
        setNextCursor(dataGetAssociation.getAssociationsIfMember.nextCursor)
      }
    } else {
      setData([])
    }
  }, [dataGetAssociation])

  return (
    <AssociationsTableBusiness
      data={data}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      goToAssociation={goToAssociation}
    />
  )
}
