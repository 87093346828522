import { Tag } from 'baseui/tag'

export default function SelectedItems({
  selectedPerson,
}: {
  selectedPerson: string[]
}) {
  return selectedPerson.length > 0 && (
    <Tag
      closeable={false}
      overrides={{
        Root: {
          style: () => ({
            margin: '0!important',
          }),
        },
      }}
    >
      {selectedPerson.length}
    </Tag>) || <></>
}
