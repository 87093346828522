import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import {Maybe} from '../../../../types';

import SepaImport from './SepaImport'

export default function SepaImportBusiness({
  importLoader,
  deleteLoader,
  uploadSepa,
  deleteOptionActivated,
  deleteSepa,
  errorMessages,
  successMessages,
}: {
  importLoader: boolean
  deleteLoader: boolean
  uploadSepa: (sepa: File) => void
  deleteOptionActivated: string
  deleteSepa: () => void
  errorMessages: Maybe<string>[]
  successMessages: Maybe<string>[]
}) {
  const intl = useIntl()
  const [errorMessage, setErrorMessage] = useState('')
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>()
  const [rejectedFiles, setRejectedFiles] = useState<File[]>()

  useEffect(() => {
    if (acceptedFiles && acceptedFiles?.length > 0) {
      uploadSepa(acceptedFiles[0])
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (rejectedFiles && rejectedFiles?.length > 0) {
      setErrorMessage(
        intl.formatMessage(
          {
            id: 'invoices.sepaImport.rejectFormats',
            defaultMessage: 'Following files were rejected: {files}',
          },
          {
            files: rejectedFiles.map((item) => item.name).join(', '),
          }
        )
      )
    }
  }, [rejectedFiles])

  return (
    <SepaImport
    importLoader={importLoader}
    deleteLoader={deleteLoader}
      errorMessage={errorMessage}
      setAcceptedFiles={setAcceptedFiles}
      setRejectedFiles={setRejectedFiles}
      deleteOptionActivated={deleteOptionActivated}
      deleteSepa={deleteSepa}
      errorMessages={errorMessages}
      successMessages={successMessages}
    />
  )
}
