import { UpdateCourseMutationVariables } from '../../../gql/Courses.generated'
import CoursesEdit from './CoursesEdit'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAlert } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'
import { prepStringWN, required } from '@lemonbrain/utils'

export default function CoursesEditBusiness({
  courseStates,
  workLevels,
  goToCourse,
  courseId,
  loading,
  updateCreateEntry,
  course,
  setCourse,
}: {
  courseStates: {
    id: string
    label: string
  }[]
  workLevels: {
    id: string
    label: string
  }[]
  goToCourse: (id: string | null) => void
  courseId?: string
  loading: boolean
  updateCreateEntry(data: UpdateCourseMutationVariables): Promise<void>
  course: UpdateCourseMutationVariables & {
    priceText: string
    memberPriceText: string
  }
  setCourse: React.Dispatch<React.SetStateAction<UpdateCourseMutationVariables & {
    priceText: string
    memberPriceText: string
  }>>
}) {
  const [errors, setErrors] = useState<{
    language: string
    educationalStage: string
    contentCategory: string
    number: string
    title: string
    description: string
    price: string
    memberPrice: string
    teacher: string
    targetGroup: string
    date: string
    endDate: string
    place: string
    subscribeTill: string
    minSubscribers: string
    maxSubscribers: string
    options: string
    status: string
  }>({
    language: '',
    educationalStage: '',
    contentCategory: '',
    number: '',
    title: '',
    description: '',
    price: '',
    memberPrice: '',
    teacher: '',
    targetGroup: '',
    date: '',
    endDate: '',
    place: '',
    subscribeTill: '',
    minSubscribers: '',
    maxSubscribers: '',
    options: '',
    status: '',
  })
  const intl = useIntl()
  const setAlert = useAlert()
  const { user } = useAppState()

  function cancelClickHandler() {
    goToCourse(null)
  }

  function copyClickHandler() {
    updateClickHandler(true)
  }

  async function updateClickHandler(copy = false) {
    copy = typeof copy === 'boolean' ? copy : false
    if (course) {
      if (user && user.uID) {
        try {
          let variables: UpdateCourseMutationVariables = {
            id: !copy ? course.id : '',
            language: prepStringWN(course.language) || '',
            educationalStageIds: course.educationalStageIds,
            contentCategory: prepStringWN(course.contentCategory) || '',
            number: course.number,
            title: course.title,
            description: course.description,
            price: Math.round(parseFloat(course.priceText) * 100),
            memberPrice: Math.round(parseFloat(course.memberPriceText) * 100),
            teacher: prepStringWN(course.teacher) || '',
            targetGroup: prepStringWN(course.targetGroup) || '',
            special: course.special,
            date: course.date,
            endDate: course.endDate,
            time: course.time,
            place: prepStringWN(course.place) || '',
            subscribeTill: course.subscribeTill,
            minSubscribers: course.minSubscribers,
            maxSubscribers: course.maxSubscribers,
            options: course.options,
            status: course.status,
          }

          await updateCreateEntry(variables)
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'courses.coursesEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'courses.coursesEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'courses.coursesEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'courses.coursesEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  function checkLanguage() {
    if (required(course.language)) {
      setErrors((prev) => ({
        ...prev,
        language: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      language: intl.formatMessage({
        id: 'courses.coursesEdit.error.languageRequired',
        defaultMessage: 'Language is required',
      }),
    }))
    return true
  }

  function checkEducationalStage() {
    if (course.educationalStageIds && course.educationalStageIds.length > 0 && parseInt(course.educationalStageIds[0]) > 0) {
      setErrors((prev) => ({
        ...prev,
        educationalStage: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      educationalStage: intl.formatMessage({
        id: 'courses.coursesEdit.error.educationalStageRequired',
        defaultMessage: 'Education stage is required',
      }),
    }))
    return true
  }

  function checkContentCategory() {
    if (required(course.contentCategory)) {
      setErrors((prev) => ({
        ...prev,
        contentCategory: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      contentCategory: intl.formatMessage({
        id: 'courses.coursesEdit.error.contentCategoryRequired',
        defaultMessage: 'Content category is required',
      }),
    }))
    return true
  }

  function checkNumber() {
    if (required(course.number)) {
      setErrors((prev) => ({
        ...prev,
        number: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      number: intl.formatMessage({
        id: 'courses.coursesEdit.error.numberRequired',
        defaultMessage: 'Number is required',
      }),
    }))
    return true
  }

  function checkTitle() {
    if (required(course.title?.de)) {
      setErrors((prev) => ({
        ...prev,
        title: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      title: intl.formatMessage({
        id: 'courses.coursesEdit.error.titleRequired',
        defaultMessage: 'Title is required',
      }),
    }))
    return true
  }

  function checkDescription() {
    if (required(course.description?.de)) {
      setErrors((prev) => ({
        ...prev,
        description: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      description: intl.formatMessage({
        id: 'courses.coursesEdit.error.descriptionRequired',
        defaultMessage: 'Description is required',
      }),
    }))
    return true
  }

  function checkPrice() {
    if (required(course.priceText)) {
      setErrors((prev) => ({
        ...prev,
        price: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      price: intl.formatMessage({
        id: 'courses.coursesEdit.error.priceRequired',
        defaultMessage: 'Price is required',
      }),
    }))
    return true
  }

  function checkMemberPrice() {
    if (required(course.memberPriceText)) {
      setErrors((prev) => ({
        ...prev,
        memberPrice: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      memberPrice: intl.formatMessage({
        id: 'courses.coursesEdit.error.memberPriceRequired',
        defaultMessage: 'Member price is required',
      }),
    }))
    return true
  }

  function checkTeacher() {
    if (required(course.teacher)) {
      setErrors((prev) => ({
        ...prev,
        teacher: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      teacher: intl.formatMessage({
        id: 'courses.coursesEdit.error.teacherRequired',
        defaultMessage: 'Teacher is required',
      }),
    }))
    return true
  }

  function checkTargetGroup() {
    if (required(course.targetGroup)) {
      setErrors((prev) => ({
        ...prev,
        targetGroup: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      targetGroup: intl.formatMessage({
        id: 'courses.coursesEdit.error.targetGroupRequired',
        defaultMessage: 'Target group is required',
      }),
    }))
    return true
  }

  function checkDate() {
    if (required(course.date)) {
      setErrors((prev) => ({
        ...prev,
        date: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      date: intl.formatMessage({
        id: 'courses.coursesEdit.error.dateRequired',
        defaultMessage: 'Date is required',
      }),
    }))
    return true
  }

  function checkPlace() {
    if (required(course.place)) {
      setErrors((prev) => ({
        ...prev,
        place: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      place: intl.formatMessage({
        id: 'courses.coursesEdit.error.placeRequired',
        defaultMessage: 'Place is required',
      }),
    }))
    return true
  }

  function checkSubscribeTill() {
    if (required(course.subscribeTill)) {
      setErrors((prev) => ({
        ...prev,
        subscribeTill: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      subscribeTill: intl.formatMessage({
        id: 'courses.coursesEdit.error.subscribeTillRequired',
        defaultMessage: 'Subscribe till is required',
      }),
    }))
    return true
  }

  function checkMinSubscribers() {
    if (required(course.minSubscribers)) {
      setErrors((prev) => ({
        ...prev,
        minSubscribers: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      minSubscribers: intl.formatMessage({
        id: 'courses.coursesEdit.error.minSubscribersRequired',
        defaultMessage: 'Min subscribers is required',
      }),
    }))
    return true
  }

  function checkMaxSubscribers() {
    if (required(course.maxSubscribers)) {
      setErrors((prev) => ({
        ...prev,
        maxSubscribers: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      maxSubscribers: intl.formatMessage({
        id: 'courses.coursesEdit.error.maxSubscribersRequired',
        defaultMessage: 'Max subscribers is required',
      }),
    }))
    return true
  }

  useEffect(() => {
    checkLanguage()
    checkEducationalStage()
    checkContentCategory()
    checkNumber()
    checkTitle()
    checkDescription()
    checkPrice()
    checkMemberPrice()
    checkTeacher()
    checkTargetGroup()
    checkDate()
    checkPlace()
    checkSubscribeTill()
    checkMinSubscribers()
    checkMaxSubscribers()
  }, [course])

  return (
    <CoursesEdit
      courseStates={courseStates}
      workLevels={workLevels}
      goToCourse={goToCourse}
      courseId={courseId}
      errors={errors}
      onCancelClick={cancelClickHandler}
      isLoading={loading}
      onCopyClick={copyClickHandler}
      onUpdateClick={updateClickHandler}
      course={course}
      setCourse={setCourse}
    />
  )
}
