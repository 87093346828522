
import AddCourseRegistration from './AddCourseRegistration'

import { SignUpState } from '../../../../types'

export default function AddCourseRegistrationBusiness({
  member,
  setMember,
  add,
  loading
}: {
  member?: { id: string, label?: string };
  setMember: React.Dispatch<React.SetStateAction<{
    id: string;
    label?: string | undefined;
  } | undefined>>
  add: (signUpState: SignUpState) => Promise<void>
  loading: boolean
}) {

  return (
    <AddCourseRegistration
      member={member}
      setMember={setMember}
      add={add}
      loading={loading}
    />
  )
}
