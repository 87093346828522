const LocaleData = {
    'auth.login.signIn': 'Anmelden',
    'auth.login.logout': 'Abmelden',
    'auth.login.error': 'Fehler',
    'auth.login.error.notLoggedIn': 'Leider konnten wir Sie nicht anmelden.',
    'auth.login.loggedIn': 'Angemeldet',
    'auth.login.loggedIn.success': 'Sie sind nun angemeldet',
    'auth.login.error.notLoggedInUsernamePasswordWrong': 'Leider konnten wir dich nicht anmelden. Bitte überprüfe Benutzername und Passwort',
    'auth.login.loggedOut': 'Abgemeldet',
    'auth.login.loggedOut.success': 'Sie wurden abgemeldet',
    'auth.login.error.notLoggedOut': 'Leider konnten wir dich nicht abmelden, versuche es noch einmal',
    'auth.login.emailAddress': 'E-Mailadresse',
    'auth.login.error.validEmailAddress': 'Eine gültige E-Mailadresse ist verlangt',
    'auth.login.password': 'Passwort',
    'auth.login.error.password': 'Ein Passwort mit mindestens 6 Zeichen ist verlangt',
    'auth.login.forgotPassword': 'Passwort vergessen',
    'auth.login.wrongAuthenticator': 'Falscher authentisierungs Token, versuchen Sie es noch einmal',
    'auth.login.error.wrongAuthenticator': 'Wir konnten Sie nicht anmelden. Falscher authentisierungs Token, versuchen Sie es noch einmal',
    'auth.login.googleAuthenticator': 'Google Authenticator',
    'auth.login.u2SAPass': 'Token',
    'auth.login.error.u2SAPass': 'Ein Token ist verlangt',
    'auth.login.error.googleRecaptchaCouldNotBeLoaded': 'Leider konnte Google ReCaptcha nicht geladen werden',
    
    'auth.checkValidationEmail.emailValidation': 'E-Mailadresse validieren',
    'auth.checkValidationEmail.error': 'Fehler',
    'auth.checkValidationEmail.error.notSendedMail': 'Wir konnten das validierungs E-Mail nicht versenden. Versuche es noch einmal',
    'auth.checkValidationEmail.mailSent': 'E-Mail gesendet',
    'auth.checkValidationEmail.mailSent.success': 'Das validierungs E-Mail wurde versendet. Bitte überprüfen Sie Ihren Posteingang oder Spam Ordner',
    'auth.checkValidationEmail.emailValidationRequired': 'E-Mail validierung ist verlangt',
    'auth.checkValidationEmail.resendValidationEmail': 'Validierungs E-Mail erneut versenden',
    'auth.checkValidationEmail.login': 'Anmelden',
    'auth.checkValidationEmail.forgotPassword': 'Passwort vergessen',
    "auth.checkValidationEmail.sentEmailOnAccountCreation": 'Bei der Registrierung haben wir Ihnen ein Email zur Bestätigung deiner Mailadresse gesendet.',
    "auth.checkValidationEmail.followTheInstructions": 'Bitte folgen Sie den Anweisungen in dem Mail.',
    "auth.checkValidationEmail.canNotFindValidationEmail": 'Sollten Sie das Email nicht bekommen haben, sehen Sie doch bitte noch im Spamordner nach.',
    'auth.checkValidationEmail.error.googleRecaptchaCouldNotBeLoaded': 'Leider konnte Google ReCaptcha nicht geladen werden',
    'auth.checkValidationEmail.error.reloadPage': 'Lade die Seite neu (refresh)',

    'auth.validateEmail.emailValidation': 'E-Mailadresse validieren',
    'auth.validateEmail.error': 'Fehler',
    'auth.validateEmail.error.emailValidationFailed': 'E-Mail validierung ist fehlgeschlagen',
    'auth.validateEmail.success': 'Erfolgreich',
    'auth.validateEmail.success.emailValidationSuccessfull': 'E-Mailadresse ist validiert',
    "auth.validateEmail.emailValidationSuccessful": 'Ihre E-Mailadresse wurde erfolgreich validiert',
    'auth.validateEmail.signIn': 'Anmelden',
    'auth.validateEmail.error.googleRecaptchaCouldNotBeLoaded': 'Leider konnte Google ReCaptcha nicht geladen werden',

    'auth.forgotPassword.forgotPassword': 'Passwort vergessen',
    'auth.forgotPassword.error': 'Fehler',
    'auth.forgotPassword.error.notSendedMail': 'Wir konnten Ihnen kein Passwort Reset E-Mail zustellen, versuchen Sie es noch einmal',
    'auth.forgotPassword.mailSent': 'E-Mail gesendet',
    'auth.forgotPassword.mailSent.success': 'Das Passwort Reset E-Mail wurde versendet. Bitte überprüfen Sie Ihren Posteingang oder Spam Ordner',
    'auth.forgotPassword.forgotYourPassword': 'Haben Sie ihr Passwort vergessen?',
    'auth.forgotPassword.emailAddress': 'E-Mailadresse',
    'auth.forgotPassword.error.validEmailAddress': 'Eine gültige E-Mailadresse ist verlangt',
    'auth.forgotPassword.resetAndSend': 'Passwort Reset E-Mail versenden',
    'auth.forgotPassword.signIn': 'Anmelden',
    'auth.forgotPassword.error.googleRecaptchaCouldNotBeLoaded': 'Leider konnte Google ReCaptcha nicht geladen werden',
    "auth.forgotPassword.sentEmailOnAccountCreation": 'Wir haben Ihnen ein Passwort Reset E-Mail gesendet.',
    "auth.forgotPassword.followTheInstructions": 'Bitte folgen Sie den Anweisungen in dem Mail.',
    "auth.forgotPassword.canNotFindValidationEmail": 'Sollten Sie das Email nicht bekommen haben, sehen Sie doch bitte noch im Spamordner nach.',

    'auth.changePassword.changePassword': 'Passwort anpassen',
    'auth.changePassword.error': 'Fehler',
    'auth.changePassword.error.tokenNoLongerValid': 'Ihr E-Mail Link ist abgelaufen, senden Sie sich ein neues Passwort Reset E-Mail',
    'auth.changePassword.changed': 'Angepasst',
    'auth.changePassword.changed.success': 'Ihr Passwort wurde angepasst',
    'auth.changePassword.error.needToBeTheSame': 'Die beiden Passwörter müssen überein stimmen',
    'auth.changePassword.password': 'Passwort',
    'auth.changePassword.error.password': 'Ein Passwort mit mindestens 6 Zeichen ist verlangt',
    'auth.login.passwordConfirm': 'Passwort wiederholen',
    'auth.changePassword.error.confirmPassword': 'Ein Passwort mit mindestens 6 Zeichen ist verlangt',
    'auth.changePassword.signIn': 'Anmelden',
    'auth.changePassword.forgotPassword': 'Passwort vergessen',
    'auth.changePassword.error.googleRecaptchaCouldNotBeLoaded': 'Leider konnte Google ReCaptcha nicht geladen werden',

    'auth.signUp.signUp': 'Registrieren',
    'auth.signUp.error': 'Fehler',
    'auth.signUp.error.reloadPage': 'Lade die Seite neu (refresh)',
    'auth.signUp.error.couldNotSignUp': 'Wir konnten Sie nicht regestrieren, versuchen es noch einmal',
    'auth.signUp.mailSent': 'E-Mail gesendet',
    'auth.signUp.mailSent.success': 'Das validierungs E-Mail wurde versendet. Bitte überprüfen Sie Ihren Posteingang oder Spam Ordner',
    'auth.signUp.error.googleRecaptchaCouldNotBeLoaded': 'Leider konnte Google ReCaptcha nicht geladen werden',
    'auth.signUp.error.needToBeTheSame': 'Die beiden Passwörter müssen überein stimmen',
    'auth.signUp.emailAddress': 'E-Mailadresse',
    'auth.signUp.error.validEmailAddress': 'Eine gültige E-Mailadresse ist verlangt',
    'auth.signUp.password': 'Passwort',
    'auth.signUp.error.password': 'Ein Passwort mit mindestens 6 Zeichen ist verlangt',
    'auth.signUp.passwordConfirm': 'Passwort wiederholen',
    'auth.signUp.error.confirmPassword': 'Ein Passwort mit mindestens 6 Zeichen ist verlangt',
    'auth.signUp.signIn': 'Anmelden',

    'auth.displayName.error': 'Fehler',
    'auth.displayName.errorMessage': 'Beim Laden des Anzeigenamens des Benutzers ist ein Fehler aufgetreten, versuchen Sie es noch einmal',

    'auth.editLanguage.error': 'Fehler',
    'auth.editLanguage.errorMessage': 'Beim Suchen des Auth-Benutzers ist ein Fehler aufgetreten, versuchen Sie es noch einmal',
    'auth.editLanguage.language': 'Sprache',
    'auth.editLanguage.editSavesDirectly': 'Änderungen werden direkt gespeichert',
}

export default LocaleData;