import { useIntl } from 'react-intl'
import { Button } from 'baseui/button'
import { ArrowUp } from 'baseui/icon'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'
import { Tag } from 'baseui/tag'
import { Notification, KIND } from 'baseui/notification'
import { Show } from 'baseui/icon'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal'
import { Textarea } from 'baseui/textarea'
import { SIZE } from 'baseui/input'
import { FormattedMessage } from 'react-intl'

import { MailingState } from '../../../../types'

export default function Mail({
  selectedInvoices,
  onClick,
  send,
  isOpen,
  mailing,
  setMailing,
  errors,
  loading,
  mailingState,
  mailingId,
}: {
  selectedInvoices: string[]
  onClick: () => any
  send: () => any
  isOpen: boolean
  mailing: {
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
    content: string
    attachments: string
  }
  setMailing: React.Dispatch<
    React.SetStateAction<{
      fromEmail: string
      fromNameEmail: string
      toEmail: string
      subject: string
      content: string
      attachments: string
    }>
  >
  errors: {
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
    content: string
    attachments: string
  }
  loading: boolean
  mailingState: {
    sentCount?: number
    failedCount?: number
    recipientCount?: number
    state?: MailingState
  }
  mailingId: unknown
}) {
  const intl = useIntl()

  return selectedInvoices.length > 0 || mailingId ? (
    <>
      <StatefulTooltip
        placement={PLACEMENT.right}
        content={intl.formatMessage({
          id: 'invoices.mail.createMailing',
          defaultMessage: 'Create mailing',
        })}
        overrides={{
          Body: {
            style: () => ({
              zIndex: 99999,
            }),
          },
        }}
      >
        <Button onClick={onClick}>
          <ArrowUp
            title={intl.formatMessage({
              id: 'invoices.mail.createMailing',
              defaultMessage: 'Create mailing',
            })}
            size={24}
          />
          {mailingId && (mailingId as number) && <Show />}
          {mailingState.recipientCount && (
            <Tag
              closeable={false}
              overrides={{
                Root: {
                  style: () => ({
                    margin: '0!important',
                  }),
                },
              }}
            >
              {mailingState.recipientCount}
            </Tag>
          )}
        </Button>
      </StatefulTooltip>
      <Modal
        onClose={onClick}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: {
              zIndex: 99999,
            },
          },
          Dialog: {
            style: {
              zIndex: 99999,
              width: '80vw',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>
          <FormattedMessage
            id='invoices.mail.createMailing'
            defaultMessage={'Crate Mailing'}
          />
          {mailingState.state && (
            <Notification
              kind={
                mailingState.state === MailingState.Interrupted
                  ? KIND.negative
                  : mailingState.state === MailingState.Completed
                  ? KIND.positive
                  : mailingState.state === MailingState.Pending
                  ? KIND.warning
                  : KIND.info
              }
            >
              <FormattedMessage
                id={`invoices.mail.state.${mailingState.state}`}
                defaultMessage={mailingState.state}
              />
            </Notification>
          )}
        </ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.mail.fromMail',
              defaultMessage: 'From',
            })}
            error={errors.fromEmail}
          >
            <Input
              value={mailing.fromEmail}
              onChange={(e) =>
                setMailing((prev) => ({
                  ...prev,
                  fromEmail: (e.target as HTMLInputElement).value,
                }))
              }
              positive={errors.fromEmail === ''}
            />
          </FormControl>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.mail.fromNameMail',
              defaultMessage: 'From name',
            })}
            error={errors.fromNameEmail}
          >
            <Input
              value={mailing.fromNameEmail}
              onChange={(e) =>
                setMailing((prev) => ({
                  ...prev,
                  fromNameEmail: (e.target as HTMLInputElement).value,
                }))
              }
              positive={errors.fromNameEmail === ''}
            />
          </FormControl>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.mail.toMail',
              defaultMessage: 'To',
            })}
            error={errors.toEmail}
          >
            <Textarea
              size={SIZE.compact}
              value={mailing.toEmail}
              onChange={(e) =>
                setMailing((prev) => ({
                  ...prev,
                  toEmail: (e.target as HTMLTextAreaElement).value,
                }))
              }
              placeholder={intl.formatMessage({
                id: 'invoices.mail.toMail',
                defaultMessage: 'To',
              })}
              clearOnEscape
              positive={errors.toEmail === ''}
            />
          </FormControl>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.mail.subject',
              defaultMessage: 'Subject',
            })}
            error={errors.subject}
          >
            <Input
              value={mailing.subject}
              onChange={(e) =>
                setMailing((prev) => ({
                  ...prev,
                  subject: (e.target as HTMLInputElement).value,
                }))
              }
              positive={errors.subject === ''}
            />
          </FormControl>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.mail.content',
              defaultMessage: 'Content',
            })}
            error={errors.content}
          >
            <Textarea
              value={mailing.content}
              onChange={(e) =>
                setMailing((prev) => ({
                  ...prev,
                  content: (e.target as HTMLTextAreaElement).value,
                }))
              }
              placeholder={intl.formatMessage({
                id: 'invoices.mail.content',
                defaultMessage: 'Content',
              })}
              overrides={{
                Input: {
                  style: {
                    width: '100vw',
                    resize: 'both',
                  },
                },
                InputContainer: {
                  style: {
                    maxWidth: '100%',
                    width: 'min-content',
                  },
                },
              }}
              positive={errors.content === ''}
            />
          </FormControl>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.mail.attachments',
              defaultMessage: 'Attachments',
            })}
            error={errors.attachments}
          >
            <Textarea
              size={SIZE.compact}
              value={mailing.attachments}
              onChange={(e) =>
                setMailing((prev) => ({
                  ...prev,
                  attachments: (e.target as HTMLTextAreaElement).value,
                }))
              }
              placeholder={intl.formatMessage({
                id: 'invoices.mail.attachments',
                defaultMessage: 'Attachments',
              })}
              clearOnEscape
              positive={errors.attachments === ''}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={send}
            isLoading={loading}
            disabled={
              errors.content !== '' ||
              errors.fromEmail !== '' ||
              errors.fromNameEmail !== '' ||
              errors.subject !== '' ||
              errors.toEmail !== '' ||
              errors.attachments !== ''
            }
          >
            <FormattedMessage
              id={`invoices.mail.send`}
              defaultMessage={'Send to recipients {recipients}'}
              values={{
                recipients: mailing.toEmail.split(',').length,
              }}
            />
          </ModalButton>
          {mailingState.recipientCount && mailingState.recipientCount > 0 && (
            <Notification>
              <FormattedMessage
                id={`invoices.mail.sending`}
                defaultMessage={
                  'Sending (recipients: {recipients}, sent: {sent}, did already receive mailing: {double}, failed: {failed})'
                }
                values={{
                  recipients: mailingState.recipientCount,
                  sent: mailingState.sentCount,
                  double: mailingState.state === MailingState.Completed ? ((mailingState.recipientCount || 0) - ((mailingState.sentCount || 0) + (mailingState.failedCount || 0))) : 0,
                  failed: mailingState.failedCount,
                }}
              />
            </Notification>
          )}
        </ModalFooter>
      </Modal>
    </>
  ) : (
    <></>
  )
}
