import { useState, useEffect } from 'react'

import AddressTypeTableBusiness from './AddressTypeTable.business'
import { RowDataT } from './AddressTypeTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteAddressTypeMutation,
  useGetAddressTypesQuery,
  GetNextAddressTypesDocument,
  GetNextAddressTypesQuery,
} from '../../../gql/AddressType.generated'

export default function AddressTypeTableData({
    setAddressTypelId,
}: {
    setAddressTypelId: (addressTypeId: string | undefined) => void
}) {
  const [addressTypes, setAddressTypes] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetAddressTypesQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteAddressTypeMutation] = useDeleteAddressTypeMutation({
    //@ts-ignore
    client,
  })

  async function removeAddressTypeEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteAddressTypeMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteAddressType.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreAddressTypeEntries() {
    if (data?.getAddressTypes.nextCursor) {
      fetchMore({
        query: GetNextAddressTypesDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getAddressTypes.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextAddressTypesQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextWorkLevels: {
                nextCursor: fetchMoreResult.getNextAddressTypes.nextCursor,
                items: [...fetchMoreResult.getNextAddressTypes.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextAddressTypes.items.length > 0 &&
            previousResult.getAddressTypes.items.filter((item) => {
              return item.id === fetchMoreResult.getNextAddressTypes.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getAddressTypes: {
                ...previousResult.getAddressTypes,
                nextCursor: fetchMoreResult.getNextAddressTypes.nextCursor,
                items: [
                  ...previousResult.getAddressTypes.items,
                  ...fetchMoreResult.getNextAddressTypes.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getAddressTypes.items) {
        setAddressTypes(
        data?.getAddressTypes.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            isInitType: item.isInitType,
            isFirm: item.isFirm,
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToAddressTypeEntry(id: string) {
    setAddressTypelId(id)
  }

  return (
    <AddressTypeTableBusiness
      isLoading={loading}
      queryError={queryError}
      addressTypes={addressTypes}
      fetchMoreAddressTypeEntries={fetchMoreAddressTypeEntries}
      refetchAddressTypeEntries={refetch}
      goToAddressTypeEntry={goToAddressTypeEntry}
      removeAddressTypeEntry={removeAddressTypeEntry}
    />
  )
}
