import { useState, useEffect } from 'react'

import StateTableBusiness from './StateTable.business'
import { RowDataT } from './StateTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteStateMutation,
  useGetStatesQuery,
  GetNextStatesDocument,
  GetNextStatesQuery,
} from '../../../gql/State.generated'

export default function StateTableData({
    setStateId,
    listStateTypes,
}: {
    setStateId: (stateId: string | undefined) => void
    listStateTypes: (stateId: string) => void
}) {
  const [states, setStates] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetStatesQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteStateMutation] = useDeleteStateMutation({
    //@ts-ignore
    client,
  })

  async function removeStateEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteStateMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteState.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreStateEntries() {
    if (data?.getStates.nextCursor) {
      fetchMore({
        query: GetNextStatesDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getStates.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextStatesQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextWorkLevels: {
                nextCursor: fetchMoreResult.getNextStates.nextCursor,
                items: [...fetchMoreResult.getNextStates.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextStates.items.length > 0 &&
            previousResult.getStates.items.filter((item) => {
              return item.id === fetchMoreResult.getNextStates.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getStates: {
                ...previousResult.getStates,
                nextCursor: fetchMoreResult.getNextStates.nextCursor,
                items: [
                  ...previousResult.getStates.items,
                  ...fetchMoreResult.getNextStates.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getStates.items) {
        setStates(
        data?.getStates.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            isInitState: item.isInitState,
            highlightColor: item.highlightColor,
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToStateEntry(id: string) {
    setStateId(id)
  }

  return (
    <StateTableBusiness
      isLoading={loading}
      queryError={queryError}
      states={states}
      fetchMoreStateEntries={fetchMoreStateEntries}
      refetchStateEntries={refetch}
      goToStateEntry={goToStateEntry}
      listStateTypes={listStateTypes}
      removeStateEntry={removeStateEntry}
    />
  )
}
