import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import DisplayName from './DisplayName'

import { useAlert } from '@lemonbrain/hooks'

export default function TimerecordingEditBusiness ({
    displayName,
    isLoading,
    queryError,
}: {
    displayName?: string,
    isLoading: boolean,
    queryError: any
}) {
    const intl = useIntl()
    const setAlert = useAlert()

    useEffect(() => {
        if (queryError) {
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.displayName.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.displayName.errorMessage', defaultMessage: 'There was an error while searching users display name. Try again' }),
                style: 'error'
            })
        }
    }, [queryError])

    return (
        <DisplayName
            displayName={displayName}
            isLoading={isLoading}
        />
    )
}
