import { useState } from 'react'

let defaultGermanMessages: any = []
export const addGermanMessagesBeforeRender = function (newGermanMessages: any) {
    defaultGermanMessages = {...defaultGermanMessages, ...newGermanMessages}
}

export const useGermanMessages = () => {
    const [germanMessages, setGermanMessages] = useState(defaultGermanMessages)


    const addGermanMessages = function (newMessage: any) {
        setGermanMessages((prev: any) =>  ({...prev, ...newMessage}))
    }
    
    return {
        addGermanMessages,
        germanMessages
    }
}
