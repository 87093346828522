// import React from 'react'
import { render } from "react-dom";
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import germanMessages from './messages/de'

import { addGermanMessagesBeforeRender } from '@lemonbrain/hooks'
import { PageWrapper } from '@lemonbrain/contexts/PageWrapper'

if (process.env.REACT_APP_LOAD_AUTH) {
  require('@lemonbrain/auth')
}

if (process.env.REACT_APP_LOAD_SETTINGS) {
  require('@svss/settings')
}

if (process.env.REACT_APP_LOAD_MEMBERS) {
  require('@svss/members')
}

if (process.env.REACT_APP_LOAD_ASSOCIATIONS) {
  require('@svss/associations')
}

if (process.env.REACT_APP_LOAD_INVOICES) {
  require('@svss/invoices')
}

if (process.env.REACT_APP_LOAD_NEWSLETTER) {
  require('@svss/newsletter')
}

if (process.env.REACT_APP_LOAD_COURSES) {
  require('@svss/courses')
}

addGermanMessagesBeforeRender(germanMessages)
const domNode = document.getElementById('root')
if (domNode) {
  const router = createBrowserRouter([
    {
      path: '*',
      element: (
        <PageWrapper>
          <App />
        </PageWrapper>
      ),
    },
  ])

  render(<RouterProvider router={router} />, domNode);
}

; (async () => {
  window.addEventListener('load', async () => {
    if ('serviceWorker' in navigator) {
      const { Workbox } = await import('workbox-window')

      const wb = new Workbox('/service-worker.js')

      const showSkipWaitingPrompt = async () => {
        wb.addEventListener('controlling', () => {
          window.location.reload()
        })

        const updateAccepted = await new Promise((resolve) => {
          /* eslint-disable no-restricted-globals */
          resolve(confirm('Soll die App ein Update erfahren?'))
        })

        if (updateAccepted) {
          wb.messageSW({ type: 'SKIP_WAITING' })
        }
      }

      wb.addEventListener('waiting', () => {
        showSkipWaitingPrompt()
      })

      wb.register().then((registration) => {
        setInterval(async function () {
          await registration?.update()
        }, parseInt(process.env.CLIENT_PING_INTERVAL || '5000'))

        registration?.addEventListener('updatefound', () => {
          const newWorker = registration.installing;

          newWorker?.addEventListener('statechange', () => {
            if (newWorker.state === 'installed') {
              if (confirm('Die Seite sollte neu geladen werden. Möchten Sie das jetzt tun?')) {
                window.location.reload()
              }
            }
          });
        });
      })
    }
  })
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
