import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import SvssFunctionBusiness from './SvssFunction.business'

export default function SvssFunctionData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { svssFunctionId } = useParams<{ svssFunctionId: string }>()
  const { language } = useParams<{ language: string }>()

  function setSvssFunctionId(svssFunctionId: string | undefined) {
    if (svssFunctionId && svssFunctionId !== '') {
      pushPath(`/${language}/settings/svss-function/${svssFunctionId}`)
    } else {
      pushPath(`/${language}/settings/svss-function`)
    }
  }

  return (
    <SvssFunctionBusiness
      subNav={subNav}
      svssFunctionId={svssFunctionId}
      setSvssFunctionId={setSvssFunctionId}
    />
  )
}
