import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import CoursesRegistrations from './CoursesRegistrations'
import {
  CourseFragmentFragment,
  useGetCourseQuery,
  useConfirmRegistrationMutation,
  useSaveRegistrationFinalPriceMutation,
  useUnregisterFromCourseByIdMutation,
  useSaveRegistrationCommentMutation
} from '../../../gql/Courses.generated'
import getReactApolloCients from '../../../localReactApollo'
import { useSendMailingMutation } from '../../../../../invoices/src/gql/members/Mailing.generated'

export default function CoursesRegistrationsData({ subNav }: { subNav: InnerRoute[] }) {
  const intl = useIntl()
  const setAlert = useAlert()

  const { courseId } = useParams<{ courseId: string | undefined }>()
  const client = getReactApolloCients().getClient()

  const [unregisterFromCourseByIdMutation, { loading, error }] = useUnregisterFromCourseByIdMutation({
    //@ts-ignore
    client,
  });
  const [saveRegistrationCommentMutation] = useSaveRegistrationCommentMutation({
    //@ts-ignore
    client,
  });
  const [changeRegistrationFinalPrice] = useSaveRegistrationFinalPriceMutation({
    //@ts-ignore
    client,
  });

  const {
    data: dataCourse,
    loading: loadingCourse,
    error: errorCourse,
  } = useGetCourseQuery({
    variables: {
      id: courseId || '',
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [confirmRegistrationMutation] = useConfirmRegistrationMutation({
    //@ts-ignore
    client,
  })
  const [sendMailingMutation] = useSendMailingMutation({
    //@ts-ignore
    client,
  })

  const unregister = async function (registraionId: string) {
    await unregisterFromCourseByIdMutation({
      variables: {
        id: registraionId,
      }
    })
  }

  const sendMailing = function (id: string) {
    sendMailingMutation({
      variables: {
        id,
      },
    })
  }

  const confirmRegistration = async function (id: string) {
    try {
      await confirmRegistrationMutation({
        variables: { id }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const saveComment = async function (id: string, comment: string) {
    try {
      await saveRegistrationCommentMutation({
        variables: { id, comment }
      })

      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.registrations.saved',
            defaultMessage: 'Saved',
          }),
          label: intl.formatMessage({
            id: 'members.registrations.savedComment',
            defaultMessage: 'Comment is saved',
          }),
          style: 'success',
        })
    } catch (error) {
      console.error(error)
    }
  }

  const saveFinalPrice = async function (id: string, finalPrice: number) {
    try {
      await changeRegistrationFinalPrice({
        variables: { id, finalPrice }
      })

      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.registrations.saved',
            defaultMessage: 'Saved',
          }),
          label: intl.formatMessage({
            id: 'members.registrations.savedFinalPrice',
            defaultMessage: 'Final price is saved',
          }),
          style: 'success',
        })
    } catch (error) {
      console.error(error)
    }
  }

  const course: CourseFragmentFragment | undefined = dataCourse?.getCourse

  useEffect(() => {
    if (error) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.registrations.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.registrations.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        error?.message || errorCourse?.message
      )
    }
  }, [error, errorCourse])

  return (
    <CoursesRegistrations
      subNav={subNav}
      course={course}
      loading={loadingCourse || loading}
      confirmRegistration={confirmRegistration}
      sendMailing={sendMailing}
      unregister={unregister}
      saveComment ={saveComment}
      saveFinalPrice ={saveFinalPrice}
    />
  )
}
