import { useState } from 'react'

import ForgotPassword from './ForgotPassword'
import { useIntl } from 'react-intl'

import { useCaptcha, useAlert } from '@lemonbrain/hooks'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'

export default function ForgotPasswordBusiness ({
    forgotPassword,
    goToSignIn,
}: {
    forgotPassword: (username: string, reCaptchaToken: string) => Promise<"is-valid">,
    goToSignIn: (a: React.SyntheticEvent<HTMLButtonElement>) => unknown,
}) {
    const setAlert = useAlert()
    const [isLoading, setIsLoading] = useState(false)
    const intl = useIntl()
    const { isLoaded, isError, getCaptchaToken } = useCaptcha()

    async function onSubmit (username: string) {
        let state = false
        setIsLoading(true)

        let newState = ''
        try {
            const reCaptchaToken = await getCaptchaToken('forgotPassword')
            newState = await forgotPassword(username, reCaptchaToken)
        } catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.forgotPassword.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.forgotPassword.error.notSendedMail', defaultMessage: 'We could not send you a reset password mail. Try again.' }),
                style: 'error'
            })
        }
        state = newState === 'is-valid'
        if (state) {
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.forgotPassword.mailSent', defaultMessage: 'Mail sent' }),
                label: intl.formatMessage({ id: 'auth.forgotPassword.mailSent.success', defaultMessage: 'The reset password email has been sent successfully. Please check your inbox and spam folder.' }),
                style: 'success'
            })
        }
        setIsLoading(false)
        return state
    }

    if (isError) {
        setAlert && setAlert({
            title: intl.formatMessage({ id: 'auth.forgotPassword.error', defaultMessage: 'Error' }),
            label: intl.formatMessage({ id: 'auth.forgotPassword.error.googleRecaptchaCouldNotBeLoaded', defaultMessage: 'Google ReCaptcha could not be loaded' }),
            style: 'error'
        })
    }

    return isLoaded ?
    (
        <ForgotPassword
            onSubmit={onSubmit}
            onLoginClick={goToSignIn}
            isLoading={isLoading}
        />
    ) : (<Loader />)
}
