import { Cell, Grid } from 'baseui/layout-grid'
import { useIntl } from 'react-intl'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Checkbox } from 'baseui/checkbox'

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

export enum STATE {
  UPDATE = 'update',
  CREATE = 'create',
}

export default function AssociationTypeEdit({
  deError,
  frError,
  itError,
  userGroupError,
  values,
  isLoading,
  setValues,
  state,
  onUpdateClick,
  onCopyClick,
  onCancelClick,
  onCreateClick,
}: {
  deError: string
  frError: string
  itError: string
  userGroupError: string
  values: {
    isDefaultType: boolean
    userGroup: string
    de: string
    fr: string
    it: string
  }
  isLoading: boolean
  setValues: React.Dispatch<
    React.SetStateAction<{
      isDefaultType: boolean
      userGroup: string
      de: string
      fr: string
      it: string
    }>
  >
  state: STATE
  onUpdateClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCopyClick?:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCancelClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCreateClick?:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
}) {
  const intl = useIntl()

  return (
    <EditContainer
      state={state}
      onUpdateClick={onUpdateClick}
      onCopyClick={onCopyClick}
      onCancelClick={onCancelClick}
      onCreateClick={onCreateClick}
      isLoading={isLoading}
    >
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 1]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.associationTypeEdit.isDefaultType',
              defaultMessage: 'Default type',
            })}
          >
            <Checkbox
              checked={(values && values.isDefaultType) || false}
              onChange={() =>
                setValues((prev) => ({
                  ...prev,
                  isDefaultType: !prev.isDefaultType,
                }))
              }
            ></Checkbox>
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 2]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.associationTypeEdit.userGroup',
              defaultMessage: 'User group',
            })}
            error={userGroupError}
          >
            <Input
              value={(values && values.userGroup) || undefined}
              //@ts-ignore
              onChange={(userGroup) =>
                setValues((prev) => ({
                  ...prev,
                  userGroup: (userGroup?.target as HTMLInputElement).value,
                }))
              }
              positive={userGroupError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 3]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.associationTypeEdit.de',
              defaultMessage: 'Label (German)',
            })}
            error={deError}
          >
            <Input
              value={(values && values.de) || undefined}
              //@ts-ignore
              onChange={(de) =>
                setValues((prev) => ({
                  ...prev,
                  de: (de?.target as HTMLInputElement).value,
                }))
              }
              positive={deError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 3]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.associationTypeEdit.fr',
              defaultMessage: 'Label (French)',
            })}
            error={frError}
          >
            <Input
              value={(values && values.fr) || undefined}
              //@ts-ignore
              onChange={(fr) =>
                setValues((prev) => ({
                  ...prev,
                  fr: (fr?.target as HTMLInputElement).value,
                }))
              }
              positive={frError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 3]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.associationTypeEdit.it',
              defaultMessage: 'Label (Italian)',
            })}
            error={itError}
          >
            <Input
              value={(values && values.it) || undefined}
              //@ts-ignore
              onChange={(it) =>
                setValues((prev) => ({
                  ...prev,
                  it: (it?.target as HTMLInputElement).value,
                }))
              }
              positive={itError === ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </EditContainer>
  )
}
