import { useEffect } from 'react'

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { useIntl } from 'react-intl'

export enum STATE {
  UPDATE = 'update',
  CREATE = 'create',
}

export default function StateTypeEdit({
  state,
  values,
  setValues,
  isLoading,
  onCreateClick,
  onCopyClick,
  onUpdateClick,
  onCancelClick,
  checkDe,
  checkFr,
  checkIt,
  deError,
  frError,
  itError,
  setDeError,
  setFrError,
  setItError,
}: {
  state: STATE
  values: {
    stateTypeId: string
    de: string
    fr: string
    it: string
    stateId: string
    isTrialType: boolean
    requireMembership: boolean
  }
  setValues: React.Dispatch<
    React.SetStateAction<{
      stateTypeId: string
      de: string
      fr: string
      it: string
      stateId: string
      isTrialType: boolean
      requireMembership: boolean
    }>
  >
  isLoading: boolean
  onCreateClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCopyClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onUpdateClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCancelClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  checkDe: (
    de: string,
    setDeError: React.Dispatch<React.SetStateAction<string>>
  ) => boolean
  checkFr: (
    fr: string,
    setFrError: React.Dispatch<React.SetStateAction<string>>
  ) => boolean
  checkIt: (
    it: string,
    setItError: React.Dispatch<React.SetStateAction<string>>
  ) => boolean
  deError: string
  frError: string
  itError: string
  setDeError: React.Dispatch<React.SetStateAction<string>>
  setFrError: React.Dispatch<React.SetStateAction<string>>
  setItError: React.Dispatch<React.SetStateAction<string>>
}) {
  const intl = useIntl()

  useEffect(() => {
    if (values) {
      checkDe(values.de, setDeError)
      checkFr(values.fr, setFrError)
      checkIt(values.it, setItError)
    }
  }, [values])
  return (
    <EditContainer
      state={state}
      onUpdateClick={onUpdateClick}
      onCopyClick={onCopyClick}
      onCancelClick={onCancelClick}
      onCreateClick={onCreateClick}
      isLoading={isLoading}
    >
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateTypeEdit.de',
              defaultMessage: 'Label (German)',
            })}
            error={deError}
          >
            <Input
              value={(values && values.de) || ''}
              onChange={(de) =>
                setValues((prev) => ({
                  ...prev,
                  de: (de.target as HTMLInputElement).value,
                }))
              }
              positive={deError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateTypeEdit.fr',
              defaultMessage: 'Label (French)',
            })}
            error={frError}
          >
            <Input
              value={(values && values.fr) || ''}
              onChange={(fr) =>
                setValues((prev) => ({
                  ...prev,
                  fr: (fr.target as HTMLInputElement).value,
                }))
              }
              positive={frError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateTypeEdit.it',
              defaultMessage: 'Label (Italian)',
            })}
            error={itError}
          >
            <Input
              value={(values && values.it) || ''}
              onChange={(it) =>
                setValues((prev) => ({
                  ...prev,
                  it: (it.target as HTMLInputElement).value,
                }))
              }
              positive={itError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 3]}>
          <Checkbox
            checked={(values && values.isTrialType) || false}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                isTrialType: (e.target as HTMLInputElement).checked,
              }))
            }
            labelPlacement={LABEL_PLACEMENT.right}
          >
            {intl.formatMessage({
              id: 'settings.stateTypeEdit.isTrialType',
              defaultMessage: 'Is a trial',
            })}
          </Checkbox>
        </Cell>
        <Cell span={[12, 6, 3]}>
          <Checkbox
            checked={(values && values.requireMembership) || false}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                requireMembership: (e.target as HTMLInputElement).checked,
              }))
            }
            labelPlacement={LABEL_PLACEMENT.right}
          >
            {intl.formatMessage({
              id: 'settings.stateTypeEdit.requireMembership',
              defaultMessage: 'Requires a membership to be set',
            })}
          </Checkbox>
        </Cell>
      </Grid>
    </EditContainer>
  )
}
