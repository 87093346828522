import AssociationsData from './views/Associations/Associations.data'
import AssociationsOverviewData from './views/AssociationsOverview/AssociationsOverview.data'
import AssociationsAddData from './views/AssociationsAdd/AssociationsAdd.data'

import germanMessages from './messages/de'
import getReactApolloCients from './localReactApollo'

import {
  addMenuRoutesBeforeRender,
  addGermanMessagesBeforeRender,
  addWsClientBeforeRender,
} from '@lemonbrain/hooks'

addMenuRoutesBeforeRender('MAIN', [
  {
    title: {
      id: 'associations.associations',
      defaultMessage: 'Associations',
    },
    icon: 'associations',
    id: 'associations',
    path: '/associations/',
    routePath: '/associations',
    component: AssociationsData,
    size: 'small',
    tabIndex: 10,
    visible: true,
    active: true,
    enabled: true,
    accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
    children: [
      {
        title: {
          id: 'associations.overview',
          defaultMessage: 'Overview',
        },
        icon: 'associations',
        id: 'associationsOverview',
        path: '/associations/overview/:associationId?',
        routePath: '/associations/overview',
        component: AssociationsOverviewData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
      },
      {
        title: {
          id: 'associations.add',
          defaultMessage: 'Add',
        },
        icon: 'associations',
        id: 'associationsAdd',
        path: '/associations/add',
        routePath: '/associations/add',
        component: AssociationsAddData,
        size: 'small',
        tabIndex: 12,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
    ],
  },
])

const wsClient = getReactApolloCients().getWsClient()
if (wsClient) {
  addWsClientBeforeRender(wsClient)
}

addGermanMessagesBeforeRender(germanMessages)
