import { useEffect, useState } from 'react'
import { Cell, Grid } from 'baseui/layout-grid'
import { useIntl } from 'react-intl'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Checkbox } from 'baseui/checkbox'

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

export default function AddressTypeEdit({
  values,
  updateEntry,
  createEntry,
  updateCancel,
  isLoading,
  addressTypeId,
  checkDe,
  checkFr,
  checkIt,
  validate,
  setValues,
}: {
  values: {
    isInitType: boolean | undefined
    isFirm: boolean | undefined
    de: string | undefined
    fr: string | undefined
    it: string | undefined
  }
  updateEntry: ({
    addressTypeId,
    isInitType,
    de,
    fr,
    it,
  }: {
    addressTypeId: string
    isInitType: boolean
    isFirm: boolean
    de: string
    fr: string
    it: string
  }) => void
  createEntry: ({
    isInitType,
    de,
    fr,
    it,
  }: {
    isInitType: boolean
    isFirm: boolean
    de: string
    fr: string
    it: string
  }) => void
  updateCancel: () => void
  isLoading: boolean
  addressTypeId?: string | null | undefined
  checkDe: (
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>
  ) => boolean
  checkFr: (
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>
  ) => boolean
  checkIt: (
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>
  ) => boolean
  validate: (
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>,
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>,
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>
  ) => boolean
  setValues: React.Dispatch<
    React.SetStateAction<{
      isInitType: boolean | undefined
      isFirm: boolean | undefined
      de: string | undefined
      fr: string | undefined
      it: string | undefined
    }>
  >
}) {
  const intl = useIntl()
  const [deError, setDeError] = useState<string | null>(null)
  const [frError, setFrError] = useState<string | null>(null)
  const [itError, setItError] = useState<string | null>(null)

  function onUpdateClick() {
    if (
      values &&
      addressTypeId &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError
      ) &&
      values.de &&
      values.fr &&
      values.it &&
      values.isInitType !== undefined &&
      values.isFirm !== undefined
    ) {
      updateEntry({
        addressTypeId,
        isInitType: values.isInitType,
        isFirm: values.isFirm,
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  function onCopyClick() {
    if (
      values &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError
      ) &&
      values.de &&
      values.fr &&
      values.it &&
      values.isInitType !== undefined && 
      values.isFirm !== undefined
    ) {
      createEntry({
        isInitType: values.isInitType,
        isFirm: values.isFirm,
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  function onCancelClick() {
    updateCancel()
  }

  function onCreateClick() {
    if (
      values &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError
      ) &&
      values.de &&
      values.fr &&
      values.it &&
      values.isInitType !== undefined &&
      values.isFirm !== undefined
    ) {
      createEntry({
        isInitType: values.isInitType,
        isFirm: values.isFirm,
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  useEffect(() => {
    if (values) {
      checkDe(values.de, setDeError)
      checkFr(values.fr, setFrError)
      checkIt(values.it, setItError)
    }
  }, [values])

  return (
    <EditContainer
      state={!!addressTypeId ? 'update' : 'create'}
      onUpdateClick={onUpdateClick}
      onCopyClick={onCopyClick}
      onCancelClick={onCancelClick}
      onCreateClick={onCreateClick}
      isLoading={isLoading}
    >
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.addressTypeEdit.isInitType',
              defaultMessage: 'Init type',
            })}
          >
            <Checkbox
              checked={(values && values.isInitType) || false}
              onChange={() =>
                setValues((prev) => ({ ...prev, isInitType: !prev.isInitType }))
              }
            >
            </Checkbox>
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 6]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.addressTypeEdit.isFirm',
              defaultMessage: 'Is a firmaddress',
            })}
          >
            <Checkbox
              checked={(values && values.isFirm) || false}
              onChange={() =>
                setValues((prev) => ({ ...prev, isFirm: !prev.isFirm }))
              }
            >
            </Checkbox>
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.addressTypeEdit.de',
              defaultMessage: 'Label (German)',
            })}
            error={deError}
          >
            <Input
              value={(values && values.de) || undefined}
              //@ts-ignore
              onChange={(de) =>
                setValues((prev) => ({ ...prev, de: de.target.value }))
              }
              positive={deError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.addressTypeEdit.fr',
              defaultMessage: 'Label (French)',
            })}
            error={frError}
          >
            <Input
              value={(values && values.fr) || undefined}
              //@ts-ignore
              onChange={(fr) =>
                setValues((prev) => ({ ...prev, fr: fr.target.value }))
              }
              positive={frError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.addressTypeEdit.it',
              defaultMessage: 'Label (Italian)',
            })}
            error={itError}
          >
            <Input
              value={(values && values.it) || undefined}
              //@ts-ignore
              onChange={(it) =>
                setValues((prev) => ({ ...prev, it: it.target.value }))
              }
              positive={itError === ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </EditContainer>
  )
}
