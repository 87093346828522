import MembersInvoices from './MembersInvoices'

import { InnerRoute } from '@lemonbrain/hooks'

export default function MembersInvoicesBusiness({
  subNav,
  goToPerson,
  personId,
}: {
  subNav: InnerRoute[]
  goToPerson: (id: string | null) => void
  personId: string | undefined
}) {
  return (
    <MembersInvoices
      subNav={subNav}
      goToPerson={goToPerson}
      personId={personId}
    />
  )
}
