import { useState, useEffect, useCallback } from 'react'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { useCookies } from './useCookies'

import { onLogout } from '@lemonbrain/utils'

export interface UserType {
  uID?: string
  uName: string
  uEmail?: string
  uGroupsPath?: string[]
  uAvatar?: string
}

export interface jwtData {
  iss: string
  iat: string
  nbf: string
  exp: string
  data: {
    user: UserType
  }
}

export const getTokenWithoutRender = () =>
  Cookies.get(process.env.REACT_APP_AUTH_TOKEN_NAME || '')
export const setTokenWithoutRender = (value: string) =>
  Cookies.set(process.env.REACT_APP_AUTH_TOKEN_NAME || '', value)
export const clearTokenWithoutRender = () =>
  Cookies.remove(process.env.REACT_APP_AUTH_TOKEN_NAME || '')
export const isTokenExpiredWithoutRender = function () {
  try {
    if (
      getTokenWithoutRender() &&
      parseInt(jwtDecode<jwtData>(getTokenWithoutRender() || '').exp) >
        Math.round(new Date().getTime() / 1000)
    ) {
      return false
    }
  } catch (e) {
    clearTokenWithoutRender()
    return true
  }
  return true
}

export const getUserWithoutRender = (): UserType | undefined => {
  if (getTokenWithoutRender()) {
    try {
      const user = jwtDecode<jwtData>(getTokenWithoutRender() || '').data.user
      if (parseInt(user.uID || '0') > 0) {
        return user
      }
    } catch (e) {
      return undefined
    }
  }
  return undefined
}

export const useUser = (wsClients: SubscriptionClient[]) => {
  const [user, setUser] = useState<UserType | undefined>(!isTokenExpiredWithoutRender() ? getUserWithoutRender() : undefined)
  const { cookie: token, setCookie: setToken, clearCookie } = useCookies(
    process.env.REACT_APP_AUTH_TOKEN_NAME || ''
  )

  const clearToken = function () {
    clearCookie()
    wsClients.map((wsClient: SubscriptionClient) => onLogout(wsClient))
  }

  const getUser = useCallback(() => {
    if (token) {
      try {
        const user = jwtDecode<jwtData>(token).data.user
        if (parseInt(user.uID || '0') > 0) {
          return user
        }
      } catch (e) {
        return undefined
      }
    }
    return undefined
  }, [token])

  const isTokenExpired = function () {
    try {
      if (
        token &&
        parseInt(jwtDecode<jwtData>(token).exp) >
          Math.round(new Date().getTime() / 1000)
      ) {
        return false
      }
    } catch (e) {
      clearToken()
      return true
    }

    clearToken()
    return true
  }

  useEffect(() => {
    setUser(getUser())
  }, [token, getUser])

  return {
    user,
    isTokenExpired,
    setToken,
    clearToken,
  }
}
