import { useIntl } from 'react-intl'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Textarea } from 'baseui/textarea'

export default function Comment({
  comment,
  setComment,
}: {
  comment?: string
  setComment: React.Dispatch<React.SetStateAction<string>>
}) {
  const intl = useIntl()

  return (
    <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
      <Cell span={[12, 12, 12]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.comment.comment',
            defaultMessage: 'Comment',
          })}
        >
          <Textarea
            value={comment}
            onChange={(e) => setComment((e.target as unknown as HTMLInputElement).value)}
            clearOnEscape
          />
        </FormControl>
      </Cell>
    </Grid>
  )
}
