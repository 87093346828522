import { InnerRoute } from '@lemonbrain/hooks'

import MembershipMailing from './MembershipMailing'

export default function MembershipMailingBusiness({
  subNav,
  newEntry,
  refresh
}: {
  subNav: InnerRoute[]
  newEntry: () => void
  refresh: boolean
}) {
  return (
    <MembershipMailing
      subNav={subNav}
      newEntry={newEntry}
      refresh={refresh}
    />
  )
}
