import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { InnerRoute } from '@lemonbrain/hooks'
import { required, verifyPostcheck, verifyIBAN } from '@lemonbrain/utils'
import { useAlert } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'

import BillingInformations, { STATE } from './BillingInformations'

import {
  UpdateBillingInformationMutationVariables,
  AddBillingInformationMutationVariables,
} from '../../gql/BillingInformation.generated'

export default function BillingInformationsBusiness({
  subNav,
  values,
  setValues,
  isLoading,
  updateBillingInformationsEntry,
  addBillingInformationsEntry,
  refetchBillingInformationsEntry,
}: {
  subNav: InnerRoute[]
  values: UpdateBillingInformationMutationVariables
  setValues: React.Dispatch<
    React.SetStateAction<UpdateBillingInformationMutationVariables>
  >
  isLoading: boolean
  updateBillingInformationsEntry: (
    data: UpdateBillingInformationMutationVariables
  ) => void
  addBillingInformationsEntry: (
    data: AddBillingInformationMutationVariables
  ) => void
  refetchBillingInformationsEntry: () => void
}) {
  const [errors, setErrors] = useState({
    firmnameFrom: '',
    streetNrFrom: '',
    postCodeFrom: '',
    placeFrom: '',
    cantonFrom: '',
    firmname: '',
    streetNr: '',
    postCode: '',
    place: '',
    canton: '',
    account: '',
    iban: '',
    qrIban: '',
  })
  const intl = useIntl()
  const setAlert = useAlert()
  const { user } = useAppState()

  const updateEntry = async () => {
    if (validate()) {
      if (values.id && values.id !== '') {
        try {
          await updateBillingInformationsEntry({
            id: values.id,
            firstNameFrom: values.firstNameFrom,
            lastNameFrom: values.lastNameFrom,
            firmNameFrom: values.firmNameFrom,
            mailboxFrom: values.mailboxFrom,
            streetNrFrom: values.streetNrFrom,
            postCodeFrom: values.postCodeFrom,
            placeFrom: values.placeFrom,
            cantonFrom: values.cantonFrom,
            countryFrom: values.countryFrom,
            firstName: values.firstName,
            lastName: values.lastName,
            firmName: values.firmName,
            mailbox: values.mailbox,
            streetNr: values.streetNr,
            postCode: values.postCode,
            place: values.place,
            canton: values.canton,
            country: values.country,
            account: values.account,
            iban: values.iban,
            qrIban: values.qrIban,
            besrId: values.besrId,
          })
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.billingInformations.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'settings.billingInformations.saved.updated',
                defaultMessage: 'The entry is updated',
              }),
              style: 'success',
            })
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.billingInformations.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'settings.billingInformations.error.thereWasAnServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.billingInformations.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.billingInformations.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  const createEntry = async () => {
    if (validate()) {
      if (
        user &&
        user.uID &&
        values.postCodeFrom &&
        values.placeFrom &&
        values.postCode &&
        values.place &&
        values.account &&
        values.iban
      ) {
        try {
          await addBillingInformationsEntry({
            firstNameFrom: values.firstNameFrom,
            lastNameFrom: values.lastNameFrom,
            firmNameFrom: values.firmNameFrom,
            mailboxFrom: values.mailboxFrom,
            streetNrFrom: values.streetNrFrom,
            postCodeFrom: values.postCodeFrom,
            placeFrom: values.placeFrom,
            cantonFrom: values.cantonFrom,
            countryFrom: values.countryFrom,
            firstName: values.firstName,
            lastName: values.lastName,
            firmName: values.firmName,
            mailbox: values.mailbox,
            streetNr: values.streetNr,
            postCode: values.postCode,
            place: values.place,
            canton: values.canton,
            country: values.country,
            account: values.account,
            iban: values.iban,
            qrIban: values.qrIban,
            besrId: values.besrId,
          })
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.billingInformations.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'settings.billingInformations.saved.created',
                defaultMessage: 'The entry is created',
              }),
              style: 'success',
            })
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.billingInformations.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'settings.billingInformations.error.thereWasAnServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.billingInformations.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.billingInformations.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  function updateCancel() {
    refetchBillingInformationsEntry()
  }

  function checkFirmname() {
    if (required(values.firmName)) {
      setErrors((prev) => ({ ...prev, firmname: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      firmname: intl.formatMessage({
        id: 'settings.billingInformations.error.firmnameRequired',
        defaultMessage: 'Firmname is required',
      }),
    }))
    return true
  }

  function checkFirmnameFrom() {
    if (required(values.firmNameFrom)) {
      setErrors((prev) => ({ ...prev, firmnameFrom: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      firmnameFrom: intl.formatMessage({
        id: 'settings.billingInformations.error.firmnameRequired',
        defaultMessage: 'Firmname is required',
      }),
    }))
    return true
  }

  function checkStreetNr() {
    if (required(values.streetNr)) {
      setErrors((prev) => ({ ...prev, streetNr: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      streetNr: intl.formatMessage({
        id: 'settings.billingInformations.error.streetNrRequired',
        defaultMessage: 'Street and Nr is required',
      }),
    }))
    return true
  }

  function checkStreetNrFrom() {
    if (required(values.streetNrFrom)) {
      setErrors((prev) => ({ ...prev, streetNrFrom: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      streetNrFrom: intl.formatMessage({
        id: 'settings.billingInformations.error.streetNrRequired',
        defaultMessage: 'Street and Nr is required',
      }),
    }))
    return true
  }

  function checkPostCode() {
    if (required(values.postCode)) {
      setErrors((prev) => ({ ...prev, postCode: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      postCode: intl.formatMessage({
        id: 'settings.billingInformations.error.postCodeRequired',
        defaultMessage: 'Post code is required',
      }),
    }))
    return true
  }

  function checkPostCodeFrom() {
    if (required(values.postCodeFrom)) {
      setErrors((prev) => ({ ...prev, postCodeFrom: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      postCodeFrom: intl.formatMessage({
        id: 'settings.billingInformations.error.postCodeRequired',
        defaultMessage: 'Post code is required',
      }),
    }))
    return true
  }

  function checkPlace() {
    if (required(values.place)) {
      setErrors((prev) => ({ ...prev, place: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      place: intl.formatMessage({
        id: 'settings.billingInformations.error.placeRequired',
        defaultMessage: 'Place is required',
      }),
    }))
    return true
  }

  function checkPlaceFrom() {
    if (required(values.placeFrom)) {
      setErrors((prev) => ({ ...prev, placeFrom: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      placeFrom: intl.formatMessage({
        id: 'settings.billingInformations.error.placeRequired',
        defaultMessage: 'Place is required',
      }),
    }))
    return true
  }

  function checkCanton() {
    if (required(values.canton)) {
      setErrors((prev) => ({ ...prev, canton: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      canton: intl.formatMessage({
        id: 'settings.billingInformations.error.cantonRequired',
        defaultMessage: 'Canton is required',
      }),
    }))
    return true
  }

  function checkCantonFrom() {
    if (required(values.cantonFrom)) {
      setErrors((prev) => ({ ...prev, cantonFrom: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      cantonFrom: intl.formatMessage({
        id: 'settings.billingInformations.error.cantonRequired',
        defaultMessage: 'Canton is required',
      }),
    }))
    return true
  }

  function checkAccount() {
    if (values.account === '' || (required(values.account) && values.account && verifyPostcheck(values.account))) {
      setErrors((prev) => ({ ...prev, account: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      account: intl.formatMessage({
        id: 'settings.billingInformations.error.accountRequired',
        defaultMessage: 'Account is required and needs the correct form',
      }),
    }))
    return true
  }

  function checkIban() {
    if (required(values.iban) && values.iban && verifyIBAN(values.iban)) {
      setErrors((prev) => ({ ...prev, iban: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      iban: intl.formatMessage({
        id: 'settings.billingInformations.error.ibanRequired',
        defaultMessage: 'IBAN is required and needs the correct form',
      }),
    }))
    return true
  }

  function checkQrIban() {
    if(values.qrIban && values.qrIban !== '') {
      if (verifyIBAN(values.qrIban)
      ) {
        setErrors((prev) => ({ ...prev, qrIban: '' }))
        return false
      }
      setErrors((prev) => ({
        ...prev,
        qrIban: intl.formatMessage({
          id: 'settings.billingInformations.error.ibanQrRequired',
          defaultMessage: 'QR IBAN is required and needs the correct form',
        }),
      }))
    }
    return true
  }

  const validate = () => {
    let generalError = false

    generalError = checkFirmname() || generalError
    generalError = checkFirmnameFrom() || generalError
    generalError = checkStreetNr() || generalError
    generalError = checkStreetNrFrom() || generalError
    generalError = checkPostCode() || generalError
    generalError = checkPostCodeFrom() || generalError
    generalError = checkPlace() || generalError
    generalError = checkPlaceFrom() || generalError
    generalError = checkCanton() || generalError
    generalError = checkCantonFrom() || generalError
    generalError = checkAccount() || generalError
    generalError = checkIban() || generalError
    generalError = checkQrIban() || generalError
    return !generalError
  }

  useEffect(() => {
    validate()
  }, [values])

  return (
    <BillingInformations
      state={!!values.id ? STATE.UPDATE : STATE.CREATE}
      subNav={subNav}
      values={values}
      setValues={setValues}
      isLoading={isLoading}
      onUpdateClick={updateEntry}
      onCopyClick={createEntry}
      onCancelClick={updateCancel}
      onCreateClick={createEntry}
      errors={errors}
    />
  )
}
