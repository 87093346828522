import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'
import { Button } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts/PageWrapper'

export default function MembersCourses({
  subNav,
  goToPerson,
  personId,
}: {
  subNav: InnerRoute[]
  goToPerson: (id: string | null) => void
  personId: string | undefined
}) {
  const { components } = useAppState()
  const CoursesOverviewData = components && components['CoursesOverviewData']

  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <Block paddingBottom={['15px', '15px', '30px', '30px']}>
              <Button
                onClick={() => goToPerson(personId ?? null)}
                startEnhancer={() => <ArrowLeft size={24} />}
              >
                <FormattedMessage
                  id='members.membersCourses.toPerson'
                  defaultMessage={'To the person'}
                />
              </Button>
            </Block>
            <Block>
              <Block>
                <Block
                  paddingTop={['15px', '15px', '15px', 0]}
                  overrides={{
                    Block: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                      },
                    },
                  }}
                >
                  <Block>
                    <Block
                      as='h3'
                      overrides={{
                        Block: {
                          style: ({ $theme }) => {
                            return {
                              ...$theme.typography.font450,
                              color: $theme.colors.primaryA,
                              marginBottom: '15px',
                            }
                          },
                        },
                      }}
                    >
                      <FormattedMessage
                        id='members.courses'
                        defaultMessage={'Courses'}
                      />
                      {CoursesOverviewData && (
                        //@ts-ignores
                        <CoursesOverviewData personId={personId ?? null} />
                      )}
                    </Block>
                  </Block>
                </Block>
              </Block>
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
