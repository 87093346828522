import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { Spinner, SIZE } from 'baseui/spinner'

import CoursesEditBusiness from './CoursesEdit.business'
import {
  CreateCourseMutationVariables,
  UpdateCourseMutationVariables,
  useCreateCourseMutation,
  useGetCourseLazyQuery,
  useUpdateCourseMutation,
} from '../../../gql/Courses.generated'
import { CourseStatus } from '../../../types'
import { useAlert, useHistoryPush } from '@lemonbrain/hooks'
import getReactApolloCients from '../../../localReactApollo'
import { useGetWorkLevelsNoPersonQuery } from '../../../gql/WorkLevel.generated'
import { getTranslation, priceFormater } from '@lemonbrain/utils'

export default function CoursesEditData({
  goToCourse,
  courseId,
}: {
  goToCourse: (id: string | null) => void
  courseId?: string
}) {
  const intl = useIntl()
  const [course, setCourse] = useState<UpdateCourseMutationVariables & {
    priceText: string
    memberPriceText: string
  }>({
    id: '',
    language: '',
    educationalStageIds: [''],
    contentCategory: '',
    number: 0,
    title: {
      de: '',
      fr: '',
      it: ''
    },
    description: {
      de: '',
      fr: '',
      it: ''
    },
    price: 0,
    memberPrice: 0,
    priceText: '0.00',
    memberPriceText: '0.00',
    teacher: '',
    targetGroup: '',
    special: {
      de: '',
      fr: '',
      it: ''
    },
    date: '',
    endDate: '',
    time: '',
    place: '',
    subscribeTill: '',
    minSubscribers: 0,
    maxSubscribers: 0,
    options: [''],
    status: CourseStatus.Open,
  })
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const setAlert = useAlert()
  const [workLevels, setWorkLevels] = useState<
    {
      id: string
      label: string
    }[]
  >([])
  const courseStates = [
    {
      id: 'open',
      label: intl.formatMessage({
        id: 'courses.coursesTable.courseStatus.open',
        defaultMessage: 'open',
      })
    },
    {
      id: 'confirmed',
      label: intl.formatMessage({
        id: 'courses.coursesTable.courseStatus.confirmed',
        defaultMessage: 'confirmed',
      })
    },
    {
      id: 'cancelled',
      label: intl.formatMessage({
        id: 'courses.coursesTable.courseStatus.cancelled',
        defaultMessage: 'cancelled',
      })
    },
  ]
  const client = getReactApolloCients().getClient()

  const [
    getCourse,
    {
      data: dataCourse,
      loading: loadingCourse,
      error: errorCourse,
      refetch: refetchCourse,
    },
  ] = useGetCourseLazyQuery({
    variables: {
      id: courseId || '',
    },
    //@ts-ignore
    client,
  })

  const [
    updateCourseMutation,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useUpdateCourseMutation({
    //@ts-ignore
    client,
  })
  const [
    createCourseMutation,
    { data: dataCreate, loading: loadingCreate, error: errorCreate },
  ] = useCreateCourseMutation({
    //@ts-ignore
    client,
  })

  async function updateCreateEntry(data: UpdateCourseMutationVariables) {
    if (data.id && courseId) {
      await updateCourseMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    } else {
      let variables: CreateCourseMutationVariables & {
        id?: string
      } = data as CreateCourseMutationVariables
      delete variables.id
      await createCourseMutation({
        variables,
        //@ts-ignore
        client,
      })
    }
  }

  const {
    data: dataWorkLevels,
    loading: loadingWorkLevels,
    error: errorWorkLevels,
  } = useGetWorkLevelsNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (dataCourse?.getCourse) {
      delete dataCourse.getCourse.description?.__typename
      delete dataCourse.getCourse.title?.__typename
      delete dataCourse.getCourse.special?.__typename

      setCourse({
        id: dataCourse.getCourse.id || '',
        language: dataCourse.getCourse.language,
        educationalStageIds: dataCourse.getCourse.educationalStages.map(item => item?.id || ''),
        contentCategory: dataCourse.getCourse.contentCategory,
        number: dataCourse.getCourse.number,
        title: dataCourse.getCourse.title,
        description: dataCourse.getCourse.description,
        price: dataCourse.getCourse.price,
        memberPrice: dataCourse.getCourse.memberPrice,
        priceText: priceFormater((dataCourse.getCourse.price || 0) / 100),
        memberPriceText: priceFormater((dataCourse.getCourse.memberPrice || 0) / 100),
        teacher: dataCourse.getCourse.teacher,
        targetGroup: dataCourse.getCourse.targetGroup,
        special: dataCourse.getCourse.special,
        date: dataCourse.getCourse.date,
        endDate: dataCourse.getCourse.endDate,
        time: dataCourse.getCourse.time,
        place: dataCourse.getCourse.place,
        subscribeTill: dataCourse.getCourse.subscribeTill,
        minSubscribers: dataCourse.getCourse.minSubscribers,
        maxSubscribers: dataCourse.getCourse.maxSubscribers,
        options: dataCourse.getCourse.options,
        status: dataCourse.getCourse.status,
      })
    }
  }, [dataCourse])

  useEffect(() => {
    if (courseId) {
      getCourse()
    }
  }, [courseId])

  useEffect(() => {
    if (!loadingUpdate) {
      refetchCourse && refetchCourse()
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (dataWorkLevels?.getWorkLevelsNoPerson.items) {
      setWorkLevels(
        dataWorkLevels?.getWorkLevelsNoPerson.items
          .map((item) => ({
            id: item.id,
            label: getTranslation(item.name, intl),
          }))
          .sort(function (a, b) {
            var labelA = a.label.toUpperCase()
            var labelB = b.label.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataWorkLevels])

  useEffect(() => {
    if (errorCourse || errorUpdate || errorCreate || errorWorkLevels) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.courseEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'courses.courseEdit.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorCourse?.message ||
          errorUpdate?.message ||
          errorCreate?.message ||
          errorWorkLevels?.message
      )
    }
  }, [errorCourse, errorUpdate, errorCreate, errorWorkLevels])

  useEffect(() => {
    if (dataCreate?.createCourse.id) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.courseEdit.created',
            defaultMessage: 'Created',
          }),
          label: intl.formatMessage({
            id: 'courses.courseEdit.saved.created',
            defaultMessage: 'Entry is created',
          }),
          style: 'success',
        })
      pushPath(`/${language}/courses/overview/${dataCreate?.createCourse.id}`)
    }
  }, [dataCreate])

  useEffect(() => {
    if (dataUpdate?.updateCourse.id) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.courseEdit.saved',
            defaultMessage: 'Saved',
          }),
          label: intl.formatMessage({
            id: 'courses.courseEdit.saved.updated',
            defaultMessage: 'Entry is updated',
          }),
          style: 'success',
        })
    }
  }, [dataUpdate])

  return loadingWorkLevels ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <CoursesEditBusiness
      courseStates={courseStates}
      workLevels={workLevels}
      loading={loadingCourse || loadingUpdate || loadingCreate}
      updateCreateEntry={updateCreateEntry}
      course={course}
      setCourse={setCourse}
      goToCourse={goToCourse}
      courseId={courseId}
    />
  )
}
