import MemberAddFormBusiness from "./MemberAddForm.business"

export default function MemberAddFormData({
  values,
  setValues
}: {
  values: {
    email: string
  }
  setValues: React.Dispatch<React.SetStateAction<{ email: string }>>
}) {

  return (
    <MemberAddFormBusiness
      values={values}
      setValues={setValues}
    />
  )
}
