import { useState, useEffect, useCallback } from 'react'

let cachedScripts: {
    src: string,
    status: scriptStatus
}[] = []

export interface scriptStatus {
    loaded: boolean,
    error: boolean
}

export const useScript = (src: string) => {
    const [status, setStatus] = useState<scriptStatus>({
        loaded: false,
        error: false
    })

    const getIndex = useCallback((src: string) => {
        for(let i = 0; i<cachedScripts.length; i++) {
            if (cachedScripts[i].src === src) {
                return i
            }
        }

        return -1
    }, [])

    const removeItem = useCallback((src: string) => {
        const i = getIndex(src)
        if (i >= 0) {
            cachedScripts.splice(i, 1)
        }
    }, [getIndex])

    useEffect(() => {
        if (cachedScripts.filter(item => item.src === src).length > 0) {
            setStatus(cachedScripts.filter(item => item.src === src)[0].status)
            return () => {}
        } else {
            const script = document.createElement('script')
            script.src = src
            script.async = true
            const onLoad = () => {
                removeItem(src)
                setStatus(prev => ({
                    ...prev,
                    loaded: true
                }))
                cachedScripts.push({
                    src,
                    status: {
                        ...status,
                        loaded: true
                    }
                })
            }

            const onError = () => {
                removeItem(src)

                script.remove()

                setStatus(prev => ({
                    ...prev,
                    error: true
                }))
                cachedScripts.push({
                    src,
                    status: {
                        ...status,
                        error: true
                    }
                })
            }

            script.addEventListener('load', onLoad)
            script.addEventListener('error', onError)

            document.body.appendChild(script)

            return () => {
                script.removeEventListener('load', onLoad)
                script.removeEventListener('error', onError)
            }
        }

    }, [src, removeItem, status])

    return {
        isLoaded: status.loaded,
        isError: status.error
    }
}