import React, { useMemo, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useStyletron } from 'baseui'
import { useTable, usePagination } from 'react-table'
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Button } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { Delete, Show } from 'baseui/icon'
import { Tag, VARIANT } from 'baseui/tag'
import { Select } from 'baseui/select'

import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'

import NewsletterSendData from './NewsletterSend/NewsletterSend.data'

export type RowDataT = {
  id: string
  name: {
    de: string
    fr: string
    it: string
  }
  from: string
  subject: string
  mailingDate: string
  createdFrom: string
}

export default function NewslettersTable({
  data,
  onFetchMoreData,
  onRefetchData,
  loading,
  pageCount: controlledPageCount,
  pageSizeInput,
  setPageSizeInput,
  goToNewsletter,
  removeRow,
}: {
  data: RowDataT[]
  onFetchMoreData: () => void
  onRefetchData: () => void
  loading: boolean
  pageCount: number
  pageSizeInput: number
  setPageSizeInput: React.Dispatch<React.SetStateAction<number>>
  goToNewsletter: (id: string | null) => void
  removeRow?: (id: string) => void
}) {
  const intl = useIntl()
  const [css] = useStyletron()
  const [deleteId, setDeleteId] = useState<string | undefined>()

  const columns = useMemo(() => {
    const columns = [
      {
        Header: intl.formatMessage({
          id: 'newsletters.newslettersTable.newsletter',
          defaultMessage: 'Newsletter',
        }),
        accessor: 'name.de', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'newsletters.newslettersTable.from',
          defaultMessage: 'From',
        }),
        accessor: 'from', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'newsletters.newslettersTable.subject',
          defaultMessage: 'Subject',
        }),
        accessor: 'subject', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'newsletters.newslettersTable.mailingDate',
          defaultMessage: 'mailingDate',
        }),
        accessor: 'mailingDate', // accessor is the "key" in the data
      },
    ]

    return columns
  }, [])

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    //@ts-ignore
    page,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    setPageSize,
    //@ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      //@ts-ignore
      columns,
      data,
      initialState: {
        //@ts-ignore
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      pageSize: pageSizeInput,
    },
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'actions',
          Header: () => (
            <div>
              <FormattedMessage
                id='newsletters.newslettersTable.actions'
                defaultMessage={'Actions'}
              />
            </div>
          ),
          //@ts-ignore
          Cell: ({ row }) => (
            <ButtonGroup>
              <Button onClick={() => goToNewsletter(row.original.id)}>
                <Show />
              </Button>
              <Button onClick={() => setDeleteId(row.original.id)}>
                <Delete />
              </Button>
              <NewsletterSendData newsletterId={row.original.id} />
            </ButtonGroup>
          ),
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    if (pageSize !== pageSizeInput) {
      setPageSize(pageSizeInput)
      onRefetchData()
    } else {
      if (pageIndex !== controlledPageCount) {
        onFetchMoreData()
      }
    }
  }, [pageIndex, pageSizeInput])
  return (
    <>
      <SimpleModal<string>
        isOpen={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'newsletters.newslettersTable.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'newsletters.newslettersTable.deleteConfirm',
          defaultMessage: 'Are you sure to delete this newsletter?',
        })}
        data={deleteId || ''}
        onConfirm={(deleteId: string) => {
          removeRow && removeRow(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'newsletters.newslettersTable.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>
      <StyledTable
        role='grid'
        $gridTemplateColumns={`repeat(${columns.length + 1},1fr)`}
        {...getTableProps()}
      >
        {headerGroups.map((headerGroup, i) => (
          <React.Fragment key={i}>
            {
          //@ts-ignore
          headerGroup.headers.map((column, j) => (
              <StyledHeadCell {...column.getHeaderProps()}>
                {column.render('Header')}
                <span>
                  {
                    //@ts-ignore
                    column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </StyledHeadCell>
            ))}
          </React.Fragment>
        ))}
        {
          //@ts-ignore
          page.map((row, i) => {
            prepareRow(row)
            return (
              <React.Fragment key={i}>
                {
                  //@ts-ignore
                  row.cells.map((cell, j) => {
                    return (
                      <StyledBodyCell
                        key={i.toString() + j.toString()}
                        {...cell.getCellProps()}
                        $striped={true}
                      >
                        {cell.render('Cell')}
                      </StyledBodyCell>
                    )
                  })}
              </React.Fragment>
            )
          })}
        {loading ? (
          // Use our custom loading state to show a loading indicator
          //@ts-ignore
          <StyledBodyCell colSpan='10000'>
            <FormattedMessage
              id='newsletters.newslettersTable.loading'
              defaultMessage={'Loading...'}
            />
          </StyledBodyCell>
        ) : (
          //@ts-ignore
          <StyledBodyCell colSpan='10000'>
            <FormattedMessage
              id='newsletters.newslettersTable.results_count'
              defaultMessage='Showing {pageLength} results'
              values={{
                pageLength: page.length,
              }}
            />
          </StyledBodyCell>
        )}
      </StyledTable>
      <div
        className={css({
          paddingTop: '1rem',
        })}
      >
        <Grid align={ALIGNMENT.center}>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                <FormattedMessage
                  id='newsletters.newslettersTable.load_mores'
                  defaultMessage={'Load mores'}
                />
              </Button>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Tag closeable={false} variant={VARIANT.light} kind='neutral'>
                <FormattedMessage
                  id='newsletters.newslettersTable.page_count'
                  defaultMessage='Page {currentPage}'
                  values={{
                    currentPage: pageIndex + 1,
                  }}
                />
              </Tag>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Select
                clearable={false}
                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                  id: intl.formatMessage(
                    {
                      id: 'newsletters.newslettersTable.page_size_selection',
                      defaultMessage: 'Show {pageSize} Items per Page',
                    },
                    {
                      pageSize,
                    }
                  ),
                  pageSize,
                }))}
                labelKey='id'
                valueKey='pageSize'
                onChange={({ value }) => {
                  setPageSizeInput(Number(value[0].pageSize))
                }}
                value={[{ id: pageSize.toString(), pageSize }]}
              />
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  )
}
