import LoginData from './views/Login/Login.data'
import CheckValidationEmailData from './views/CheckValidationEmail/CheckValidationEmail.data'
import ValidateEmailData from './views/ValidateEmail/ValidateEmail.data'
import ForgotPasswordData from './views/ForgotPassword/ForgotPassword.data'
import ChangePasswordData from './views/ChangePassword/ChangePassword.data'
import SignUpData from './views/SignUp/SignUp.data'
import DisplayNameData from './views/DisplayName/DisplayName.data'
import EditLanguageData from './views/EditLanguage/EditLanguage.data'

import germanMessages from "./messages/de"

import { addOpenMenuRoutesBeforeRender, addGermanMessagesBeforeRender, addComponentsBeforeRender } from '@lemonbrain/hooks'

addOpenMenuRoutesBeforeRender('TOP', [
    {
        title: {
            id: 'auth.login.signIn',
            defaultMessage: 'Sign In'
        },
        icon: 'benutzer',
        id: 'login',
        path: '/login/:email?',
        routePath: '/login',
        component: LoginData,
        size: 'small',
        tabIndex: 10,
        visible: !!process.env.REACT_APP_SIGN_IN_VISIBLE,
        active: false,
        enabled: true,
        accessibleBy: [
            process.env.REACT_APP_GROUP_GUESTS || '',
        ],
    },
    {
        title: {
            id: 'auth.checkValidationEmail.emailValidation',
            defaultMessage: 'Email validation'
        },
        icon: 'benutzer',
        id: 'check-validation-email',
        path: '/check-validation-email/:uName',
        routePath: '/check-validation-email',
        component: CheckValidationEmailData,
        size: 'small',
        tabIndex: 11,
        visible: !!process.env.REACT_APP_EMAIL_VALIDATION_VISIBLE,
        active: false,
        enabled: true,
        accessibleBy: [
            process.env.REACT_APP_GROUP_GUESTS || '',
        ],
    },
    {
        title: {
            id: 'auth.validateEmail.emailValidation',
            defaultMessage: 'Email validation'
        },
        icon: 'benutzer',
        id: 'validate-email',
        path: '/validate-email/:token',
        routePath: '/validate-email',
        component: ValidateEmailData,
        size: 'small',
        tabIndex: 12,
        visible: !!process.env.REACT_APP_VALIDATE_EMAIL_VISIBLE,
        active: false,
        enabled: true,
        accessibleBy: [
            process.env.REACT_APP_GROUP_GUESTS || '',
        ],
    },
    {
        title: {
            id: 'auth.forgotPassword.forgotPassword',
            defaultMessage: 'Forgot Password'
        },
        icon: 'benutzer',
        id: 'forgot-password',
        path: '/forgot-password',
        routePath: '/forgot-password',
        component: ForgotPasswordData,
        size: 'small',
        tabIndex: 13,
        visible: !!process.env.REACT_APP_FORGOT_PASSWORD_VISIBLE,
        active: false,
        enabled: true,
        accessibleBy: [
            process.env.REACT_APP_GROUP_GUESTS || '',
        ],
    },
    {
        title: {
            id: 'auth.changePassword.changePassword',
            defaultMessage: 'Change Password'
        },
        icon: 'benutzer',
        id: 'change-password',
        path: '/change-password/:token',
        routePath: '/change-password',
        component: ChangePasswordData,
        size: 'small',
        tabIndex: 14,
        visible: !!process.env.REACT_APP_CHANGE_PASSWORD_VISIBLE,
        active: false,
        enabled: true,
        accessibleBy: [
            process.env.REACT_APP_GROUP_GUESTS || '',
        ],
    },
    {
        title: {
            id: 'auth.signUp.signUp',
            defaultMessage: 'Sign Up'
        },
        icon: 'benutzer',
        id: 'sign-up',
        path: '/sign-up',
        routePath: '/sign-up',
        component: SignUpData,
        size: 'small',
        tabIndex: 15,
        visible: !!process.env.REACT_APP_SIGN_UP_VISIBLE,
        active: false,
        enabled: !!process.env.REACT_APP_SIGN_UP_ENABLED,
        accessibleBy: [
            process.env.REACT_APP_GROUP_GUESTS || '',
        ],
    },
])

addGermanMessagesBeforeRender(germanMessages)

let components = {
    DisplayNameData,
    EditLanguageData
}

addComponentsBeforeRender(components)