import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import MembersEditBusiness from './MembersEdit.business'
import getReactApolloCients, {getReactApolloAuthCients} from '../../../localReactApollo'
import {
  useUpdateUserByIdMutation
} from '../../../gql/auth/Auth.generated'
import {
  useGetPersonQuery,
  useUpdatePersonMutation,
  GetPersonQuery,
  useAddPersonMutation,
  UpdatePersonMutationVariables,
  AddPersonMutationVariables,
} from '../../../gql/members/Person.generated'
import { useGetAssociationTypesNoPersonQuery } from '../../../gql/members/AssociationType.generated'
import { ArrayTasks } from '../../../gql/members/types'
import * as Apollo from '@apollo/client';
import * as Types from '../../../gql/members/types';

export default function MembersEditData({
  goToPerson,
  goToCourses,
  goToInvoices,
  personId,
}: {
  goToPerson: (id: string | null) => void
  goToCourses: (id: string | null) => void
  goToInvoices: (id: string | null) => void
  personId?: string
}) {
  const intl = useIntl()
  const [personal, setPersonal] = useState<
    | {
        id: string
        authId?: string
        email: string
        birthdate: string
        telephone: string
        mobile: string
        ahvNumber: string
        jsNumber: string
        slrgNumber: string
      }
    | undefined
  >({
    id: '',
    email: '',
    birthdate: '',
    telephone: '',
    mobile: '',
    ahvNumber: '',
    jsNumber: '',
    slrgNumber: '',
  })
  const [addresses, setAddresses] = useState<
    {
      id: string
      salutation: string
      firstName: string
      lastName: string
      firmName: string
      mailbox: string
      streetNr: string
      postCode: string
      place: string
      canton: string
      country: string
      types: {
        id: string
        isFirm?: boolean
        name: {
          de: string
          fr: string
          it: string
        }
      }
    }[]
  >([
    {
      id: (1).toString(),
      salutation: '',
      firstName: '',
      lastName: '',
      firmName: '',
      mailbox: '',
      streetNr: '',
      postCode: '',
      place: '',
      canton: '',
      country: '',
      types: {
        id: '',
        isFirm: false,
        name: {
          de: intl.formatMessage({
            id: 'members.addresses.new',
            defaultMessage: 'New',
          }),
          fr: intl.formatMessage({
            id: 'members.addresses.new',
            defaultMessage: 'New',
          }),
          it: intl.formatMessage({
            id: 'members.addresses.new',
            defaultMessage: 'New',
          }),
        },
      },
    },
    {
      id: (2).toString(),
      salutation: '',
      firstName: '',
      lastName: '',
      firmName: '',
      mailbox: '',
      streetNr: '',
      postCode: '',
      place: '',
      canton: '',
      country: '',
      types: {
        id: '',
        isFirm: false,
        name: {
          de: intl.formatMessage({
            id: 'members.addresses.new',
            defaultMessage: 'New',
          }),
          fr: intl.formatMessage({
            id: 'members.addresses.new',
            defaultMessage: 'New',
          }),
          it: intl.formatMessage({
            id: 'members.addresses.new',
            defaultMessage: 'New',
          }),
        },
      },
    },
  ])
  const [sportsEducation, setSportsEducation] = useState<
    {
      id: string
    }[]
  >([])
  const [workLevel, setWorkLevel] = useState<
    {
      id: string
    }[]
  >([])
  const [stateFunctions, setStateFunctions] = useState<
    {
      id: string
    }[]
  >([])
  const [svssFunctions, setSvssFunctions] = useState<
    {
      id: string
    }[]
  >([])
  const [newsletters, setNewsletters] = useState<
    {
      id: string
    }[]
  >([])
  const [associations, setAssociations] = useState<
    {
      associationId: string
      associationTypes: {
        id: string,
        label: string,
        task?: ArrayTasks
      }[]
    }[]
  >([])
  const [associationPayTo, setAssociationPayTo] = useState<
    {
      id: string
    }
  >({id: ''})
  const [state, setState] = useState('')
  const [stateType, setStateType] = useState('')
  const [membership, setMembership] = useState('')
  const [comment, setComment] = useState('')
  const [associationComment, setAssociationComment] = useState('')
  const [loading, setLoading] = useState(false)
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const authClient = getReactApolloAuthCients().getClient()


  let dataPerson: GetPersonQuery | undefined,
  loadingPerson: boolean = false,
  errorPerson: Apollo.ApolloError | undefined,
    refetch: (variables?: Partial<Types.Exact<{
      id: string;
  }>> | undefined) => Promise<Apollo.ApolloQueryResult<GetPersonQuery>> | undefined

  if(personId) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const result = useGetPersonQuery({
    variables: {
      id: personId,
    },
    //@ts-ignore
    client,
  })
  dataPerson = result.data
  loadingPerson = result.loading
  errorPerson = result.error
  refetch = result.refetch
}
  const [updateUserByIdMutation, { loading: userGroupLoading, error: userGroupError }] = useUpdateUserByIdMutation({
     //@ts-ignore
     client: authClient,
  });
  const [
    updatePersonMutation,
    { loading: loadingUpdate, error: loadingError },
  ] = useUpdatePersonMutation({
    //@ts-ignore
    client,
  })
  const [
    addPersonMutation,
    { loading: loadingAdd, error: errorAdd },
  ] = useAddPersonMutation({
    //@ts-ignore
    client,
  })

  async function updatePersonEntry(
    data: UpdatePersonMutationVariables
  ) {
    if (data.id && personId) {
      await updatePersonMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    } else {
      let variables: AddPersonMutationVariables & {
        id?: string
      } = data as AddPersonMutationVariables
      delete variables.id
      await addPersonMutation({
        variables,
        //@ts-ignore
        client,
      })
    }
  }

  const { data: dataAssociationTypes, loading: loadingAssociationTypes, error: errorAssociationTypes } = useGetAssociationTypesNoPersonQuery({
    variables: {
       cursor: {
         pageSize: 100,
       },
    },
    //@ts-ignore
    client,
  });

  useEffect(() => {
    if (dataPerson?.getPerson) {
      setPersonal({
        id: dataPerson.getPerson.id,
        authId: dataPerson.getPerson.authId || '',
        email: dataPerson.getPerson.email || '',
        birthdate: dataPerson.getPerson.birthdate || '',
        telephone: dataPerson.getPerson.telephone || '',
        mobile: dataPerson.getPerson.mobile || '',
        ahvNumber: dataPerson.getPerson.ahvNumber || '',
        jsNumber: dataPerson.getPerson.jsNumber || '',
        slrgNumber: dataPerson.getPerson.slrgNumber || '',
      })
      const addressesTmp: {
        id: string
        salutation: string
        firstName: string
        lastName: string
        firmName: string
        mailbox: string
        streetNr: string
        postCode: string
        place: string
        canton: string
        country: string
        types: {
          id: string
          isFirm: boolean
          name: {
            de: string
            fr: string
            it: string
          }
        }
      }[] = []
      switch (dataPerson.getPerson.addresses.length) {
        //@ts-ignore
        case 0:
          addressesTmp.push({
            id: (1).toString(),
            salutation: '',
            firstName: '',
            lastName: '',
            firmName: '',
            mailbox: '',
            streetNr: '',
            postCode: '',
            place: '',
            canton: '',
            country: '',
            types: {
              id: '',
              isFirm: false,
              name: {
                de: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
                fr: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
                it: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
              },
            },
          })
          addressesTmp.push({
            id: (2).toString(),
            salutation: '',
            firstName: '',
            lastName: '',
            firmName: '',
            mailbox: '',
            streetNr: '',
            postCode: '',
            place: '',
            canton: '',
            country: '',
            types: {
              id: '',
              isFirm: false,
              name: {
                de: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
                fr: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
                it: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
              },
            },
          })
          break
        case 1:
          addressesTmp.push({
            id: (1).toString(),
            salutation: '',
            firstName: '',
            lastName: '',
            firmName: '',
            mailbox: '',
            streetNr: '',
            postCode: '',
            place: '',
            canton: '',
            country: '',
            types: {
              id: '',
              isFirm: false,
              name: {
                de: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
                fr: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
                it: intl.formatMessage({
                  id: 'members.addresses.new',
                  defaultMessage: 'New',
                }),
              },
            },
          })
      }
      setAddresses([
        ...dataPerson.getPerson.addresses.map((item) => ({
          id: item.id || '',
          salutation: item.salutation || '',
          firstName: item.firstName || '',
          lastName: item.lastName || '',
          firmName: item.firmName || '',
          mailbox: item.mailbox || '',
          streetNr: item.streetNr || '',
          postCode: item.postCode.toString(),
          place: item.place || '',
          canton: item.canton || '',
          country: item.country || '',
          types: {
            id: item.types[0].id || '',
            isFirm: item.types[0].isFirm || false,
            name: {
              de: item.types[0].name.de || '',
              fr: item.types[0].name.fr || '',
              it: item.types[0].name.it || '',
            },
          },
          //@ts-ignore
        })).sort((a, b) => a.id - b.id),
        ...addressesTmp,
      ])
      setSportsEducation(() => {
        if (
          dataPerson?.getPerson.hasSportsEducation &&
          dataPerson?.getPerson.hasSportsEducation?.length > 0
        ) {
          return dataPerson.getPerson.hasSportsEducation.map(
            (item: { id: string }) => ({
              id: item.id,
            })
          )
        } else {
          return []
        }
      })
      setWorkLevel(() => {
        if (
          dataPerson?.getPerson.currentWork &&
          dataPerson?.getPerson.currentWork?.length > 0
        ) {
          return dataPerson.getPerson.currentWork.map(
            (item: { id: string }) => ({
              id: item.id,
            })
          )
        } else {
          return []
        }
      })
      setAssociations(() => {
        if (
          dataPerson?.getPerson.associationPersons &&
          dataPerson?.getPerson.associationPersons?.length > 0
        ) {
          return dataPerson.getPerson.associationPersons.map(
            //@ts-ignore
            (item: {
              association: {
                id: string
              }
              associationTypes: {
                id: string
                name: {
                  de: string,
                  fr: string,
                  it: string
                }
              }[]
            }) => ({
              associationId: item.association.id,
              associationTypes: item.associationTypes.map(item => ({
                id: item.id,
                label: getTranslation(item.name, intl),
              })),
            })
          )
        } else {
          return []
        }
      })
      setAssociationPayTo(dataPerson.getPerson.associationPayTo || {id: ''})

      setState(dataPerson.getPerson.state?.id || '')
      setStateType(dataPerson.getPerson.stateType?.id || '')
      setMembership(dataPerson.getPerson.membership?.id || '')
      setComment(dataPerson.getPerson.comment || '')
      setAssociationComment(dataPerson.getPerson.associationComment || '')
      setStateFunctions(() => {
        if (
          dataPerson?.getPerson.stateFunctions &&
          dataPerson?.getPerson.stateFunctions?.length > 0
        ) {
          return dataPerson.getPerson.stateFunctions.map(
            (item: { id: string }) => ({
              id: item.id,
            })
          )
        } else {
          return []
        }
      })
      setSvssFunctions(() => {
        if (
          dataPerson?.getPerson.svssFunctions &&
          dataPerson?.getPerson.svssFunctions?.length > 0
        ) {
          return dataPerson.getPerson.svssFunctions.map(
            (item: { id: string }) => ({
              id: item.id,
            })
          )
        } else {
          return []
        }
      })
      setNewsletters(() => {
        if (
          dataPerson?.getPerson.newsletter &&
          dataPerson?.getPerson.newsletter?.length > 0
        ) {
          return dataPerson.getPerson.newsletter.map(
            (item: { id: string }) => ({
              id: item.id,
            })
          )
        } else {
          return []
        }
      })
    }
  }, [dataPerson])

  useEffect(() => {
    setLoading(loadingPerson || loadingUpdate || userGroupLoading || loadingAssociationTypes || loadingAdd)
  }, [loadingPerson, loadingUpdate, userGroupLoading, loadingAssociationTypes, loadingAdd])

  useEffect(() => {
    if (!loadingUpdate) {
      refetch && refetch()
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (errorPerson || loadingError || userGroupError || errorAssociationTypes || errorAdd) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.membersEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.membersEdit.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }) + ' ' + (errorPerson?.message || loadingError?.message || userGroupError?.message || errorAssociationTypes?.message),
          style: 'error',
        })
      console.error(errorPerson?.message || loadingError?.message || userGroupError?.message || errorAssociationTypes?.message || errorAdd?.message)
    }
  }, [errorPerson, loadingError, userGroupError, errorAssociationTypes, errorAdd])
  return (
    <MembersEditBusiness
      getPerson={dataPerson?.getPerson}
      goToCourses={goToCourses}
      goToInvoices={goToInvoices}
      dataAssociationTypes={dataAssociationTypes}
      goToPerson={goToPerson}
      loading={loading}
      updatePersonEntry={updatePersonEntry}
      updateUserByIdMutation={updateUserByIdMutation}
      personal={personal}
      setPersonal={setPersonal}
      addresses={addresses}
      setAddresses={setAddresses}
      sportsEducation={sportsEducation}
      setSportsEducation={setSportsEducation}
      workLevel={workLevel}
      setWorkLevel={setWorkLevel}
      stateFunctions={stateFunctions}
      setStateFunctions={setStateFunctions}
      svssFunctions={svssFunctions}
      setSvssFunctions={setSvssFunctions}
      newsletters={newsletters}
      setNewsletters={setNewsletters}
      associations={associations}
      setAssociations={setAssociations}
      associationPayTo={associationPayTo}
      setAssociationPayTo={setAssociationPayTo}
      state={state}
      setState= {setState}
      stateType={stateType}
      setStateType={setStateType}
      membership={membership}
      setMembership={setMembership}
      associationComment={associationComment}
      setAssociationComment={setAssociationComment}
      comment={comment}
      setComment={setComment}
      personId={personId}
    />
  )
}
