import CreateOneAssociationInvoices from './CreateOneAssociationInvoices'

export default function CreateOneAssociationInvoicesBusiness({
    loading,
    createOneAssociationInvoice,
    progress,
}: {
    loading: boolean
    createOneAssociationInvoice: () => void
    progress: number | null
}) {

  return <CreateOneAssociationInvoices loading={loading} onClick={createOneAssociationInvoice} progress={progress} />
}
