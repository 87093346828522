// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type BillingInformationFragmentFragment = (
  { __typename?: 'BillingInformation' }
  & Pick<Types.BillingInformation, 'id' | 'firstNameFrom' | 'lastNameFrom' | 'firmNameFrom' | 'mailboxFrom' | 'streetNrFrom' | 'postCodeFrom' | 'placeFrom' | 'cantonFrom' | 'countryFrom' | 'firstName' | 'lastName' | 'firmName' | 'mailbox' | 'streetNr' | 'postCode' | 'place' | 'canton' | 'country' | 'account' | 'iban' | 'qrIban' | 'besrId' | 'createdFrom'>
);

export type GetBillingInformationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBillingInformationQuery = (
  { __typename?: 'Query' }
  & { getBillingInformation: (
    { __typename?: 'BillingInformation' }
    & BillingInformationFragmentFragment
  ) }
);

export type AddBillingInformationMutationVariables = Types.Exact<{
  firstNameFrom?: Types.Maybe<Types.Scalars['String']>;
  lastNameFrom?: Types.Maybe<Types.Scalars['String']>;
  firmNameFrom?: Types.Maybe<Types.Scalars['String']>;
  mailboxFrom?: Types.Maybe<Types.Scalars['String']>;
  streetNrFrom?: Types.Maybe<Types.Scalars['String']>;
  postCodeFrom: Types.Scalars['Int'];
  placeFrom: Types.Scalars['String'];
  cantonFrom?: Types.Maybe<Types.Scalars['String']>;
  countryFrom?: Types.Maybe<Types.Scalars['String']>;
  firstName?: Types.Maybe<Types.Scalars['String']>;
  lastName?: Types.Maybe<Types.Scalars['String']>;
  firmName?: Types.Maybe<Types.Scalars['String']>;
  mailbox?: Types.Maybe<Types.Scalars['String']>;
  streetNr?: Types.Maybe<Types.Scalars['String']>;
  postCode: Types.Scalars['Int'];
  place: Types.Scalars['String'];
  canton?: Types.Maybe<Types.Scalars['String']>;
  country?: Types.Maybe<Types.Scalars['String']>;
  account?: Types.Maybe<Types.Scalars['String']>;
  iban: Types.Scalars['String'];
  qrIban?: Types.Maybe<Types.Scalars['String']>;
  besrId?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type AddBillingInformationMutation = (
  { __typename?: 'Mutation' }
  & { addBillingInformation: (
    { __typename?: 'BillingInformation' }
    & BillingInformationFragmentFragment
  ) }
);

export type UpdateBillingInformationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  firstNameFrom?: Types.Maybe<Types.Scalars['String']>;
  lastNameFrom?: Types.Maybe<Types.Scalars['String']>;
  firmNameFrom?: Types.Maybe<Types.Scalars['String']>;
  mailboxFrom?: Types.Maybe<Types.Scalars['String']>;
  streetNrFrom?: Types.Maybe<Types.Scalars['String']>;
  postCodeFrom?: Types.Maybe<Types.Scalars['Int']>;
  placeFrom?: Types.Maybe<Types.Scalars['String']>;
  cantonFrom?: Types.Maybe<Types.Scalars['String']>;
  countryFrom?: Types.Maybe<Types.Scalars['String']>;
  firstName?: Types.Maybe<Types.Scalars['String']>;
  lastName?: Types.Maybe<Types.Scalars['String']>;
  firmName?: Types.Maybe<Types.Scalars['String']>;
  mailbox?: Types.Maybe<Types.Scalars['String']>;
  streetNr?: Types.Maybe<Types.Scalars['String']>;
  postCode?: Types.Maybe<Types.Scalars['Int']>;
  place?: Types.Maybe<Types.Scalars['String']>;
  canton?: Types.Maybe<Types.Scalars['String']>;
  country?: Types.Maybe<Types.Scalars['String']>;
  account?: Types.Maybe<Types.Scalars['String']>;
  iban?: Types.Maybe<Types.Scalars['String']>;
  qrIban?: Types.Maybe<Types.Scalars['String']>;
  besrId?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type UpdateBillingInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateBillingInformation: (
    { __typename?: 'BillingInformation' }
    & BillingInformationFragmentFragment
  ) }
);

export const BillingInformationFragmentFragmentDoc = gql`
    fragment BillingInformationFragment on BillingInformation {
  id
  firstNameFrom
  lastNameFrom
  firmNameFrom
  mailboxFrom
  streetNrFrom
  postCodeFrom
  placeFrom
  cantonFrom
  countryFrom
  firstName
  lastName
  firmName
  mailbox
  streetNr
  postCode
  place
  canton
  country
  account
  iban
  qrIban
  besrId
  createdFrom
}
    `;
export const GetBillingInformationDocument = gql`
    query getBillingInformation($id: ID!) {
  getBillingInformation(id: $id) {
    ...BillingInformationFragment
  }
}
    ${BillingInformationFragmentFragmentDoc}`;

/**
 * __useGetBillingInformationQuery__
 *
 * To run a query within a React component, call `useGetBillingInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBillingInformationQuery(baseOptions: Apollo.QueryHookOptions<GetBillingInformationQuery, GetBillingInformationQueryVariables>) {
        return Apollo.useQuery<GetBillingInformationQuery, GetBillingInformationQueryVariables>(GetBillingInformationDocument, baseOptions);
      }
export function useGetBillingInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingInformationQuery, GetBillingInformationQueryVariables>) {
          return Apollo.useLazyQuery<GetBillingInformationQuery, GetBillingInformationQueryVariables>(GetBillingInformationDocument, baseOptions);
        }
export type GetBillingInformationQueryHookResult = ReturnType<typeof useGetBillingInformationQuery>;
export type GetBillingInformationLazyQueryHookResult = ReturnType<typeof useGetBillingInformationLazyQuery>;
export type GetBillingInformationQueryResult = Apollo.QueryResult<GetBillingInformationQuery, GetBillingInformationQueryVariables>;
export const AddBillingInformationDocument = gql`
    mutation addBillingInformation($firstNameFrom: String, $lastNameFrom: String, $firmNameFrom: String, $mailboxFrom: String, $streetNrFrom: String, $postCodeFrom: Int!, $placeFrom: String!, $cantonFrom: String, $countryFrom: String, $firstName: String, $lastName: String, $firmName: String, $mailbox: String, $streetNr: String, $postCode: Int!, $place: String!, $canton: String, $country: String, $account: String, $iban: String!, $qrIban: String, $besrId: Int) {
  addBillingInformation(
    firstNameFrom: $firstNameFrom
    lastNameFrom: $lastNameFrom
    firmNameFrom: $firmNameFrom
    mailboxFrom: $mailboxFrom
    streetNrFrom: $streetNrFrom
    postCodeFrom: $postCodeFrom
    placeFrom: $placeFrom
    cantonFrom: $cantonFrom
    countryFrom: $countryFrom
    firstName: $firstName
    lastName: $lastName
    firmName: $firmName
    mailbox: $mailbox
    streetNr: $streetNr
    postCode: $postCode
    place: $place
    canton: $canton
    country: $country
    account: $account
    iban: $iban
    qrIban: $qrIban
    besrId: $besrId
  ) {
    ...BillingInformationFragment
  }
}
    ${BillingInformationFragmentFragmentDoc}`;
export type AddBillingInformationMutationFn = Apollo.MutationFunction<AddBillingInformationMutation, AddBillingInformationMutationVariables>;

/**
 * __useAddBillingInformationMutation__
 *
 * To run a mutation, you first call `useAddBillingInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBillingInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBillingInformationMutation, { data, loading, error }] = useAddBillingInformationMutation({
 *   variables: {
 *      firstNameFrom: // value for 'firstNameFrom'
 *      lastNameFrom: // value for 'lastNameFrom'
 *      firmNameFrom: // value for 'firmNameFrom'
 *      mailboxFrom: // value for 'mailboxFrom'
 *      streetNrFrom: // value for 'streetNrFrom'
 *      postCodeFrom: // value for 'postCodeFrom'
 *      placeFrom: // value for 'placeFrom'
 *      cantonFrom: // value for 'cantonFrom'
 *      countryFrom: // value for 'countryFrom'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      firmName: // value for 'firmName'
 *      mailbox: // value for 'mailbox'
 *      streetNr: // value for 'streetNr'
 *      postCode: // value for 'postCode'
 *      place: // value for 'place'
 *      canton: // value for 'canton'
 *      country: // value for 'country'
 *      account: // value for 'account'
 *      iban: // value for 'iban'
 *      qrIban: // value for 'qrIban'
 *      besrId: // value for 'besrId'
 *   },
 * });
 */
export function useAddBillingInformationMutation(baseOptions?: Apollo.MutationHookOptions<AddBillingInformationMutation, AddBillingInformationMutationVariables>) {
        return Apollo.useMutation<AddBillingInformationMutation, AddBillingInformationMutationVariables>(AddBillingInformationDocument, baseOptions);
      }
export type AddBillingInformationMutationHookResult = ReturnType<typeof useAddBillingInformationMutation>;
export type AddBillingInformationMutationResult = Apollo.MutationResult<AddBillingInformationMutation>;
export type AddBillingInformationMutationOptions = Apollo.BaseMutationOptions<AddBillingInformationMutation, AddBillingInformationMutationVariables>;
export const UpdateBillingInformationDocument = gql`
    mutation updateBillingInformation($id: ID!, $firstNameFrom: String, $lastNameFrom: String, $firmNameFrom: String, $mailboxFrom: String, $streetNrFrom: String, $postCodeFrom: Int, $placeFrom: String, $cantonFrom: String, $countryFrom: String, $firstName: String, $lastName: String, $firmName: String, $mailbox: String, $streetNr: String, $postCode: Int, $place: String, $canton: String, $country: String, $account: String, $iban: String, $qrIban: String, $besrId: Int) {
  updateBillingInformation(
    id: $id
    firstNameFrom: $firstNameFrom
    lastNameFrom: $lastNameFrom
    firmNameFrom: $firmNameFrom
    mailboxFrom: $mailboxFrom
    streetNrFrom: $streetNrFrom
    postCodeFrom: $postCodeFrom
    placeFrom: $placeFrom
    cantonFrom: $cantonFrom
    countryFrom: $countryFrom
    firstName: $firstName
    lastName: $lastName
    firmName: $firmName
    mailbox: $mailbox
    streetNr: $streetNr
    postCode: $postCode
    place: $place
    canton: $canton
    country: $country
    account: $account
    iban: $iban
    qrIban: $qrIban
    besrId: $besrId
  ) {
    ...BillingInformationFragment
  }
}
    ${BillingInformationFragmentFragmentDoc}`;
export type UpdateBillingInformationMutationFn = Apollo.MutationFunction<UpdateBillingInformationMutation, UpdateBillingInformationMutationVariables>;

/**
 * __useUpdateBillingInformationMutation__
 *
 * To run a mutation, you first call `useUpdateBillingInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingInformationMutation, { data, loading, error }] = useUpdateBillingInformationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstNameFrom: // value for 'firstNameFrom'
 *      lastNameFrom: // value for 'lastNameFrom'
 *      firmNameFrom: // value for 'firmNameFrom'
 *      mailboxFrom: // value for 'mailboxFrom'
 *      streetNrFrom: // value for 'streetNrFrom'
 *      postCodeFrom: // value for 'postCodeFrom'
 *      placeFrom: // value for 'placeFrom'
 *      cantonFrom: // value for 'cantonFrom'
 *      countryFrom: // value for 'countryFrom'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      firmName: // value for 'firmName'
 *      mailbox: // value for 'mailbox'
 *      streetNr: // value for 'streetNr'
 *      postCode: // value for 'postCode'
 *      place: // value for 'place'
 *      canton: // value for 'canton'
 *      country: // value for 'country'
 *      account: // value for 'account'
 *      iban: // value for 'iban'
 *      qrIban: // value for 'qrIban'
 *      besrId: // value for 'besrId'
 *   },
 * });
 */
export function useUpdateBillingInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingInformationMutation, UpdateBillingInformationMutationVariables>) {
        return Apollo.useMutation<UpdateBillingInformationMutation, UpdateBillingInformationMutationVariables>(UpdateBillingInformationDocument, baseOptions);
      }
export type UpdateBillingInformationMutationHookResult = ReturnType<typeof useUpdateBillingInformationMutation>;
export type UpdateBillingInformationMutationResult = Apollo.MutationResult<UpdateBillingInformationMutation>;
export type UpdateBillingInformationMutationOptions = Apollo.BaseMutationOptions<UpdateBillingInformationMutation, UpdateBillingInformationMutationVariables>;