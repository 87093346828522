export const languages = [
    'de_DE',
    'fr_FR',
    'it_IT',
  ]
  
  export function getLanguageOptions(intl: any) {
    const languageOptions: {
      id: string
      label: string
    }[] = []
    languages.forEach((language) => {
        languageOptions.push({
        id: language,
        label: intl.formatMessage({
          id: 'languages.' + language,
          defaultMessgae: language,
        }),
      })
    })
    return languageOptions
  }
  