import Membership from './Membership'

import { InnerRoute } from '@lemonbrain/hooks'

export default function MembershipBusiness({
  subNav,
  membershipId,
  setMembershipId,
}: {
  subNav: InnerRoute[]
  membershipId: string | null | undefined
  setMembershipId: (membershipId: string) => void
}) {
  return (
    <Membership
      subNav={subNav}
      membershipId={membershipId}
      setMembershipId={setMembershipId}
    />
  )
}