import SportsEducation from './SportsEducation'

import { InnerRoute } from '@lemonbrain/hooks'

export default function SportsEducationBusiness({
  subNav,
  sportsEducationId,
  setSportsEducationId,
}: {
  subNav: InnerRoute[]
  sportsEducationId: string | null | undefined
  setSportsEducationId: (sportsEducationId: string | undefined) => void
}) {
  return (
    <SportsEducation
      subNav={subNav}
      sportsEducationId={sportsEducationId}
      setSportsEducationId={setSportsEducationId}
    />
  )
}
