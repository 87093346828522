import React from 'react';

type SvgIconProps = {
  src: string;
  style?: React.CSSProperties;
};

const SvgIcon = ({ src, style }: SvgIconProps) => {
  return (
    <img
      style={style}
      className="svg-icon"
      src={src}
    />
  );
};

export default SvgIcon;
