import { useIntl } from 'react-intl'
import { Search } from 'baseui/icon'
import { Input } from 'baseui/input'
import { Select } from 'baseui/select'
import { Modal, SIZE, ROLE } from 'baseui/modal'
import { Grid, Cell } from 'baseui/layout-grid'
import { Tag } from 'baseui/tag'
import { Filter } from 'baseui/icon'

export enum ModalStatesBasic {
  empty = '',
}

export default function MembersFilter<ModalStates extends string>({
  modalState = ModalStatesBasic.empty,
  setModalState,
  query,
  setQuery,
  values,
  options,
  addFilter,
  editFilter,
  removeFilter,
  getMessage,
  filters,
}: {
  modalState?: ModalStates | ModalStatesBasic
  setModalState?: React.Dispatch<
    React.SetStateAction<ModalStates | ModalStatesBasic>
  >
  query: string
  setQuery: React.Dispatch<React.SetStateAction<string>>
  values?: ModalStates[]
  options?: {
    id: ModalStates
    label: string
  }[]
  addFilter?: (newValue: ModalStates[]) => void
  editFilter?: (value: ModalStates) => void
  removeFilter?: (oldValue: ModalStates) => void
  getMessage?: (modalState: ModalStates) => string
  filters?: {
    id: ModalStates
    Component: JSX.Element
  }[]
}) {
  const intl = useIntl()

  const filter = filters && filters.filter((item) => item.id === modalState)

  return (
    <>
      <Modal
        onClose={() => setModalState && setModalState(ModalStatesBasic.empty)}
        closeable
        isOpen={modalState !== ModalStatesBasic.empty}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Root: {
            style: () => ({
              zIndex: 999,
            }),
          },
        }}
      >
        {filter && filter.length > 0 && filter[0].Component}
      </Modal>
      <div style={{ marginLeft: '-10px', marginRight: '-10px' }}>
        <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
          <Cell span={[12, 6, 6]}>
            <Input
              endEnhancer={<Search size='18px' />}
              clearable
              value={query}
              onChange={(value) => setQuery(value.currentTarget.value)}
            />
          </Cell>
          {options && options.length > 0 && (
            <>
              {values && values.length > 0 && <Cell span={[12, 6, 6]}></Cell>}
              <Cell
                span={[12, 6, 6]}
                overrides={{
                  Cell: {
                    style: () => {
                      return {
                        marginTop: values && values.length > 0 ? '5px' : '0px',
                      }
                    },
                  },
                }}
              >
                <Select
                  options={options}
                  placeholder={intl.formatMessage({
                    id: 'members.members_filter.chooseFilter',
                    defaultMessage: 'Choose a filter',
                  })}
                  maxDropdownHeight='300px'
                  onChange={({ value }) =>
                  addFilter && addFilter(value.map((item) => item.id) as ModalStates[])
                  }
                />
              </Cell>
            </>
          )}
          {values && values.length > 0 && (
            <Cell span={[12, 6, 6]}>
              {values.map((item) => (
                <Tag
                  key={item}
                  closeable
                  onClick={() => editFilter && editFilter(item)}
                  onActionClick={() => removeFilter && removeFilter(item)}
                >
                  <Filter
                    overrides={{
                      Svg: {
                        style: () => ({
                          marginRight: '5px',
                        }),
                      },
                    }}
                  />
                  {getMessage && getMessage(item)}
                </Tag>
              ))}
            </Cell>
          )}
        </Grid>
      </div>
    </>
  )
}
