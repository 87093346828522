import { useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { MutationHookOptions, FetchResult } from '@apollo/client'
import { useParams } from 'react-router'

import { useAlert } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'
import {useHistoryPush} from '@lemonbrain/hooks'

import MembersEdit from './MembersEdit'
import {
  UpdatePersonMutationVariables,
} from '../../../gql/members/Person.generated'
import { PersonFragmentFragment } from '../../../gql/members/Person.generated'
import { GetAssociationTypesNoPersonQuery } from '../../../gql/members/AssociationType.generated'
import {
  UpdateUserByIdMutation,
  UpdateUserByIdMutationVariables,
} from '../../../gql/auth/Auth.generated'
import { ArrayTasks, ArrayUpdate } from '../../../gql/members/types'

export default function MembersEditBusiness({
  goToPerson,
  goToCourses,
  goToInvoices,
  loading,
  updatePersonEntry,
  updateUserByIdMutation,
  personal,
  setPersonal,
  addresses,
  setAddresses,
  getPerson,
  dataAssociationTypes,
  sportsEducation,
  setSportsEducation,
  workLevel,
  setWorkLevel,
  stateFunctions,
  setStateFunctions,
  svssFunctions,
  setSvssFunctions,
  newsletters,
  setNewsletters,
  associations,
  setAssociations,
  associationPayTo,
  setAssociationPayTo,
  associationComment,
  setAssociationComment,
  state,
  setState,
  stateType,
  setStateType,
  membership,
  setMembership,
  comment,
  setComment,
  personId,
}: {
  goToPerson: (id: string | null) => void
  goToCourses: (id: string | null) => void
  goToInvoices: (id: string | null) => void
  loading: boolean
  updatePersonEntry(
    data: UpdatePersonMutationVariables
  ): Promise<void>
  updateUserByIdMutation: (
    baseOptions?: MutationHookOptions<
      UpdateUserByIdMutation,
      UpdateUserByIdMutationVariables
    >
  ) => Promise<
    FetchResult<
      UpdateUserByIdMutation,
      Record<string, any>,
      Record<string, any>
    >
  >
  personal:
    | {
        id: string
        authId?: string
        email: string
        birthdate: string
        telephone: string
        mobile: string
        ahvNumber: string
        jsNumber: string
        slrgNumber: string
      }
    | undefined
  setPersonal: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          authId?: string
          email: string
          birthdate: string
          telephone: string
          mobile: string
          ahvNumber: string
          jsNumber: string
          slrgNumber: string
        }
      | undefined
    >
  >
  addresses: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      isFirm?: boolean
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }[]
  setAddresses: React.Dispatch<
    React.SetStateAction<
      {
        id: string
        salutation: string
        firstName: string
        lastName: string
        firmName: string
        mailbox: string
        streetNr: string
        postCode: string
        place: string
        canton: string
        country: string
        types: {
          id: string
          isFirm?: boolean
          name: {
            de: string
            fr: string
            it: string
          }
        }
      }[]
    >
  >
  getPerson?: { __typename?: 'Person' } & PersonFragmentFragment
  dataAssociationTypes: GetAssociationTypesNoPersonQuery | undefined
  sportsEducation?: { id: string }[]
  setSportsEducation: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  workLevel?: { id: string }[]
  setWorkLevel: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  stateFunctions?: { id: string }[]
  setStateFunctions: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  svssFunctions?: { id: string }[]
  setSvssFunctions: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  newsletters?: { id: string }[]
  setNewsletters: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  associations?: {
    associationId: string
    associationTypes: {
      id: string
      label: string
      task?: ArrayTasks
    }[]
  }[]
  setAssociations: React.Dispatch<
    React.SetStateAction<
      {
        associationId: string
        associationTypes: {
          id: string
          label: string
          task?: ArrayTasks
        }[]
      }[]
    >
  >
  associationPayTo: { id: string }
  setAssociationPayTo: React.Dispatch<React.SetStateAction<{ id: string }>>
  associationComment: string
  setAssociationComment: React.Dispatch<React.SetStateAction<string>>
  state: string
  setState: React.Dispatch<React.SetStateAction<string>>
  stateType: string
  setStateType: React.Dispatch<React.SetStateAction<string>>
  membership: string
  setMembership: React.Dispatch<React.SetStateAction<string>>
  comment: string
  setComment: React.Dispatch<React.SetStateAction<string>>
  personId?: string
}) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const [errors, setErrors] = useState<string[]>([])
  const [personalErrors, setPersonalErrors] = useState<string[]>([])
  const [sportsEducationErrors, setSportsEducationErrors] = useState<string[]>(
    []
  )
  const [workLevelErrors, setWorkLevelErrors] = useState<string[]>([])
  const [associationErrors, setAssociationErrors] = useState<string[]>([])
  const [addressErrors, setAddressErrors] = useState<string[]>([])
  const [stateErrors, setStateErrors] = useState<string[]>([])
  const [askForSave, setAskForSave] = useState(false)
  const intl = useIntl()
  const setAlert = useAlert()
  const { user } = useAppState()

  function getHasPersonalErrors(errors: { email: string; birthdate: string }) {
    if (errors && Object.values(errors)) {
      setPersonalErrors([
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map(
            (item) =>
              `${intl.formatMessage({
                id: 'members.membersEdit.personal',
                defaultMessage: '3/4 Personal',
              })} - ${item}`
          ),
      ])
    } else {
      setPersonalErrors([])
    }
  }

  function getHasSportsEducationErrors(errors: { sportsEducation: string }) {
    if (errors && Object.values(errors)) {
      setSportsEducationErrors([
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map(
            (item) =>
              `${intl.formatMessage({
                id: 'members.membersEdit.additional',
                defaultMessage: '4/4 Additional',
              })} - ${item}`
          ),
      ])
    } else {
      setSportsEducationErrors([])
    }
  }

  function getHasWorkLevelErrors(errors: { workLevel: string }) {
    if (errors && Object.values(errors)) {
      setWorkLevelErrors([
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map(
            (item) =>
              `${intl.formatMessage({
                id: 'members.membersEdit.additional',
                defaultMessage: '4/4 Additional',
              })} - ${item}`
          ),
      ])
    } else {
      setWorkLevelErrors([])
    }
  }

  function getHasAssociationErrors(errors: {
    association: string
    associationPayTo: string
  }) {
    if (errors && Object.values(errors)) {
      setAssociationErrors([
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map(
            (item) =>
              `${intl.formatMessage({
                id: 'members.membersEdit.additional',
                defaultMessage: '4/4 Additional',
              })} - ${item}`
          ),
      ])
    } else {
      setAssociationErrors([])
    }
  }

  function getHasStateErrors(errors: { state: string; stateType: string }) {
    if (errors && Object.values(errors)) {
      setStateErrors([
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map(
            (item) =>
              `${intl.formatMessage({
                id: 'members.membersEdit.state',
                defaultMessage: 'State',
              })} - ${item}`
          ),
      ])
    } else {
      setStateErrors([])
    }
  }

  function getHasAddressErrors(
    addressTypeName: string,
    errors: {
      salutation: string
      firstName: string
      lastName: string
      firmName: string
      streetNr: string
      postCode: string
      place: string
      canton: string
      country: string
    }
  ) {
    if (errors && Object.values(errors)) {
      setAddressErrors((prev) => [
        ...prev.filter((item) => item.indexOf(addressTypeName.slice(0, 4)) < 0),
        ...Object.values(errors)
          .filter((item) => item !== '')
          .map((item) => `${addressTypeName} - ${item}`),
      ])
    } else {
      setAddressErrors([])
    }
  }

  function validate() {
    return errors.length === 0
  }

  function cancelClickHandler() {
    goToPerson(null)
  }

  async function updateClickHandler() {
    if(!validate()) {
      setAskForSave(true)
    } else {
      save()
    }
  }

  async function save() {
    setAskForSave(false)
    if (personal) {
      if (user && user.uID && personal.id) {
        try {
          let variables: UpdatePersonMutationVariables = {
            id: personal.id,
            email: personal.email,
            birthdate: personal.birthdate,
            telephone: personal.telephone,
            mobile: personal.mobile,
            ahvNumber: personal.ahvNumber,
            jsNumber: personal.jsNumber,
            slrgNumber: personal.slrgNumber,
            addresses: getAddressesInput(),
          }

          let sportsEducationInput: {
            id: string
            task: ArrayTasks
          }[] = getSportsEducationInput()
          if (sportsEducationInput.length > 0) {
            variables = {
              ...variables,
              hasSportsEducation: sportsEducationInput,
            }
          }

          let workLevelInput: {
            id: string
            task: ArrayTasks
          }[] = getWorkLevelInput()
          if (workLevelInput.length > 0) {
            variables = {
              ...variables,
              currentWork: workLevelInput,
            }
          }

          let stateFunctionsInput: {
            id: string
            task: ArrayTasks
          }[] = getStateFunctionsInput()
          if (stateFunctionsInput.length > 0) {
            variables = {
              ...variables,
              stateFunctions: stateFunctionsInput,
            }
          }

          let svssFunctionsInput: {
            id: string
            task: ArrayTasks
          }[] = getSvssFunctionsInput()
          if (svssFunctionsInput.length > 0) {
            variables = {
              ...variables,
              svssFunctions: svssFunctionsInput,
            }
          }

          let newsletterInput: {
            id: string
            task: ArrayTasks
          }[] = getNewsletterInput()
          if (newsletterInput.length > 0) {
            variables = {
              ...variables,
              newsletter: newsletterInput,
            }
          }

          let associationInput: {
            associationId: string
            // personId: string
            associationTypes: {
              id: string
              task: ArrayTasks
            }[]
            task: ArrayUpdate
          }[] = await getAssociationInput()
          if (associationInput.length > 0) {
            variables = {
              ...variables,
              associationPersons: associationInput,
            }
          }

          let associationPayToInput: {
            id: string
            task: ArrayTasks
          } = getAssociationPayToInput()
          if (associationPayToInput.id !== '') {
            variables = {
              ...variables,
              associationPayTo: associationPayToInput,
            }
          }

          if (state !== '') {
            variables = {
              ...variables,
              stateId: state,
            }
          }

          if (stateType !== '') {
            variables = {
              ...variables,
              stateTypeId: stateType,
            }
          }

            variables = {
              ...variables,
              membershipId: membership,
            }

          if (comment !== '') {
            variables = {
              ...variables,
              comment,
            }
          }

          if (associationComment !== '') {
            variables = {
              ...variables,
              associationComment,
            }
          }

          await updatePersonEntry(
            variables,
          )
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'members.membersEdit.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'members.membersEdit.saved.updated',
                defaultMessage: 'Entry is updated',
              }),
              style: 'success',
            })
          pushPath('/' + language + '/members/overview')
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'members.membersEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'members.membersEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'members.membersEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'members.membersEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  const getAddressesInput = useCallback(() => {
    const addressesTmp = addresses.filter((item) => item.salutation !== '')
    let addressesToDelete = getPerson?.addresses.filter((oldItem) => {
      if (
        addressesTmp &&
        addressesTmp.filter(
          (currentItem) =>
            currentItem.id !== '' && currentItem.id === oldItem.id
        ).length === 0
      ) {
        return true
      }
      return false
    })
    let addressesInput: {
      id?: string
      salutation: string
      firstName: string
      lastName: string
      firmName: string
      mailbox: string
      streetNr: string
      postCode: number
      place: string
      canton: string
      country: string
      types?: {
        id: string
        task: ArrayTasks
      }[]
      task: ArrayUpdate
    }[] = []

    addressesInput = addressesTmp?.map((item) => {
      let types = null
      const existingAddress = getPerson?.addresses.filter(
        (item1) => item1.id === item.id
      )
      if (existingAddress && existingAddress.length > 0) {
        if (existingAddress[0].types[0].id !== item.types.id) {
          types = [
            {
              id:
                getPerson?.addresses.filter((item1) => item1.id === item.id)[0]
                  .types[0].id || '',
              task: ArrayTasks.Remove,
            },
            {
              id: item.types.id,
              task: ArrayTasks.Add,
            },
          ]
        }
      } else {
        return {
          salutation: item.salutation,
          firstName: item.firstName,
          lastName: item.lastName,
          firmName: item.firmName,
          mailbox: item.mailbox,
          streetNr: item.streetNr,
          postCode: parseInt(item.postCode),
          place: item.place,
          canton: item.canton,
          country: item.country,
          types: [
            {
              id: item.types.id,
              task: ArrayTasks.Add,
            },
          ],
          task: ArrayUpdate.Add,
        }
      }

      return types
        ? {
            id: item.id,
            salutation: item.salutation,
            firstName: item.firstName,
            lastName: item.lastName,
            firmName: item.firmName,
            mailbox: item.mailbox,
            streetNr: item.streetNr,
            postCode: parseInt(item.postCode),
            place: item.place,
            canton: item.canton,
            country: item.country,
            types,
            task: ArrayUpdate.Update,
          }
        : {
            id: item.id,
            salutation: item.salutation,
            firstName: item.firstName,
            lastName: item.lastName,
            firmName: item.firmName,
            mailbox: item.mailbox,
            streetNr: item.streetNr,
            postCode: parseInt(item.postCode),
            place: item.place,
            canton: item.canton,
            country: item.country,
            task: ArrayUpdate.Update,
          }
    })
    if (addressesToDelete && addressesToDelete.length > 0) {
      addressesToDelete.map((item) => {
        if (item) {
          if (!addressesInput) {
            addressesInput = []
          }
          addressesInput.push({
            id: item.id,
            salutation: item.salutation || '',
            firstName: item.firstName || '',
            lastName: item.lastName || '',
            firmName: item.firmName || '',
            mailbox: item.mailbox || '',
            streetNr: item.streetNr || '',
            postCode: item.postCode,
            place: item.place,
            canton: item.canton || '',
            country: item.country || '',
            task: ArrayUpdate.Remove,
          })
        }
      })
    }
    return addressesInput
  }, [addresses, getPerson])

  const getSportsEducationInput = useCallback(() => {
    let sportsEducationToDelete: ({ id: string } | undefined)[] = []

    let sportsEducationInput: {
      id: string
      task: ArrayTasks
    }[] = []
    if (
      getPerson?.hasSportsEducation &&
      getPerson?.hasSportsEducation?.length > 0
    ) {
      sportsEducationToDelete = getPerson?.hasSportsEducation.map(
        (oldItem: { id: string }) => {
          if (
            sportsEducation?.filter(
              (currentItem) => currentItem.id === oldItem.id
            ).length === 0
          ) {
            return oldItem
          }
          return undefined
        }
      )
    }

    let sportsEducationAdd =
      sportsEducation?.map((newItem) => {
        let existingSportsEducation: ({ id: string } | undefined)[] = []
        if (
          getPerson?.hasSportsEducation &&
          getPerson?.hasSportsEducation?.length > 0
        ) {
          existingSportsEducation = getPerson?.hasSportsEducation.filter(
            (item1: { id: string }) => item1.id === newItem.id
          )
        }
        if (!existingSportsEducation || existingSportsEducation.length === 0) {
          return newItem
        }
        return undefined
      }) || []
    if (sportsEducationAdd && sportsEducationAdd.length > 0) {
      sportsEducationAdd.map((item: { id: string } | undefined) => {
        if (item) {
          sportsEducationInput.push({
            id: item.id,
            task: ArrayTasks.Add,
          })
        }
      })
    }
    if (sportsEducationToDelete && sportsEducationToDelete.length > 0) {
      sportsEducationToDelete.map((item: { id: string } | undefined) => {
        if (item) {
          sportsEducationInput.push({
            id: item.id,
            task: ArrayTasks.Remove,
          })
        }
      })
    }

    return sportsEducationInput
  }, [getPerson, sportsEducation])

  const getWorkLevelInput = useCallback(() => {
    let workLevelToDelete: ({ id: string } | undefined)[] = []

    let workLevelInput: {
      id: string
      task: ArrayTasks
    }[] = []
    if (getPerson?.currentWork && getPerson?.currentWork?.length > 0) {
      workLevelToDelete = getPerson?.currentWork.map(
        (oldItem: { id: string }) => {
          if (
            workLevel?.filter((currentItem) => currentItem.id === oldItem.id)
              .length === 0
          ) {
            return oldItem
          }
          return undefined
        }
      )
    }

    let workLevelAdd =
      workLevel?.map((newItem) => {
        let existingWorkLevel: ({ id: string } | undefined)[] = []
        if (getPerson?.currentWork && getPerson?.currentWork?.length > 0) {
          existingWorkLevel = getPerson?.currentWork.filter(
            (item1: { id: string }) => item1.id === newItem.id
          )
        }
        if (!existingWorkLevel || existingWorkLevel.length === 0) {
          return newItem
        }
        return undefined
      }) || []
    if (workLevelAdd && workLevelAdd.length > 0) {
      workLevelAdd.map((item: { id: string } | undefined) => {
        if (item) {
          workLevelInput.push({
            id: item.id,
            task: ArrayTasks.Add,
          })
        }
      })
    }
    if (workLevelToDelete && workLevelToDelete.length > 0) {
      workLevelToDelete.map((item: { id: string } | undefined) => {
        if (item) {
          workLevelInput.push({
            id: item.id,
            task: ArrayTasks.Remove,
          })
        }
      })
    }

    return workLevelInput
  }, [getPerson, workLevel])

  const getStateFunctionsInput = useCallback(() => {
    let stateFunctionsToDelete: ({ id: string } | undefined)[] = []

    let stateFunctionsInput: {
      id: string
      task: ArrayTasks
    }[] = []
    if (getPerson?.stateFunctions && getPerson?.stateFunctions?.length > 0) {
      stateFunctionsToDelete = getPerson?.stateFunctions.map(
        (oldItem: { id: string }) => {
          if (
            stateFunctions?.filter(
              (currentItem) => currentItem.id === oldItem.id
            ).length === 0
          ) {
            return oldItem
          }
          return undefined
        }
      )
    }

    let stateFunctionsAdd =
      stateFunctions?.map((newItem) => {
        let existingStateFunctions: ({ id: string } | undefined)[] = []
        if (getPerson?.stateFunctions && getPerson.stateFunctions?.length > 0) {
          existingStateFunctions = getPerson?.stateFunctions.filter(
            (item1: { id: string }) => item1.id === newItem.id
          )
        }
        if (!existingStateFunctions || existingStateFunctions.length === 0) {
          return newItem
        }
        return undefined
      }) || []
    if (stateFunctionsAdd && stateFunctionsAdd.length > 0) {
      stateFunctionsAdd.map((item: { id: string } | undefined) => {
        if (item) {
          stateFunctionsInput.push({
            id: item.id,
            task: ArrayTasks.Add,
          })
        }
      })
    }
    if (stateFunctionsToDelete && stateFunctionsToDelete.length > 0) {
      stateFunctionsToDelete.map((item: { id: string } | undefined) => {
        if (item) {
          stateFunctionsInput.push({
            id: item.id,
            task: ArrayTasks.Remove,
          })
        }
      })
    }

    return stateFunctionsInput
  }, [getPerson, stateFunctions])

  const getSvssFunctionsInput = useCallback(() => {
    let svssFunctionsToDelete: ({ id: string } | undefined)[] = []

    let svssFunctionsInput: {
      id: string
      task: ArrayTasks
    }[] = []
    if (getPerson?.svssFunctions && getPerson?.svssFunctions?.length > 0) {
      svssFunctionsToDelete = getPerson?.svssFunctions.map(
        (oldItem: { id: string }) => {
          if (
            svssFunctions?.filter(
              (currentItem) => currentItem.id === oldItem.id
            ).length === 0
          ) {
            return oldItem
          }
          return undefined
        }
      )
    }

    let svssFunctionsAdd =
      svssFunctions?.map((newItem) => {
        let existingSvssFunctions: ({ id: string } | undefined)[] = []
        if (getPerson?.svssFunctions && getPerson?.svssFunctions?.length > 0) {
          existingSvssFunctions = getPerson?.svssFunctions.filter(
            (item1: { id: string }) => item1.id === newItem.id
          )
        }
        if (!existingSvssFunctions || existingSvssFunctions.length === 0) {
          return newItem
        }
        return undefined
      }) || []
    if (svssFunctionsAdd && svssFunctionsAdd.length > 0) {
      svssFunctionsAdd.map((item: { id: string } | undefined) => {
        if (item) {
          svssFunctionsInput.push({
            id: item.id,
            task: ArrayTasks.Add,
          })
        }
      })
    }
    if (svssFunctionsToDelete && svssFunctionsToDelete.length > 0) {
      svssFunctionsToDelete.map((item: { id: string } | undefined) => {
        if (item) {
          svssFunctionsInput.push({
            id: item.id,
            task: ArrayTasks.Remove,
          })
        }
      })
    }

    return svssFunctionsInput
  }, [getPerson, svssFunctions])

  const getNewsletterInput = useCallback(() => {
    let newsletterToDelete: ({ id: string } | undefined)[] = []

    let newsletterInput: {
      id: string
      task: ArrayTasks
    }[] = []
    if (getPerson?.newsletter && getPerson?.newsletter?.length > 0) {
      newsletterToDelete = getPerson?.newsletter.map(
        (oldItem: { id: string }) => {
          if (
            newsletters?.filter(
              (currentItem) => currentItem.id === oldItem.id
            ).length === 0
          ) {
            return oldItem
          }
          return undefined
        }
      )
    }

    let newsletterAdd =
      newsletters?.map((newItem) => {
        let existingNewsletter: ({ id: string } | undefined)[] = []
        if (getPerson?.newsletter && getPerson?.newsletter?.length > 0) {
          existingNewsletter = getPerson?.newsletter.filter(
            (item1: { id: string }) => item1.id === newItem.id
          )
        }
        if (!existingNewsletter || existingNewsletter.length === 0) {
          return newItem
        }
        return undefined
      }) || []
    if (newsletterAdd && newsletterAdd.length > 0) {
      newsletterAdd.map((item: { id: string } | undefined) => {
        if (item) {
          newsletterInput.push({
            id: item.id,
            task: ArrayTasks.Add,
          })
        }
      })
    }
    if (newsletterToDelete && newsletterToDelete.length > 0) {
      newsletterToDelete.map((item: { id: string } | undefined) => {
        if (item) {
          newsletterInput.push({
            id: item.id,
            task: ArrayTasks.Remove,
          })
        }
      })
    }
    return newsletterInput
  }, [getPerson, newsletters])

  const getAssociationInput = useCallback(async () => {
    let associationToDelete: (
      | {
          associationId: string
          // personId: string
          associationTypes: {
            id: string
            task: ArrayTasks
          }[]
        }
      | undefined
    )[] = []

    let associationInput: {
      associationId: string
      // personId: string
      associationTypes: {
        id: string
        task: ArrayTasks
      }[]
      task: ArrayUpdate
    }[] = []
    if (
      getPerson?.associationPersons &&
      getPerson.associationPersons?.length > 0
    ) {
      //@ts-ignore
      associationToDelete = getPerson?.associationPersons.map(
        //@ts-ignore
        (oldItem: {
          association: {
            id: string
          }
          associationTypes: {
            id: string
          }[]
        }) => {
          if (
            associations?.filter(
              (currentItem) =>
                currentItem.associationId === oldItem.association.id
            ).length === 0
          ) {
            return {
              associationId: oldItem.association.id,
              // personId: personal?.id || '',
              associationTypes: oldItem.associationTypes,
            }
          }
          return undefined
        }
      )
    }

    let associationAdd =
      associations?.map((newItem) => {
        let existingAssociation: (
          | {
              associationId: string
              // personId: string
              associationTypes: {
                id: string
                task: ArrayTasks
              }[]
            }
          | undefined
        )[] = []
        if (
          getPerson?.associationPersons &&
          getPerson.associationPersons?.length > 0
        ) {
          //@ts-ignore
          existingAssociation = getPerson?.associationPersons
            .filter(
              //@ts-ignore
              (item1: {
                association: {
                  id: string
                }
                associationTypes: {
                  id: string
                }[]
              }) => item1.association.id === newItem.associationId
            )
            .map(
              //@ts-ignore
              (item2: {
                association: {
                  id: string
                }
                associationTypes: {
                  id: string
                }[]
              }) => ({
                associationId: item2.association.id,
                // personId: personal?.id || '',
                associationTypes: item2.associationTypes,
              })
            )
        }
        if (!existingAssociation || existingAssociation.length === 0) {
          return {
            ...newItem,
            // personId: personal?.id || '',
          }
        }
        return undefined
      }) || []
    if (associationAdd && associationAdd.length > 0) {
      associationAdd.map(
        (
          item:
            | {
                associationId: string
                // personId: string
                associationTypes: {
                  id: string
                }[]
              }
            | undefined
        ) => {
          if (item) {
            associationInput.push({
              ...item,
              associationTypes: item.associationTypes.map(
                (associationType) => ({
                  id: associationType.id,
                  task: ArrayTasks.Add,
                })
              ),
              task: ArrayUpdate.Add,
            })
          }
        }
      )
    }
    if (associationToDelete && associationToDelete.length > 0) {
      associationToDelete.map(
        (
          item:
            | {
                associationId: string
                // personId: string
                associationTypes: {
                  id: string
                }[]
              }
            | undefined
        ) => {
          if (item) {
            associationInput.push({
              ...item,
              associationTypes: item.associationTypes.map(
                (associationType) => ({
                  id: associationType.id,
                  task: ArrayTasks.Remove,
                })
              ),
              task: ArrayUpdate.Remove,
            })
          }
        }
      )
    }

    associations?.map((item) => {
      if (
        !associationInput.find(
          (item1) => item.associationId === item1.associationId
        )
      ) {
        if (
          getPerson?.associationPersons &&
          getPerson.associationPersons?.length > 0
        ) {
          let associationTypes: {
            id: string
            task: ArrayTasks
          }[] = []

      //@ts-ignore
          const existingAssociationTypes = getPerson?.associationPersons.find(
            //@ts-ignore
            (item2: {
              association: {
                id: string
              }
              associationTypes: {
                id: string
              }[]
            }) => item2.association.id === item.associationId
          ).associationTypes

          const toDelete = existingAssociationTypes?.filter(
            (item2: { id: string }) =>
              !item.associationTypes.find((item3) => item3.id === item2.id)
          )
          const toAdd = item.associationTypes.filter(
            (item2) =>
              !existingAssociationTypes?.find(
                (item3: { id: string }) => item3.id === item2.id
              )
          )

          toDelete?.map((item2: { id: string }) => {
            associationTypes.push({
              id: item2.id,
              task: ArrayTasks.Remove,
            })
          })

          toAdd.map((item2: { id: string }) => {
            associationTypes.push({
              id: item2.id,
              task: ArrayTasks.Add,
            })
          })

          associationInput.push({
            associationId: item.associationId,
            // personId: personal?.id || '',
            associationTypes,
            task: ArrayUpdate.Update,
          })
        }
      }
    })

    const variables: {
      id: string
      groups: {
        name: string
        task: string
      }[]
    } = {
      id: getPerson?.authId || '',
      groups: [],
    }
    for (const associationChanges of associationInput) {
      for (const associationTypeInput of associationChanges.associationTypes) {
        const associationType = dataAssociationTypes?.getAssociationTypesNoPerson.items.find(
          (item) => item.id === associationTypeInput.id
        )
        if (associationType) {
          variables.groups = [
            ...variables.groups,
            {
              name: `${process.env.REACT_APP_USER_GROUP_PATH}${associationType.userGroup}`,
              task:
                associationTypeInput.task === ArrayTasks.Remove
                  ? 'REMOVE'
                  : 'ADD',
            },
          ].sort((a, b) => (a.task > b.task ? -1 : 1))
        }
      }
    }

    if (variables.groups.length > 0) {
      await updateUserByIdMutation({
        variables,
      })
    }

    return associationInput
  }, [getPerson, associations])

  const getAssociationPayToInput = useCallback(() => {
    let associationInput: {
      id: string
      task: ArrayTasks
    } = {
      id: '',
      task: ArrayTasks.Add,
    }
    if (
      getPerson?.associationPayTo &&
      getPerson.associationPayTo.id !== '' &&
      getPerson?.associationPayTo.id !== associationPayTo.id
    ) {
      associationInput = {
        id: associationPayTo.id,
        task: ArrayTasks.Add,
      }
    } else if (
      !getPerson?.associationPayTo?.id ||
      getPerson?.associationPayTo?.id === ''
    ) {
      associationInput = {
        id: associationPayTo.id,
        task: ArrayTasks.Add,
      }
    }

    return associationInput
  }, [getPerson, associationPayTo])

  function setAddress(address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }) {
    setAddresses((prev) =>
      prev.map((item) => {
        if (item.id === address.id) {
          return {
            ...address,
          }
        } else {
          return item
        }
      })
    )
  }

  useEffect(() => {
    setErrors([
      ...personalErrors,
      ...addressErrors,
      ...sportsEducationErrors,
      ...workLevelErrors,
      ...associationErrors,
      ...stateErrors,
    ])
  }, [
    personalErrors,
    addressErrors,
    sportsEducationErrors,
    workLevelErrors,
    associationErrors,
    stateErrors,
  ])

  return (
    <MembersEdit
      errors={errors}
      goToPerson={goToPerson}
      goToCourses={goToCourses}
      goToInvoices={goToInvoices}
      onCancelClick={cancelClickHandler}
      isLoading={loading}
      onUpdateClick={updateClickHandler}
      personal={personal}
      setPersonal={setPersonal}
      addresses={addresses}
      setAddress={setAddress}
      getHasPersonalErrors={getHasPersonalErrors}
      getHasAddressErrors={getHasAddressErrors}
      sportsEducation={sportsEducation}
      setSportsEducation={setSportsEducation}
      getHasSportsEducationErrors={getHasSportsEducationErrors}
      workLevel={workLevel}
      setWorkLevel={setWorkLevel}
      stateFunctions={stateFunctions}
      setStateFunctions={setStateFunctions}
      svssFunctions={svssFunctions}
      setSvssFunctions={setSvssFunctions}
      newsletters={newsletters}
      setNewsletters={setNewsletters}
      getHasWorkLevelErrors={getHasWorkLevelErrors}
      associations={associations}
      setAssociations={setAssociations}
      associationPayTo={associationPayTo}
      setAssociationPayTo={setAssociationPayTo}
      getHasAssociationErrors={getHasAssociationErrors}
      state={state}
      setState={setState}
      stateType={stateType}
      setStateType={setStateType}
      membership={membership}
      setMembership={setMembership}
      associationComment={associationComment}
      setAssociationComment={setAssociationComment}
      getHasStateErrors={getHasStateErrors}
      comment={comment}
      setComment={setComment}
      user={user}
      personId={personId}
      askForSave={askForSave}
      setAskForSave={setAskForSave}
      save={save}
    />
  )
}
