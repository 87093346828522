import { useState, useEffect } from 'react'

import StateFunctionTableBusiness from './StateFunctionTable.business'
import { RowDataT } from './StateFunctionTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteStateFunctionMutation,
  useGetStateFunctionsNoPersonQuery,
  GetNextStateFunctionsNoPersonDocument,
  GetNextStateFunctionsNoPersonQuery,
} from '../../../gql/StateFunction.generated'

export default function StateFunctionTableData({
  setStateFunctionId,
}: {
  setStateFunctionId: (stateFunctionId: string | undefined) => void
}) {
  const [stateFunctions, setStateFunctions] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetStateFunctionsNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteStateFunctionMutation] = useDeleteStateFunctionMutation({
    //@ts-ignore
    client,
  })

  async function removeStateFunctionEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteStateFunctionMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteStateFunction.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreStateFunctionEntries() {
    if (data?.getStateFunctionsNoPerson.nextCursor) {
      fetchMore({
        query: GetNextStateFunctionsNoPersonDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getStateFunctionsNoPerson.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextStateFunctionsNoPersonQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextStateFunctionsNoPerson: {
                nextCursor: fetchMoreResult.getNextStateFunctionsNoPerson.nextCursor,
                items: [...fetchMoreResult.getNextStateFunctionsNoPerson.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextStateFunctionsNoPerson.items.length > 0 &&
            previousResult.getStateFunctionsNoPerson.items.filter((item) => {
              return item.id === fetchMoreResult.getNextStateFunctionsNoPerson.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getStateFunctionsNoPerson: {
                ...previousResult.getStateFunctionsNoPerson,
                nextCursor: fetchMoreResult.getNextStateFunctionsNoPerson.nextCursor,
                items: [
                  ...previousResult.getStateFunctionsNoPerson.items,
                  ...fetchMoreResult.getNextStateFunctionsNoPerson.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getStateFunctionsNoPerson.items) {
      setStateFunctions(
        data?.getStateFunctionsNoPerson.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToStateFunctionEntry(id: string) {
    setStateFunctionId(id)
  }

  return (
    <StateFunctionTableBusiness
      isLoading={loading}
      stateFunctions={stateFunctions}
      fetchMoreStateFunctionEntries={fetchMoreStateFunctionEntries}
      refetchStateFunctionEntries={refetch}
      goToStateFunctionEntry={goToStateFunctionEntry}
      removeStateFunctionEntry={removeStateFunctionEntry}
      queryError={queryError}
    />
  )
}
