import State from './State'

import { InnerRoute } from '@lemonbrain/hooks'

export default function StateBusiness({
  subNav,
  stateId,
  setStateId,
  listStateTypes
}: {
  subNav: InnerRoute[]
  stateId: string | null | undefined
  setStateId: (stateId: string | undefined) => void
  listStateTypes: (stateId: string) => void
}) {
  return (
    <State
      subNav={subNav}
      stateId={stateId}
      setStateId={setStateId}
      listStateTypes={listStateTypes}
    />
  )
}
