import React, { useMemo, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useStyletron } from 'baseui'
import { useTable, usePagination } from 'react-table'
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Button } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { Delete, Show, CheckIndeterminate, Plus } from 'baseui/icon'
import { Tag, VARIANT } from 'baseui/tag'
import { Select } from 'baseui/select'

import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'
import { getTranslation } from '@lemonbrain/utils'

import { GetStatesNoPersonQuery } from '../../../gql/members/State.generated'

export type RowDataT = {
  id: string
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  firmName?: string | null
  state?: {
    id: string
    name: {
      de: string
      fr: string
      it: string
    }
  }
}

export default function MembersTable({
  data,
  onFetchMoreData,
  onRefetchData,
  loading,
  pageCount: controlledPageCount,
  pageSizeInput,
  setPageSizeInput,
  goToPerson,
  removeRow,
  dataState,
  setSelectedPerson,
}: {
  data: RowDataT[]
  onFetchMoreData: () => void
  onRefetchData: () => void
  loading: boolean
  pageCount: number
  pageSizeInput: number
  setPageSizeInput: React.Dispatch<React.SetStateAction<number>>
  goToPerson: (id: string | null) => void
  removeRow: (id: string) => void
  dataState: GetStatesNoPersonQuery | undefined
  setSelectedPerson: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const intl = useIntl()
  const [css] = useStyletron()
  const [deleteId, setDeleteId] = useState<string | undefined>()

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({
          id: 'members.mebers_table.email',
          defaultMessage: 'E-Mail',
        }),
        accessor: 'email', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'members.mebers_table.firstName',
          defaultMessage: 'First name',
        }),
        accessor: 'firstName', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'members.mebers_table.lastName',
          defaultMessage: 'Last name',
        }),
        accessor: 'lastName', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'members.mebers_table.firmName',
          defaultMessage: 'Firm name',
        }),
        accessor: 'firmName', // accessor is the "key" in the data
      },
    ],
    []
  )

  function statusToTagKind(status: string) {
    const state = dataState?.getStatesNoPerson.items.find(
      (item) => item.id === status
    )
    return state?.highlightColor || 'neutral'
  }


  const {
    getTableProps,
    headerGroups,
    prepareRow,
    //@ts-ignore
    page,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    setPageSize,
    //@ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      //@ts-ignore
      columns,
      data,
      initialState: {
        //@ts-ignore
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      pageSize: pageSizeInput,
    },
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'functions',
          Header: () => (
            <div>
              <FormattedMessage
                id='members.membersTable.functions'
                defaultMessage={'Functions'}
              />
            </div>
          ),
          //@ts-ignore
          Cell: ({ row }) => (
            <ButtonGroup>
              <Button onClick={() => goToPerson(row.original.id)}>
                <Show />
              </Button>
              <Button onClick={() => setDeleteId(row.original.id)}>
                <Delete />
              </Button>
              {
                //@ts-ignore
                row.original.selected ? (
                  <Button
                    onClick={() =>
                      setSelectedPerson((prev) => {
                        prev.splice(prev.indexOf(row.original.id), 1)
                        return [...prev]
                      })
                    }
                  >
                    <CheckIndeterminate />
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      setSelectedPerson((prev) => [...prev, row.original.id])
                    }
                  >
                    <Plus />
                  </Button>
                )}
            </ButtonGroup>
          ),
        },
        {
          id: 'state',
          Header: () => (
            <div>
              <FormattedMessage
                id='members.membersTable.state'
                defaultMessage={'State'}
              />
            </div>
          ),
          //@ts-ignore
          Cell: ({ row }) => (
            <Tag
              closeable={false}
              variant='outlined'
              //@ts-ignore
              kind={statusToTagKind(row.original.state.id)}
            >
              {
                //@ts-ignore
                getTranslation(row.original.state.name, intl)}
            </Tag>
          ),
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    if (pageSize !== pageSizeInput) {
      setPageSize(pageSizeInput)
      onRefetchData()
    } else {
      if (pageIndex !== controlledPageCount) {
        onFetchMoreData()
      }
    }
  }, [pageIndex, pageSizeInput])

  return (
    <>
      <SimpleModal<string>
        isOpen={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'members.membersTable.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'members.membersTable.deleteConfirm',
          defaultMessage: 'Are you sure to delete this member?',
        })}
        data={deleteId || ''}
        onConfirm={(deleteId: string) => {
          removeRow(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'members.membersTable.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>
      <StyledTable
        role='grid'
        $gridTemplateColumns={`repeat(${columns.length + 2},1fr)`}
        {...getTableProps()}
      >
        {headerGroups.map((headerGroup, i) => (
          <React.Fragment key={i}>
            {
              //@ts-ignore
              headerGroup.headers.map((column, j) => (
                <StyledHeadCell {...column.getHeaderProps()}
                  key={i.toString() + j.toString()}>
                  {column.render('Header')}
                  <span>
                    {
                      //@ts-ignore
                      column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </StyledHeadCell>
              ))}
          </React.Fragment>
        ))}
        {loading && (
          <>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={i}>
                {
                  //@ts-ignore
                  headerGroup.headers.map((column, j) => (
                    <StyledBodyCell key={i.toString() + j.toString()}>
                      <Loader />
                    </StyledBodyCell>
                  ))}
              </React.Fragment>
            ))}
          </>
        )}
        {
          //@ts-ignore
          page.map((row, i) => {
            prepareRow(row)
            return (
              <React.Fragment key={i}>
                {
                  //@ts-ignore
                  row.cells.map((cell, j) => {
                    return (
                      <StyledBodyCell
                        key={i.toString() + j.toString()}
                        {...cell.getCellProps()}
                        $striped={true}
                      >
                        {cell.render('Cell')}
                      </StyledBodyCell>
                    )
                  })}
              </React.Fragment>
            )
          })}
        {loading ? (
          // Use our custom loading state to show a loading indicator
          <>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={i}>
                {
                  //@ts-ignore
                  headerGroup.headers.map((column, j) => (
                    <StyledBodyCell
                      key={i.toString() + j.toString()}
                    >
                      <Loader />
                    </StyledBodyCell>
                  ))}
              </React.Fragment>
            ))}
          </>
        ) : (
          //@ts-ignore
          <StyledBodyCell colSpan='10000'>
            <FormattedMessage
              id='members.membersTable.results_count'
              defaultMessage='Showing {pageLength} results'
              values={{
                pageLength: page.length,
              }}
            />
          </StyledBodyCell>
        )}
      </StyledTable>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div
        className={css({
          paddingTop: '1rem',
        })}
      >
        <Grid align={ALIGNMENT.center}>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                <FormattedMessage
                  id='members.membersTable.load_mores'
                  defaultMessage={'Load mores'}
                />
              </Button>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Tag closeable={false} variant={VARIANT.light} kind='neutral'>
                <FormattedMessage
                  id='members.membersTable.page_count'
                  defaultMessage='Page {currentPage}'
                  values={{
                    currentPage: pageIndex + 1,
                  }}
                />
              </Tag>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Select
                clearable={false}
                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                  id: intl.formatMessage(
                    {
                      id: 'members.membersTable.page_size_selection',
                      defaultMessage: 'Show {pageSize} Items per Page',
                    },
                    {
                      pageSize,
                    }
                  ),
                  pageSize,
                }))}
                labelKey='id'
                valueKey='pageSize'
                onChange={({ value }) => {
                  setPageSizeInput(Number(value[0].pageSize))
                }}
                value={[{ id: pageSize.toString(), pageSize }]}
              />
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  )
}
