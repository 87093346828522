import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import PaymentInfosBusiness from './PaymentInfos.business'

import getReactApolloCients from '../../../../localReactApollo'
import { useGetSvssBillingInformationQuery } from '../../../../gql/billing_information/BillingInformation.generated'
import { useGetInvoiceIfToAssociationQuery } from '../../../../gql/invoices/Invoices.generated'

export default function PaymentInfosData({ 
  invoiceId
 }: { 
  invoiceId: string
 }) {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  const {
    data: invoice,
    loading: loadingInvoice,
    error: errorInvoice,
  } = useGetInvoiceIfToAssociationQuery({
    variables: {
      id: invoiceId,
    },
    //@ts-ignore
    client,
  })

  const {
    data: billingInformation,
    loading: loadingBillingInformation,
    error: errorBillingInformation,
  } = useGetSvssBillingInformationQuery({
    variables: {},
    //@ts-ignore
    client,
  })

  useEffect(() => {
    setLoading(loadingInvoice || loadingBillingInformation)
  }, [loadingInvoice, loadingBillingInformation])

  useEffect(() => {
    if (errorInvoice || errorBillingInformation) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.paymentInfos.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.paymentInfos.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorInvoice?.message || errorBillingInformation?.message)
    }
  }, [errorInvoice, errorBillingInformation])

  return (
    <PaymentInfosBusiness
      loading={loading}
      invoice={invoice?.getInvoiceIfToAssociation}
      billingInformation={billingInformation?.getBillingInformation}
    />
  )
}
