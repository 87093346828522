
import { Block } from 'baseui/block'

import Loader from '@lemonbrain/components/UiElements/Loader/Loader'

export default function TimerecordingEdit ({
    displayName,
    isLoading,
}: {
    displayName?: string,
    isLoading: boolean,
}) {

    return isLoading ? (
        <Loader />
    ) : (
            <Block as="p"
                overrides={{
                    Block: {
                        style: ({ $theme }) => {
                            return {
                                color: $theme.colors.primaryA,
                            }
                        },
                    },
                }}>
                {displayName}
            </Block>
        )
}
