import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'

import getReactApolloCients from '../../../../localReactApollo'
import { useCreateAssociationInvoiceMutation } from '../../../../gql/invoices/Invoices.generated'
import CreateAssociationInvoicesBusiness from './CreateAssociationInvoices.business'

export default function CreateAssociationInvoicesData({
  invoicesTableDataRef,
}: {
  invoicesTableDataRef: React.MutableRefObject<
    | {
        refetchInvoices: () => void
      }
    | undefined
  >
}) {
  const [associationProcessId, setAssociationProcessId] = useLocalStorage<
    string | null
  >('associationProcessId')
  const intl = useIntl()
  const setAlert = useAlert()
  const [progress, setProgress] = useState<number | null>(null)

  const client = getReactApolloCients().getClient()

  const [
    createAssociationInvoiceMutation,
    { data, loading, error },
  ] = useCreateAssociationInvoiceMutation({
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (associationProcessId && associationProcessId !== '') {
      createAssociationInvoiceMutation({
        variables: {
          processId: associationProcessId,
        },
      })
    } 
  }, [])

  useEffect(() => {
    if (error) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.createAssociationInvoices.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.createAssociationInvoices.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(error?.message)
    }
  }, [error])

  useEffect(() => {
    switch (data && data.createAssociationInvoice.returnCode) {
      case 200:
        if (
          data?.createAssociationInvoice.progress &&
          data?.createAssociationInvoice.progress === 100
        ) {
          setProgress(null)
          setAssociationProcessId(null)

          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.createAssociationInvoices.success',
                defaultMessage: 'Success',
              }),
              label: intl.formatMessage({
                id: 'invoices.createAssociationInvoices.invoicesCreated',
                defaultMessage: 'Invoices created',
              }),
              style: 'success',
            })
          invoicesTableDataRef?.current?.refetchInvoices()
        } else {
          setAssociationProcessId(
            data?.createAssociationInvoice.processId || ''
          )
          setProgress(data?.createAssociationInvoice.progress || 0)
          setTimeout(() => {
            createAssociationInvoiceMutation({
              variables: {
                processId: data?.createAssociationInvoice.processId,
              },
            })
          }, 1000)
        }
        break
      case 404:
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'invoices.createAssociationInvoices.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage(
              {
                id: 'invoices.createAssociationInvoices.networkErrorMessage',
                defaultMessage:
                  'We had network problem: {message}. Please try again',
              },
              {
                message: data?.createAssociationInvoice.errorMessage,
              }
            ),
            style: 'error',
          })
        console.error(data?.createAssociationInvoice.errorMessage)
        break
    }
  }, [data])

  return (
    <CreateAssociationInvoicesBusiness
      loading={loading}
      createAssociationInvoices={createAssociationInvoiceMutation}
      progress={progress}
    />
  )
}
