import { useEffect, useState } from 'react'
import { Cell, Grid } from 'baseui/layout-grid'
import { useIntl } from 'react-intl'
import { FormControl } from 'baseui/form-control'
import {Input} from 'baseui/input';

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

export default function MembershipMailingForm({
  values,
  setValues,
  createEntry,
  isLoading,
  validate,
}: {
  values: { email: string | undefined }
  setValues: React.Dispatch<React.SetStateAction<{ email: string | undefined }>>
  createEntry: ({email}: { email: string }) => void
  isLoading: boolean
  validate: (
    email: string | null | undefined,
    setEmailError: React.Dispatch<React.SetStateAction<string | null>>,
  ) => boolean
}) {
  const intl = useIntl()
  const [emailError, setEmailError] = useState<string | null>(null)

  function onCreateClick() {
    if (
      values &&
      validate(
        values.email,
        setEmailError
      ) &&
      values.email
    ) {
      createEntry({
        email: values.email,
      })
    }
  }

  useEffect(() => {
    if (values) {
      validate(values.email, setEmailError)
    }
  }, [values])

  return (
    <EditContainer
      state='create'
      noUpdate={true}
      noCopy={true}

      onCreateClick={onCreateClick}
      isLoading={isLoading}

      onUpdateClick={undefined}
      onCancelClick={undefined}
    >
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 4]}>
          <h4>Neuer Email Dingsi</h4>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.membershipMailingForm.email',
              defaultMessage: 'Email',
            })}
            error={emailError}
          >
            <Input
              value={values.email||''}
              //@ts-ignore
              onChange={(email) => setValues((prev) => ({ ...prev, email: email.target.value }))}
              positive={emailError === ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </EditContainer>
  )
}
