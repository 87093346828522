// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AssociationTypeFragmentFragment = (
  { __typename?: 'AssociationType' }
  & Pick<Types.AssociationType, 'id' | 'isDefaultType' | 'userGroup' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetAssociationTypeNoPersonQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAssociationTypeNoPersonQuery = (
  { __typename?: 'Query' }
  & { getAssociationTypeNoPerson: (
    { __typename?: 'AssociationType' }
    & AssociationTypeFragmentFragment
  ) }
);

export type AddAssociationTypeMutationVariables = Types.Exact<{
  isDefaultType: Types.Scalars['Boolean'];
  userGroup: Types.Scalars['String'];
  name: Types.TranslationObjectCreate;
}>;


export type AddAssociationTypeMutation = (
  { __typename?: 'Mutation' }
  & { addAssociationType: (
    { __typename?: 'AssociationType' }
    & AssociationTypeFragmentFragment
  ) }
);

export type UpdateAssociationTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  isDefaultType?: Types.Maybe<Types.Scalars['Boolean']>;
  userGroup?: Types.Maybe<Types.Scalars['String']>;
  name: Types.TranslationObjectUpdate;
}>;


export type UpdateAssociationTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateAssociationType: (
    { __typename?: 'AssociationType' }
    & AssociationTypeFragmentFragment
  ) }
);

export type DeleteAssociationTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteAssociationTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteAssociationType: (
    { __typename?: 'AssociationType' }
    & AssociationTypeFragmentFragment
  ) }
);

export type GetAssociationTypesNoPersonQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetAssociationTypesNoPersonQuery = (
  { __typename?: 'Query' }
  & { getAssociationTypesNoPerson: (
    { __typename?: 'AssociationTypePagination' }
    & Pick<Types.AssociationTypePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'AssociationType' }
      & AssociationTypeFragmentFragment
    )> }
  ) }
);

export type GetNextAssociationTypesNoPersonQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextAssociationTypesNoPersonQuery = (
  { __typename?: 'Query' }
  & { getNextAssociationTypesNoPerson: (
    { __typename?: 'AssociationTypePagination' }
    & Pick<Types.AssociationTypePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'AssociationType' }
      & AssociationTypeFragmentFragment
    )> }
  ) }
);

export const AssociationTypeFragmentFragmentDoc = gql`
    fragment AssociationTypeFragment on AssociationType {
  id
  isDefaultType
  userGroup
  name {
    de
    fr
    it
  }
  createdFrom
}
    `;
export const GetAssociationTypeNoPersonDocument = gql`
    query getAssociationTypeNoPerson($id: ID!) {
  getAssociationTypeNoPerson(id: $id) {
    ...AssociationTypeFragment
  }
}
    ${AssociationTypeFragmentFragmentDoc}`;

/**
 * __useGetAssociationTypeNoPersonQuery__
 *
 * To run a query within a React component, call `useGetAssociationTypeNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociationTypeNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociationTypeNoPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssociationTypeNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetAssociationTypeNoPersonQuery, GetAssociationTypeNoPersonQueryVariables>) {
        return Apollo.useQuery<GetAssociationTypeNoPersonQuery, GetAssociationTypeNoPersonQueryVariables>(GetAssociationTypeNoPersonDocument, baseOptions);
      }
export function useGetAssociationTypeNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssociationTypeNoPersonQuery, GetAssociationTypeNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetAssociationTypeNoPersonQuery, GetAssociationTypeNoPersonQueryVariables>(GetAssociationTypeNoPersonDocument, baseOptions);
        }
export type GetAssociationTypeNoPersonQueryHookResult = ReturnType<typeof useGetAssociationTypeNoPersonQuery>;
export type GetAssociationTypeNoPersonLazyQueryHookResult = ReturnType<typeof useGetAssociationTypeNoPersonLazyQuery>;
export type GetAssociationTypeNoPersonQueryResult = Apollo.QueryResult<GetAssociationTypeNoPersonQuery, GetAssociationTypeNoPersonQueryVariables>;
export const AddAssociationTypeDocument = gql`
    mutation addAssociationType($isDefaultType: Boolean!, $userGroup: String!, $name: TranslationObjectCreate!) {
  addAssociationType(
    isDefaultType: $isDefaultType
    userGroup: $userGroup
    name: $name
  ) {
    ...AssociationTypeFragment
  }
}
    ${AssociationTypeFragmentFragmentDoc}`;
export type AddAssociationTypeMutationFn = Apollo.MutationFunction<AddAssociationTypeMutation, AddAssociationTypeMutationVariables>;

/**
 * __useAddAssociationTypeMutation__
 *
 * To run a mutation, you first call `useAddAssociationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssociationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssociationTypeMutation, { data, loading, error }] = useAddAssociationTypeMutation({
 *   variables: {
 *      isDefaultType: // value for 'isDefaultType'
 *      userGroup: // value for 'userGroup'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddAssociationTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddAssociationTypeMutation, AddAssociationTypeMutationVariables>) {
        return Apollo.useMutation<AddAssociationTypeMutation, AddAssociationTypeMutationVariables>(AddAssociationTypeDocument, baseOptions);
      }
export type AddAssociationTypeMutationHookResult = ReturnType<typeof useAddAssociationTypeMutation>;
export type AddAssociationTypeMutationResult = Apollo.MutationResult<AddAssociationTypeMutation>;
export type AddAssociationTypeMutationOptions = Apollo.BaseMutationOptions<AddAssociationTypeMutation, AddAssociationTypeMutationVariables>;
export const UpdateAssociationTypeDocument = gql`
    mutation updateAssociationType($id: ID!, $isDefaultType: Boolean, $userGroup: String, $name: TranslationObjectUpdate!) {
  updateAssociationType(
    id: $id
    isDefaultType: $isDefaultType
    userGroup: $userGroup
    name: $name
  ) {
    ...AssociationTypeFragment
  }
}
    ${AssociationTypeFragmentFragmentDoc}`;
export type UpdateAssociationTypeMutationFn = Apollo.MutationFunction<UpdateAssociationTypeMutation, UpdateAssociationTypeMutationVariables>;

/**
 * __useUpdateAssociationTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAssociationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssociationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssociationTypeMutation, { data, loading, error }] = useUpdateAssociationTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDefaultType: // value for 'isDefaultType'
 *      userGroup: // value for 'userGroup'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateAssociationTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssociationTypeMutation, UpdateAssociationTypeMutationVariables>) {
        return Apollo.useMutation<UpdateAssociationTypeMutation, UpdateAssociationTypeMutationVariables>(UpdateAssociationTypeDocument, baseOptions);
      }
export type UpdateAssociationTypeMutationHookResult = ReturnType<typeof useUpdateAssociationTypeMutation>;
export type UpdateAssociationTypeMutationResult = Apollo.MutationResult<UpdateAssociationTypeMutation>;
export type UpdateAssociationTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAssociationTypeMutation, UpdateAssociationTypeMutationVariables>;
export const DeleteAssociationTypeDocument = gql`
    mutation deleteAssociationType($id: ID!) {
  deleteAssociationType(id: $id) {
    ...AssociationTypeFragment
  }
}
    ${AssociationTypeFragmentFragmentDoc}`;
export type DeleteAssociationTypeMutationFn = Apollo.MutationFunction<DeleteAssociationTypeMutation, DeleteAssociationTypeMutationVariables>;

/**
 * __useDeleteAssociationTypeMutation__
 *
 * To run a mutation, you first call `useDeleteAssociationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssociationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssociationTypeMutation, { data, loading, error }] = useDeleteAssociationTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssociationTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssociationTypeMutation, DeleteAssociationTypeMutationVariables>) {
        return Apollo.useMutation<DeleteAssociationTypeMutation, DeleteAssociationTypeMutationVariables>(DeleteAssociationTypeDocument, baseOptions);
      }
export type DeleteAssociationTypeMutationHookResult = ReturnType<typeof useDeleteAssociationTypeMutation>;
export type DeleteAssociationTypeMutationResult = Apollo.MutationResult<DeleteAssociationTypeMutation>;
export type DeleteAssociationTypeMutationOptions = Apollo.BaseMutationOptions<DeleteAssociationTypeMutation, DeleteAssociationTypeMutationVariables>;
export const GetAssociationTypesNoPersonDocument = gql`
    query getAssociationTypesNoPerson($cursor: StartCursorInput!) {
  getAssociationTypesNoPerson(cursor: $cursor) {
    items {
      ...AssociationTypeFragment
    }
    nextCursor
  }
}
    ${AssociationTypeFragmentFragmentDoc}`;

/**
 * __useGetAssociationTypesNoPersonQuery__
 *
 * To run a query within a React component, call `useGetAssociationTypesNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociationTypesNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociationTypesNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAssociationTypesNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetAssociationTypesNoPersonQuery, GetAssociationTypesNoPersonQueryVariables>) {
        return Apollo.useQuery<GetAssociationTypesNoPersonQuery, GetAssociationTypesNoPersonQueryVariables>(GetAssociationTypesNoPersonDocument, baseOptions);
      }
export function useGetAssociationTypesNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssociationTypesNoPersonQuery, GetAssociationTypesNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetAssociationTypesNoPersonQuery, GetAssociationTypesNoPersonQueryVariables>(GetAssociationTypesNoPersonDocument, baseOptions);
        }
export type GetAssociationTypesNoPersonQueryHookResult = ReturnType<typeof useGetAssociationTypesNoPersonQuery>;
export type GetAssociationTypesNoPersonLazyQueryHookResult = ReturnType<typeof useGetAssociationTypesNoPersonLazyQuery>;
export type GetAssociationTypesNoPersonQueryResult = Apollo.QueryResult<GetAssociationTypesNoPersonQuery, GetAssociationTypesNoPersonQueryVariables>;
export const GetNextAssociationTypesNoPersonDocument = gql`
    query getNextAssociationTypesNoPerson($cursor: NextCursorInput!) {
  getNextAssociationTypesNoPerson(cursor: $cursor) {
    items {
      ...AssociationTypeFragment
    }
    nextCursor
  }
}
    ${AssociationTypeFragmentFragmentDoc}`;

/**
 * __useGetNextAssociationTypesNoPersonQuery__
 *
 * To run a query within a React component, call `useGetNextAssociationTypesNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextAssociationTypesNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextAssociationTypesNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextAssociationTypesNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetNextAssociationTypesNoPersonQuery, GetNextAssociationTypesNoPersonQueryVariables>) {
        return Apollo.useQuery<GetNextAssociationTypesNoPersonQuery, GetNextAssociationTypesNoPersonQueryVariables>(GetNextAssociationTypesNoPersonDocument, baseOptions);
      }
export function useGetNextAssociationTypesNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextAssociationTypesNoPersonQuery, GetNextAssociationTypesNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetNextAssociationTypesNoPersonQuery, GetNextAssociationTypesNoPersonQueryVariables>(GetNextAssociationTypesNoPersonDocument, baseOptions);
        }
export type GetNextAssociationTypesNoPersonQueryHookResult = ReturnType<typeof useGetNextAssociationTypesNoPersonQuery>;
export type GetNextAssociationTypesNoPersonLazyQueryHookResult = ReturnType<typeof useGetNextAssociationTypesNoPersonLazyQuery>;
export type GetNextAssociationTypesNoPersonQueryResult = Apollo.QueryResult<GetNextAssociationTypesNoPersonQuery, GetNextAssociationTypesNoPersonQueryVariables>;