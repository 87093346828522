const LocaleData = {
  'invoices.invoices': 'Rechnungen',

  'invoices.overview': 'Übersicht',
  'invoices.add': 'Hinzufügen',
  'invoices.my': 'Meine Rechnungen',

  'invoices.invoicesOverview': 'Übersicht',
  'invoices.invoicesToMe': 'Rechnungen an mich',

  'invoices.invoicesTable.fetchingMore':
    'Es werden mehr Rapporteinträge geladen...',
  'invoices.invoicesTable.noEntries':
    'Es sind keine Einträge vorhanden',
    'invoices.invoicesTable.invoiceNumber': 'Rechnungsnummer',
    'invoices.invoicesTable.from': 'Von',
    'invoices.invoicesTable.to': 'An',
    'invoices.invoicesTable.date': 'Datum',
    'invoices.invoicesTable.total': 'Total',
    'invoices.invoicesTable.sendAt': 'Gesendet am',
    'invoices.invoicesTable.payDate': 'Bezahlt am',
    'invoices.invoicesTable.payAmount': 'Bezahlt',
    'invoices.invoicesTable.canceledAt': 'Storniert am',
  'invoices.invoicesTable.actions': 'Funktionen',
  'invoices.invoicesTable.editOrDeleteAnEntry':
    'Bearbeite oder storniere einen Eintrag',
  'invoices.invoicesTable.Invoices': 'Rechnungen',
  'invoices.invoicesTable.delete': 'Stornieren',
  'invoices.invoicesTable.deleteConfirm':
    'Sicher, dass diese Rechnung storniert werden soll?',
  'invoices.invoicesTable.error': 'Fehler',
  'invoices.invoicesTable.errorMessage':
    'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
  'invoices.invoicesTable.removeRow.error': 'Fehler',
  'invoices.invoicesTable.removeRow.error.notRemoved':
    'Eintrag wurde nicht storniert, versuche es noch einmal',
  'invoices.invoicesTable.removeRow.success': 'Eintrag wurde storeniert',
  'invoices.invoicesTable.loading': 'Laden...',
  'invoices.invoicesTable.results_count':
    'Zeigt {pageLength} Resultate',
  'invoices.invoicesTable.load_mores': 'Mehr laden',
  'invoices.invoicesTable.page_count': 'Seite {currentPage}',
  'invoices.invoicesTable.page_size_selection':
    'Zeige {pageSize} Elemente pro Seite',
  'invoices.invoicesTable.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',

    'invoices.createDirectMemberInvoices.createDirectMember': 'Direktmitgliederrechnung erstellen',
    'invoices.createDirectMemberInvoices.error': 'Fehler',
    'invoices.createDirectMemberInvoices.networkError': 'Beim Erstellen der Direktmitgliederrechnung trat ein Fehler auf, versuche es noch einmal',
    'invoices.createDirectMemberInvoices.networkErrorMessage': 'Beim Erstellen der Direktmitgliederrechnung trat ein Fehler auf, versuche es noch einmal: {message}',
    'invoices.createDirectMemberInvoices.success': 'Erfolgreich',
    'invoices.createDirectMemberInvoices.invoicesCreated': 'Die Rechnungen wurden erstellt',

    'invoices.createAssociationInvoices.createAssociation': 'Verbandsrechnungen erstellen',
    'invoices.createAssociationInvoices.error': 'Fehler',
    'invoices.createAssociationInvoices.networkError': 'BBeim Erstellen der Verbandsrechnungen trat ein Fehler auf, versuche es noch einmal',
    'invoices.createAssociationInvoices.networkErrorMessage': 'Beim Erstellen der Verbandsrechnungen trat ein Fehler auf, versuche es noch einmal: {message}',
    'invoices.createAssociationInvoices.success': 'Erfolgreich',
    'invoices.createAssociationInvoices.invoicesCreated': 'Die Rechnungen wurden erstellt',

    'invoices.createMemberInvoices.createMember': 'Mitgliederrechnung erstellen',
    'invoices.CreateMemberInvoices.error': 'Fehler',
    'invoices.CreateMemberInvoices.networkError': 'Beim Erstellen der Mitgliederrechnung trat ein Fehler auf, versuche es noch einmal',
    'invoices.CreateMemberInvoices.networkErrorMessage': 'Beim Erstellen der Mitgliederrechnung trat ein Fehler auf, versuche es noch einmal: {message}',
    'invoices.CreateMemberInvoices.success': 'Erfolgreich',
    'invoices.CreateMemberInvoices.invoicesCreated': 'Die Rechnungen wurden erstellt',

    'invoices.toggleAll.toggleAll': 'Alle umschalten',
    'invoices.toggleAll.error': 'Fehler',
    'invoices.toggleAll.networkError': 'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',

    'invoices.export.exportInvoices': 'Rechnungen exportieren',
    'invoices.export.error': 'Fehler',
    'invoices.export.networkError':
      'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',



  'invoices.mail.createMailing': 'Mailing erstellen',
  'invoices.mail.send': 'Sende an {recipients} Empfänger',
  'invoices.mail.sending': 'Versand an {recipients} Empfänger läuft (Gesendet: {sent}, haben Mailing bereits erhalten: {double}, Fehlgeschlagen: {failed})',
  'invoices.mail.fromMail': 'Von',
  'invoices.mail.fromNameMail': 'Von Name',
  'invoices.mail.toMail': 'An',
  'invoices.mail.subject': 'Betreff',
  'invoices.mail.content': 'Inhalt',
  'invoices.mail.attachments': 'Anhang',
  'invoices.mail.fromNameEmailIsRequired': 'Von E-Mail Name ist verlangt und muss im korrekten Format sein',
  'invoices.mail.toEmailIsRequired': 'An E-Mailadresse ist verlangt',
  'invoices.mail.toEmailFormatIsRequired': 'An E-Mailadresse muss im korrekten Format sein',
  'invoices.mail.attachmentsToCount': 'Es muss gleich viel Anhänge wie Empfänger geben und die Reihenfolge muss übereinstimmen',
  'invoices.mail.attachmentsIsRequired': 'Ein Anhang ist verlangt',
  'invoices.mail.subjectIsRequired': 'Betreff ist verlangt',
  'invoices.mail.contentIsRequired': 'Inhalt ist verlangt',
  'invoices.mail.error': 'Fehler',
  'invoices.mail.networkError': 'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'invoices.mail.state.pending': 'Bereit zum Versand',
  'invoices.mail.state.running': 'Versand läuft',
  'invoices.mail.state.sleeping': 'Versandspause, startet automatisch wieder',
  'invoices.mail.state.interrupted': 'Versand unterbrochen, versuche es noch einmal zu starten (Bereits versendete, werden nicht erneut versendet)',
  'invoices.mail.state.completed': 'Versand erfolgreich abgeschlossen',

  'invoices.invoiceEdit.toOverview': 'Zur Übersicht',
  'invoices.invoiceEdit.invoiceAmount': 'Rechnungsbetrag',
  'invoices.invoiceEdit.total': 'Total',
  'invoices.invoiceEdit.payment': 'Zahlung',
  'invoices.invoiceEdit.error': 'Fehler',
  'invoices.invoiceEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
  'invoices.invoiceEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
  'invoices.invoiceEdit.error.thereWasAnServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
  'invoices.invoiceEdit.saved': 'Gespeichert',
  'invoices.invoiceEdit.saved.updated': 'Der Eintrag wurde aktualisiert',
  'invoices.invoiceEdit.error.totalRequired': 'Total ist verlangt und muss eine Dezimalzahl sein',
  'invoices.invoiceEdit.error.prizeRequired': 'Betrag bezahlt ist verlangt und muss eine Dezimalzahl sein',
  'invoices.invoiceEdit.prize': 'Preis',
  'invoices.invoiceEdit.prizePayed': 'Betrag bezahlt',
  'invoices.invoiceEdit.error.payedAtRequired': 'Bezahlt am muss ein Datum sein und ist erforderlich wenn ein Betrag eingegeben wird',
  'invoices.invoiceEdit.payedAt': 'Bezahlt am',
  'invoices.invoiceEdit.alreadySent': 'Diese Rechnung wurde schon versendet und das Total kann nicht mehr bearbeitet werden',

  'invoices.sepaImport.sepaImport': 'SEPA importieren',
  'invoices.sepaImport.rejectFormats': 'Folgende Dateien werden nicht unterstützt: {files}',
  'invoices.sepaImport.error': 'Fehler',
  'invoices.sepaImport.networkError': 'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'invoices.sepaImport.dropSepaFilesHere': 'Füge die SEPA Datei hier hinzu für den Upload',
  'invoices.sepaImport.success': 'Erfolgreich',
  'invoices.sepaImport.sepaImported': 'SEPA wurde erfolgreich importiert',
  'invoices.sepaImport.fileAlreadyExist': 'Es wurde bereits eine SEPA Datei mit dem Namen {filename} importiert',
  'invoices.sepaImport.deleteSepa': 'Lösche die SEPA Datei {filename} und all ihre Abhängigkeiten',
  'invoices.sepaImport.sure': 'Sicher?',
  'invoices.sepaImport.yes': 'Ja',
  'invoices.sepaImport.no': 'Nein',
  'invoices.sepaImport.sepaRemoved': 'SEPA wurde erfolgreich entfernt',

  'invoices.paymentInfos.paymentInfos': 'Einzahlungsinfo',
  'invoices.paymentInfos.error': 'Fehler',
  'invoices.paymentInfos.networkError': 'Es trat ein Fehler auf, versuche es noch einmal',
  'invoices.paymentInfos.account': 'Konto Nr.: {account}',
  'invoices.paymentInfos.iban': 'IBAN: {iban}',
  'invoices.paymentInfos.total': 'Total Betrag: CHF {total}',

  'invoices.onlinePayer.payOnline': 'Online bezahlen',
  'invoices.onlinePayer.paySuccessed': 'Erfolgreich bezahlt',
  'invoices.onlinePayer.payFailed': 'Bezahlung schlug fehl',

  'invoices.invoicesMy': 'Meine Rechnungen',

  'invoices.myInvoiceEdit.toOverview': 'Zur Übersicht',

  'invoicesAdd.invoices_add': 'Hinzufügen',

  'invoices.invoicesEdit.error': 'Fehler',
  'invoices.invoicesEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
  'invoices.invoicesEdit.created': 'Erstellt',
  'invoices.invoicesEdit.saved.created': 'Eintrag ist erstellt',
  'invoices.invoicesEdit.error.thereWasAServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
  'invoices.invoicesEdit.error.notLoggedIn': 'Du bist nicht mehr angemeldet',
  'invoices.invoicesEdit.error.personIdRequired': 'Person ist verlangt',
  'invoices.invoicesEdit.error.titleRequired': 'Titel ist verlangt',
  'invoices.invoicesEdit.error.itemTextRequired': 'Text ist verlangt',
  'invoices.invoicesEdit.error.costRequired': 'Kosten sind verlangt',
  'invoices.invoicesEdit.toOverview': 'Zur Übersicht',
  'invoices.invoicesEdit.person': 'Person',
  'invoices.invoicesEdit.title': 'Titel',
  'invoices.invoicesEdit.itemText': 'Text',
  'invoices.invoicesEdit.cost': 'Kosten',
  'invoices.invoiceEdit.onlinePayment': 'Online Bezahlung',
  'invoices.invoiceEdit.state': 'Status',
  'invoices.invoiceEdit.updatedAt': 'Aktualisiert am',

  'invoices.invoices_filter.payed': 'Bezahlt',

  'invoices.payed_filter.payed': 'Bezahlt',
  'invoices.payed_filter.payedComment': 'Nur bezahlte Rechnungen anzeigen',
}

export default LocaleData
