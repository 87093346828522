import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { priceFormater} from '@lemonbrain/utils'

import MembershipEditBusiness from './MembershipEdit.business'
import {
    useGetMembershipLazyQuery,
  useUpdateMembershipMutation,
  useAddMembershipMutation,
} from '../../../gql/Membership.generated'
import getReactApolloCients from '../../../localReactApollo'

import { useEffect, useState } from 'react'

export default function MembershipEditData({
  membershipId,
  setMembershipId,
}: {
  membershipId: string | null | undefined
  setMembershipId: (membershipId: string) => void
}) {
  const setAlert = useAlert()
  const [values, setValues] = useState<{
    de: string
    fr: string
    it: string
    prize: string
    directInvoicing: boolean
  }>({
    de: '',
    fr: '',
    it: '',
    prize: '',
    directInvoicing: false,
  })
  const client = getReactApolloCients().getClient()
  const intl = useIntl()
  const [
    getMembershipQuery,
    { data, loading, error: queryError },
  ] = useGetMembershipLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateMembershipMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateMembershipMutation({
    //@ts-ignore
    client,
  })
  const [
    addMembershipMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddMembershipMutation({
    //@ts-ignore
    client,
  })

  async function updateMembershipEntry(data: {
    membershipId: string
    de: string
    fr: string
    it: string
    prize: number
    directInvoicing: boolean
  }) {
    if (data.membershipId) {
      await updateMembershipMutation({
        variables: {
          id: data.membershipId,
          name: {
              de: data.de,
              fr: data.fr,
              it: data.it
          },
          prize: data.prize,
          directInvoicing: data.directInvoicing,
        },
      })
    }
  }

  async function createMembershipEntry(data: {
    de: string
    fr: string
    it: string
    prize: number
    directInvoicing: boolean
  }) {
    await addMembershipMutation({
      variables: {
        name: {
            de: data.de,
            fr: data.fr,
            it: data.it,
        },
        prize: data.prize,
        directInvoicing: data.directInvoicing,
      },
    })
  }

  function removeMembershipId() {
    setMembershipId('')
  }

  useEffect(() => {
    if (membershipId) {
        getMembershipQuery({
        variables: {
          id: membershipId,
        },
      })
    }
  }, [membershipId])

  useEffect(() => {
    if (data?.getMembership) {

      setValues({
        de: data.getMembership.name.de,
        fr: data.getMembership.name.fr,
        it: data.getMembership.name.it,
        prize: priceFormater(data.getMembership.prize / 100),
        directInvoicing: !!data.getMembership.directInvoicing,
      })
    }
  }, [data])

  useEffect(() => {
    if (queryError || queryChangeError || queryAddError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.membershipEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.membershipEdit.errorMessage',
            defaultMessage: 'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError, queryChangeError, queryAddError])

  return (
    <MembershipEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      removeMembershipId={removeMembershipId}
      membershipId={membershipId}
      updateMembershipEntry={updateMembershipEntry}
      createMembershipEntry={createMembershipEntry}
    />
  )
}
