import React, { createContext, useContext } from 'react'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import {
  TYPES,
  useMenuNavItems,
  Route,
  UserType,
  useUser,
  useGermanMessages,
  useComponents,
  useWsClients,
  getUserWithoutRender,
} from '@lemonbrain/hooks'

export const AppContext = createContext<{
  setUser?: React.Dispatch<React.SetStateAction<UserType>>
  user: UserType | undefined
  mainTyedRoutesItems: { [type in TYPES]: Route[] }
  setToken?: React.Dispatch<React.SetStateAction<string | undefined>>
  clearToken?: () => void
  isTokenExpired?: () => boolean
  addGermanMessages?: React.Dispatch<React.SetStateAction<any>>
  components?: { [name: string]: () => JSX.Element }
  addComponents?: (newComponents: { [name: string]: () => JSX.Element }) => void
  wsClients?: SubscriptionClient[]
  addWsClient?: (wsClient: SubscriptionClient) => void
}>({
  user: undefined,
  mainTyedRoutesItems: {
    ['TOP']: [],
    ['AVATAR']: [],
    ['MAIN']: [],
  },
})

export const PageWrapper = ({ children }: { children: JSX.Element }) => {
  const { wsClients, addWsClient } = useWsClients()
  const { user, setToken, clearToken, isTokenExpired } = useUser(wsClients)
  const { accessibleTypedRoutesItems: mainTyedRoutesItems } = useMenuNavItems(
    user
  )
  const { addGermanMessages } = useGermanMessages()
  const { components, addComponents } = useComponents()

  return Object.keys(mainTyedRoutesItems).length > 0 &&
    ((getUserWithoutRender() &&
      user &&
      Object.keys(mainTyedRoutesItems).length > 1) ||
      !getUserWithoutRender()) ? (
    <AppContext.Provider
      value={{
        setToken,
        clearToken,
        isTokenExpired,
        user,
        mainTyedRoutesItems: mainTyedRoutesItems,
        addGermanMessages,
        components,
        addComponents,
        wsClients,
        addWsClient,
      }}
    >
      {children}
    </AppContext.Provider>
  ) : (
    <></>
  )
}

export const useAppState = () => useContext(AppContext)
