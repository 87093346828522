import CommentBusiness from './Comment.business'

export default function CommentData({
  comment,
  setComment,
}: {
  comment?: string
  setComment: React.Dispatch<React.SetStateAction<string>>
}) {
  return <CommentBusiness comment={comment} setComment={setComment} />
}
