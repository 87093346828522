import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'
import { useParams } from 'react-router'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'
import { useHistoryPush } from '@lemonbrain/hooks'

import NewslettersEditBusiness from './NewslettersEdit.business'
import getReactApolloCients from '../../../localReactApollo'
import {
  UpdateNewsletterMailingMutationVariables,
  UpdateNewsletterMailingItemMutationVariables,
  AddNewsletterMailingMutationVariables,
  useGetNewsletterMailingLazyQuery,
  useUpdateNewsletterMailingMutation,
  useAddNewsletterMailingMutation,
} from '../../../gql/NewsletterMailing.generated'
import {
  useGetNewslettersNoPersonQuery,
  useGetNewsletterMailaddressesLazyQuery,
} from '../../../gql/Newsletter.generated'
import * as Types from '../../../types'

export type NewsletterItem = {
  label: string
  id: string
}

export default function NewslettersEditData({
  goToNewsletter,
  newsletterId,
}: {
  goToNewsletter: (id: string | null) => void
  newsletterId?: string
}) {
  const intl = useIntl()
  const [newsletterMailing, setNewsletterMailing] = useState<
    UpdateNewsletterMailingMutationVariables & {
      toJoined: string
      mainImageSrc: string
      items: (UpdateNewsletterMailingItemMutationVariables & {
        imageSrc: string
        task: Types.ArrayUpdate
      })[]
    }
  >({
    id: '',
    newsletterId: '',
    items: [],
    from: '',
    fromName: '',
    to: [''],
    toJoined: '',
    subject: '',
    mailingDate: '',
    mainImage: null,
    mainImageSrc: '',
    mainImageCopyright: '',
    title: '',
    content: '',
    leftColoumn: '',
    rightColoumn: '',
    attachment: null,
    showOnWebsite: false,
  })
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const [newsletters, setNewsletters] = useState<NewsletterItem[]>()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  const [
    getNewsletterMailing,
    {
      data: dataNewsletterMailing,
      loading: loadingNewsletterMailing,
      error: errorNewsletterMailing,
      refetch: refetchNewsletterMailing,
    },
  ] = useGetNewsletterMailingLazyQuery({
    variables: {
      id: newsletterId || '',
    },
    //@ts-ignore
    client,
  })

  const [
    updateNewsletterMailingMutation,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useUpdateNewsletterMailingMutation({
    //@ts-ignore
    client,
  })
  const [
    addNewsletterMailingMutation,
    { data: dataAdd, loading: loadingAdd, error: errorAdd },
  ] = useAddNewsletterMailingMutation({
    //@ts-ignore
    client,
  })

  async function updateNewsletterMailingEntry(
    data: UpdateNewsletterMailingMutationVariables
  ) {
    if (data.id && newsletterId) {
      await updateNewsletterMailingMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    } else {
      let variables: AddNewsletterMailingMutationVariables & {
        id?: string
      } = data as AddNewsletterMailingMutationVariables
      delete variables.id
      await addNewsletterMailingMutation({
        variables,
        //@ts-ignore
        client,
      })
    }
  }

  const {
    data: dataNewsletters,
    loading: loadingNewsletters,
    error: errorNewsletters,
  } = useGetNewslettersNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })

  const [
    getNewsletterMailaddresses,
    {
      data: dataGetNewsletterMailaddresses,
      loading: loadingGetNewsletterMailaddresses,
      error: errorGetNewsletterMailaddresses,
    },
  ] = useGetNewsletterMailaddressesLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (dataNewsletterMailing?.getNewsletterMailing) {
      setNewsletterMailing({
        id: dataNewsletterMailing.getNewsletterMailing.id,
        newsletterId: dataNewsletterMailing.getNewsletterMailing.newsletter.id,
        items: dataNewsletterMailing.getNewsletterMailing.items
          .sort((itemA, itemB) => (itemA?.orderNr || 0) - (itemB?.orderNr || 0))
          .map((item) => ({
            id: item?.id || '',
            imageId: item?.imageId || '',
            imageSrc: item?.imageSrc || '',
            imageCopyright: item?.imageCopyright || '',
            title: item?.title || '',
            content: item?.content || '',
            link: item?.link || '',
            orderNr: item?.orderNr || 0,

            createdAt: item?.createdAt,
            createdFrom: item?.createdFrom,
            updatedAt: item?.updatedAt,
            updatedFrom: item?.updatedFrom,
            task: Types.ArrayUpdate.Update,
          })),
        from: dataNewsletterMailing.getNewsletterMailing.from,
        fromName: dataNewsletterMailing.getNewsletterMailing.fromName,
        to: dataNewsletterMailing.getNewsletterMailing.to,
        toJoined: dataNewsletterMailing.getNewsletterMailing.to.join(', '),
        subject: dataNewsletterMailing.getNewsletterMailing.subject,
        mailingDate: dataNewsletterMailing.getNewsletterMailing.mailingDate,
        mainImageSrc:
          dataNewsletterMailing.getNewsletterMailing.mainImageSrc || '',
        mainImageId:
          dataNewsletterMailing.getNewsletterMailing.mainImageId || '',
        mainImageCopyright:
          dataNewsletterMailing.getNewsletterMailing.mainImageCopyright || '',
        title: dataNewsletterMailing.getNewsletterMailing.title || '',
        content: dataNewsletterMailing.getNewsletterMailing.content || '',
        leftColoumn:
          dataNewsletterMailing.getNewsletterMailing.leftColoumn || '',
        rightColoumn:
          dataNewsletterMailing.getNewsletterMailing.rightColoumn || '',
        attachment: dataNewsletterMailing.getNewsletterMailing.attachment,
        state: dataNewsletterMailing.getNewsletterMailing.state,
        showOnWebsite: dataNewsletterMailing.getNewsletterMailing.showOnWebsite,
      })
    }
  }, [dataNewsletterMailing])

  useEffect(() => {
    if (dataNewsletters?.getNewslettersNoPerson) {
      setNewsletters(
        dataNewsletters.getNewslettersNoPerson.items.map((item) => ({
          label: getTranslation(item.name, intl),
          id: item.id,
        }))
      )
    }
  }, [dataNewsletters])

  useEffect(() => {
    if (newsletterId) {
      getNewsletterMailing()
    }
  }, [newsletterId])

  useEffect(() => {
    if (!loadingUpdate) {
      refetchNewsletterMailing && refetchNewsletterMailing()
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (
      errorNewsletterMailing ||
      errorUpdate ||
      errorAdd ||
      errorNewsletters ||
      errorGetNewsletterMailaddresses
    ) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'newsletters.newslettersEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'newsletters.newslettersEdit.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorNewsletterMailing?.message ||
          errorUpdate?.message ||
          errorAdd?.message ||
          errorGetNewsletterMailaddresses?.message ||
          errorNewsletters?.message
      )
    }
  }, [
    errorNewsletterMailing,
    errorUpdate,
    errorAdd,
    errorGetNewsletterMailaddresses,
    errorNewsletters,
  ])

  useEffect(() => {
    if (dataAdd?.addNewsletterMailing.id) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'newsletters.newslettersEdit.created',
            defaultMessage: 'Created',
          }),
          label: intl.formatMessage({
            id: 'newsletters.newslettersEdit.saved.created',
            defaultMessage: 'Entry is created',
          }),
          style: 'success',
        })
      pushPath(
        `/${language}/newsletters/overview/${dataAdd?.addNewsletterMailing.id}`
      )
    }
  }, [dataAdd])

  useEffect(() => {
    if (dataUpdate?.updateNewsletterMailing.id) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'newsletters.newslettersEdit.saved',
            defaultMessage: 'Saved',
          }),
          label: intl.formatMessage({
            id: 'newsletters.newslettersEdit.saved.updated',
            defaultMessage: 'Entry is updated',
          }),
          style: 'success',
        })
    }
  }, [dataUpdate])

  return loadingNewsletters ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <NewslettersEditBusiness
      goToNewsletter={goToNewsletter}
      newsletterId={newsletterId}
      loading={
        loadingNewsletterMailing ||
        loadingUpdate ||
        loadingAdd ||
        loadingGetNewsletterMailaddresses
      }
      updateNewsletterMailingEntry={updateNewsletterMailingEntry}
      newsletterMailing={newsletterMailing}
      setNewsletterMailing={setNewsletterMailing}
      getNewsletterMailaddresses={getNewsletterMailaddresses}
      mailaddresses={
        dataGetNewsletterMailaddresses?.getNewsletterMailaddresses || []
      }
      newsletters={newsletters}
    />
  )
}
