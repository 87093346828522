import { useState } from 'react'

import { UserType } from '.'

export type TYPES = 'MAIN' | 'AVATAR' | 'TOP'

export interface NavItemType {
  id: string
  title: {
    id: string
    defaultMessage: string
  }
  note?: string
  icon: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  size?: 'default' | 'small'
  tabIndex?: number
  hasFocus?: boolean
  disabled?: boolean
  visible?: boolean
  active?: boolean
  enabled: boolean
  titleVisible?: boolean
}

export interface InnerRoute extends NavItemType {
  path: string
  routePath: string
  component: React.ComponentClass<any, any> | ((props: any) => JSX.Element)
  accessibleBy: string[]
  visibleBy?: string[]
  excludeFromNav?: boolean
}

export interface Route extends InnerRoute {
  children?: InnerRoute[]
}

let defaultMenuRoutes: {
  [type in TYPES]: Route[]
} = {
  ['TOP']: [],
  ['AVATAR']: [],
  ['MAIN']: [],
}
export const addMenuRoutesBeforeRender = function (
  type: TYPES,
  newRoutes: Route[]
) {
  defaultMenuRoutes = {
    ...defaultMenuRoutes,
    [type]: [...defaultMenuRoutes[type], ...newRoutes].sort(
      (itemA, itemB) =>
        (itemA.tabIndex ? itemA.tabIndex + 1 : 999) -
        (itemB.tabIndex ? itemB.tabIndex + 1 : 0)
    ),
  }
}

export let defaultOpenMenuRoutes: {
  [type in TYPES]: Route[]
} = {
  ['TOP']: [],
  ['AVATAR']: [],
  ['MAIN']: [],
}
export const addOpenMenuRoutesBeforeRender = function (
  type: TYPES,
  newRoutes: Route[]
) {
  defaultOpenMenuRoutes = {
    ...defaultOpenMenuRoutes,
    [type]: [...defaultOpenMenuRoutes[type], ...newRoutes].sort(
      (itemA, itemB) =>
        (itemA.tabIndex ? itemA.tabIndex + 1 : 999) -
        (itemB.tabIndex ? itemB.tabIndex + 1 : 0)
    ),
  }
}

export const useMenuNavItems = (user?: UserType) => {
  const [typedRoutes, setTypedRoutes] = useState<
    {
      [type in TYPES]: Route[]
    }
  >(defaultMenuRoutes)

  const addRoutes = function (type: TYPES, newRoutes: Route[]) {
    setTypedRoutes((prev) => ({
      ...prev,
      [type]: [...prev[type], ...newRoutes].sort(
        (itemA, itemB) =>
          (itemA.tabIndex ? itemA.tabIndex + 1 : 999) -
          (itemB.tabIndex ? itemB.tabIndex + 1 : 0)
      ),
    }))
  }

  function refreshAccessRoutes (user: UserType | undefined, typedRoutes: { MAIN: Route[]; AVATAR: Route[]; TOP: Route[] }) {
    const userGroups = user && user.uGroupsPath
  
    let accessibleTypedRoutes: {
      [type in TYPES]: Route[]
    } = {
      ['TOP']: [],
      ['AVATAR']: [],
      ['MAIN']: [],
    }
    
    if (user && Number(user.uID) === 1) {
      accessibleTypedRoutes = typedRoutes
    } else {
      (Object.keys(typedRoutes) as TYPES[]).forEach((type: TYPES) => {
        typedRoutes[type].forEach((route) => {
          if ((route.enabled &&
            route.accessibleBy.some(
              (group) => (userGroups && userGroups.includes(`/${String(group)}`)) ||
                group === process.env.REACT_APP_GROUP_GUESTS
            )) ||
            (userGroups &&
              userGroups.includes(
                process.env.REACT_APP_GROUP_ADMINS || '/Administrators'
              ))) {
            accessibleTypedRoutes[type].push(route)
          }
          return true
        })
      });
  
      (Object.keys(accessibleTypedRoutes) as TYPES[]).forEach((type) => {
        accessibleTypedRoutes[type].forEach((route) => {
          if (route.children) {
            let accessibleChildrenRoutes: InnerRoute[] = []
            route.children.forEach((childrenRoute) => {
              if ((childrenRoute.enabled &&
                childrenRoute.accessibleBy.some(
                  (group) => (userGroups &&
                    userGroups.includes(`/${String(group)}`)) ||
                    group === process.env.REACT_APP_GROUP_GUESTS
                )) ||
                (userGroups &&
                  userGroups.includes(
                    process.env.REACT_APP_GROUP_ADMINS || '/Administrators'
                  ))) {
                accessibleChildrenRoutes.push(childrenRoute)
              }
              return true
            })
  
            route.children = accessibleChildrenRoutes.sort(
              (itemA, itemB) => (itemA.tabIndex ? itemA.tabIndex + 1 : 999) -
                (itemB.tabIndex ? itemB.tabIndex + 1 : 0)
            )
          }
        })
      })
    }
    
    return accessibleTypedRoutes
  }

  return {
    addRoutes,
    accessibleTypedRoutesItems: refreshAccessRoutes(user, typedRoutes),
  }
}

