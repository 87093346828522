import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import ToggleAllBusiness from './ToggleAll.business'
import getReactApolloCients from '../../../../localReactApollo'

import { useGetInvoicesIdLazyQuery } from '../../../../gql/invoices/Invoices.generated'

export default function ToggleAllData({
  searchQuery,
  selectedInvoices,
  setSelectedInvoices,
}: {
  searchQuery: string
  selectedInvoices: string[]
  setSelectedInvoices: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const [
    getInvoicesId,
    { data: dataGetInvoice, loading: loadingGetInvoice, error: errorGetInvoice },
  ] = useGetInvoicesIdLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  function getFilter() {
    const filter: { id: string; value: string }[] = []

    filter.push({
      id: 'query',
      value: searchQuery,
    })

    return filter
  }

  const toggleAll = function () {
    if (selectedInvoices.length > 0) {
      setSelectedInvoices([])
    } else {
      getInvoicesId({
        variables: {
          cursor: {
            pageSize: 9999,
            filtered: getFilter(),
          },
        },
      })
    }
  }

  useEffect(() => {
    if (
      !loadingGetInvoice &&
      dataGetInvoice &&
      dataGetInvoice?.getInvoices.items.length > 0
    ) {
      setSelectedInvoices(dataGetInvoice?.getInvoices.items.map((item) => item.id))
    }
  }, [dataGetInvoice])

  useEffect(() => {
    if (errorGetInvoice) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoides.toggleAll.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.toggleAll.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorGetInvoice?.message)
    }
  }, [errorGetInvoice])

  return (
    <ToggleAllBusiness
      loading={loadingGetInvoice}
      selectedInvoices={selectedInvoices}
      toggleAll={toggleAll}
    />
  )
}
