import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import WorkLevelBusiness from './WorkLevel.business'
import getReactApolloCients from '../../../../localReactApollo'
import {useGetWorkLevelsNoPersonQuery} from '../../../../gql/members/WorkLevel.generated'

export default function WorkLevelData({
    workLevel,
    setWorkLevel,
    getHasWorkLevelErrors,
  }: {
    workLevel?: { id: string }[]
    setWorkLevel: React.Dispatch<
      React.SetStateAction<{ id: string }[]>
    >
    getHasWorkLevelErrors: (errors: { workLevel: string }) => void
  }) {
    const intl = useIntl()
    const setAlert = useAlert()
    const [workLevels, setWorkLevels] = useState<
      {
        id: string
        label: string
      }[]
    >([])
    const client = getReactApolloCients().getClient()
      const { data: dataWorkLevels, loading: loadingWorkLevels, error: errorWorkLevels } = useGetWorkLevelsNoPersonQuery({
        variables: {
          cursor: {
            pageSize: 100,
          },
        },
        //@ts-ignore
        client,
     });

     useEffect(() => {
       if (errorWorkLevels) {
         setAlert &&
           setAlert({
             title: intl.formatMessage({
               id: 'members.workLevel.error',
               defaultMessage: 'Error',
             }),
             label: intl.formatMessage({
               id: 'members.workLevel.networkError',
               defaultMessage: 'We had network problem. Please try again',
             }),
             style: 'error',
           })
         console.error(errorWorkLevels?.message)
       }
     }, [errorWorkLevels])
   
     useEffect(() => {
       if (dataWorkLevels?.getWorkLevelsNoPerson.items) {
        setWorkLevels(
            dataWorkLevels?.getWorkLevelsNoPerson.items.map((item) => ({
             id: item.id,
             label: getTranslation(item.name, intl),
           })).sort(function(a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            
            return 0;
          })
         )
       }
     }, [dataWorkLevels])
    
      return <WorkLevelBusiness workLevel={workLevel} setWorkLevel={setWorkLevel} loadingWorkLevels={loadingWorkLevels} workLevels={workLevels} getHasWorkLevelErrors={getHasWorkLevelErrors} />
  }