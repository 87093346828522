import { useIntl } from 'react-intl'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Spinner, SIZE } from 'baseui/spinner'

export default function SvssFunction({
  svssFunctions,
  setSvssFunctions,
  loadingSvssFunctions,
  svssFunctionsInput,
}: {
  svssFunctions?: { id: string }[]
  setSvssFunctions: React.Dispatch<React.SetStateAction<{ id: string }[]>>
  loadingSvssFunctions: boolean
  svssFunctionsInput: {
    id: string
    label: string
  }[]
}) {
  const intl = useIntl()

  return (
    <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'members.svssFunction.svssFunctions',
            defaultMessage: 'SVSS functions',
          })}
        >
          <>
            {loadingSvssFunctions ? (
              <Spinner $size={SIZE.small} />
            ) : (
              svssFunctionsInput.map((item) => (
                <Checkbox
                  key={item.id}
                  value={item.id}
                  checked={
                    svssFunctions &&
                    !!svssFunctions.find((item1) => item1.id === item.id)
                  }
                  onChange={(e) => {
                    setSvssFunctions((prev) => {
                      let hasSvssFunctionsNew = [...prev]
                      const value = (e.target as HTMLInputElement).value
                      const index = hasSvssFunctionsNew.findIndex(
                        (item1) => item1.id === value
                      )
                      if (index >= 0) {
                        hasSvssFunctionsNew.splice(index, 1)
                      } else {
                        hasSvssFunctionsNew.push({ id: value })
                      }
                      return hasSvssFunctionsNew
                    })
                  }}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  {item.label}
                </Checkbox>
              ))
            )}
          </>
        </FormControl>
      </Cell>
    </Grid>
  )
}
