import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import WorkLevelBusiness from './WorkLevel.business'

export default function WorkLevelData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { workLevelId } = useParams<{ workLevelId: string }>()
  const { language } = useParams<{ language: string }>()

  function setWorkLevelId(workLevelId: string | undefined) {
    if (workLevelId && workLevelId !== '') {
      pushPath(`/${language}/settings/work-level/${workLevelId}`)
    } else {
      pushPath(`/${language}/settings/work-level`)
    }
  }

  return (
    <WorkLevelBusiness
      subNav={subNav}
      workLevelId={workLevelId}
      setWorkLevelId={setWorkLevelId}
    />
  )
}
