import { Checkbox } from 'baseui/checkbox'
import { Cell, Grid } from 'baseui/layout-grid'
import { useIntl } from 'react-intl'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

export default function MembershipEdit({
  state,
  isLoading,
  values,
  setValues,
  deError,
  frError,
  itError,
  prizeError,
  onUpdateClick,
  onCopyClick,
  onCancelClick,
  onCreateClick,
}: {
  state: 'update' | 'create'
  isLoading: boolean
  values: {
    de: string
    fr: string
    it: string
    prize: string
    directInvoicing: boolean
  }
  setValues: React.Dispatch<
    React.SetStateAction<{
      de: string
      fr: string
      it: string
      prize: string
      directInvoicing: boolean
    }>
  >
  deError: string
  frError: string
  itError: string
  prizeError: string
  onUpdateClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCopyClick?:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCancelClick:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
  onCreateClick?:
    | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
    | undefined
}) {
  const intl = useIntl()

  return (
    <EditContainer
      state={state}
      onUpdateClick={onUpdateClick}
      onCopyClick={onCopyClick}
      onCancelClick={onCancelClick}
      onCreateClick={onCreateClick}
      isLoading={isLoading}
    >
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.membershipEdit.de',
              defaultMessage: 'Label (German)',
            })}
            error={deError}
          >
            <Input
              value={(values && values.de) || ''}
              onChange={(de) =>
                setValues((prev) => ({
                  ...prev,
                  de: (de.target as HTMLInputElement).value,
                }))
              }
              positive={deError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.membershipEdit.fr',
              defaultMessage: 'Label (French)',
            })}
            error={frError}
          >
            <Input
              value={(values && values.fr) || ''}
              onChange={(fr) =>
                setValues((prev) => ({
                  ...prev,
                  fr: (fr.target as HTMLInputElement).value,
                }))
              }
              positive={frError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.membershipEdit.it',
              defaultMessage: 'Label (Italian)',
            })}
            error={itError}
          >
            <Input
              value={(values && values.it) || ''}
              onChange={(it) =>
                setValues((prev) => ({
                  ...prev,
                  it: (it.target as HTMLInputElement).value,
                }))
              }
              positive={itError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.membershipEdit.prize',
              defaultMessage: 'Prize',
            })}
            error={prizeError}
          >
            <Input
              value={(values && values.prize) || ''}
              onChange={(prize) =>
                setValues((prev) => ({
                  ...prev,
                  prize: (prize.target as HTMLInputElement).value,
                }))
              }
              positive={prizeError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.membershipEdit.directInvoicing',
              defaultMessage: 'Direct invoicing',
            })}
          >
            <Checkbox
              checked={(values && values.directInvoicing) || false}
              onChange={() =>
                setValues((prev) => ({
                  ...prev,
                  directInvoicing: !prev.directInvoicing,
                }))
              }
            ></Checkbox>
          </FormControl>
        </Cell>
      </Grid>
    </EditContainer>
  )
}
