import React from 'react'
import { Link } from 'react-router-dom'

import Wrapper from './Logo.styled'

type LogoProps = {
  path: string | any
  src: React.ReactNode
  style?: React.CSSProperties
}

const Logo = ({ path, src, style }: LogoProps) => {
  return (
    <Wrapper>
      <Link className="logo" to={path} style={style}>
        {src}
      </Link>
      </Wrapper>
  )
}

export default Logo
