import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import AddressTypeEdit from './AddressTypeEdit'

import { useAppState } from '@lemonbrain/contexts'
import { prepStringWN } from '@lemonbrain/utils'
import { useAlert } from '@lemonbrain/hooks'

export default function AddressTypeEditBusiness({
  values,
  queryError,
  isLoading,
  removeAddressTypeId,
  addressTypeId,
  updateAddressTypeEntry,
  createAddressTypeEntry,
  setValues,
}: {
  values: {
    isInitType: boolean | undefined
    isFirm: boolean | undefined
    de: string | undefined
    it: string | undefined
    fr: string | undefined
  }
  queryError: any
  isLoading: boolean
  removeAddressTypeId: () => void
  addressTypeId?: string | null | undefined
  updateAddressTypeEntry: (data: {
    addressTypeId: string
    isInitType: boolean
    isFirm: boolean
    de: string
    fr: string
    it: string
  }) => void
  createAddressTypeEntry: (data: {
    isInitType: boolean
    isFirm: boolean
    de: string
    fr: string
    it: string
  }) => void
  setValues: React.Dispatch<
    React.SetStateAction<{
      isInitType: boolean | undefined
      isFirm: boolean | undefined
      de: string | undefined
      fr: string | undefined
      it: string | undefined
    }>
  >
}) {
  const intl = useIntl()
  const { user } = useAppState()
  const setAlert = useAlert()

  const updateEntry = async ({
    addressTypeId,
    isInitType,
    isFirm,
    de,
    fr,
    it,
  }: {
    addressTypeId: string
    isInitType: boolean
    isFirm: boolean
    de: string
    fr: string
    it: string
  }) => {
    if (user && user.uID && addressTypeId) {
      try {
        await updateAddressTypeEntry({
          addressTypeId: prepStringWN(addressTypeId) || addressTypeId,
          isInitType,
          isFirm,
          de: prepStringWN(de) || de,
          fr: prepStringWN(fr) || fr,
          it: prepStringWN(it) || it,
        })
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.addressTypeEdit.saved',
              defaultMessage: 'Saved',
            }),
            label: intl.formatMessage({
              id: 'settings.addressTypeEdit.saved.updated',
              defaultMessage: 'Entry is updated',
            }),
            style: 'success',
          })
        updateCancel()
      } catch (e) {
        console.error(e)
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.addressTypeEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.addressTypeEdit.error.thereWasAServerError',
              defaultMessage: 'There was an server error, try again',
            }),
            style: 'error',
          })
      }
    } else {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.addressTypeEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.addressTypeEdit.error.notLoggedIn',
            defaultMessage: 'You are not anymore logged in',
          }),
          style: 'error',
        })
    }
  }

  const createEntry = async ({
    isInitType,
    isFirm,
    de,
    fr,
    it,
  }: {
    isInitType: boolean,
    isFirm: boolean,
    de: string
    fr: string
    it: string
  }) => {
    if (user && user.uID) {
      try {
        await createAddressTypeEntry({
          isInitType,
          isFirm,
          de: prepStringWN(de) || de,
          fr: prepStringWN(fr) || fr,
          it: prepStringWN(it) || it,
        })
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.addressTypeEdit.saved',
              defaultMessage: 'Saved',
            }),
            label: intl.formatMessage({
              id: 'settings.addressTypeEdit.saved.created',
              defaultMessage: 'Entry is created',
            }),
            style: 'success',
          })
        updateCancel()
      } catch (e) {
        console.error(e)
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.addressTypeEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.addressTypeEdit.error.thereWasAServerError',
              defaultMessage: 'There was an server error, try again',
            }),
            style: 'error',
          })
      }
    } else {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.addressTypeEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.addressTypeEdit.error.notLoggedIn',
            defaultMessage: 'You are not anymore logged in',
          }),
          style: 'error',
        })
    }
  }

  function updateCancel() {
    removeAddressTypeId()
    setValues((prev) => ({
      ...prev,
      de: '',
      fr: '',
      it: '',
    }))
  }

  function checkDe(
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
    if (!de || de === '') {
      setDeError(
        intl.formatMessage({
          id: 'settings.addressTypeEdit.error.deRequired',
          defaultMessage: 'Label (German) is required',
        })
      )
      return true
    }
    setDeError('')
    return false
  }

  function checkFr(
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
    if (!fr || fr === '') {
      setFrError(
        intl.formatMessage({
          id: 'settings.addressTypeEdit.error.frRequired',
          defaultMessage: 'Label (French) is required',
        })
      )
      return true
    }
    setFrError('')
    return false
  }

  function checkIt(
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
    if (!it || it === '') {
      setItError(
        intl.formatMessage({
          id: 'settings.addressTypeEdit.error.itRequired',
          defaultMessage: 'Label (Italian) is required',
        })
      )
      return true
    }
    setItError('')
    return false
  }

  function validate(
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>,
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>,
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>
  ) {
    let generalError = false

    generalError = generalError || checkDe(de, setDeError)
    generalError = generalError || checkFr(fr, setFrError)
    generalError = generalError || checkIt(it, setItError)

    return !generalError
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.addressTypeEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.addressTypeEdit.errorMessage',
            defaultMessage: 'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  return (
    <AddressTypeEdit
      checkDe={checkDe}
      checkFr={checkFr}
      checkIt={checkIt}
      validate={validate}
      values={values}
      isLoading={isLoading}
      updateCancel={updateCancel}
      addressTypeId={addressTypeId}
      updateEntry={updateEntry}
      createEntry={createEntry}
      setValues={setValues}
    />
  )
}
