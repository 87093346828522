import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { required } from '@lemonbrain/utils'

import NewslettersItem from './NewsletterItem'
import { UpdateNewsletterMailingItemMutationVariables, UpdateNewsletterMailingMutationVariables } from '../../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../../types'

export default function NewslettersItemBusiness({
  newsletterMailingItem,
  setNewsletterMailing,
}: {
  newsletterMailingItem:  (UpdateNewsletterMailingItemMutationVariables & {
    imageSrc: string
    task: Types.ArrayUpdate
  })
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: Types.ArrayUpdate
        })[]
      }
    >
  >
}) {
  const [errors, setErrors] = useState<{
    image: string
    imageCopyright: string
    title: string
    content: string
    link: string
  }>({
    image: '',
    imageCopyright: '',
    title: '',
    content: '',
    link: '',
  })
  const intl = useIntl()
  const [rejectedFiles, setRejectedFiles] = useState<File[]>()

  function checkTitle() {
    if (required(newsletterMailingItem.title)) {
      setErrors((prev) => ({
        ...prev,
        title: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      title: intl.formatMessage({
        id: 'newsletters.newsletterItem.error.titleRequired',
        defaultMessage: 'Title is required',
      }),
    }))
    return true
  }

  function checkContent() {
    if (required(newsletterMailingItem.content)) {
      setErrors((prev) => ({
        ...prev,
        content: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      content: intl.formatMessage({
        id: 'newsletters.newsletterItem.error.contentRequired',
        defaultMessage: 'Content is required',
      }),
    }))
    return true
  }

  function checkLink() {
    if (required(newsletterMailingItem.link)) {
      setErrors((prev) => ({
        ...prev,
        link: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      link: intl.formatMessage({
        id: 'newsletters.newsletterItem.error.link',
        defaultMessage: 'Right coloumn is required',
      }),
    }))
    return true
  }

  function checkImage() {
    if (
      required(newsletterMailingItem.image) ||
      required(newsletterMailingItem.imageSrc)
    ) {
      setErrors((prev) => ({
        ...prev,
        image: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      image: intl.formatMessage({
        id: 'newsletters.newsletterItem.error.imageRequired',
        defaultMessage: 'Image is required',
      }),
    }))
    return true
  }

  useEffect(() => {
    if (rejectedFiles && rejectedFiles?.length > 0) {
      setErrors((prev) => ({
        ...prev,
        image: intl.formatMessage(
          {
            id: 'newsletters.newsletterItem.rejectFormats',
            defaultMessage: 'Following files were rejected: {files}',
          },
          {
            files: rejectedFiles.map((item) => item.name).join(', '),
          }
        ),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        image: '',
      }))
    }
  }, [rejectedFiles])

  useEffect(() => {
    checkTitle()
    checkContent()
    checkLink()
    checkImage()
  }, [newsletterMailingItem])

  return (
    <NewslettersItem
      newsletterMailingItem={newsletterMailingItem}
      setNewsletterMailing={setNewsletterMailing}
      errors={errors}
      setRejectedFiles={setRejectedFiles}
    />
  )
}
