import fetch from 'unfetch'

import {
  clearTokenWithoutRender,
  isTokenExpiredWithoutRender,
  getTokenWithoutRender,
  setTokenWithoutRender,
} from '@lemonbrain/hooks'

const refreshTokenFetch = () =>
  fetch(process.env.REACT_APP_AUTH_URL + '/refresh_token', {
    method: 'POST',
    credentials: 'include',
    //@ts-ignore
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const getRefreshToken = async function () {
  const token = getTokenWithoutRender()
  const hadToken = token && token !== '' && typeof token === 'string'
  if (hadToken) {
    try {
      const response = await refreshTokenFetch()
      const answer = await response.json()

      if (!answer.authToken || answer.error || answer.error !== '') {
        clearTokenWithoutRender()
        if (window.location.href.indexOf('/de/login') < 0) {
          window.location.href = '/de/login'
        }
      } else {
        setTokenWithoutRender(answer.authToken)
      }
    } catch (error) {
      console.info('Your refresh token is invalid. Try to relogin', error)
      await clearTokenWithoutRender()
      if (window.location.href.indexOf('/de/login') < 0) {
        window.location.href = '/de/login'
      }
    }
  }
}

export const checkForExpiredToken = async () => {
  if (isTokenExpiredWithoutRender() && getTokenWithoutRender()) {
    await getRefreshToken()
  }
}

export const customFetch = async (uri: any, options: any) => {
  await checkForExpiredToken()

  const token = getTokenWithoutRender()
  const hasToken = token && token !== '' && typeof token === 'string'
  options.credentials = 'include'

  if (hasToken) {
    options.headers.Authorization = `Bearer ${token}`
  }

  const encodedUri = encodeURI(uri)
  var initialRequest = fetch(encodedUri, options)

  return initialRequest.then(async (response: any) => {
    if (response && response.status === 401) {
      await getRefreshToken()
      options.credentials = 'include'

      const token = getTokenWithoutRender()
      const hasToken = token && token !== '' && typeof token === 'string'
      if (hasToken) {
        options.headers.Authorization = `Bearer ${token}`
      }
      return fetch(encodedUri, options)
    }
    return response
  })
}
