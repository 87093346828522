import MembersFilterBusiness from './MembersFilter.business'

export default function MembersFilterData({
  searchQuery,
  setSearchQuery,
  associationFilter,
  setAssociationFilter,
  workLevelFilter,
  setWorkLevelFilter,
  sportsEducationFilter,
  setSportsEducationFilter,
  stateFilter,
  setStateFilter,
  stateTypeFilter,
  setStateTypeFilter,
  membershipFilter,
  setMembershipFilter,
}: {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  associationFilter: string[] | null
  setAssociationFilter: React.Dispatch<React.SetStateAction<string[] | null>>
  workLevelFilter: string[] | null
  setWorkLevelFilter: React.Dispatch<React.SetStateAction<string[] | null>>
  sportsEducationFilter: string[] | null
  setSportsEducationFilter: React.Dispatch<
    React.SetStateAction<string[] | null>
  >
  stateFilter: string | null
  setStateFilter: React.Dispatch<
    React.SetStateAction<string | null>
  >
  stateTypeFilter: string | null
  setStateTypeFilter: React.Dispatch<
    React.SetStateAction<string | null>
  >
  membershipFilter: string | null
  setMembershipFilter: React.Dispatch<React.SetStateAction<string | null>>
}) {
  return (
    <MembersFilterBusiness
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      associationFilter={associationFilter}
      setAssociationFilter={setAssociationFilter}
      workLevelFilter={workLevelFilter}
      setWorkLevelFilter={setWorkLevelFilter}
      sportsEducationFilter={sportsEducationFilter}
      setSportsEducationFilter={setSportsEducationFilter}
      stateFilter={stateFilter}
      setStateFilter={setStateFilter}
      stateTypeFilter={stateTypeFilter}
      setStateTypeFilter={setStateTypeFilter}
      membershipFilter={membershipFilter}
      setMembershipFilter={setMembershipFilter}
    />
  )
}
