import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'

import { useLocalStorage } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import getReactApolloCients from '../../../../localReactApollo'
import {
  useGetMailingLazyQuery,
  // useAddNewsletterMailingMutation,
  useSendMailingMutation,
} from '../../../../gql/members/Mailing.generated'
import {
  useGetNewslettersNoPersonQuery,
  useGetNewsletterMailaddressesLazyQuery
} from '../../../../gql/members/Newsletter.generated'
import NewsletterBusiness from './Newsletter.business'

import { MailingState } from '../../../../gql/members/types'

export default function NewsletterData({
}: {
}) {
  const [mailingId, setMailingId] = useLocalStorage<string | null>('mailingId')
  const [mailingState, setMailingState] = useState<{
    sentCount?: number
    failedCount?: number
    recipientCount?: number
    state?: MailingState
    from?: string
    fromName?: string
    to?: string[]
    subject?: string
  }>({})
  let refreshId: NodeJS.Timeout | null = null;
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newsletters, setNewsletters] = useState<{
    label: string,
    id: string
  }[]>()
  const intl = useIntl()
  const client = getReactApolloCients().getClient()
  const {
    data: dataNewsletters,
    loading: loadingNewsletters,
  } = useGetNewslettersNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })
  const [
    getNewsletterMailaddresses,
    { data: dataGetNewsletterMailaddresses },
  ] = useGetNewsletterMailaddressesLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [
    getMailingLazy,
    {
      data: dataGetMailing,
    },
  ] = useGetMailingLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })
  // const [
  //   addMailingMutation,
  //   {
  //     data: dataAddMailing,
  //     loading: loadingAddMailing,
  //     error: errorAddMailing,
  //   },
  // ] = useAddNewsletterMailingMutation({
  //   //@ts-ignore
  //   client,
  // })
  const [
    sendMailingMutation,
    {
      data: dataSendMailing,
    },
  ] = useSendMailingMutation({
    //@ts-ignore
    client,
  })

  function addMailing() {
    setMailingState({})
    // addMailingMutation({
    //   variables: {
    //     newsletterId,
    //     from,
    //     to,
    //     subject,
    //   },
    // })
  }

  function sendMailing(id: string) {
    sendMailingMutation({
      variables: {
        id,
      },
    })
  }

  // useEffect(() => {
  //   if (dataAddMailing?.addNewsletterMailing.id) {
  //     setMailingId(dataAddMailing.addNewsletterMailing.id)
  //   }
  // }, [dataAddMailing])

  useEffect(() => {
    if (mailingId && dataSendMailing?.sendMailing.id === mailingId) {
      getMailingLazy({
        variables: {
          id: mailingId,
        },
      })
    }
  }, [dataSendMailing])

  useEffect(() => {
    if (mailingId) {
      getMailingLazy({
        variables: {
          id: mailingId,
        },
      })
    }
  }, [mailingId])

  useEffect(() => {
    if (dataGetMailing?.getMailing) {
      if (mailingId && dataGetMailing?.getMailing.state === MailingState.Pending) {
        refreshId && clearTimeout(refreshId)
        sendMailingMutation({
          variables: {
            id: mailingId,
          },
        })
      } else if (
        dataGetMailing?.getMailing.state === MailingState.Completed
      ) {
        refreshId && clearTimeout(refreshId)
        setMailingId(null)
        setLoading(false)
      }

      if (dataGetMailing.getMailing) {
        setMailingState({
          sentCount: dataGetMailing.getMailing.sent.length,
          failedCount: dataGetMailing.getMailing.failed.length,
          recipientCount: dataGetMailing.getMailing.to.length,
          state: dataGetMailing.getMailing.state as MailingState,
          from: dataGetMailing.getMailing.from,
          fromName: dataGetMailing.getMailing.fromName,
          to: dataGetMailing.getMailing.to,
          subject: dataGetMailing.getMailing.subject,
        })
      }

      if (
        dataGetMailing.getMailing.id &&
        dataGetMailing?.getMailing.state !== MailingState.Completed &&
        dataGetMailing?.getMailing.state !== MailingState.Interrupted
      ) {
        setLoading(true)
        refreshId = setTimeout(() => {
          getMailingLazy({
            variables: {
              id: dataGetMailing.getMailing.id,
            },
          })
        }, 10000)
      } else if (
        dataGetMailing.getMailing.id &&
        (dataGetMailing?.getMailing.state === MailingState.Completed ||
        dataGetMailing?.getMailing.state === MailingState.Interrupted
      )) {
        setLoading(false)
      }
    }
  }, [dataGetMailing])

  useEffect(() => {
    if (dataNewsletters?.getNewslettersNoPerson) {
      setNewsletters(dataNewsletters.getNewslettersNoPerson.items.map(item => ({
        label: getTranslation(item.name, intl),
        id: item.id,
      })))
    }
  }, [dataNewsletters])

  // useEffect(() => {
  //     setLoading(loadingGetMailing || loadingAddMailing || loadingSendMailing || loadingGetNewsletterMailaddresses)
  // }, [loadingGetMailing, loadingAddMailing, loadingSendMailing, loadingGetNewsletterMailaddresses])

  // useEffect(() => {
  //   if (
  //     errorNewsletters ||
  //     errorGetMailing ||
  //     errorAddMailing ||
  //     errorSendMailing ||
  //     errorGetNewsletterMailaddresses
  //   ) {
  //     setAlert &&
  //       setAlert({
  //         title: intl.formatMessage({
  //           id: 'members.newsletter.error',
  //           defaultMessage: 'Error',
  //         }),
  //         label: intl.formatMessage({
  //           id: 'members.newsletter.networkError',
  //           defaultMessage: 'We had network problem. Please try again',
  //         }),
  //         style: 'error',
  //       })
  //     console.error(
  //       errorNewsletters?.message ||
  //         errorGetMailing?.message ||
  //         errorAddMailing?.message ||
  //         errorSendMailing?.message ||
  //         errorGetNewsletterMailaddresses?.message
  //     )
  //   }
  // }, [errorNewsletters, errorGetMailing, errorAddMailing, errorSendMailing, errorGetNewsletterMailaddresses])

  useEffect(() => {
    if (!isOpen && !mailingId) {
      setMailingState({})
    }
  }, [isOpen])

  return loadingNewsletters ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <NewsletterBusiness
      mailingId={mailingId}
      addMailing={addMailing}
      sendMailing={sendMailing}
      mailingState={mailingState}
      loading={loading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      mailaddresses={dataGetNewsletterMailaddresses?.getNewsletterMailaddresses || []}
      getNewsletterMailaddresses={getNewsletterMailaddresses}
      newsletters={newsletters}
    />
  )
}
