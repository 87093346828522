import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { priceFormater } from '@lemonbrain/utils'

import AssociationEditBusiness from './AssociationEdit.business'
import getReactApolloCients from '../../../localReactApollo'
import {
  UpdateAssociationIfMemberMutationVariables,
  useGetAssociationIfMemberQuery,
  useUpdateAssociationIfMemberMutation,
} from '../../../gql/Association.generated'

export default function AssociationEditIfManagesData({
  goToAssociation,
  associationId,
}: {
  goToAssociation: (id: string | null) => void
  associationId: string
}) {
  const intl = useIntl()
  const [association, setAssociation] = useState<
    UpdateAssociationIfMemberMutationVariables & { prizeString: string, imageSrc: string }
  >({
    id: '',
    name: {
      de: '',
      fr: '',
      it: '',
    },
    short: {
      de: '',
      fr: '',
      it: '',
    },
    image: null,
    imageSrc: '',
    prize: 0,
    prizeString: '0',
    associationURL: '',
    isStufenvereinigung: false,
    needsAComment: false,
    defaultComment: '',
    // membersCalculationAt: undefined,
    billingInformation: {
      firstName: '',
      lastName: '',
      firmName: '',
      mailbox: '',
      streetNr: '',
      postCode: 0,
      place: '',
      canton: '',
      country: '',
      account: '',
      iban: '',
      qrIban: '',
      besrId: 0,
    },
  })
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const {
    data: dataAssociation,
    loading: loadingAssociation,
    error: errorAssociation,
    refetch,
  } = useGetAssociationIfMemberQuery({
    variables: {
      id: associationId,
    },
    //@ts-ignore
    client,
  })
  const [
    updateAssociationMutation,
    { loading: loadingUpdate, error: loadingError },
  ] = useUpdateAssociationIfMemberMutation({
    //@ts-ignore
    client,
  })

  async function updateAssociationEntry(
    data: UpdateAssociationIfMemberMutationVariables
  ) {
    if (data.id) {
      await updateAssociationMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    }
  }

  useEffect(() => {
    if (dataAssociation?.getAssociationIfMember) {
      setAssociation({
        id: dataAssociation.getAssociationIfMember.id,
        name: {
          de: dataAssociation.getAssociationIfMember.name.de,
          fr: dataAssociation.getAssociationIfMember.name.fr,
          it: dataAssociation.getAssociationIfMember.name.it,
        },
        short: {
          de: dataAssociation.getAssociationIfMember.short.de,
          fr: dataAssociation.getAssociationIfMember.short.fr,
          it: dataAssociation.getAssociationIfMember.short.it,
        },
        associationURL: dataAssociation.getAssociationIfMember.associationURL||'',
        isStufenvereinigung: dataAssociation.getAssociationIfMember.isStufenvereinigung,
        imageId: dataAssociation.getAssociationIfMember.imageId,
        imageSrc: dataAssociation.getAssociationIfMember.imageSrc||'',
        prize: dataAssociation.getAssociationIfMember.prize,
        prizeString: priceFormater(
          dataAssociation.getAssociationIfMember.prize / 100
        ),
        needsAComment: dataAssociation.getAssociationIfMember.needsAComment,
        defaultComment: dataAssociation.getAssociationIfMember.defaultComment,
        // membersCalculationAt:
        //   dataAssociation.getAssociationIfMember.membersCalculationAt,
        billingInformation: {
          firstName:
            dataAssociation.getAssociationIfMember?.billingInformation?.firstName || '',
          lastName:
            dataAssociation.getAssociationIfMember?.billingInformation?.lastName || '',
          firmName:
            dataAssociation.getAssociationIfMember?.billingInformation?.firmName || '',
          mailbox:
            dataAssociation.getAssociationIfMember?.billingInformation?.mailbox || '',
          streetNr:
            dataAssociation.getAssociationIfMember?.billingInformation?.streetNr || '',
          postCode:
            dataAssociation.getAssociationIfMember?.billingInformation?.postCode || 0,
          place:
            dataAssociation.getAssociationIfMember?.billingInformation?.place || '',
          canton:
            dataAssociation.getAssociationIfMember?.billingInformation?.canton || '',
          country:
            dataAssociation.getAssociationIfMember?.billingInformation?.country || '',
          account:
            dataAssociation.getAssociationIfMember?.billingInformation?.account || '',
          iban: dataAssociation.getAssociationIfMember?.billingInformation?.iban || '',
        },
      })
    }
  }, [dataAssociation])

  useEffect(() => {
    if (!loadingUpdate) {
      refetch()
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (errorAssociation || loadingError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'associations.associationEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'associations.associationEdit.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorAssociation?.message || loadingError?.message)
    }
  }, [errorAssociation, loadingError])
  return (
    <AssociationEditBusiness
      goToAssociation={goToAssociation}
      loading={loadingAssociation || loadingUpdate}
      updateAssociationEntry={updateAssociationEntry}
      association={association}
      setAssociation={setAssociation}
      associationId={associationId}
    />
  )
}
