const LocaleData = {
    'login': 'Anmelden',
    'logout': 'Abmelden',
    'settings': 'Einstellungen',
    'homesite': 'Startseite',
    'darkMode': 'Mach es dunkel',
    'lightMode': 'Bring Licht hinein',
    'confirm': 'Ok',
    'cancel': 'Abbrechen',

    'components.editContainer.update': 'Aktualisieren',
    'components.editContainer.copy': 'Kopieren',
    'components.editContainer.cancel': 'Abbrechen',
    'components.editContainer.create': 'Erstellen',

    'cantons.ZH': 'Zürich',
    'cantons.BE': 'Bern',
    'cantons.LU': 'Luzern',
    'cantons.UR': 'Uri',
    'cantons.SZ': 'Schwyz',
    'cantons.OW': 'Obwalden',
    'cantons.NW': 'Nidwalden',
    'cantons.GL': 'Glarus',
    'cantons.ZG': 'Zug',
    'cantons.FR': 'Freiburg',
    'cantons.SO': 'Solothurn',
    'cantons.BS': 'Basel-Stadt',
    'cantons.BL': 'Basel-Landschaft',
    'cantons.SH': 'Schaffhausen',
    'cantons.AR': 'Appenzell Ausserrhoden',
    'cantons.AI': 'Appenzell Innerrhoden',
    'cantons.SG': 'St. Gallen',
    'cantons.GR': 'Graubünden',
    'cantons.AG': 'Aargau',
    'cantons.TG': 'Thurgau',
    'cantons.TI': 'Tessin',
    'cantons.VD': 'Waadt',
    'cantons.VS': 'Wallis',
    'cantons.NE': 'Neuenburg',
    'cantons.GE': 'Genf',
    'cantons.JU': 'Jura',

    'countries.AC': 'Ascension',
    'countries.AD': 'Andorra',
    'countries.AE': 'Vereinigte Arabische Emirate',
    'countries.AF': 'Afghanistan',
    'countries.AG': 'Antigua und Barbuda',
    'countries.AI': 'Anguilla',
    'countries.AL': 'Albanien',
    'countries.AM': 'Armenien',
    'countries.AO': 'Angola',
    'countries.AQ': 'Antarktis',
    'countries.AR': 'Argentinien',
    'countries.AS': 'Amerikanisch-Samoa',
    'countries.AT': 'Österreich',
    'countries.AU': 'Australien',
    'countries.AW': 'Aruba',
    'countries.AX': 'Ålandinseln',
    'countries.AZ': 'Aserbaidschan',
    'countries.BA': 'Bosnien und Herzegowina',
    'countries.BB': 'Barbados',
    'countries.BD': 'Bangladesch',
    'countries.BE': 'Belgien',
    'countries.BF': 'Burkina Faso',
    'countries.BG': 'Bulgarien',
    'countries.BH': 'Bahrain',
    'countries.BI': 'Burundi',
    'countries.BJ': 'Benin',
    'countries.BL': 'St. Barthélemy',
    'countries.BM': 'Bermuda',
    'countries.BN': 'Brunei Darussalam',
    'countries.BO': 'Bolivien',
    'countries.BQ': 'Karibische Niederlande',
    'countries.BR': 'Brasilien',
    'countries.BS': 'Bahamas',
    'countries.BT': 'Bhutan',
    'countries.BV': 'Bouvetinsel',
    'countries.BW': 'Botsuana',
    'countries.BY': 'Belarus',
    'countries.BZ': 'Belize',
    'countries.CA': 'Kanada',
    'countries.CC': 'Kokosinseln',
    'countries.CD': 'Kongo-Kinshasa',
    'countries.CF': 'Zentralafrikanische Republik',
    'countries.CG': 'Kongo-Brazzaville',
    'countries.CH': 'Schweiz',
    'countries.CI': 'Côte d’Ivoire',
    'countries.CK': 'Cookinseln',
    'countries.CL': 'Chile',
    'countries.CM': 'Kamerun',
    'countries.CN': 'China',
    'countries.CO': 'Kolumbien',
    'countries.CP': 'Clipperton-Insel',
    'countries.CR': 'Costa Rica',
    'countries.CU': 'Kuba',
    'countries.CV': 'Cabo Verde',
    'countries.CW': 'Curaçao',
    'countries.CX': 'Weihnachtsinsel',
    'countries.CY': 'Zypern',
    'countries.CZ': 'Tschechien',
    'countries.DE': 'Deutschland',
    'countries.DG': 'Diego Garcia',
    'countries.DJ': 'Dschibuti',
    'countries.DK': 'Dänemark',
    'countries.DM': 'Dominica',
    'countries.DO': 'Dominikanische Republik',
    'countries.DZ': 'Algerien',
    'countries.EA': 'Ceuta und Melilla',
    'countries.EC': 'Ecuador',
    'countries.EE': 'Estland',
    'countries.EG': 'Ägypten',
    'countries.EH': 'Westsahara',
    'countries.ER': 'Eritrea',
    'countries.ES': 'Spanien',
    'countries.ET': 'Äthiopien',
    'countries.EU': 'Europäische Union',
    'countries.EZ': 'Eurozone',
    'countries.FI': 'Finnland',
    'countries.FJ': 'Fidschi',
    'countries.FK': 'Falklandinseln',
    'countries.FM': 'Mikronesien',
    'countries.FO': 'Färöer',
    'countries.FR': 'Frankreich',
    'countries.GA': 'Gabun',
    'countries.GB': 'Vereinigtes Königreich',
    'countries.GD': 'Grenada',
    'countries.GE': 'Georgien',
    'countries.GF': 'Französisch-Guayana',
    'countries.GG': 'Guernsey',
    'countries.GH': 'Ghana',
    'countries.GI': 'Gibraltar',
    'countries.GL': 'Grönland',
    'countries.GM': 'Gambia',
    'countries.GN': 'Guinea',
    'countries.GP': 'Guadeloupe',
    'countries.GQ': 'Äquatorialguinea',
    'countries.GR': 'Griechenland',
    'countries.GS': 'Südgeorgien und die Südlichen Sandwichinseln',
    'countries.GT': 'Guatemala',
    'countries.GU': 'Guam',
    'countries.GW': 'Guinea-Bissau',
    'countries.GY': 'Guyana',
    'countries.HK': 'Sonderverwaltungsregion Hongkong',
    'countries.HM': 'Heard und McDonaldinseln',
    'countries.HN': 'Honduras',
    'countries.HR': 'Kroatien',
    'countries.HT': 'Haiti',
    'countries.HU': 'Ungarn',
    'countries.IC': 'Kanarische Inseln',
    'countries.ID': 'Indonesien',
    'countries.IE': 'Irland',
    'countries.IL': 'Israel',
    'countries.IM': 'Isle of Man',
    'countries.IN': 'Indien',
    'countries.IO': 'Britisches Territorium im Indischen Ozean',
    'countries.IQ': 'Irak',
    'countries.IR': 'Iran',
    'countries.IS': 'Island',
    'countries.IT': 'Italien',
    'countries.JE': 'Jersey',
    'countries.JM': 'Jamaika',
    'countries.JO': 'Jordanien',
    'countries.JP': 'Japan',
    'countries.KE': 'Kenia',
    'countries.KG': 'Kirgisistan',
    'countries.KH': 'Kambodscha',
    'countries.KI': 'Kiribati',
    'countries.KM': 'Komoren',
    'countries.KN': 'St. Kitts und Nevis',
    'countries.KP': 'Nordkorea',
    'countries.KR': 'Südkorea',
    'countries.KW': 'Kuwait',
    'countries.KY': 'Kaimaninseln',
    'countries.KZ': 'Kasachstan',
    'countries.LA': 'Laos',
    'countries.LB': 'Libanon',
    'countries.LC': 'St. Lucia',
    'countries.LI': 'Liechtenstein',
    'countries.LK': 'Sri Lanka',
    'countries.LR': 'Liberia',
    'countries.LS': 'Lesotho',
    'countries.LT': 'Litauen',
    'countries.LU': 'Luxemburg',
    'countries.LV': 'Lettland',
    'countries.LY': 'Libyen',
    'countries.MA': 'Marokko',
    'countries.MC': 'Monaco',
    'countries.MD': 'Republik Moldau',
    'countries.ME': 'Montenegro',
    'countries.MF': 'St. Martin',
    'countries.MG': 'Madagaskar',
    'countries.MH': 'Marshallinseln',
    'countries.MK': 'Nordmazedonien',
    'countries.ML': 'Mali',
    'countries.MM': 'Myanmar',
    'countries.MN': 'Mongolei',
    'countries.MO': 'Sonderverwaltungsregion Macau',
    'countries.MP': 'Nördliche Marianen',
    'countries.MQ': 'Martinique',
    'countries.MR': 'Mauretanien',
    'countries.MS': 'Montserrat',
    'countries.MT': 'Malta',
    'countries.MU': 'Mauritius',
    'countries.MV': 'Malediven',
    'countries.MW': 'Malawi',
    'countries.MX': 'Mexiko',
    'countries.MY': 'Malaysia',
    'countries.MZ': 'Mosambik',
    'countries.NA': 'Namibia',
    'countries.NC': 'Neukaledonien',
    'countries.NE': 'Niger',
    'countries.NF': 'Norfolkinsel',
    'countries.NG': 'Nigeria',
    'countries.NI': 'Nicaragua',
    'countries.NL': 'Niederlande',
    'countries.NO': 'Norwegen',
    'countries.NP': 'Nepal',
    'countries.NR': 'Nauru',
    'countries.NU': 'Niue',
    'countries.NZ': 'Neuseeland',
    'countries.OM': 'Oman',
    'countries.PA': 'Panama',
    'countries.PE': 'Peru',
    'countries.PF': 'Französisch-Polynesien',
    'countries.PG': 'Papua-Neuguinea',
    'countries.PH': 'Philippinen',
    'countries.PK': 'Pakistan',
    'countries.PL': 'Polen',
    'countries.PM': 'St. Pierre und Miquelon',
    'countries.PN': 'Pitcairninseln',
    'countries.PR': 'Puerto Rico',
    'countries.PS': 'Palästinensische Autonomiegebiete',
    'countries.PT': 'Portugal',
    'countries.PW': 'Palau',
    'countries.PY': 'Paraguay',
    'countries.QA': 'Katar',
    'countries.QO': 'Äußeres Ozeanien',
    'countries.RE': 'Réunion',
    'countries.RO': 'Rumänien',
    'countries.RS': 'Serbien',
    'countries.RU': 'Russland',
    'countries.RW': 'Ruanda',
    'countries.SA': 'Saudi-Arabien',
    'countries.SB': 'Salomonen',
    'countries.SC': 'Seychellen',
    'countries.SD': 'Sudan',
    'countries.SE': 'Schweden',
    'countries.SG': 'Singapur',
    'countries.SH': 'St. Helena',
    'countries.SI': 'Slowenien',
    'countries.SJ': 'Spitzbergen und Jan Mayen',
    'countries.SK': 'Slowakei',
    'countries.SL': 'Sierra Leone',
    'countries.SM': 'San Marino',
    'countries.SN': 'Senegal',
    'countries.SO': 'Somalia',
    'countries.SR': 'Suriname',
    'countries.SS': 'Südsudan',
    'countries.ST': 'São Tomé und Príncipe',
    'countries.SV': 'El Salvador',
    'countries.SX': 'Sint Maarten',
    'countries.SY': 'Syrien',
    'countries.SZ': 'Eswatini',
    'countries.TA': 'Tristan da Cunha',
    'countries.TC': 'Turks- und Caicosinseln',
    'countries.TD': 'Tschad',
    'countries.TF': 'Französische Süd- und Antarktisgebiete',
    'countries.TG': 'Togo',
    'countries.TH': 'Thailand',
    'countries.TJ': 'Tadschikistan',
    'countries.TK': 'Tokelau',
    'countries.TL': 'Timor-Leste',
    'countries.TM': 'Turkmenistan',
    'countries.TN': 'Tunesien',
    'countries.TO': 'Tonga',
    'countries.TR': 'Türkei',
    'countries.TT': 'Trinidad und Tobago',
    'countries.TV': 'Tuvalu',
    'countries.TW': 'Taiwan',
    'countries.TZ': 'Tansania',
    'countries.UA': 'Ukraine',
    'countries.UG': 'Uganda',
    'countries.UM': 'Amerikanische Überseeinseln',
    'countries.UN': 'Vereinte Nationen',
    'countries.US': 'Vereinigte Staaten',
    'countries.UY': 'Uruguay',
    'countries.UZ': 'Usbekistan',
    'countries.VA': 'Vatikanstadt',
    'countries.VC': 'St. Vincent und die Grenadinen',
    'countries.VE': 'Venezuela',
    'countries.VG': 'Britische Jungferninseln',
    'countries.VI': 'Amerikanische Jungferninseln',
    'countries.VN': 'Vietnam',
    'countries.VU': 'Vanuatu',
    'countries.WF': 'Wallis und Futuna',
    'countries.WS': 'Samoa',
    'countries.XA': 'Pseudo-Akzente',
    'countries.XB': 'Pseudo-Bidi',
    'countries.XK': 'Kosovo',
    'countries.YE': 'Jemen',
    'countries.YT': 'Mayotte',
    'countries.ZA': 'Südafrika',
    'countries.ZM': 'Sambia',
    'countries.ZW': 'Simbabwe',

    'languages.de_DE': 'Deutsch',
    'languages.fr_FR': 'Französisch',
    'languages.it_IT': 'Ialienisch',

    'courseContentCategories.dance': 'Tanz',
    'courseContentCategories.mobingSchool': 'Bewegte Schule',
    'courseContentCategories.blessYou': 'Gesundheit',
    'courseContentCategories.outdoor': 'Outdoor',
    'courseContentCategories.polysportivesAndGames': 'Polysportives und Spiele',
    'courseContentCategories.efSport': 'EF Sport',
    'courseContentCategories.water': 'Wasser',
    'courseContentCategories.apparatusGymnastics': 'Geräteturnen',
    'courseContentCategories.inclusion': 'Inklusion',

    'courseOptions.js_accepted': 'J+S anerkannt',
    'courseOptions.pickup_course': 'Holkurs',
    'courseOptions.certificated_course': 'Zertifizierender Kurs',
}

export default LocaleData