import { useState } from 'react'

import Login from './Login'
import Nonce from './Nonce'
import { useIntl } from 'react-intl'

import { useAppState } from '@lemonbrain/contexts'
import { useCaptcha, useAlert } from '@lemonbrain/hooks'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'

export default function LoginBusiness ({
    login,
    checkNonce,
    logout,
    goToForgotPassword,
    goToProfile,
    goToCheckValidationEmail,
    emailUrl,
}: {
    login: (email: string, password: string, reCaptchaToken: string) => Promise<{ token?: string, nonce?: string, state: "is-valid" }>,
    checkNonce: (email: string, nonce: string, u2SAPass: string, reCaptchaToken: string) => Promise<{ token: string, state: "is-valid" }>,
    logout: () => Promise<"is-valid">,
    goToForgotPassword: () => void,
    goToProfile: (uName?: string) => void,
    goToCheckValidationEmail: (uName: string) => void,
    emailUrl?: string,
}) {
    const { isLoaded, isError, getCaptchaToken } = useCaptcha()
    const setAlert = useAlert()
    const { user, setToken, clearToken } = useAppState()
    const [isLoading, setIsLoading] = useState(false)
    const [nonce, setNonce] = useState<string | undefined>(undefined)
    const [email, setEmail] = useState<string | undefined>(undefined)
    const intl = useIntl()

    async function onLogin ({ email, password }: { email: string, password: string }) {
        let state = false
        setIsLoading(true)
        let answer: { token?: string, nonce?: string, state: string } | undefined = undefined
        try {
            if (!user || user.uID !== '') {
                try {
                    const reCaptchaToken = await getCaptchaToken('login')
                    answer = await login(email, password, reCaptchaToken)
                } catch (authError) {
                    //@ts-ignore
                    if (authError.type === 'user_non_validated') {
                        //@ts-ignore
                        goToCheckValidationEmail(authError.uName)
                    }
                    state = false
                    setAlert && setAlert({
                        title: intl.formatMessage({ id: 'auth.login.error', defaultMessage: 'Error' }),
                        label: intl.formatMessage({ id: 'auth.login.error.notLoggedInUsernamePasswordWrong', defaultMessage: 'We could not log you in. Username or Password wrong' }),
                        style: 'error'
                    })
                }
                state = !!answer && answer.state === 'is-valid'
                if (answer && answer.token) {
                    setToken && setToken(answer.token)
                    setAlert && setAlert({
                        title: intl.formatMessage({ id: 'auth.login.loggedIn', defaultMessage: 'Logged in' }),
                        label: intl.formatMessage({ id: 'auth.login.loggedIn.success', defaultMessage: 'You are now logged in' }),
                        style: 'success'
                    })
                    goToProfile(user?.uName)
                } else if (answer && answer.nonce) {
                    setNonce(answer.nonce)
                    setEmail(email)
                }
            }
        }
        catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.login.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.login.error.notLoggedInUsernamePasswordWrong', defaultMessage: 'We could not log you in. Username or Password wrong' }),
                style: 'error'
            })
        }
        setIsLoading(false)
        return state
    }

    async function onCheckNonce ({ u2SAPass }: { u2SAPass: string }) {
        let state = false
        setIsLoading(true)
        try {
            if (!user || user.uID !== '') {
                let answer: { token: string, state: string } | undefined = undefined
                try {
                    const reCaptchaToken = await getCaptchaToken('checkNonce')
                    answer = await checkNonce(email || '', nonce || '', u2SAPass, reCaptchaToken)
                } catch (authError) {
                    state = false
                    setAlert && setAlert({
                        title: intl.formatMessage({ id: 'auth.login.error', defaultMessage: 'Error' }),
                        label: intl.formatMessage({ id: 'auth.login.wrongAuthenticator', defaultMessage: 'Invalid authenticator token. Try again' }),
                        style: 'error'
                    })
                }
                state = !!answer && answer.state === 'is-valid'
                if (answer && answer.token) {
                    setNonce(undefined)
                    setEmail(undefined)
                    setToken && setToken(answer.token)
                    setAlert && setAlert({
                        title: intl.formatMessage({ id: 'auth.login.loggedIn', defaultMessage: 'Logged in' }),
                        label: intl.formatMessage({ id: 'auth.login.loggedIn.success', defaultMessage: 'You are now logged in' }),
                        style: 'success'
                    })
                    goToProfile(user?.uName)
                }
            }
        }
        catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.login.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.login.error.wrongAuthenticator', defaultMessage: 'Invalid authenticator token. Try again' }),
                style: 'error'
            })
        }
        setIsLoading(false)
        return state
    }

    async function onLogoutSubmit () {
        let state = false
        setIsLoading(true)
        try {
            const newState = await logout()
            state = newState === 'is-valid'
            clearToken && clearToken()
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.login.loggedOut', defaultMessage: 'Logged out' }),
                label: intl.formatMessage({ id: 'auth.login.loggedOut.success', defaultMessage: 'You are now logged out' }),
                style: 'success'
            })
        }
        catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.login.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.login.error.notLoggedOut', defaultMessage: 'We could not log you out. Please try again' }),
                style: 'error'
            })
        }
        setIsLoading(false)
        return state
    }

    if (isError) {
        setAlert && setAlert({
            title: intl.formatMessage({ id: 'auth.login.error', defaultMessage: 'Error' }),
            label: intl.formatMessage({ id: 'auth.login.error.googleRecaptchaCouldNotBeLoaded', defaultMessage: 'Google ReCaptcha could not be loaded' }),
            style: 'error'
        })
    }
    
    return isLoaded ?
        (
            nonce && !user ? (
                <Nonce onSubmit={onCheckNonce} onForgotPasswortClick={goToForgotPassword} isLoading={isLoading} />
            ) : (
                    <Login onSubmit={onLogin} onLogoutSubmit={onLogoutSubmit} onForgotPasswortClick={goToForgotPassword} isLoading={isLoading} user={user} emailUrl={emailUrl} />
                )

        ) : (<Loader />)
}
