import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import {
  useGetPersonsIfManagesLazyQuery,
  useGetNextPersonsIfManagesLazyQuery,
} from '../../../../gql/members/Person.generated'
import ExportBusiness from './Export.business'
import getReactApolloCients from '../../../../localReactApollo'

export default function ExportIfManagesData({
  setNext,
  selectedPerson,
}: {
  setNext: (data: any[], nextExportData: (nextCursor: string) => void, nextCursor: string) => void
  selectedPerson: string[]
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [cursor, setCursor] = useState<
    | {
        pageSize: number
        filtered: {
          id: string
          value: string
        }[]
      }
    | undefined
  >()
  const client = getReactApolloCients().getClient()
  const [
    getPersonsQuery,
    { data: dataGetPerson, loading: loadingGetPerson, error: errorGetPerson },
  ] = useGetPersonsIfManagesLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })
  const [
    getNextPersonsQuery,
    {
      data: dataGetNextPerson,
      loading: loadingGetNextPerson,
      error: errorGetNextPerson,
    },
  ] = useGetNextPersonsIfManagesLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const startExport = function () {
    if (cursor) {
      getPersonsQuery({
        variables: {
          cursor,
        },
      })
    }
  }

  const nextExportData = (nextCursor: string) => {
    if (cursor) {
      getNextPersonsQuery({
        variables: {
          cursor: {
            ...cursor,
            nextCursor,
          },
        },
      })
    }
  }

  useEffect(() => {
      setCursor({
        pageSize: parseInt(process.env.REACT_APP_MITGLIEDER_EXPORT_PAGE_SIZE || '1'),
        filtered: (() => {
          const filter: { id: string; value: string }[] = []

          filter.push({
            id: 'ids',
            value: selectedPerson.join(', '),
          })
          return filter
        })(),
      })
  }, [selectedPerson])

  useEffect(() => {
    if (
      dataGetPerson &&
      dataGetPerson?.getPersonsIfManages.items.length > 0
    ) {
      setNext(
        dataGetPerson?.getPersonsIfManages.items,
        nextExportData,
        dataGetPerson.getPersonsIfManages.nextCursor || ''
      )
    }
  }, [dataGetPerson])

  useEffect(() => {
    if (dataGetNextPerson) {
      setNext(
        dataGetNextPerson?.getNextPersonsIfManages.items,
        nextExportData,
        dataGetNextPerson.getNextPersonsIfManages.nextCursor || ''
      )
    }
  }, [dataGetNextPerson])

  useEffect(() => {
    if (errorGetPerson || errorGetNextPerson) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.membersTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.membersTable.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorGetPerson?.message || '' + errorGetNextPerson?.message)
    }
  }, [errorGetPerson, errorGetNextPerson])

  return (
    <ExportBusiness
      loading={loadingGetPerson || loadingGetNextPerson}
      startExport={startExport}
      selectedPerson={selectedPerson}
    />
  )
}
