import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import SignupRecipientTable from './SignupRecipientTable'

export default function SignupRecipientTableBusiness({
  isLoading,
  signupRecipients,
  refetchSignupRecipientsEntries,
  removeSignupRecipientEntry,
  queryError,
}: {
  isLoading: boolean
  signupRecipients: {
    id: string
    data: { email: string }
  }[]
  refetchSignupRecipientsEntries: () => void
  removeSignupRecipientEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeSignupRecipientEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.courses.courseSettings.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchSignupRecipientsEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.courses.courseSettings.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.courses.courseSettings.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.courses.courseSettings.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.courses.courseSettings.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (
    <SignupRecipientTable
      isLoading={currentIsLoading}
      rows={signupRecipients}
      removeRow={removeRow}
    />
  )
}
