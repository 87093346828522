import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import AddressBusiness from './Address.business'
import getReactApolloCients from '../../../../localReactApollo'
import { useGetAddressTypesQuery } from '../../../../gql/members/Address.generated'

export default function AddressData({
  setAddress,
  address,
  getHasAddressErrors,
  isFirst,
  otherTypeId
}: {
  setAddress: (address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }) => void
  address: {
    id: string
    salutation: string
    firstName: string
    lastName: string
    firmName: string
    mailbox: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    country: string
    types: {
      id: string
      isFirm?: boolean
      name: {
        de: string
        fr: string
        it: string
      }
    }
  }
  getHasAddressErrors: (
    errors: {
      salutation: string
      firstName: string
      lastName: string
      firmName: string
      streetNr: string
      postCode: string
      place: string
      canton: string
      country: string
  }) => void
  isFirst: boolean
  otherTypeId?: string
}) {
  const [types, setTypes] = useState<
    {
      id: string
      isFirm: boolean
      label: string
    }[]
  >([])
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()
  const {
    data: dataAddressType,
    loading: loadingAddressType,
    error: errorAddressType,
  } = useGetAddressTypesQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (errorAddressType) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.address.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.address.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorAddressType?.message)
    }
  }, [errorAddressType])

  useEffect(() => {
    if (dataAddressType?.getAddressTypes.items) {
      setTypes(
        dataAddressType?.getAddressTypes.items.filter(item => !otherTypeId || (otherTypeId && otherTypeId !== item.id)).map((item) => ({
          id: item.id,
          isFirm: item.isFirm,
          label: getTranslation(item.name, intl),
        }))
      )
    }
  }, [dataAddressType])

  return (
    <AddressBusiness
      types={types}
      address={address}
      setAddress={setAddress}
      loadingAddressType={loadingAddressType}
      getHasAddressErrors={getHasAddressErrors}
      isFirst={isFirst}
    />
  )
}
