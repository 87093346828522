import React, { useEffect } from 'react'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import { ButtonGroup } from '@lemonbrain/components/PageStyles/Settings.styled'

interface iFormInput {
  email: string
  password: string
  passwordConfirm: string
}

export default function ChangePassword({
  onSubmit,
  isLoading,
  onLoginClick,
}: {
  onSubmit: (email: string, password: string) => Promise<boolean>
  isLoading: boolean
  onLoginClick?: (a: React.SyntheticEvent<HTMLButtonElement>) => unknown
}) {
  const {
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<iFormInput>()
  const intl = useIntl()

  useEffect(() => {
    register('email', {
      required: true,
      pattern: /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    })
    register('password', {
      required: true,
      minLength: 6,
    })
    register('passwordConfirm', {
      required: true,
      minLength: 6,
    })
  }, [register])

  const onSubmitCalled = async ({
    email,
    password,
    passwordConfirm,
  }: iFormInput) => {
    if (password === passwordConfirm) {
      await onSubmit(email, password)
    } else {
      setError('password', {
        type: 'manual',
        message: intl.formatMessage({
          id: 'auth.signUp.error.needToBeTheSame',
          defaultMessage: 'The two password needs to be the same',
        }),
      })
      setError('passwordConfirm', {
        type: 'manual',
        message: intl.formatMessage({
          id: 'auth.signUp.error.needToBeTheSame',
          defaultMessage: 'The two password needs to be the same',
        }),
      })
    }
  }

  return (
    <Container>
      <Block
        overrides={{
          Block: {
            style: {
              minHeight: 'calc(100vh - 213px)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmitCalled)} style={{ width: '100%' }}>
          <Block
            as='h1'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font1250,
                    color: '#fff',
                    fontWeight: 700,
                    marginBottom: '30px',
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='auth.signUp.signUp'
              defaultMessage={'Sign up'}
            />
          </Block>
          <Block marginBottom='30px'>
            <FormControl
              label={intl.formatMessage({
                id: 'auth.signUp.emailAddress',
                defaultMessage: 'Email Address',
              })}
              caption='johndoe@demo.io'
              error={
                errors.email &&
                (errors.email.message
                  ? errors.email.message
                  : intl.formatMessage({
                      id: 'auth.signUp.error.validEmailAddress',
                      defaultMessage: 'Please enter a valid Email Address',
                    }))
              }
            >
              <Input
                name='email'
                overrides={{
                  InputContainer: {
                    style: () => {
                      return { backgroundColor: 'transparent' }
                    },
                  },
                }}
                onChange={(event) => {
                  setValue('email', event.currentTarget.value)
                }}
              />
            </FormControl>
          </Block>
          <Block marginBottom='30px'>
            <FormControl
              label={intl.formatMessage({
                id: 'auth.signUp.password',
                defaultMessage: 'Password',
              })}
              error={
                errors.password &&
                (errors.password.message
                  ? errors.password.message
                  : intl.formatMessage({
                      id: 'auth.signUp.error.password',
                      defaultMessage:
                        'Please enter a password width at least 6 signs',
                    }))
              }
            >
              <Input
                name='password'
                type='password'
                overrides={{
                  InputContainer: {
                    style: () => {
                      return { backgroundColor: 'transparent' }
                    },
                  },
                }}
                onChange={(event) => {
                  setValue('password', event.currentTarget.value)
                }}
              />
            </FormControl>
          </Block>
          <Block marginBottom='30px'>
            <FormControl
              label={intl.formatMessage({
                id: 'auth.signUp.passwordConfirm',
                defaultMessage: 'Confirm password',
              })}
              error={
                errors.password &&
                (errors.password.message
                  ? errors.password.message
                  : intl.formatMessage({
                      id: 'auth.signUp.error.confirmPassword',
                      defaultMessage:
                        'Please enter a confirmation password width at least 6 signs',
                    }))
              }
            >
              <Input
                name='passwordConfirm'
                type='password'
                overrides={{
                  InputContainer: {
                    style: () => {
                      return { backgroundColor: 'transparent' }
                    },
                  },
                }}
                onChange={(event) => {
                  setValue('passwordConfirm', event.currentTarget.value)
                }}
              />
            </FormControl>
          </Block>
          <ButtonGroup>
            <Button type='submit' isLoading={isLoading}>
              <FormattedMessage
                id='auth.signUp.signUp'
                defaultMessage={'Sign up'}
              />{' '}
            </Button>
            <Button kind='tertiary' type='button' onClick={onLoginClick}>
              <FormattedMessage
                id='auth.signUp.signIn'
                defaultMessage={'Login'}
              />
            </Button>
          </ButtonGroup>
        </form>
      </Block>
    </Container>
  )
}
