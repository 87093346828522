import { useIntl } from 'react-intl'
import { Button, SIZE } from 'baseui/button'
import { ArrowDown, Menu } from 'baseui/icon'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'

export default function ExportAttendeesList({
  loading,
  onClick,
}: {
  loading: boolean
  onClick: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => any
}) {
  const intl = useIntl()

  return <StatefulTooltip
      placement={PLACEMENT.right}
      content={intl.formatMessage({
        id: 'courses.exportAttendeesList.exportAttendeesList',
        defaultMessage: 'Export Attendees List',
      })}
      overrides={{
        Body: {
          style: () => ({
            zIndex: 99999,
          }),
        },
      }}
    >
      <Button isLoading={loading} onClick={onClick} size={SIZE.compact}>
        <ArrowDown
          title={intl.formatMessage({
            id: 'courses.exportAttendeesList.exportAttendeesList',
            defaultMessage: 'Export Attendees List',
          })}
        />
        <Menu
          title={intl.formatMessage({
            id: 'courses.exportAttendeesList.exportAttendeesList',
            defaultMessage: 'Export Attendees List',
          })}
        />
      </Button>
    </StatefulTooltip>
}
