const LocaleData = {
  'members.members': 'Mitglieder',

  'members.overview': 'Übersicht',
  'members.invoices': 'Rechnungen',
  'members.courses': 'Weiterbildungen',
  'members.add': 'Hinzufügen',

  'members.members_overview': 'Übersicht',

  'membersAdd.members_add': 'Mitglied hinzufügen',

  'members.create.error': 'Fehler beim Hinzufügen',
  'members.create.errorText': 'Die Person konnte nicht erstellt werden',

  'members.mebers_table.email': 'E-Mail',
  'members.mebers_table.firstName': 'Vorname',
  'members.mebers_table.lastName': 'Nachname',
  'members.mebers_table.firmName': 'Firmenname',
  'members.membersTable.error': 'Fehler',
  'members.membersTable.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.membersTable.load_mores': 'Mehr laden',
  'members.membersTable.page_count': 'Seite {currentPage}',
  'members.membersTable.page_size_selection':
    'Zeige {pageSize} Elemente pro Seite',
  'members.membersTable.loading': 'Laden...',
  'members.membersTable.results_count': 'Zeigt {pageLength} Resultate',
  'members.membersTable.functions': 'Funktionen',
  'members.membersTable.delete': 'Löschen',
  'members.membersTable.deleteConfirm':
    'Soll der Benutzer wirklich gelöscht werden',
  'members.membersTable.state': 'Status',

  'members.membersEdit.toOverview': 'Zur Übersicht',
  'members.membersEdit.toInvoices': 'Zu den Rechnungen',
  'members.membersEdit.toCourses': 'Zu den Weiterbildungen',
  'members.membersEdit.error': 'Fehler',
  'members.membersEdit.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.membersEdit.saved': 'Gespeichert',
  'members.membersEdit.saved.updated': 'Der Eintrag wurde aktualisiert',
  'members.membersEdit.error.thereWasAServerError':
    'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
  'members.membersEdit.error.notLoggedIn':
    'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
  'members.membersEdit.correspondence_adress': '1/4 Korrespondenz Adresse',
  'members.membersEdit.additional_adress': '2/4 Zusätzliche Adresse',
  'members.membersEdit.personal': '3/4 Personalien',
  'members.membersEdit.additional': '4/4 Sonstiges',
  'members.membersEdit.state': 'Status',
  'members.membersEdit.save': 'Speichern',
  'members.membersEdit.saveConfirm': 'Eintrag trotz Fehlern speichern?',
  'members.membersEdit.openAuthEntry': 'Öffne Auth Eintrag mit ID: {authId}',
  'members.membersEdit.userHasNoLogin': 'Der Benutzer hat kein verknüpftes Login auf dem Auth-Server. Er muss über die Website ein neues Profil mit der gleichen Mailadresse wie in der Mitglieder DB erstellen. Oder es kann ein existierendes Auth-Profil mit hilfe von lemonbrain verknüpft werden',

  'members.personal.email.isRequired':
    'Es ist eine gültige E-Mailadresse verlangt',
  'members.personal.email': 'Korrespondenz E-Mailadresse',
  'members.personal.birthdate.isRequired':
    'Es ist ein gültiges Geburtsdatum verlangt',
  'members.personal.ahvNumber.isRequired': 'Es ist eine gültige AHV Nummer verlangt',
  'members.personal.birthdate': 'Geburtsdatum',
  'members.personal.telephone': 'Telefonnummer',
  'members.personal.mobile': 'Handynummer',
  'members.personal.ahvNumber': 'AHV Nummer',
  'members.personal.jsNumber': 'J+S Nummer',
  'members.personal.slrgNumber': 'SLRG Nummer',

  'members.addresses.add': 'Hinzufügen',
  'members.addresses.new': 'Neu',

  'members.address.error': 'Fehler',
  'members.address.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.address.type': 'Typ',
  'members.address.salutation': 'Anrede',
  'members.address.firmName': 'Schule',
  'members.address.firstName': 'Vorname',
  'members.address.lastName': 'Nachname',
  'members.address.mailbox': 'Adresszusatz',
  'members.address.streetNr': 'Strasse und Hausnummer',
  'members.address.postCode': 'PLZ',
  'members.address.place': 'Ort',
  'members.address.canton': 'Kanton',
  'members.address.country': 'Land',
  'members.address.salutation.isRequired': 'Anrede ist verlangt',
  'members.address.firmName.isRequired': 'Schule ist verlangt',
  'members.address.firstName.isRequired': 'Vorname ist verlangt',
  'members.address.lastName.isRequired': 'Nachname ist verlangt',
  'members.address.streetNr.isRequired': 'Strasse und Hausnummer sind verlangt',
  'members.address.postCode.isRequired':
    'PLZ muss eine Nummer sein und ist verlangt',
  'members.address.place.isRequired': 'Ort ist verlangt',
  'members.address.canton.isRequired': 'Kanton ist verlangt',
  'members.address.country.isRequired': 'Land ist verlangt',
  'members.address.remove': 'Entfernen',

  'members.sportsEducation.error': 'Fehler',
  'members.sportsEducation.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.sportsEducation.hasSportsEducation':
    'Befähigung zum Sportunterricht',
  'members.sportsEducation.isRequired':
    'eine Befähigung zum Sportuntericht ist verlangt',

  'members.workLevel.error': 'Fehler',
  'members.workLevel.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.workLevel.currentWork': 'Aktuelle Unterrichtsstufe',
  'members.workLevel.isRequired': 'Eine Unterichtsstufe ist verlangt',

  'members.association.error': 'Fehler',
  'members.association.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.association.association': 'Mitgliedschaft Kantonalverband',
  'members.association.isRequired': 'Eine Mitgliedschaft ist verlangt',
  'members.association.associationPayTo': 'Bezahlt an',
  'members.associationPayTo.isRequired': 'Bezahlt an ist verlangt',
  'members.association.associationType.isRequired': 'Verbandstätigkei ist verlangt',
  'members.association.associationType': 'Verbandstätigkeit',
  'members.association.beAwareOfMoreRightsForThem':
    'Bitte berücksichtige, dass diese Person dadurch mehr Rechte bekommen kann',
    'members.association.associationComment': 'Kommentar oder Begründung zur Wahl des Kantonalverbands',

  'members.members_filter.state': 'Status',
  'members.members_filter.association': 'Kantonalverband',
  'members.members_filter.workLevel': 'Unterrichtsstufe',
  'members.members_filter.sportsEducation': 'Sportausbildung',
  'members.members_filter.chooseFilter': 'Wähle einen Filter',
  'members.members_filter.activeFilters': 'Aktivierte Filter',

  'members.association_filter.cancel': 'Abbrechen',
  'members.association_filter.error': 'Fehler',
  'members.association_filter.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.association_filter.association': 'Kantonalverband',
  'members.association_filter.associationComment':
    'Ist Mitglied von folgenden Kantonalverbänden',
    'members.association.associationComment.isRequired': 'Kommentar/Begründung ist verlangt',
    'members.association.associationComment.isRequired1': 'Es ist eine Begründung verlangt, warum man nicht einer Vereinigung beitreten möchte',

  'members.workLevel_filter.cancel': 'Abbrechen',
  'members.workLevel_filter.error': 'Fehler',
  'members.workLevel_filter.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.workLevel_filter.workLevel': 'Unterrichtsstufe',
  'members.workLevel_filter.workLevelComment':
    'Arbeitet auf folgenden Unterrichtsstufen',

  'members.state_filter.cancel': 'Abbrechen',
  'members.state_filter.error': 'Fehler',
  'members.state_filter.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.state_filter.state': 'Status',
  'members.state_filter.stateComment': 'Besitzt folgenden Status',
  'members.state_filter.reason': 'Grund',
  'members.state_filter.reasonComment': 'Besitzt folgenden Grund',
  'members.state_filter.membership': 'Mitgliedschaft',
  'members.state_filter.membershipComment': 'Besitzt folgende Mitgliedschaft',

  'members.sportsEducation_filter.cancel': 'Abbrechen',
  'members.sportsEducation_filter.error': 'Fehler',
  'members.sportsEducation_filter.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.sportsEducation_filter.sportsEducation': 'Sportausbildung',
  'members.sportsEducation_filter.sportsEducationComment':
    'Hat folgende Sportausbildungen absolviert',

  'members.state.error': 'Fehler',
  'members.state.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.state.state': 'Status',
  'members.state.stateType': 'Grund',
  'members.state.membership': 'Mitgliedschaft',
  'members.state.isRequired': 'Ein Status muss ausgewählt werden',
  'members.state.stateTypeisRequired': 'Ein Grund muss ausgewählt werden',
  'members.state.membershipisRequired': 'Eine Mitgliedschaft ist verlangt',

  'members.comment.comment': 'Kommentar',

  'members.export.exportMembers': 'Mitglieder exportieren',
  'members.export.id': 'ID',
  'members.export.email': 'E-Mail-Adresse',
  'members.export.birthdate': 'Geburtsdatum',
  'members.export.telephone': 'Telefonnummer',
  'members.export.mobile': 'Handynummer',
  'members.export.ahvNumber': 'AHV Nummer',
  'members.export.jsNumber': 'J+S Nummer',
  'members.export.slrgNumber': 'SLRG Nummer',
  'members.export.comment': 'Kommentar',
  'members.export.addressId': 'Adress ID',
  'members.export.addressSalutation': 'Anrede',
  'members.export.addressFirstName': 'Vorname',
  'members.export.addressLastName': 'Nachname',
  'members.export.addressFirmName': 'Schule',
  'members.export.addressMailbox': 'Postfach',
  'members.export.addressStreetNr': 'Strasse Nr.',
  'members.export.addressPostCode': 'PLZ',
  'members.export.addressPlace': 'Ort',
  'members.export.addressCanton': 'Kanton',
  'members.export.addressCountry': 'Land',
  'members.export.addressIsFirm': 'Firmenadresse',
  'members.export.addressIsFirm.yes': 'Ja',
  'members.export.addressIsFirm.no': 'Nein',
  'members.export.AddressType': 'Typ',
  'members.export.hasSportsEducation': 'Befähigung zum Sportunterricht',
  'members.export.currentWork': 'Aktuelle Unterrichtsstufe',
  'members.export.associationPersons': 'Verband [Tätigkeit]',
  'members.export.associationPayTo': 'Bezahlt an Verband',
  'members.export.state': 'Status',
  'members.export.stateType': 'Detail Status',

  'members.mail.createMailing': 'Mailing erstellen',
  'members.mail.send': 'Sende an {recipients} Empfänger',
  'members.mail.sending': 'Versand an {recipients} Empfänger läuft (Gesendet: {sent}, haben Mailing bereits erhalten: {double}, Fehlgeschlagen: {failed})',
  'members.mail.fromMail': 'Von',
  'members.mail.fromNameMail': 'Von Name',
  'members.mail.toMail': 'An',
  'members.mail.subject': 'Betreff',
  'members.mail.content': 'Inhalt',
  'members.mail.fromEmailIsRequired': 'Von E-Mailadresse ist verlangt und muss im korrekten Format sein',
  'members.mail.fromNameEmailIsRequired': 'Von E-Mail Name ist verlangt und muss im korrekten Format sein',
  'members.mail.toEmailIsRequired': 'An E-Mailadresse ist verlangt',
  'members.mail.subjectIsRequired': 'Betreff ist verlangt',
  'members.mail.contentIsRequired': 'Inhalt ist verlangt',
  'members.mail.error': 'Fehler',
  'members.mail.networkError': 'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.mail.state.pending': 'Bereit zum Versand',
  'members.mail.state.running': 'Versand läuft',
  'members.mail.state.sleeping': 'Versandspause, startet automatisch wieder',
  'members.mail.state.interrupted': 'Versand unterbrochen, versuche es noch einmal zu starten (Bereits versendete, werden nicht erneut versendet)',
  'members.mail.state.completed': 'Versand erfolgreich abgeschlossen',

  'members.newsletter.createNewsletter': 'Newsletter erstellen',
  'members.newsletter.send': 'Sende an {recipients} Empfänger',
  'members.newsletter.sending': 'Versand an {recipients} Empfänger läuft (Gesendet: {sent}, haben Mailing bereits erhalten: {double}, Fehlgeschlagen: {failed})',
  'members.newsletter.fromMail': 'Von',
  'members.newsletter.fromNameMail': 'Von Name',
  'members.newsletter.toMail': 'An',
  'members.newsletter.subject': 'Betreff',
  'members.newsletter.content': 'Inhalt',
  'members.newsletter.fromEmailIsRequired': 'Von E-Mailadresse ist verlangt und muss im korrekten Format sein',
  'members.newsletter.fromNameEmailIsRequired': 'Von E-Mail Name ist verlangt und muss im korrekten Format sein',
  'members.newsletter.toEmailIsRequired': 'An E-Mailadresse ist verlangt',
  'members.newsletter.subjectIsRequired': 'Betreff ist verlangt',
  'members.newsletter.contentIsRequired': 'Inhalt ist verlangt',
  'members.newsletter.error': 'Fehler',
  'members.newsletter.networkError': 'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.newsletter.state.pending': 'Bereit zum Versand',
  'members.newsletter.state.running': 'Versand läuft',
  'members.newsletter.state.sleeping': 'Versandspause, startet automatisch wieder',
  'members.newsletter.state.interrupted': 'Versand unterbrochen, versuche es noch einmal zu starten (Bereits versendete, werden nicht erneut versendet)',
  'members.newsletter.state.completed': 'Versand erfolgreich abgeschlossen',
  'members.newsletter.selectNewsletter': 'Wähle einen Newsletter',
  'members.newsletter.newsletter': 'Newsletter',
  'members.newsletter.newsletters': 'Newsletter',

  'members.toggleAll.toggleAll': 'Alle umschalten',
  'members.toggleAll.error': 'Fehler',
  'members.toggleAll.networkError': 'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',

  'members.svssFunction.error': 'Fehler',
  'members.svssFunction.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.svssFunction.svssFunctions': 'SVSS Funktionen',

  'members.stateFunction.error': 'Fehler',
  'members.stateFunction.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'members.stateFunction.stateFunctions': 'Kantons Funktionen',

  'members.membersInvoices.toPerson': 'Zur Person',

  'members.membersCourses.toPerson': 'Zur Person',

  'members.memberSelector.error': 'Fehler',
  'members.memberSelector.networkError': 'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
}

export default LocaleData
