import InvoicesFilterBusiness from './InvoicesFilter.business'

export default function InvoicesFilterData({
  searchQuery,
  setSearchQuery,
  payedFilter,
  setPayedFilter,
}: {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  payedFilter: string[] | null
  setPayedFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
  return (
    <InvoicesFilterBusiness
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      payedFilter={payedFilter}
      setPayedFilter={setPayedFilter}
    />
  )
}
