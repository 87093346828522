import React, {} from 'react'
import { useParams, useLocation } from "react-router-dom"

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AuthHeader from '../Header/AuthHeader/AuthHeader'

import AppWrapper, { ContentWrapper } from './Layout.styled'

import { useThemeSwitcherCtx, THEME } from '@lemonbrain/contexts/theme/theme.provider'

type Props = {
  children: React.ReactNode
}

const Layout: React.FunctionComponent<Props> = ({
  children,
}) => {
  const location = useLocation()
  const { language } = useParams<{ language: string }>()
  const pathname = location.pathname
  const { theme } = useThemeSwitcherCtx()

  let layoutBg = '#ffffff'

  if (theme === THEME.dark) {
    layoutBg = '#000000'
  }

  if (
    (theme === THEME.light && pathname === `/${language}/`) ||
    (theme === THEME.light && pathname === `/${language}/chat`)
  ) {
    layoutBg = '#fcfcfc'
  }

  if (
    (theme === THEME.dark && pathname === `/${language}/`) ||
    (theme === THEME.dark && pathname === `/${language}/chat`)
  ) {
    layoutBg = '#0c0c0c'
  }

  return (
    <AppWrapper className={theme} style={{ backgroundColor: layoutBg }}>
      {pathname === `/${language}/login` || pathname === `/${language}/signup` ? (
        <AuthHeader pathname={pathname} />
      ) : (
          <Header />
        )}

      <ContentWrapper>{children}</ContentWrapper>

      {((pathname === `/${language}/login` || pathname === `/${language}/signup`) && !(window.location.hostname.includes('localhost') || window.location.hostname.includes('lemonbrain.ch'))) ? '' : <Footer />}
    </AppWrapper>
  )
}

export default Layout
