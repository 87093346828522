// import { Transform, Options } from 'json2csv'
import { TransformStream } from '@json2csv/whatwg';
import streamSaver from 'streamsaver'
//@ts-ignore
import { Readable, Writable } from 'stream-browserify'

//https://www.npmjs.com/package/json2csv
//https://juanjodiaz.github.io/json2csv/#/parsers/whatwg-transform-stream
//https://github.com/jimmywarting/StreamSaver.js/
//https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream
//https://developer.mozilla.org/en-US/docs/Web/API/WritableStream

class PushableStream {
  controller: ReadableStreamDefaultController | null;
  queue: any[];
  closed: boolean;
  stream: ReadableStream<any>;

  constructor() {
    this.controller = null;
    this.queue = [];
    this.closed = false;

    this.stream = new ReadableStream({
      start: (controller) => {
        this.controller = controller;
        this._processQueue();
      },
      pull: () => {
        this._processQueue();
      },
      cancel: () => {
        this.closed = true;
      }
    });
  }

  push(chunk: any) {
    if (this.closed) {
      throw new Error('Stream is already closed');
    }
    if (chunk === null) {
      this.controller!.close();
      this.closed = true;
    } else {
      this.queue.push(chunk);
      this._processQueue();
    }
  }

  getIsClosed() {
    return this.closed;
  }

  _processQueue() {
    if (this.controller && this.queue.length > 0) {
      while (this.queue.length > 0 && this.controller && this.controller.desiredSize && this.controller.desiredSize > 0) {
        const chunk = this.queue.shift();
        this.controller.enqueue(chunk);
      }
    }
  }
}

export const useJson2CsvExporter = (
  filename: string,
  opts?: any
) => {
  let input: ReadableStream | undefined = undefined
  let fileStream: WritableStream<any> | undefined = undefined
  let writer: WritableStreamDefaultWriter<any> | undefined = undefined
  let lastNextCursor = ''
  let pushableStream = new PushableStream();
  fileStream = streamSaver.createWriteStream(filename)
  writer = fileStream.getWriter()

  const setNext = (
    data: any[],
    nextExportData: (nextCursor: string) => void,
    nextCursor: string
  ) => {
    if (data && data.length > 0) {
      if (!input) {
        fileStream = streamSaver.createWriteStream(filename)
        writer = fileStream.getWriter()

        if (pushableStream.getIsClosed()) {
          pushableStream = new PushableStream();
          lastNextCursor = ''
        }
        input = pushableStream.stream;
        const echoStream = new WritableStream(
          {
            write(chunk) {
              return new Promise(async (resolve) => {
                if (writer) {
                  const uInt8 = new TextEncoder().encode(chunk)
                  await writer.write(uInt8)
                }
                resolve();
              });
            },
            abort(err) {
              console.log("Sink error:", err);
            },
          }
        );

        const transformOpts = { objectMode: true }
        const json2csv = new TransformStream(opts, transformOpts)
        input.pipeThrough(json2csv).pipeTo(echoStream)
      }

      data.map((item) => pushableStream && pushableStream.push(item))
      if (nextCursor && lastNextCursor !== nextCursor) {
        nextExportData(nextCursor)
        lastNextCursor = nextCursor
      }
    } else if (input) {
      pushableStream.push(null)
      if (writer) {
        writer.ready
          .then(() => {
            if (writer) {
              writer.close();
            }
          })
          .catch((err) => {
            console.log("Stream error:", err);
          });
      }
      input = undefined
    }
  }

  return setNext
}
