import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'

import LoginBusiness from './Login.business'

import { getApolloClientAuth } from '../../react-apollo'
import {
  useLogoutMutation,
  useLoginMutation,
  useCheckNonceMutation,
} from '../../gql/Auth.generated'

export default function LoginData() {
  const pushPath = useHistoryPush()
  const clientAuth = getApolloClientAuth()
  const { language } = useParams<{ language: string }>()
  const { email } = useParams<{ email: string }>()

  const [logoutMutation] = useLogoutMutation({
    client: clientAuth,
  })
  const [loginMutation] = useLoginMutation({
    client: clientAuth,
  })
  const [checkNonceMutation] = useCheckNonceMutation({
    client: clientAuth,
  })

  async function login(
    email: string,
    password: string,
    reCaptchaToken: string
  ) {
    return new Promise<{ token?: string; nonce?: string; state: 'is-valid' }>(
      async (resolve, reject) => {
        try {
          const result = await loginMutation({
            variables: {
              username: email,
              password,
              reCaptchaToken,
            },
          })

          if (result.data?.login.authError) {
            reject(result.data?.login.authError)
          } else {
            resolve({
              token: result.data?.login.authToken,
              nonce: result.data?.login.nonce,
              state: 'is-valid',
            })
          }
        } catch (e) {
          reject()
        }
      }
    )
  }

  async function checkNonce(
    email: string,
    nonce: string,
    u2SAPass: string,
    reCaptchaToken: string
  ) {
    return new Promise<{ token: string; state: 'is-valid' }>(
      async (resolve, reject) => {
        try {
          const result = await checkNonceMutation({
            variables: {
              username: email,
              nonce,
              u2SAPass,
              reCaptchaToken,
            },
          })

          if (result.data?.checkNonce.authError) {
            reject(result.data?.checkNonce.authError)
          } else {
            resolve({
              token: result.data?.checkNonce.authToken || '',
              state: 'is-valid',
            })
          }
        } catch (e) {
          reject()
        }
      }
    )
  }

  async function logout() {
    return new Promise<'is-valid'>(async (resolve, reject) => {
      try {
        await logoutMutation()
        resolve('is-valid')
      } catch (e) {
        reject()
      }
    })
  }

  function goToForgotPassword() {
    pushPath(`/${language}/forgot-password`)
  }

  function goToProfile(uName?: string) {
    if (process.env.REACT_APP_LOGGEDIN_DESTINATION) {
      pushPath(`/${language}/${process.env.REACT_APP_LOGGEDIN_DESTINATION}`)
    } else {
      pushPath(`/${language}/profile/${uName}`)
    }
  }

  function goToCheckValidationEmail(uName: string) {
    pushPath(`/${language}/check-validation-email/${uName}`)
  }

  return (
    <LoginBusiness
      login={login}
      checkNonce={checkNonce}
      logout={logout}
      goToForgotPassword={goToForgotPassword}
      goToProfile={goToProfile}
      goToCheckValidationEmail={goToCheckValidationEmail}
      emailUrl={email}
    />
  )
}
