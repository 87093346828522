import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import SvssFunctionTable, { RowDataT } from './SvssFunctionTable'

import { useAlert } from '@lemonbrain/hooks'

export default function SvssFunctionTableBusiness({
  isLoading,
svssFunctions,
  fetchMoreSvssFunctionEntries,
  refetchSvssFunctionEntries,
  goToSvssFunctionEntry,
  removeSvssFunctionEntry,
  queryError,
}: {
  isLoading: boolean
  svssFunctions: {
    id: string
    data: RowDataT
  }[]
  fetchMoreSvssFunctionEntries: () => void
  refetchSvssFunctionEntries: () => void
  goToSvssFunctionEntry: (id: string) => void
  removeSvssFunctionEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeSvssFunctionEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.svssFunctionTable.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchSvssFunctionEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.svssFunctionTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.svssFunctionTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.svssFunctionTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.svssFunctionTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<SvssFunctionTable
      isLoading={currentIsLoading}
      rows={svssFunctions}
      fetchMoreListItems={fetchMoreSvssFunctionEntries}
      editRow={goToSvssFunctionEntry}
      removeRow={removeRow}
    />
  )
}
