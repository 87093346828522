import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import MembershipEdit from './MembershipEdit'

import { useAppState } from '@lemonbrain/contexts'
import { prepStringWN } from '@lemonbrain/utils'
import { required, verifyFloat } from '@lemonbrain/utils'
import { useAlert } from '@lemonbrain/hooks'

export default function MembershipEditBusiness({
  values,
  isLoading,
  removeMembershipId,
  membershipId,
  updateMembershipEntry,
  createMembershipEntry,
  setValues,
}: {
  values: {
    de: string
    fr: string
    it: string
    prize: string
    directInvoicing: boolean
  }
  isLoading: boolean
  removeMembershipId: () => void
  membershipId?: string | null | undefined
  updateMembershipEntry: (data: {
    membershipId: string
    de: string
    fr: string
    it: string
    prize: number
    directInvoicing: boolean
  }) => void
  createMembershipEntry: (data: {
    de: string
    fr: string
    it: string
    prize: number
    directInvoicing: boolean
  }) => void
  setValues: React.Dispatch<
    React.SetStateAction<{
      de: string
      fr: string
      it: string
      prize: string
      directInvoicing: boolean
    }>
  >
}) {
  const intl = useIntl()
  const { user } = useAppState()
  const setAlert = useAlert()
  const [deError, setDeError] = useState<string>('')
  const [frError, setFrError] = useState<string>('')
  const [itError, setItError] = useState<string>('')
  const [prizeError, setPrizeError] = useState<string>('')

  const updateEntry = async () => {
    if (
      values &&
      membershipId &&
      validate() &&
      values.de &&
      values.fr &&
      values.it &&
      values.prize
    ) {
      if (user && user.uID && membershipId) {
        try {
          await updateMembershipEntry({
            membershipId: prepStringWN(membershipId) || membershipId,
            de: prepStringWN(values.de) || values.de,
            fr: prepStringWN(values.fr) || values.fr,
            it: prepStringWN(values.it) || values.it,
            prize: Number(values.prize) * 100,
            directInvoicing: !!values.directInvoicing
          })
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.membershipEdit.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'settings.membershipEdit.saved.updated',
                defaultMessage: 'Membership is updated',
              }),
              style: 'success',
            })
          updateCancel()
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.membershipEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'settings.membershipEdit.error.thereWasAnServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.membershipEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.membershipEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  const createEntry = async () => {
    if (
      values &&
      validate() &&
      values.de &&
      values.fr &&
      values.it &&
      values.prize
    ) {
      if (user && user.uID) {
        try {
          await createMembershipEntry({
            de: prepStringWN(values.de) || values.de,
            fr: prepStringWN(values.fr) || values.fr,
            it: prepStringWN(values.it) || values.it,
            prize: Number(values.prize) * 100,
            directInvoicing: !!values.directInvoicing,
          })
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.membershipEdit.saved',
                defaultMessage: 'Saved',
              }),
              label: intl.formatMessage({
                id: 'settings.membershipEdit.saved.created',
                defaultMessage: 'Membership is created',
              }),
              style: 'success',
            })
          updateCancel()
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'settings.membershipEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'settings.membershipEdit.error.thereWasAnServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'settings.membershipEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'settings.membershipEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  function updateCancel() {
    removeMembershipId()
    setValues({
      de: '',
      fr: '',
      it: '',
      prize: '',
      directInvoicing: false,
    })
  }

  function checkDe() {
    if (required(values.de)) {
      setDeError('')
      return false
    }
    setDeError(
      intl.formatMessage({
        id: 'settings.membershipEdit.error.deRequired',
        defaultMessage: 'Label (German) is required',
      })
    )
    return true
  }

  function checkFr() {
    if (required(values.fr)) {
      setFrError('')
      return false
    }
    setFrError(
      intl.formatMessage({
        id: 'settings.membershipEdit.error.frRequired',
        defaultMessage: 'Label (French) is required',
      })
    )
    return true
  }

  function checkIt() {
    if (required(values.it)) {
      setItError('')
      return false
    }
    setItError(
      intl.formatMessage({
        id: 'settings.membershipEdit.error.itRequired',
        defaultMessage: 'Label (Italian) is required',
      })
    )
    return true
  }

  function checkPrize() {
    if (required(values.prize) && verifyFloat(values.prize)) {
      setPrizeError('')
      return false
    }
    setPrizeError(
      intl.formatMessage({
        id: 'settings.membershipEdit.error.prizeRequired',
        defaultMessage: 'Price is required and needs to be a decimal',
      })
    )
    return true
  }

  function validate() {
    let generalError = false

    generalError = generalError || checkDe()
    generalError = generalError || checkFr()
    generalError = generalError || checkIt()
    generalError = generalError || checkPrize()

    return !generalError
  }

  useEffect(() => {
    if (values) {
      checkDe()
      checkFr()
      checkIt()
      checkPrize()
    }
  }, [values])

  return (
    <MembershipEdit
      onUpdateClick={updateEntry}
      onCopyClick={createEntry}
      onCancelClick={updateCancel}
      onCreateClick={createEntry}
      values={values}
      isLoading={isLoading}
      setValues={setValues}
      deError={deError}
      frError={frError}
      itError={itError}
      prizeError={prizeError}
      state={!!membershipId ? 'update' : 'create'}
    />
  )
}
