import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'

import WorkLevelEditData from './WorkLevelEdit/WorkLevelEdit.data'
import WorkLevelTableData from './WorkLevelTable/WorkLevelTable.data'


export default function WorkLevel({ 
  subNav,
  workLevelId,
  setWorkLevelId,
}: { 
  subNav: InnerRoute[]
  workLevelId: string | null | undefined
  setWorkLevelId: (workLevelId: string | undefined) => void
 }) {
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <Block>
              <Block>
                <Block
                  paddingTop={['15px', '15px', '15px', 0]}
                  overrides={{
                    Block: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                      },
                    },
                  }}
                >
                  <Block>
                    <Block
                      as='h3'
                      overrides={{
                        Block: {
                          style: ({ $theme }) => {
                            return {
                              ...$theme.typography.font450,
                              color: $theme.colors.primaryA,
                              marginBottom: '15px',
                            }
                          },
                        },
                      }}
                    >
                      <FormattedMessage
                        id='settings.workLevel'
                        defaultMessage={'Work level'}
                      />
                    </Block>
                  </Block>
                </Block>
              </Block>
            <WorkLevelEditData
              workLevelId={workLevelId}
              setWorkLevelId={setWorkLevelId}
              />
              <Block
                overrides={{
                  Block: {
                    style: () => {
                      return {
                        marginTop: '15px',
                        marginBottom: '15px',
                      }
                    },
                  },
                }}
              >
                <hr />
              </Block>
              {!workLevelId && (
                <WorkLevelTableData 
                setWorkLevelId={setWorkLevelId}
                 />
              )}
              </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
