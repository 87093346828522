const verifyEmail = function (value: string) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

const verifyLength = function (value: string, length: number) {
  if (value.toString().length >= length) {
    return true
  }
  return false
}

const required = function (value: any) {
  if (value && value.toString().length > 0) {
    return true
  }
  return false
}

const compare = function (string1: string, string2: string) {
  if (string1 === string2) {
    return true
  }
  return false
}

const verifyNumber = function (value: string) {
  const numberRex = new RegExp('^[0-9]+$')
  if (numberRex.test(value.toString())) {
    return true
  }
  return false
}

const verifyFloat = function (value: string) {
  const floatRex = /(^-?\d\d*\.\d\d*$)|(^-?\.\d\d*$)/
  if (floatRex.test(value.toString())) {
    return true
  }
  return false
}

const biggerOrEqualThen = function (value1: number, value2: number) {
  if (value1 >= value2) {
    return true
  }
  return false
}

const smallerOrEqualThen = function (value1: number, value2: number) {
  if (value1 <= value2) {
    return true
  }
  return false
}

const verifyUrl = function (value: string) {
  try {
    new URL(value)
    return true
  } catch (_) {
    return false
  }
}

const verifyDate = function (value: string) {
  try {
    new Date(value)
    return true
  } catch (_) {
    return false
  }
}

const verifyAhv = function (value: string) {
  return (
    parseInt(value.slice(-1), 10) === generateCheckDigit(value.slice(0, -1))
  )
}

function verifyIBAN(value: string) {
  let rearrange =
      value.substring(4, value.length)
      + value.substring(0, 4);
  let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
  let alphaMap: any = {};
  let number: string[] = [];

  alphabet.forEach((value, index) => {
      alphaMap[value] = index + 10;
  });

  rearrange.split('').forEach((value, index) => {
      number[index] = alphaMap[value] || value;
  });

  return modulo(number.join('').toString(), 97) === 1;
}

const verifyPostcheck = function (value: string) {
  const values = value.split('-');
  return (
    values.length === 3 &&
    parseInt(values[2]) === generatePostcheckCheck(values[0], values[1])
  )
}

//private
const modulo = (aNumStr: string, aDiv: number) => {
  var tmp: any = "";
  var i, r;
  for (i = 0; i < aNumStr.length; i++) {
      tmp += aNumStr.charAt(i);
      r = tmp % aDiv;
      tmp = r.toString();
  }
  return tmp / 1;
}

const generatePostcheckCheck = function(value1: string, value2: string) {
  const uebertrags = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5];
  let values2 = value2.split('');
  while(values2.length < 6) {
    values2 = ['0', ...values2];
  }
  const values = [...value1, ...values2];

  let currentUebertrag = 0;
  for(let key = 0; key < values.length; key++) {
    const valueString = values[key]
    currentUebertrag = uebertrags[(currentUebertrag + parseInt(valueString, 10)) % 10];
  }

  return (10 - currentUebertrag) % 10;
}

const generateCheckDigit = function (value: string) {
  let counter = 0
  const chars = value.split('')
  for (let key = (chars.length-1), count = 0; key >= 0; key--) {
    const valueString = chars[key]
    if (valueString !== '.') {
      const value = parseInt(valueString, 10)
      if (count % 2 !== 0) {
        counter += value
      } else {
        counter += 3 * value
      }
      count++
    }
  }
  return (10 - (counter % 10)) % 10
}

export {
  verifyEmail,
  verifyLength,
  verifyNumber,
  verifyFloat,
  verifyUrl,
  compare,
  required,
  biggerOrEqualThen,
  smallerOrEqualThen,
  verifyDate,
  verifyAhv,
  verifyPostcheck,
  verifyIBAN,
}
