import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import getReactApolloCients from '../../../../localReactApollo'
import {
  useAddSepaMutation,
  useDeleteSepaMutation,
} from '../../../../gql/sepa/Sepa.generated'
import { Maybe } from '../../../../types'

import SepaImportBusiness from './SepaImport.business'

export default function SepaImportData({
  invoicesTableDataRef,
}: {
  invoicesTableDataRef: React.MutableRefObject<
    | {
        refetchInvoices: () => void
      }
    | undefined
  >
}) {
  const [deleteOptionActivated, setDeleteOptionActivated] = useState('')
  const [lastUsedSepa, setLastUsedSepa] = useState<File | null>(null)
  const [errorMessages, setErrorMessages] = useState<Maybe<string>[]>([])
  const [successMessages, setSuccessMessages] = useState<Maybe<string>[]>([])
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  const [
    addSepaMutation,
    {
      data: dataUploadSepaMutation,
      loading: loadingUploadSepaMutation,
      error: errorUploadSepaMutation,
    },
  ] = useAddSepaMutation({
    //@ts-ignore
    client,
  })

  const [
    deleteSepaMutation,
    {
      data: dataDeleteSepaMutation,
      loading: loadingDeleteSepaMutation,
      error: errorDeleteSepaMutation,
    },
  ] = useDeleteSepaMutation({
    //@ts-ignore
    client,
  })

  function uploadSepa(sepa: File) {
    setDeleteOptionActivated('')
    setLastUsedSepa(sepa)
  }

  function deleteSepa() {
    setDeleteOptionActivated('')
    if (lastUsedSepa?.name) {
      setErrorMessages([])
      setSuccessMessages([])
      deleteSepaMutation({
        variables: {
          filename: lastUsedSepa?.name,
        },
      })
    }
  }

  useEffect(() => {
    if (lastUsedSepa) {
      setErrorMessages([])
      setSuccessMessages([])
      addSepaMutation({
        variables: {
          sepa: lastUsedSepa,
        },
      })
    }
  }, [lastUsedSepa])

  useEffect(() => {
    if (errorUploadSepaMutation || errorDeleteSepaMutation) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.sepaImport.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.sepaImport.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorUploadSepaMutation?.message || errorDeleteSepaMutation?.message
      )
    }
  }, [errorUploadSepaMutation, errorDeleteSepaMutation])

  useEffect(() => {
    if (dataUploadSepaMutation?.addSepa) {
      switch (dataUploadSepaMutation?.addSepa.errorCode) {
        case 0:
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.sepaImport.success',
                defaultMessage: 'Success',
              }),
              label: intl.formatMessage({
                id: 'invoices.sepaImport.sepaImported',
                defaultMessage: 'SEPA imported',
              }),
              style: 'success',
            })
          setErrorMessages(dataUploadSepaMutation.addSepa.errorMessages)
          setSuccessMessages(dataUploadSepaMutation.addSepa.successMessages)
          invoicesTableDataRef?.current?.refetchInvoices()
          break
        case 1:
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.sepaImport.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage(
                {
                  id: 'invoices.sepaImport.fileAlreadyExist',
                  defaultMessage:
                    'There is already an SEPA file imported with this filename: {filename}',
                },
                {
                  filename: lastUsedSepa?.name,
                }
              ),
              style: 'error',
            })
          setDeleteOptionActivated(lastUsedSepa?.name || '')
          break
        default:
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.sepaImport.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'invoices.sepaImport.networkError',
                defaultMessage: 'We had network problem. Please try again',
              }),
              style: 'error',
            })
      }
    }
  }, [dataUploadSepaMutation])

  useEffect(() => {
    if (dataDeleteSepaMutation?.deleteSepa) {
      if (dataDeleteSepaMutation.deleteSepa.errorCode === 0) {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'invoices.sepaImport.success',
              defaultMessage: 'Success',
            }),
            label: intl.formatMessage({
              id: 'invoices.sepaImport.sepaRemoved',
              defaultMessage: 'SEPA removed',
            }),
            style: 'success',
          })
        setErrorMessages(dataDeleteSepaMutation.deleteSepa.errorMessages)
        setSuccessMessages(dataDeleteSepaMutation.deleteSepa.successMessages)
        invoicesTableDataRef?.current?.refetchInvoices()
      }
    }
  }, [dataDeleteSepaMutation])

  return (
    <SepaImportBusiness
      importLoader={loadingUploadSepaMutation}
      deleteLoader={loadingDeleteSepaMutation}
      uploadSepa={uploadSepa}
      deleteOptionActivated={deleteOptionActivated}
      deleteSepa={deleteSepa}
      errorMessages={errorMessages}
      successMessages={successMessages}
    />
  )
}
