import { useIntl } from 'react-intl'
import { Block } from 'baseui/block'
import { FormControl } from 'baseui/form-control'
import { Select, Value } from 'baseui/select'

import Loader from '@lemonbrain/components/UiElements/Loader/Loader'
import {
    getLanguageOptions,
} from '@lemonbrain/utils'

export default function EditLanguage({
    language,
    setLanguage,
    isLoading,
}: {
    language: string
    setLanguage: React.Dispatch<React.SetStateAction<string>>
    isLoading: boolean,
}) {
    const intl = useIntl()

    return isLoading ? (
        <Loader />
    ) : (
        <Block as="p"
            overrides={{
                Block: {
                    style: ({ $theme }) => {
                        return {
                            color: $theme.colors.primaryA,
                        }
                    },
                },
            }}>

            <FormControl
                label={intl.formatMessage({
                    id: 'auth.editLanguage.language',
                    defaultMessage: 'Language',
                })}
            >
                <Select
                    options={getLanguageOptions(intl)}
                    value={
                        ((getLanguageOptions(intl).find(
                            (item) => item.id === language,
                        ) as unknown) as Value) || undefined
                    }
                    placeholder={intl.formatMessage({
                        id: 'auth.editLanguage.editSavesDirectly',
                        defaultMessage: 'Edit saves directly',
                    })}
                    onChange={({ value }: { value: Value }) => {
                        setLanguage(value[0].id as string)
                    }}
                />
            </FormControl>
        </Block>
    )
}
