import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { QueryLazyOptions } from '@apollo/client'

import { useAlert } from '@lemonbrain/hooks'
import { useAppState } from '@lemonbrain/contexts'
import { prepStringWN } from '@lemonbrain/utils'
import { required, verifyDate, verifyEmail } from '@lemonbrain/utils'

import NewslettersEdit from './NewslettersEdit'
import { NewsletterItem } from './NewslettersEdit.data'
import {
  UpdateNewsletterMailingMutationVariables,
  UpdateNewsletterMailingItemMutationVariables,
} from '../../../gql/NewsletterMailing.generated'
import * as Types from '../../../types'

export default function NewslettersEditBusiness({
  goToNewsletter,
  newsletterId,
  loading,
  updateNewsletterMailingEntry,
  newsletterMailing,
  setNewsletterMailing,
  getNewsletterMailaddresses,
  mailaddresses,
  newsletters,
}: {
  goToNewsletter: (id: string | null) => void
  newsletterId?: string
  loading: boolean
  updateNewsletterMailingEntry(
    data: UpdateNewsletterMailingMutationVariables
  ): Promise<void>
  newsletterMailing: UpdateNewsletterMailingMutationVariables & {
    toJoined: string
    mainImageSrc: string
    items: (UpdateNewsletterMailingItemMutationVariables & {
      imageSrc: string
      task: Types.ArrayUpdate
    })[]
  }
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: Types.ArrayUpdate
        })[]
      }
    >
  >
  getNewsletterMailaddresses: (
    options?:
      | QueryLazyOptions<
          Types.Exact<{
            id: string
          }>
        >
      | undefined
  ) => void
  mailaddresses: string[] | []
  newsletters?: NewsletterItem[]
}) {
  const [errors, setErrors] = useState<{
    from: string
    fromName: string
    to: string
    subject: string
    mailingDate: string
    mainImage: string
    mainImageCopyright: string
    title: string
    content: string
    leftColoumn: string
    rightColoumn: string
  }>({
    from: '',
    fromName: '',
    to: '',
    subject: '',
    mailingDate: '',
    mainImage: '',
    mainImageCopyright: '',
    title: '',
    content: '',
    leftColoumn: '',
    rightColoumn: '',
  })
  const intl = useIntl()
  const setAlert = useAlert()
  const { user } = useAppState()
  const [selectedNewsletter, setSelectedNewsletter] = useState<
    NewsletterItem | undefined
  >()
  const [rejectedFiles, setRejectedFiles] = useState<File[]>()

  function cancelClickHandler() {
    goToNewsletter(null)
  }

  function copyClickHandler() {
    updateClickHandler(true)
  }

  async function updateClickHandler(copy = false) {
    copy = typeof copy === 'boolean' ? copy : false
    if (newsletterMailing) {
      if (user && user.uID) {
        try {
          let variables: UpdateNewsletterMailingMutationVariables = {
            id: !copy ? newsletterMailing.id : '',
            newsletterId: newsletterMailing.newsletterId,
            items: newsletterMailing.items.map((item, index) => ({
              id: item?.id || '',
              image: item?.image,
              imageId: item?.imageId,
              imageCopyright: item?.imageCopyright,
              title: item?.title,
              content: item?.content,
              link: item?.link,
              orderNr: index,
              task: item?.task || Types.ArrayUpdate.Update,
            })),
            from: prepStringWN(newsletterMailing.from) || '',
            fromName: prepStringWN(newsletterMailing.fromName) || '',
            to: newsletterMailing.to,
            subject: !copy
              ? prepStringWN(newsletterMailing.subject) || ''
              : prepStringWN(newsletterMailing.subject) +
                ' ' +
                intl.formatMessage(
                  {
                    id: 'newsletters.newslettersEdit.copyOf',
                    defaultMessage: 'copy of {newsletterId}',
                  },
                  {
                    newsletterId: newsletterMailing.id,
                  }
                ),
            mailingDate: newsletterMailing.mailingDate,
            mainImage: newsletterMailing.mainImage,
            mainImageId: newsletterMailing.mainImageId,
            mainImageCopyright:
              prepStringWN(newsletterMailing.mainImageCopyright) || '',
            title: prepStringWN(newsletterMailing.title) || '',
            content: prepStringWN(newsletterMailing.content) || '',
            leftColoumn: prepStringWN(newsletterMailing.leftColoumn) || '',
            rightColoumn: prepStringWN(newsletterMailing.rightColoumn) || '',
            attachment: newsletterMailing.attachment,
            showOnWebsite: newsletterMailing.showOnWebsite,
          }

          await updateNewsletterMailingEntry(variables)
        } catch (e) {
          console.error(e)
          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'newsletters.newslettersEdit.error',
                defaultMessage: 'Error',
              }),
              label: intl.formatMessage({
                id: 'newsletters.newslettersEdit.error.thereWasAServerError',
                defaultMessage: 'There was an server error, try again',
              }),
              style: 'error',
            })
        }
      } else {
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'newsletters.newslettersEdit.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage({
              id: 'newsletters.newslettersEdit.error.notLoggedIn',
              defaultMessage: 'You are not anymore logged in',
            }),
            style: 'error',
          })
      }
    }
  }

  function checkFrom() {
    if (
      required(newsletterMailing.from) &&
      verifyEmail(newsletterMailing.from)
    ) {
      setErrors((prev) => ({
        ...prev,
        from: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      from: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.fromRequired',
        defaultMessage: 'From is required',
      }),
    }))
    return true
  }

  function checkFromName() {
    if (
      required(newsletterMailing.fromName)
    ) {
      setErrors((prev) => ({
        ...prev,
        fromName: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      fromName: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.fromNameRequired',
        defaultMessage: 'From name is required',
      }),
    }))
    return true
  }

  function checkTo() {
    if (
      required(newsletterMailing.toJoined) &&
      newsletterMailing.to.length > 0
    ) {
      setErrors((prev) => ({
        ...prev,
        to: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      to: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.toRequired',
        defaultMessage: 'To is required',
      }),
    }))
    return true
  }

  function checkSubject() {
    if (required(newsletterMailing.subject)) {
      setErrors((prev) => ({
        ...prev,
        subject: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      subject: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.subjectRequired',
        defaultMessage: 'Subject is required',
      }),
    }))
    return true
  }

  function checkMailingDate() {
    if (
      newsletterMailing?.mailingDate &&
      verifyDate(newsletterMailing?.mailingDate)
    ) {
      setErrors((prev) => ({
        ...prev,
        mailingDate: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      mailingDate: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.mailingDateRequired',
        defaultMessage: 'Mailing date is required',
      }),
    }))
    return true
  }

  function checkTitle() {
    if (!newsletterMailing.id || required(newsletterMailing.title)) {
      setErrors((prev) => ({
        ...prev,
        title: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      title: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.titleRequired',
        defaultMessage: 'Title is required',
      }),
    }))
    return true
  }

  function checkContent() {
    if (!newsletterMailing.id || required(newsletterMailing.content)) {
      setErrors((prev) => ({
        ...prev,
        content: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      content: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.contentRequired',
        defaultMessage: 'Content is required',
      }),
    }))
    return true
  }

  function checkMainImage() {
    if (
      !newsletterMailing.id ||
      required(newsletterMailing.mainImage) ||
      required(newsletterMailing.mainImageSrc)
    ) {
      setErrors((prev) => ({
        ...prev,
        mainImage: '',
      }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      mainImage: intl.formatMessage({
        id: 'newsletters.newslettersEdit.error.mainImageRequired',
        defaultMessage: 'Image is required',
      }),
    }))
    return true
  }

  useEffect(() => {
    if (rejectedFiles && rejectedFiles?.length > 0) {
      setErrors((prev) => ({
        ...prev,
        mainImage: intl.formatMessage(
          {
            id: 'newsletters.newslettersEdit.rejectFormats',
            defaultMessage: 'Following files were rejected: {files}',
          },
          {
            files: rejectedFiles.map((item) => item.name).join(', '),
          }
        ),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        mainImage: '',
      }))
    }
  }, [rejectedFiles])

  useEffect(() => {
    checkFrom()
    checkFromName()
    checkTo()
    checkSubject()
    checkMailingDate()
    checkTitle()
    checkContent()
    checkMainImage()
  }, [newsletterMailing])

  useEffect(() => {
    if (
      !selectedNewsletter &&
      newsletters &&
      newsletters.length > 0 &&
      newsletterMailing.newsletterId
    ) {
      setSelectedNewsletter(
        newsletters?.find((item) => item.id === newsletterMailing.newsletterId)
      )
    }
  }, [newsletters, newsletterMailing])

  useEffect(() => {
    if (selectedNewsletter && parseInt(selectedNewsletter.id) > 0) {
      setNewsletterMailing((prev) => ({
        ...prev,
        newsletterId: selectedNewsletter.id,
      }))
      getNewsletterMailaddresses({
        variables: {
          id: selectedNewsletter.id.toString(),
        },
      })
    }
  }, [selectedNewsletter])

  useEffect(() => {
    if (mailaddresses.length > 0 && selectedNewsletter) {
      if (
        selectedNewsletter.id !== newsletterMailing.newsletterId ||
        newsletterMailing.to.length === 0 || newsletterMailing.toJoined === ''
      ) {
        setNewsletterMailing((prev) => ({
          ...prev,
          to: mailaddresses,
          toJoined: mailaddresses.join(', ') || '',
        }))
      }
    }
  }, [mailaddresses])

  return (
    <NewslettersEdit
      goToNewsletter={goToNewsletter}
      newsletterId={newsletterId}
      errors={errors}
      onCancelClick={cancelClickHandler}
      isLoading={loading}
      onCopyClick={copyClickHandler}
      onUpdateClick={updateClickHandler}
      newsletterMailing={newsletterMailing}
      setNewsletterMailing={setNewsletterMailing}
      newsletters={newsletters}
      selectedNewsletter={selectedNewsletter}
      setSelectedNewsletter={setSelectedNewsletter}
      setRejectedFiles={setRejectedFiles}
    />
  )
}
