import React, { useEffect, useCallback, useState } from 'react'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'react-intl'

import { UserType } from '@lemonbrain/hooks'
import Container from '@lemonbrain/components/UiElements/Container/Container'
import {
    ButtonGroup,
} from '@lemonbrain/components/PageStyles/Settings.styled'

interface iFormInput {
    email: string
    password: string
}

export default function Login ({
    onSubmit,
    onLogoutSubmit,
    isLoading,
    user,
    onForgotPasswortClick,
    emailUrl
}: {
    onSubmit: ({ email, password }: { email: string, password: string }) => Promise<boolean>,
    onLogoutSubmit: () => Promise<boolean>,
    isLoading: boolean,
    user?: UserType,
    onForgotPasswortClick?: (a: React.SyntheticEvent<HTMLButtonElement>) => unknown,
    emailUrl?: string,
}) {
    const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm<iFormInput>()
    const [isNotLoggedIn, setIsNotLoggedIn] = useState(!user || !user.uID)
    const intl = useIntl()

    useEffect(() => {
        register('email',{
            required: true,
            pattern: /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        })
        register('password',{
            required: true,
            minLength: 6
        })
        setValue('email', emailUrl || '')
    }, [register])

    const submitLogoutHandler = useCallback(async () => {
        let state = false
        state = await onLogoutSubmit()
        return state
    }, [onLogoutSubmit])

    const onSubmitCalled = async ({ email, password }: iFormInput) => {
        const state = await onSubmit({ email, password })
        if (!state) {
            setError('email', {
                type: "manual",
                message: intl.formatMessage({ id: 'auth.login.error.notLoggedInUsernamePasswordWrong', defaultMessage: 'We could not log you in. Username or Password wrong' }),
            })
            setError('password', {
                type: "manual",
                message: intl.formatMessage({ id: 'auth.login.error.notLoggedInUsernamePasswordWrong', defaultMessage: 'We could not log you in. Username or Password wrong' }),
            })
        }
    }

    useEffect(() => {
        setIsNotLoggedIn(!user || !user.uID)
    }, [user])

    return (

        <Container>
            <Block
                overrides={{
                    Block: {
                        style: {
                            minHeight: 'calc(100vh - 213px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    },
                }}
            >
                {isNotLoggedIn
                    ?
                    (
                        <form onSubmit={handleSubmit(onSubmitCalled)} style={{ width: '100%' }}>
                            <Block
                                as="h1"
                                overrides={{
                                    Block: {
                                        style: ({ $theme }) => {
                                            return {
                                                ...$theme.typography.font1250,
                                                color: '#fff',
                                                fontWeight: 700,
                                                marginBottom: '30px',
                                            }
                                        },
                                    },
                                }}
                            >
                                <FormattedMessage
                                    id='auth.login.signIn'
                                    defaultMessage={'Sign In'}
                                />
                            </Block>
                            <Block marginBottom="30px">
                                <FormControl
                                    label={intl.formatMessage({ id: 'auth.login.emailAddress', defaultMessage: 'Email Address' })}
                                    caption="johndoe@demo.io"
                                    error={errors.email && (errors.email.message ? errors.email.message : intl.formatMessage({ id: 'auth.login.error.validEmailAddress', defaultMessage: 'Please enter a valid Email Address' }))}
                                >
                                    <Input
                                        name="email"
                                        required
                                        pattern='^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$'
                                        overrides={{
                                            InputContainer: {
                                                style: () => {
                                                    return { backgroundColor: 'transparent' }
                                                },
                                            },
                                        }}
                                        onChange={(event) => { setValue('email', event.currentTarget.value) }}
                                    />
                                </FormControl>
                            </Block>
                            <Block marginBottom="30px">
                                <FormControl
                                    label={intl.formatMessage({ id: 'auth.login.password', defaultMessage: 'Password' })}
                                    error={errors.password && (errors.password.message ? errors.password.message : intl.formatMessage({ id: 'auth.login.error.password', defaultMessage: 'Please enter a Password width at least 6 signs' }))}
                                >
                                    <Input
                                        name="password"
                                        type="password"
                                        required
                                        min={6}
                                        overrides={{
                                            InputContainer: {
                                                style: () => {
                                                    return { backgroundColor: 'transparent' }
                                                },
                                            },
                                        }}
                                        onChange={(event) => { setValue('password', event.currentTarget.value) }}
                                    />
                                </FormControl>
                            </Block>
                            <ButtonGroup>
                                <Button type="submit" isLoading={isLoading}>
                                    <FormattedMessage
                                        id='auth.login.signIn'
                                        defaultMessage={'Login'}
                                    />  </Button>
                                <Button kind="tertiary" type="button" onClick={onForgotPasswortClick}>
                                    <FormattedMessage
                                        id='auth.login.forgotPassword'
                                        defaultMessage={'Forgot password'}
                                    />
                                </Button>
                            </ButtonGroup>
                        </form>
                    ) : (
                        <form onSubmit={async (event) => {
                            event.preventDefault()
                            await submitLogoutHandler()
                        }} >
                            <Block
                                as="h1"
                                overrides={{
                                    Block: {
                                        style: ({ $theme }) => {
                                            return {
                                                ...$theme.typography.font1250,
                                                color: '#fff',
                                                fontWeight: 700,
                                                marginBottom: '30px',
                                            }
                                        },
                                    },
                                }}
                            >
                                <FormattedMessage
                                    id='auth.login.logout'
                                    defaultMessage={'Logout'}
                                />
                            </Block>
                            <Button type="submit" isLoading={isLoading}>
                                <FormattedMessage
                                    id='auth.login.logout'
                                    defaultMessage={'Logout'}
                                />
                            </Button>
                        </form>

                    )}
            </Block>
        </Container>
    )
}
