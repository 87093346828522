import { Button, SIZE as BTNSIZE, KIND as BTNKIND } from "baseui/button"
import { Check, Delete } from "baseui/icon"
import { StyledBodyCell } from "baseui/table-grid"
import { Tag, VARIANT, KIND } from "baseui/tag"
import { Textarea } from "baseui/textarea"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import DocumentIcon from '../../../../icons/DocumentIcon'
import SendMailingIcon from "../../../../icons/SendMailingIcon"
import SentMailingIcon from "../../../../icons/SentMailingIcon"
import { SignUpState } from "../../../../../../invoices/src/types"
import ConfirmRegistrationButton from "../ConfirmRegistrationButton/ConfirmRegistrationButton"
import { useStyletron } from "baseui"
import { useHistoryPush } from "@lemonbrain/hooks"
import { getTranslation } from '@lemonbrain/utils'
import { useParams } from "react-router-dom"
import { Input } from "baseui/input"
import { CourseFragmentFragment } from "../../../../gql/Courses.generated"

export default function CourseRegistrationRow({
  course,
  row,
  sendMailing,
  confirmRegistration,
  unregister,
  saveComment,
  saveFinalPrice,
  loading
}: {
  course: CourseFragmentFragment
  row: {
    id: string
    participant: any
    createdAt: string
    signUpStatus: string
    invoices?: any[] | null
    comment?: string | null
    finalPrice: number
  }
  sendMailing: (id: string) => void
  confirmRegistration: (id: string) => void
  unregister: (registraionId: string) => Promise<void>
  saveComment: (id: string, comment: string) => void
  saveFinalPrice: (id: string, finalPrice: number) => void
  loading: boolean
}) {
  const intl = useIntl()
  const [css] = useStyletron()
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()

  const [comment, setComment] = React.useState(row.comment || '')
  const [finalPrice, setFinalPrice] = React.useState((row.finalPrice / 100).toFixed(2))

  return (
    <React.Fragment key={row?.id}>
      <div role="row" className={css({ display: 'contents' })}>
        <StyledBodyCell>
          <strong>
            {row.participant?.addresses?.[0]?.firstName} {row.participant?.addresses?.[0]?.lastName}
          </strong>
          <br />
          ({row.participant?.email}, {getTranslation({
                              de: row.participant?.state?.name?.de || '',
                              fr: row.participant?.state?.name?.fr || '',
                              it: row.participant?.state?.name?.it || ''
                            }, intl) || ''})
        </StyledBodyCell>
        <StyledBodyCell>
          {new Date(row.createdAt!).toLocaleDateString('de-CH')}
          {' - '}
          {new Date(row.createdAt!).toLocaleTimeString('de-CH', { timeStyle: 'short' })}
          {' Uhr'}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.signUpStatus === SignUpState.Payed && (
            <Tag kind={KIND.green} closeable={false} variant={VARIANT.solid}>
              <FormattedMessage
                id='courses.registrations.status.payed'
                defaultMessage={SignUpState.Payed}
              />
            </Tag>
          )}
          {row.signUpStatus === SignUpState.Confirmed && (
            <Tag kind={KIND.green} closeable={false}>
              <FormattedMessage
                id='courses.registrations.status.confirmed'
                defaultMessage={SignUpState.Confirmed}
              />
            </Tag>
          )}
          {row.signUpStatus === SignUpState.WaitingList && (
            <Tag kind={KIND.yellow} closeable={false}>
              <FormattedMessage
                id='courses.registrations.status.waitingList'
                defaultMessage={'waiting list'}
              />
            </Tag>
          )}
          {row.signUpStatus === SignUpState.Unconfirmed && (
            <Tag kind={KIND.neutral} closeable={false}>
              <FormattedMessage
                id='courses.registrations.status.unconfirmed'
                defaultMessage={SignUpState.Unconfirmed}
              />
            </Tag>
          )}
          {row.signUpStatus === SignUpState.Cancelled && (
            <Tag kind={KIND.neutral} closeable={false} disabled>
              <FormattedMessage
                id='courses.registrations.status.cancelled'
                defaultMessage={SignUpState.Cancelled}
              />
            </Tag>
          )}
          {(row.invoices?.at(0)?.reminderMailing && (
            <Tag
              kind={KIND.yellow}
              closeable={false}
              title={
                intl.formatMessage({
                  id: 'courses.registrations.status.remindedTitle',
                  defaultMessage: 'Reminder sent'
                })
              }
            >
              <FormattedMessage
                id='courses.registrations.status.reminded'
                defaultMessage={'!'}
              />
            </Tag>
          ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.invoices?.map((invoice) => {
            return (
              <React.Fragment key={invoice?.id}>
                <Button
                  onClick={() => pushPath(`/${language}/invoices/overview/${invoice?.id}`)}
                  size={BTNSIZE.compact}
                  kind={BTNKIND.secondary}
                  disabled={invoice?.mailing?.state === 'completed'}
                >
                  {'Fr. ' + ((invoice?.total??0)/100).toFixed(2)}
                  {/* <InvoiceIcon /> */}
                </Button>
                {' '}
                <Button
                  onClick={() => sendMailing(invoice?.mailing?.id || '')}
                  size={BTNSIZE.compact}
                  kind={BTNKIND.secondary}
                  disabled={invoice?.mailing?.state === 'completed'}
                >
                  {invoice?.mailing?.state === 'completed' && <SentMailingIcon />}
                  {invoice?.mailing?.state !== 'completed' && <SendMailingIcon />}
                </Button>
                {' '}
                <Button
                  onClick={() => window?.open(process.env.REACT_APP_MITGLIEDER_URL + '/application/files/invoices/Rechnung_' + invoice?.invoiceNumber + '.pdf', '_blank')?.focus()}
                  size={BTNSIZE.compact}
                  kind={BTNKIND.secondary}
                >
                  <DocumentIcon />
                </Button>
              </React.Fragment>
            )
          })}
          {row.signUpStatus === SignUpState.Unconfirmed &&
            <div className={css({ display: "flex" })}>
              <Input
                value={finalPrice}
                onChange={e => setFinalPrice(e.target.value)}
                startEnhancer={() => "Fr."}
              />
              <Button
                size={BTNSIZE.compact}
                onClick={() => saveFinalPrice(row.id!, parseInt(finalPrice) * 100)}
                disabled={parseInt(finalPrice) * 100 === row.finalPrice}
                title={intl.formatMessage({
                  id: 'courses.registrations.saveFinalPrice',
                  defaultMessage: 'save final Price',
                })}
              >
                <Check  />
              </Button>
            </div>}
        </StyledBodyCell>
        <StyledBodyCell>
          {(
            course.status === 'confirmed' &&
            (row.signUpStatus === SignUpState.Unconfirmed ||
            row.signUpStatus === SignUpState.WaitingList)
          ) && (
            <ConfirmRegistrationButton
              icon={<Check title={intl.formatMessage({
                id: 'courses.registrations.confirm',
                defaultMessage: 'confirm pending',
              })} />}
              confirmRegistration={confirmRegistration}
              itemId={row.id!}
            />
          )}

          <Button
            size={BTNSIZE.compact}
            onClick={() => unregister(row.id!)}
            isLoading={loading}
          >
            <Delete title={intl.formatMessage({
              id: 'courses.registrations.cancel',
              defaultMessage: 'Cancel'
            })} />
          </Button>
        </StyledBodyCell>
      </div>
      <div role="row" className={css({ display: 'contents' })} key={row.id}>
        <StyledBodyCell></StyledBodyCell>
        <StyledBodyCell $gridColumn={`span 3`}>
          <Textarea
            value={ comment || ''}
            rows={1}
            onChange={(event) => {
              setComment(event.currentTarget.value)
            }}
            overrides={{
              Input: {
                style: {
                  resize: 'vertical',
                },
              },
            }}
          />
        </StyledBodyCell>
        <StyledBodyCell>
          <Button
            size={BTNSIZE.mini}
            onClick={() => saveComment(row.id!, comment || '')}
            disabled={comment === row.comment}
          >
            <FormattedMessage
              id='courses.registrations.saveComment'
              defaultMessage={'save comment'}
            />
          </Button>
        </StyledBodyCell>
      </div>
    </React.Fragment>
  )
}
