import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { priceFormater } from '@lemonbrain/utils'

import AssociationEditBusiness from './AssociationEdit.business'
import getReactApolloCients from '../../../localReactApollo'
import {
  UpdateAssociationIfMemberMutationVariables,
  AddAssociationMutationVariables,
  useGetAssociationLazyQuery,
  useUpdateAssociationMutation,
  useAddAssociationMutation,
} from '../../../gql/Association.generated'

export default function AssociationEditData({
  goToAssociation,
  associationId,
}: {
  goToAssociation: (id: string | null) => void
  associationId?: string
}) {
  const intl = useIntl()
  const [association, setAssociation] = useState<
    UpdateAssociationIfMemberMutationVariables & { prizeString: string, imageSrc: string }
  >({
    id: '',
    name: {
      de: '',
      fr: '',
      it: '',
    },
    short: {
      de: '',
      fr: '',
      it: '',
    },
    associationURL: '',
    isStufenvereinigung: false,
    image: null,
    imageSrc: '',
    prize: 0,
    prizeString: '0',
    needsAComment: false,
    defaultComment: '',
    // membersCalculationAt: undefined,
    billingInformation: {
      firstName: '',
      lastName: '',
      firmName: '',
      mailbox: '',
      streetNr: '',
      postCode: 0,
      place: '',
      canton: '',
      country: '',
      account: '',
      iban: '',
      qrIban: '',
      besrId: null,
    },
  })
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  const [getAssociation, {
    data: dataAssociation,
    loading: loadingAssociation,
    error: errorAssociation,
    refetch: refetchAssociation,
  }] = useGetAssociationLazyQuery({
    variables: {
      id: associationId || '',
    },
    //@ts-ignore
    client,
    skip: !associationId,
  })
  const [
    updateAssociationMutation,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useUpdateAssociationMutation({
    //@ts-ignore
    client,
  })
  const [
    addAssociationMutation,
    { loading: loadingAdd, error: errorAdd },
  ] = useAddAssociationMutation({
    //@ts-ignore
    client,
  })

  async function updateAssociationEntry(
    data: UpdateAssociationIfMemberMutationVariables
  ) {
    if (data.id && associationId) {
      await updateAssociationMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    } else {
      let variables: AddAssociationMutationVariables & {
        id?: string
      } = data as AddAssociationMutationVariables
      delete variables.id
      await addAssociationMutation({
        variables,
        //@ts-ignore
        client,
      })
    }
  }

  useEffect(() => {
    if (dataAssociation?.getAssociation) {
      setAssociation({
        id: dataAssociation.getAssociation.id,
        name: {
          de: dataAssociation.getAssociation.name.de,
          fr: dataAssociation.getAssociation.name.fr,
          it: dataAssociation.getAssociation.name.it,
        },
        short: {
          de: dataAssociation.getAssociation.short.de,
          fr: dataAssociation.getAssociation.short.fr,
          it: dataAssociation.getAssociation.short.it,
        },
        associationURL: dataAssociation.getAssociation.associationURL||'',
        isStufenvereinigung: dataAssociation.getAssociation.isStufenvereinigung,
        imageId: dataAssociation.getAssociation.imageId,
        imageSrc: dataAssociation.getAssociation.imageSrc||'',
        prize: dataAssociation.getAssociation.prize,
        prizeString: priceFormater(dataAssociation.getAssociation.prize / 100),
        needsAComment: dataAssociation.getAssociation.needsAComment,
        defaultComment: dataAssociation.getAssociation.defaultComment,
        // membersCalculationAt:
        // dataAssociation.getAssociation.membersCalculationAt,
        billingInformation: {
          firstName:
            dataAssociation.getAssociation?.billingInformation?.firstName || '',
          lastName:
            dataAssociation.getAssociation?.billingInformation?.lastName || '',
          firmName:
            dataAssociation.getAssociation?.billingInformation?.firmName || '',
          mailbox:
            dataAssociation.getAssociation?.billingInformation?.mailbox || '',
          streetNr:
            dataAssociation.getAssociation?.billingInformation?.streetNr || '',
          postCode:
            dataAssociation.getAssociation?.billingInformation?.postCode || 0,
          place:
            dataAssociation.getAssociation?.billingInformation?.place || '',
          canton:
            dataAssociation.getAssociation?.billingInformation?.canton || '',
          country:
            dataAssociation.getAssociation?.billingInformation?.country || '',
          account:
            dataAssociation.getAssociation?.billingInformation?.account || '',
          iban: dataAssociation.getAssociation?.billingInformation?.iban || '',
          qrIban:
            dataAssociation.getAssociation?.billingInformation?.qrIban || '',
          besrId: dataAssociation.getAssociation?.billingInformation?.besrId,
        },
      })
    }
  }, [dataAssociation])

  useEffect(() => {
    if(associationId) {
      getAssociation()
    }
  }, [associationId])

  useEffect(() => {
    if (!loadingUpdate) {
      refetchAssociation && refetchAssociation()
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (errorAssociation || errorUpdate || errorAdd) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'association.associationEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'association.associationEdit.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorAssociation?.message || errorUpdate?.message || errorAdd?.message
      )
    }
  }, [errorAssociation, errorUpdate, errorAdd])

  return (
    <AssociationEditBusiness
      goToAssociation={goToAssociation}
      loading={loadingAssociation || loadingUpdate || loadingAdd}
      updateAssociationEntry={updateAssociationEntry}
      association={association}
      setAssociation={setAssociation}
      associationId={associationId}
    />
  )
}
