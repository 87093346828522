import { useParams } from "react-router-dom"

import {useHistoryPush} from '@lemonbrain/hooks'

import ValidateEmailBusiness from './ValidateEmail.business'

import { getApolloClientAuth } from '../../react-apollo'
import { useValidateEmailMutation } from '../../gql/Auth.generated'

export default function ValidateEmailData () {
    const { language } = useParams<{ language: string }>()
    const { token } = useParams<{ token: string }>()
    const pushPath = useHistoryPush()
    const clientAuth = getApolloClientAuth()

    const [validateEmailMutation] = useValidateEmailMutation({
        client: clientAuth,
    })

    async function validateEmail (reCaptchaToken: string) {
        return new Promise<"is-valid">(async (resolve, reject) => {
            try {
                const result = await validateEmailMutation({ variables: { token: token || '', reCaptchaToken } })

                if (result.data?.validateEmail) {
                    resolve('is-valid')
                } else {
                    reject()
                }
            } catch (e) {
                reject(e)
            }
            reject()
        })
    }
    function goToSignIn () {
        pushPath(`/${language}/login`)
    }

    return (
        <ValidateEmailBusiness
            validateEmail={validateEmail}
            goToSignIn={goToSignIn}
        />
    )
}
