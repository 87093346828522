import React from 'react'
import Sticky from 'react-stickynode'
import Logo from '../../UiElements/Logo/Logo'
import SvgIcon from '../../UiElements/SvgIcon/SvgIcon'
import Container from '../../UiElements/Container/Container'
import TopMenu from '../HeaderMenu/TopMenu/TopMenu'
import MainMenu from '../HeaderMenu/MainMenu/MainMenu'
import AvatarMenu from '../HeaderMenu/AvatarMenu/AvatarMenu'
import HeaderWrapper, {
  TopBar,
  MenuRight,
  Navbar,
  StickyNav,
  NavLeft,
  NavRight,
} from './DefaultHeader.styled'

import {
  useThemeSwitcherCtx,
  THEME,
} from '@lemonbrain/contexts/theme/theme.provider'
import { useAppState } from '@lemonbrain/contexts/PageWrapper'

const DefaultHeader: React.FC<{}> = () => {
  const { theme, logo, logoColor, avatar } = useThemeSwitcherCtx()
  const { user } = useAppState()

  return (
    <HeaderWrapper className='default'>
      <Container>
        <TopBar className='top-bar'>
          <Logo
            path='/'
            src={
              theme === THEME.dark ? (
                <SvgIcon
                  src={logo}
                  style={{ width: '476px', height: '96px' }}
                />
              ) : (
                <SvgIcon
                  src={logoColor}
                  style={{ width: '476px', height: '96px' }}
                />
              )
            }
          />
          <MenuRight className='menu-right'>
            <TopMenu />
            <AvatarMenu
              name={user && user.uName}
              src={(user && user.uAvatar) || avatar}
            />
          </MenuRight>
        </TopBar>
      </Container>

      <Sticky>
        <Navbar className='navbar'>
          <Container>
            <StickyNav>
              <NavLeft>
                <Logo
                  path='/'
                  src={
                    <SvgIcon
                      src={logo}
                      style={{ width: '238px', height: '48px', marginTop: '15px', marginBottom: '15px' }}
                    />
                  }
                />
                <MainMenu className='main-nav' />
              </NavLeft>
              <NavRight className='cart-and-avatar'>
                <AvatarMenu
                  name={user && user.uName}
                  src={(user && user.uAvatar) || avatar}
                />
              </NavRight>
            </StickyNav>
          </Container>
        </Navbar>
      </Sticky>
    </HeaderWrapper>
  )
}

export default DefaultHeader
