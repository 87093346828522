import Comment from './Comment'

export default function CommentBusiness({
  comment,
  setComment,
}: {
  comment?: string
  setComment: React.Dispatch<React.SetStateAction<string>>
}) {
  return <Comment comment={comment} setComment={setComment} />
}
