import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'baseui/button'
import { Upload } from 'baseui/icon'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal'
import { Notification, KIND } from 'baseui/notification'
import { Tag } from 'baseui/tag'
import { ProgressBar } from 'baseui/progress-bar'
import { FormattedMessage } from 'react-intl'

import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'

import { NewsletterMailingFragmentFragment } from '../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../types'
import { MailingState } from '../../../../types'

export default function NewsletterSend({
  onClick,
  send,
  isOpen,
  loading,
  newsletterMailing,
}: {
  onClick: () => any
  send: () => any
  isOpen: boolean
  loading: boolean
  newsletterMailing?: Pick<Types.NewsletterMailing, 'htmlContent' | 'sendProcessId'> &
    NewsletterMailingFragmentFragment
}) {
  const [progress, setProgress] = useState(0)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const intl = useIntl()

  function openModal() {
    setIsConfirmOpen(true)
  }

  function closeModal() {
    setIsConfirmOpen(false)
  }

  useEffect(() => {
    if (
      (newsletterMailing?.sent.length || 0) > 0 ||
      (newsletterMailing?.failed.length || 0) > 0
    ) {
      let progress
      if (
        (newsletterMailing?.sent.length || 0) +
          (newsletterMailing?.failed.length || 0) ===
        (newsletterMailing?.to.length || 0)
      ) {
        progress = 100
      } else {
        progress = Math.ceil(
          (100 *
            ((newsletterMailing?.sent.length || 0) +
              (newsletterMailing?.failed.length || 0))) /
            (newsletterMailing?.to.length || 0)
        )
        progress = progress === 100 ? 99 : progress
      }
      setProgress(progress)
    }
  }, [newsletterMailing])
  return (
    <>
    <SimpleModal<string>
      isOpen={isConfirmOpen}
      onClose={() => closeModal()}
      headerLabel={intl.formatMessage({
        id: 'newsletters.newsletterSend.sendNewsletter',
        defaultMessage: 'Send newsletter',
      })}
      content={intl.formatMessage({
        id: 'newsletters.newsletterSend.sendConfirm',
        defaultMessage: 'Are you sure to send this newsletter to {recipientCount} people?',
      }, {
        recipientCount: newsletterMailing?.to.length || 0
      })}
      data={''}
      onConfirm={send}
      confirmLabel={intl.formatMessage({
        id: 'newsletters.newsletterSend.sendNewsletter',
        defaultMessage: 'Send newsletter',
      })}
    ></SimpleModal>
      <StatefulTooltip
        placement={PLACEMENT.right}
        content={intl.formatMessage({
          id: 'newsletters.newsletterSend.sendNewsletter',
          defaultMessage: 'Send newsletter',
        })}
        overrides={{
          Body: {
            style: () => ({
              zIndex: 1,
            }),
          },
        }}
      >
        <Button onClick={onClick}>
          <Upload
            title={intl.formatMessage({
              id: 'newsletters.newsletterSend.sendNewsletter',
              defaultMessage: 'Send newsletter',
            })}
            size={24}
          />
          {newsletterMailing?.to && newsletterMailing.to.length > 0 && (
            <>
              <Tag
                closeable={false}
                overrides={{
                  Root: {
                    style: () => ({
                      margin: '0!important',
                      color: '#000',
                    }),
                  },
                }}
                title={intl.formatMessage(
                  {
                    id: 'newsletters.newsletterSend.sending',
                    defaultMessage:
                      'Sending (recipients: {recipients}, sent: {sent}, did already receive mailing: {double}, failed: {failed})',
                  },
                  {
                    recipients: newsletterMailing.to.length,
                    sent: newsletterMailing.sent.length,
                    double:
                      newsletterMailing.state === MailingState.Completed
                        ? (newsletterMailing.to.length || 0) -
                          ((newsletterMailing.sent.length || 0) +
                            (newsletterMailing.failed.length || 0))
                        : 0,
                    failed: newsletterMailing.failed.length,
                  }
                )}
              >
                {`${newsletterMailing.to.length} - (${newsletterMailing.sent.length} / ${newsletterMailing.failed.length})`}
              </Tag>
            </>
          )}
        </Button>
      </StatefulTooltip>
      <Modal
        onClose={onClick}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: {
              zIndex: 1,
            },
          },
          Dialog: {
            style: {
              zIndex: 999,
              width: '80vw',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>
          <FormattedMessage
            id='newsletters.newsletterSend.sendNewsletter'
            defaultMessage={'Send newsletter'}
          />
          {newsletterMailing?.state && (
            <Notification
              kind={
                newsletterMailing.state === MailingState.Interrupted
                  ? KIND.negative
                  : newsletterMailing.state === MailingState.Completed
                  ? KIND.positive
                  : newsletterMailing.state === MailingState.Pending
                  ? KIND.warning
                  : KIND.info
              }
            >
              <FormattedMessage
                id={`newsletters.newsletterSend.state.${newsletterMailing.state}`}
                defaultMessage={newsletterMailing.state}
              />
            </Notification>
          )}
        </ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <div
            style={{ backgroundColor: '#fff' }}
            dangerouslySetInnerHTML={{
              __html: newsletterMailing?.htmlContent || '',
            }}
          />
        </ModalBody>
        <ModalFooter>
          {newsletterMailing?.state !== MailingState.Completed ? (
          <ModalButton onClick={openModal} isLoading={loading}>
            <FormattedMessage
              id={`newsletters.newsletterSend.send`}
              defaultMessage={'Send to recipients {recipients}'}
              values={{
                recipients:
                  newsletterMailing?.to && newsletterMailing?.to.length,
              }}
            />
          </ModalButton>) : (
            <Notification
              kind={KIND.positive}
            >
              <FormattedMessage
                id={`newsletters.newsletterSend.state.successfullSended`}
                defaultMessage="Successfull sended newsletter need to be copied to send again"
              />
            </Notification>

          )}
          {newsletterMailing?.state !== MailingState.Draft && (
            <Notification
              kind={
                newsletterMailing?.state === MailingState.Interrupted
                  ? KIND.negative
                  : newsletterMailing?.state === MailingState.Completed
                  ? KIND.positive
                  : newsletterMailing?.state === MailingState.Pending
                  ? KIND.warning
                  : KIND.info
              }
            >
              <FormattedMessage
                id={`newsletters.newsletterSend.state.${newsletterMailing?.state}`}
                defaultMessage={newsletterMailing?.state}
              />
            </Notification>
          )}
          {newsletterMailing?.state !== MailingState.Draft &&
            newsletterMailing?.to &&
            newsletterMailing.to.length > 0 && (
              <>
                <Notification>
                  <FormattedMessage
                    id={`newsletters.newsletterSend.sending`}
                    defaultMessage={
                      'Sending (recipients: {recipients}, sent: {sent}, did already receive mailing: {double}, failed: {failed})'
                    }
                    values={{
                      recipients: newsletterMailing.to.length,
                      sent: newsletterMailing.sent.length,
                      double:
                        newsletterMailing.state === MailingState.Completed
                          ? (newsletterMailing.to.length || 0) -
                            ((newsletterMailing.sent.length || 0) +
                              (newsletterMailing.failed.length || 0))
                          : 0,
                      failed: newsletterMailing.failed.length,
                    }}
                  />
                </Notification>
                <ProgressBar steps={100} value={progress} />
              </>
            )}
        </ModalFooter>
      </Modal>
    </>
  )
}
