import { useState } from 'react'

import { Button } from 'baseui/button'
import { Plus } from 'baseui/icon'
import { useStyletron } from 'baseui'
import { List,
  arrayMove } from 'baseui/dnd-list'
import { useIntl } from 'react-intl'

import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'

import NewsletterItemData from './NewsletterItem/NewsletterItem.data'
import {
  UpdateNewsletterMailingItemMutationVariables,
  UpdateNewsletterMailingMutationVariables,
} from '../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../types'

export default function NewslettersItems({
  addItem,
  deleteItem,
  newsletterMailing,
  setNewsletterMailing,
}: {
  addItem: () => void
  deleteItem: (id: string) => void
  newsletterMailing: UpdateNewsletterMailingMutationVariables & {
    toJoined: string
    mainImageSrc: string
    items: (UpdateNewsletterMailingItemMutationVariables & {
      imageSrc: string
      task: Types.ArrayUpdate
    })[]
  }
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: Types.ArrayUpdate
        })[]
      }
    >
  >
}) {
  const intl = useIntl()
  const [css] = useStyletron()
  const [deleteId, setDeleteId] = useState<string | undefined>()

  return (
    <>
      <SimpleModal<string>
        isOpen={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'newsletters.newsletterItem.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'newsletters.newsletterItem.deleteConfirm',
          defaultMessage: 'Are you sure to delete this newsletter item?',
        })}
        data={deleteId || ''}
        onConfirm={(deleteId: string) => {
          deleteItem && deleteItem(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'newsletters.newsletterItem.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>
      <List
        items={newsletterMailing.items
          .map((item) => item?.task !== Types.ArrayUpdate.Remove && (
          <NewsletterItemData
            key={item?.id}
            mailingItem={item as (UpdateNewsletterMailingItemMutationVariables & {
              imageSrc: string
              task: Types.ArrayUpdate
            })}
            setNewsletterMailing={setNewsletterMailing}
          />
        ))}
        removable
        removableByMove
        onChange={({ oldIndex, newIndex }) => {
          if (newIndex === -1 && newsletterMailing.items[oldIndex]) {
            setDeleteId(newsletterMailing.items[oldIndex]?.id.toString())
          } else if (newIndex >= 0) {
            setNewsletterMailing((prev) => ({
              ...prev,
              items: arrayMove(prev.items, oldIndex, newIndex)
            }))
          }
        }}
      />
      <div
        className={css({
          display: 'inline-block',
          textAlign: 'center',
          margin: '50px',
        })}
      >
        <Button onClick={() => addItem()}>
          <Plus />
        </Button>
      </div>
    </>
  )
}
