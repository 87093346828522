import getReactApolloCients from '../../../localReactApollo'

import { useState } from 'react'
import MembershipMailingFormBusiness from './MembershipMailingForm.business'

import { useCreateMembershipSignupRecipientMutation } from '../../../gql/SignupRecipient.generated'

export default function MembershipMailingFormData({
  newEntry
}: {
  newEntry: () => void
}) {
  const client = getReactApolloCients().getClient()

  const [values, setValues] = useState<{
    email: string | undefined
  }>({
    email: undefined,
  })

  const [
    addSignupRecipientMutation,
    { loading: addLoading, error: queryAddError },
  ] = useCreateMembershipSignupRecipientMutation({
    //@ts-ignore
    client,
  })

  async function createSignupRecipient(data: { email: string }) {
    await addSignupRecipientMutation({
      variables: {
        email: data.email
      },
    })
    newEntry()
  }

  return (
    <MembershipMailingFormBusiness
      values={values}
      setValues={setValues}
      queryError={queryAddError}
      isLoading={addLoading}
      createSignupRecipientEntry={createSignupRecipient}
    />
  )
}
