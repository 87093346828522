import { useIntl, FormattedMessage } from 'react-intl'
import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Select } from 'baseui/select'
import { StyledDivider, SIZE } from 'baseui/divider'

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'
import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'
import { getCantonsOptions } from '@lemonbrain/utils'

import { UpdateBillingInformationMutationVariables } from '../../gql/BillingInformation.generated'

export enum STATE {
  UPDATE = 'update',
  CREATE = 'create',
}

type Cantons = ReturnType<typeof getCantonsOptions>

export default function BillingInformations({
  subNav,
  values,
  setValues,
  isLoading,
  state,
  onUpdateClick,
  onCopyClick,
  onCancelClick,
  onCreateClick,
  errors,
}: {
  subNav: InnerRoute[]
  values: UpdateBillingInformationMutationVariables
  setValues: React.Dispatch<
    React.SetStateAction<UpdateBillingInformationMutationVariables>
  >
  isLoading: boolean
  state: STATE
  onUpdateClick:
  | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
  | undefined
  onCopyClick?:
  | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
  | undefined
  onCancelClick:
  | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
  | undefined
  onCreateClick?:
  | ((event: React.SyntheticEvent<HTMLButtonElement, Event>) => any)
  | undefined
  errors: {
    firmnameFrom: string
    streetNrFrom: string
    postCodeFrom: string
    placeFrom: string
    cantonFrom: string
    firmname: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    account: string
    iban: string
    qrIban: string
  }
}) {
  const intl = useIntl()

  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <EditContainer
              state={state}
              onUpdateClick={onUpdateClick}
              onCopyClick={onCopyClick}
              onCancelClick={onCancelClick}
              onCreateClick={onCreateClick}
              isLoading={isLoading}
              noCopy={true}
            >
              <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
                <Cell span={[12, 12, 12]}>
                  <Block
                    paddingTop={['15px', '15px', '15px', 0]}
                    overrides={{
                      Block: {
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '30px',
                        },
                      },
                    }}
                  >
                    <Block>
                      <Block
                        as='h3'
                        overrides={{
                          Block: {
                            style: ({ $theme }) => {
                              return {
                                ...$theme.typography.font450,
                                color: $theme.colors.primaryA,
                                marginBottom: '15px',
                              }
                            },
                          },
                        }}
                      >
                        <FormattedMessage
                          id='settings.svssBillingInformations.from'
                          defaultMessage={'Billing from'}
                        />
                      </Block>
                    </Block>
                  </Block>
                </Cell>
                <Cell span={[12, 6, 4]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.firstName',
                      defaultMessage: 'Firstname',
                    })}
                  >
                    <Input
                      value={(values && values.firstNameFrom) || undefined}
                      //@ts-ignore
                      onChange={(firstNameFrom) =>
                        setValues((prev) => ({
                          ...prev,
                          firstNameFrom: (firstNameFrom.target as HTMLInputElement)
                            .value,
                        }))
                      }
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 4]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.lastName',
                      defaultMessage: 'Lastname',
                    })}
                  >
                    <Input
                      value={(values && values.lastNameFrom) || undefined}
                      //@ts-ignore
                      onChange={(lastNameFrom) =>
                        setValues((prev) => ({
                          ...prev,
                          lastNameFrom: (lastNameFrom.target as HTMLInputElement).value,
                        }))
                      }
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 4]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.firmName',
                      defaultMessage: 'Firmname',
                    })}
                    error={errors.firmnameFrom}
                  >
                    <Input
                      value={(values && values.firmNameFrom) || undefined}
                      //@ts-ignore
                      onChange={(firmNameFrom) =>
                        setValues((prev) => ({
                          ...prev,
                          firmNameFrom: (firmNameFrom.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.firmnameFrom === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.mailbox',
                      defaultMessage: 'Mailbox',
                    })}
                  >
                    <Input
                      value={values.mailboxFrom?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          mailboxFrom: (e.target as HTMLInputElement).value,
                        }))
                      }
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.streetNr',
                      defaultMessage: 'Street Nr',
                    })}
                    error={errors.streetNrFrom}
                  >
                    <Input
                      value={values.streetNrFrom?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          streetNrFrom: (e.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.streetNrFrom === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.postCode',
                      defaultMessage: 'Post code',
                    })}
                    error={errors.postCodeFrom}
                  >
                    <Input
                      value={values.postCodeFrom?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          postCodeFrom: parseInt(
                            (e.target as HTMLInputElement).value
                          ),
                        }))
                      }
                      positive={errors.postCodeFrom === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.place',
                      defaultMessage: 'Place',
                    })}
                    error={errors.placeFrom}
                  >
                    <Input
                      value={values.placeFrom?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          placeFrom: (e.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.placeFrom === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 12]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.canton',
                      defaultMessage: 'Canton',
                    })}
                    error={errors.cantonFrom}
                  >
                    <Select
                      clearable={false}
                      options={getCantonsOptions(intl)}
                      onChange={({ value }) =>
                        setValues((prev) => ({
                          ...prev,
                          cantonFrom: (value as Cantons)[0].id,
                        }))
                      }
                      value={
                        values.cantonFrom
                          ? [
                            {
                              ...getCantonsOptions(intl).find(
                                (item) => item.id === values.cantonFrom
                              ),
                            },
                          ]
                          : [
                            {
                              ...getCantonsOptions(intl)[0],
                            },
                          ]
                      }
                      positive={errors.cantonFrom === ''}
                    />
                  </FormControl>
                </Cell>


                <Cell span={[12, 12, 12]}>
                  <Block
                    paddingTop={['30px']}
                    overrides={{
                      Block: {
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '30px',
                        },
                      },
                    }}
                  >
                    <Block>
                      <Block
                        as='h3'
                        overrides={{
                          Block: {
                            style: ({ $theme }) => {
                              return {
                                ...$theme.typography.font450,
                                color: $theme.colors.primaryA,
                                marginBottom: '15px',
                              }
                            },
                          },
                        }}
                      >
                        <FormattedMessage
                          id='settings.billingInformations'
                          defaultMessage={'Billing informations'}
                        />
                      </Block>
                    </Block>
                  </Block>
                </Cell>
                <Cell span={[12, 6, 4]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.firstName',
                      defaultMessage: 'Firstname',
                    })}
                  >
                    <Input
                      value={(values && values.firstName) || undefined}
                      //@ts-ignore
                      onChange={(firstName) =>
                        setValues((prev) => ({
                          ...prev,
                          firstName: (firstName.target as HTMLInputElement)
                            .value,
                        }))
                      }
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 4]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.lastName',
                      defaultMessage: 'Lastname',
                    })}
                  >
                    <Input
                      value={(values && values.lastName) || undefined}
                      //@ts-ignore
                      onChange={(lastName) =>
                        setValues((prev) => ({
                          ...prev,
                          lastName: (lastName.target as HTMLInputElement).value,
                        }))
                      }
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 4]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.firmName',
                      defaultMessage: 'Firmname',
                    })}
                    error={errors.firmname}
                  >
                    <Input
                      value={(values && values.firmName) || undefined}
                      //@ts-ignore
                      onChange={(firmName) =>
                        setValues((prev) => ({
                          ...prev,
                          firmName: (firmName.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.firmname === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.mailbox',
                      defaultMessage: 'Mailbox',
                    })}
                  >
                    <Input
                      value={values.mailbox?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          mailbox: (e.target as HTMLInputElement).value,
                        }))
                      }
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.streetNr',
                      defaultMessage: 'Street Nr',
                    })}
                    error={errors.streetNr}
                  >
                    <Input
                      value={values.streetNr?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          streetNr: (e.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.streetNr === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.postCode',
                      defaultMessage: 'Post code',
                    })}
                    error={errors.postCode}
                  >
                    <Input
                      value={values.postCode?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          postCode: parseInt(
                            (e.target as HTMLInputElement).value
                          ),
                        }))
                      }
                      positive={errors.postCode === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.place',
                      defaultMessage: 'Place',
                    })}
                    error={errors.place}
                  >
                    <Input
                      value={values.place?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          place: (e.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.place === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 12]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.canton',
                      defaultMessage: 'Canton',
                    })}
                    error={errors.canton}
                  >
                    <Select
                      clearable={false}
                      options={getCantonsOptions(intl)}
                      onChange={({ value }) =>
                        setValues((prev) => ({
                          ...prev,
                          canton: (value as Cantons)[0].id,
                        }))
                      }
                      value={
                        values.canton
                          ? [
                            {
                              ...getCantonsOptions(intl).find(
                                (item) => item.id === values.canton
                              ),
                            },
                          ]
                          : [
                            {
                              ...getCantonsOptions(intl)[0],
                            },
                          ]
                      }
                      positive={errors.canton === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.account',
                      defaultMessage: 'Account',
                    })}
                    error={errors.account}
                  >
                    <Input
                      value={values.account?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          account: (e.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.account === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.iban',
                      defaultMessage: 'IBAN',
                    })}
                    error={errors.iban}
                  >
                    <Input
                      value={values.iban?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          iban: (e.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.iban === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 12, 12]}>
                  <StyledDivider $size={SIZE.cell} />
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.qrIban',
                      defaultMessage: 'QR-IBAN',
                    })}
                    error={errors.qrIban}
                  >
                    <Input
                      value={values.qrIban?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          qrIban: (e.target as HTMLInputElement).value,
                        }))
                      }
                      positive={errors.qrIban === ''}
                    />
                  </FormControl>
                </Cell>
                <Cell span={[12, 6, 6]}>
                  <FormControl
                    label={intl.formatMessage({
                      id: 'settings.billingInformations.besrId',
                      defaultMessage: 'BESR-ID',
                    })}
                  >
                    <Input
                      value={values.besrId?.toString()}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          besrId: parseInt((e.target as HTMLInputElement).value) > 0 ? parseInt((e.target as HTMLInputElement).value) : null,
                        }))
                      }
                    />
                  </FormControl>
                </Cell>
              </Grid>
            </EditContainer>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
