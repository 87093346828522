// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddressTypeFragmentFragment = (
  { __typename?: 'AddressType' }
  & Pick<Types.AddressType, 'id' | 'isFirm' | 'isInitType' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetAddressTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAddressTypeQuery = (
  { __typename?: 'Query' }
  & { getAddressType: (
    { __typename?: 'AddressType' }
    & AddressTypeFragmentFragment
  ) }
);

export type GetAddressInitTypeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAddressInitTypeQuery = (
  { __typename?: 'Query' }
  & { getAddressInitType: (
    { __typename?: 'AddressType' }
    & AddressTypeFragmentFragment
  ) }
);

export type AddAddressTypeMutationVariables = Types.Exact<{
  isInitType: Types.Scalars['Boolean'];
  isFirm: Types.Scalars['Boolean'];
  name: Types.TranslationObjectCreate;
}>;


export type AddAddressTypeMutation = (
  { __typename?: 'Mutation' }
  & { addAddressType: (
    { __typename?: 'AddressType' }
    & AddressTypeFragmentFragment
  ) }
);

export type UpdateAddressTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  isInitType?: Types.Maybe<Types.Scalars['Boolean']>;
  isFirm?: Types.Maybe<Types.Scalars['Boolean']>;
  name: Types.TranslationObjectUpdate;
}>;


export type UpdateAddressTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateAddressType: (
    { __typename?: 'AddressType' }
    & AddressTypeFragmentFragment
  ) }
);

export type DeleteAddressTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteAddressTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteAddressType: (
    { __typename?: 'AddressType' }
    & AddressTypeFragmentFragment
  ) }
);

export type GetAddressTypesQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetAddressTypesQuery = (
  { __typename?: 'Query' }
  & { getAddressTypes: (
    { __typename?: 'AddressTypePagination' }
    & Pick<Types.AddressTypePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'AddressType' }
      & AddressTypeFragmentFragment
    )> }
  ) }
);

export type GetNextAddressTypesQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextAddressTypesQuery = (
  { __typename?: 'Query' }
  & { getNextAddressTypes: (
    { __typename?: 'AddressTypePagination' }
    & Pick<Types.AddressTypePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'AddressType' }
      & AddressTypeFragmentFragment
    )> }
  ) }
);

export const AddressTypeFragmentFragmentDoc = gql`
    fragment AddressTypeFragment on AddressType {
  id
  isFirm
  isInitType
  name {
    de
    fr
    it
  }
  createdFrom
}
    `;
export const GetAddressTypeDocument = gql`
    query getAddressType($id: ID!) {
  getAddressType(id: $id) {
    ...AddressTypeFragment
  }
}
    ${AddressTypeFragmentFragmentDoc}`;

/**
 * __useGetAddressTypeQuery__
 *
 * To run a query within a React component, call `useGetAddressTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddressTypeQuery(baseOptions: Apollo.QueryHookOptions<GetAddressTypeQuery, GetAddressTypeQueryVariables>) {
        return Apollo.useQuery<GetAddressTypeQuery, GetAddressTypeQueryVariables>(GetAddressTypeDocument, baseOptions);
      }
export function useGetAddressTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressTypeQuery, GetAddressTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetAddressTypeQuery, GetAddressTypeQueryVariables>(GetAddressTypeDocument, baseOptions);
        }
export type GetAddressTypeQueryHookResult = ReturnType<typeof useGetAddressTypeQuery>;
export type GetAddressTypeLazyQueryHookResult = ReturnType<typeof useGetAddressTypeLazyQuery>;
export type GetAddressTypeQueryResult = Apollo.QueryResult<GetAddressTypeQuery, GetAddressTypeQueryVariables>;
export const GetAddressInitTypeDocument = gql`
    query getAddressInitType {
  getAddressInitType {
    ...AddressTypeFragment
  }
}
    ${AddressTypeFragmentFragmentDoc}`;

/**
 * __useGetAddressInitTypeQuery__
 *
 * To run a query within a React component, call `useGetAddressInitTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressInitTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressInitTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressInitTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetAddressInitTypeQuery, GetAddressInitTypeQueryVariables>) {
        return Apollo.useQuery<GetAddressInitTypeQuery, GetAddressInitTypeQueryVariables>(GetAddressInitTypeDocument, baseOptions);
      }
export function useGetAddressInitTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressInitTypeQuery, GetAddressInitTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetAddressInitTypeQuery, GetAddressInitTypeQueryVariables>(GetAddressInitTypeDocument, baseOptions);
        }
export type GetAddressInitTypeQueryHookResult = ReturnType<typeof useGetAddressInitTypeQuery>;
export type GetAddressInitTypeLazyQueryHookResult = ReturnType<typeof useGetAddressInitTypeLazyQuery>;
export type GetAddressInitTypeQueryResult = Apollo.QueryResult<GetAddressInitTypeQuery, GetAddressInitTypeQueryVariables>;
export const AddAddressTypeDocument = gql`
    mutation addAddressType($isInitType: Boolean!, $isFirm: Boolean!, $name: TranslationObjectCreate!) {
  addAddressType(isInitType: $isInitType, isFirm: $isFirm, name: $name) {
    ...AddressTypeFragment
  }
}
    ${AddressTypeFragmentFragmentDoc}`;
export type AddAddressTypeMutationFn = Apollo.MutationFunction<AddAddressTypeMutation, AddAddressTypeMutationVariables>;

/**
 * __useAddAddressTypeMutation__
 *
 * To run a mutation, you first call `useAddAddressTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressTypeMutation, { data, loading, error }] = useAddAddressTypeMutation({
 *   variables: {
 *      isInitType: // value for 'isInitType'
 *      isFirm: // value for 'isFirm'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddAddressTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddAddressTypeMutation, AddAddressTypeMutationVariables>) {
        return Apollo.useMutation<AddAddressTypeMutation, AddAddressTypeMutationVariables>(AddAddressTypeDocument, baseOptions);
      }
export type AddAddressTypeMutationHookResult = ReturnType<typeof useAddAddressTypeMutation>;
export type AddAddressTypeMutationResult = Apollo.MutationResult<AddAddressTypeMutation>;
export type AddAddressTypeMutationOptions = Apollo.BaseMutationOptions<AddAddressTypeMutation, AddAddressTypeMutationVariables>;
export const UpdateAddressTypeDocument = gql`
    mutation updateAddressType($id: ID!, $isInitType: Boolean, $isFirm: Boolean, $name: TranslationObjectUpdate!) {
  updateAddressType(
    id: $id
    isInitType: $isInitType
    isFirm: $isFirm
    name: $name
  ) {
    ...AddressTypeFragment
  }
}
    ${AddressTypeFragmentFragmentDoc}`;
export type UpdateAddressTypeMutationFn = Apollo.MutationFunction<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>;

/**
 * __useUpdateAddressTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAddressTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressTypeMutation, { data, loading, error }] = useUpdateAddressTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isInitType: // value for 'isInitType'
 *      isFirm: // value for 'isFirm'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateAddressTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>) {
        return Apollo.useMutation<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>(UpdateAddressTypeDocument, baseOptions);
      }
export type UpdateAddressTypeMutationHookResult = ReturnType<typeof useUpdateAddressTypeMutation>;
export type UpdateAddressTypeMutationResult = Apollo.MutationResult<UpdateAddressTypeMutation>;
export type UpdateAddressTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>;
export const DeleteAddressTypeDocument = gql`
    mutation deleteAddressType($id: ID!) {
  deleteAddressType(id: $id) {
    ...AddressTypeFragment
  }
}
    ${AddressTypeFragmentFragmentDoc}`;
export type DeleteAddressTypeMutationFn = Apollo.MutationFunction<DeleteAddressTypeMutation, DeleteAddressTypeMutationVariables>;

/**
 * __useDeleteAddressTypeMutation__
 *
 * To run a mutation, you first call `useDeleteAddressTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressTypeMutation, { data, loading, error }] = useDeleteAddressTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAddressTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressTypeMutation, DeleteAddressTypeMutationVariables>) {
        return Apollo.useMutation<DeleteAddressTypeMutation, DeleteAddressTypeMutationVariables>(DeleteAddressTypeDocument, baseOptions);
      }
export type DeleteAddressTypeMutationHookResult = ReturnType<typeof useDeleteAddressTypeMutation>;
export type DeleteAddressTypeMutationResult = Apollo.MutationResult<DeleteAddressTypeMutation>;
export type DeleteAddressTypeMutationOptions = Apollo.BaseMutationOptions<DeleteAddressTypeMutation, DeleteAddressTypeMutationVariables>;
export const GetAddressTypesDocument = gql`
    query getAddressTypes($cursor: StartCursorInput!) {
  getAddressTypes(cursor: $cursor) {
    items {
      ...AddressTypeFragment
    }
    nextCursor
  }
}
    ${AddressTypeFragmentFragmentDoc}`;

/**
 * __useGetAddressTypesQuery__
 *
 * To run a query within a React component, call `useGetAddressTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressTypesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAddressTypesQuery(baseOptions: Apollo.QueryHookOptions<GetAddressTypesQuery, GetAddressTypesQueryVariables>) {
        return Apollo.useQuery<GetAddressTypesQuery, GetAddressTypesQueryVariables>(GetAddressTypesDocument, baseOptions);
      }
export function useGetAddressTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressTypesQuery, GetAddressTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetAddressTypesQuery, GetAddressTypesQueryVariables>(GetAddressTypesDocument, baseOptions);
        }
export type GetAddressTypesQueryHookResult = ReturnType<typeof useGetAddressTypesQuery>;
export type GetAddressTypesLazyQueryHookResult = ReturnType<typeof useGetAddressTypesLazyQuery>;
export type GetAddressTypesQueryResult = Apollo.QueryResult<GetAddressTypesQuery, GetAddressTypesQueryVariables>;
export const GetNextAddressTypesDocument = gql`
    query getNextAddressTypes($cursor: NextCursorInput!) {
  getNextAddressTypes(cursor: $cursor) {
    items {
      ...AddressTypeFragment
    }
    nextCursor
  }
}
    ${AddressTypeFragmentFragmentDoc}`;

/**
 * __useGetNextAddressTypesQuery__
 *
 * To run a query within a React component, call `useGetNextAddressTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextAddressTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextAddressTypesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextAddressTypesQuery(baseOptions: Apollo.QueryHookOptions<GetNextAddressTypesQuery, GetNextAddressTypesQueryVariables>) {
        return Apollo.useQuery<GetNextAddressTypesQuery, GetNextAddressTypesQueryVariables>(GetNextAddressTypesDocument, baseOptions);
      }
export function useGetNextAddressTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextAddressTypesQuery, GetNextAddressTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetNextAddressTypesQuery, GetNextAddressTypesQueryVariables>(GetNextAddressTypesDocument, baseOptions);
        }
export type GetNextAddressTypesQueryHookResult = ReturnType<typeof useGetNextAddressTypesQuery>;
export type GetNextAddressTypesLazyQueryHookResult = ReturnType<typeof useGetNextAddressTypesLazyQuery>;
export type GetNextAddressTypesQueryResult = Apollo.QueryResult<GetNextAddressTypesQuery, GetNextAddressTypesQueryVariables>;