export interface UserType {
    uID?: string
    uName: string
    uEmail?: string
    uGroupsPath?: string[]
    uAvatar?: string
}

export const isInGroupOrAdmin = function (user: UserType, groups: string[]) {
    let returnValue = false
    if (user && parseInt(user.uID || '0') > 0) {
        groups.forEach(group => returnValue = returnValue || group === process.env.REACT_APP_GROUP_GUESTS || (user.uGroupsPath && Object.values(user.uGroupsPath).indexOf(
            `/${String(group)}`
        ) > -1) || false)
        
        returnValue = returnValue || parseInt(user.uID || '') === 1 || (user.uGroupsPath && Object.values(user.uGroupsPath).indexOf(
            process.env.REACT_APP_GROUP_ADMINS || '/Administrators'
        ) > -1) || false
    }

    return returnValue
}

export const isInGroup = function (user: UserType, groups: string[]) {
    let returnValue = false

    if (user && parseInt(user.uID || '0') > 0) {
        groups.forEach(group => returnValue = returnValue || group === process.env.REACT_APP_GROUP_GUESTS || (user.uGroupsPath && Object.values(user.uGroupsPath).indexOf(
            `/${String(group)}`
        ) > -1) || false)
    }

    return returnValue
}