import { useState, useEffect, useCallback } from 'react'

import { debounce } from '@lemonbrain/utils'
import SearchFilters from '@lemonbrain/components/UiElements/SearchFilters/SearchFilters'

export enum ModalStates {
  state = 'state',
  association = 'association',
  workLevel = 'workLevel',
  sportsEducation = 'sportsEducation',
}

export default function AssociationsFilterBusiness({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}) {
  const [query, setQuery] = useState(searchQuery)
  const debouncedSearch = useCallback(debounce(setSearchQuery, 500), [])

  useEffect(() => {
    debouncedSearch(query)
  }, [query])

  return (
    <SearchFilters<ModalStates>
      query={query}
      setQuery={setQuery}
    />
  )
}
