import { useState, useEffect } from 'react'

import { priceFormater} from '@lemonbrain/utils'

import MembershipTableBusiness from './MembershipTable.business'
import { RowDataT } from './MembershipTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteMembershipMutation,
  useGetMembershipsQuery,
  GetNextMembershipsDocument,
  GetNextMembershipsQuery,
} from '../../../gql/Membership.generated'

export default function MembershipTableData({
  setMembershipId,
}: {
  setMembershipId: (membershipId: string) => void
}) {
  const [memberships, setMemberships] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetMembershipsQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteMembershipMutation] = useDeleteMembershipMutation({
    //@ts-ignore
    client,
  })

  async function removeMembershipEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteMembershipMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteMembership.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreMembershipEntries() {
    if (data?.getMemberships.nextCursor) {
      fetchMore({
        query: GetNextMembershipsDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getMemberships.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextMembershipsQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextMemberships: {
                nextCursor: fetchMoreResult.getNextMemberships.nextCursor,
                items: [...fetchMoreResult.getNextMemberships.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextMemberships.items.length > 0 &&
            previousResult.getMemberships.items.filter((item) => {
              return item.id === fetchMoreResult.getNextMemberships.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getMemberships: {
                ...previousResult.getMemberships,
                nextCursor: fetchMoreResult.getNextMemberships.nextCursor,
                items: [
                  ...previousResult.getMemberships.items,
                  ...fetchMoreResult.getNextMemberships.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getMemberships.items) {
      setMemberships(
        data?.getMemberships.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            prize: priceFormater(item.prize / 100),
            directInvoicing: !!item.directInvoicing,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToMembershipEntry(id: string) {
    setMembershipId(id)
  }

  return (
    <MembershipTableBusiness
      isLoading={loading}
      memberships={memberships}
      fetchMoreMembershipEntries={fetchMoreMembershipEntries}
      refetchMembershipEntries={refetch}
      goToMembershipEntry={goToMembershipEntry}
      removeMembershipEntry={removeMembershipEntry}
      queryError={queryError}
    />
  )
}
