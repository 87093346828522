import React, { useState, useEffect } from 'react'
import { toaster } from 'baseui/toast'

import Toaster from '@lemonbrain/components/UiElements/Toaster/Toaster'

export const alertContext = React.createContext<React.Dispatch<React.SetStateAction<{
    title?: string | undefined;
    label?: string | undefined;
    style?: "info" | "error" | "success" | "warning" | undefined;
}>> | undefined | null>(null)

const { Provider } = alertContext
export const AlertProvider = ({
    children
}: {
    children: JSX.Element
}) => {
  const [alert, setAlert] = useState<{
    title?: string
    label?: string
    style?: 'info' | 'error' | 'success' | 'warning'
  }>({
    title: '',
    label: '',
    style: 'info',
  })
  let autoHideDuration = 5000

  useEffect(() => {
    if (alert && alert.title) {
      switch (alert.style) {
        case 'success':
          toaster.positive(<><strong>{alert.title}</strong> {alert.label}</>, {
            autoHideDuration,
          })
          break
        case 'error':
          toaster.negative(<><strong>{alert.title}</strong> {alert.label}</>, {
            autoHideDuration,
          })
          break
        case 'warning':
          toaster.warning(<><strong>{alert.title}</strong> {alert.label}</>, {
            autoHideDuration,
          })
          break
        case 'info':
          toaster.info(<><strong>{alert.title}</strong> {alert.label}</>, {
            autoHideDuration,
          })
          break
        default:
          toaster.info(<><strong>{alert.title}</strong> {alert.label}</>, {
            autoHideDuration,
          })
      }
    }
  }, [alert, autoHideDuration])

  return (
    <>
      <Provider value={setAlert}>{children}</Provider>
      <Toaster />
    </>
  )
}
