import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'

import {
  useGetNewsletterMailingsQuery,
  GetNextNewsletterMailingsQuery,
  GetNextNewsletterMailingsDocument,
  useDeleteNewsletterMailingMutation,
} from '../../../gql/NewsletterMailing.generated'
import NewslettersTableBusiness from './NewslettersTable.business'
import { RowDataT } from './NewslettersTable'
import getReactApolloCients from '../../../localReactApollo'

export default function NewslettersTableData({
  goToNewsletter,
}: {
  goToNewsletter: (id: string | null) => void
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [data, setData] = useState<RowDataT[]>([])
  const [nextCursor, setNextCursor] = useState<string | null | undefined>()
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useLocalStorage<number>('pageSize', 10)
  const [pageCount, setPageCount] = useState(1)
  const client = getReactApolloCients().getClient()
  const {
    data: dataGetNewsletterMailings,
    loading: loadingGetNewsletterMailings,
    error: errorGetNewsletterMailings,
    fetchMore,
    refetch,
  } = useGetNewsletterMailingsQuery({
    variables: {
      cursor: {
        pageSize,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })
  const [
    deleteNewsletterMailingMutation,
    { loading: loadingDelete, error: errorDelete },
  ] = useDeleteNewsletterMailingMutation({
    //@ts-ignore
    client,
  })

  const onFetchMoreData = function () {
    if (nextCursor) {
      fetchMore({
        query: GetNextNewsletterMailingsDocument,
        variables: {
          cursor: {
            pageSize,
            nextCursor: nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextNewsletterMailingsQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextNewsletterMailings: {
                nextCursor: fetchMoreResult.getNextNewsletterMailings.nextCursor,
                items: [...fetchMoreResult.getNextNewsletterMailings.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextNewsletterMailings.items.length > 0 &&
            previousResult.getNewsletterMailings.items.filter((item) => {
              return item.id === fetchMoreResult.getNextNewsletterMailings.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getNewsletterMailings: {
                ...previousResult.getNewsletterMailings,
                nextCursor: fetchMoreResult.getNextNewsletterMailings.nextCursor,
                items: [
                  ...previousResult.getNewsletterMailings.items,
                  ...fetchMoreResult.getNextNewsletterMailings.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }
  const onRefetchData = function () {
    setPageCount(1)
    refetch({
      cursor: {
        pageSize,
      },
    })
  }

  const deleteNewsletterMailing = async function (id: string) {
    await deleteNewsletterMailingMutation({
      variables: {
        id,
      },
    })
    await setData([])
    refetch()
  }

  useEffect(() => {
    setLoading(loadingGetNewsletterMailings || loadingDelete)
  }, [loadingGetNewsletterMailings, loadingDelete])

  useEffect(() => {
    if (errorGetNewsletterMailings || errorDelete) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'newsletters.newslettersTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'newsletters.newslettersTable.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorGetNewsletterMailings?.message ||
          '' + errorDelete?.message
      )
    }
  }, [errorGetNewsletterMailings, errorDelete])

  useEffect(() => {
    if (dataGetNewsletterMailings && dataGetNewsletterMailings?.getNewsletterMailings.items.length > 0) {
      setData(
        dataGetNewsletterMailings.getNewsletterMailings.items.map((item) => ({
          id: item.id,
          name: {
            de: item.newsletter.name.de,
            fr: item.newsletter.name.fr,
            it: item.newsletter.name.it,
          },
          from: item.from,
          fromName: item.fromName,
          subject: item.subject,
          mailingDate:
            (item.mailingDate &&
              new Date(item.mailingDate).toLocaleDateString('de-CH')) ||
            '',
          createdFrom: item.createdFrom.toString(),
        }))
      )
      if (
        dataGetNewsletterMailings.getNewsletterMailings.nextCursor &&
        dataGetNewsletterMailings.getNewsletterMailings.nextCursor !== nextCursor
      ) {
        if (nextCursor !== dataGetNewsletterMailings.getNewsletterMailings.nextCursor) {
          setPageCount((prev) => ++prev)
        }
        setNextCursor(dataGetNewsletterMailings.getNewsletterMailings.nextCursor)
      }
    } else {
      setData([])
    }
  }, [dataGetNewsletterMailings])

  return loading ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <NewslettersTableBusiness
      data={data}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      goToNewsletter={goToNewsletter}
      deleteNewsletterMailing={deleteNewsletterMailing}
    />
  )
}
