import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import AssociationFilterBusiness from './AssociationFilter.business'
import getReactApolloCients from '../../../../../localReactApollo'
import {useGetAssociationsNoPersonQuery} from '../../../../../gql/members/Association.generated'

export default function AssociationFilterData({
  associationFilter,
  setAssociationFilter,
}: {
  associationFilter: string[] | null
  setAssociationFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {
    const intl = useIntl()
    const setAlert = useAlert()
    const [associations, setAssociations] = useState<
      {
        id: string
        label: string
      }[]
    >([])
    const client = getReactApolloCients().getClient()
      const { data: dataAssociations, loading: loadingAssociations, error: errorAssociations } = useGetAssociationsNoPersonQuery({
        variables: {
          cursor: {
            pageSize: 100,
          },
        },
        //@ts-ignore
        client,
     });

     useEffect(() => {
       if (errorAssociations) {
         setAlert &&
           setAlert({
             title: intl.formatMessage({
               id: 'members.association_filter.error',
               defaultMessage: 'Error',
             }),
             label: intl.formatMessage({
               id: 'members.association_filter.networkError',
               defaultMessage: 'We had network problem. Please try again',
             }),
             style: 'error',
           })
         console.error(errorAssociations?.message)
       }
     }, [errorAssociations])
   
     useEffect(() => {
       if (dataAssociations?.getAssociationsNoPerson.items) {
        setAssociations(
          dataAssociations?.getAssociationsNoPerson.items.map((item) => ({
             id: item.id,
             label: getTranslation(item.name, intl),
           })).sort(function(a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            
            return 0;
          })
         )
       }
     }, [dataAssociations])

  return <AssociationFilterBusiness associations={associations} loadingAssociations={loadingAssociations} associationFilter={associationFilter} setAssociationFilter={setAssociationFilter}/>
}
