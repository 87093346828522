import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { InnerRoute } from '@lemonbrain/hooks'
import { useAlert } from '@lemonbrain/hooks'

import BillingInformationsBusiness from './BillingInformations.business'
import {
  UpdateBillingInformationMutationVariables,
  AddBillingInformationMutationVariables,
  useAddBillingInformationMutation,
  useGetBillingInformationQuery,
  useUpdateBillingInformationMutation,
} from '../../gql/BillingInformation.generated'
import getReactApolloCients from '../../localReactApollo'

export default function SvssBillingInformationsData({ subNav }: { subNav: InnerRoute[] }) {
  const intl = useIntl()
  const [
    values,
    setValues,
  ] = useState<UpdateBillingInformationMutationVariables>({
    id: '',
  })
  const client = getReactApolloCients().getClient()
  const setAlert = useAlert()
  const {
    data,
    loading,
    error: queryError,
    refetch,
  } = useGetBillingInformationQuery({
    variables: {
      id: '1',
    },
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateBillingInformationsMutation,
    { loading: updateLoading, error: queryUpdateError },
  ] = useUpdateBillingInformationMutation({
    //@ts-ignore
    client,
  })
  const [
    addBillingInformationsMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddBillingInformationMutation({
    //@ts-ignore
    client,
  })

  async function updateBillingInformationsEntry(
    data: UpdateBillingInformationMutationVariables
  ) {
    if (values.id) {
      await updateBillingInformationsMutation({
        variables: data,
        //@ts-ignore
        client,
      })
    }
  }

  async function addBillingInformationsEntry(
    data: AddBillingInformationMutationVariables
  ) {
    await addBillingInformationsMutation({
      variables: data,
      //@ts-ignore
      client,
    })
  }

  const setData = function() {
    if (data?.getBillingInformation) {
      setValues({
        id: data.getBillingInformation.id,
        firstNameFrom: data.getBillingInformation.firstNameFrom,
        lastNameFrom: data.getBillingInformation.lastNameFrom,
        firmNameFrom: data.getBillingInformation.firmNameFrom,
        mailboxFrom: data.getBillingInformation.mailboxFrom,
        streetNrFrom: data.getBillingInformation.streetNrFrom,
        postCodeFrom: data.getBillingInformation.postCodeFrom,
        placeFrom: data.getBillingInformation.placeFrom,
        cantonFrom: data.getBillingInformation.cantonFrom,
        countryFrom: data.getBillingInformation.countryFrom,
        firstName: data.getBillingInformation.firstName,
        lastName: data.getBillingInformation.lastName,
        firmName: data.getBillingInformation.firmName,
        mailbox: data.getBillingInformation.mailbox,
        streetNr: data.getBillingInformation.streetNr,
        postCode: data.getBillingInformation.postCode,
        place: data.getBillingInformation.place,
        canton: data.getBillingInformation.canton,
        country: data.getBillingInformation.country,
        account: data.getBillingInformation.account,
        iban: data.getBillingInformation.iban,
        qrIban: data.getBillingInformation.qrIban,
        besrId: data.getBillingInformation.besrId,
      })
    }
  }

  async function refetchBillingInformationsEntry() {
    await refetch()
    setData()
  }

  useEffect(() => {
    setData()
  }, [data])

  useEffect(() => {
    if (queryError || queryUpdateError || queryAddError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.svssBillingInformations.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.svssBillingInformations.errorMessage',
            defaultMessage: 'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError, queryUpdateError, queryAddError])

  return (
    <BillingInformationsBusiness
      subNav={subNav}
      values={values}
      setValues={setValues}
      isLoading={loading || addLoading || updateLoading}
      addBillingInformationsEntry={addBillingInformationsEntry}
      updateBillingInformationsEntry={updateBillingInformationsEntry}
      refetchBillingInformationsEntry={refetchBillingInformationsEntry}
    />
  )
}
