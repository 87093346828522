// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SportsEducationFragmentFragment = (
  { __typename?: 'SportsEducation' }
  & Pick<Types.SportsEducation, 'id' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetSportsEducationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetSportsEducationQuery = (
  { __typename?: 'Query' }
  & { getSportsEducation: (
    { __typename?: 'SportsEducation' }
    & SportsEducationFragmentFragment
  ) }
);

export type AddSportsEducationMutationVariables = Types.Exact<{
  name: Types.TranslationObjectCreate;
}>;


export type AddSportsEducationMutation = (
  { __typename?: 'Mutation' }
  & { addSportsEducation: (
    { __typename?: 'SportsEducation' }
    & SportsEducationFragmentFragment
  ) }
);

export type UpdateSportsEducationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.TranslationObjectUpdate;
}>;


export type UpdateSportsEducationMutation = (
  { __typename?: 'Mutation' }
  & { updateSportsEducation: (
    { __typename?: 'SportsEducation' }
    & SportsEducationFragmentFragment
  ) }
);

export type DeleteSportsEducationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSportsEducationMutation = (
  { __typename?: 'Mutation' }
  & { deleteSportsEducation: (
    { __typename?: 'SportsEducation' }
    & SportsEducationFragmentFragment
  ) }
);

export type GetSportsEducationsQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetSportsEducationsQuery = (
  { __typename?: 'Query' }
  & { getSportsEducations: (
    { __typename?: 'SportsEducationPagination' }
    & Pick<Types.SportsEducationPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'SportsEducation' }
      & SportsEducationFragmentFragment
    )> }
  ) }
);

export type GetNextSportsEducationsQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextSportsEducationsQuery = (
  { __typename?: 'Query' }
  & { getNextSportsEducations: (
    { __typename?: 'SportsEducationPagination' }
    & Pick<Types.SportsEducationPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'SportsEducation' }
      & SportsEducationFragmentFragment
    )> }
  ) }
);

export const SportsEducationFragmentFragmentDoc = gql`
    fragment SportsEducationFragment on SportsEducation {
  id
  name {
    de
    fr
    it
  }
  createdFrom
}
    `;
export const GetSportsEducationDocument = gql`
    query getSportsEducation($id: ID!) {
  getSportsEducation(id: $id) {
    ...SportsEducationFragment
  }
}
    ${SportsEducationFragmentFragmentDoc}`;

/**
 * __useGetSportsEducationQuery__
 *
 * To run a query within a React component, call `useGetSportsEducationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsEducationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsEducationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSportsEducationQuery(baseOptions: Apollo.QueryHookOptions<GetSportsEducationQuery, GetSportsEducationQueryVariables>) {
        return Apollo.useQuery<GetSportsEducationQuery, GetSportsEducationQueryVariables>(GetSportsEducationDocument, baseOptions);
      }
export function useGetSportsEducationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSportsEducationQuery, GetSportsEducationQueryVariables>) {
          return Apollo.useLazyQuery<GetSportsEducationQuery, GetSportsEducationQueryVariables>(GetSportsEducationDocument, baseOptions);
        }
export type GetSportsEducationQueryHookResult = ReturnType<typeof useGetSportsEducationQuery>;
export type GetSportsEducationLazyQueryHookResult = ReturnType<typeof useGetSportsEducationLazyQuery>;
export type GetSportsEducationQueryResult = Apollo.QueryResult<GetSportsEducationQuery, GetSportsEducationQueryVariables>;
export const AddSportsEducationDocument = gql`
    mutation addSportsEducation($name: TranslationObjectCreate!) {
  addSportsEducation(name: $name) {
    ...SportsEducationFragment
  }
}
    ${SportsEducationFragmentFragmentDoc}`;
export type AddSportsEducationMutationFn = Apollo.MutationFunction<AddSportsEducationMutation, AddSportsEducationMutationVariables>;

/**
 * __useAddSportsEducationMutation__
 *
 * To run a mutation, you first call `useAddSportsEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSportsEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSportsEducationMutation, { data, loading, error }] = useAddSportsEducationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddSportsEducationMutation(baseOptions?: Apollo.MutationHookOptions<AddSportsEducationMutation, AddSportsEducationMutationVariables>) {
        return Apollo.useMutation<AddSportsEducationMutation, AddSportsEducationMutationVariables>(AddSportsEducationDocument, baseOptions);
      }
export type AddSportsEducationMutationHookResult = ReturnType<typeof useAddSportsEducationMutation>;
export type AddSportsEducationMutationResult = Apollo.MutationResult<AddSportsEducationMutation>;
export type AddSportsEducationMutationOptions = Apollo.BaseMutationOptions<AddSportsEducationMutation, AddSportsEducationMutationVariables>;
export const UpdateSportsEducationDocument = gql`
    mutation updateSportsEducation($id: ID!, $name: TranslationObjectUpdate!) {
  updateSportsEducation(id: $id, name: $name) {
    ...SportsEducationFragment
  }
}
    ${SportsEducationFragmentFragmentDoc}`;
export type UpdateSportsEducationMutationFn = Apollo.MutationFunction<UpdateSportsEducationMutation, UpdateSportsEducationMutationVariables>;

/**
 * __useUpdateSportsEducationMutation__
 *
 * To run a mutation, you first call `useUpdateSportsEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportsEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportsEducationMutation, { data, loading, error }] = useUpdateSportsEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSportsEducationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSportsEducationMutation, UpdateSportsEducationMutationVariables>) {
        return Apollo.useMutation<UpdateSportsEducationMutation, UpdateSportsEducationMutationVariables>(UpdateSportsEducationDocument, baseOptions);
      }
export type UpdateSportsEducationMutationHookResult = ReturnType<typeof useUpdateSportsEducationMutation>;
export type UpdateSportsEducationMutationResult = Apollo.MutationResult<UpdateSportsEducationMutation>;
export type UpdateSportsEducationMutationOptions = Apollo.BaseMutationOptions<UpdateSportsEducationMutation, UpdateSportsEducationMutationVariables>;
export const DeleteSportsEducationDocument = gql`
    mutation deleteSportsEducation($id: ID!) {
  deleteSportsEducation(id: $id) {
    ...SportsEducationFragment
  }
}
    ${SportsEducationFragmentFragmentDoc}`;
export type DeleteSportsEducationMutationFn = Apollo.MutationFunction<DeleteSportsEducationMutation, DeleteSportsEducationMutationVariables>;

/**
 * __useDeleteSportsEducationMutation__
 *
 * To run a mutation, you first call `useDeleteSportsEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSportsEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSportsEducationMutation, { data, loading, error }] = useDeleteSportsEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSportsEducationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSportsEducationMutation, DeleteSportsEducationMutationVariables>) {
        return Apollo.useMutation<DeleteSportsEducationMutation, DeleteSportsEducationMutationVariables>(DeleteSportsEducationDocument, baseOptions);
      }
export type DeleteSportsEducationMutationHookResult = ReturnType<typeof useDeleteSportsEducationMutation>;
export type DeleteSportsEducationMutationResult = Apollo.MutationResult<DeleteSportsEducationMutation>;
export type DeleteSportsEducationMutationOptions = Apollo.BaseMutationOptions<DeleteSportsEducationMutation, DeleteSportsEducationMutationVariables>;
export const GetSportsEducationsDocument = gql`
    query getSportsEducations($cursor: StartCursorInput!) {
  getSportsEducations(cursor: $cursor) {
    items {
      ...SportsEducationFragment
    }
    nextCursor
  }
}
    ${SportsEducationFragmentFragmentDoc}`;

/**
 * __useGetSportsEducationsQuery__
 *
 * To run a query within a React component, call `useGetSportsEducationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsEducationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsEducationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetSportsEducationsQuery(baseOptions: Apollo.QueryHookOptions<GetSportsEducationsQuery, GetSportsEducationsQueryVariables>) {
        return Apollo.useQuery<GetSportsEducationsQuery, GetSportsEducationsQueryVariables>(GetSportsEducationsDocument, baseOptions);
      }
export function useGetSportsEducationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSportsEducationsQuery, GetSportsEducationsQueryVariables>) {
          return Apollo.useLazyQuery<GetSportsEducationsQuery, GetSportsEducationsQueryVariables>(GetSportsEducationsDocument, baseOptions);
        }
export type GetSportsEducationsQueryHookResult = ReturnType<typeof useGetSportsEducationsQuery>;
export type GetSportsEducationsLazyQueryHookResult = ReturnType<typeof useGetSportsEducationsLazyQuery>;
export type GetSportsEducationsQueryResult = Apollo.QueryResult<GetSportsEducationsQuery, GetSportsEducationsQueryVariables>;
export const GetNextSportsEducationsDocument = gql`
    query getNextSportsEducations($cursor: NextCursorInput!) {
  getNextSportsEducations(cursor: $cursor) {
    items {
      ...SportsEducationFragment
    }
    nextCursor
  }
}
    ${SportsEducationFragmentFragmentDoc}`;

/**
 * __useGetNextSportsEducationsQuery__
 *
 * To run a query within a React component, call `useGetNextSportsEducationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextSportsEducationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextSportsEducationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextSportsEducationsQuery(baseOptions: Apollo.QueryHookOptions<GetNextSportsEducationsQuery, GetNextSportsEducationsQueryVariables>) {
        return Apollo.useQuery<GetNextSportsEducationsQuery, GetNextSportsEducationsQueryVariables>(GetNextSportsEducationsDocument, baseOptions);
      }
export function useGetNextSportsEducationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextSportsEducationsQuery, GetNextSportsEducationsQueryVariables>) {
          return Apollo.useLazyQuery<GetNextSportsEducationsQuery, GetNextSportsEducationsQueryVariables>(GetNextSportsEducationsDocument, baseOptions);
        }
export type GetNextSportsEducationsQueryHookResult = ReturnType<typeof useGetNextSportsEducationsQuery>;
export type GetNextSportsEducationsLazyQueryHookResult = ReturnType<typeof useGetNextSportsEducationsLazyQuery>;
export type GetNextSportsEducationsQueryResult = Apollo.QueryResult<GetNextSportsEducationsQuery, GetNextSportsEducationsQueryVariables>;