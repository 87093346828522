import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { useAlert } from '@lemonbrain/hooks'
import getReactApolloCients from '../../../localReactApollo'
import StateTypeEditBusiness from './StateTypeEdit.business'
import {
  useGetStateTypeLazyQuery,
  useAddStateTypeMutation,
  useUpdateStateTypeMutation,
} from '../../../gql/StateType.generated'

export default function StateTypeEditData({
  stateId,
  stateTypeId,
  setStateTypeId,
}: {
  stateId: string
  stateTypeId: string
  setStateTypeId: (stateTypeId: string) => void
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [values, setValues] = useState<{
    stateTypeId: string
    de: string
    fr: string
    it: string
    stateId: string
    isTrialType: boolean
    requireMembership: boolean
  }>({
    stateTypeId: '',
    de: '',
    fr: '',
    it: '',
    stateId: stateId,
    isTrialType: false,
    requireMembership: false,
  })

  const client = getReactApolloCients().getClient()

  const [
    getStateTypeQuery,
    { data, loading: getLoading, error: queryGetError },
  ] = useGetStateTypeLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })

  const [
    updateStateTypeMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateStateTypeMutation({
    //@ts-ignore
    client,
  })

  const [
    addStateTypeMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddStateTypeMutation({
    //@ts-ignore
    client,
  })

  async function updateStateTypeEntry(data: {
    name: {
      de: string
      fr: string
      it: string
    },
    isTrialType: boolean
    requireMembership: boolean
  }) {
    if (stateId && stateTypeId) {
      await updateStateTypeMutation({
        variables: {
          id: stateTypeId,
          name: {
            de: data.name.de,
            fr: data.name.fr,
            it: data.name.it,
          },
          stateId,
          isTrialType: data.isTrialType,
          requireMembership: data.requireMembership,
        },
      })
    }
  }

  async function createStateTypeEntry(data: {
    name: {
      de: string
      fr: string
      it: string
    },
    state: {
      id: string
    },
    isTrialType: boolean
    requireMembership: boolean
  }) {
    await addStateTypeMutation({
      variables: {
        name: {
          de: data.name.de,
          fr: data.name.fr,
          it: data.name.it,
        },
        stateId: data.state.id,
        isTrialType: data.isTrialType,
        requireMembership: data.requireMembership,
      },
    })
  }

  function removeStateTypeId() {
    setStateTypeId('')
  }


  useEffect(() => {
    if (stateTypeId) {
      getStateTypeQuery({
        variables: {
          id: stateTypeId,
        },
      })
    }
  }, [stateTypeId])

  useEffect(() => {
    if (data?.getStateType) {
      setValues({
        stateTypeId: data.getStateType.id,
        de: data.getStateType.name.de,
        fr: data.getStateType.name.fr,
        it: data.getStateType.name.it,
        stateId: stateId,
        isTrialType: data.getStateType.isTrialType,
        requireMembership: data.getStateType.requireMembership,
      })
    }
  }, [data])

  useEffect(() => {
    if (queryGetError || queryChangeError || queryAddError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateTypeEdit.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateTypeEdit.errorMessage',
            defaultMessage: 'There was an error network problem. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryGetError, queryChangeError, queryAddError])

  return (
    <StateTypeEditBusiness
      stateId={stateId}
      stateTypeId={stateTypeId}
      values={values}
      setValues={setValues}  
      isLoading={getLoading || changeLoading || addLoading}
      createStateTypeEntry={createStateTypeEntry}
      updateStateTypeEntry={updateStateTypeEntry}
      removeStateTypeId={removeStateTypeId}
    />
  )
}
