import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import MembershipTable, { RowDataT } from './MembershipTable'

import { useAlert } from '@lemonbrain/hooks'

export default function MembershipTableBusiness({
  isLoading,
  memberships,
  fetchMoreMembershipEntries,
  refetchMembershipEntries,
  goToMembershipEntry,
  removeMembershipEntry,
  queryError,
}: {
  isLoading: boolean
  memberships: {
    id: string
    data: RowDataT
  }[]
  fetchMoreMembershipEntries: () => void
  refetchMembershipEntries: () => void
  goToMembershipEntry: (id: string) => void
  removeMembershipEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeMembershipEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.membership.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchMembershipEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.membershipTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.membershipTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.membershipTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.membershipTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<MembershipTable
      isLoading={currentIsLoading}
      rows={memberships}
      fetchMoreListItems={fetchMoreMembershipEntries}
      editRow={goToMembershipEntry}
      removeRow={removeRow}
    />
  )
}
