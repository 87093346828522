import { useParams } from "react-router-dom"

import {useHistoryPush} from '@lemonbrain/hooks'

import SignUpBusiness from './SignUp.business'

import { getApolloClientAuth } from '../../react-apollo'
import { useCreateUserMutation } from '../../gql/Auth.generated'
import { Task } from '../../types'

export default function SignUpData () {
    const pushPath = useHistoryPush()
    const clientAuth = getApolloClientAuth()
    const { language } = useParams<{ language: string }>()

    const [createUserMutation] = useCreateUserMutation({
        client: clientAuth,
    })

    async function createUser (email: string, password: string, reCaptchaToken: string) {
        return new Promise<{uName: string, uEmail: string, state: "is-valid"}>(async (resolve, reject) => {
            try {
                const validationUrl = `${window.location.host}/${language}/validate-email`
                const result = await createUserMutation({
                    variables: {
                        email,
                        password,
                        reCaptchaToken,
                        validationUrl,
                        groups: [{ name: process.env.REACT_APP_GROUP_GUESTS || '', task: Task.Add }],
                    }
                })

                if (result.data?.createUser.validationErrors) {
                    reject(result.data?.createUser.validationErrors)
                } else {
                    resolve({
                        uName: result.data?.createUser.result?.uName || '',
                        uEmail: result.data?.createUser.result?.uEmail || '',
                        state: 'is-valid'
                    })
                }
            } catch (e) {
                reject()
            }
        })
    }

    function goToSignIn (email?: string) {
        pushPath(`/${language}/login/${email}`)
    }

    return (
        <SignUpBusiness
        createUser={createUser}
        goToSignIn={goToSignIn}
        />
    )
}
