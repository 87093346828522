// @ts-nocheck
import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type TransactionFragmentFragment = (
  { __typename?: 'SvssTransaction' }
  & Pick<Types.SvssTransaction, 'id' | 'state' | 'createdFrom' | 'updatedAt'>
);

export type AddTransactionMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['ID'];
  trsansactionId: Types.Scalars['ID'];
}>;


export type AddTransactionMutation = (
  { __typename?: 'Mutation' }
  & { addTransaction: (
    { __typename?: 'SvssTransaction' }
    & TransactionFragmentFragment
  ) }
);

export type GetTransactionQueryVariables = Types.Exact<{
  invoiceId: Types.Scalars['ID'];
}>;


export type GetTransactionQuery = (
  { __typename?: 'Query' }
  & { getTransaction?: Types.Maybe<(
    { __typename?: 'SvssTransaction' }
    & TransactionFragmentFragment
  )> }
);

export const TransactionFragmentFragmentDoc = gql`
    fragment TransactionFragment on SvssTransaction {
  id
  state
  createdFrom
  updatedAt
}
    `;
export const AddTransactionDocument = gql`
    mutation addTransaction($invoiceId: ID!, $trsansactionId: ID!) {
  addTransaction(invoiceId: $invoiceId, trsansactionId: $trsansactionId) {
    ...TransactionFragment
  }
}
    ${TransactionFragmentFragmentDoc}`;
export type AddTransactionMutationFn = Apollo.MutationFunction<AddTransactionMutation, AddTransactionMutationVariables>;

/**
 * __useAddTransactionMutation__
 *
 * To run a mutation, you first call `useAddTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTransactionMutation, { data, loading, error }] = useAddTransactionMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      trsansactionId: // value for 'trsansactionId'
 *   },
 * });
 */
export function useAddTransactionMutation(baseOptions?: Apollo.MutationHookOptions<AddTransactionMutation, AddTransactionMutationVariables>) {
        return Apollo.useMutation<AddTransactionMutation, AddTransactionMutationVariables>(AddTransactionDocument, baseOptions);
      }
export type AddTransactionMutationHookResult = ReturnType<typeof useAddTransactionMutation>;
export type AddTransactionMutationResult = Apollo.MutationResult<AddTransactionMutation>;
export type AddTransactionMutationOptions = Apollo.BaseMutationOptions<AddTransactionMutation, AddTransactionMutationVariables>;
export const GetTransactionDocument = gql`
    query getTransaction($invoiceId: ID!) {
  getTransaction(invoiceId: $invoiceId) {
    ...TransactionFragment
  }
}
    ${TransactionFragmentFragmentDoc}`;

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
        return Apollo.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, baseOptions);
      }
export function useGetTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, baseOptions);
        }
export type GetTransactionQueryHookResult = ReturnType<typeof useGetTransactionQuery>;
export type GetTransactionLazyQueryHookResult = ReturnType<typeof useGetTransactionLazyQuery>;
export type GetTransactionQueryResult = Apollo.QueryResult<GetTransactionQuery, GetTransactionQueryVariables>;