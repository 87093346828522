import { useState, useEffect } from 'react'

import SportsEducationTableBusiness from './SportsEducationTable.business'
import { RowDataT } from './SportsEducationTable'
import getReactApolloCients from '../../../localReactApollo'
import {
  useDeleteSportsEducationMutation,
  useGetSportsEducationsQuery,
  GetNextSportsEducationsDocument,
  GetNextSportsEducationsQuery,
} from '../../../gql/SportsEducation.generated'

export default function SportsEducationTableData({
  setSportsEducationId,
}: {
  setSportsEducationId: (sportsEducationId: string | undefined) => void
}) {
  const [sportsEducations, setSportsEducations] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch,
  } = useGetSportsEducationsQuery({
    variables: {
      cursor: {
        pageSize: 50,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [deleteSportsEducationMutation] = useDeleteSportsEducationMutation({
    //@ts-ignore
    client,
  })

  async function removeSportsEducationEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteSportsEducationMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteSportsEducation.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreSportsEducationEntries() {
    if (data?.getSportsEducations.nextCursor) {
      fetchMore({
        query: GetNextSportsEducationsDocument,
        variables: {
          cursor: {
            pageSize: 25,
            nextCursor: data?.getSportsEducations.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextSportsEducationsQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextSportsEducations: {
                nextCursor: fetchMoreResult.getNextSportsEducations.nextCursor,
                items: [...fetchMoreResult.getNextSportsEducations.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextSportsEducations.items.length > 0 &&
            previousResult.getSportsEducations.items.filter((item) => {
              return item.id === fetchMoreResult.getNextSportsEducations.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getSportsEducations: {
                ...previousResult.getSportsEducations,
                nextCursor: fetchMoreResult.getNextSportsEducations.nextCursor,
                items: [
                  ...previousResult.getSportsEducations.items,
                  ...fetchMoreResult.getNextSportsEducations.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getSportsEducations.items) {
      setSportsEducations(
        data?.getSportsEducations.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            de: item.name.de,
            fr: item.name.fr,
            it: item.name.it,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToSportsEducationEntry(id: string) {
    setSportsEducationId(id)
  }

  return (
    <SportsEducationTableBusiness
      isLoading={loading}
      sportsEducations={sportsEducations}
      fetchMoreSportsEducationEntries={fetchMoreSportsEducationEntries}
      refetchSportsEducationEntries={refetch}
      goToSportsEducationEntry={goToSportsEducationEntry}
      removeSportsEducationEntry={removeSportsEducationEntry}
      queryError={queryError}
    />
  )
}
