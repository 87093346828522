import { useIntl } from 'react-intl'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Block } from 'baseui/block'
import { Select } from 'baseui/select'
import { StyledDivider, SIZE } from 'baseui/divider'

import { getCantonsOptions } from '@lemonbrain/utils'

import { UpdateAssociationIfMemberMutationVariables } from '../../../../gql/Association.generated'

type Cantons = ReturnType<typeof getCantonsOptions>

export default function BillingInformations({
  association,
  setAssociation,
  errors,
}: {
  association: UpdateAssociationIfMemberMutationVariables & {
    prizeString: string
  }
  setAssociation: React.Dispatch<
    React.SetStateAction<
      UpdateAssociationIfMemberMutationVariables & { prizeString: string, imageSrc: string }
    >
  >
  errors: {
    firmname: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    account: string
    iban: string
    qrIban: string
    prize: string
    // membersCalculationAt: string
  }
}) {
  const intl = useIntl()

  return (
    <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
      <Cell span={[12, 6, 4]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.prize',
            defaultMessage: 'Prize',
          })}
          error={errors.prize}
        >
          <Input
            value={(association && association.prizeString) || ''}
            onChange={(prize) =>
              setAssociation((prev) => ({
                ...prev,
                prizeString: (prize.target as HTMLInputElement).value,
              }))
            }
            positive={errors.prize === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 4]}></Cell>
      <Cell span={[12, 12, 12]}>
        <Block marginTop='30px' marginBottom='30px'>
          <hr />
        </Block>
      </Cell>
      <Cell span={[12, 6, 4]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.firstName',
            defaultMessage: 'Firstname',
          })}
        >
          <Input
            value={
              (association?.billingInformation &&
                association?.billingInformation.firstName) ||
              ''
            }
            //@ts-ignore
            onChange={(firstName) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  firstName: (firstName.target as HTMLInputElement).value,
                },
              }))
            }
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 4]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.lastName',
            defaultMessage: 'Lastname',
          })}
        >
          <Input
            value={
              (association?.billingInformation &&
                association?.billingInformation.lastName) ||
              ''
            }
            //@ts-ignore
            onChange={(lastName) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  lastName: (lastName.target as HTMLInputElement).value,
                },
              }))
            }
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 4]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.firmName',
            defaultMessage: 'Firmname',
          })}
          error={errors.firmname}
        >
          <Input
            value={
              (association?.billingInformation &&
                association?.billingInformation.firmName) ||
              ''
            }
            //@ts-ignore
            onChange={(firmName) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  firmName: (firmName.target as HTMLInputElement).value,
                },
              }))
            }
            positive={errors.firmname === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.mailbox',
            defaultMessage: 'Mailbox',
          })}
        >
          <Input
            value={association?.billingInformation?.mailbox?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  mailbox: (e.target as HTMLInputElement).value,
                },
              }))
            }
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.streetNr',
            defaultMessage: 'Street Nr',
          })}
          error={errors.streetNr}
        >
          <Input
            value={association?.billingInformation?.streetNr?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  streetNr: (e.target as HTMLInputElement).value,
                },
              }))
            }
            positive={errors.streetNr === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.postCode',
            defaultMessage: 'Post code',
          })}
          error={errors.postCode}
        >
          <Input
            value={association?.billingInformation?.postCode?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  postCode: parseInt((e.target as HTMLInputElement).value),
                },
              }))
            }
            positive={errors.postCode === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.place',
            defaultMessage: 'Place',
          })}
          error={errors.place}
        >
          <Input
            value={association?.billingInformation?.place?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  place: (e.target as HTMLInputElement).value,
                },
              }))
            }
            positive={errors.place === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 12]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.canton',
            defaultMessage: 'Canton',
          })}
          error={errors.canton}
        >
          <Select
            clearable={false}
            options={getCantonsOptions(intl)}
            onChange={({ value }) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  canton: (value as Cantons)[0].id,
                },
              }))
            }
            value={
              association?.billingInformation?.canton
                ? [
                    {
                      ...getCantonsOptions(intl).find(
                        (item) =>
                          item.id === association?.billingInformation?.canton
                      ),
                    },
                  ]
                : [
                    {
                      ...getCantonsOptions(intl)[0],
                    },
                  ]
            }
            positive={errors.canton === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.account',
            defaultMessage: 'Account',
          })}
          error={errors.account}
        >
          <Input
            value={association?.billingInformation.account?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  account: (e.target as HTMLInputElement).value,
                },
              }))
            }
            positive={errors.account === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.iban',
            defaultMessage: 'IBAN',
          })}
          error={errors.iban}
        >
          <Input
            value={association?.billingInformation?.iban?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  iban: (e.target as HTMLInputElement).value,
                },
              }))
            }
            positive={errors.iban === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 12, 12]}>
        <StyledDivider $size={SIZE.cell} />
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.qrIban',
            defaultMessage: 'QR-IBAN',
          })}
          error={errors.qrIban}
        >
          <Input
            value={association?.billingInformation?.qrIban?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  qrIban: (e.target as HTMLInputElement).value,
                },
              }))
            }
            positive={errors.qrIban === ''}
          />
        </FormControl>
      </Cell>
      <Cell span={[12, 6, 6]}>
        <FormControl
          label={intl.formatMessage({
            id: 'associations.billingInformations.besrId',
            defaultMessage: 'BESR-ID',
          })}
        >
          <Input
            value={association?.billingInformation?.besrId?.toString()}
            onChange={(e) =>
              setAssociation((prev) => ({
                ...prev,
                billingInformation: {
                  ...prev.billingInformation,
                  besrId:
                    parseInt((e.target as HTMLInputElement).value) > 0
                      ? parseInt((e.target as HTMLInputElement).value)
                      : null,
                },
              }))
            }
          />
        </FormControl>
      </Cell>
    </Grid>
  )
}
