import { InnerRoute, useAlert } from '@lemonbrain/hooks'
import MembersAddBusiness from './MembersAdd.business'
import { useCreateUserMutation } from '../../../../auth/src/gql/Auth.generated'
import { useAddEmptyPersonMutation } from '../../gql/members/Person.generated'
import getReactApolloCients, { getReactApolloAuthCients } from '../../localReactApollo'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Task } from '../../../../auth/src/types'
import { useIntl } from 'react-intl'

export default function MembersAddData({ subNav }: { subNav: InnerRoute[] }) {
  const clientAuth = getReactApolloAuthCients().getClient()
  const client = getReactApolloCients().getClient()
  const { language } = useParams<{ language: string }>()
  const intl = useIntl()
  const setAlert = useAlert()

  const [values, setValues] = useState({
    email: ''
  })

  const [createUserMutation] = useCreateUserMutation({
    //@ts-ignore
    client: clientAuth,
  })
  const [addEmptyPersonMutation] = useAddEmptyPersonMutation({
    //@ts-ignore
    client: client
  })


  async function createUser(email: string) {
    return new Promise<{id: string, uName: string, uEmail: string, state: "is-valid"}>(async (resolve, reject) => {
      try {
        const validationUrl = `${window.location.host}/${language}/validate-email`
        const result = await createUserMutation({
          variables: {
            email,
            password: Math.random().toString(36).substring(2.10),
            reCaptchaToken: '',
            validationUrl,
            groups: [{ name: process.env.REACT_APP_GROUP_GUESTS || '', task: Task.Add }],
          }
        })

        if (result.data?.createUser.validationErrors && result.data?.createUser.validationErrors.length > 0) {
          reject(result.data?.createUser.validationErrors)
        } else {
          resolve({
            id: result.data?.createUser.result?.uID || '',
            uName: result.data?.createUser.result?.uName || '',
            uEmail: result.data?.createUser.result?.uEmail || '',
            state: 'is-valid'
          })
        }
      } catch (e) {
        reject()
      }
    })
  }

  async function createPerson(authId: string, email: string) {
    const result = await addEmptyPersonMutation({
      variables: {
        email,
        authId
      }
    })

    if (result.data?.addEmptyPerson.id) {
      window.location.href = `/${language}/members/overview/${result?.data?.addEmptyPerson.id}`
    } else {
      console.error('Error creating person')
      setAlert && setAlert({
        title: intl.formatMessage({ id: 'members.create.error', defaultMessage: 'Error Adding Person' }),
        label: intl.formatMessage({ id: 'members.create.errorText', defaultMessage: 'The Person could not be added' }),
        style: 'error'
    })
    }
  }

  return (
    <MembersAddBusiness
      subNav={subNav}
      createUser={createUser}
      createPerson={createPerson}
      values={values}
      setValues={setValues}
    />
  )
}
