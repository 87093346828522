import MyInvoiceEditBusiness from './MyInvoiceEdit.business'
import { PaymentState } from '../InvoicesMy.data'

export default function MyInvoiceEditIfToAssociationData({
  goToInvoice,
  invoiceId,
  paymentState,
  language,
}: {
  goToInvoice: (id: string | null) => void
  invoiceId: string
  paymentState: PaymentState | undefined
  language: string
}) {
  return (
    <MyInvoiceEditBusiness
      goToInvoice={goToInvoice}
      invoiceId={invoiceId}
      paymentState={paymentState}
      language={language}
    />
  )
}
