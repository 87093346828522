import { useParams } from "react-router-dom"
import { FormattedMessage } from 'react-intl'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'

import {useHistoryPush} from '@lemonbrain/hooks'

import Logo from '../../UiElements/Logo/Logo'
import SvgIcon from '../../UiElements/SvgIcon/SvgIcon'
import Container from '../../UiElements/Container/Container'

import {
  useThemeSwitcherCtx,
  THEME,
} from '@lemonbrain/contexts/theme/theme.provider'

type AuthHeaderType = {
  pathname?: string
}

const AuthHeader = ({ pathname }: AuthHeaderType) => {
  const pushPath = useHistoryPush()
  const { theme, logo, logoColor } = useThemeSwitcherCtx()
  const { language } = useParams<{ language: string }>()

  return (
    <Container>
      <Block
        overrides={{
          Block: {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '23px 0',
            },
          },
        }}
      >
        <Logo
          path="/"
          src={theme === THEME.dark ? (
            <SvgIcon
              src={logo}
              style={{ width: '476px', height: '96px' }}
            />
          ) : (
            <SvgIcon
              src={logoColor}
              style={{ width: '476px', height: '96px' }}
            />
          )
          }
        />

        <Block>
          <Button
            kind="primary"
            disabled={pathname === `/${language}/login`}
            onClick={() => pushPath(`/${language}/login`)}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font250,
                    ':disabled': {
                      backgroundColor: 'transparent',
                    },
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='login'
              defaultMessage="Login"
            />
          </Button>
          {/* <Button
            disabled={pathname === '/signup'}
            onClick={() => history.push('/signup')}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font250,
                  };
                },
              },
            }}
          >
            Join Free
          </Button> */}
        </Block>
      </Block>
    </Container>
  )
}

export default AuthHeader
