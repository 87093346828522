import React, { useEffect, useState } from 'react'

import SignUp from './SignUp'
import { useIntl } from 'react-intl'

import { useCaptcha, useAlert } from '@lemonbrain/hooks'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'

export default function LoginBusiness ({
    createUser,
    goToSignIn
}: {
    createUser: (email: string, password: string, reCaptchaToken: string) => Promise<{ uName: string, uEmail: string, state: "is-valid" }>,
    goToSignIn: (email?: string) => void,
}) {
    const { isLoaded, isError, getCaptchaToken } = useCaptcha()
    const setAlert = useAlert()
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState<string | undefined>(undefined)
    const intl = useIntl()


    async function onSignUp (email: string, password: string) {
        let state = false
        setIsLoading(true)

        let answer: { uName: string, uEmail: string, state: "is-valid" } | undefined = undefined
        try {
            let reCaptchaToken = ''
            try {
                reCaptchaToken = await getCaptchaToken('signup')
                answer = await createUser(email, password, reCaptchaToken)
            } catch (e) {
                setAlert && setAlert({
                    title: intl.formatMessage({ id: 'auth.signUp.error', defaultMessage: 'Error' }),
                    label: intl.formatMessage({ id: 'auth.signUp.error.reloadPage', defaultMessage: 'Reload the page' }),
                    style: 'success'
                })
            }

        } catch (e) {
            state = false
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.signUp.error', defaultMessage: 'Error' }),
                label: intl.formatMessage({ id: 'auth.signUp.error.couldNotSignUp', defaultMessage: 'We could not sign you up. Try again.' }),
                style: 'error'
            })
        }
        state = answer?.state === 'is-valid'
        if (state) {
            setAlert && setAlert({
                title: intl.formatMessage({ id: 'auth.signUp.mailSent', defaultMessage: 'Mail sent' }),
                label: intl.formatMessage({ id: 'auth.signUp.mailSent.success', defaultMessage: 'The validation email has been sent successfully. Please check your inbox and spam folder.' }),
                style: 'success'
            })
            setEmail(answer?.uEmail)
        }
        setIsLoading(false)
        return state
    }

    useEffect(() => {
        if (email) {
            goToSignIn(email)
        }
    }, [email, goToSignIn])

    if (isError) {
        setAlert && setAlert({
            title: intl.formatMessage({ id: 'auth.signUp.error', defaultMessage: 'Error' }),
            label: intl.formatMessage({ id: 'auth.signUp.error.googleRecaptchaCouldNotBeLoaded', defaultMessage: 'Google ReCaptcha could not be loaded' }),
            style: 'error'
        })
    }

    return isLoaded ?
        (
            <SignUp
                onSubmit={onSignUp}
                isLoading={isLoading}
                onLoginClick={() => goToSignIn()} 
                />
        ) : (
            <Loader />
        )
}
