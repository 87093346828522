import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import InlineEditor from '@ckeditor/ckeditor5-build-inline/build/ckeditor'
import CKEditor from '@lemonbrain/components/UiElements/CKEditor/CKEditor'

import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Select, Value } from 'baseui/select'
import { Notification, KIND } from 'baseui/notification'
import { Textarea } from 'baseui/textarea'
import { SIZE } from 'baseui/input'
import { Tabs, Tab, FILL } from 'baseui/tabs-motion'
import { Datepicker } from 'baseui/datepicker'
import { FileUploader } from 'baseui/file-uploader'
import { Avatar } from 'baseui/avatar'
import { Grid, Cell, ALIGNMENT, BEHAVIOR } from 'baseui/layout-grid'
import { StyledDivider, SIZE as DIVIDER_SIZE } from 'baseui/divider'
import de from 'date-fns/locale/de'
import { Spinner, SIZE as SpinnerSIZE } from 'baseui/spinner'
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";

import EditContainer, {
  STATE,
} from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

import { NewsletterItem } from './NewslettersEdit.data'
import NewsletterItemsData from './NewsletterItems/NewsletterItems.data'
import './custom.css'

import {
  UpdateNewsletterMailingMutationVariables,
  UpdateNewsletterMailingItemMutationVariables,
} from '../../../gql/NewsletterMailing.generated'
import { MailingState, ArrayUpdate } from '../../../types'


export default function NewslettersEdit({
  goToNewsletter,
  isLoading,
  onUpdateClick,
  onCopyClick,
  onCancelClick,
  newsletterMailing,
  setNewsletterMailing,
  errors,
  newsletterId,
  newsletters,
  selectedNewsletter,
  setSelectedNewsletter,
  setRejectedFiles,
}: {
  goToNewsletter: (id: string | null) => void
  isLoading: boolean
  onUpdateClick: () => void
  onCopyClick: () => void
  onCancelClick: () => void
  newsletterMailing: UpdateNewsletterMailingMutationVariables & {
    toJoined: string
    mainImageSrc: string
    items: (UpdateNewsletterMailingItemMutationVariables & {
      imageSrc: string
      task: ArrayUpdate
    })[]
  }
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: ArrayUpdate
        })[]
      }
    >
  >
  errors: {
    from: string
    fromName: string
    to: string
    subject: string
    mailingDate: string
    mainImage: string
    mainImageCopyright: string
    title: string
    content: string
    leftColoumn: string
    rightColoumn: string
  }
  newsletterId?: string
  newsletters?: NewsletterItem[]
  selectedNewsletter: NewsletterItem | undefined
  setSelectedNewsletter: React.Dispatch<
    React.SetStateAction<NewsletterItem | undefined>
  >
  setRejectedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>
}) {
  const intl = useIntl()
  const [activeKey, setActiveKey] = useState<React.Key>(0)

  return (
    <>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
        <Button
          onClick={() => goToNewsletter(null)}
          startEnhancer={() => <ArrowLeft size={24} />}
        >
          <FormattedMessage
            id='newsletters.newslettersEdit.toOverview'
            defaultMessage={'To the overview'}
          />
        </Button>
      </Block>
      <EditContainer
        state={newsletterId ? STATE.UPDATE : STATE.CREATE}
        onUpdateClick={onUpdateClick}
        onCreateClick={onUpdateClick}
        onCopyClick={onCopyClick}
        onCancelClick={onCancelClick}
        isLoading={isLoading}
        noUpdate={newsletterMailing?.state === MailingState.Completed}
      >
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => setActiveKey(activeKey)}
          fill={FILL.fixed}
          renderAll
        >
          <Tab
            title={intl.formatMessage({
              id: 'newsletters.newslettersEdit.base',
              defaultMessage: '1/2 Base',
            })}
          >
            <FormControl
              label={intl.formatMessage({
                id: 'newsletters.newslettersEdit.newsletter',
                defaultMessage: 'Newsletter',
              })}
            >
              <Select
                options={newsletters}
                value={
                  selectedNewsletter
                    ? ([selectedNewsletter] as Value)
                    : undefined
                }
                placeholder={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.selectNewsletter',
                  defaultMessage: 'Select a newsletter',
                })}
                onChange={({ value }: { value: Value }) =>
                  setSelectedNewsletter({
                    ...(value[0] as NewsletterItem),
                  })
                }
              />
            </FormControl>
            {selectedNewsletter && (
              <>
                <FormControl
                  label={intl.formatMessage({
                    id: 'newsletters.newslettersEdit.fromMail',
                    defaultMessage: 'From',
                  })}
                  error={errors.from || undefined}
                >
                  <Input
                    value={newsletterMailing.from}
                    onChange={(e) =>
                      setNewsletterMailing((prev) => ({
                        ...prev,
                        from: (e.target as HTMLInputElement).value,
                      }))
                    }
                    positive={errors.from === ''}
                    type='email'
                  />
                </FormControl>
                <FormControl
                  label={intl.formatMessage({
                    id: 'newsletters.newslettersEdit.fromNameMail',
                    defaultMessage: 'From Name',
                  })}
                  error={errors.fromName || undefined}
                >
                  <Input
                    value={newsletterMailing.fromName}
                    onChange={(e) =>
                      setNewsletterMailing((prev) => ({
                        ...prev,
                        fromName: (e.target as HTMLInputElement).value,
                      }))
                    }
                    positive={errors.fromName === ''}
                  />
                </FormControl>
                <FormControl
                  label={intl.formatMessage({
                    id: 'newsletters.newslettersEdit.toMail',
                    defaultMessage: 'To',
                  })}
                  error={errors.to || undefined}
                >
                  <Textarea
                    size={SIZE.compact}
                    value={newsletterMailing.toJoined}
                    onChange={(e) =>
                      setNewsletterMailing((prev) => ({
                        ...prev,
                        toJoined: (e.target as HTMLTextAreaElement).value,
                        to: (e.target as HTMLTextAreaElement).value.split(','),
                      }))
                    }
                    placeholder={intl.formatMessage({
                      id: 'newsletters.newslettersEdit.toMail',
                      defaultMessage: 'To',
                    })}
                    clearOnEscape
                    positive={errors.to === ''}
                  />
                </FormControl>
                <FormControl
                  label={intl.formatMessage({
                    id: 'newsletters.newslettersEdit.subject',
                    defaultMessage: 'Subject',
                  })}
                  error={errors.subject || undefined}
                >
                  <Input
                    value={newsletterMailing.subject}
                    onChange={(e) =>
                      setNewsletterMailing((prev) => ({
                        ...prev,
                        subject: (e.target as HTMLInputElement).value,
                      }))
                    }
                    positive={errors.subject === ''}
                  />
                </FormControl>
                <FormControl
                  label={intl.formatMessage({
                    id: 'newsletters.newslettersEdit.mailingDate',
                    defaultMessage: 'Date',
                  })}
                  error={errors.mailingDate || undefined}
                >
                  <Datepicker
                    locale={de}
                    onChange={({ date }) =>
                      setNewsletterMailing((prev) => ({
                        ...prev,
                        //@ts-ignore
                        mailingDate: date ? date.toISOString() : undefined,
                      }))
                    }
                    value={
                      newsletterMailing && newsletterMailing.mailingDate
                        ? new Date(newsletterMailing.mailingDate)
                        : undefined
                    }
                    formatString='dd.MM.yyyy'
                    placeholder='dd.MM.yyyy'
                    mask={null}
                    positive={errors.mailingDate === ''}
                  />
                </FormControl>
                <FormControl
                  label={intl.formatMessage({
                    id: 'newsletters.newslettersEdit.showOnWebsite',
                    defaultMessage: 'Show on website',
                  })}
                >
                <Checkbox
                  checked={newsletterMailing && newsletterMailing.showOnWebsite as boolean}
                  onChange={(e) => setNewsletterMailing((prev) => ({
                    ...prev,
                    showOnWebsite: e.target.checked,
                  }))}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                </Checkbox>
                </FormControl>
              </>
            )}
          </Tab>
          {newsletterMailing.id && (
            <Tab
              title={intl.formatMessage({
                id: 'newsletters.newslettersEdit.content2',
                defaultMessage: '2/2 Content',
              })}
            >
              <FormControl
                label={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.mainImage',
                  defaultMessage: 'Image',
                })}
                error={errors.mainImage || undefined}
              >
                <Grid align={ALIGNMENT.center} behavior={BEHAVIOR.fluid}>
                  <Cell span={[6]}>
                    {newsletterMailing.mainImageSrc && (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                        }}
                        size='140px'
                        src={newsletterMailing.mainImageSrc}
                      />
                    )}
                  </Cell>
                  <Cell span={[6]}>
                    <FileUploader
                      accept={'image/*'}
                      // errorMessage={errors.mainImage}
                      onDrop={(acceptedFiles, rejectedFiles) => {
                        if (acceptedFiles.length > 0) {
                          const fileReader = new FileReader()
                          fileReader.readAsDataURL(acceptedFiles[0])
                          fileReader.addEventListener('load', function () {
                            setNewsletterMailing((prev) => ({
                              ...prev,
                              mainImage: acceptedFiles[0],
                              mainImageSrc: this.result?.toString() || '',
                            }))
                          })
                        }
                        setRejectedFiles(rejectedFiles)
                      }}
                      progressMessage={isLoading ? `Uploading...` : ''}
                      overrides={{
                        ContentMessage: {
                          component: () => (
                            <span>
                              {intl.formatMessage({
                                id: 'newsletters.newslettersEdit.dropFilesHere',
                                defaultMessage: 'Drop file here to upload...',
                              })}
                            </span>
                          ),
                        },
                      }}
                    />
                  </Cell>
                </Grid>
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.mainImageCopyright',
                  defaultMessage: 'Copyright',
                })}
                error={errors.mainImageCopyright || undefined}
              >
                <Input
                  value={newsletterMailing.mainImageCopyright}
                  onChange={(e) =>
                    setNewsletterMailing((prev) => ({
                      ...prev,
                      mainImageCopyright: (e.target as HTMLInputElement).value,
                    }))
                  }
                  positive={errors.mainImageCopyright === ''}
                />
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.title',
                  defaultMessage: 'Title',
                })}
                error={errors.title || undefined}
              >
                <Input
                  value={newsletterMailing.title}
                  onChange={(e) =>
                    setNewsletterMailing((prev) => ({
                      ...prev,
                      title: (e.target as HTMLInputElement).value,
                    }))
                  }
                  positive={errors.title === ''}
                />
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.content',
                  defaultMessage: 'Content',
                })}
                error={errors.content || undefined}
              >
                <CKEditor
                  //@ts-ignore
                  style={{
                    color: '#fff',
                  }}
                  editor={InlineEditor}
                  config={{
                    language: 'de-ch',
                    toolbar: [
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'insertTable',
                      'undo',
                      'redo',
                    ],
                  }}
                  data={newsletterMailing.content}
                  //@ts-ignore
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData()
                    setNewsletterMailing((prev) => ({
                      ...prev,
                      content: data,
                    }))
                  }}
                />
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.leftColoumn',
                  defaultMessage: 'Left coloumn',
                })}
                error={errors.leftColoumn || undefined}
              >
                <CKEditor
                  //@ts-ignore
                  style={{
                    color: '#fff',
                  }}
                  editor={InlineEditor}
                  config={{
                    language: 'de-ch',
                    toolbar: [
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'insertTable',
                      'undo',
                      'redo',
                    ],
                  }}
                  data={newsletterMailing.leftColoumn}
                  //@ts-ignore
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData()
                    setNewsletterMailing((prev) => ({
                      ...prev,
                      leftColoumn: data,
                    }))
                  }}
                />
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.rightColoumn',
                  defaultMessage: 'Right coloumn',
                })}
                error={errors.rightColoumn || undefined}
              >
                <CKEditor
                  //@ts-ignore
                  style={{
                    color: '#fff',
                  }}
                  editor={InlineEditor}
                  config={{
                    language: 'de-ch',
                    toolbar: [
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'insertTable',
                      'undo',
                      'redo',
                    ],
                  }}
                  data={newsletterMailing.rightColoumn}
                  //@ts-ignore
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData()
                    setNewsletterMailing((prev) => ({
                      ...prev,
                      rightColoumn: data,
                    }))
                  }}
                />
              </FormControl>
              <StyledDivider $size={DIVIDER_SIZE.section} />
              <FormControl
                label={intl.formatMessage({
                  id: 'newsletters.newslettersEdit.items',
                  defaultMessage: 'Items',
                })}
              >
                {isLoading ? (
                  <Spinner $size={SpinnerSIZE.large} />
                ) : (
                  <NewsletterItemsData
                    newsletterMailing={newsletterMailing}
                    setNewsletterMailing={setNewsletterMailing}
                  />
                )}
              </FormControl>
            </Tab>
          )}
        </Tabs>
      </EditContainer>
      {Object.values(errors).find((item) => item !== '') && (
        <Notification kind={KIND.negative}>
          <ul>
            {Object.keys(errors).map(
              (item) =>
                (errors as { [key: string]: string })[item] && (
                  <li key={item}>
                    {(errors as { [key: string]: string })[item]}
                  </li>
                )
            )}
          </ul>
        </Notification>
      )}
      {newsletterMailing?.state === MailingState.Completed && (
        <Notification kind={KIND.info}>
          <FormattedMessage
            id={`newsletters.newsletterSend.state.successfullSended`}
            defaultMessage='Successfull sended newsletter need to be copied to send again'
          />
        </Notification>
      )}
    </>
  )
}
