// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type StateFunctionFragmentFragment = (
  { __typename?: 'StateFunction' }
  & Pick<Types.StateFunction, 'id' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetStateFunctionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetStateFunctionQuery = (
  { __typename?: 'Query' }
  & { getStateFunction: (
    { __typename?: 'StateFunction' }
    & StateFunctionFragmentFragment
  ) }
);

export type AddStateFunctionMutationVariables = Types.Exact<{
  name: Types.TranslationObjectCreate;
}>;


export type AddStateFunctionMutation = (
  { __typename?: 'Mutation' }
  & { addStateFunction: (
    { __typename?: 'StateFunction' }
    & StateFunctionFragmentFragment
  ) }
);

export type UpdateStateFunctionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.TranslationObjectUpdate;
}>;


export type UpdateStateFunctionMutation = (
  { __typename?: 'Mutation' }
  & { updateStateFunction: (
    { __typename?: 'StateFunction' }
    & StateFunctionFragmentFragment
  ) }
);

export type DeleteStateFunctionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteStateFunctionMutation = (
  { __typename?: 'Mutation' }
  & { deleteStateFunction: (
    { __typename?: 'StateFunction' }
    & StateFunctionFragmentFragment
  ) }
);

export type GetStateFunctionsNoPersonQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetStateFunctionsNoPersonQuery = (
  { __typename?: 'Query' }
  & { getStateFunctionsNoPerson: (
    { __typename?: 'StateFunctionPagination' }
    & Pick<Types.StateFunctionPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'StateFunction' }
      & StateFunctionFragmentFragment
    )> }
  ) }
);

export type GetNextStateFunctionsNoPersonQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextStateFunctionsNoPersonQuery = (
  { __typename?: 'Query' }
  & { getNextStateFunctionsNoPerson: (
    { __typename?: 'StateFunctionPagination' }
    & Pick<Types.StateFunctionPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'StateFunction' }
      & StateFunctionFragmentFragment
    )> }
  ) }
);

export const StateFunctionFragmentFragmentDoc = gql`
    fragment StateFunctionFragment on StateFunction {
  id
  name {
    de
    fr
    it
  }
  createdFrom
}
    `;
export const GetStateFunctionDocument = gql`
    query getStateFunction($id: ID!) {
  getStateFunction(id: $id) {
    ...StateFunctionFragment
  }
}
    ${StateFunctionFragmentFragmentDoc}`;

/**
 * __useGetStateFunctionQuery__
 *
 * To run a query within a React component, call `useGetStateFunctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateFunctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateFunctionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStateFunctionQuery(baseOptions: Apollo.QueryHookOptions<GetStateFunctionQuery, GetStateFunctionQueryVariables>) {
        return Apollo.useQuery<GetStateFunctionQuery, GetStateFunctionQueryVariables>(GetStateFunctionDocument, baseOptions);
      }
export function useGetStateFunctionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStateFunctionQuery, GetStateFunctionQueryVariables>) {
          return Apollo.useLazyQuery<GetStateFunctionQuery, GetStateFunctionQueryVariables>(GetStateFunctionDocument, baseOptions);
        }
export type GetStateFunctionQueryHookResult = ReturnType<typeof useGetStateFunctionQuery>;
export type GetStateFunctionLazyQueryHookResult = ReturnType<typeof useGetStateFunctionLazyQuery>;
export type GetStateFunctionQueryResult = Apollo.QueryResult<GetStateFunctionQuery, GetStateFunctionQueryVariables>;
export const AddStateFunctionDocument = gql`
    mutation addStateFunction($name: TranslationObjectCreate!) {
  addStateFunction(name: $name) {
    ...StateFunctionFragment
  }
}
    ${StateFunctionFragmentFragmentDoc}`;
export type AddStateFunctionMutationFn = Apollo.MutationFunction<AddStateFunctionMutation, AddStateFunctionMutationVariables>;

/**
 * __useAddStateFunctionMutation__
 *
 * To run a mutation, you first call `useAddStateFunctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStateFunctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStateFunctionMutation, { data, loading, error }] = useAddStateFunctionMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddStateFunctionMutation(baseOptions?: Apollo.MutationHookOptions<AddStateFunctionMutation, AddStateFunctionMutationVariables>) {
        return Apollo.useMutation<AddStateFunctionMutation, AddStateFunctionMutationVariables>(AddStateFunctionDocument, baseOptions);
      }
export type AddStateFunctionMutationHookResult = ReturnType<typeof useAddStateFunctionMutation>;
export type AddStateFunctionMutationResult = Apollo.MutationResult<AddStateFunctionMutation>;
export type AddStateFunctionMutationOptions = Apollo.BaseMutationOptions<AddStateFunctionMutation, AddStateFunctionMutationVariables>;
export const UpdateStateFunctionDocument = gql`
    mutation updateStateFunction($id: ID!, $name: TranslationObjectUpdate!) {
  updateStateFunction(id: $id, name: $name) {
    ...StateFunctionFragment
  }
}
    ${StateFunctionFragmentFragmentDoc}`;
export type UpdateStateFunctionMutationFn = Apollo.MutationFunction<UpdateStateFunctionMutation, UpdateStateFunctionMutationVariables>;

/**
 * __useUpdateStateFunctionMutation__
 *
 * To run a mutation, you first call `useUpdateStateFunctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateFunctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateFunctionMutation, { data, loading, error }] = useUpdateStateFunctionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateStateFunctionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateFunctionMutation, UpdateStateFunctionMutationVariables>) {
        return Apollo.useMutation<UpdateStateFunctionMutation, UpdateStateFunctionMutationVariables>(UpdateStateFunctionDocument, baseOptions);
      }
export type UpdateStateFunctionMutationHookResult = ReturnType<typeof useUpdateStateFunctionMutation>;
export type UpdateStateFunctionMutationResult = Apollo.MutationResult<UpdateStateFunctionMutation>;
export type UpdateStateFunctionMutationOptions = Apollo.BaseMutationOptions<UpdateStateFunctionMutation, UpdateStateFunctionMutationVariables>;
export const DeleteStateFunctionDocument = gql`
    mutation deleteStateFunction($id: ID!) {
  deleteStateFunction(id: $id) {
    ...StateFunctionFragment
  }
}
    ${StateFunctionFragmentFragmentDoc}`;
export type DeleteStateFunctionMutationFn = Apollo.MutationFunction<DeleteStateFunctionMutation, DeleteStateFunctionMutationVariables>;

/**
 * __useDeleteStateFunctionMutation__
 *
 * To run a mutation, you first call `useDeleteStateFunctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStateFunctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStateFunctionMutation, { data, loading, error }] = useDeleteStateFunctionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStateFunctionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStateFunctionMutation, DeleteStateFunctionMutationVariables>) {
        return Apollo.useMutation<DeleteStateFunctionMutation, DeleteStateFunctionMutationVariables>(DeleteStateFunctionDocument, baseOptions);
      }
export type DeleteStateFunctionMutationHookResult = ReturnType<typeof useDeleteStateFunctionMutation>;
export type DeleteStateFunctionMutationResult = Apollo.MutationResult<DeleteStateFunctionMutation>;
export type DeleteStateFunctionMutationOptions = Apollo.BaseMutationOptions<DeleteStateFunctionMutation, DeleteStateFunctionMutationVariables>;
export const GetStateFunctionsNoPersonDocument = gql`
    query getStateFunctionsNoPerson($cursor: StartCursorInput!) {
  getStateFunctionsNoPerson(cursor: $cursor) {
    items {
      ...StateFunctionFragment
    }
    nextCursor
  }
}
    ${StateFunctionFragmentFragmentDoc}`;

/**
 * __useGetStateFunctionsNoPersonQuery__
 *
 * To run a query within a React component, call `useGetStateFunctionsNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateFunctionsNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateFunctionsNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetStateFunctionsNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetStateFunctionsNoPersonQuery, GetStateFunctionsNoPersonQueryVariables>) {
        return Apollo.useQuery<GetStateFunctionsNoPersonQuery, GetStateFunctionsNoPersonQueryVariables>(GetStateFunctionsNoPersonDocument, baseOptions);
      }
export function useGetStateFunctionsNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStateFunctionsNoPersonQuery, GetStateFunctionsNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetStateFunctionsNoPersonQuery, GetStateFunctionsNoPersonQueryVariables>(GetStateFunctionsNoPersonDocument, baseOptions);
        }
export type GetStateFunctionsNoPersonQueryHookResult = ReturnType<typeof useGetStateFunctionsNoPersonQuery>;
export type GetStateFunctionsNoPersonLazyQueryHookResult = ReturnType<typeof useGetStateFunctionsNoPersonLazyQuery>;
export type GetStateFunctionsNoPersonQueryResult = Apollo.QueryResult<GetStateFunctionsNoPersonQuery, GetStateFunctionsNoPersonQueryVariables>;
export const GetNextStateFunctionsNoPersonDocument = gql`
    query getNextStateFunctionsNoPerson($cursor: NextCursorInput!) {
  getNextStateFunctionsNoPerson(cursor: $cursor) {
    items {
      ...StateFunctionFragment
    }
    nextCursor
  }
}
    ${StateFunctionFragmentFragmentDoc}`;

/**
 * __useGetNextStateFunctionsNoPersonQuery__
 *
 * To run a query within a React component, call `useGetNextStateFunctionsNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextStateFunctionsNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextStateFunctionsNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextStateFunctionsNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetNextStateFunctionsNoPersonQuery, GetNextStateFunctionsNoPersonQueryVariables>) {
        return Apollo.useQuery<GetNextStateFunctionsNoPersonQuery, GetNextStateFunctionsNoPersonQueryVariables>(GetNextStateFunctionsNoPersonDocument, baseOptions);
      }
export function useGetNextStateFunctionsNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextStateFunctionsNoPersonQuery, GetNextStateFunctionsNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetNextStateFunctionsNoPersonQuery, GetNextStateFunctionsNoPersonQueryVariables>(GetNextStateFunctionsNoPersonDocument, baseOptions);
        }
export type GetNextStateFunctionsNoPersonQueryHookResult = ReturnType<typeof useGetNextStateFunctionsNoPersonQuery>;
export type GetNextStateFunctionsNoPersonLazyQueryHookResult = ReturnType<typeof useGetNextStateFunctionsNoPersonLazyQuery>;
export type GetNextStateFunctionsNoPersonQueryResult = Apollo.QueryResult<GetNextStateFunctionsNoPersonQuery, GetNextStateFunctionsNoPersonQueryVariables>;