import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import NewslettersAddBusiness from './NewslettersAdd.business'

export default function NewslettersAddData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()

  const goToNewsletter = function (id: string | null) {
    if (id && id !== '') {
      pushPath(`/${language}/newsletters/overview/${id}`)
    } else {
      pushPath(`/${language}/newsletters/overview`)
    }
  }

  return <NewslettersAddBusiness subNav={subNav} goToNewsletter={goToNewsletter} />
}
