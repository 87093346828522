import { Fragment, useState, useEffect } from 'react'
import { Block } from 'baseui/block'
import { FormControl } from 'baseui/form-control'
import { useIntl, FormattedMessage } from 'react-intl'
import { AiFillCloseSquare } from 'react-icons/ai'
import { IoIosEye } from 'react-icons/io'
import { useStyletron } from 'baseui'
import { StyledTable, StyledBodyCell } from 'baseui/table-grid'

import { useAppState } from '@lemonbrain/contexts/PageWrapper'
import { StyledTableHeadAlt } from '@lemonbrain/components/PageStyles/Apps.styled'
import IconButton from '@lemonbrain/components/UiElements/IconButton/IconButton'
import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'
import Loader from '@lemonbrain/components/UiElements/Loader/Loader'
import { useInfiniteScroll } from '@lemonbrain/hooks'

export type RowDataT = {
  de: string
  fr: string
  it: string
  createdFrom: string
}

export default function SportsEducationTable({
  isLoading,
  rows,
  fetchMoreListItems,
  editRow,
  removeRow,
}: {
  isLoading: boolean
  rows: {
    id: string
    data: RowDataT
  }[]
  fetchMoreListItems: () => void
  editRow: (id: string) => void
  removeRow: (id: string) => void
}) {
  const { components } = useAppState()
  const [css] = useStyletron()
  const intl = useIntl()
  const { isFetching, setIsFetching } = useInfiniteScroll(fetchMoreListItems)
  const [deleteId, setDeleteId] = useState<string | undefined>()
  const DisplayNameData = components && components['DisplayNameData']

  useEffect(() => {
    if (!isLoading && isFetching) {
      setIsFetching(false)
    }
  }, [isFetching, isLoading])

  return (
    <>
      <SimpleModal<string>
        isOpen={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'settings.sportsEducationTable.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'settings.sportsEducationTable.deleteConfirm',
          defaultMessage: 'Are you sure to delete this sports education?',
        })}
        data={deleteId || ''}
        onConfirm={(deleteId: string) => {
          removeRow(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'settings.sportsEducationTable.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>
      <FormControl
        label={intl.formatMessage({
          id: 'settings.sportsEducationTable.sportsEducations',
          defaultMessage: 'Sports educations',
        })}
        caption={intl.formatMessage({
          id: 'settings.sportsEducationTable.editOrDeleteASportsEducation',
          defaultMessage: 'Edit or delete a sports education',
        })}
      >
        <>
          {rows.length > 0 ? (
            <>
              <StyledTable $gridTemplateColumns='max-content max-content max-content max-content max-content'>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.sportsEducationTable.action',
                    defaultMessage: 'Action',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.sportsEducationTable.de',
                    defaultMessage: 'Label (German)',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.sportsEducationTable.fr',
                    defaultMessage: 'Label (French)',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.sportsEducationTable.it',
                    defaultMessage: 'Label (Italien)',
                  })}
                </StyledTableHeadAlt>
                <StyledTableHeadAlt>
                  {intl.formatMessage({
                    id: 'settings.sportsEducationTable.createdFrom',
                    defaultMessage: 'Created from',
                  })}
                </StyledTableHeadAlt>
                {rows.map((item, index) => {
                  const striped = index % 2 === 0
                  return (
                    <Fragment key={index}>
                      <StyledBodyCell $striped={striped}>
                        <IconButton onClick={() => editRow(item.id)}>
                          <IoIosEye />
                        </IconButton>
                        <IconButton onClick={() => setDeleteId(item.id)}>
                          <AiFillCloseSquare />
                        </IconButton>
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.de}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.fr}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {item.data.it}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {DisplayNameData && (
                          //@ts-ignores
                          <DisplayNameData userId={item.data.createdFrom} />
                        )}
                      </StyledBodyCell>
                    </Fragment>
                  )
                })}
              </StyledTable>
            </>
          ) : (
            <Block
              as='span'
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      color: $theme.colors.primaryA,
                    }
                  },
                },
              }}
            >
              {intl.formatMessage({
                id: 'settings.sportsEducationTable.noEntries',
                defaultMessage: 'There are no entries',
              })}
            </Block>
          )}
        </>
      </FormControl>
      {(isFetching || isLoading) && (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <Loader />
          <Block
            as='p'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    textAlign: 'center',
                    marginTop: '15px',
                    color: $theme.colors.primaryA,
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='settings.sportsEducationTable.fetchingMore'
              defaultMessage={'Fetching more list items...'}
            />
          </Block>
        </div>
      )}
    </>
  )
}
