import { ReactApolloCients } from '@lemonbrain/utils'

import introspectionResult from './introspection-result'

let reactApolloCients: ReactApolloCients;
const getReactApolloCients = function() {
    if(!reactApolloCients){
        reactApolloCients = new ReactApolloCients({
            apolloUrl: process.env.REACT_APP_MITGLIEDER_URL,
            // apolloWebsocketUrl: process.env.REACT_APP_MITGLIEDER_WEBSOCKET_URL,
            introspectionResult: introspectionResult
        })
    }
    return reactApolloCients
}

export default getReactApolloCients