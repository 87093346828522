import NewslettersData from './views/Newsletters/Newsletters.data'
import NewslettersOverviewData from './views/NewslettersOverview/NewslettersOverview.data'
import NewslettersAddData from './views/NewslettersAdd/NewslettersAdd.data'

import germanMessages from './messages/de'
import getReactApolloCients from './localReactApollo'

import {
  addMenuRoutesBeforeRender,
  addGermanMessagesBeforeRender,
  addWsClientBeforeRender,
} from '@lemonbrain/hooks'

addMenuRoutesBeforeRender('MAIN', [
  {
    title: {
      id: 'newsletters.newsletters',
      defaultMessage: 'Newsletters',
    },
    icon: 'newsletters',
    id: 'newsletters',
    path: '/newsletters/',
    routePath: '/newsletters',
    component: NewslettersData,
    size: 'small',
    tabIndex: 10,
    visible: true,
    active: true,
    enabled: true,
    accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
    children: [
      {
        title: {
          id: 'newsletters.overview',
          defaultMessage: 'Overview',
        },
        icon: 'newsletters',
        id: 'newslettersOverview',
        path: '/newsletters/overview/:newsletterId?',
        routePath: '/newsletters/overview',
        component: NewslettersOverviewData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'newsletters.add',
          defaultMessage: 'Add',
        },
        icon: 'newsletters',
        id: 'newslettersAdd',
        path: '/newsletters/add',
        routePath: '/newsletters/add',
        component: NewslettersAddData,
        size: 'small',
        tabIndex: 12,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
    ],
  },
])

const wsClient = getReactApolloCients().getWsClient()
if (wsClient) {
  addWsClientBeforeRender(wsClient)
}

addGermanMessagesBeforeRender(germanMessages)
