import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'
import { UserType } from '@lemonbrain/hooks'
import { isInGroupOrAdmin } from '@lemonbrain/utils'

import MembersEditData from './MemebersEdit/MembersEdit.data'
import MembersEditIfManagesData from './MemebersEdit/MembersEditIfManages.data'
import MembersTableData from './MembersTable/MembersTable.data'
import MembersTableIfManagesData from './MembersTable/MembersTableIfManages.data'
import MembersFilterData from './MembersFilter/MembersFilter.data'
import MembersFunctions from './MembersFunctions/MembersFunctions'

export default function MembersOverview({
  subNav,
  goToPerson,
  goToCourses,
  goToInvoices,
  personId,
  searchQuery,
  setSearchQuery,
  associationFilter,
  setAssociationFilter,
  workLevelFilter,
  setWorkLevelFilter,
  sportsEducationFilter,
  setSportsEducationFilter,
  stateFilter,
  setStateFilter,
  stateTypeFilter,
  setStateTypeFilter,
  membershipFilter,
  setMembershipFilter,
  selectedPerson,
  setSelectedPerson,
  user,
}: {
  subNav: InnerRoute[]
  goToPerson: (id: string | null) => void
  goToCourses: (id: string | null) => void
  goToInvoices: (id: string | null) => void
  personId: string | undefined
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  associationFilter: string[] | null
  setAssociationFilter: React.Dispatch<React.SetStateAction<string[] | null>>
  workLevelFilter: string[] | null
  setWorkLevelFilter: React.Dispatch<React.SetStateAction<string[] | null>>
  sportsEducationFilter: string[] | null
  setSportsEducationFilter: React.Dispatch<
    React.SetStateAction<string[] | null>
  >
  stateFilter: string | null
  setStateFilter: React.Dispatch<React.SetStateAction<string | null>>
  stateTypeFilter: string | null
  setStateTypeFilter: React.Dispatch<React.SetStateAction<string | null>>
  membershipFilter: string | null
  setMembershipFilter: React.Dispatch<React.SetStateAction<string | null>>
  selectedPerson: string[]
  setSelectedPerson: React.Dispatch<React.SetStateAction<string[]>>
  user: UserType | undefined
}) {
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <Block>
              <Block>
                <Block
                  paddingTop={['15px', '15px', '15px', 0]}
                  overrides={{
                    Block: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                      },
                    },
                  }}
                >
                  <Block>
                    <Block
                      as='h3'
                      overrides={{
                        Block: {
                          style: ({ $theme }) => {
                            return {
                              ...$theme.typography.font450,
                              color: $theme.colors.primaryA,
                              marginBottom: '15px',
                            }
                          },
                        },
                      }}
                    >
                      <FormattedMessage
                        id='members.members_overview'
                        defaultMessage={'Overview'}
                      />
                    </Block>
                  </Block>
                </Block>
              </Block>
            </Block>
            <Block>
              {personId && parseInt(personId) > 0 ? (
                user &&
                isInGroupOrAdmin(user, [
                  process.env.REACT_APP_GROUP_MANAGERS || '',
                  process.env.REACT_APP_GROUP_ADMINS || '',
                ]) ? (
                  <MembersEditData
                    goToPerson={goToPerson}
                    goToCourses={goToCourses}
                    goToInvoices={goToInvoices}
                    personId={personId}
                  />
                ) : (
                  <MembersEditIfManagesData
                    goToPerson={goToPerson}
                    goToCourses={goToCourses}
                    goToInvoices={goToInvoices}
                    personId={personId}
                  />
                )
              ) : (
                <>
                  <Block
                    overrides={{
                      Block: {
                        style: () => {
                          return {
                            marginBottom: '15px',
                          }
                        },
                      },
                    }}
                  >
                    <MembersFilterData
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      associationFilter={associationFilter}
                      setAssociationFilter={setAssociationFilter}
                      workLevelFilter={workLevelFilter}
                      setWorkLevelFilter={setWorkLevelFilter}
                      sportsEducationFilter={sportsEducationFilter}
                      setSportsEducationFilter={setSportsEducationFilter}
                      stateFilter={stateFilter}
                      setStateFilter={setStateFilter}
                      stateTypeFilter={stateTypeFilter}
                      setStateTypeFilter={setStateTypeFilter}
                      membershipFilter={membershipFilter}
                      setMembershipFilter={setMembershipFilter}
                    />
                  </Block>
                  <Block
                    overrides={{
                      Block: {
                        style: () => {
                          return {
                            marginBottom: '0',
                          }
                        },
                      },
                    }}
                  >
                    <MembersFunctions
                      searchQuery={searchQuery}
                      associationFilter={associationFilter}
                      workLevelFilter={workLevelFilter}
                      sportsEducationFilter={sportsEducationFilter}
                      stateFilter={stateFilter}
                      stateTypeFilter={stateTypeFilter}
                      membershipFilter={membershipFilter}
                      selectedPerson={selectedPerson}
                      setSelectedPerson={setSelectedPerson}
                      user={user}
                    />
                  </Block>
                  {user &&
                  isInGroupOrAdmin(user, [
                    process.env.REACT_APP_GROUP_MANAGERS || '',
                    process.env.REACT_APP_GROUP_ADMINS || '',
                  ]) ? (
                    <MembersTableData
                      goToPerson={goToPerson}
                      searchQuery={searchQuery}
                      associationFilter={associationFilter}
                      workLevelFilter={workLevelFilter}
                      sportsEducationFilter={sportsEducationFilter}
                      stateFilter={stateFilter}
                      stateTypeFilter={stateTypeFilter}
                      membershipFilter={membershipFilter}
                      selectedPerson={selectedPerson}
                      setSelectedPerson={setSelectedPerson}
                    />
                  ) : (
                    <MembersTableIfManagesData
                      goToPerson={goToPerson}
                      searchQuery={searchQuery}
                      associationFilter={associationFilter}
                      workLevelFilter={workLevelFilter}
                      sportsEducationFilter={sportsEducationFilter}
                      stateFilter={stateFilter}
                      stateTypeFilter={stateTypeFilter}
                      membershipFilter={membershipFilter}
                      selectedPerson={selectedPerson}
                      setSelectedPerson={setSelectedPerson}
                    />
                  )}
                </>
              )}
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
