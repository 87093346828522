import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import StateBusiness from './State.business'
import getReactApolloCients from '../../../../localReactApollo'
import { useGetStatesNoPersonQuery } from '../../../../gql/members/State.generated'
import { useGetMembershipsNoPersonQuery } from '../../../../gql/members/Membership.generated'
import { StateType } from '../../../../gql/members/types'

export default function StateData({
  state,
  setState,
  stateType,
  setStateType,
  membership,
  setMembership,
  getHasStateErrors,
}: {
  state?: string
  setState: React.Dispatch<React.SetStateAction<string>>
  membership?: string
  setMembership: React.Dispatch<React.SetStateAction<string>>
  stateType?: string
  setStateType: React.Dispatch<React.SetStateAction<string>>
  getHasStateErrors: (errors: { state: string; stateType: string; membership: string; }) => void
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [states, setStates] = useState<
    {
      id: string
      name: string
      types:
        | {
            id: string
            name: string
            requireMembership: boolean
          }[]
        | null
    }[]
  >([])
  const [memberships, setMemberships] = useState<
    {
      id: string
      name: string
    }[]
  >([])
  const client = getReactApolloCients().getClient()
  const {
    data: dataStates,
    loading: loadingStates,
    error: errorStates,
  } = useGetStatesNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })
  const {
    data: dataMemberships,
    loading: loadingMemberships,
    error: errorMemberships,
  } = useGetMembershipsNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (errorStates || errorMemberships) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.state.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.state.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorStates?.message || errorMemberships?.message)
    }
  }, [errorStates, errorMemberships])

  useEffect(() => {
    if (dataStates?.getStatesNoPerson.items) {
      setStates(
        dataStates?.getStatesNoPerson.items
          .map((item) => ({
            id: item.id,
            name: getTranslation(item.name, intl),
            types: item.types
              ? item.types
              //@ts-ignore
                  .map((item1: StateType) => ({
                    id: item1.id,
                    name: getTranslation(item1.name, intl),
                    requireMembership: item1.requireMembership,
                  }))
                  .sort(function (a: { name: string }, b: { name: string }) {
                    var labelA = a.name.toUpperCase()
                    var labelB = b.name.toUpperCase()
                    if (labelA < labelB) {
                      return -1
                    }
                    if (labelA > labelB) {
                      return 1
                    }

                    return 0
                  })
              : null,
          }))
          .sort(function (a, b) {
            var labelA = a.name.toUpperCase()
            var labelB = b.name.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataStates])

  useEffect(() => {
    if (dataMemberships?.getMembershipsNoPerson.items) {
      setMemberships(
        dataMemberships?.getMembershipsNoPerson.items
          .map((item) => ({
            id: item.id,
            name: getTranslation(item.name, intl),
          }))
          .sort(function (a, b) {
            var labelA = a.name.toUpperCase()
            var labelB = b.name.toUpperCase()
            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }

            return 0
          })
      )
    }
  }, [dataMemberships])

  return (
    <StateBusiness
      state={state}
      setState={setState}
      membership={membership}
      setMembership={setMembership}
      stateType={stateType}
      setStateType={setStateType}
      loadingStates={loadingStates || loadingMemberships}
      states={states}
      memberships={memberships}
      getHasStateErrors={getHasStateErrors}
    />
  )
}
