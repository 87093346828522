const LocaleData = {
  'newsletters.newsletters': 'Newsletter',

  'newsletters.overview': 'Übersicht',
  'newsletters.add': 'Hinzufügen',

  'newsletters.newslettersEdit.toOverview': 'Zur Übersicht',
  'newsletters.newslettersEdit.saved': 'Gespeichert',
  'newsletters.newslettersEdit.saved.updated':
    'Der Newsletter wurde aktualisiert',
  'newsletters.newslettersEdit.error': 'Fehler',
  'newsletters.newslettersEdit.error.thereWasAServerError':
    'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
  'newsletters.newslettersEdit.errorMessage':
    'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
  'newsletters.newslettersEdit.error.notLoggedIn':
    'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
  'newsletters.newslettersEdit.error.subjectRequired': 'Betreff ist verlangt',
  'newsletters.newslettersEdit.error.fromRequired': 'Von ist verlangt',
  'newsletters.newslettersEdit.error.fromNameRequired': 'Von Name ist verlangt',
  'newsletters.newslettersEdit.error.toRequired': 'An ist verlangt',
  'newsletters.newslettersEdit.error.mailingDateRequired': 'Datum ist verlangt',
  'newsletters.newslettersEdit.newsletter': 'Newsletter',
  'newsletters.newslettersEdit.selectNewsletter': 'Wähle einen Newsletter',
  'newsletters.newslettersEdit.fromMail': 'Von',
  'newsletters.newslettersEdit.fromNameMail': 'Von Name',
  'newsletters.newslettersEdit.toMail': 'An',
  'newsletters.newslettersEdit.subject': 'Betreff',
  'newsletters.newslettersEdit.mailingDate': 'Datum',
  'newsletters.newslettersEdit.base': '1/2 Basis',
  'newsletters.newslettersEdit.content2': '2/2 Inhalt',
  'newsletters.newslettersEdit.rejectFormats':
    'Folgende Dateien werden nicht unterstützt: {files}',
  'newsletters.newslettersEdit.mainImageCopyright': 'Copyright',
  'newsletters.newslettersEdit.title': 'Titel',
  'newsletters.newslettersEdit.error.mainImageCopyrightRequired':
    'Copyright ist verlangt',
  'newsletters.newslettersEdit.error.titleRequired': 'Title ist verlangt',
  'newsletters.newslettersEdit.content': 'Inhalt',
  'newsletters.newslettersEdit.leftColoumn': 'Linke Spalte',
  'newsletters.newslettersEdit.rightColoumn': 'Rechte Spalte',
  'newsletters.newslettersEdit.error.contentRequired': 'Inhalt ist verlangt',
  'newsletters.newslettersEdit.error.leftColoumnRequired':
    'Linke Spalte ist verlangt',
  'newsletters.newslettersEdit.error.rightColoumnRequired':
    'Rechte Spalte ist verlangt',
  'newsletters.newslettersEdit.dropFilesHere':
    'Füge die Datei hier hinzu für den Upload',
  'newsletters.newslettersEdit.mainImage': 'Bild',
  'newsletters.newslettersEdit.error.mainImageRequired': 'Bild ist verlangt',
  'newsletters.newslettersEdit.items': 'Einträge',
  'newsletters.newslettersEdit.copyOf': 'Kopie von {newsletterId}',
  'newsletters.newslettersEdit.showOnWebsite': 'Auf der Website anzeigen',

  'newslettersAdd.newsletters_add': 'Newsletter hinzufügen',

  'newsletters.newslettersTable.newsletter': 'Newsletter',
  'newsletters.newslettersTable.from': 'Von',
  'newsletters.newslettersTable.subject': 'Betreff',
  'newsletters.newslettersTable.mailingDate': 'Datum',
  'newsletters.newslettersTable.actions': 'Funktionen',
  'newsletters.newslettersTable.loading': 'Laden...',
  'newsletters.newslettersTable.results_count': 'Zeigt {pageLength} Resultate',
  'newsletters.newslettersTable.load_mores': 'Mehr laden',
  'newsletters.newslettersTable.page_count': 'Seite {currentPage}',
  'newsletters.newslettersTable.page_size_selection':
    'Zeige {pageSize} Elemente pro Seite',
  'newsletters.newslettersTable.delete': 'Löschen',
  'newsletters.newslettersTable.deleteConfirm':
    'Sicher, dass dieser Newsletter gelöscht werden soll?',
  'newsletters.newslettersTable.error': 'Fehler',
  'newsletters.newslettersTable.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',

  'newsletters.newsletterItems.error': 'Fehler',
  'newsletters.newsletterItems.errorMessage':
    'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',

  'newsletters.newsletterItem.saved': 'Gespeichert',
  'newsletters.newslettersEdit.created': 'Erstellt',
  'newsletters.newslettersEdit.saved.created': 'Der Newsletter wurde erstellt',
  'newsletters.newsletterItem.saved.updated':
    'Der Newslettereintrag wurde aktualisiert',
  'newsletters.newsletterItem.error': 'Fehler',
  'newsletters.newsletterItem.errorMessage':
    'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
  'newsletters.newsletterItem.error.imageCopyright': 'Copyright ist verlangt',
  'newsletters.newsletterItem.error.titleRequired': 'Titel ist verlangt',
  'newsletters.newsletterItem.error.contentRequired': 'Inhalt ist verlangt',
  'newsletters.newsletterItem.error.link': 'Link ist verlang',
  'newsletters.newsletterItem.error.imageRequired': 'Bild ist verlangt',
  'newsletters.newsletterItem.rejectFormats':
    'Folgende Dateien werden nicht unterstützt: {files}',
  'newsletters.newsletterItem.delete': 'Löschen',
  'newsletters.newsletterItem.deleteConfirm': 'Sicher, dass dieser Newsletter Eintrag gelöscht werden soll?',
  'newsletters.newsletterItem.image': 'Bild',
  'newsletters.newsletterItem.dropFilesHere': 'Füge die Datei hier hinzu für den Upload',
  'newsletters.newsletterItem.imageCopyright': 'Copyright',
  'newsletters.newsletterItem.link': 'Link',
  'newsletters.newsletterItem.title': 'Titel',
  'newsletters.newsletterItem.content': 'Inhalt',

  'newsletters.newsletterSend.error': 'Fehler',
  'newsletters.newsletterSend.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
  'newsletters.newsletterSend.sendNewsletter': 'Newsletter versenden',
  'newsletters.newsletterSend.send': 'Sende an {recipients} Empfänger',
  'newsletters.newsletterSend.sendConfirm': 'Dieses E-Mail an {recipientCount} Personen versenden?',
  'newsletters.newsletterSend.state.pending': 'Bereit zum Versand',
  'newsletters.newsletterSend.state.running': 'Versand läuft',
  'newsletters.newsletterSend.state.sleeping': 'Versandspause, startet automatisch wieder',
  'newsletters.newsletterSend.state.interrupted': 'Versand unterbrochen, versuche es noch einmal zu starten (Bereits versendete, werden nicht erneut versendet)',
  'newsletters.newsletterSend.state.completed': 'Versand erfolgreich abgeschlossen',
  'newsletters.newsletterSend.state.draft': 'Newsletter im Entwurf',
  'newsletters.newsletterSend.sending': 'Versand an {recipients} Empfänger läuft (Gesendet: {sent}, haben Mailing bereits erhalten: {double}, Fehlgeschlagen: {failed})',
  'newsletters.newsletterSend.state.successfullSended': 'Erfolgreich verschickte Newsletter müssen kopiert werden um sie nochmals zu versenden',
}

export default LocaleData
