// @ts-nocheck
import * as Types from './src/gql/members/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  canton?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  firmName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mailbox?: Maybe<Scalars['String']['output']>;
  place: Scalars['String']['output'];
  postCode: Scalars['Int']['output'];
  salutation?: Maybe<Scalars['String']['output']>;
  streetNr?: Maybe<Scalars['String']['output']>;
  types: Array<AddressType>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
  validFrom: Scalars['Int']['output'];
};

export type AddressCreate = {
  canton?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  place: Scalars['String']['input'];
  postCode: Scalars['Int']['input'];
  salutation?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
  types: Array<AddressTypeElemCreate>;
  validFrom?: InputMaybe<Scalars['Int']['input']>;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
  postalState: Scalars['String']['input'];
  salutation: Scalars['String']['input'];
};

export type AddressPagination = {
  __typename?: 'AddressPagination';
  items: Array<Address>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isFirm: Scalars['Boolean']['output'];
  isInitType: Scalars['Boolean']['output'];
  name: TranslationObject;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type AddressTypeElemCreate = {
  id: Scalars['ID']['input'];
};

export type AddressTypeElemUpdate = {
  id: Scalars['ID']['input'];
  task: ArrayTasks;
};

export type AddressTypePagination = {
  __typename?: 'AddressTypePagination';
  items: Array<AddressType>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type AddressUpdate = {
  canton?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
  task: ArrayUpdate;
  types?: InputMaybe<Array<AddressTypeElemUpdate>>;
  validFrom?: InputMaybe<Scalars['Int']['input']>;
};

export enum ArrayTasks {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export enum ArrayUpdate {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export type Association = {
  __typename?: 'Association';
  /** All persons who are in this Association */
  associationPersons?: Maybe<Array<AssociationPerson>>;
  associationURL?: Maybe<Scalars['String']['output']>;
  billingInformation?: Maybe<BillingInformation>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  defaultComment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['ID']['output']>;
  imageSrc?: Maybe<Scalars['String']['output']>;
  isStufenvereinigung: Scalars['Boolean']['output'];
  membersCalculationAt?: Maybe<Scalars['String']['output']>;
  /** Persons who have this Association selected for the SVSS payment */
  membershipPayers?: Maybe<Array<Person>>;
  /** Name of the Association */
  name: TranslationObject;
  needsAComment: Scalars['Boolean']['output'];
  /** Prize to pay for beeing in this Association (without SVSS cost: 50.-) in rp */
  prize: Scalars['Int']['output'];
  /** Short name of Association */
  short: TranslationObject;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type AssociationElemCreate = {
  /** id of of the Association to create */
  id: Scalars['ID']['input'];
};

export type AssociationElemUpdate = {
  /** id of of the Association to update */
  id: Scalars['ID']['input'];
  /** which action do you want to do with that Association */
  task: ArrayTasks;
};

export type AssociationPagination = {
  __typename?: 'AssociationPagination';
  items: Array<Association>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type AssociationPerson = {
  __typename?: 'AssociationPerson';
  association: Association;
  associationTypes?: Maybe<Array<AssociationType>>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  person: Person;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type AssociationPersonAssociationElemCreate = {
  associationTypes: Array<InputMaybe<AssociationTypeElemCreate>>;
  personId: Scalars['ID']['input'];
};

export type AssociationPersonAssociationElemUpdate = {
  associationTypes: Array<AssociationTypeElemUpdate>;
  personId: Scalars['ID']['input'];
  /** which action do you want to do with that Association */
  task: ArrayUpdate;
};

export type AssociationPersonPersonElemCreate = {
  associationId: Scalars['ID']['input'];
  associationTypes: Array<InputMaybe<AssociationTypeElemCreate>>;
};

export type AssociationPersonPersonElemUpdate = {
  associationId: Scalars['ID']['input'];
  associationTypes: Array<AssociationTypeElemUpdate>;
  /** which action do you want to do with that Association */
  task: ArrayUpdate;
};

export type AssociationType = {
  __typename?: 'AssociationType';
  associationPersons?: Maybe<Array<AssociationPerson>>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isDefaultType: Scalars['Boolean']['output'];
  name: TranslationObject;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
  userGroup: Scalars['String']['output'];
};

export type AssociationTypeElemCreate = {
  id: Scalars['ID']['input'];
};

export type AssociationTypeElemUpdate = {
  id: Scalars['ID']['input'];
  task: ArrayTasks;
};

export type AssociationTypePagination = {
  __typename?: 'AssociationTypePagination';
  items: Array<AssociationType>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type AssociationWithPresident = {
  __typename?: 'AssociationWithPresident';
  association: Association;
  presidentEmail?: Maybe<Scalars['String']['output']>;
  presidentName?: Maybe<Scalars['String']['output']>;
};

export type BillingInformation = {
  __typename?: 'BillingInformation';
  account?: Maybe<Scalars['String']['output']>;
  besrId?: Maybe<Scalars['Int']['output']>;
  canton?: Maybe<Scalars['String']['output']>;
  cantonFrom?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryFrom?: Maybe<Scalars['String']['output']>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  firmName?: Maybe<Scalars['String']['output']>;
  firmNameFrom?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  firstNameFrom?: Maybe<Scalars['String']['output']>;
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lastNameFrom?: Maybe<Scalars['String']['output']>;
  mailbox?: Maybe<Scalars['String']['output']>;
  mailboxFrom?: Maybe<Scalars['String']['output']>;
  place: Scalars['String']['output'];
  placeFrom: Scalars['String']['output'];
  postCode: Scalars['Int']['output'];
  postCodeFrom: Scalars['Int']['output'];
  qrIban?: Maybe<Scalars['String']['output']>;
  streetNr?: Maybe<Scalars['String']['output']>;
  streetNrFrom?: Maybe<Scalars['String']['output']>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type BillingInformationCreate = {
  account?: InputMaybe<Scalars['String']['input']>;
  besrId?: InputMaybe<Scalars['Int']['input']>;
  canton?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  iban: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  postCode: Scalars['Int']['input'];
  qrIban?: InputMaybe<Scalars['String']['input']>;
  streetNr: Scalars['String']['input'];
};

export type BillingInformationUpdate = {
  account?: InputMaybe<Scalars['String']['input']>;
  besrId?: InputMaybe<Scalars['Int']['input']>;
  canton?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['Int']['input']>;
  qrIban?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
};

export type Course = {
  __typename?: 'Course';
  contentCategory?: Maybe<Scalars['String']['output']>;
  courseRegistrations?: Maybe<Array<Maybe<CourseRegistration>>>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<TranslationObject>;
  educationalStages: Array<Maybe<WorkLevel>>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  maxSubscribers?: Maybe<Scalars['Int']['output']>;
  memberPrice?: Maybe<Scalars['Int']['output']>;
  minSubscribers?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  options: Array<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  special?: Maybe<TranslationObject>;
  status?: Maybe<CourseStatus>;
  subscribeTill?: Maybe<Scalars['String']['output']>;
  targetGroup?: Maybe<Scalars['String']['output']>;
  teacher?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  title: TranslationObject;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type CoursePagination = {
  __typename?: 'CoursePagination';
  items: Array<Course>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CourseRegistration = {
  __typename?: 'CourseRegistration';
  course: Course;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  participant: Person;
  signUpStatus: SignUpState;
};

export type CourseRegistrationPagination = {
  __typename?: 'CourseRegistrationPagination';
  items: Array<CourseRegistration>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum CourseStatus {
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Open = 'open'
}

export type FilterInput = {
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Invoice = {
  __typename?: 'Invoice';
  account: Scalars['String']['output'];
  besrId?: Maybe<Scalars['Int']['output']>;
  canceledAt?: Maybe<Scalars['String']['output']>;
  canton?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  courseRegistration?: Maybe<CourseRegistration>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firmName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
  items: Array<InvoiceItem>;
  lastName?: Maybe<Scalars['String']['output']>;
  mailbox?: Maybe<Scalars['String']['output']>;
  mailing?: Maybe<Mailing>;
  payAmount?: Maybe<Scalars['Int']['output']>;
  payDate?: Maybe<Scalars['String']['output']>;
  paymentCanton?: Maybe<Scalars['String']['output']>;
  paymentCountry?: Maybe<Scalars['String']['output']>;
  paymentFirmName?: Maybe<Scalars['String']['output']>;
  paymentFirstName?: Maybe<Scalars['String']['output']>;
  paymentLastName?: Maybe<Scalars['String']['output']>;
  paymentMailbox?: Maybe<Scalars['String']['output']>;
  paymentPlace: Scalars['String']['output'];
  paymentPostCode: Scalars['Int']['output'];
  paymentStreetNr?: Maybe<Scalars['String']['output']>;
  pdfPath: Scalars['String']['output'];
  place: Scalars['String']['output'];
  postCode: Scalars['Int']['output'];
  qrIban?: Maybe<Scalars['String']['output']>;
  reminderMailing?: Maybe<Mailing>;
  salutation?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['String']['output']>;
  streetNr?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export type InvoicePagination = {
  __typename?: 'InvoicePagination';
  items: Array<Invoice>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type Mailing = {
  __typename?: 'Mailing';
  attachment?: Maybe<Scalars['String']['output']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  content: Scalars['String']['output'];
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  failed: Array<Maybe<Scalars['String']['output']>>;
  from: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sent: Array<Maybe<Scalars['String']['output']>>;
  state: MailingState;
  subject: Scalars['String']['output'];
  to: Array<Scalars['String']['output']>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export enum MailingState {
  Completed = 'completed',
  Draft = 'draft',
  Interrupted = 'interrupted',
  Pending = 'pending',
  Running = 'running',
  Sleeping = 'sleeping'
}

export type Membership = {
  __typename?: 'Membership';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  directInvoicing: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Persons who are members of this Membership */
  members?: Maybe<Array<Person>>;
  /** Name of the Membership */
  name: TranslationObject;
  /** Prize to pay for beeing in this Membership in rp */
  prize: Scalars['Int']['output'];
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type MembershipElemCreate = {
  id: Scalars['ID']['input'];
};

export type MembershipElemUpdate = {
  /** id of of the membership to update */
  id: Scalars['ID']['input'];
  /** which action do you want to do with that membership */
  task: ArrayTasks;
};

export type MembershipPagination = {
  __typename?: 'MembershipPagination';
  items: Array<Membership>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAddress: Address;
  addAddressType: AddressType;
  addAssociation: Association;
  addAssociationType: AssociationType;
  addBillingInformation: BillingInformation;
  addEmptyPerson: Person;
  addInvoice: Invoice;
  addMailing: Mailing;
  addMembership: Membership;
  addNewsletter: Newsletter;
  addNewsletterMailing: NewsletterMailing;
  addNewsletterMailingItem: NewsletterMailingItem;
  addPerson: Person;
  addPersonIfOwns: Person;
  addSepa: SepaReturn;
  addSportsEducation: SportsEducation;
  addState: State;
  addStateFunction: StateFunction;
  addStateType: StateType;
  addSvssFunction: SvssFunction;
  addTransaction: SvssTransaction;
  addWorkLevel: WorkLevel;
  cancelRegistrations: Course;
  confirmOpenRegistrations: Course;
  confirmRegistration: CourseRegistration;
  confirmWaitingList: Course;
  createAssociationInvoice: CreateInvoiceReturn;
  createAttendeesList: Scalars['String']['output'];
  createCourse: Course;
  createDirectMemberInvoice: CreateInvoiceReturn;
  createMemberInvoice: CreateInvoiceReturn;
  createOneAssociationInvoice: CreateInvoiceReturn;
  createSignupRecipient: SignupRecipient;
  deleteAddress: Address;
  deleteAddressType: AddressType;
  deleteAssociation: Association;
  deleteAssociationType: AssociationType;
  deleteCourse: Course;
  deleteInvoice: Invoice;
  deleteMailing: Mailing;
  deleteMembership: Membership;
  deleteNewsletter: Newsletter;
  deleteNewsletterMailing: NewsletterMailing;
  deleteNewsletterMailingItem: NewsletterMailingItem;
  deletePerson: Person;
  deleteSepa: SepaReturn;
  deleteSignupRecipient: SignupRecipient;
  deleteSportsEducation: SportsEducation;
  deleteState: State;
  deleteStateFunction: StateFunction;
  deleteStateType: StateType;
  deleteSvssFunction: SvssFunction;
  deleteWorkLevel: WorkLevel;
  pay: Transaction;
  registerForCourse: Course;
  registerForNewsletter?: Maybe<Scalars['Boolean']['output']>;
  renderNewsletterMailing: NewsletterMailing;
  sendMailing: Mailing;
  sendNewsletterMailing: SendReturn;
  unregisterForNewsletter?: Maybe<Scalars['Boolean']['output']>;
  unregisterFromCourse: Course;
  unregisterFromCourseById: Course;
  updateAddress: Address;
  updateAddressType: AddressType;
  updateAssociation: Association;
  updateAssociationIfMember: Association;
  updateAssociationType: AssociationType;
  updateBillingInformation: BillingInformation;
  updateCourse: Course;
  updateInvoice: Invoice;
  updateInvoiceIfAssociation: Invoice;
  updateMailing: Mailing;
  updateMembership: Membership;
  updateNewsletter: Newsletter;
  updateNewsletterMailing: NewsletterMailing;
  updateNewsletterMailingItem: NewsletterMailingItem;
  updatePerson: Person;
  updatePersonIfManages: Person;
  updatePersonIfOwns: Person;
  updateSportsEducation: SportsEducation;
  updateState: State;
  updateStateFunction: StateFunction;
  updateStateType: StateType;
  updateSvssFunction: SvssFunction;
  updateWorkLevel: WorkLevel;
};


export type MutationAddAddressArgs = {
  canton?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  place: Scalars['String']['input'];
  postCode: Scalars['Int']['input'];
  salutation?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
  types: Array<AddressTypeElemCreate>;
  validFrom?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAddAddressTypeArgs = {
  isFirm: Scalars['Boolean']['input'];
  isInitType: Scalars['Boolean']['input'];
  name: TranslationObjectCreate;
};


export type MutationAddAssociationArgs = {
  associationPersons?: InputMaybe<Array<AssociationPersonAssociationElemCreate>>;
  associationURL: Scalars['String']['input'];
  billingInformation: BillingInformationCreate;
  defaultComment?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  isStufenvereinigung: Scalars['Boolean']['input'];
  membersCalculationAt?: InputMaybe<Scalars['String']['input']>;
  membershipPayers?: InputMaybe<Array<PersonElemCreate>>;
  name: TranslationObjectCreate;
  needsAComment: Scalars['Boolean']['input'];
  prize: Scalars['Int']['input'];
  short: TranslationObjectCreate;
};


export type MutationAddAssociationTypeArgs = {
  isDefaultType: Scalars['Boolean']['input'];
  name: TranslationObjectCreate;
  userGroup: Scalars['String']['input'];
};


export type MutationAddBillingInformationArgs = {
  account?: InputMaybe<Scalars['String']['input']>;
  besrId?: InputMaybe<Scalars['Int']['input']>;
  canton?: InputMaybe<Scalars['String']['input']>;
  cantonFrom?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryFrom?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firmNameFrom?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameFrom?: InputMaybe<Scalars['String']['input']>;
  iban: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameFrom?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  mailboxFrom?: InputMaybe<Scalars['String']['input']>;
  place: Scalars['String']['input'];
  placeFrom: Scalars['String']['input'];
  postCode: Scalars['Int']['input'];
  postCodeFrom: Scalars['Int']['input'];
  qrIban?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
  streetNrFrom?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddEmptyPersonArgs = {
  authId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};


export type MutationAddInvoiceArgs = {
  cost: Scalars['Int']['input'];
  itemText: Scalars['String']['input'];
  personId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationAddMailingArgs = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content: Scalars['String']['input'];
  from: Scalars['String']['input'];
  fromName: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};


export type MutationAddMembershipArgs = {
  directInvoicing: Scalars['Boolean']['input'];
  members?: InputMaybe<Array<PersonElemCreate>>;
  name: TranslationObjectCreate;
  prize: Scalars['Int']['input'];
};


export type MutationAddNewsletterArgs = {
  name: TranslationObjectCreate;
  wantsNewsletter?: InputMaybe<Array<PersonElemCreate>>;
};


export type MutationAddNewsletterMailingArgs = {
  attachment?: InputMaybe<Scalars['Upload']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  failed?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from: Scalars['String']['input'];
  fromName: Scalars['String']['input'];
  items: Array<InputMaybe<NewsletterMailingItemElemUpdate>>;
  leftColoumn?: InputMaybe<Scalars['String']['input']>;
  mailingDate: Scalars['String']['input'];
  mainImage?: InputMaybe<Scalars['Upload']['input']>;
  mainImageCopyright?: InputMaybe<Scalars['String']['input']>;
  mainImageId?: InputMaybe<Scalars['ID']['input']>;
  newsletterId: Scalars['ID']['input'];
  rightColoumn?: InputMaybe<Scalars['String']['input']>;
  sent?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showOnWebsite?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<MailingState>;
  subject: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  to: Array<Scalars['String']['input']>;
};


export type MutationAddNewsletterMailingItemArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  imageCopyright?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  newsletterMailingId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddPersonArgs = {
  addresses: Array<AddressCreate>;
  ahvNumber: Scalars['String']['input'];
  associationComment?: InputMaybe<Scalars['String']['input']>;
  associationPayTo?: InputMaybe<AssociationElemCreate>;
  associationPersons?: InputMaybe<Array<AssociationPersonPersonElemCreate>>;
  authId?: InputMaybe<Scalars['ID']['input']>;
  birthdate: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  currentWork?: InputMaybe<Array<WorkLevelElemCreate>>;
  email: Scalars['String']['input'];
  hasSportsEducation?: InputMaybe<Array<SportsEducationElemCreate>>;
  jsNumber?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Array<NewsletterElemCreate>>;
  slrgNumber?: InputMaybe<Scalars['String']['input']>;
  stateFunctions?: InputMaybe<Array<StateFunctionElemCreate>>;
  stateId: Scalars['ID']['input'];
  stateTypeId?: InputMaybe<Scalars['ID']['input']>;
  svssFunctions?: InputMaybe<Array<SvssFunctionElemCreate>>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddPersonIfOwnsArgs = {
  addresses: Array<AddressCreate>;
  ahvNumber: Scalars['String']['input'];
  associationComment?: InputMaybe<Scalars['String']['input']>;
  associationPayTo?: InputMaybe<AssociationElemCreate>;
  associationPersons?: InputMaybe<Array<AssociationPersonPersonElemCreate>>;
  authId: Scalars['ID']['input'];
  birthdate: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  currentWork?: InputMaybe<Array<WorkLevelElemCreate>>;
  email: Scalars['String']['input'];
  hasSportsEducation?: InputMaybe<Array<SportsEducationElemCreate>>;
  jsNumber?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Array<NewsletterElemCreate>>;
  slrgNumber?: InputMaybe<Scalars['String']['input']>;
  stateFunctions?: InputMaybe<Array<StateFunctionElemCreate>>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
  stateTypeId?: InputMaybe<Scalars['ID']['input']>;
  svssFunctions?: InputMaybe<Array<SvssFunctionElemCreate>>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddSepaArgs = {
  sepa: Scalars['Upload']['input'];
};


export type MutationAddSportsEducationArgs = {
  name: TranslationObjectCreate;
  persons?: InputMaybe<Array<PersonElemCreate>>;
};


export type MutationAddStateArgs = {
  highlightColor: Scalars['String']['input'];
  isInitState: Scalars['Boolean']['input'];
  name: TranslationObjectCreate;
  typeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationAddStateFunctionArgs = {
  name: TranslationObjectCreate;
  people?: InputMaybe<Array<PersonElemCreate>>;
};


export type MutationAddStateTypeArgs = {
  isTrialType: Scalars['Boolean']['input'];
  name: TranslationObjectCreate;
  requireMembership: Scalars['Boolean']['input'];
  stateId: Scalars['ID']['input'];
};


export type MutationAddSvssFunctionArgs = {
  name: TranslationObjectCreate;
  people?: InputMaybe<Array<PersonElemCreate>>;
};


export type MutationAddTransactionArgs = {
  invoiceId: Scalars['ID']['input'];
  trsansactionId: Scalars['ID']['input'];
};


export type MutationAddWorkLevelArgs = {
  name: TranslationObjectCreate;
  worksOnLevel?: InputMaybe<Array<PersonElemCreate>>;
};


export type MutationCancelRegistrationsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationConfirmOpenRegistrationsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationConfirmRegistrationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationConfirmWaitingListArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateAssociationInvoiceArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateAttendeesListArgs = {
  courseId: Scalars['ID']['input'];
};


export type MutationCreateCourseArgs = {
  contentCategory?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<TranslationObjectUpdate>;
  educationalStageIds: Array<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  maxSubscribers?: InputMaybe<Scalars['Int']['input']>;
  memberPrice?: InputMaybe<Scalars['Int']['input']>;
  minSubscribers?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  options: Array<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  special?: InputMaybe<TranslationObjectUpdate>;
  status?: InputMaybe<CourseStatus>;
  subscribeTill?: InputMaybe<Scalars['String']['input']>;
  targetGroup?: InputMaybe<Scalars['String']['input']>;
  teacher?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TranslationObjectUpdate>;
};


export type MutationCreateDirectMemberInvoiceArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateMemberInvoiceArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateOneAssociationInvoiceArgs = {
  associationId: Scalars['ID']['input'];
  processId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateSignupRecipientArgs = {
  email: Scalars['String']['input'];
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAddressTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAssociationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAssociationTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCourseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMailingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNewsletterArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNewsletterMailingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNewsletterMailingItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePersonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSepaArgs = {
  filename: Scalars['String']['input'];
};


export type MutationDeleteSignupRecipientArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSportsEducationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStateFunctionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStateTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSvssFunctionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWorkLevelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPayArgs = {
  billingAddress: AddressInput;
  currency: Scalars['String']['input'];
  failedUrl: Scalars['String']['input'];
  language: Scalars['String']['input'];
  products: Array<Product>;
  shippingAddress: AddressInput;
  successUrl: Scalars['String']['input'];
};


export type MutationRegisterForCourseArgs = {
  courseId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
  signUpStatus: SignUpState;
};


export type MutationRegisterForNewsletterArgs = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recaptchaResponse: Scalars['String']['input'];
};


export type MutationRenderNewsletterMailingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendMailingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendNewsletterMailingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnregisterForNewsletterArgs = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recaptchaResponse: Scalars['String']['input'];
};


export type MutationUnregisterFromCourseArgs = {
  courseId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
};


export type MutationUnregisterFromCourseByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateAddressArgs = {
  canton?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<AddressTypeElemUpdate>>;
  validFrom?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateAddressTypeArgs = {
  id: Scalars['ID']['input'];
  isFirm?: InputMaybe<Scalars['Boolean']['input']>;
  isInitType?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<TranslationObjectUpdate>;
};


export type MutationUpdateAssociationArgs = {
  associationPersons?: InputMaybe<Array<AssociationPersonAssociationElemUpdate>>;
  associationURL: Scalars['String']['input'];
  billingInformation: BillingInformationUpdate;
  defaultComment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  imageId?: InputMaybe<Scalars['ID']['input']>;
  isStufenvereinigung?: InputMaybe<Scalars['Boolean']['input']>;
  membersCalculationAt?: InputMaybe<Scalars['String']['input']>;
  membershipPayers?: InputMaybe<Array<PersonElemUpdate>>;
  name?: InputMaybe<TranslationObjectUpdate>;
  needsAComment?: InputMaybe<Scalars['Boolean']['input']>;
  prize?: InputMaybe<Scalars['Int']['input']>;
  short?: InputMaybe<TranslationObjectUpdate>;
};


export type MutationUpdateAssociationIfMemberArgs = {
  associationPersons?: InputMaybe<Array<AssociationPersonAssociationElemUpdate>>;
  associationURL: Scalars['String']['input'];
  billingInformation: BillingInformationUpdate;
  defaultComment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  imageId?: InputMaybe<Scalars['ID']['input']>;
  isStufenvereinigung?: InputMaybe<Scalars['Boolean']['input']>;
  membersCalculationAt?: InputMaybe<Scalars['String']['input']>;
  membershipPayers?: InputMaybe<Array<PersonElemUpdate>>;
  name?: InputMaybe<TranslationObjectUpdate>;
  needsAComment?: InputMaybe<Scalars['Boolean']['input']>;
  prize?: InputMaybe<Scalars['Int']['input']>;
  short?: InputMaybe<TranslationObjectUpdate>;
};


export type MutationUpdateAssociationTypeArgs = {
  id: Scalars['ID']['input'];
  isDefaultType?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<TranslationObjectUpdate>;
  userGroup?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateBillingInformationArgs = {
  account?: InputMaybe<Scalars['String']['input']>;
  besrId?: InputMaybe<Scalars['Int']['input']>;
  canton?: InputMaybe<Scalars['String']['input']>;
  cantonFrom?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryFrom?: InputMaybe<Scalars['String']['input']>;
  firmName?: InputMaybe<Scalars['String']['input']>;
  firmNameFrom?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameFrom?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameFrom?: InputMaybe<Scalars['String']['input']>;
  mailbox?: InputMaybe<Scalars['String']['input']>;
  mailboxFrom?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  placeFrom?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['Int']['input']>;
  postCodeFrom?: InputMaybe<Scalars['Int']['input']>;
  qrIban?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
  streetNrFrom?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCourseArgs = {
  contentCategory?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<TranslationObjectUpdate>;
  educationalStageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  maxSubscribers?: InputMaybe<Scalars['Int']['input']>;
  memberPrice?: InputMaybe<Scalars['Int']['input']>;
  minSubscribers?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  place?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  special?: InputMaybe<TranslationObjectUpdate>;
  status?: InputMaybe<CourseStatus>;
  subscribeTill?: InputMaybe<Scalars['String']['input']>;
  targetGroup?: InputMaybe<Scalars['String']['input']>;
  teacher?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TranslationObjectUpdate>;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID']['input'];
  payAmount: Scalars['Int']['input'];
  payDate?: InputMaybe<Scalars['String']['input']>;
  total: Scalars['Int']['input'];
};


export type MutationUpdateInvoiceIfAssociationArgs = {
  id: Scalars['ID']['input'];
  payAmount: Scalars['Int']['input'];
  payDate?: InputMaybe<Scalars['String']['input']>;
  total: Scalars['Int']['input'];
};


export type MutationUpdateMailingArgs = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content?: InputMaybe<Scalars['String']['input']>;
  failed?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from?: InputMaybe<Scalars['String']['input']>;
  fromName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  sent?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  state?: InputMaybe<MailingState>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdateMembershipArgs = {
  directInvoicing?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  members?: InputMaybe<Array<PersonElemUpdate>>;
  name?: InputMaybe<TranslationObjectUpdate>;
  prize?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateNewsletterArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<TranslationObjectUpdate>;
  wantsNewsletter?: InputMaybe<Array<PersonElemUpdate>>;
};


export type MutationUpdateNewsletterMailingArgs = {
  attachment?: InputMaybe<Scalars['Upload']['input']>;
  content: Scalars['String']['input'];
  failed?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from: Scalars['String']['input'];
  fromName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  items: Array<InputMaybe<NewsletterMailingItemElemUpdate>>;
  leftColoumn: Scalars['String']['input'];
  mailingDate: Scalars['String']['input'];
  mainImage?: InputMaybe<Scalars['Upload']['input']>;
  mainImageCopyright: Scalars['String']['input'];
  mainImageId?: InputMaybe<Scalars['ID']['input']>;
  newsletterId: Scalars['ID']['input'];
  rightColoumn: Scalars['String']['input'];
  sent?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showOnWebsite?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<MailingState>;
  subject: Scalars['String']['input'];
  title: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};


export type MutationUpdateNewsletterMailingItemArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  imageCopyright?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  orderNr?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePersonArgs = {
  addresses?: InputMaybe<Array<AddressUpdate>>;
  ahvNumber?: InputMaybe<Scalars['String']['input']>;
  associationComment?: InputMaybe<Scalars['String']['input']>;
  associationPayTo?: InputMaybe<AssociationElemUpdate>;
  associationPersons?: InputMaybe<Array<AssociationPersonPersonElemUpdate>>;
  authId?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  currentWork?: InputMaybe<Array<WorkLevelElemUpdate>>;
  email?: InputMaybe<Scalars['String']['input']>;
  hasSportsEducation?: InputMaybe<Array<SportsEducationElemUpdate>>;
  id: Scalars['ID']['input'];
  jsNumber?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Array<NewsletterElemUpdate>>;
  slrgNumber?: InputMaybe<Scalars['String']['input']>;
  stateFunctions?: InputMaybe<Array<StateFunctionElemUpdate>>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
  stateTypeId?: InputMaybe<Scalars['ID']['input']>;
  svssFunctions?: InputMaybe<Array<SvssFunctionElemUpdate>>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePersonIfManagesArgs = {
  addresses?: InputMaybe<Array<AddressUpdate>>;
  ahvNumber?: InputMaybe<Scalars['String']['input']>;
  associationComment?: InputMaybe<Scalars['String']['input']>;
  associationPayTo?: InputMaybe<AssociationElemUpdate>;
  associationPersons?: InputMaybe<Array<AssociationPersonPersonElemUpdate>>;
  authId?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  currentWork?: InputMaybe<Array<WorkLevelElemUpdate>>;
  email?: InputMaybe<Scalars['String']['input']>;
  hasSportsEducation?: InputMaybe<Array<SportsEducationElemUpdate>>;
  id: Scalars['ID']['input'];
  jsNumber?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Array<NewsletterElemUpdate>>;
  slrgNumber?: InputMaybe<Scalars['String']['input']>;
  stateFunctions?: InputMaybe<Array<StateFunctionElemUpdate>>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
  stateTypeId?: InputMaybe<Scalars['ID']['input']>;
  svssFunctions?: InputMaybe<Array<SvssFunctionElemUpdate>>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePersonIfOwnsArgs = {
  addresses?: InputMaybe<Array<AddressUpdate>>;
  ahvNumber?: InputMaybe<Scalars['String']['input']>;
  associationComment?: InputMaybe<Scalars['String']['input']>;
  associationPayTo?: InputMaybe<AssociationElemUpdate>;
  associationPersons?: InputMaybe<Array<AssociationPersonPersonElemUpdate>>;
  authId?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  currentWork?: InputMaybe<Array<WorkLevelElemUpdate>>;
  email?: InputMaybe<Scalars['String']['input']>;
  hasSportsEducation?: InputMaybe<Array<SportsEducationElemUpdate>>;
  id: Scalars['ID']['input'];
  jsNumber?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Array<NewsletterElemUpdate>>;
  slrgNumber?: InputMaybe<Scalars['String']['input']>;
  stateFunctions?: InputMaybe<Array<StateFunctionElemUpdate>>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
  stateTypeId?: InputMaybe<Scalars['ID']['input']>;
  svssFunctions?: InputMaybe<Array<SvssFunctionElemUpdate>>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSportsEducationArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<TranslationObjectUpdate>;
  persons?: InputMaybe<Array<PersonElemUpdate>>;
};


export type MutationUpdateStateArgs = {
  highlightColor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isInitState?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<TranslationObjectUpdate>;
  typeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationUpdateStateFunctionArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<TranslationObjectUpdate>;
  people?: InputMaybe<Array<PersonElemUpdate>>;
};


export type MutationUpdateStateTypeArgs = {
  id: Scalars['ID']['input'];
  isTrialType?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<TranslationObjectUpdate>;
  requireMembership?: InputMaybe<Scalars['Boolean']['input']>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateSvssFunctionArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<TranslationObjectUpdate>;
  people?: InputMaybe<Array<PersonElemUpdate>>;
};


export type MutationUpdateWorkLevelArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<TranslationObjectUpdate>;
  worksOnLevel?: InputMaybe<Array<PersonElemUpdate>>;
};

export type Newsletter = {
  __typename?: 'Newsletter';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Name of the newsletter */
  name: TranslationObject;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
  /** Persons who are subscribed to this newsletter */
  wantsNewsletter?: Maybe<Array<Person>>;
};

export type NewsletterElemCreate = {
  id: Scalars['ID']['input'];
};

export type NewsletterElemUpdate = {
  /** id of of the newsletter to update */
  id: Scalars['ID']['input'];
  /** which action do you want to do with that newsletter */
  task: ArrayTasks;
};

export type NewsletterMailing = {
  __typename?: 'NewsletterMailing';
  attachment?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  failed: Array<Maybe<Scalars['String']['output']>>;
  from: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  htmlContent?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<Maybe<NewsletterMailingItem>>;
  leftColoumn?: Maybe<Scalars['String']['output']>;
  mailingDate: Scalars['String']['output'];
  mainImageCopyright?: Maybe<Scalars['String']['output']>;
  mainImageId?: Maybe<Scalars['ID']['output']>;
  mainImageSrc?: Maybe<Scalars['String']['output']>;
  newsletter: Newsletter;
  rightColoumn?: Maybe<Scalars['String']['output']>;
  sendProcessId?: Maybe<Scalars['String']['output']>;
  sent: Array<Maybe<Scalars['String']['output']>>;
  showOnWebsite: Scalars['Boolean']['output'];
  state: MailingState;
  subject: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  to: Array<Scalars['String']['output']>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type NewsletterMailingHtml = {
  __typename?: 'NewsletterMailingHtml';
  htmlContent?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mailingDate: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type NewsletterMailingHtmlPagination = {
  __typename?: 'NewsletterMailingHtmlPagination';
  items: Array<NewsletterMailingHtml>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type NewsletterMailingItem = {
  __typename?: 'NewsletterMailingItem';
  content?: Maybe<Scalars['String']['output']>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  imageCopyright?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['ID']['output']>;
  imageSrc?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  orderNr?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type NewsletterMailingItemElemUpdate = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  imageCopyright?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  orderNr?: InputMaybe<Scalars['Int']['input']>;
  task: ArrayUpdate;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NewsletterMailingPagination = {
  __typename?: 'NewsletterMailingPagination';
  items: Array<NewsletterMailing>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type NewsletterPagination = {
  __typename?: 'NewsletterPagination';
  items: Array<Newsletter>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type NextCursorInput = {
  filtered?: InputMaybe<Array<FilterInput>>;
  nextCursor: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
  sorted?: InputMaybe<Array<SortInput>>;
};

export type Person = {
  __typename?: 'Person';
  /** All the addresses of the person */
  addresses: Array<Address>;
  /** The persons AHV number */
  ahvNumber?: Maybe<Scalars['String']['output']>;
  associationComment?: Maybe<Scalars['String']['output']>;
  /** To which association is the SVSS bill payed (only needed if membership = association) */
  associationPayTo?: Maybe<Association>;
  /** All the associations the person is in */
  associationPersons?: Maybe<Array<AssociationPerson>>;
  /** authId of the user on the auth server */
  authId: Scalars['String']['output'];
  birthdate: Scalars['String']['output'];
  /** Internal comment for the user */
  comment?: Maybe<Scalars['String']['output']>;
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  /** All the current working levels */
  currentWork?: Maybe<Array<WorkLevel>>;
  email?: Maybe<Scalars['String']['output']>;
  /** The highest sport education of the user */
  hasSportsEducation?: Maybe<Array<SportsEducation>>;
  id: Scalars['ID']['output'];
  /** The persons J+S number */
  jsNumber?: Maybe<Scalars['String']['output']>;
  /** Which kind of SVSS member is the person */
  membership?: Maybe<Membership>;
  mobile?: Maybe<Scalars['String']['output']>;
  /** All the newsletter the person wants */
  newsletter?: Maybe<Array<Newsletter>>;
  /** The persons SLRG number */
  slrgNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<State>;
  stateFunctions?: Maybe<Array<StateFunction>>;
  /** Which Type of State the user is in */
  stateType?: Maybe<StateType>;
  svssFunctions?: Maybe<Array<SvssFunction>>;
  telephone?: Maybe<Scalars['String']['output']>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type PersonElemCreate = {
  id: Scalars['ID']['input'];
};

export type PersonElemUpdate = {
  id: Scalars['ID']['input'];
  task: ArrayTasks;
};

export type PersonPagination = {
  __typename?: 'PersonPagination';
  items: Array<Person>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ProcessState = {
  __typename?: 'ProcessState';
  errorMessage: Scalars['String']['output'];
  processId: Scalars['ID']['output'];
  progress: Scalars['Int']['output'];
  returnCode: Scalars['Int']['output'];
};

export type Product = {
  amountIncludingTax: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  sku: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  uniqueId: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  dummy?: Maybe<Scalars['String']['output']>;
  getAddress: Address;
  getAddressInitType: AddressType;
  getAddressType: AddressType;
  getAddressTypes: AddressTypePagination;
  getAddresses: AddressPagination;
  getAssociation: Association;
  getAssociationIfMember: Association;
  getAssociationNoPerson: Association;
  getAssociationType: AssociationType;
  getAssociationTypeNoPerson: AssociationType;
  getAssociationTypes: AssociationTypePagination;
  getAssociationTypesNoPerson: AssociationTypePagination;
  getAssociations: AssociationPagination;
  getAssociationsForFrontend: Array<AssociationWithPresident>;
  getAssociationsIfMember: AssociationPagination;
  getAssociationsNoPerson: AssociationPagination;
  getBillingInformation: BillingInformation;
  getCourse: Course;
  getCourseRegistrations: CourseRegistrationPagination;
  getCourses: CoursePagination;
  getDefaultAssociationType: AssociationType;
  getInitState: State;
  getInvoice: Invoice;
  getInvoiceIfFromAssociation: Invoice;
  getInvoiceIfOwner: Invoice;
  getInvoiceIfToAssociation: Invoice;
  getInvoices: InvoicePagination;
  getInvoicesIfFromAssociation: InvoicePagination;
  getInvoicesIfOwner: InvoicePagination;
  getInvoicesIfToAssociation: InvoicePagination;
  getMailing: Mailing;
  getMembership: Membership;
  getMembershipIfMember: Membership;
  getMembershipNoPerson: Membership;
  getMemberships: MembershipPagination;
  getMembershipsNoPerson: MembershipPagination;
  getMyCourses?: Maybe<Array<CourseRegistration>>;
  getNewsletter: Newsletter;
  getNewsletterMailaddresses: Array<Scalars['String']['output']>;
  getNewsletterMailing: NewsletterMailing;
  getNewsletterMailingHtml: NewsletterMailingHtml;
  getNewsletterMailingItem: NewsletterMailingItem;
  getNewsletterMailings: NewsletterMailingPagination;
  getNewsletterMailingsHtml: NewsletterMailingHtmlPagination;
  getNewsletterNoPerson: Newsletter;
  getNewsletters: NewsletterPagination;
  getNewslettersNoPerson: NewsletterPagination;
  getNewslettersToRegisterNoPerson: NewsletterPagination;
  getNextAddressTypes: AddressTypePagination;
  getNextAddresses: AddressPagination;
  getNextAssociationTypes: AssociationTypePagination;
  getNextAssociationTypesNoPerson: AssociationTypePagination;
  getNextAssociations: AssociationPagination;
  getNextAssociationsIfMember: AssociationPagination;
  getNextAssociationsNoPerson: AssociationPagination;
  getNextCourseRegistrations: CourseRegistrationPagination;
  getNextCourses: CoursePagination;
  getNextInvoices: InvoicePagination;
  getNextInvoicesIfFromAssociation: InvoicePagination;
  getNextInvoicesIfOwner: InvoicePagination;
  getNextInvoicesIfToAssociation: InvoicePagination;
  getNextMemberships: MembershipPagination;
  getNextMembershipsNoPerson: MembershipPagination;
  getNextNewsletterMailings: NewsletterMailingPagination;
  getNextNewsletterMailingsHtml: NewsletterMailingHtmlPagination;
  getNextNewsletters: NewsletterPagination;
  getNextNewslettersNoPerson: NewsletterPagination;
  getNextNewslettersToRegisterNoPerson: NewsletterPagination;
  getNextPersons: PersonPagination;
  getNextPersonsIfManages: PersonPagination;
  getNextPersonsIfOwns: PersonPagination;
  getNextSportsEducations: SportsEducationPagination;
  getNextSportsEducationsNoPerson: SportsEducationPagination;
  getNextStateFunctions: StateFunctionPagination;
  getNextStateFunctionsNoPerson: StateFunctionPagination;
  getNextStateTypes: StateTypePagination;
  getNextStates: StatePagination;
  getNextStatesNoPerson: StatePagination;
  getNextSvssFunctions: SvssFunctionPagination;
  getNextSvssFunctionsNoPerson: SvssFunctionPagination;
  getNextWorkLevels: WorkLevelPagination;
  getNextWorkLevelsNoPerson: WorkLevelPagination;
  getPerson: Person;
  getPersonByAuthId: Person;
  getPersonIfManages: Person;
  getPersonIfManagesByAuthId: Person;
  getPersonIfOwns: Person;
  getPersonIfOwnsByAuthId: Person;
  getPersons: PersonPagination;
  getPersonsIfManages: PersonPagination;
  getPersonsIfOwns: PersonPagination;
  getSignupRecipients?: Maybe<Array<SignupRecipient>>;
  getSportsEducation: SportsEducation;
  getSportsEducationNoPerson: SportsEducation;
  getSportsEducations: SportsEducationPagination;
  getSportsEducationsNoPerson: SportsEducationPagination;
  getState: State;
  getStateFunction: StateFunction;
  getStateFunctionNoPerson: StateFunction;
  getStateFunctions: StateFunctionPagination;
  getStateFunctionsNoPerson: StateFunctionPagination;
  getStateNoPerson: State;
  getStateType: StateType;
  getStateTypes: StateTypePagination;
  getStates: StatePagination;
  getStatesNoPerson: StatePagination;
  getSvssFunction: SvssFunction;
  getSvssFunctionNoPerson: SvssFunction;
  getSvssFunctions: SvssFunctionPagination;
  getSvssFunctionsNoPerson: SvssFunctionPagination;
  getTransaction?: Maybe<SvssTransaction>;
  getWorkLevel: WorkLevel;
  getWorkLevelNoPerson: WorkLevel;
  getWorkLevels: WorkLevelPagination;
  getWorkLevelsNoPerson: WorkLevelPagination;
  hasTransaction?: Maybe<TransactionState>;
};


export type QueryGetAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAddressTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAddressTypesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAddressesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAssociationIfMemberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAssociationNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAssociationTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAssociationTypeNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAssociationTypesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationTypesNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationsIfMemberArgs = {
  cursor: StartCursorInput;
};


export type QueryGetAssociationsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetBillingInformationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCourseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCourseRegistrationsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetCoursesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInvoiceIfFromAssociationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInvoiceIfOwnerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInvoiceIfToAssociationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInvoicesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetInvoicesIfFromAssociationArgs = {
  cursor: StartCursorInput;
};


export type QueryGetInvoicesIfOwnerArgs = {
  cursor: StartCursorInput;
};


export type QueryGetInvoicesIfToAssociationArgs = {
  cursor: StartCursorInput;
};


export type QueryGetMailingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMembershipIfMemberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMembershipNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMembershipsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetMembershipsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetMyCoursesArgs = {
  userID: Scalars['ID']['input'];
};


export type QueryGetNewsletterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNewsletterMailaddressesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNewsletterMailingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNewsletterMailingHtmlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNewsletterMailingItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNewsletterMailingsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNewsletterMailingsHtmlArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNewsletterNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNewslettersArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNewslettersNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNewslettersToRegisterNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetNextAddressTypesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAddressesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationTypesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationTypesNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationsIfMemberArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextAssociationsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextCourseRegistrationsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextCoursesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextInvoicesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextInvoicesIfFromAssociationArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextInvoicesIfOwnerArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextInvoicesIfToAssociationArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextMembershipsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextMembershipsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextNewsletterMailingsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextNewsletterMailingsHtmlArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextNewslettersArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextNewslettersNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextNewslettersToRegisterNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextPersonsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextPersonsIfManagesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextPersonsIfOwnsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextSportsEducationsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextSportsEducationsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextStateFunctionsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextStateFunctionsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextStateTypesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextStatesArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextStatesNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextSvssFunctionsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextSvssFunctionsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextWorkLevelsArgs = {
  cursor: NextCursorInput;
};


export type QueryGetNextWorkLevelsNoPersonArgs = {
  cursor: NextCursorInput;
};


export type QueryGetPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPersonByAuthIdArgs = {
  authId: Scalars['ID']['input'];
};


export type QueryGetPersonIfManagesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPersonIfManagesByAuthIdArgs = {
  authId: Scalars['ID']['input'];
};


export type QueryGetPersonIfOwnsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPersonIfOwnsByAuthIdArgs = {
  authId: Scalars['ID']['input'];
};


export type QueryGetPersonsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetPersonsIfManagesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetPersonsIfOwnsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetSportsEducationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSportsEducationNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSportsEducationsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetSportsEducationsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetStateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStateFunctionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStateFunctionNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStateFunctionsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetStateFunctionsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetStateNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStateTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStateTypesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetStatesArgs = {
  cursor: StartCursorInput;
};


export type QueryGetStatesNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetSvssFunctionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSvssFunctionNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSvssFunctionsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetSvssFunctionsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryGetTransactionArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type QueryGetWorkLevelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWorkLevelNoPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWorkLevelsArgs = {
  cursor: StartCursorInput;
};


export type QueryGetWorkLevelsNoPersonArgs = {
  cursor: StartCursorInput;
};


export type QueryHasTransactionArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type SendReturn = {
  __typename?: 'SendReturn';
  item: NewsletterMailing;
  process: ProcessState;
};

export type Sepa = {
  __typename?: 'Sepa';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  fileId: Scalars['Int']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type SepaReturn = {
  __typename?: 'SepaReturn';
  errorCode: Scalars['Int']['output'];
  errorMessages: Array<Maybe<Scalars['String']['output']>>;
  sepa?: Maybe<Sepa>;
  successMessages: Array<Maybe<Scalars['String']['output']>>;
};

export enum SignUpState {
  AlreadyRegistered = 'alreadyRegistered',
  AlreadyRegisteredAndPayed = 'alreadyRegisteredAndPayed',
  Cancelled = 'cancelled',
  CompleteProfile = 'completeProfile',
  Confirmed = 'confirmed',
  FullyBooked = 'fullyBooked',
  LogInFirst = 'logInFirst',
  Payed = 'payed',
  SignUp = 'signUp',
  Unconfirmed = 'unconfirmed',
  WaitingList = 'waitingList'
}

export type SignupRecipient = {
  __typename?: 'SignupRecipient';
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type SortInput = {
  asc: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type SportsEducation = {
  __typename?: 'SportsEducation';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Name of the sports education */
  name: TranslationObject;
  /** People who work at this level */
  persons?: Maybe<Array<Person>>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type SportsEducationElemCreate = {
  /** id of of the sports education to create */
  id: Scalars['ID']['input'];
};

export type SportsEducationElemUpdate = {
  /** id of of the sports education to update */
  id: Scalars['ID']['input'];
  /** which action do you want to do with that sports education */
  task: ArrayTasks;
};

export type SportsEducationPagination = {
  __typename?: 'SportsEducationPagination';
  items: Array<SportsEducation>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type StartCursorInput = {
  filtered?: InputMaybe<Array<FilterInput>>;
  includeTotalCount?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize: Scalars['Int']['input'];
  sorted?: InputMaybe<Array<SortInput>>;
};

export type State = {
  __typename?: 'State';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  highlightColor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isInitState: Scalars['Boolean']['output'];
  name: TranslationObject;
  types?: Maybe<Array<StateType>>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type StateFunction = {
  __typename?: 'StateFunction';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Name of the work level */
  name: TranslationObject;
  /** People who work at this level */
  people?: Maybe<Array<Person>>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type StateFunctionElemCreate = {
  /** id of of the work level to create */
  id: Scalars['ID']['input'];
};

export type StateFunctionElemUpdate = {
  /** id of of the work level to update */
  id: Scalars['ID']['input'];
  /** which action do you want to do with that work level */
  task: ArrayTasks;
};

export type StateFunctionPagination = {
  __typename?: 'StateFunctionPagination';
  items: Array<StateFunction>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type StatePagination = {
  __typename?: 'StatePagination';
  items: Array<State>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type StateType = {
  __typename?: 'StateType';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isTrialType: Scalars['Boolean']['output'];
  name: TranslationObject;
  persons?: Maybe<Array<Person>>;
  requireMembership: Scalars['Boolean']['output'];
  state: State;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type StateTypePagination = {
  __typename?: 'StateTypePagination';
  items: Array<StateType>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  addedAddress: Address;
  addedAddressType: AddressType;
  addedAssociation: Association;
  addedAssociationType: AssociationType;
  addedBillingInformation: BillingInformation;
  addedCourse: Course;
  addedInvoice: Invoice;
  addedMailing: Mailing;
  addedMembership: Membership;
  addedNewsletter: Newsletter;
  addedNewsletterMailing: NewsletterMailing;
  addedNewsletterMailingItem: NewsletterMailingItem;
  addedPerson: Person;
  addedSepa: Sepa;
  addedSportsEducation: SportsEducation;
  addedState: State;
  addedStateFunction: StateFunction;
  addedStateType: StateType;
  addedSvssFunction: SvssFunction;
  addedTransaction: SvssTransaction;
  addedWorkLevel: WorkLevel;
  deletedAddress: Address;
  deletedAddressType: AddressType;
  deletedAssociation: Association;
  deletedAssociationIfMember: Association;
  deletedAssociationType: AssociationType;
  deletedBillingInformation: BillingInformation;
  deletedCourse: Course;
  deletedInvoice: Invoice;
  deletedMailing: Mailing;
  deletedMembership: Membership;
  deletedNewsletter: Newsletter;
  deletedNewsletterMailing: NewsletterMailing;
  deletedNewsletterMailingItem: NewsletterMailingItem;
  deletedPerson: Person;
  deletedSepa: Sepa;
  deletedSportsEducation: SportsEducation;
  deletedState: State;
  deletedStateFunction: StateFunction;
  deletedStateType: StateType;
  deletedSvssFunction: SvssFunction;
  deletedWorkLevel: WorkLevel;
  updatedAddress: Address;
  updatedAddressType: AddressType;
  updatedAssociation: Association;
  updatedAssociationIfMember: Association;
  updatedAssociationType: AssociationType;
  updatedBillingInformation: BillingInformation;
  updatedCourse: Course;
  updatedInvoice: Invoice;
  updatedInvoiceIfAssociation: Invoice;
  updatedInvoiceIfOwner: Invoice;
  updatedMailing: Mailing;
  updatedMembership: Membership;
  updatedNewsletter: Newsletter;
  updatedNewsletterMailing: NewsletterMailing;
  updatedNewsletterMailingItem: NewsletterMailingItem;
  updatedPerson: Person;
  updatedPersonIfOwns: Person;
  updatedSportsEducation: SportsEducation;
  updatedState: State;
  updatedStateFunction: StateFunction;
  updatedStateType: StateType;
  updatedSvssFunction: SvssFunction;
  updatedWorkLevel: WorkLevel;
};

export type SvssFunction = {
  __typename?: 'SvssFunction';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Name of the work level */
  name: TranslationObject;
  /** People who work at this level */
  people?: Maybe<Array<Person>>;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type SvssFunctionElemCreate = {
  /** id of of the work level to create */
  id: Scalars['ID']['input'];
};

export type SvssFunctionElemUpdate = {
  /** id of of the work level to update */
  id: Scalars['ID']['input'];
  /** which action do you want to do with that work level */
  task: ArrayTasks;
};

export type SvssFunctionPagination = {
  __typename?: 'SvssFunctionPagination';
  items: Array<SvssFunction>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type SvssTransaction = {
  __typename?: 'SvssTransaction';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  state: TransactionState;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  fancyboxUrl: Scalars['String']['output'];
  transactionId: Scalars['ID']['output'];
};

export enum TransactionState {
  Authorized = 'AUTHORIZED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Create = 'CREATE',
  Decline = 'DECLINE',
  Failed = 'FAILED',
  Fulfill = 'FULFILL',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Voided = 'VOIDED'
}

export enum TranslationLanguage {
  De = 'de',
  Fr = 'fr',
  It = 'it'
}

export type TranslationObject = {
  __typename?: 'TranslationObject';
  de: Scalars['String']['output'];
  fr: Scalars['String']['output'];
  it: Scalars['String']['output'];
};

export type TranslationObjectCreate = {
  de: Scalars['String']['input'];
  fr: Scalars['String']['input'];
  it: Scalars['String']['input'];
};

export type TranslationObjectUpdate = {
  de?: InputMaybe<Scalars['String']['input']>;
  fr?: InputMaybe<Scalars['String']['input']>;
  it?: InputMaybe<Scalars['String']['input']>;
};

export type WorkLevel = {
  __typename?: 'WorkLevel';
  /** When the object was created */
  createdAt: Scalars['String']['output'];
  /** Which user created the object */
  createdFrom: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Name of the work level */
  name: TranslationObject;
  /** When the object was updated at last */
  updatedAt: Scalars['String']['output'];
  /** Which user updated the object at last */
  updatedFrom: Scalars['Int']['output'];
  /** People who work at this level */
  worksOnLevel?: Maybe<Array<Person>>;
};

export type WorkLevelElemCreate = {
  /** id of of the work level to create */
  id: Scalars['ID']['input'];
};

export type WorkLevelElemUpdate = {
  /** id of of the work level to update */
  id: Scalars['ID']['input'];
  /** which action do you want to do with that work level */
  task: ArrayTasks;
};

export type WorkLevelPagination = {
  __typename?: 'WorkLevelPagination';
  items: Array<WorkLevel>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type CreateInvoiceReturn = {
  __typename?: 'createInvoiceReturn';
  errorMessage: Scalars['String']['output'];
  processId: Scalars['ID']['output'];
  progress: Scalars['Int']['output'];
  returnCode: Scalars['Int']['output'];
};

export type SportEducationFragmentFragment = { __typename?: 'SportsEducation', id: string, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string } };

export type GetSportsEducationsNoPersonQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetSportsEducationsNoPersonQuery = { __typename?: 'Query', getSportsEducationsNoPerson: { __typename?: 'SportsEducationPagination', nextCursor?: string | null, items: Array<{ __typename?: 'SportsEducation', id: string, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string } }> } };

export const SportEducationFragmentFragmentDoc = gql`
    fragment sportEducationFragment on SportsEducation {
  id
  name {
    de
    fr
    it
  }
}
    `;
export const GetSportsEducationsNoPersonDocument = gql`
    query getSportsEducationsNoPerson($cursor: StartCursorInput!) {
  getSportsEducationsNoPerson(cursor: $cursor) {
    items {
      ...sportEducationFragment
    }
    nextCursor
  }
}
    ${SportEducationFragmentFragmentDoc}`;

/**
 * __useGetSportsEducationsNoPersonQuery__
 *
 * To run a query within a React component, call `useGetSportsEducationsNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsEducationsNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsEducationsNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetSportsEducationsNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetSportsEducationsNoPersonQuery, GetSportsEducationsNoPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSportsEducationsNoPersonQuery, GetSportsEducationsNoPersonQueryVariables>(GetSportsEducationsNoPersonDocument, options);
      }
export function useGetSportsEducationsNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSportsEducationsNoPersonQuery, GetSportsEducationsNoPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSportsEducationsNoPersonQuery, GetSportsEducationsNoPersonQueryVariables>(GetSportsEducationsNoPersonDocument, options);
        }
export type GetSportsEducationsNoPersonQueryHookResult = ReturnType<typeof useGetSportsEducationsNoPersonQuery>;
export type GetSportsEducationsNoPersonLazyQueryHookResult = ReturnType<typeof useGetSportsEducationsNoPersonLazyQuery>;
export type GetSportsEducationsNoPersonQueryResult = Apollo.QueryResult<GetSportsEducationsNoPersonQuery, GetSportsEducationsNoPersonQueryVariables>;