import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import SportsEducationBusiness from './SportsEducation.business'

export default function SportsEducationData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { sportsEducationId } = useParams<{ sportsEducationId: string }>()
  const { language } = useParams<{ language: string }>()

  function setSportsEducationId(sportsEducationId: string | undefined) {
    if (sportsEducationId && sportsEducationId !== '') {
      pushPath(`/${language}/settings/sports-education/${sportsEducationId}`)
    } else {
      pushPath(`/${language}/settings/sports-education`)
    }
  }

  return (
    <SportsEducationBusiness
      subNav={subNav}
      sportsEducationId={sportsEducationId}
      setSportsEducationId={setSportsEducationId}
    />
  )
}
