import { useState, useEffect } from 'react'

import { useAlert } from '@lemonbrain/hooks'
import { useIntl } from 'react-intl'

import StateTypeTableBusiness from './StateTypeTable.business'
import getReactApolloCients from '../../../localReactApollo'
import { RowDataT } from './StateTypeTable'

import {
  useDeleteStateTypeMutation,
  useGetStateTypesQuery,
  GetNextStateTypesDocument,
  GetNextStateTypesQuery,
} from '../../../gql/StateType.generated'

export default function StateTypeTableData({
  setStateTypeId,
  stateId
}: {
  setStateTypeId: (stateTypeId: string) => void,
  stateId: string
}) {
  const setAlert = useAlert()
  const intl = useIntl()

    const [stateTypes, setStateTypes] = useState<
    {
      id: string
      data: RowDataT
    }[]
  >([])
  const client = getReactApolloCients().getClient()

  const [deleteStateTypeMutation, {loading: deleteLoading}] = useDeleteStateTypeMutation({
    //@ts-ignore
    client,
  })
  const {
    data,
    loading,
    error: queryError,
    fetchMore,
    refetch
  } = useGetStateTypesQuery({
    variables: {
      cursor: {
        pageSize: 10,
        filtered: [
          {
            id: 'state_id',
            value: stateId
          }
        ]
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  async function removeStateTypeEntry(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await deleteStateTypeMutation({
          variables: {
            id,
          },
        })

        if (result.data && result.data.deleteStateType.id) {
          resolve(true)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    })
  }

  function fetchMoreStateTypeEntries() {
    if (data?.getStateTypes.nextCursor) {
      fetchMore({
        query: GetNextStateTypesDocument,
        variables: {
          cursor: {
            pageSize: 5,
            nextCursor: data?.getStateTypes.nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextStateTypesQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextWorkLevels: {
                nextCursor: fetchMoreResult.getNextStateTypes.nextCursor,
                items: [...fetchMoreResult.getNextStateTypes.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextStateTypes.items.length > 0 &&
            previousResult.getStateTypes.items.filter((item) => {
              return item.id === fetchMoreResult.getNextStateTypes.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getStateTypes: {
                ...previousResult.getStateTypes,
                nextCursor: fetchMoreResult.getNextStateTypes.nextCursor,
                items: [
                  ...previousResult.getStateTypes.items,
                  ...fetchMoreResult.getNextStateTypes.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }

  useEffect(() => {
    if (data?.getStateTypes.items) {
        setStateTypes(
        data?.getStateTypes.items.map((item) => ({
          id: item.id.toString() || '',
          data: {
            name: {
              de: item.name.de,
              fr: item.name.fr,
              it: item.name.it,
            },
            isTrialType: item.isTrialType,
            requireMembership: item.requireMembership,
            createdFrom: item.createdFrom.toString(),
          },
        }))
      )
    }
  }, [data])

  function goToStateTypeEntry(id: string) {
    setStateTypeId(id)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.stateTypeTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.stateTypeTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  return (
    <StateTypeTableBusiness
      isLoading={loading || deleteLoading}
      stateTypes={stateTypes}
      fetchMoreStateTypeEntries={fetchMoreStateTypeEntries}
      refetchStateTypeEntries={refetch}
      goToStateTypeEntry={goToStateTypeEntry}
      removeStateTypeEntry={removeStateTypeEntry}
    />
  )
}
