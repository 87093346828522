// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SvssFunctionFragmentFragment = (
  { __typename?: 'SvssFunction' }
  & Pick<Types.SvssFunction, 'id' | 'createdFrom'>
  & { name: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ) }
);

export type GetSvssFunctionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetSvssFunctionQuery = (
  { __typename?: 'Query' }
  & { getSvssFunction: (
    { __typename?: 'SvssFunction' }
    & SvssFunctionFragmentFragment
  ) }
);

export type AddSvssFunctionMutationVariables = Types.Exact<{
  name: Types.TranslationObjectCreate;
}>;


export type AddSvssFunctionMutation = (
  { __typename?: 'Mutation' }
  & { addSvssFunction: (
    { __typename?: 'SvssFunction' }
    & SvssFunctionFragmentFragment
  ) }
);

export type UpdateSvssFunctionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.TranslationObjectUpdate;
}>;


export type UpdateSvssFunctionMutation = (
  { __typename?: 'Mutation' }
  & { updateSvssFunction: (
    { __typename?: 'SvssFunction' }
    & SvssFunctionFragmentFragment
  ) }
);

export type DeleteSvssFunctionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSvssFunctionMutation = (
  { __typename?: 'Mutation' }
  & { deleteSvssFunction: (
    { __typename?: 'SvssFunction' }
    & SvssFunctionFragmentFragment
  ) }
);

export type GetSvssFunctionsNoPersonQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetSvssFunctionsNoPersonQuery = (
  { __typename?: 'Query' }
  & { getSvssFunctionsNoPerson: (
    { __typename?: 'SvssFunctionPagination' }
    & Pick<Types.SvssFunctionPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'SvssFunction' }
      & SvssFunctionFragmentFragment
    )> }
  ) }
);

export type GetNextSvssFunctionsNoPersonQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextSvssFunctionsNoPersonQuery = (
  { __typename?: 'Query' }
  & { getNextSvssFunctionsNoPerson: (
    { __typename?: 'SvssFunctionPagination' }
    & Pick<Types.SvssFunctionPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'SvssFunction' }
      & SvssFunctionFragmentFragment
    )> }
  ) }
);

export const SvssFunctionFragmentFragmentDoc = gql`
    fragment SvssFunctionFragment on SvssFunction {
  id
  name {
    de
    fr
    it
  }
  createdFrom
}
    `;
export const GetSvssFunctionDocument = gql`
    query getSvssFunction($id: ID!) {
  getSvssFunction(id: $id) {
    ...SvssFunctionFragment
  }
}
    ${SvssFunctionFragmentFragmentDoc}`;

/**
 * __useGetSvssFunctionQuery__
 *
 * To run a query within a React component, call `useGetSvssFunctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSvssFunctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSvssFunctionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSvssFunctionQuery(baseOptions: Apollo.QueryHookOptions<GetSvssFunctionQuery, GetSvssFunctionQueryVariables>) {
        return Apollo.useQuery<GetSvssFunctionQuery, GetSvssFunctionQueryVariables>(GetSvssFunctionDocument, baseOptions);
      }
export function useGetSvssFunctionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSvssFunctionQuery, GetSvssFunctionQueryVariables>) {
          return Apollo.useLazyQuery<GetSvssFunctionQuery, GetSvssFunctionQueryVariables>(GetSvssFunctionDocument, baseOptions);
        }
export type GetSvssFunctionQueryHookResult = ReturnType<typeof useGetSvssFunctionQuery>;
export type GetSvssFunctionLazyQueryHookResult = ReturnType<typeof useGetSvssFunctionLazyQuery>;
export type GetSvssFunctionQueryResult = Apollo.QueryResult<GetSvssFunctionQuery, GetSvssFunctionQueryVariables>;
export const AddSvssFunctionDocument = gql`
    mutation addSvssFunction($name: TranslationObjectCreate!) {
  addSvssFunction(name: $name) {
    ...SvssFunctionFragment
  }
}
    ${SvssFunctionFragmentFragmentDoc}`;
export type AddSvssFunctionMutationFn = Apollo.MutationFunction<AddSvssFunctionMutation, AddSvssFunctionMutationVariables>;

/**
 * __useAddSvssFunctionMutation__
 *
 * To run a mutation, you first call `useAddSvssFunctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSvssFunctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSvssFunctionMutation, { data, loading, error }] = useAddSvssFunctionMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddSvssFunctionMutation(baseOptions?: Apollo.MutationHookOptions<AddSvssFunctionMutation, AddSvssFunctionMutationVariables>) {
        return Apollo.useMutation<AddSvssFunctionMutation, AddSvssFunctionMutationVariables>(AddSvssFunctionDocument, baseOptions);
      }
export type AddSvssFunctionMutationHookResult = ReturnType<typeof useAddSvssFunctionMutation>;
export type AddSvssFunctionMutationResult = Apollo.MutationResult<AddSvssFunctionMutation>;
export type AddSvssFunctionMutationOptions = Apollo.BaseMutationOptions<AddSvssFunctionMutation, AddSvssFunctionMutationVariables>;
export const UpdateSvssFunctionDocument = gql`
    mutation updateSvssFunction($id: ID!, $name: TranslationObjectUpdate!) {
  updateSvssFunction(id: $id, name: $name) {
    ...SvssFunctionFragment
  }
}
    ${SvssFunctionFragmentFragmentDoc}`;
export type UpdateSvssFunctionMutationFn = Apollo.MutationFunction<UpdateSvssFunctionMutation, UpdateSvssFunctionMutationVariables>;

/**
 * __useUpdateSvssFunctionMutation__
 *
 * To run a mutation, you first call `useUpdateSvssFunctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSvssFunctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSvssFunctionMutation, { data, loading, error }] = useUpdateSvssFunctionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSvssFunctionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSvssFunctionMutation, UpdateSvssFunctionMutationVariables>) {
        return Apollo.useMutation<UpdateSvssFunctionMutation, UpdateSvssFunctionMutationVariables>(UpdateSvssFunctionDocument, baseOptions);
      }
export type UpdateSvssFunctionMutationHookResult = ReturnType<typeof useUpdateSvssFunctionMutation>;
export type UpdateSvssFunctionMutationResult = Apollo.MutationResult<UpdateSvssFunctionMutation>;
export type UpdateSvssFunctionMutationOptions = Apollo.BaseMutationOptions<UpdateSvssFunctionMutation, UpdateSvssFunctionMutationVariables>;
export const DeleteSvssFunctionDocument = gql`
    mutation deleteSvssFunction($id: ID!) {
  deleteSvssFunction(id: $id) {
    ...SvssFunctionFragment
  }
}
    ${SvssFunctionFragmentFragmentDoc}`;
export type DeleteSvssFunctionMutationFn = Apollo.MutationFunction<DeleteSvssFunctionMutation, DeleteSvssFunctionMutationVariables>;

/**
 * __useDeleteSvssFunctionMutation__
 *
 * To run a mutation, you first call `useDeleteSvssFunctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSvssFunctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSvssFunctionMutation, { data, loading, error }] = useDeleteSvssFunctionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSvssFunctionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSvssFunctionMutation, DeleteSvssFunctionMutationVariables>) {
        return Apollo.useMutation<DeleteSvssFunctionMutation, DeleteSvssFunctionMutationVariables>(DeleteSvssFunctionDocument, baseOptions);
      }
export type DeleteSvssFunctionMutationHookResult = ReturnType<typeof useDeleteSvssFunctionMutation>;
export type DeleteSvssFunctionMutationResult = Apollo.MutationResult<DeleteSvssFunctionMutation>;
export type DeleteSvssFunctionMutationOptions = Apollo.BaseMutationOptions<DeleteSvssFunctionMutation, DeleteSvssFunctionMutationVariables>;
export const GetSvssFunctionsNoPersonDocument = gql`
    query getSvssFunctionsNoPerson($cursor: StartCursorInput!) {
  getSvssFunctionsNoPerson(cursor: $cursor) {
    items {
      ...SvssFunctionFragment
    }
    nextCursor
  }
}
    ${SvssFunctionFragmentFragmentDoc}`;

/**
 * __useGetSvssFunctionsNoPersonQuery__
 *
 * To run a query within a React component, call `useGetSvssFunctionsNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSvssFunctionsNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSvssFunctionsNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetSvssFunctionsNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetSvssFunctionsNoPersonQuery, GetSvssFunctionsNoPersonQueryVariables>) {
        return Apollo.useQuery<GetSvssFunctionsNoPersonQuery, GetSvssFunctionsNoPersonQueryVariables>(GetSvssFunctionsNoPersonDocument, baseOptions);
      }
export function useGetSvssFunctionsNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSvssFunctionsNoPersonQuery, GetSvssFunctionsNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetSvssFunctionsNoPersonQuery, GetSvssFunctionsNoPersonQueryVariables>(GetSvssFunctionsNoPersonDocument, baseOptions);
        }
export type GetSvssFunctionsNoPersonQueryHookResult = ReturnType<typeof useGetSvssFunctionsNoPersonQuery>;
export type GetSvssFunctionsNoPersonLazyQueryHookResult = ReturnType<typeof useGetSvssFunctionsNoPersonLazyQuery>;
export type GetSvssFunctionsNoPersonQueryResult = Apollo.QueryResult<GetSvssFunctionsNoPersonQuery, GetSvssFunctionsNoPersonQueryVariables>;
export const GetNextSvssFunctionsNoPersonDocument = gql`
    query getNextSvssFunctionsNoPerson($cursor: NextCursorInput!) {
  getNextSvssFunctionsNoPerson(cursor: $cursor) {
    items {
      ...SvssFunctionFragment
    }
    nextCursor
  }
}
    ${SvssFunctionFragmentFragmentDoc}`;

/**
 * __useGetNextSvssFunctionsNoPersonQuery__
 *
 * To run a query within a React component, call `useGetNextSvssFunctionsNoPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextSvssFunctionsNoPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextSvssFunctionsNoPersonQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextSvssFunctionsNoPersonQuery(baseOptions: Apollo.QueryHookOptions<GetNextSvssFunctionsNoPersonQuery, GetNextSvssFunctionsNoPersonQueryVariables>) {
        return Apollo.useQuery<GetNextSvssFunctionsNoPersonQuery, GetNextSvssFunctionsNoPersonQueryVariables>(GetNextSvssFunctionsNoPersonDocument, baseOptions);
      }
export function useGetNextSvssFunctionsNoPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextSvssFunctionsNoPersonQuery, GetNextSvssFunctionsNoPersonQueryVariables>) {
          return Apollo.useLazyQuery<GetNextSvssFunctionsNoPersonQuery, GetNextSvssFunctionsNoPersonQueryVariables>(GetNextSvssFunctionsNoPersonDocument, baseOptions);
        }
export type GetNextSvssFunctionsNoPersonQueryHookResult = ReturnType<typeof useGetNextSvssFunctionsNoPersonQuery>;
export type GetNextSvssFunctionsNoPersonLazyQueryHookResult = ReturnType<typeof useGetNextSvssFunctionsNoPersonLazyQuery>;
export type GetNextSvssFunctionsNoPersonQueryResult = Apollo.QueryResult<GetNextSvssFunctionsNoPersonQuery, GetNextSvssFunctionsNoPersonQueryVariables>;