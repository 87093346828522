import { useState, useEffect } from 'react'
import Cookies from "js-cookie"

export const useCookies = (key: string) => {
    const initial = Cookies.get(key)
    const [cookie, setCookie] = useState(initial)

    useEffect(() => {
        if (cookie === '') {
            Cookies.remove(key)
        } else {
            Cookies.set(key, cookie || '')
        }
    }, [cookie, key])

    const clearCookie = () => {
        setCookie('')
    }

    return {
        cookie, 
        setCookie, 
        clearCookie
    }
}