import { FormattedMessage, useIntl } from 'react-intl'
import { Block } from 'baseui/block'
import { ParagraphMedium, LabelMedium } from 'baseui/typography'
import { Spinner, SIZE } from 'baseui/spinner'

import { priceFormater } from '@lemonbrain/utils'

export default function PaymentInfos({
    loading,
  firstName,
  lastName,
  firmName,
  mailbox,
  streetNr,
  postCode,
  place,
  account,
  iban,
  total
}: {
    loading: boolean
  firstName: string
  lastName: string
  firmName: string
  mailbox: string
  streetNr: string
  postCode: number
  place: string
  account: string
  iban: string
  total: number
}) {
  const intl = useIntl()

  return loading ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <>
      <LabelMedium>
        <FormattedMessage
          id='invoices.paymentInfos.paymentInfos'
          defaultMessage={'Payment infos'}
        />
      </LabelMedium>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
      <ParagraphMedium>
        {firmName !== '' && (
          <>
            {firmName} <br />
          </>
        )}
        {(firstName !== '' || lastName !== '') && (
          <>
            {firstName} {lastName} <br />
          </>
        )}
        {mailbox !== '' && (
          <>
            {mailbox} <br />
          </>
        )}
        {streetNr !== '' && (
          <>
            {streetNr} <br />
          </>
        )}
        {(postCode > 0 || place !== '') && (
          <>
            {postCode} {place} <br />
          </>
        )}
      </ParagraphMedium>
      </Block>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
      <ParagraphMedium>
        {account !== '' && (
          <>
            {intl.formatMessage(
              {
                id: 'invoices.paymentInfos.account',
                defaultMessage: 'Account: {account}',
              },
              {
                account,
              }
            )}{' '}
            <br />
          </>
        )}
        {iban !== '' && (
          <>
            {intl.formatMessage(
              {
                id: 'invoices.paymentInfos.iban',
                defaultMessage: 'IBAN: {iban}',
              },
              {
                iban,
              }
            )}{' '}
            <br />
          </>
        )}
      </ParagraphMedium>
      </Block>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
      <ParagraphMedium>
        {total > 0 && (
          <>
            {intl.formatMessage(
              {
                id: 'invoices.paymentInfos.total',
                defaultMessage: 'Amount: CHF {total}',
              },
              {
                total: priceFormater(total / 100),
              }
            )}{' '}
            <br />
          </>
        )}
      </ParagraphMedium>
      </Block>
    </>
  )
}
