import MembersData from './views/Members/Members.data'
import MembersOverviewData from './views/MembersOverview/MembersOverview.data'
import MembersInvoicesData from './views/MembersInvoices/MembersInvoices.data'
import MembersCoursesData from './views/MembersCourses/MembersCourses.data'
import MembersAddData from './views/MembersAdd/MembersAdd.data'
import MemberSelectorData from './views/MemberSelector/MemberSelector.data'

import germanMessages from './messages/de'
import getReactApolloCients from './localReactApollo'

import {
  addMenuRoutesBeforeRender,
  addGermanMessagesBeforeRender,
  addWsClientBeforeRender,
  addComponentsBeforeRender,
} from '@lemonbrain/hooks'

addMenuRoutesBeforeRender('MAIN', [
  {
    title: {
      id: 'members.members',
      defaultMessage: 'Members',
    },
    icon: 'members',
    id: 'members',
    path: '/members/',
    routePath: '/members',
    component: MembersData,
    size: 'small',
    tabIndex: 10,
    visible: true,
    active: true,
    enabled: true,
    accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
    children: [
      {
        title: {
          id: 'members.overview',
          defaultMessage: 'Overview',
        },
        icon: 'members',
        id: 'membersOverview',
        path: '/members/overview/:personId?',
        routePath: '/members/overview',
        component: MembersOverviewData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
      },
      {
        title: {
          id: 'members.invoices',
          defaultMessage: 'Invoices',
        },
        icon: 'members',
        id: 'membersInvoices',
        path: '/members/overview/:personId?/invoices',
        routePath: '/members/overview',
        component: MembersInvoicesData,
        size: 'small',
        tabIndex: 12,
        visible: false,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
      },
      {
        title: {
          id: 'members.courses',
          defaultMessage: 'Courses',
        },
        icon: 'members',
        id: 'membersCourses',
        path: '/members/overview/:personId?/courses',
        routePath: '/members/overview',
        component: MembersCoursesData,
        size: 'small',
        tabIndex: 13,
        visible: false,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
      },
      {
        title: {
          id: 'members.add',
          defaultMessage: 'Add',
        },
        icon: 'members',
        id: 'membersAdd',
        path: '/members/add',
        routePath: '/members/add',
        component: MembersAddData,
        size: 'small',
        tabIndex: 12,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
    ],
  },
])

const wsClient = getReactApolloCients().getWsClient()
if (wsClient) {
  addWsClientBeforeRender(wsClient)
}

addGermanMessagesBeforeRender(germanMessages)


let components = {
  MemberSelectorData
}

addComponentsBeforeRender(components)