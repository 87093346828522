import React from 'react'
import { Block } from 'baseui/block'
import { ParagraphMedium } from 'baseui/typography'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'

export default function CheckValidationEmail () {
    return (
        <Container>
            <Block
                overrides={{
                    Block: {
                        style: {
                            minHeight: 'calc(100vh - 213px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    },
                }}
            >
                <Block
                    as="h1"
                    overrides={{
                        Block: {
                            style: ({ $theme }) => {
                                return {
                                    ...$theme.typography.font1250,
                                    color: '#fff',
                                    fontWeight: 700,
                                    marginBottom: '30px',
                                }
                            },
                        },
                    }}
                >
                    <FormattedMessage
                        id='auth.checkValidationEmail.emailValidationRequired'
                        defaultMessage={'Email validation Required'}
                    />
                </Block>
                <ParagraphMedium>
                    <FormattedMessage
                        id="auth.checkValidationEmail.sentEmailOnAccountCreation"
                        defaultMessage="On account creation we have sent you an email to validate your email address."
                    />
                </ParagraphMedium>
                <br />
                <ParagraphMedium>
                    <b>
                        {' '}
                        <FormattedMessage id="auth.checkValidationEmail.followTheInstructions" defaultMessage="Please follow the instructions in the email." />
                    </b>
                </ParagraphMedium>
                <br />
                <ParagraphMedium>
                    <FormattedMessage
                        id="auth.checkValidationEmail.canNotFindValidationEmail"
                        defaultMessage="If you can't find the validation email, please have also a look in your spam folder!"
                    />
                </ParagraphMedium>
            </Block>
        </Container>
    )
}
