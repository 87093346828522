import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const useHistoryPush = () => {
  const navigate = useNavigate()
  const [path, setPath] = useState<string>()

  useEffect(() => {
    if (path) {
      navigate(path)
    }
  }, [path, history])

  return setPath
}
