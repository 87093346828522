import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppState } from '@lemonbrain/contexts'
import { QueryLazyOptions } from '@apollo/client'

import { verifyEmail, required } from '@lemonbrain/utils'
import * as Types from '../../../../gql/members/types'
import { Exact, StartCursorInput } from '../../../../gql/members/types'

import Mail from './Mail'
import { MailingState } from '../../../../gql/members/types'

export default function MailBusiness({
  isOpen,
  setIsOpen,
  selectedPerson,
  getPersons,
  getPersonsEMail,
  loading,
  mailingId,
  addMailing,
  sendMailing,
  mailingState,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedPerson: string[]
  getPersons: ({
    __typename?: "PersonPagination" | undefined;
} & Pick<Types.PersonPagination, "nextCursor"> & {
    items: ({
        __typename?: "Person" | undefined;
    } & Pick<Types.Person, "email">)[];
}) | undefined
  getPersonsEMail: (
    options?:
      | QueryLazyOptions<
          Exact<{
            cursor: StartCursorInput
          }>
        >
      | undefined
  ) => void
  loading: boolean
  mailingId: unknown
  addMailing({
    from,
    fromName,
    to,
    subject,
    content,
  }: {
    from: string
    fromName: string
    to: string[]
    subject: string
    content: string
  }): void
  sendMailing(id: string): void
  mailingState: {
    sentCount?: number
    failedCount?: number
    recipientCount?: number
    state?: MailingState
    from?: string
    fromName?: string
    to?: string[]
    subject?: string
    content?: string
  }
}) {
  const { user } = useAppState()
  const [mailing, setMailing] = useState<{
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
    content: string
  }>({
    fromEmail: user?.uEmail || '',
    fromNameEmail: user?.uEmail || '',
    toEmail: getPersons?.items.map((item) => item.email).join(', ') || '',
    subject: '',
    content: '',
  })
  const [errors, setErrors] = useState<{
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
    content: string
  }>({
    fromEmail: '',
    fromNameEmail: '',
    toEmail: '',
    subject: '',
    content: '',
  })
  const intl = useIntl()

  function checkFromEmail() {
    if (verifyEmail(mailing.fromEmail)) {
      setErrors((prev) => ({ ...prev, fromEmail: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      fromEmail: intl.formatMessage({
        id: 'members.mail.fromEmailIsRequired',
        defaultMessage:
          'From E-Mail needs to be in the right format and is required',
      }),
    }))
    return true
  }

  function checkFromNameEmail() {
    if (required(mailing.fromNameEmail)) {
      setErrors((prev) => ({ ...prev, fromNameEmail: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      fromNameEmail: intl.formatMessage({
        id: 'members.mail.fromNameEmailIsRequired',
        defaultMessage:
          'From E-Mail name needs to be in the right format and is required',
      }),
    }))
    return true
  }

  function checkToEmail() {
    if (required(mailing.toEmail)) {
      setErrors((prev) => ({ ...prev, toEmail: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      toEmail: intl.formatMessage({
        id: 'members.mail.toEmailIsRequired',
        defaultMessage: 'To E-Mail is required',
      }),
    }))
    return true
  }

  function checkSubject() {
    if (required(mailing.subject)) {
      setErrors((prev) => ({ ...prev, subject: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      subject: intl.formatMessage({
        id: 'members.mail.subjectIsRequired',
        defaultMessage: 'Subject is required',
      }),
    }))
    return true
  }

  function checkContent() {
    if (required(mailing.content)) {
      setErrors((prev) => ({ ...prev, content: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      content: intl.formatMessage({
        id: 'members.mail.contentIsRequired',
        defaultMessage: 'Content is required',
      }),
    }))
    return true
  }

  function onClick() {
    if (!isOpen && selectedPerson.length > 0) {
      getPersonsEMail({
        variables: {
          cursor: {
            pageSize: 9999,
            filtered: [
              {
                id: 'ids',
                value: selectedPerson.join(', '),
              },
            ],
          },
        },
      })
    }
    setIsOpen((prev) => !prev)
  }

  function send() {
    if (mailingId) {
      sendMailing(mailingId as string)
    } else {
      addMailing({
        from: mailing.fromEmail,
        fromName: mailing.fromNameEmail,
        to: mailing.toEmail.split(','),
        subject: mailing.subject,
        content: mailing.content,
      })
    }
  }

  useEffect(() => {
    if (!mailing.subject && mailingState.subject) {
      setMailing({
        fromEmail: mailingState.from || '',
        fromNameEmail: mailingState.fromName || '',
        toEmail: (mailingState.to && mailingState.to.join(', ')) || '',
        subject: mailingState.subject || '',
        content: mailingState.content || '',
      })
    }
  }, [mailingState])

  useEffect(() => {
    checkFromEmail()
    checkFromNameEmail()
    checkToEmail()
    checkSubject()
    checkContent()
  }, [mailing])

  useEffect(() => {
    if (!isOpen && !mailingId) {
      setMailing({
        fromEmail: user?.uEmail || '',
        fromNameEmail: user?.uName || '',
        toEmail:
          getPersons?.items
            .map((item) => item.email)
            .join(', ') || '',
        subject: '',
        content: '',
      })
    }
  }, [isOpen])

  return (
    <Mail
      mailingState={mailingState}
      loading={loading}
      mailing={mailing}
      setMailing={setMailing}
      errors={errors}
      selectedPerson={selectedPerson}
      onClick={onClick}
      send={send}
      isOpen={isOpen}
      mailingId={mailingId}
    />
  )
}
