import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import MemberAddForm from "./MemberAddForm"
import { verifyEmail } from "@lemonbrain/utils/verify"

export default function MemberAddFormBusiness({
  values,
  setValues
}: {
  values: {
    email: string
  }
  setValues: React.Dispatch<React.SetStateAction<{ email: string }>>
}) {
  const intl = useIntl()

  const [errors, setErrors] = useState<{
    email: string,
  }>({
    email: '',
  })

  function checkEmail(email: string | null | undefined) {
    if (email && verifyEmail(email)) {
      setErrors((prev) => ({ ...prev, email: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      email: intl.formatMessage({
        id: 'members.personal.email.isRequired',
        defaultMessage: 'E-Mail needs to be in the right format and is required',
      }),
    }))
    return true
  }

  useEffect(() => {
    checkEmail(values?.email)
  }, [values])

  return (
    <MemberAddForm
      values={values}
      setValues={setValues}
      errors={errors}
    />
  )
}
