import SvssFunctionEditBusiness from './SvssFunctionEdit.business'
import {
    useGetSvssFunctionLazyQuery,
  useUpdateSvssFunctionMutation,
  useAddSvssFunctionMutation,
} from '../../../gql/SvssFunction.generated'
import getReactApolloCients from '../../../localReactApollo'

import { useEffect, useState } from 'react'

export default function SvssFunctionEditData({
  svssFunctionId,
  setSvssFunctionId,
}: {
  svssFunctionId: string | null | undefined
  setSvssFunctionId: (svssFunctionId: string | undefined) => void
}) {
  const [values, setValues] = useState<{
    de: string | undefined
    fr: string | undefined
    it: string | undefined
  }>({
    de: undefined,
    fr: undefined,
    it: undefined,
  })
  const client = getReactApolloCients().getClient()
  const [
    getSvssFunctionQuery,
    { data, loading, error: queryError },
  ] = useGetSvssFunctionLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateSvssFunctionMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateSvssFunctionMutation({
    //@ts-ignore
    client,
  })
  const [
    addSvssFunctionMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddSvssFunctionMutation({
    //@ts-ignore
    client,
  })

  async function updateSvssFunctionEntry(data: {
    svssFunctionId: string
    de: string
    fr: string
    it: string
  }) {
    if (data.svssFunctionId) {
      await updateSvssFunctionMutation({
        variables: {
          id: data.svssFunctionId,
          name: {
              de: data.de,
              fr: data.fr,
              it: data.it
          }
        },
      })
    }
  }

  async function createSvssFunctionEntry(data: {
    de: string
    fr: string
    it: string
  }) {
    await addSvssFunctionMutation({
      variables: {
        name: {
            de: data.de,
            fr: data.fr,
            it: data.it
        }
      },
    })
  }

  function removeSvssFunctionId() {
    setSvssFunctionId(undefined)
  }

  useEffect(() => {
    if (svssFunctionId) {
        getSvssFunctionQuery({
        variables: {
          id: svssFunctionId,
        },
      })
    }
  }, [svssFunctionId])

  useEffect(() => {
    if (data?.getSvssFunction) {

      setValues({
        de: data.getSvssFunction.name.de,
        fr: data.getSvssFunction.name.fr,
        it: data.getSvssFunction.name.it,
      })
    }
  }, [data])

  return (
    <SvssFunctionEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      queryError={queryError || queryChangeError || queryAddError}
      removeSvssFunctionId={removeSvssFunctionId}
      svssFunctionId={svssFunctionId}
      updateSvssFunctionEntry={updateSvssFunctionEntry}
      createSvssFunctionEntry={createSvssFunctionEntry}
    />
  )
}
