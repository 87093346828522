import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
    NormalizedCacheObject
} from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link';
import ApolloClient from 'apollo-client'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import createUploadLink from 'apollo-upload-client/public/createUploadLink';

import { customFetch, checkForExpiredToken } from './customFetch'

interface IntrospectionResultData {
    __schema: {
        types: {
            kind: string
            name: string
            possibleTypes: {
                name: string
            }[]
        }[]
    }
}

const getApolloClient = ({
    apolloUrl,
    introspectionResult,
}: {
    apolloUrl?: string,
    introspectionResult?: IntrospectionResultData
}) => {
    if (!apolloUrl) {
        throw new Error("You wanna have a client without a url, searously")
    }

    checkForExpiredToken()  //async

    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: introspectionResult
    })

    const cache = new InMemoryCache({
        fragmentMatcher
    })

    const httpLink = createUploadLink({
        uri: apolloUrl + '/graphql',
        credentials: 'include',
        fetch: customFetch
    }) as unknown as ApolloLink

    const client = new ApolloClient({
        link: httpLink,
        cache: cache,
        connectToDevTools: true,
        queryDeduplication: false,
    })

    return {
        client,
        // wsClient
    }
}


export class ReactApolloCients {
    private clients: {
        client: ApolloClient<NormalizedCacheObject> | null
    }

    constructor({
        apolloUrl,
        introspectionResult,
    }: {
        apolloUrl?: string,
        introspectionResult?: IntrospectionResultData
    }) {
        this.clients = getApolloClient({
            apolloUrl,
            introspectionResult
        })
    }

    public getWsClient () {
        return null //this.clients.wsClient
    }

    public getClient () {
        if (!this.clients.client) {
            throw new Error("There is no client")
        }
        return this.clients.client
    }
}

export function onLogout (wsClient: SubscriptionClient | null) {
    if (wsClient) {
        wsClient.unsubscribeAll()
        wsClient.close(true)
    }
}
