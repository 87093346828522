import { useEffect } from 'react'
import { Spinner, SIZE } from 'baseui/spinner'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import getReactApolloCients from '../../../../localReactApollo'
import { useGetInvoiceIfToAssociationQuery } from '../../../../gql/invoices/Invoices.generated'
import { usePayMutation } from '../../../../gql/payment/Postfinance.generated'
import { useAddTransactionMutation } from '../../../../gql/transaction/Transaction.generated'
import { PaymentState } from '../../InvoicesMy.data'

import OnlinePayerBusiness from './OnlinePayer.business'

export default function OnlinePayerData({
  invoiceId,
  paymentState,
  language,
}: {
  invoiceId: string
  paymentState: PaymentState | undefined
  language: string
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const client = getReactApolloCients().getClient()

  const {
    data: invoice,
    loading: loadingInvoice,
    error: errorInvoice,
  } = useGetInvoiceIfToAssociationQuery({
    variables: {
      id: invoiceId,
    },
    //@ts-ignore
    client,
  })

  const [
    payMutation,
    { error: paymentError },
  ] = usePayMutation({
    //@ts-ignore
    client,
  })

  const [
    addTransactionMutation,
    { error: addTransactionError },
  ] = useAddTransactionMutation({
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (errorInvoice || paymentError || addTransactionError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.paymentInfos.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.paymentInfos.errorMessage',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorInvoice?.message || paymentError?.message || addTransactionError?.message)
    }
  }, [errorInvoice, paymentError, addTransactionError])

  return loadingInvoice ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <OnlinePayerBusiness
      invoice={invoice?.getInvoiceIfToAssociation}
      invoiceId={invoiceId}
      paymentState={paymentState}
      language={language}
      payMutation={payMutation}
      addTransactionMutation={addTransactionMutation}
    />
  )
}
