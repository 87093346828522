import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import { useGetInvoicesNumberLazyQuery } from '../../../../gql/invoices/Invoices.generated'
import { Invoice } from '../../../../types'
import ExportBusiness from './Export.business'
import getReactApolloCients from '../../../../localReactApollo'

export default function ExportData({
  selectedInvoices,
}: {
  selectedInvoices: string[]
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [cursor, setCursor] = useState<
    | {
        pageSize: number
        filtered: {
          id: string
          value: string
        }[]
      }
    | undefined
  >()
  const client = getReactApolloCients().getClient()
  const [
    getInvoicesQuery,
    {
      data: dataGetInvoices,
      loading: loadingGetInvoices,
      error: errorGetInvoices,
    },
  ] = useGetInvoicesNumberLazyQuery({
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const startExport = function () {
    if (cursor) {
      getInvoicesQuery({
        variables: {
          cursor,
        },
      })
    }
  }

  useEffect(() => {
    setCursor({
      pageSize: 999,
      filtered: (() => {
        const filter: { id: string; value: string }[] = []

        filter.push({
          id: 'ids',
          value: selectedInvoices.join(', '),
        })
        return filter
      })(),
    })
  }, [selectedInvoices])

  useEffect(() => {
    if (dataGetInvoices && dataGetInvoices?.getInvoices.items.length > 0) {
      download_files(dataGetInvoices?.getInvoices.items)
    }
  }, [dataGetInvoices])

  function download_files(
    files: ({
      __typename?: 'Invoice' | undefined
    } & Pick<Invoice, 'invoiceNumber'>)[]
  ) {
    function download_next(i: number) {
      if (i >= files.length) {
        return
      }
      var a: HTMLAnchorElement = document.createElement('a')
      a.href = `${process.env.REACT_APP_MITGLIEDER_URL}/application/files/invoices/Rechnung_${files[i].invoiceNumber}.pdf`
      a.target = '_blank'
      // Use a.download if available, it prevents plugins from opening.
      if ('download' in a) {
        a.download = `${files[i].invoiceNumber}.pdf`
      }
      // Add a to the doc for click to work.
      ;(document.body || document.documentElement).appendChild(a)
      if (a.click) {
        a.click() // The click method is supported by most browsers.
      }
      // Delete the temporary link.
      a.parentNode?.removeChild(a)
      // Download the next file with a small timeout. The timeout is necessary
      // for IE, which will otherwise only download the first file.
      setTimeout(function () {
        download_next(i + 1)
      }, 500)
    }
    // Initiate the first download.
    download_next(0)
  }

  useEffect(() => {
    if (errorGetInvoices) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.export.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.export.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(errorGetInvoices?.message)
    }
  }, [errorGetInvoices])

  return (
    <ExportBusiness
      loading={loadingGetInvoices}
      startExport={startExport}
      selectedInvoices={selectedInvoices}
    />
  )
}
