// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingInformationFragmentFragment = { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number };

export type AssociationFragmentFragment = { __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null };

export type GetAssociationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetAssociationQuery = { __typename?: 'Query', getAssociation: { __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null } };

export type GetAssociationIfMemberQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetAssociationIfMemberQuery = { __typename?: 'Query', getAssociationIfMember: { __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null } };

export type GetAssociationsQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetAssociationsQuery = { __typename?: 'Query', getAssociations: { __typename?: 'AssociationPagination', nextCursor?: string | null, items: Array<{ __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null }> } };

export type GetAssociationsIfMemberQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetAssociationsIfMemberQuery = { __typename?: 'Query', getAssociationsIfMember: { __typename?: 'AssociationPagination', nextCursor?: string | null, items: Array<{ __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null }> } };

export type GetNextAssociationsQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextAssociationsQuery = { __typename?: 'Query', getNextAssociations: { __typename?: 'AssociationPagination', nextCursor?: string | null, items: Array<{ __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null }> } };

export type GetNextAssociationsIfMemberQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextAssociationsIfMemberQuery = { __typename?: 'Query', getNextAssociationsIfMember: { __typename?: 'AssociationPagination', nextCursor?: string | null, items: Array<{ __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null }> } };

export type AddAssociationMutationVariables = Types.Exact<{
  name: Types.TranslationObjectCreate;
  short: Types.TranslationObjectCreate;
  prize: Types.Scalars['Int']['input'];
  isStufenvereinigung: Types.Scalars['Boolean']['input'];
  needsAComment: Types.Scalars['Boolean']['input'];
  defaultComment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  membersCalculationAt?: Types.InputMaybe<Types.Scalars['String']['input']>;
  billingInformation: Types.BillingInformationCreate;
  associationURL: Types.Scalars['String']['input'];
  image: Types.Scalars['Upload']['input'];
}>;


export type AddAssociationMutation = { __typename?: 'Mutation', addAssociation: { __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null } };

export type UpdateAssociationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.TranslationObjectUpdate;
  short: Types.TranslationObjectUpdate;
  associationURL: Types.Scalars['String']['input'];
  isStufenvereinigung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  image?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
  imageId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  prize: Types.Scalars['Int']['input'];
  needsAComment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  defaultComment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  membersCalculationAt?: Types.InputMaybe<Types.Scalars['String']['input']>;
  billingInformation: Types.BillingInformationUpdate;
}>;


export type UpdateAssociationMutation = { __typename?: 'Mutation', updateAssociation: { __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null } };

export type UpdateAssociationIfMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.TranslationObjectUpdate;
  short: Types.TranslationObjectUpdate;
  associationURL: Types.Scalars['String']['input'];
  isStufenvereinigung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  image?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
  imageId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  prize: Types.Scalars['Int']['input'];
  needsAComment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  defaultComment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  membersCalculationAt?: Types.InputMaybe<Types.Scalars['String']['input']>;
  billingInformation: Types.BillingInformationUpdate;
}>;


export type UpdateAssociationIfMemberMutation = { __typename?: 'Mutation', updateAssociationIfMember: { __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null } };

export type DeleteAssociationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteAssociationMutation = { __typename?: 'Mutation', deleteAssociation: { __typename?: 'Association', id: string, imageId?: string | null, imageSrc?: string | null, associationURL?: string | null, isStufenvereinigung: boolean, prize: number, needsAComment: boolean, defaultComment?: string | null, membersCalculationAt?: string | null, createdFrom: number, name: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, short: { __typename?: 'TranslationObject', de: string, fr: string, it: string }, billingInformation?: { __typename?: 'BillingInformation', id: string, firstName?: string | null, lastName?: string | null, firmName?: string | null, mailbox?: string | null, streetNr?: string | null, postCode: number, place: string, canton?: string | null, country?: string | null, account?: string | null, iban: string, qrIban?: string | null, besrId?: number | null, createdFrom: number } | null } };

export const BillingInformationFragmentFragmentDoc = gql`
    fragment BillingInformationFragment on BillingInformation {
  id
  firstName
  lastName
  firmName
  mailbox
  streetNr
  postCode
  place
  canton
  country
  account
  iban
  qrIban
  besrId
  createdFrom
}
    `;
export const AssociationFragmentFragmentDoc = gql`
    fragment AssociationFragment on Association {
  id
  name {
    de
    fr
    it
  }
  short {
    de
    fr
    it
  }
  imageId
  imageSrc
  associationURL
  isStufenvereinigung
  prize
  needsAComment
  defaultComment
  membersCalculationAt
  billingInformation {
    ...BillingInformationFragment
  }
  createdFrom
}
    ${BillingInformationFragmentFragmentDoc}`;
export const GetAssociationDocument = gql`
    query getAssociation($id: ID!) {
  getAssociation(id: $id) {
    ...AssociationFragment
  }
}
    ${AssociationFragmentFragmentDoc}`;

/**
 * __useGetAssociationQuery__
 *
 * To run a query within a React component, call `useGetAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssociationQuery(baseOptions: Apollo.QueryHookOptions<GetAssociationQuery, GetAssociationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssociationQuery, GetAssociationQueryVariables>(GetAssociationDocument, options);
      }
export function useGetAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssociationQuery, GetAssociationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssociationQuery, GetAssociationQueryVariables>(GetAssociationDocument, options);
        }
export type GetAssociationQueryHookResult = ReturnType<typeof useGetAssociationQuery>;
export type GetAssociationLazyQueryHookResult = ReturnType<typeof useGetAssociationLazyQuery>;
export type GetAssociationQueryResult = Apollo.QueryResult<GetAssociationQuery, GetAssociationQueryVariables>;
export const GetAssociationIfMemberDocument = gql`
    query getAssociationIfMember($id: ID!) {
  getAssociationIfMember(id: $id) {
    ...AssociationFragment
  }
}
    ${AssociationFragmentFragmentDoc}`;

/**
 * __useGetAssociationIfMemberQuery__
 *
 * To run a query within a React component, call `useGetAssociationIfMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociationIfMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociationIfMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssociationIfMemberQuery(baseOptions: Apollo.QueryHookOptions<GetAssociationIfMemberQuery, GetAssociationIfMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssociationIfMemberQuery, GetAssociationIfMemberQueryVariables>(GetAssociationIfMemberDocument, options);
      }
export function useGetAssociationIfMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssociationIfMemberQuery, GetAssociationIfMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssociationIfMemberQuery, GetAssociationIfMemberQueryVariables>(GetAssociationIfMemberDocument, options);
        }
export type GetAssociationIfMemberQueryHookResult = ReturnType<typeof useGetAssociationIfMemberQuery>;
export type GetAssociationIfMemberLazyQueryHookResult = ReturnType<typeof useGetAssociationIfMemberLazyQuery>;
export type GetAssociationIfMemberQueryResult = Apollo.QueryResult<GetAssociationIfMemberQuery, GetAssociationIfMemberQueryVariables>;
export const GetAssociationsDocument = gql`
    query getAssociations($cursor: StartCursorInput!) {
  getAssociations(cursor: $cursor) {
    items {
      ...AssociationFragment
    }
    nextCursor
  }
}
    ${AssociationFragmentFragmentDoc}`;

/**
 * __useGetAssociationsQuery__
 *
 * To run a query within a React component, call `useGetAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAssociationsQuery(baseOptions: Apollo.QueryHookOptions<GetAssociationsQuery, GetAssociationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssociationsQuery, GetAssociationsQueryVariables>(GetAssociationsDocument, options);
      }
export function useGetAssociationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssociationsQuery, GetAssociationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssociationsQuery, GetAssociationsQueryVariables>(GetAssociationsDocument, options);
        }
export type GetAssociationsQueryHookResult = ReturnType<typeof useGetAssociationsQuery>;
export type GetAssociationsLazyQueryHookResult = ReturnType<typeof useGetAssociationsLazyQuery>;
export type GetAssociationsQueryResult = Apollo.QueryResult<GetAssociationsQuery, GetAssociationsQueryVariables>;
export const GetAssociationsIfMemberDocument = gql`
    query getAssociationsIfMember($cursor: StartCursorInput!) {
  getAssociationsIfMember(cursor: $cursor) {
    items {
      ...AssociationFragment
    }
    nextCursor
  }
}
    ${AssociationFragmentFragmentDoc}`;

/**
 * __useGetAssociationsIfMemberQuery__
 *
 * To run a query within a React component, call `useGetAssociationsIfMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociationsIfMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociationsIfMemberQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAssociationsIfMemberQuery(baseOptions: Apollo.QueryHookOptions<GetAssociationsIfMemberQuery, GetAssociationsIfMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssociationsIfMemberQuery, GetAssociationsIfMemberQueryVariables>(GetAssociationsIfMemberDocument, options);
      }
export function useGetAssociationsIfMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssociationsIfMemberQuery, GetAssociationsIfMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssociationsIfMemberQuery, GetAssociationsIfMemberQueryVariables>(GetAssociationsIfMemberDocument, options);
        }
export type GetAssociationsIfMemberQueryHookResult = ReturnType<typeof useGetAssociationsIfMemberQuery>;
export type GetAssociationsIfMemberLazyQueryHookResult = ReturnType<typeof useGetAssociationsIfMemberLazyQuery>;
export type GetAssociationsIfMemberQueryResult = Apollo.QueryResult<GetAssociationsIfMemberQuery, GetAssociationsIfMemberQueryVariables>;
export const GetNextAssociationsDocument = gql`
    query getNextAssociations($cursor: NextCursorInput!) {
  getNextAssociations(cursor: $cursor) {
    items {
      ...AssociationFragment
    }
    nextCursor
  }
}
    ${AssociationFragmentFragmentDoc}`;

/**
 * __useGetNextAssociationsQuery__
 *
 * To run a query within a React component, call `useGetNextAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextAssociationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextAssociationsQuery(baseOptions: Apollo.QueryHookOptions<GetNextAssociationsQuery, GetNextAssociationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextAssociationsQuery, GetNextAssociationsQueryVariables>(GetNextAssociationsDocument, options);
      }
export function useGetNextAssociationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextAssociationsQuery, GetNextAssociationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextAssociationsQuery, GetNextAssociationsQueryVariables>(GetNextAssociationsDocument, options);
        }
export type GetNextAssociationsQueryHookResult = ReturnType<typeof useGetNextAssociationsQuery>;
export type GetNextAssociationsLazyQueryHookResult = ReturnType<typeof useGetNextAssociationsLazyQuery>;
export type GetNextAssociationsQueryResult = Apollo.QueryResult<GetNextAssociationsQuery, GetNextAssociationsQueryVariables>;
export const GetNextAssociationsIfMemberDocument = gql`
    query getNextAssociationsIfMember($cursor: NextCursorInput!) {
  getNextAssociationsIfMember(cursor: $cursor) {
    items {
      ...AssociationFragment
    }
    nextCursor
  }
}
    ${AssociationFragmentFragmentDoc}`;

/**
 * __useGetNextAssociationsIfMemberQuery__
 *
 * To run a query within a React component, call `useGetNextAssociationsIfMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextAssociationsIfMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextAssociationsIfMemberQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextAssociationsIfMemberQuery(baseOptions: Apollo.QueryHookOptions<GetNextAssociationsIfMemberQuery, GetNextAssociationsIfMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextAssociationsIfMemberQuery, GetNextAssociationsIfMemberQueryVariables>(GetNextAssociationsIfMemberDocument, options);
      }
export function useGetNextAssociationsIfMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextAssociationsIfMemberQuery, GetNextAssociationsIfMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextAssociationsIfMemberQuery, GetNextAssociationsIfMemberQueryVariables>(GetNextAssociationsIfMemberDocument, options);
        }
export type GetNextAssociationsIfMemberQueryHookResult = ReturnType<typeof useGetNextAssociationsIfMemberQuery>;
export type GetNextAssociationsIfMemberLazyQueryHookResult = ReturnType<typeof useGetNextAssociationsIfMemberLazyQuery>;
export type GetNextAssociationsIfMemberQueryResult = Apollo.QueryResult<GetNextAssociationsIfMemberQuery, GetNextAssociationsIfMemberQueryVariables>;
export const AddAssociationDocument = gql`
    mutation addAssociation($name: TranslationObjectCreate!, $short: TranslationObjectCreate!, $prize: Int!, $isStufenvereinigung: Boolean!, $needsAComment: Boolean!, $defaultComment: String, $membersCalculationAt: String, $billingInformation: BillingInformationCreate!, $associationURL: String!, $image: Upload!) {
  addAssociation(
    name: $name
    short: $short
    prize: $prize
    isStufenvereinigung: $isStufenvereinigung
    needsAComment: $needsAComment
    defaultComment: $defaultComment
    membersCalculationAt: $membersCalculationAt
    billingInformation: $billingInformation
    associationURL: $associationURL
    image: $image
  ) {
    ...AssociationFragment
  }
}
    ${AssociationFragmentFragmentDoc}`;
export type AddAssociationMutationFn = Apollo.MutationFunction<AddAssociationMutation, AddAssociationMutationVariables>;

/**
 * __useAddAssociationMutation__
 *
 * To run a mutation, you first call `useAddAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssociationMutation, { data, loading, error }] = useAddAssociationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      short: // value for 'short'
 *      prize: // value for 'prize'
 *      isStufenvereinigung: // value for 'isStufenvereinigung'
 *      needsAComment: // value for 'needsAComment'
 *      defaultComment: // value for 'defaultComment'
 *      membersCalculationAt: // value for 'membersCalculationAt'
 *      billingInformation: // value for 'billingInformation'
 *      associationURL: // value for 'associationURL'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddAssociationMutation(baseOptions?: Apollo.MutationHookOptions<AddAssociationMutation, AddAssociationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAssociationMutation, AddAssociationMutationVariables>(AddAssociationDocument, options);
      }
export type AddAssociationMutationHookResult = ReturnType<typeof useAddAssociationMutation>;
export type AddAssociationMutationResult = Apollo.MutationResult<AddAssociationMutation>;
export type AddAssociationMutationOptions = Apollo.BaseMutationOptions<AddAssociationMutation, AddAssociationMutationVariables>;
export const UpdateAssociationDocument = gql`
    mutation updateAssociation($id: ID!, $name: TranslationObjectUpdate!, $short: TranslationObjectUpdate!, $associationURL: String!, $isStufenvereinigung: Boolean, $image: Upload, $imageId: ID, $prize: Int!, $needsAComment: Boolean, $defaultComment: String, $membersCalculationAt: String, $billingInformation: BillingInformationUpdate!) {
  updateAssociation(
    id: $id
    name: $name
    short: $short
    associationURL: $associationURL
    isStufenvereinigung: $isStufenvereinigung
    image: $image
    imageId: $imageId
    prize: $prize
    needsAComment: $needsAComment
    defaultComment: $defaultComment
    membersCalculationAt: $membersCalculationAt
    billingInformation: $billingInformation
  ) {
    ...AssociationFragment
  }
}
    ${AssociationFragmentFragmentDoc}`;
export type UpdateAssociationMutationFn = Apollo.MutationFunction<UpdateAssociationMutation, UpdateAssociationMutationVariables>;

/**
 * __useUpdateAssociationMutation__
 *
 * To run a mutation, you first call `useUpdateAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssociationMutation, { data, loading, error }] = useUpdateAssociationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      short: // value for 'short'
 *      associationURL: // value for 'associationURL'
 *      isStufenvereinigung: // value for 'isStufenvereinigung'
 *      image: // value for 'image'
 *      imageId: // value for 'imageId'
 *      prize: // value for 'prize'
 *      needsAComment: // value for 'needsAComment'
 *      defaultComment: // value for 'defaultComment'
 *      membersCalculationAt: // value for 'membersCalculationAt'
 *      billingInformation: // value for 'billingInformation'
 *   },
 * });
 */
export function useUpdateAssociationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssociationMutation, UpdateAssociationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssociationMutation, UpdateAssociationMutationVariables>(UpdateAssociationDocument, options);
      }
export type UpdateAssociationMutationHookResult = ReturnType<typeof useUpdateAssociationMutation>;
export type UpdateAssociationMutationResult = Apollo.MutationResult<UpdateAssociationMutation>;
export type UpdateAssociationMutationOptions = Apollo.BaseMutationOptions<UpdateAssociationMutation, UpdateAssociationMutationVariables>;
export const UpdateAssociationIfMemberDocument = gql`
    mutation updateAssociationIfMember($id: ID!, $name: TranslationObjectUpdate!, $short: TranslationObjectUpdate!, $associationURL: String!, $isStufenvereinigung: Boolean, $image: Upload, $imageId: ID, $prize: Int!, $needsAComment: Boolean, $defaultComment: String, $membersCalculationAt: String, $billingInformation: BillingInformationUpdate!) {
  updateAssociationIfMember(
    id: $id
    name: $name
    short: $short
    associationURL: $associationURL
    isStufenvereinigung: $isStufenvereinigung
    image: $image
    imageId: $imageId
    prize: $prize
    needsAComment: $needsAComment
    defaultComment: $defaultComment
    membersCalculationAt: $membersCalculationAt
    billingInformation: $billingInformation
  ) {
    ...AssociationFragment
  }
}
    ${AssociationFragmentFragmentDoc}`;
export type UpdateAssociationIfMemberMutationFn = Apollo.MutationFunction<UpdateAssociationIfMemberMutation, UpdateAssociationIfMemberMutationVariables>;

/**
 * __useUpdateAssociationIfMemberMutation__
 *
 * To run a mutation, you first call `useUpdateAssociationIfMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssociationIfMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssociationIfMemberMutation, { data, loading, error }] = useUpdateAssociationIfMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      short: // value for 'short'
 *      associationURL: // value for 'associationURL'
 *      isStufenvereinigung: // value for 'isStufenvereinigung'
 *      image: // value for 'image'
 *      imageId: // value for 'imageId'
 *      prize: // value for 'prize'
 *      needsAComment: // value for 'needsAComment'
 *      defaultComment: // value for 'defaultComment'
 *      membersCalculationAt: // value for 'membersCalculationAt'
 *      billingInformation: // value for 'billingInformation'
 *   },
 * });
 */
export function useUpdateAssociationIfMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssociationIfMemberMutation, UpdateAssociationIfMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssociationIfMemberMutation, UpdateAssociationIfMemberMutationVariables>(UpdateAssociationIfMemberDocument, options);
      }
export type UpdateAssociationIfMemberMutationHookResult = ReturnType<typeof useUpdateAssociationIfMemberMutation>;
export type UpdateAssociationIfMemberMutationResult = Apollo.MutationResult<UpdateAssociationIfMemberMutation>;
export type UpdateAssociationIfMemberMutationOptions = Apollo.BaseMutationOptions<UpdateAssociationIfMemberMutation, UpdateAssociationIfMemberMutationVariables>;
export const DeleteAssociationDocument = gql`
    mutation deleteAssociation($id: ID!) {
  deleteAssociation(id: $id) {
    ...AssociationFragment
  }
}
    ${AssociationFragmentFragmentDoc}`;
export type DeleteAssociationMutationFn = Apollo.MutationFunction<DeleteAssociationMutation, DeleteAssociationMutationVariables>;

/**
 * __useDeleteAssociationMutation__
 *
 * To run a mutation, you first call `useDeleteAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssociationMutation, { data, loading, error }] = useDeleteAssociationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssociationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssociationMutation, DeleteAssociationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssociationMutation, DeleteAssociationMutationVariables>(DeleteAssociationDocument, options);
      }
export type DeleteAssociationMutationHookResult = ReturnType<typeof useDeleteAssociationMutation>;
export type DeleteAssociationMutationResult = Apollo.MutationResult<DeleteAssociationMutation>;
export type DeleteAssociationMutationOptions = Apollo.BaseMutationOptions<DeleteAssociationMutation, DeleteAssociationMutationVariables>;