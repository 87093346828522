import PayedFilterBusiness from './PayedFilter.business'

export default function PayedFilterData({
    payedFilter,
    setPayedFilter,
}: {
    payedFilter: string[] | null
  setPayedFilter: React.Dispatch<React.SetStateAction<string[] | null>>
}) {

  return <PayedFilterBusiness payedFilter={payedFilter} setPayedFilter={setPayedFilter}/>
}
