import { useIntl } from 'react-intl'
import { Button } from 'baseui/button'
import { ArrowUp } from 'baseui/icon'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'
import { Tag } from 'baseui/tag'
import { Notification, KIND } from 'baseui/notification'
import { Show } from 'baseui/icon'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal'
import { Textarea } from 'baseui/textarea'
import { SIZE } from 'baseui/input'
import { Select, Value } from 'baseui/select'
import { FormattedMessage } from 'react-intl'

import { MailingState } from '../../../../gql/members/types'

export default function Newsletter({
  onClick,
  send,
  isOpen,
  mailing,
  setMailing,
  errors,
  loading,
  mailingState,
  mailingId,
  newsletters,
  selectedNewsletter,
  setSelectedNewsletter,
}: {
  onClick: () => any
  send: () => any
  isOpen: boolean
  mailing: {
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
  }
  setMailing: React.Dispatch<
    React.SetStateAction<{
      fromEmail: string
      fromNameEmail: string
      toEmail: string
      subject: string
    }>
  >
  errors: {
    fromEmail: string
    fromNameEmail: string
    toEmail: string
    subject: string
  }
  loading: boolean
  mailingState: {
    sentCount?: number
    failedCount?: number
    recipientCount?: number
    state?: MailingState
  }
  mailingId: unknown
  newsletters:
    | {
        label: string
        id: string
      }[]
    | undefined
  selectedNewsletter: Value
  setSelectedNewsletter: React.Dispatch<React.SetStateAction<Value>>
}) {
  const intl = useIntl()
  return (
    <>
      <StatefulTooltip
        placement={PLACEMENT.right}
        content={intl.formatMessage({
          id: 'members.newsletter.createNewsletter',
          defaultMessage: 'Create newsletter',
        })}
        overrides={{
          Body: {
            style: () => ({
              zIndex: 1,
            }),
          },
        }}
      >
        <Button onClick={onClick}>
          <ArrowUp
            title={intl.formatMessage({
              id: 'members.newsletter.createNewsletter',
              defaultMessage: 'Create newsletter',
            })}
            size={24}
          />
          {mailingId && (mailingId as number) && <Show />}
          {mailingState.recipientCount && (
            <Tag
              closeable={false}
              overrides={{
                Root: {
                  style: () => ({
                    margin: '0!important',
                  }),
                },
              }}
            >
              {mailingState.recipientCount}
            </Tag>
          )}
        </Button>
      </StatefulTooltip>
      <Modal
        onClose={onClick}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: {
              zIndex: 1,
            },
          },
          Dialog: {
            style: {
              zIndex: 1,
              width: '80vw',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>
          <FormattedMessage
            id='members.newsletter.createNewsletter'
            defaultMessage={'Crate newsletter'}
          />
          {mailingState.state && (
            <Notification
              kind={
                mailingState.state === MailingState.Interrupted
                  ? KIND.negative
                  : mailingState.state === MailingState.Completed
                  ? KIND.positive
                  : mailingState.state === MailingState.Pending
                  ? KIND.warning
                  : KIND.info
              }
            >
              <FormattedMessage
                id={`members.newsletter.state.${mailingState.state}`}
                defaultMessage={mailingState.state}
              />
            </Notification>
          )}
        </ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <FormControl 
                label={intl.formatMessage({
                  id: 'members.newsletter.newsletter',
                  defaultMessage: 'Newsletter',
                })}>
            <Select
              options={newsletters}
              value={selectedNewsletter}
              placeholder={intl.formatMessage({
                id: 'members.newsletter.selectNewsletter',
                defaultMessage: 'Select a newsletter',
              })}
              onChange={({ value }: { value: Value }) =>
                setSelectedNewsletter(value)
              }
            />
          </FormControl>
          {selectedNewsletter.length > 0 && (
            <>
              <FormControl
                label={intl.formatMessage({
                  id: 'members.newsletter.fromMail',
                  defaultMessage: 'From',
                })}
                error={errors.fromEmail}
              >
                <Input
                  value={mailing.fromEmail}
                  onChange={(e) =>
                    setMailing((prev) => ({
                      ...prev,
                      fromEmail: (e.target as HTMLInputElement).value,
                    }))
                  }
                  positive={errors.fromEmail === ''}
                />
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'members.newsletter.fromNameMail',
                  defaultMessage: 'From name',
                })}
                error={errors.fromNameEmail}
              >
                <Input
                  value={mailing.fromNameEmail}
                  onChange={(e) =>
                    setMailing((prev) => ({
                      ...prev,
                      fromNameEmail: (e.target as HTMLInputElement).value,
                    }))
                  }
                  positive={errors.fromNameEmail === ''}
                />
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'members.newsletter.toMail',
                  defaultMessage: 'To',
                })}
                error={errors.toEmail}
              >
                <Textarea
                  size={SIZE.compact}
                  value={mailing.toEmail}
                  onChange={(e) =>
                    setMailing((prev) => ({
                      ...prev,
                      toEmail: (e.target as HTMLTextAreaElement).value,
                    }))
                  }
                  placeholder={intl.formatMessage({
                    id: 'members.newsletter.toMail',
                    defaultMessage: 'To',
                  })}
                  clearOnEscape
                  positive={errors.toEmail === ''}
                />
              </FormControl>
              <FormControl
                label={intl.formatMessage({
                  id: 'members.newsletter.subject',
                  defaultMessage: 'Subject',
                })}
                error={errors.subject}
              >
                <Input
                  value={mailing.subject}
                  onChange={(e) =>
                    setMailing((prev) => ({
                      ...prev,
                      subject: (e.target as HTMLInputElement).value,
                    }))
                  }
                  positive={errors.subject === ''}
                />
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={send}
            isLoading={loading}
            disabled={
              errors.fromEmail !== '' ||
              errors.fromNameEmail !== '' ||
              errors.subject !== '' ||
              errors.toEmail !== ''
            }
          >
            <FormattedMessage
              id={`members.newsletter.send`}
              defaultMessage={'Send to recipients {recipients}'}
              values={{
                recipients: mailing.toEmail.split(',').length,
              }}
            />
          </ModalButton>
          {mailingState.recipientCount && mailingState.recipientCount > 0 && (
            <Notification>
              <FormattedMessage
                id={`members.newsletter.sending`}
                defaultMessage={
                  'Sending (recipients: {recipients}, sent: {sent}, did already receive mailing: {double}, failed: {failed})'
                }
                values={{
                  recipients: mailingState.recipientCount,
                  sent: mailingState.sentCount,
                  double: mailingState.state === MailingState.Completed ? ((mailingState.recipientCount || 0) - ((mailingState.sentCount || 0) + (mailingState.failedCount || 0))) : 0,
                  failed: mailingState.failedCount,
                }}
              />
            </Notification>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}
