import AddressTypeEditBusiness from './AddressTypeEdit.business'
import {
    useGetAddressTypeLazyQuery,
  useUpdateAddressTypeMutation,
  useAddAddressTypeMutation,
} from '../../../gql/AddressType.generated'
import getReactApolloCients from '../../../localReactApollo'

import { useEffect, useState } from 'react'

export default function AddressTypeEditData({
  addressTypeId,
  setAddressTypelId,
}: {
  addressTypeId: string | null | undefined
  setAddressTypelId: (addressTypeId: string | undefined) => void
}) {
  const [values, setValues] = useState<{
    isInitType: boolean | undefined
    isFirm: boolean | undefined
    de: string | undefined
    fr: string | undefined
    it: string | undefined
  }>({
    isInitType: false,
    isFirm: false,
    de: undefined,
    fr: undefined,
    it: undefined,
  })
  const client = getReactApolloCients().getClient()
  const [
    getAddressTypeQuery,
    { data, loading, error: queryError },
  ] = useGetAddressTypeLazyQuery({
    //@ts-ignore
    client,
    fetchPolicy: 'network-only',
  })
  const [
    updateAddressTypeMutation,
    { loading: changeLoading, error: queryChangeError },
  ] = useUpdateAddressTypeMutation({
    //@ts-ignore
    client,
  })
  const [
    addAddressTypeMutation,
    { loading: addLoading, error: queryAddError },
  ] = useAddAddressTypeMutation({
    //@ts-ignore
    client,
  })

  async function updateAddressTypeEntry(data: {
    addressTypeId: string,
    isInitType: boolean,
    isFirm: boolean,
    de: string,
    fr: string,
    it: string,
  }) {
    if (data.addressTypeId) {
      await updateAddressTypeMutation({
        variables: {
          id: data.addressTypeId,
          isInitType: data.isInitType,
          isFirm: data.isFirm,
          name: {
              de: data.de,
              fr: data.fr,
              it: data.it
          }
        },
      })
    }
  }

  async function createAddressTypeEntry(data: {
    isInitType: boolean,
    isFirm: boolean,
    de: string,
    fr: string,
    it: string,
  }) {
    await addAddressTypeMutation({
      variables: {
        isInitType: data.isInitType,
        isFirm: data.isFirm,
        name: {
            de: data.de,
            fr: data.fr,
            it: data.it
        }
      },
    })
  }

  function removeAddressTypeId() {
    setAddressTypelId(undefined)
  }

  useEffect(() => {
    if (addressTypeId) {
        getAddressTypeQuery({
        variables: {
          id: addressTypeId,
        },
      })
    }
  }, [addressTypeId])

  useEffect(() => {
    if (data?.getAddressType) {

      setValues({
        isInitType: data.getAddressType.isInitType,
        isFirm: data.getAddressType.isFirm,
        de: data.getAddressType.name.de,
        fr: data.getAddressType.name.fr,
        it: data.getAddressType.name.it,
      })
    }
  }, [data])

  return (
    <AddressTypeEditBusiness
      values={values}
      setValues={setValues}
      isLoading={loading || changeLoading || addLoading}
      queryError={queryError || queryChangeError || queryAddError}
      removeAddressTypeId={removeAddressTypeId}
      addressTypeId={addressTypeId}
      updateAddressTypeEntry={updateAddressTypeEntry}
      createAddressTypeEntry={createAddressTypeEntry}
    />
  )
}
