import React, { useEffect } from 'react'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import { ButtonGroup } from '@lemonbrain/components/PageStyles/Settings.styled'

interface iFormInput {
  u2SAPass: string
}

export default function Nonce({
  onSubmit,
  isLoading,
  onForgotPasswortClick,
}: {
  onSubmit: ({ u2SAPass }: { u2SAPass: string }) => Promise<boolean>
  isLoading: boolean
  onForgotPasswortClick?: (
    a: React.SyntheticEvent<HTMLButtonElement>
  ) => unknown
}) {
  const {
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<iFormInput>()
  const intl = useIntl()

  useEffect(() => {
    register('u2SAPass', {
      required: true,
    })
  }, [register])

  const onSubmitCalled = async ({ u2SAPass }: iFormInput) => {
    const state = await onSubmit({ u2SAPass })
    if (!state) {
      setError('u2SAPass', {
        type: 'manual',
        message: intl.formatMessage({
          id: 'auth.login.error.wrongAuthenticator',
          defaultMessage:
            'We could not log you in. Invalid authenticator token. Try again',
        }),
      })
    }
  }

  return (
    <Container>
      <Block
        overrides={{
          Block: {
            style: {
              minHeight: 'calc(100vh - 213px)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmitCalled)} style={{ width: '100%' }}>
          <Block
            as='h1'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font1250,
                    color: '#fff',
                    fontWeight: 700,
                    marginBottom: '30px',
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='auth.login.googleAuthenticator'
              defaultMessage={'Google Authenticator'}
            />
          </Block>
          <Block marginBottom='30px'>
            <FormControl
              label={intl.formatMessage({
                id: 'auth.login.u2SAPass',
                defaultMessage: 'Token',
              })}
              error={
                errors.u2SAPass &&
                (errors.u2SAPass.message
                  ? errors.u2SAPass.message
                  : intl.formatMessage({
                      id: 'auth.login.error.u2SAPass',
                      defaultMessage: 'Please enter a Token',
                    }))
              }
            >
              <Input
                name='u2SAPass'
                type='u2SAPass'
                overrides={{
                  InputContainer: {
                    style: () => {
                      return { backgroundColor: 'transparent' }
                    },
                  },
                }}
                onChange={(event) => {
                  setValue('u2SAPass', event.currentTarget.value)
                }}
              />
            </FormControl>
          </Block>
          <ButtonGroup>
            <Button type='submit' isLoading={isLoading}>
              <FormattedMessage
                id='auth.login.signIn'
                defaultMessage={'Login'}
              />{' '}
            </Button>
            <Button
              kind='tertiary'
              type='button'
              onClick={onForgotPasswortClick}
            >
              <FormattedMessage
                id='auth.login.forgotPassword'
                defaultMessage={'Forgot password'}
              />
            </Button>
          </ButtonGroup>
        </form>
      </Block>
    </Container>
  )
}
