import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import DisplayNameBusiness from './EditLanguage.business'
import getReactApolloCients from '../../localReactApollo'
import { useGetUserByIdQuery, useUpdateUserByIdMutation } from '../../gql/Auth.generated'

export default function EditLanguageData({
  authId
}: {
  authId: string
}) {
  const [language, setLanguage] = useState<string>('de_DE')
  const clientAuth = getReactApolloCients().getClient()
  const intl = useIntl()
  const setAlert = useAlert()

  const { data: userData, loading: userLoading, error: userError } = useGetUserByIdQuery({
    variables: {
      id: authId || '',
    },
    //@ts-ignore
    client: clientAuth,
  })

  const [updateUserByIdMutation, { data: updateData, loading: updateLoading, error: updateError }] = useUpdateUserByIdMutation({
    variables: {
      id: authId || '',
      userLocale: language
    },
    //@ts-ignore
    client: clientAuth,
  });

  useEffect(() => {
    if (userData && userData.getUserById && userData.getUserById.uDefaultLanguage) {
      setLanguage(userData.getUserById.uDefaultLanguage)
    }
  }, [userData])


  useEffect(() => {
    if (updateData && updateData.updateUserById && updateData.updateUserById.result?.uDefaultLanguage) {
      setLanguage(updateData.updateUserById.result.uDefaultLanguage)
    }
  }, [updateData])

  useEffect(() => {
      updateUserByIdMutation()
  }, [language])

  useEffect(() => {
      if (userError || updateError) {
          setAlert && setAlert({
              title: intl.formatMessage({ id: 'auth.editLanguage.error', defaultMessage: 'Error' }),
              label: intl.formatMessage({ id: 'auth.editLanguage.errorMessage', defaultMessage: 'There was an error while searching auth-user. Try again' }),
              style: 'error'
          })
      }
  }, [userError, updateError])

  return (
    <DisplayNameBusiness
      language={language}
      setLanguage={setLanguage}
      isLoading={userLoading || updateLoading}
    />
  )
}
