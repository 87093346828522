import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'
import { getTranslation } from '@lemonbrain/utils'

import SvssFunctionBusiness from './SvssFunction.business'
import getReactApolloCients from '../../../../localReactApollo'
import {useGetSvssFunctionsNoPersonQuery} from '../../../../gql/members/SvssFunction.generated'

export default function SvssFunctionData({
    svssFunctions,
    setSvssFunctions,
  }: {
    svssFunctions?: { id: string }[]
    setSvssFunctions: React.Dispatch<
      React.SetStateAction<{ id: string }[]>
    >
  }) {
    const intl = useIntl()
    const setAlert = useAlert()
    const [svssFunctionsInput, setSvssFunctionsInput] = useState<
      {
        id: string
        label: string
      }[]
    >([])
    const client = getReactApolloCients().getClient()
      const { data: dataSvssFunctions, loading: loadingSvssFunctions, error: errorSvssFunctions } = useGetSvssFunctionsNoPersonQuery({
        variables: {
          cursor: {
            pageSize: 100,
          },
        },
        //@ts-ignore
        client,
     });

     useEffect(() => {
       if (errorSvssFunctions) {
         setAlert &&
           setAlert({
             title: intl.formatMessage({
               id: 'members.svssFunction.error',
               defaultMessage: 'Error',
             }),
             label: intl.formatMessage({
               id: 'members.svssFunction.networkError',
               defaultMessage: 'We had network problem. Please try again',
             }),
             style: 'error',
           })
         console.error(errorSvssFunctions?.message)
       }
     }, [errorSvssFunctions])
   
     useEffect(() => {
       if (dataSvssFunctions?.getSvssFunctionsNoPerson.items) {
        setSvssFunctionsInput(
          dataSvssFunctions?.getSvssFunctionsNoPerson.items.map((item) => ({
             id: item.id,
             label: getTranslation(item.name, intl),
           })).sort(function(a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            
            return 0;
          })
         )
       }
     }, [dataSvssFunctions])
    
      return <SvssFunctionBusiness svssFunctions={svssFunctions} setSvssFunctions={setSvssFunctions} loadingSvssFunctions={loadingSvssFunctions} svssFunctionsInput={svssFunctionsInput} />
  }