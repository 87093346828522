import { useEffect, useState } from 'react'
import { Cell, Grid } from 'baseui/layout-grid'
import { useIntl } from 'react-intl'
import { FormControl } from 'baseui/form-control'
import {Input} from 'baseui/input';

import EditContainer from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

export default function StateFunctionEdit({
  values,
  updateEntry,
  createEntry,
  updateCancel,
  isLoading,
  stateFunctionId,
  checkDe,
  checkFr,
  checkIt,
  validate,
  setValues,
}: {
  values: {
    de: string | undefined
    fr: string | undefined
    it: string | undefined
  }
  updateEntry: ({
    stateFunctionId,
    de,
    fr,
    it,
  }: {
    stateFunctionId: string
    de: string
    fr: string
    it: string
  }) => void
  createEntry: ({
    de,
    fr,
    it,
  }: {
    de: string
    fr: string
    it: string
  }) => void
  updateCancel: () => void
  isLoading: boolean
  stateFunctionId?: string | null | undefined
  checkDe: (
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>
  ) => boolean
  checkFr: (
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>
  ) => boolean
  checkIt: (
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>
  ) => boolean
  validate: (
    de: string | null | undefined,
    setDeError: React.Dispatch<React.SetStateAction<string | null>>,
    fr: string | null | undefined,
    setFrError: React.Dispatch<React.SetStateAction<string | null>>,
    it: string | null | undefined,
    setItError: React.Dispatch<React.SetStateAction<string | null>>,
  ) => boolean
  setValues: React.Dispatch<
    React.SetStateAction<{
      de: string | undefined
      fr: string | undefined
      it: string | undefined
    }>
  >
}) {
  const intl = useIntl()
  const [deError, setDeError] = useState<string | null>(null)
  const [frError, setFrError] = useState<string | null>(null)
  const [itError, setItError] = useState<string | null>(null)

  function onUpdateClick() {
    if (
      values &&
      stateFunctionId &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError,
      ) &&
      values.de &&
      values.fr &&
      values.it
    ) {
      updateEntry({
        stateFunctionId,
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  function onCopyClick() {
    if (
      values &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError,
      ) &&
      values.de &&
      values.fr &&
      values.it
    ) {
      createEntry({
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  function onCancelClick() {
    updateCancel()
  }

  function onCreateClick() {
    if (
      values &&
      validate(
        values.de,
        setDeError,
        values.fr,
        setFrError,
        values.it,
        setItError,
      ) && 
      values.de &&
      values.fr &&
      values.it
    ) {
      createEntry({
        de: values.de,
        fr: values.fr,
        it: values.it,
      })
    }
  }

  useEffect(() => {
    if (values) {
      checkDe(values.de, setDeError)
      checkFr(values.fr, setFrError)
      checkIt(values.it, setItError)
    }
  }, [values])

  return (
    <EditContainer
      state={!!stateFunctionId ? 'update' : 'create'}
      onUpdateClick={onUpdateClick}
      onCopyClick={onCopyClick}
      onCancelClick={onCancelClick}
      onCreateClick={onCreateClick}
      isLoading={isLoading}
    >
      <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateFunctionEdit.de',
              defaultMessage: 'Label (German)',
            })}
            error={deError}
          >
            <Input
              value={values && values.de || undefined}
              //@ts-ignore
              onChange={(de) => setValues((prev) => ({ ...prev, de: de.target.value }))}
              positive={deError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateFunctionEdit.fr',
              defaultMessage: 'Label (French)',
            })}
            error={frError}
          >
            <Input
              value={values && values.fr || undefined}
              //@ts-ignore
              onChange={(fr) => setValues((prev) => ({ ...prev, fr: fr.target.value }))}
              positive={frError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'settings.stateFunctionEdit.it',
              defaultMessage: 'Label (Italian)',
            })}
            error={itError}
          >
            <Input
              value={values && values.it || undefined}
              //@ts-ignore
              onChange={(it) => setValues((prev) => ({ ...prev, it: it.target.value }))}
              positive={itError === ''}
            />
          </FormControl>
        </Cell>
      </Grid>
    </EditContainer>
  )
}
