import CourseSettings from './CourseSettings'

import { InnerRoute } from '@lemonbrain/hooks'

export default function CourseSettingsBusiness({
  subNav,
  newEntry,
  refresh
}: {
  subNav: InnerRoute[]
  newEntry: () => void
  refresh: boolean
}) {
  return (
    <CourseSettings
      subNav={subNav}
      newEntry={newEntry}
      refresh={refresh}
    />
  )
}
