import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import AssociationTypeBusiness from './AssociationType.business'

export default function AssociationTypeData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { associationTypeId } = useParams<{ associationTypeId: string }>()
  const { language } = useParams<{ language: string }>()

  function setAssociationTypeId(associationTypeId: string) {
    if (associationTypeId && associationTypeId !== '') {
      pushPath(`/${language}/settings/association-type/${associationTypeId}`)
    } else {
      pushPath(`/${language}/settings/association-type`)
    }
  }

  return (
    <AssociationTypeBusiness
      subNav={subNav}
      associationTypeId={associationTypeId}
      setAssociationTypeId={setAssociationTypeId}
    />
  )
}
