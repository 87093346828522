import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import SportsEducationTable, { RowDataT } from './SportsEducationTable'

import { useAlert } from '@lemonbrain/hooks'

export default function SportsEducationTableBusiness({
  isLoading,
  sportsEducations,
  fetchMoreSportsEducationEntries,
  refetchSportsEducationEntries,
  goToSportsEducationEntry,
  removeSportsEducationEntry,
  queryError,
}: {
  isLoading: boolean
  sportsEducations: {
    id: string
    data: RowDataT
  }[]
  fetchMoreSportsEducationEntries: () => void
  refetchSportsEducationEntries: () => void
  goToSportsEducationEntry: (id: string) => void
  removeSportsEducationEntry: (id: string) => void
  queryError: any
}) {
  const setAlert = useAlert()
  const intl = useIntl()
  const [currentIsLoading, setCurrentIsLoading] = useState(isLoading)

  async function removeRow(id: string) {
    setCurrentIsLoading(true)
    try {
      await removeSportsEducationEntry(id)
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.sportsEducationTable.removeRow.success',
            defaultMessage: 'Entry removed',
          }),
          style: 'success',
        })
        refetchSportsEducationEntries()
    } catch (e) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.sportsEducationTable.removeRow.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.sportsEducationTable.removeRow.error.notRemoved',
            defaultMessage:
              'We could not remove the entry. Please try again',
          }),
          style: 'error',
        })
    }
    setCurrentIsLoading(false)
  }

  useEffect(() => {
    if (queryError) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'settings.sportsEducationTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'settings.sportsEducationTable.errorMessage',
            defaultMessage:
              'There was an error while searching for entries. Try again',
          }),
          style: 'error',
        })
    }
  }, [queryError])

  useEffect(() => {
    setCurrentIsLoading(isLoading)
  }, [isLoading])

  return (<SportsEducationTable
      isLoading={currentIsLoading}
      rows={sportsEducations}
      fetchMoreListItems={fetchMoreSportsEducationEntries}
      editRow={goToSportsEducationEntry}
      removeRow={removeRow}
    />
  )
}
