import {
  UpdateNewsletterMailingItemMutationVariables,
  UpdateNewsletterMailingMutationVariables,
} from '../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../types'
import NewslettersItems from './NewsletterItems'

export default function NewslettersItemsBusiness({
  newsletterMailing,
  setNewsletterMailing,
}: {
  newsletterMailing: UpdateNewsletterMailingMutationVariables & {
    toJoined: string
    mainImageSrc: string
    items: (UpdateNewsletterMailingItemMutationVariables & {
      imageSrc: string
      task: Types.ArrayUpdate
    })[]
  }
  setNewsletterMailing: React.Dispatch<
    React.SetStateAction<
      UpdateNewsletterMailingMutationVariables & {
        toJoined: string
        mainImageSrc: string
        items: (UpdateNewsletterMailingItemMutationVariables & {
          imageSrc: string
          task: Types.ArrayUpdate
        })[]
      }
    >
  >
}) {
  function addItem() {
    setNewsletterMailing((prev) => {
      let cpyItem = { ...prev }

      //@ts-ignore
      cpyItem.items.push({
        //@ts-ignore
        id: (Math.max.apply(Math, cpyItem.items.map(item => parseInt(item?.id || '0'))) + 1).toString(),
        task: Types.ArrayUpdate.Add,
        orderNr: Math.max.apply(Math, cpyItem.items.map(item => item?.orderNr || 0)) + 1
      })
      return cpyItem
    })
  }

  function deleteItem(id: string) {
    setNewsletterMailing((prev) => {
      let cpyItem = { ...prev }
      cpyItem.items = cpyItem.items?.map(item => (item?.id === id ? {
        ...item,
        task: Types.ArrayUpdate.Remove
      } : item)) as (UpdateNewsletterMailingItemMutationVariables & {
        imageSrc: string
        task: Types.ArrayUpdate
      })[]
      return cpyItem
    })

  }

  return (
    <NewslettersItems
      addItem={addItem}
      deleteItem={deleteItem}
      newsletterMailing={newsletterMailing}
      setNewsletterMailing={setNewsletterMailing}
    />
  )
}
