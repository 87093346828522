import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'

import StateEditData from './StateEdit/StateEdit.data'
import StateTableData from './StateTable/StateTable.data'


export default function State({ 
  subNav,
  stateId,
  setStateId,
  listStateTypes,
}: { 
  subNav: InnerRoute[]
  stateId: string | null | undefined
  setStateId: (stateId: string | undefined) => void
  listStateTypes: (stateId: string) => void
 }) {
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={15} gridMargins={0}>
          <Cell span={[12, 12, 3]}>
            <SideMenu menuItems={subNav} />
          </Cell>
          <Cell span={[12, 12, 9]}>
            <Block>
              <Block>
                <Block
                  paddingTop={['15px', '15px', '15px', 0]}
                  overrides={{
                    Block: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                      },
                    },
                  }}
                >
                  <Block>
                    <Block
                      as='h3'
                      overrides={{
                        Block: {
                          style: ({ $theme }) => {
                            return {
                              ...$theme.typography.font450,
                              color: $theme.colors.primaryA,
                              marginBottom: '15px',
                            }
                          },
                        },
                      }}
                    >
                      <FormattedMessage
                        id='settings.state'
                        defaultMessage={'State'}
                      />
                    </Block>
                  </Block>
                </Block>
              </Block>
            <StateEditData
              stateId={stateId}
              setStateId={setStateId}
              />
              <Block
                overrides={{
                  Block: {
                    style: () => {
                      return {
                        marginTop: '15px',
                        marginBottom: '15px',
                      }
                    },
                  },
                }}
              >
                <hr />
              </Block>
              {!stateId && (
                <StateTableData 
                setStateId={setStateId}
                listStateTypes={listStateTypes}
                 />
              )}
              </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
