import InvoicesData from './views/Invoices/Invoices.data'
import InvoicesOverviewData from './views/InvoicesOverview/InvoicesOverview.data'
import InvoicesAddData from './views/InvoicesAdd/InvoicesAdd.data'
import InvoicesMyData from './views/InvoicesMy/InvoicesMy.data'
import CreateOneAssociationInvoicesData from './views/InvoicesOverview/InvoicesFunctions/CreateOneAssociationInvoice/CreateOneAssociationInvoices.data'

import germanMessages from './messages/de'
import getReactApolloCients from './localReactApollo'

import {
  addMenuRoutesBeforeRender,
  addGermanMessagesBeforeRender,
  addWsClientBeforeRender,
  addComponentsBeforeRender
} from '@lemonbrain/hooks'

addMenuRoutesBeforeRender('MAIN', [
  {
    title: {
      id: 'invoices.invoices',
      defaultMessage: 'Invoices',
    },
    icon: 'Invoices',
    id: 'invoices',
    path: '/invoices/',
    routePath: '/invoices',
    component: InvoicesData,
    size: 'small',
    tabIndex: 10,
    visible: true,
    active: true,
    enabled: true,
    accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
    children: [
      {
        title: {
          id: 'invoices.overview',
          defaultMessage: 'Overview',
        },
        icon: 'invoices',
        id: 'invoicesOverview',
        path: '/invoices/overview/:invoiceId?',
        routePath: '/invoices/overview',
        component: InvoicesOverviewData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
      },
      {
        title: {
          id: 'invoices.add',
          defaultMessage: 'Add',
        },
        icon: 'invoices',
        id: 'invoicesAdd',
        path: '/invoices/add',
        routePath: '/invoices/add',
        component: InvoicesAddData,
        size: 'small',
        tabIndex: 12,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || ''],
      },
      {
        title: {
          id: 'invoices.my',
          defaultMessage: 'My invoices',
        },
        icon: 'invoices',
        id: 'invoicesMy',
        path: '/invoices/my/:invoiceId?/:paymentState?',
        routePath: '/invoices/my',
        component: InvoicesMyData,
        size: 'small',
        tabIndex: 11,
        visible: true,
        active: false,
        enabled: true,
        accessibleBy: [process.env.REACT_APP_GROUP_MANAGERS || '', process.env.REACT_APP_GROUP_ASSOCIATION_ADMIN || '', process.env.REACT_APP_GROUP_ASSOCIATION_CASHIER || '', process.env.REACT_APP_GROUP_ASSOCIATION_PRESIDENT || ''],
      },
    ],
  },
])

const wsClient = getReactApolloCients().getWsClient()
if (wsClient) {
  addWsClientBeforeRender(wsClient)
}

addGermanMessagesBeforeRender(germanMessages)

let components = {
  InvoicesOverviewData,
  CreateOneAssociationInvoicesData
}

addComponentsBeforeRender(components)
