import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import NewslettersOverviewBusiness from './NewslettersOverview.business'

export default function NewslettersOverviewData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { language } = useParams<{ language: string }>()
  const { newsletterId } = useParams<{ newsletterId: string | undefined }>()

  const goToNewsletter = function (id: string | null) {
    if (id && id !== '') {
      pushPath(`/${language}/newsletters/overview/${id}`)
    } else {
      pushPath(`/${language}/newsletters/overview`)
    }
  }

  return <NewslettersOverviewBusiness subNav={subNav} goToNewsletter={goToNewsletter} newsletterId={newsletterId} />
}
