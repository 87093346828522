import StateFunction from './StateFunction'

import { InnerRoute } from '@lemonbrain/hooks'

export default function StateFunctionBusiness({
  subNav,
  stateFunctionId,
  setStateFunctionId,
}: {
  subNav: InnerRoute[]
  stateFunctionId: string | null | undefined
  setStateFunctionId: (stateFunctionId: string | undefined) => void
}) {
  return (
    <StateFunction
      subNav={subNav}
      stateFunctionId={stateFunctionId}
      setStateFunctionId={setStateFunctionId}
    />
  )
}
