import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useAlert } from '@lemonbrain/hooks'

import AddCourseRegistrationBusiness from './AddCourseRegistration.business'
import { CourseFragmentFragment, useRegisterForCourseMutation } from '../../../../gql/Courses.generated'
import { SignUpState } from '../../../../types'
import getReactApolloCients from '../../../../localReactApollo'

export default function AddCourseRegistrationData({
  course,
}: {
  course: CourseFragmentFragment | undefined
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [member, setMember] = useState<{ id: string, label?: string }>()

  const client = getReactApolloCients().getClient()

  const [
    registerForCourseMutation,
    { loading, error },
  ] = useRegisterForCourseMutation({
    //@ts-ignore
    client,
  })

  const add = async (signUpState: SignUpState) => {
    if (member && course) {
      await registerForCourseMutation({
        variables: {
          courseId: course!.id,
          participantId: member.id,
          signUpStatus: signUpState,
        },
      })
      setMember(undefined)
    }
  }

  useEffect(() => {
    if (error) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.addCourseRegister.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.addCourseRegister.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        error?.message
      )
    }
  }, [error])

  return <AddCourseRegistrationBusiness
    member={member}
    setMember={setMember}
    add={add}
    loading={loading}
  />
}
