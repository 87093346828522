import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { verifyEmail, verifyDate, required, verifyAhv } from '@lemonbrain/utils'

import Personal from './Personal'

export default function PersonalBusiness({
  getHasPersonalErrors,
  personal,
  setPersonal,
}: {
  getHasPersonalErrors: (
    errors: {
      email: string
      birthdate: string
    }) => void
  personal:
    | {
        id: string
        email: string
        birthdate: string
        telephone: string
        mobile: string
        ahvNumber: string
        jsNumber: string
        slrgNumber: string
      }
    | undefined
  setPersonal: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          email: string
          birthdate: string
          telephone: string
          mobile: string
          ahvNumber: string
          jsNumber: string
          slrgNumber: string
        }
      | undefined
    >
  >
}) {
  const [errors, setErrors] = useState<{
    email: string,
    birthdate: string
    ahvNumber: string
  }>({
    email: '',
    birthdate: '',
    ahvNumber: '',
  })
  const intl = useIntl()

  function checkEmail(email: string | null | undefined) {
    if (email && verifyEmail(email)) {
      setErrors((prev) => ({ ...prev, email: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      email: intl.formatMessage({
        id: 'members.personal.email.isRequired',
        defaultMessage: 'E-Mail needs to be in the right format and is required',
      }),
    }))
    return true
  }

  function checkBirthdate(birthdate: string | null | undefined) {
    if (birthdate && verifyDate(birthdate)) {
      setErrors((prev) => ({ ...prev, birthdate: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      birthdate: intl.formatMessage({
        id: 'members.personal.birthdate.isRequired',
        defaultMessage: 'Birthdate needs to be in the right format and is required',
      }),
    }))
    return true
  }

  function checkAhvNumber(ahvNumber: string | null | undefined) {
    if (ahvNumber && required(ahvNumber) && verifyAhv(ahvNumber)) {
      setErrors((prev) => ({ ...prev, ahvNumber: '' }))
      return false
    }
    setErrors((prev) => ({
      ...prev,
      ahvNumber: intl.formatMessage({
        id: 'members.personal.ahvNumber.isRequired',
        defaultMessage: 'AHV number needs to be in the right format and is required',
      }),
    }))
    return true
  }

  useEffect(() => {
    checkEmail(personal?.email)
    checkBirthdate(personal?.birthdate)
    checkAhvNumber(personal?.ahvNumber)
  }, [personal])

  useEffect(() => {
    getHasPersonalErrors(errors)
  }, [errors])

  return <Personal values={personal} setValues={setPersonal} errors={errors} />
}
