import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormattedMessage } from 'react-intl'

import Container from '@lemonbrain/components/UiElements/Container/Container'
import SideMenu from '@lemonbrain/components/SideMenu/SideMenu'
import { InnerRoute } from '@lemonbrain/hooks'

import CoursesEditData from './CoursesEdit/CoursesEdit.data'
import CoursesTableData from './CoursesTable/CoursesTable.data'

export default function CoursesOverview({
  subNav,
  goToCourse,
  courseId,
  sendMailing,
  personId,
}: {
  subNav?: InnerRoute[]
  goToCourse: (id: string | null) => void
  courseId: string | undefined
  sendMailing: (id: string) => void
  personId?: string
}) {
  return (
    <Container>
      <Block paddingTop={['0', '0', '0', '40px']}>
        <Grid gridColumns={12} gridGutters={0} gridMargins={0}>
          {subNav && (
            <Cell span={[12, 12, 3]}>
              <SideMenu menuItems={subNav} />
            </Cell>
          )}
          <Cell span={[12, 12, (subNav ? 9 : 12)]}>
            {!personId && (
              <Block>
                <Block>
                  <Block
                    paddingTop={['15px', '15px', '15px', 0]}
                    overrides={{
                      Block: {
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '30px',
                        },
                      },
                    }}
                  >
                    <Block>
                      <Block
                        as='h3'
                        overrides={{
                          Block: {
                            style: ({ $theme }) => {
                              return {
                                ...$theme.typography.font450,
                                color: $theme.colors.primaryA,
                                marginBottom: '15px',
                              }
                            },
                          },
                        }}
                      >
                        <FormattedMessage
                          id='courses.overview'
                          defaultMessage={'Overview'}
                        />
                      </Block>
                    </Block>
                  </Block>
                </Block>
              </Block>
            )}
            <Block>
              {courseId && parseInt(courseId) > 0 ? (
                <CoursesEditData goToCourse={goToCourse} courseId={courseId} />
              ) : (
                <>
                  <CoursesTableData goToCourse={goToCourse} sendMailing={sendMailing} personId={personId} />
                </>
              )}
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Container>
  )
}
