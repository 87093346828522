import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'

import getReactApolloCients from '../../../../localReactApollo'
import { useCreateDirectMemberInvoiceMutation } from '../../../../gql/invoices/Invoices.generated'
import CreateDirectMemberInvoicesBusiness from './CreateDirectMemberInvoices.business'

export default function CreateDirectMemberInvoicesData({
  invoicesTableDataRef,
}: {
  invoicesTableDataRef: React.MutableRefObject<
    | {
        refetchInvoices: () => void
      }
    | undefined
  >
}) {
  const [directMemberProcessId, setDirectMemberProcessId] = useLocalStorage<string | null>('directMemberProcessId')
  const intl = useIntl()
  const setAlert = useAlert()
  const [progress, setProgress] = useState<number | null>(null)

  const client = getReactApolloCients().getClient()

  const [
    createDirectMemberInvoiceMutation,
    { data, loading, error },
  ] = useCreateDirectMemberInvoiceMutation({
    //@ts-ignore
    client,
  })

  useEffect(() => {
    if (directMemberProcessId && directMemberProcessId !== '') {
      createDirectMemberInvoiceMutation({
        variables: {
          processId: directMemberProcessId,
        },
      })
    } 
  }, [])

  useEffect(() => {
    if (error) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'invoices.createDirectMemberInvoices.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'invoices.createDirectMemberInvoices.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(error?.message)
    }
  }, [error])

  useEffect(() => {
    switch (data && data.createDirectMemberInvoice.returnCode) {
      case 200:
        if (data?.createDirectMemberInvoice.progress && data?.createDirectMemberInvoice.progress === 100) {
          setProgress(null)
          setDirectMemberProcessId(null)

          setAlert &&
            setAlert({
              title: intl.formatMessage({
                id: 'invoices.createDirectMemberInvoices.success',
                defaultMessage: 'Success',
              }),
              label: intl.formatMessage({
                id: 'invoices.createDirectMemberInvoices.invoicesCreated',
                defaultMessage: 'Invoices created',
              }),
              style: 'success',
            })
          invoicesTableDataRef?.current?.refetchInvoices()
        } else {
          setDirectMemberProcessId(data?.createDirectMemberInvoice.processId || '')
          setProgress(data?.createDirectMemberInvoice.progress || 0)
          setTimeout(() => {
            createDirectMemberInvoiceMutation({
              variables: {
                processId: data?.createDirectMemberInvoice.processId,
              },
            })
          }, 1000)
        }
        break
      case 404:
        setAlert &&
          setAlert({
            title: intl.formatMessage({
              id: 'invoices.createDirectMemberInvoices.error',
              defaultMessage: 'Error',
            }),
            label: intl.formatMessage(
              {
                id: 'invoices.createDirectMemberInvoices.networkErrorMessage',
                defaultMessage:
                  'We had network problem: {message}. Please try again',
              },
              {
                message: data?.createDirectMemberInvoice.errorMessage,
              }
            ),
            style: 'error',
          })
        console.error(data?.createDirectMemberInvoice.errorMessage)
        break
    }
  }, [data])

  return (
    <CreateDirectMemberInvoicesBusiness
      loading={loading}
      createDirectMemberInvoices={createDirectMemberInvoiceMutation}
      progress={progress}
    />
  )
}
