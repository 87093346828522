import NewsletterSend from './NewsletterSend'
import { NewsletterMailingFragmentFragment } from '../../../../gql/NewsletterMailing.generated'
import * as Types from '../../../../types'

export default function NewsletterSendBusiness({
  isOpen,
  setIsOpen,
  loading,
  sendMailing,
  newsletterMailing,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  sendMailing(): void
  newsletterMailing?: Pick<Types.NewsletterMailing, 'htmlContent' | 'sendProcessId'> &
    NewsletterMailingFragmentFragment
}) {
  function onClick() {
    setIsOpen((prev) => !prev)
  }

  function send() {
    sendMailing()
  }

  return (
    <NewsletterSend
      loading={loading}
      onClick={onClick}
      send={send}
      isOpen={isOpen}
      newsletterMailing={newsletterMailing}
    />
  )
}
