import Export from './Export'

export default function ExportBusiness({
  loading,
  startExport,
  selectedInvoices,
}: {
  loading: boolean
  startExport: () => void
  selectedInvoices: string[]
}) {
  return (
    <Export
      loading={loading}
      onClick={startExport}
      selectedInvoices={selectedInvoices}
    />
  )
}
