import { useParams } from 'react-router-dom'

import {useHistoryPush} from '@lemonbrain/hooks'
import { InnerRoute } from '@lemonbrain/hooks'

import MembershipBusiness from './Membership.business'

export default function MembershipData({ subNav }: { subNav: InnerRoute[] }) {
  const pushPath = useHistoryPush()
  const { membershipId } = useParams<{ membershipId: string }>()
  const { language } = useParams<{ language: string }>()

  function setMembershipId(membershipId: string) {
    if (membershipId && membershipId !== '') {
      pushPath(`/${language}/settings/membership/${membershipId}`)
    } else {
      pushPath(`/${language}/settings/membership`)
    }
  }

  return (
    <MembershipBusiness
      subNav={subNav}
      membershipId={membershipId}
      setMembershipId={setMembershipId}
    />
  )
}