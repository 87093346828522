import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'
import { Tabs, Tab, FILL } from 'baseui/tabs-motion'
import { Notification, KIND } from 'baseui/notification'
import { ButtonGroup } from 'baseui/button-group'

import EditContainer, { STATE } from '@lemonbrain/components/UiElements/EditContainer/EditContainer'
import { useAppState } from '@lemonbrain/contexts/PageWrapper'

import AssociationBusiness from './Association/Association.business'
import BillingInformationsBusiness from './BillingInformations/BillingInformations.business'
import { UpdateAssociationIfMemberMutationVariables } from '../../../gql/Association.generated'

export default function MembersEdit({
  goToAssociation,
  isLoading,
  onUpdateClick,
  onCancelClick,
  association,
  setAssociation,
  getHasAssociationErrors,
  getHasBillingInformaitinosErrors,
  errors,
  associationId,
}: {
  goToAssociation: (id: string | null) => void
  isLoading: boolean
  onUpdateClick: () => void
  onCancelClick: () => void
  association: UpdateAssociationIfMemberMutationVariables & {
    prizeString: string
    imageSrc: string
  }
  setAssociation: React.Dispatch<
    React.SetStateAction<
      UpdateAssociationIfMemberMutationVariables & { prizeString: string, imageSrc: string }
    >
  >
  getHasAssociationErrors: (errors: {
    deName: string
    frName: string
    itName: string
    deShort: string
    frShort: string
    itShort: string
    prize: string
    associationURL: string
  }) => void
  getHasBillingInformaitinosErrors: (errors: {
    firmname: string
    streetNr: string
    postCode: string
    place: string
    canton: string
    account: string
    iban: string
    qrIban: string
    prize: string
    associationURL: string
    // membersCalculationAt: string
  }) => void
  errors: string[]
  associationId?: string
}) {
  const intl = useIntl()
  const [activeKey, setActiveKey] = useState<React.Key>(0)
  const { components } = useAppState()
  const CreateOneAssociationInvoicesData = components && components['CreateOneAssociationInvoicesData']

  return (
    <>
      <Block paddingBottom={['15px', '15px', '30px', '30px']}>
        <ButtonGroup>
          <Button
            onClick={() => goToAssociation(null)}
            startEnhancer={() => <ArrowLeft size={24} />}
          >
            <FormattedMessage
              id='associations.associationEdit.toOverview'
              defaultMessage={'To the overview'}
            />
          </Button>
          {CreateOneAssociationInvoicesData && (
            //@ts-ignores
            <CreateOneAssociationInvoicesData associationId={associationId ?? null} />
          )}
        </ButtonGroup>
      </Block>
      <EditContainer
        state={associationId ? STATE.UPDATE : STATE.CREATE}
        onUpdateClick={onUpdateClick}
        onCreateClick={onUpdateClick}
        onCancelClick={onCancelClick}
        isLoading={isLoading}
      >
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => setActiveKey(activeKey)}
          fill={FILL.fixed}
          renderAll
        >
          <Tab
            title={intl.formatMessage({
              id: 'associations.associationEdit.association',
              defaultMessage: '1/2 Association',
            })}
          >
            <AssociationBusiness
              association={association}
              setAssociation={setAssociation}
              getHasAssociationErrors={getHasAssociationErrors}
              isLoading={isLoading}
            />
          </Tab>
          <Tab
            title={intl.formatMessage({
              id: 'associations.associationEdit.billingInformations',
              defaultMessage: '2/2 Billing informations',
            })}
          >
            <BillingInformationsBusiness
              association={association}
              setAssociation={setAssociation}
              getHasBillingInformaitinosErrors={getHasBillingInformaitinosErrors}
            />
          </Tab>
        </Tabs>
      </EditContainer>
      {errors.length > 0 && (
        <Notification kind={KIND.negative}>
          <ul>
            {errors.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Notification>
      )}
    </>
  )
}
