const LocaleData = {
  'associations.associations': 'Vereinigungen',

  'associations.overview': 'Übersicht',
  'associations.add': 'Hinzufügen',

  'associations.associations_overview': 'Übersicht',

  'associations.associationsTable.fetchingMore':
    'Es werden mehr Rapporteinträge geladen...',
  'associations.associationsTable.noEntries':
    'Es sind keine Einträge vorhanden',
  'associations.associationsTable.createdFrom': 'Erstellt von',
  'associations.associationsTable.name.de': 'Bezeichnung (Deutsch)',
  'associations.associationsTable.name.fr': 'Bezeichnung (Französisch)',
  'associations.associationsTable.name.it': 'Bezeichnung (Italienisch)',
  'associations.associationsTable.short.de': 'Abkürzung (Deutsch)',
  'associations.associationsTable.short.fr': 'Abkürzung (Französisch)',
  'associations.associationsTable.short.it': 'Abkürzung (Italienisch)',
  'associations.associationsTable.price': 'Preis',
  'associations.associationsTable.needsAComment': 'Kommentar verlangt',
  'associations.associationsTable.actions': 'Funktionen',
  'associations.associationsTable.editOrDeleteAnAssociation':
    'Bearbeite oder lösche eine Verband/Verein',
  'associations.associationsTable.associations': 'Mitglieder Vereinigungen',
  'associations.associationsTable.delete': 'Löschen',
  'associations.associationsTable.deleteConfirm':
    'Sicher, dass dieser Eintrag gelöscht werden soll? Dies könnte bei verwendeten Mitglieder zu Problemen führen',
  'associations.associationsTable.error': 'Fehler',
  'associations.associationsTable.errorMessage':
    'Bei der Suche nach Einträgen ist ein Fehleraufgetreten, versuche es noch einmal',
  'associations.associationsTable.removeRow.error': 'Fehler',
  'associations.associationsTable.removeRow.error.notRemoved':
    'Eintrag wurde nicht gelöscht, versuche es noch einmal',
  'associations.associationsTable.removeRow.success': 'Eintrag wurde gelöscht',
  'associations.associationsTable.loading': 'Laden...',
  'associations.associationsTable.results_count':
    'Zeigt {pageLength} Resultate',
  'associations.associationsTable.load_mores': 'Mehr laden',
  'associations.associationsTable.page_count': 'Seite {currentPage}',
  'associations.associationsTable.page_size_selection':
    'Zeige {pageSize} Elemente pro Seite',
  'associations.associationsTable.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',

    'associations.associationEdit.toOverview': 'Zur Übersicht',
    'associations.associationEdit.de.name': 'Bezeichnung (Deutsch)',
    'associations.associationEdit.fr.name': 'Bezeichnung (Französisch)',
    'associations.associationEdit.it.name': 'Bezeichnung (Italienisch)',
    'associations.associationEdit.de.short': 'Abkürzung (Deutsch)',
    'associations.associationEdit.fr.short': 'Abkürzung (Französisch)',
    'associations.associationEdit.it.short': 'Abkürzung (Italienisch)',
    'associations.associationEdit.associationURL': 'Website',
    'associations.associationEdit.isStufenvereinigung': 'Stufenvereinigung (keine Kantonale)',
    'associations.associationEdit.dropFilesHere': 'Füge die Datei hier hinzu für den Upload',
    'associations.associationEdit.prize': 'Prize',
    'associations.associationEdit.needsAComment': 'Kommentar wird bei der Anmeldung verlangt',
    'associations.associationEdit.defaultComment': 'Kommentar Vorlage',
    'associations.associationEdit.membersCalculationAt': 'Mitgliederabrechnungen werden erstellt am',
    'associations.associationEdit.error': 'Fehler',
    'associations.associationEdit.errorMessage': 'Es ist ein Netzwerkproblem auf getreten, versuche es noch einmal',
    'associations.associationEdit.error.deRequired': 'Bereichnung (Deutsch) ist verlangt',
    'associations.associationEdit.error.frRequired': 'Bereichnung (Französisch) ist verlangt',
    'associations.associationEdit.error.itRequired': 'Bereichnung (Italienisch) ist verlangt',
    'associations.associationEdit.error.deShortRequired': 'Abkürzung (Deutsch) ist verlangt',
    'associations.associationEdit.error.frShortRequired': 'Abkürzung (Französisch) ist verlangt',
    'associations.associationEdit.error.itShortRequired': 'Abkürzung (Italienisch) ist verlangt',
    'associations.associationEdit.error.associationURLRequired': 'Website (Link) ist verlangt',
    'associations.associationEdit.error.prizeRequired': 'Prize ist verlangt und muss eine Dezimalzahl sein',
    'associations.associationEdit.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'associations.associationEdit.error.thereWasAServerError': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'associations.associationEdit.saved': 'Gespeichert',
    'associations.associationEdit.saved.updated': 'Die Vereiningung wurde aktualisiert',
    'associations.associationEdit.saved.created': 'Die Vereiningung wurde erstellt',
    'associations.associationEdit.association': '1/2 Vereiningung',
    'associations.associationEdit.billingInformations': '2/2 Rechnungsinformationen',

    'associationsAdd.associations_add': 'Vereinigung hinzufügen',

    'associations.billingInformations.prize': 'Prize',
    'associations.billingInformations.error.prizeRequired': 'Prize ist verlangt und muss eine Dezimalzahl sein',
    'associations.billingInformations.membersCalculationAt': 'Mitgliederabrechnungen werden erstellt am',
    'associations.billingInformations.error.membersCalculationAt': 'Das Datum ist verlangt und muss im korrekten Format sein',

    'associations.billingInformations': 'Rechnungsinformationen',
    'associations.billingInformations.firstName': 'Vorname',
    'associations.billingInformations.lastName': 'Nachname',
    'associations.billingInformations.firmName': 'Firmenname',
    'associations.billingInformations.saved': 'Gespeichert',
    'associations.billingInformations.saved.updated': 'Der Eintrag wurde aktualisiert',
    'associations.billingInformations.error': 'Fehler',
    'associations.billingInformations.error.thereWasAnServerError' : 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'associations.billingInformations.error.notLoggedIn': 'Nicht mehr angemeldet, der Eintrag wurde nicht gespeichert',
    'associations.billingInformations.saved.created': 'Der Eintrag wurde erstellt',
    'associations.billingInformations.error.firmnameRequired': 'Firmenname ist verlangt',
    'associations.billingInformations.errorMessage': 'Es ist ein Server Fehler aufgetreten, versuche es noch einmal',
    'associations.billingInformations.mailbox': 'Addresszusatz',
    'associations.billingInformations.streetNr': 'Strasse und Hausnummer',
    'associations.billingInformations.postCode': 'PLZ',
    'associations.billingInformations.place': 'Ort',
    'associations.billingInformations.canton': 'Kanton',
    'associations.billingInformations.account': 'Postcheck-Konto',
    'associations.billingInformations.iban': 'IBAN',
    'associations.billingInformations.qrIban': 'QR-IBAN',
    'associations.billingInformations.besrId': 'BESR-ID',
    'associations.billingInformations.error.accountRequired': 'Postcheck-Konto ist verlangt und muss im korrekten Format sein',
    'associations.billingInformations.error.ibanRequired': 'IBAN ist verlangt und muss im korrekten Format sein',
    'associations.billingInformations.error.ibanQrRequired': 'QR IBAN muss im korrekten Format sein',
    'associations.billingInformations.error.streetNrRequired': 'Strasse und Hausnummer ist verlangt',
    'associations.billingInformations.error.postCodeRequired': 'PLZ ist verlangt',
    'associations.billingInformations.error.placeRequired': 'Ort ist verlangt',
    'associations.billingInformations.error.cantonRequired': 'Kanton ist verlangt',
}

export default LocaleData
