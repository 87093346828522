import { FormattedMessage, useIntl } from 'react-intl'
import { Block } from 'baseui/block'
import { Input } from 'baseui/input'
import { Cell } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Datepicker } from 'baseui/datepicker'
import de from 'date-fns/locale/de'

import EditContainer, {
  STATE,
} from '@lemonbrain/components/UiElements/EditContainer/EditContainer'

import {
  GetTransactionQuery
} from '../../../gql/transaction/Transaction.generated'

export default function InvoiceEdit({
  isLoading,
  onUpdateClick,
  onCancelClick,
  invoice,
  setInvoice,
  prizeError,
  dateError,
  totalError,
  invoiceId,
  dataTransaction,
}: {
  isLoading: boolean
  onUpdateClick: () => void
  onCancelClick: () => void
  invoice:
  | {
    id: string
    payAmount: string
    payDate: string
    total: string
    sendAt: string
  }
  | undefined
  setInvoice: React.Dispatch<
    React.SetStateAction<{
      id: string
      payAmount: string
      payDate: string
      total: string
      sendAt: string
    }>
  >
  prizeError: string
  dateError: string
  totalError: string
  invoiceId: string
  dataTransaction?: GetTransactionQuery
}) {
  const intl = useIntl()

  return (
    <>
      <EditContainer
        state={invoiceId ? STATE.UPDATE : STATE.CREATE}
        noCreate={true}
        noCopy={true}
        onUpdateClick={onUpdateClick}
        onCancelClick={onCancelClick}
        isLoading={isLoading}
      >
        <Cell span={[12, 6, 4]}>
          <Block
            as='h3'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font450,
                    color: $theme.colors.primaryA,
                    marginBottom: '15px',
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='invoices.invoiceEdit.invoiceAmount'
              defaultMessage={'Invoice amount'}
            />
          </Block>
          {(invoice?.sendAt && <Block
            as='p'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font300,
                    color: $theme.colors.primaryA,
                    marginBottom: '15px',
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='invoices.invoiceEdit.alreadySent'
              defaultMessage={'Invoice already sent'}
            />
          </Block>)}
          {(!(invoice?.sendAt) && <FormControl
            label={intl.formatMessage({
              id: 'invoices.invoiceEdit.total',
              defaultMessage: 'total',
            })}
            error={totalError}
          >
            <Input
              value={(invoice && invoice!.total) || ''}
              onChange={(total) =>
                setInvoice((prev) => ({
                  ...prev,
                  total: (total.target as HTMLInputElement).value,
                }))
              }
              positive={totalError === ''}
            />
          </FormControl>)}

          <Block
            as='h3'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font450,
                    color: $theme.colors.primaryA,
                    marginBottom: '15px',
                    marginTop: '45px',
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='invoices.invoiceEdit.payment'
              defaultMessage={'Payment'}
            />
          </Block>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.invoiceEdit.payedAt',
              defaultMessage: 'Invoice payed at',
            })}
            error={dateError}
          >
            <Datepicker
              locale={de}
              onChange={({ date }) =>
                setInvoice((prev) => ({
                  ...prev,
                  //@ts-ignore
                  payDate: date ? date.toISOString() : undefined,
                }))
              }
              value={
                invoice && invoice!.payDate
                  ? new Date(invoice!.payDate)
                  : undefined
              }
              formatString='dd.MM.yyyy'
              placeholder='dd.MM.yyyy'
              mask={null}
              positive={dateError === ''}
            />
          </FormControl>
        </Cell>
        <Cell span={[12, 6, 4]}>
          <FormControl
            label={intl.formatMessage({
              id: 'invoices.invoiceEdit.prizePayed',
              defaultMessage: 'Prize payed',
            })}
            error={prizeError}
          >
            <Input
              value={(invoice && invoice!.payAmount) || ''}
              onChange={(prize) =>
                setInvoice((prev) => ({
                  ...prev,
                  payAmount: (prize.target as HTMLInputElement).value,
                }))
              }
              positive={prizeError === ''}
            />
          </FormControl>
        </Cell>
        {dataTransaction?.getTransaction && (<div>
          <Block
            as='h3'
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font450,
                    color: $theme.colors.primaryA,
                    marginBottom: '15px',
                    marginTop: '45px',
                  }
                },
              },
            }}
          >
            <FormattedMessage
              id='invoices.invoiceEdit.onlinePayment'
              defaultMessage={'Online payment'}
            />
          </Block>

          <Cell span={[12, 6, 4]}>
            <FormControl
              label={intl.formatMessage({
                id: 'invoices.invoiceEdit.state',
                defaultMessage: 'State',
              })}
            >
              <Input
                value={dataTransaction?.getTransaction.state}
                disabled
              />
            </FormControl>
          </Cell>

          <Cell span={[12, 6, 4]}>
            <FormControl
              label={intl.formatMessage({
                id: 'invoices.invoiceEdit.updatedAt',
                defaultMessage: 'Updated at',
              })}
            >
              <Input
                value={dataTransaction?.getTransaction.updatedAt}
                disabled
              />
            </FormControl>
          </Cell>
        </div>)
        }
      </EditContainer>
    </>
  )
}
